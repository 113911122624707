import React from "react";
import { FormHelper } from "../../../helpers/Form.helper";
import { EditIcon } from "../../../icon/Edit.Icon";
import {
  GenericFeedbackAnswer,
  InterviewRoundFeedbackQuestion,
} from "../../../model/requests/InterviewRoundFeedback";
import { GenericFeedbackQuestionResponse } from "../../../model/response/GenericFeedbackQuestion.response";
import { QuestionCategoryResponse } from "../../../model/response/QuestionCategory.response";
import { User, UserBasicDetails } from "../../../user/User";
import { TextViewComponent } from "../TextView";
import { FormInputLabel } from "../eb-ui-components";
import { FormikGenericQuestionInput } from "../input/FormikGenericQuestionInput";
import { FormikHiddenInput } from "../input/FormikHiddentInput";
import { FormikInputParams } from "../input/FormikInputParams.model";
import { FormikQuestionCategorySelect } from "../input/FormikQuestionCategorySelect";
import { FormikQuestionDifficultySelect } from "../input/FormikQuestionDifficultySelect";
import { FormikQuestionPicker } from "../input/FormikQuestionPicker";
import { FormikRatingNumberSelect } from "../input/FormikRatingSelect";
import { EBTextAreaV2 } from "./FormElements";
import { Label } from "./elements/FormInputLabel";
import { Mode } from "./model/mode.model";

interface FormikGenericQuestionViewComponentProps extends FormikInputParams {
  genericAnswer: GenericFeedbackAnswer;
}

export function FormikGenericQuestionViewComponent(
  props: FormikGenericQuestionViewComponentProps
) {
  let genericQuestion = props.genericAnswer.question;
  return (
    <div className="GenericQuestionViewComponent" key={genericQuestion.id}>
      <>
        <label>{genericQuestion.displayStatement}</label> &nbsp;
        <FormikHiddenInput
          value={genericQuestion.id}
          name={`${props.name}.question.id`}
        />
        <FormikGenericQuestionInput
          genericAnswer={props.genericAnswer}
          name={`${props.name}.answer`}
          mode={props.mode}
          required={props.required}
        />
      </>
    </div>
  );
}

interface QuestionCategoryBasedInputsProps extends FormikInputParams {
  questionCategory: QuestionCategoryResponse;
  questionFeedback: InterviewRoundFeedbackQuestion;
}
export class QuestionCategoryBasedInputs extends React.Component<QuestionCategoryBasedInputsProps> {
  render() {
    let props = this.props;

    function findFullQuestion(
      searchQuestion?: GenericFeedbackQuestionResponse
    ): GenericFeedbackQuestionResponse | undefined {
      if (!searchQuestion || !props.questionCategory.genericQuestions) {
        return undefined;
      }
      for (let question of props.questionCategory.genericQuestions) {
        if (question.id === searchQuestion.id) {
          return question;
        }
      }
      return undefined;
    }
    {
      let finalAnswers = [];

      if (props.questionFeedback.genericAnswers) {
        for (let answer of props.questionFeedback.genericAnswers) {
          let fullQuestion = findFullQuestion(answer.question);
          if (fullQuestion) {
            answer.question = fullQuestion;
            finalAnswers.push(answer);
          }
        }
      }
      props.questionFeedback.genericAnswers = finalAnswers;
    }

    if (
      (!props.questionFeedback.genericAnswers ||
        props.questionFeedback.genericAnswers.length == 0) &&
      props.mode === Mode.EDIT
    ) {
      props.questionFeedback.genericAnswers =
        props.questionCategory.genericQuestions.map((question) => {
          return { question: question };
        });
    }
    if (props.questionFeedback.genericAnswers) {
      props.questionFeedback.genericAnswers =
        props.questionFeedback.genericAnswers.sort(function (a, b) {
          return a.question.order - b.question.order;
        });
    }
    return (
      <div className="row">
        {props.questionCategory.genericQuestions &&
          props.questionFeedback &&
          props.questionFeedback.genericAnswers &&
          props.questionFeedback.genericAnswers.map((genericAnswer, index) => {
            return (
              <>
                {(props.mode === Mode.EDIT ||
                  FormHelper.isAnswerPresent(genericAnswer)) && (
                  <div className="col-lg-6 col-md-6">
                    <FormikGenericQuestionViewComponent
                      genericAnswer={genericAnswer}
                      name={`${props.name}.${index}`}
                      mode={props.mode}
                    />
                  </div>
                )}
              </>
            );
          })}
      </div>
    );
  }
}

interface FormSingleQuestionFeedbackProps extends FormikInputParams {
  feedbackQuestion: InterviewRoundFeedbackQuestion;
  allQuestionCategories: QuestionCategoryResponse[];
  user: User;
}
export class FormSingleQuestionFeedback extends React.Component<
  FormSingleQuestionFeedbackProps,
  {}
> {
  render() {
    let props = this.props;
    function getFullCategory(
      searchCategory?: QuestionCategoryResponse
    ): QuestionCategoryResponse | undefined {
      if (!searchCategory) {
        return undefined;
      }
      for (let category of props.allQuestionCategories) {
        if (category.id === searchCategory.id) {
          return category;
        }
      }
      return undefined;
    }

    let questionCategory = getFullCategory(props.feedbackQuestion.category);
    if (questionCategory) {
      props.feedbackQuestion.category = questionCategory;
    }
    return (
      <div>
        <input
          type="hidden"
          className="form-control"
          name={`${props.name}.id`}
        />
        {props.mode === Mode.VIEW && (
          <FormQuestionFeedbackMetadataView
            questionFeedback={props.feedbackQuestion}
          />
        )}

        {props.mode === Mode.EDIT && (
          <FormQuestionFeedbackMetadataEdit
            questionFeedback={props.feedbackQuestion}
            allQuestionCategories={props.allQuestionCategories}
            name={props.name}
            user={props.user}
          />
        )}

        {questionCategory &&
          ((props.feedbackQuestion &&
            props.feedbackQuestion.genericAnswers &&
            props.feedbackQuestion.genericAnswers.length > 0) ||
            props.mode === Mode.EDIT) && (
            <div className="row mt-2">
              <div className="col">
                <hr />

                <QuestionCategoryBasedInputs
                  questionCategory={questionCategory}
                  name={`${props.name}.genericAnswers`}
                  questionFeedback={props.feedbackQuestion}
                  mode={props.mode}
                />
              </div>
            </div>
          )}
      </div>
    );
  }
}

interface FormQuestionFeedbackMetadataViewProps {
  questionFeedback: InterviewRoundFeedbackQuestion;
}

function FormQuestionFeedbackMetadataView(
  props: FormQuestionFeedbackMetadataViewProps
) {
  return (
    <div className="FormQuestionFeedbackMetadataView">
      <div>Rating: {props.questionFeedback.ratingOutOfTen}/10</div>
      {props.questionFeedback.detailedFeedback && (
        <div>
          Feedback:{" "}
          <TextViewComponent text={props.questionFeedback.detailedFeedback} />
        </div>
      )}
    </div>
  );
}

interface FormQuestionFeedbackMetadataEditProps extends FormikInputParams {
  questionFeedback: InterviewRoundFeedbackQuestion;
  allQuestionCategories: QuestionCategoryResponse[];
  user: User;
}

function FormQuestionFeedbackMetadataEdit(
  props: FormQuestionFeedbackMetadataEditProps
) {
  return (
    <div className="FormQuestionFeedbackMetadataEdit">
      <div className="row">
        <div className="col">
          <FormInputLabel label="Question:" required={true} />
          <div className="hstack gap-2">
            {props.questionFeedback.question ? (
              <>
                {props.questionFeedback.question.title}

                <FormikQuestionPicker
                  name={`${props.name}.question`}
                  uniqueKey={`${props.name}.question`.replaceAll(".", "_")}
                  pickerButtonContent={{
                    normal: (
                      <>
                        <EditIcon />
                      </>
                    ),
                    submitting: "",
                    extraClassNames: "btn-sm btn-light",
                  }}
                  user={props.user}
                />
              </>
            ) : (
              <>
                <FormikQuestionPicker
                  name={`${props.name}.question`}
                  uniqueKey={`${props.name}.question`.replaceAll(".", "_")}
                  pickerButtonContent={{
                    normal: <>Pick Question</>,
                    submitting: "",
                  }}
                  user={props.user}
                />
              </>
            )}
          </div>
        </div>
        <div className="col">
          <FormikQuestionCategorySelect
            questionCategories={props.allQuestionCategories}
            name={`${props.name}.category`}
            required={true}
          />
        </div>
        <div className="col">
          <FormikQuestionDifficultySelect
            label="Difficulty:"
            name={`${props.name}.difficulty`}
            required={true}
          />
        </div>
        <div className="col">
          <Label
            label="Rating (10 being highest):"
            required={true}
            forName={`${props.name}.ratingOutOfTen`}
          />
          <FormikRatingNumberSelect
            minRating={1}
            maxRating={10}
            name={`${props.name}.ratingOutOfTen`}
            required={true}
          />
        </div>
      </div>

      <div className="row mt-2">
        <div className="col">
          <EBTextAreaV2
            name={`${props.name}.detailedFeedback`}
            maxLength={5000}
            rows={3}
            required
            helpText={""}
            label="Feedback"
            enablePerformance={true}
            placeholder=""
          />
        </div>
      </div>
    </div>
  );
}
