import { Field } from "formik";
import React from "react";
import { Skill } from "../../model/Skill";
import { SkillAPI } from "../../skills/SkillAPI";
import { EBSelect } from "./eb-ui-components";

interface SkillsPickerViewProps {
  skills?: Skill[];
}

interface SkillsPickerViewState {
  skills?: Skill[];
}

interface SelectItem {
  value?: string;
  label?: string;
}

export class SkillsPickerView extends React.Component<
  SkillsPickerViewProps,
  SkillsPickerViewState
> {
  constructor(props: SkillsPickerViewProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.skills) {
      this.setState({ skills: this.props.skills });
    } else {
      SkillAPI.fetchAll().then((skills) => {
        this.setState({ skills: skills });
      });
    }
  }

  render() {
    function mapSkillSelectToAPI(selectSkill: SelectItem) {
      return { id: selectSkill.value, title: selectSkill.label };
    }

    function mapSkillAPIToSelect(apiSkill: Skill): SelectItem {
      if (!apiSkill) {
        return { value: undefined, label: undefined };
      }
      return { value: apiSkill.id.toString(), label: apiSkill.dl };
    }
    let skillsToShow: SelectItem[] = [];
    if (this.state.skills) {
      for (var skill of this.state.skills) {
        skillsToShow.push(mapSkillAPIToSelect(skill));
      }
    }

    return (
      <div>
        <Field
          label="Skills"
          name="skills"
          component={EBSelect}
          options={skillsToShow}
          isMulti={true}
          mappingRequired={true}
          selectToApiMapper={mapSkillSelectToAPI}
          apiToSelectMapper={mapSkillAPIToSelect}
        />
      </div>
    );
  }
}
