import { Field } from "formik";
import React from "react";
import { QuestionCategoryResponse } from "../../../model/response/QuestionCategory.response";
import { SelectItem } from "../../../model/views/Select.props";
import { EBSelect } from "../eb-ui-components";
import { FormikInputParams } from "./FormikInputParams.model";

export interface FormikQuestionCategorySelectProps extends FormikInputParams {
  questionCategories: QuestionCategoryResponse[];
  label?: string;
  isMulti?: boolean;
}
export function FormikQuestionCategorySelect(
  props: FormikQuestionCategorySelectProps
) {
  function mapToQuestionCategory(
    selectItem: SelectItem
  ): QuestionCategoryResponse {
    for (let questionCategory of props.questionCategories) {
      if (questionCategory.id === selectItem.value) {
        return questionCategory;
      }
    }
    throw new Error("Invalid state");
  }

  function mapToSelectItem(
    category: QuestionCategoryResponse
  ): SelectItem | undefined {
    if (!category) {
      return undefined;
    }

    for (let questionCategory of props.questionCategories) {
      if (questionCategory.id === category.id) {
        return {
          value: questionCategory.id,
          label: questionCategory.displayName,
        };
      }
    }
    throw new Error("Invalid state for category: " + JSON.stringify(category));
  }

  let categoryOptions: (SelectItem | undefined)[] = [];
  for (var category of props.questionCategories) {
    categoryOptions.push(mapToSelectItem(category));
  }

  return (
    <div>
      <Field
        label={props.label ? props.label : "Category:"}
        name={props.name}
        component={EBSelect}
        options={categoryOptions}
        isMulti={props.isMulti}
        mappingRequired={true}
        selectToApiMapper={mapToQuestionCategory}
        apiToSelectMapper={mapToSelectItem}
        required={props.required}
      />
    </div>
  );
}
