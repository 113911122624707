import React from "react";
import { Api, ApiMethod } from "../../components/common/ApiLinkedButton";
import { PriceView } from "../../components/common/Price.view";
import {
  ReviewButton,
  ReviewData,
} from "../../components/common/Review.button";
import { TargetLink } from "../../components/common/Target.link";
import { TimestampView } from "../../components/common/TimestampView";
import { reloadWindowPromise } from "../../components/common/reloadCallback";
import { admiApiLinkConstants } from "../../constants";
import { ListItemPlainLayout } from "../../list/ListItem.Layout";
import { ListItemProps, ListV2 } from "../../list/ListV2";
import { PageResponse } from "../../paging/Paging.Model";
import { StatusComponent } from "../../status/Status.Component";
import { UserLink } from "../../user/User.link";
import {
  PaymentReleaseRequest,
  PaymentReleaseRequestStatus,
} from "./PaymentReleaseRequest.model";

interface PaymentReleaseRequestsListComponentProps {
  prrs: PaymentReleaseRequest[];
  showReviewOptions?: boolean;

  onPrrUpdate?: (index: number, prr: PaymentReleaseRequest) => Promise<void>;
}

export function PaymentReleaseRequestsListComponent(
  props: PaymentReleaseRequestsListComponentProps
) {
  return (
    <div className="PaymentReleaseRequestsListComponent">
      <ListV2
        hidePaging={true}
        onFetch={function (): Promise<PageResponse<any>> {
          return Promise.resolve({
            content: props.prrs,
            totalSize: props.prrs.length,
          });
        }}
        ItemLayoutComponent={ListItemPlainLayout}
        itemNode={(itemProps: ListItemProps) => {
          return (
            <PaymentReleaseRequestsListItemComponent
              prr={itemProps.item as PaymentReleaseRequest}
              showReviewOptions={props.showReviewOptions}
              index={itemProps.index}
              onPrrUpdate={props.onPrrUpdate}
            />
          );
        }}
      />
    </div>
  );
}

interface PaymentReleaseRequestsListItemComponentProps {
  prr: PaymentReleaseRequest;
  index: number;
  showReviewOptions?: boolean;

  onPrrUpdate?: (index: number, prr: PaymentReleaseRequest) => Promise<void>;
}

export function PaymentReleaseRequestsListItemComponent(
  props: PaymentReleaseRequestsListItemComponentProps
) {
  let prr = props.prr;
  return (
    <div
      className={`PaymentReleaseRequestsListItemComponent card card-body mb-2 ${
        prr.status == PaymentReleaseRequestStatus.VERIFIED &&
        "border-left-success"
      } ${
        prr.status == PaymentReleaseRequestStatus.REJECTED &&
        "border-left-danger"
      } ${
        prr.status == PaymentReleaseRequestStatus.WORK_COMPLETED &&
        "border-left-warning"
      } ${
        prr.status == PaymentReleaseRequestStatus.CREATED &&
        "border-left-warning"
      } shadow`}
    >
      <div className={`row gy-2`}>
        <div className="col-md-1">{props.index + 1}</div>
        <div className="col-md-3">
          Created At: <TimestampView timestamp={prr.createdAt} />
        </div>

        <div className="col-md-1">
          <PriceView showFree={false} price={prr.paymentAmount} />
        </div>

        <div className="col-md-2">
          {prr.statusDl && <StatusComponent status={prr.statusDl} />}
        </div>

        <div className="col-md-2">
          <UserLink user={prr.user} />
        </div>
        {prr.source && prr.source.label && (
          <>
            <div className="col-md-2">
              <TargetLink target={prr.source} />
            </div>
          </>
        )}
        {props.showReviewOptions &&
          !prr.verifiedAt &&
          !prr.rejectedAt &&
          prr.workCompletedAt && (
            <div className="col-md-2 hstack gap-2">
              <ReviewButton
                uniqueKey={`prr-${prr.id}-verify`}
                buttonProps={{
                  content: { normal: "Verify", submitting: "Verifying..." },
                }}
                api={
                  new Api<ReviewData, PaymentReleaseRequest>(
                    admiApiLinkConstants.API_ADMIN_PAYMENT_RELEASE_REQUEST_VERIFY.replaceAll(
                      "{userId}",
                      prr.user.id
                    ).replaceAll("{prrId}", prr.id),
                    ApiMethod.POST
                  )
                }
                successCallback={function (prr: PaymentReleaseRequest) {
                  if (props.onPrrUpdate) {
                    return props.onPrrUpdate(props.index, prr);
                  }
                  return reloadWindowPromise();
                }}
              />

              <ReviewButton
                uniqueKey={`prr-${prr.id}-reject`}
                buttonProps={{
                  content: { normal: "Reject", submitting: "Rejecting..." },
                }}
                api={
                  new Api<ReviewData, PaymentReleaseRequest>(
                    admiApiLinkConstants.API_ADMIN_PAYMENT_RELEASE_REQUEST_REJECT.replaceAll(
                      "{userId}",
                      prr.user.id
                    ).replaceAll("{prrId}", prr.id),
                    ApiMethod.POST
                  )
                }
                successCallback={function (prr: PaymentReleaseRequest) {
                  if (props.onPrrUpdate) {
                    return props.onPrrUpdate(props.index, prr);
                  }
                  return reloadWindowPromise();
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
}
