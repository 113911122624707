import React, { useState } from "react";
import { CommentsComponent } from "../components/common/comments/Comments.component";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { User } from "../user/User";
import { CommunityGroup } from "./CommunityGroup.Model";

interface CommunityGroupComponentProps {
  cg: CommunityGroup;
  liu?: User;
}

export function CommunityGroupComponent(props: CommunityGroupComponentProps) {
  let [loading, setLoading] = useState<boolean>();

  return (
    <div className="CommunityGroupComponent">
      <h5 className="mb-4">{props.cg.dl}</h5>

      <CommentsComponent
        targetType={AttachmentTargetType.COMMUNITY_GROUP}
        targetId={props.cg.id + ""}
        loggedInuser={props.liu}
      />
    </div>
  );
}
