import React, { useEffect, useState } from "react";
import { CallerContext } from "../../../components/common/CallerContext";
import { EditTitle } from "../../../components/common/Edit.title";
import { Loader } from "../../../components/common/Loader.component";
import { KeyHelper } from "../../../helpers/Key.Helper";
import { Question } from "../../../question/Question.Model";
import { QuestionAccordionView } from "../../../question/Question.view";
import { QuestionPickerView } from "../../../question/QuestionPickerView";
import { QuestionsAPI } from "../../../question/QuestionsAPI";
import { ContentItemDataQuestion } from "../../Content.model";
import { ContentItemInputProps } from "../ContentItem.model";

export function ContentItemInputQuestion(props: ContentItemInputProps) {
  let [question, setQuestion] = useState<Question>();
  let [loading, setLoading] = useState<boolean>(false);
  let [reRenderCount, setRenderCount] = useState<number>(0);

  function reRender() {
    setRenderCount(reRenderCount + 1);
  }

  function onChange(ques: Question) {
    let existingData = props.item.data as ContentItemDataQuestion;
    if (existingData) {
      existingData.questionId = ques.id;
    } else {
      props.item.data = { questionId: ques.id };
    }
    props.onChange();
    reRender();
  }

  let data = props.item.data as ContentItemDataQuestion;

  useEffect(() => {
    if (data && data.questionId !== undefined && data.questionId !== null) {
      setLoading(true);
      QuestionsAPI.fetchQuestionDetails(data.questionId)
        .then((questionResponse) => {
          setQuestion(questionResponse);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setQuestion(undefined);
    }
  }, [data?.questionId]);

  return (
    <div className="ContentItemInputQuestion p-2">
      <div className="row">
        <div className="col">
          <h6>Question:</h6>
        </div>
        <div className="col-auto">
          {props.user && (
            <>
              <QuestionPickerView
                callback={function (
                  question: Question,
                  callerContext: CallerContext
                ): void {
                  setQuestion(question);
                  onChange(question);
                }}
                callerContext={{}}
                uniqueKey={KeyHelper.getUniqueKey()}
                user={props.user}
                pickerButtonContent={{
                  normal: question ? (
                    <EditTitle text="Edit Question" />
                  ) : (
                    <EditTitle text="Pick Question" />
                  ),
                  extraClassNames: "btn-sm",
                }}
              />
            </>
          )}
        </div>
      </div>
      <div>
        {loading && <Loader></Loader>}
        {!loading && (
          <>
            {question && (
              <div className="mt-2">
                <QuestionAccordionView question={question} user={props.user} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
