import React, { useEffect, useState } from "react";
import { Center } from "../components/common/layout/Center.component";
import { Loader } from "../loader/Loader";
import { User } from "../user/User";
import { BookingApi } from "./Booking.Api";
import { BookingComponent } from "./Booking.Component";
import { Booking } from "./Booking.model";

interface BookingComponentWrapperProps {
  bookingId: string;
  user?: User;
}
export function BookingComponentWrapper(props: BookingComponentWrapperProps) {
  let [booking, setBooking] = useState<Booking>();
  let [loading, setLoading] = useState<boolean>();
  useEffect(() => {
    setLoading(true);
    BookingApi.get(props.bookingId)
      .executeV2()
      .then((booking) => {
        setBooking(booking);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.bookingId]);
  return (
    <div className="booking-page-content">
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}

      {!loading && booking && props.user && (
        <BookingComponent booking={booking} user={props.user} />
      )}
    </div>
  );
}
