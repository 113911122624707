import React from "react";
import { ImageComponent } from "../components/common/Image.component";
import { HTMLProps } from "../model/HTMLProps";
import { LocationType } from "../model/Picture";

export function UnpublishIcon(props: HTMLProps) {
  return (
    <ImageComponent
      image={{ locationType: LocationType.URL, data: "/img/unpublish-32.png" }}
      {...props}
      width={16}
    />
  );
}
