import { Col, Collapse, CollapseProps, Row } from "antd";
import React from "react";
import "./Agenda.Component.css";
import { Agenda, AgendaItem } from "./Offering";

export function AgendaComponent(props: { agenda: Agenda }) {
  const collapseItems: CollapseProps["items"] = [];
  const agendaItems: AgendaItem[] = props.agenda.items!;
  let i = 0;
  for (let topAgendaItem of agendaItems) {
    collapseItems.push({
      key: i++ + "",
      label: topAgendaItem.dl,
      children: (
        <div>
          {(!topAgendaItem.items || topAgendaItem.items.length == 0) && (
            <ul>
              <li>{topAgendaItem.dl}</li>
            </ul>
          )}
          <ul>
            {topAgendaItem.items?.map((subItem) => (
              <li>{subItem.dl}</li>
            ))}
          </ul>
        </div>
      ),
    });
  }
  return (
    <div className="AgendaComponent">
      {/* <Collapse items={collapseItems} className="rounded-0" /> */}

      {props.agenda.items?.map((topAgendaItem, index) => (
        <div key={index} className="mt-1">
          {topAgendaItem.items && topAgendaItem.items.length > 0 ? (
            <Collapse
              // expandIconPosition="end"
              items={[
                {
                  key: index,
                  label: <AgendaItemHeader agendaItem={topAgendaItem} />,
                  children: (
                    <div>
                      <ul className="mb-0">
                        {topAgendaItem.items?.map((subItem) => (
                          <li>
                            <AgendaItemHeader agendaItem={subItem} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            <div className="card agenda-item">
              <AgendaItemHeader agendaItem={topAgendaItem} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function AgendaItemHeader(props: { agendaItem: AgendaItem }) {
  return (
    <>
      <Row gutter={[16, 2]}>
        <Col flex={"auto"}>{props.agendaItem.dl}</Col>
        <Col className="fw-light text-muted">
          {props.agendaItem.contentMetrics && (
            <small className="">{props.agendaItem.contentMetrics.dl}</small>
          )}
        </Col>
      </Row>
    </>
  );
}
