import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { UserBasicDetails } from "../user/User";
import { RelatedContentConstants } from "./RelatedContent.Constants";
import { IContent, RelatedContentRequest } from "./RelatedContent.model";

export class RelatedContentApi {
  public static getRelatedContent(
    request: RelatedContentRequest
  ): Promise<IContent[]> {
    return new Api<RelatedContentRequest, IContent[]>(
      RelatedContentConstants.API_RELATED_CONTENT,
      ApiMethod.POST
    ).execute(request);
  }

  public static getRelatedExperts(
    request: RelatedContentRequest
  ): Promise<UserBasicDetails[]> {
    return new Api<RelatedContentRequest, UserBasicDetails[]>(
      RelatedContentConstants.API_RELATED_EXPERTS,
      ApiMethod.POST
    ).execute(request);
  }
}
