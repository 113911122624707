import React from "react";
import {
  PaymentDetails,
  PaymentDetailsManual,
  PaymentDetailsPaypal,
  PaymentDetailsRazorPay,
  PaymentProcessorName,
} from "../../model/OrderDetails";
import { PaymentPaypalComponent } from "../../payments/PaymentPaypal.component";
import { User } from "../../user/User";
import { PaymentManualComponent } from "./PaymentManualComponent";
import { PaymentRazorpayComponent } from "./PaymentRazorpayComponent";

interface PaymentSummaryProps {
  payment: PaymentDetails;
  index: number;
  liu?: User;
}
export class PaymentSummaryComponent extends React.Component<PaymentSummaryProps> {
  render() {
    return (
      <div className="PaymentSummaryComponent">
        <h6>Payment {this.props.index + 1}</h6>
        <div className="">
          {this.props.payment.paymentMethod ==
            PaymentProcessorName.RAZORPAY && (
            <PaymentRazorpayComponent
              paymentDetails={this.props.payment as PaymentDetailsRazorPay}
              liu={this.props.liu}
            />
          )}
          {this.props.payment.paymentMethod == PaymentProcessorName.PAYPAL && (
            <div>
              <PaymentPaypalComponent
                paymentDetails={this.props.payment as PaymentDetailsPaypal}
              />
            </div>
          )}
          {this.props.payment.paymentMethod == PaymentProcessorName.MANUAL && (
            <PaymentManualComponent
              paymentDetails={this.props.payment as PaymentDetailsManual}
            />
          )}
        </div>
      </div>
    );
  }
}
