import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import {
  AvailableDateTimeSlotsResponse,
  CalendarSettings,
  EBTimezone,
} from "./Calendar.model";

export class CalendarApi {
  public static getUserCalendarSettings(
    userId: string
  ): Promise<CalendarSettings> {
    let api = apiLinkConstants.API_USER_CALENDAR_SETTINGS_FETCH.replaceAll(
      "{userId}",
      userId
    );
    return new Api<void, CalendarSettings>(api, ApiMethod.GET).execute();
  }

  public static updateUserCalendarSettings(
    userId: string,
    settings: CalendarSettings
  ): Promise<CalendarSettings> {
    let api = apiLinkConstants.API_USER_CALENDAR_SETTINGS_UPDATE.replaceAll(
      "{userId}",
      userId
    );
    return new Api<CalendarSettings, CalendarSettings>(
      api,
      ApiMethod.PUT
    ).execute(settings);
  }

  public static getUserAvailableDateTimeSlots(
    userId: string,
    durationMins: number
  ): Promise<AvailableDateTimeSlotsResponse> {
    let api =
      apiLinkConstants.API_USER_CALENDAR_AVAILABILITY_DATE_TIMES.replaceAll(
        "{userId}",
        userId
      ).replaceAll("{durationMins}", `${durationMins}`);
    return new Api<void, AvailableDateTimeSlotsResponse>(
      api,
      ApiMethod.GET
    ).execute();
  }

  public static getAllTimezones(): Promise<EBTimezone[]> {
    return new Api<void, EBTimezone[]>(
      apiLinkConstants.API_CALENDAR_TZS_LIST_FETCH,
      ApiMethod.GET
    ).execute();
  }
}
