import React, { useEffect, useState } from "react";
import { ReactMatch } from "../model/ReactMatchObj";
import { FetchContext } from "../model/enums/FetchContext.enum";
import { PageProps } from "../model/views/PageProps";
import { EventApi } from "./Event.Api";
import { EventV2 } from "./Event.Model";
import { EventTypeInput } from "./EventBasicInformation.input";

interface UpdateEventComponentPathParams {
  eventId: string;
}

interface UpdateEventComponentProps extends PageProps {
  match: ReactMatch<UpdateEventComponentPathParams>;
}

export function UpdateEventComponent(props: UpdateEventComponentProps) {
  let [eventV2, setEventV2] = useState<EventV2>();

  useEffect(() => {
    EventApi.get(props.match.params.eventId, FetchContext.EDIT).then(
      (event: EventV2) => {
        setEventV2(event);
      }
    );
  }, []);

  return (
    <div className="UpdateEventComponent">
      {props.user?.basicDetails && eventV2 && (
        <>
          <h4>Edit Event:</h4>
          <EventTypeInput
            user={props.user}
            eventId={props.match.params.eventId}
            event={eventV2}
          ></EventTypeInput>
        </>
      )}
    </div>
  );
}
