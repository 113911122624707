let API_BASE = "/api";

export class PriceDifferentialSettingsConstants {
  public static PRICE_DIFFERENTIAL_ADD =
    API_BASE + "/v1/price_differential_settings";
  public static PRICE_DIFFERENTIAL_LIST =
    API_BASE + "/v1/price_differential_settings";
  public static PRICE_DIFFERENTIAL_SINGLE =
    API_BASE + "/v1/price_differential_settings/{pdsId}";
}
