import { Field, Form, Formik } from "formik";
import React from "react";
import { FormikDatePicker } from "../input/FormikDatePicker";

interface DateTimeUpdateFormProps {
  submitCallback: (pickedDate: string) => Promise<void>;
  initialDateValue?: string;
}
export function DateTimeUpdateForm(props: DateTimeUpdateFormProps) {
  return (
    <Formik
      initialValues={{ date_time: props.initialDateValue }}
      onSubmit={(values, action) => {
        if (values.date_time) {
          action.setSubmitting(true);
          props
            .submitCallback(values.date_time)
            .then(() => {
              action.setSubmitting(false);
            })
            .catch(() => {
              action.setSubmitting(false);
            });
        }
      }}
    >
      {(formProps) => (
        <Form>
          <div className="hstack">
            <Field
              name="date_time"
              placeholder="Enter date and time"
              component={FormikDatePicker}
              className="form-control"
            />

            <button
              className="btn btn-sm btn-primary"
              type="submit"
              disabled={formProps.isSubmitting}
            >
              {formProps.isSubmitting ? "Saving... Please wait." : "Save"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
