import axios from "axios";
import { apiLinkConstants } from "../../../constants";
import { AttachmentTargetType } from "../../../model/AttachmentTargetType";
import { Api, ApiMethod } from "../ApiLinkedButton";
import { Comment } from "./Comments.model";

export class CommentsAPI {
  public static getComments(
    targetType: AttachmentTargetType,
    targetId: string,
    pageNum: number,
    pageSize: number
  ): Promise<Comment[]> {
    let api =
      apiLinkConstants.API_COMMENTS_LIST_ALL.replaceAll(
        "{targetType}",
        targetType
      ).replaceAll("{targetId}", targetId) +
      "?pageNum=" +
      pageNum +
      "&pageSize=" +
      pageSize;

    return new Promise<Comment[]>((resolve, reject) => {
      return axios.get(api).then((response) => {
        resolve(response.data);
      });
    });
  }

  public static addComment(
    targetType: AttachmentTargetType,
    targetId: string,
    commentRequest: Comment
  ): Promise<Comment> {
    let api = apiLinkConstants.API_COMMENTS_ADD.replaceAll(
      "{targetType}",
      targetType
    ).replaceAll("{targetId}", targetId);

    return new Api<Comment, Comment>(api, ApiMethod.POST).executeV2(
      commentRequest
    );
  }
}
