import { Alert } from "antd";
import React from "react";
import { SpoofHelper } from "../../helpers/Spoof.Helper";
import { documentHelpers } from "../../helpers/commonHelpers";
import { PageProps } from "../../model/views/PageProps";
import { User } from "../../user/User";
import { UserLink } from "../../user/User.link";
import { UserAPI } from "../../user/UserAPI";
import { CallbackButton } from "../common/ApiLinkedButton";
import { reloadWindowPromise } from "../common/reloadCallback";
import { PublicFooter } from "./PublicFooter";
import PublicHeader from "./PublicHeader";
import { PublicPageAlertMessage } from "./PublicPageAlertMessage";

interface PublicPageProps {
  contentComponent: React.ComponentType<PageProps>;
  navBarComponent?: React.ComponentType<PageProps>;
  hideNavBar?: boolean;
  hideFooter?: boolean;
  showFooterOnMobile?: boolean;
  contentNeedLogin?: boolean;
  headerLinks?: { id: string; text: string; href: string }[];
}

interface PublicPageState {
  user?: User;
}

export class PublicPage extends React.Component<
  PublicPageProps,
  PublicPageState
> {
  constructor(props: PublicPageProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    documentHelpers.addScript(document, "/js/home-custom.js");

    UserAPI.fetchLoggedInUser().then((user) => {
      this.setState({ user });
    });
  }

  render() {
    const ContentComponent = this.props.contentComponent;
    const NavBarComponent = this.props.navBarComponent;

    let spoofedUser = SpoofHelper.currentSpoof();
    if (spoofedUser) {
      console.log("Spoofing: " + JSON.stringify(spoofedUser));
    }
    return (
      <div id="index-wrapper">
        {spoofedUser && (
          <Alert
            message={
              <>
                Currntly Spoofing: <UserLink user={spoofedUser} />
                <div className="float-right">
                  <CallbackButton
                    buttonProps={{
                      text: { normal: "Stop Spoofing" },
                      type: "danger",
                    }}
                    callback={function (): Promise<void> {
                      SpoofHelper.unspoof();
                      return reloadWindowPromise();
                    }}
                    uniqueKey={"stop-spoofing"}
                  />
                </div>
              </>
            }
            type="error"
          />
        )}
        {!this.props.hideNavBar && (
          <>
            {NavBarComponent ? (
              <NavBarComponent {...this.props} user={this.state.user} />
            ) : (
              <PublicHeader {...this.props} user={this.state.user} />
            )}
          </>
        )}
        <div className="content-container">
          <PublicPageAlertMessage />

          {(!this.props.contentNeedLogin || this.state.user) && (
            <ContentComponent {...this.props} user={this.state.user} />
          )}
        </div>
        {!this.props.hideFooter && (
          <PublicFooter
            user={this.state.user}
            showOnMobile={this.props.showFooterOnMobile}
          />
        )}
      </div>
    );
  }
}

export default PublicPage;
