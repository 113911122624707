import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const FormikDatePicker = (formikFieldProps: any) => {
  const { field, form, ...props } = formikFieldProps;

  const setFieldValue = (date: Date) => {
    form.setFieldValue(field.name, date?.getTime());
  };

  const getFieldValue = () => {
    return field.value ? new Date(field.value) : null;
  };

  return (
    <ReactDatePicker
      selected={getFieldValue()}
      onChange={setFieldValue}
      className={props.className}
      placeholderText={props.placeholder}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="Time"
      dateFormat="MMMM d, yyyy h:mm aa"
      required={props.required}
    />
  );
};
