import { Api, ApiMethod } from "../../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../../constants";
import { SessionType } from "../Offering";

export class OfferingTextQueryAPI {
  public static getTextQueryTypes(): Promise<SessionType[]> {
    let api = apiLinkConstants.API_OFFERING_TEXT_QUERY_TYPE_LIST;
    return new Api<void, SessionType[]>(api, ApiMethod.GET).execute();
  }
}
