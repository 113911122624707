import React from "react";
import ReactMarkdown from "react-markdown";
import { ReactElement } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import "./MarkdownView.css";

interface MarkdownViewProps {
  text: string;
}

export function MarkdownView(props: MarkdownViewProps) {
  return <MarkdownViewV1>{props.text}</MarkdownViewV1>;
}

interface MarkdownViewV1Props {
  children: string;
}

export function MarkdownViewV1(props: MarkdownViewV1Props) {
  return (
    <div className="MarkdownView dont-break-out">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        linkTarget="_blank"
        components={{
          img(props): ReactElement {
            return <img {...props} style={{ maxWidth: "100%" }} />;
          },
          a(props): ReactElement {
            return (
              <a
                {...props}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            );
          },
        }}
        unwrapDisallowed={true}
      >
        {props.children}
      </ReactMarkdown>
    </div>
  );
}
