import { AttachmentTargetType } from "../../model/AttachmentTargetType";
import { CountryCode } from "../../model/Country.model";

export interface PriceDifferentialSettings {
  id?: string;
  matchInfo: PriceDifferentialSettingsMatchInfo;
  applyInfo: PriceDifferentialSettingsApplyInfo;
}

// Match Info
export interface PriceDifferentialSettingsMatchInfo {
  matchType: AttachmentTargetType;
  matchData: IPriceDifferentialMatchTypeData;
}

export interface IPriceDifferentialMatchTypeData {}

export enum PriceDifferentialSettingsApplyType {
  PERCENT = "PERCENT",
}

export interface PriceDifferentialMatchTypeDataCountry
  extends IPriceDifferentialMatchTypeData {
  countryCode: CountryCode;
  operator: Operator;
}

export enum Operator {
  NOT,
}

// Apply Info

export interface PriceDifferentialSettingsApplyInfo {
  applyType: PriceDifferentialSettingsApplyType;
  applyData: IPriceDifferentialSettingsApplyTypeData;
}

export interface IPriceDifferentialSettingsApplyTypeData {}

export interface PriceDifferentialSettingsApplyTypePercent
  extends IPriceDifferentialSettingsApplyTypeData {
  value: number;
}
