import axios from "axios";
import { apiLinkConstants } from "../constants";
import { LinkHelpers } from "../helpers/Link.Helpers";
import { InclusionHelper } from "../inclusion/Inclusion.Helper";
import { UserInclusion } from "../inclusion/Inclusion.Model";
import { SearchUserQuery } from "../model/SearchUserQuery";
import { UserSearchRequestV1 } from "../model/search/UserSearchRequestV1";
import { User, UserBasicDetails } from "../user/User";

export class SearchAPI {
  public static searchUser(searchUserQuery: SearchUserQuery): Promise<User[]> {
    return new Promise<User[]>((resolve, reject) => {
      axios
        .post(apiLinkConstants.SEARCH_USER_API, searchUserQuery)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static searchExpert(
    searchUserQuery: UserSearchRequestV1,
    inclusion?: UserInclusion
  ): Promise<UserBasicDetails[]> {
    let api = apiLinkConstants.EXPERT_SEARCH_API;
    if (inclusion) {
      api = LinkHelpers.addQueryParam(
        api,
        InclusionHelper.getUserQp(inclusion)
      );
    }

    return new Promise<UserBasicDetails[]>((resolve, reject) => {
      axios
        .post(api, searchUserQuery)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
