import { BogieName } from "../Global.Names";
import { WindowHelpers } from "../helpers/Window.helper";

export interface FilterGroupBy {
  groupBy: FilterGroupByEnumId;
  dl: React.ReactNode;
}

export enum FilterGroupByEnumId {
  BOGIE = "BOGIE",
  ENGINE = "ENGINE",
  NONE = "NONE",
}

export class BookingListGroupByHelper {
  static groupBys: FilterGroupBy[] = [
    {
      groupBy: FilterGroupByEnumId.BOGIE,
      dl: "Group by " + BogieName.SENTENCE_MID,
    },
    { groupBy: FilterGroupByEnumId.NONE, dl: "No Grouping" },
  ];

  static list(): FilterGroupBy[] {
    return this.groupBys;
  }

  static get(groupByEnumId: FilterGroupByEnumId): FilterGroupBy {
    for (let groupBy of this.groupBys) {
      if (groupBy.groupBy == groupByEnumId) {
        return groupBy;
      }
    }

    throw new Error("Unexpected");
  }

  static getFromQParam(qParamKey: string): FilterGroupBy | undefined {
    let str: string = WindowHelpers.qParam(qParamKey);
    if (!str) {
      return undefined;
    }

    return this.get(str as FilterGroupByEnumId);
  }
}
