import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import { HTMLProps } from "../../../../model/HTMLProps";

interface LabelProps extends HTMLProps {
  label: React.ReactNode;
  forName?: string;
  forId?: string;
  required?: boolean;
  tooltip?: string;
}

export function Label(props: LabelProps) {
  return (
    <label htmlFor={props.forId || props.forName} className={props.className}>
      {props.label}
      {props.required ? <span className="text-danger">*</span> : null}
      {props.tooltip && (
        <Tooltip title={props.tooltip} className="ml-1">
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </label>
  );
}
