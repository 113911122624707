import React from "react";
import { InterviewRoundAPI } from "../../api/InterviewRoundAPI";
import { InterviewRound } from "../../model/InterviewRound";
import { User } from "../../user/User";
import { InterviewRoundView } from "../common/ats/InterviewRoundView";

interface IntervieweRoundInterviewerPageContentState {
  interview: InterviewRound;
}

interface IntervieweRoundInterviewerPageContentProps {
  interviewRoundId: string;
  user: User;
}
export class IntervieweRoundInterviewerPageContent extends React.Component<
  IntervieweRoundInterviewerPageContentProps,
  IntervieweRoundInterviewerPageContentState
> {
  componentDidMount() {
    InterviewRoundAPI.getInterviewRoundFetchById(
      this.props.interviewRoundId
    ).then((response) => {
      this.setState({ interview: response });
    });
  }

  render() {
    return (
      <div className="main-container">
        {this.state && this.state.interview && this.props.user && (
          <InterviewRoundView {...this.props} {...this.state} />
        )}
      </div>
    );
  }
}
