import { CheckoutAPI } from "../api/CheckoutAPI";
import { PaymentAPI } from "../api/PaymentAPI";
import { RazorpayAPI } from "../api/RazorpayAPI";
import { PaymentProcessorName } from "../model/response/Checkout.response";
import { PaymentSuccessResponse } from "../model/response/PaymentSuccess.response";
import { RazorpaymentProcessorCheckoutResponse } from "../model/response/Razorpay.response";
import { FreePaymentProcessorCheckoutResponse } from "../payments/FreePayment.response";
import { CheckoutResponsePaypal } from "../payments/Paypal.response";
import { User } from "../user/User";
import { WindowHelpers } from "./Window.helper";

export class PaymentHelper {
  public static processOrderPayment(
    orderId: string,
    user: User
  ): Promise<PaymentSuccessResponse> {
    return CheckoutAPI.checkoutOrder(orderId).then((checkoutResponse) => {
      if (checkoutResponse.name === PaymentProcessorName.PAYPAL) {
        return new Promise<PaymentSuccessResponse>((resolve, reject) => {
          WindowHelpers.redirect(
            (checkoutResponse as CheckoutResponsePaypal).redirectUrl
          );
        });
      } else if (checkoutResponse.name === PaymentProcessorName.FREE) {
        return PaymentAPI.markFreePaymentSuccess(
          checkoutResponse as FreePaymentProcessorCheckoutResponse
        );
      } else if (checkoutResponse.name === PaymentProcessorName.RAZORPAY) {
        return RazorpayAPI.processPayment(
          checkoutResponse as RazorpaymentProcessorCheckoutResponse,
          user
        ).then((response) => {
          return PaymentAPI.markRazorpayPaymentSuccess(response);
        });
      } else {
        return Promise.reject(
          "Some error occurred. Please contact us through help!"
        );
      }
    });
  }
}
