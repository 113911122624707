import React from "react";
import Moment from "react-moment";
import { PriceHelpers } from "../../helpers/Helpers";
import { PaymentDetailsRazorPay } from "../../model/OrderDetails";
import { PaymentProcessorName } from "../../model/response/Checkout.response";
import { PaymentApi } from "../../payments/Payment.Api";
import { User } from "../../user/User";
import { ApiLinkedButtonV3 } from "../common/Form/ApiLinked.button.V3";
import { reloadWindowPromise } from "../common/reloadCallback";

interface PaymentRazorpayComponentProps {
  paymentDetails: PaymentDetailsRazorPay;
  liu?: User;
}

export class PaymentRazorpayComponent extends React.Component<PaymentRazorpayComponentProps> {
  constructor(props: PaymentRazorpayComponentProps) {
    super(props);
    this.setState({
      razorpayDetails: this.props.paymentDetails,
    });
  }
  render() {
    return (
      <>
        <div className="">
          <div className="card-body p-0">
            {this.props.paymentDetails.razorpayPaymentId && (
              <div className="col no-gutters align-items-center">
                <span className="font-weight-bold">Payment ID : </span>
                <span className="text-xs mb-1">
                  {this.props.paymentDetails.razorpayPaymentId}
                </span>
              </div>
            )}
            {this.props.paymentDetails.paymentDate && (
              <div className="col no-gutters align-items-center">
                <span className="font-weight-bold">Payment Date: </span>
                <span className=" text-xs mb-1">
                  <Moment format="DD-MM-YYYY">
                    {this.props.paymentDetails.paymentDate}
                  </Moment>
                </span>
              </div>
            )}
            <div className="col no-gutters align-items-center">
              <span className="font-weight-bold">Payment Status: </span>
              {this.props.paymentDetails.paymentStatus}
            </div>
            <div className="col no-gutters align-items-center">
              <span className="font-weight-bold">Payment Method: </span>

              <span className="text-xs mb-1">
                {this.props.paymentDetails.paymentMethod}
              </span>
            </div>
            {this.props.paymentDetails.paymentPrice && (
              <div className="col no-gutters align-items-center">
                <span className="font-weight-bold">Payment Amount: </span>

                <span className="text-xs mb-1">
                  {PriceHelpers.getDisplayPriceV2(
                    this.props.paymentDetails.paymentPrice
                  )}
                </span>
              </div>
            )}
            {this.props.liu?.basicDetails.isAdmin && (
              <>
                <ApiLinkedButtonV3
                  formModalSize="md"
                  initialValues={{
                    name: PaymentProcessorName.RAZORPAY,
                    razorpayOrderId: this.props.paymentDetails.razorPayOrderId,
                    razorpayPaymentId: "admin",
                    razorpaySignature: "dummy",
                  }}
                  apiFormBody={
                    <>
                      <h6>Are you sure you? </h6>
                      <div className="alert alert-warning mt-2" role="alert">
                        This is an irreversible action.
                      </div>
                    </>
                  }
                  buttonProps={{
                    content: {
                      normal: <>Mark as Paid</>,
                    },
                  }}
                  api={PaymentApi.markRazorpayPaymentSuccess()}
                  successCallback={reloadWindowPromise}
                  uniqueKey={
                    "payment-" +
                    this.props.paymentDetails.razorPayOrderId +
                    "-mark-complete"
                  }
                />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
