import { InputNumber, Space } from "antd";
import React from "react";

export interface EBPercentInputProps {
  defaultValue?: number;
  value?: number;
  maxValue?: number;
  onChange?: (value: number | null) => void;
}

export function EBPercentInput(props: EBPercentInputProps) {
  return (
    <InputNumber<number>
      defaultValue={props.defaultValue}
      min={0}
      max={props.maxValue}
      formatter={(value) => `${value ? value / 100 : value}%`}
      parser={(value) => (value?.replace("%", "") as unknown as number) * 100}
      onChange={props.onChange}
      value={props.value}
      size="large"
    />
  );
}
