import React from "react";
import { ImageComponent } from "../components/common/Image.component";
import { MarkdownView } from "../components/common/MarkdownView";
import { ImageHelpers } from "../helpers/Helpers";
import { CountType } from "../model/Count.model";
import { IOfferingV2 } from "./Offering";
import { OfferingPricePill } from "./Offering.Price";
import "./OfferingCard.css";

export interface OfferingCardV2Props {
  offering: IOfferingV2;
  className?: string;
  onOfferingSelect?: () => void;
  showDetailsLong?: boolean;
}

export function OfferingCardV2(props: OfferingCardV2Props) {
  const offeringCommonDetails = props.offering.commonDetails;
  return (
    <>
      <div
        className={`card OfferingCard ${props.className}`}
        onClick={props.onOfferingSelect}
      >
        {offeringCommonDetails.bannerImage &&
          ImageHelpers.validImage(offeringCommonDetails.bannerImage.data) && (
            <ImageComponent
              image={offeringCommonDetails.bannerImage}
              width={"100%"}
              height={"auto"}
              className="rounded-top"
            />
          )}
        <div className="mt-2 py-2 px-3 border-bottom">
          <div className="row">
            <div className="col">
              <span className="h5 m-0 font-weight-bold text-dark">
                <ImageComponent
                  image={offeringCommonDetails.type.dp}
                  className="mr-2"
                />
                {offeringCommonDetails.dl}
              </span>
            </div>
            <div className="col-auto">
              {offeringCommonDetails.ratingsSummary && (
                <span className="">
                  <i className="fas fa-star text-primary"></i>{" "}
                  {offeringCommonDetails.ratingsSummary.dlAvgRating}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="card-body px-3 pt-3 pb-2">
          <div className="OfferingCardContent">
            {props.showDetailsLong ? (
              <MarkdownView text={props.offering.commonDetails.detailLong} />
            ) : (
              <MarkdownView text={props.offering.commonDetails.detailShort} />
            )}
            {props.offering.tags &&
              props.offering.tags.map((tag, index) => (
                <div className="badge badge-light mr-1 mt-2 px-2 py-2 align-middle">
                  {tag}
                </div>
              ))}
          </div>
          <div className="row mt-2 pt-3 pb-2">
            <div className="col"></div>
            <div className="col-auto">
              {offeringCommonDetails.bookingLimitPerUser &&
                offeringCommonDetails.bookingLimitPerUser.type ===
                  CountType.LIMITED &&
                offeringCommonDetails.bookingLimitPerUserConsumed !=
                  undefined && (
                  <span
                    className={`${
                      offeringCommonDetails.bookingLimitPerUserAvailable
                        ? "text-success"
                        : "text-danger"
                    } mr-2`}
                  >
                    {`${
                      offeringCommonDetails.bookingLimitPerUserConsumed ?? 0
                    } / ${
                      offeringCommonDetails.bookingLimitPerUser.value
                    } Consumed`}
                  </span>
                )}
              {offeringCommonDetails.bookingLimitPerUserAvailable && (
                <OfferingPricePill offering={props.offering} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
