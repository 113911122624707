import React from "react";
import { ImageComponent } from "../components/common/Image.component";
import { Company } from "../company/Company";

interface CompanyBadgeProps {
  className?: string;
  company: Company;
}

export class CompanyBadge extends React.Component<CompanyBadgeProps> {
  render() {
    return (
      <span
        className={"badge mr-1 px-1 bg-light-gray " + this.props.className}
        key={this.props.company.id}
      >
        {this.props.company.dp && (
          <ImageComponent
            image={this.props.company.dp}
            width={10}
            className="mr-1 mb-0_5"
          />
        )}

        {this.props.company.dl}
      </span>
    );
  }
}
