import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { CouponApplyResponse } from "../coupon/Coupon.model";
import { FormResponse } from "../form/model/form.model";
import { LinkHelpers } from "../helpers/Link.Helpers";
import { InclusionHelper } from "../inclusion/Inclusion.Helper";
import { Inclusion } from "../inclusion/Inclusion.Model";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { OrderResponseV1 } from "../model/OrderDetails";
import { AuthzAction } from "../model/enums/AuthzActions.enum";
import { FetchContext } from "../model/enums/FetchContext.enum";
import { Target } from "../model/target.model";
import { ShareableContent } from "../share/Share.model";
import { UserBasicDetails } from "../user/User";
import {
  IOfferingV2,
  OfferingCommonDetails,
  OfferingFetchContext,
  OfferingType,
  OfferingTypeName,
  Offerings,
} from "./Offering";
import { OfferingConstants } from "./Offering.Constants";

export class OfferingAPI {
  public static get(
    offeringType: OfferingTypeName,
    offeringId: string,
    fetchContext: OfferingFetchContext
  ): Api<void, IOfferingV2> {
    return new Api<void, IOfferingV2>(
      OfferingConstants.API_OFFERING_FETCH_SINGLE.replace(
        "{offeringType}",
        offeringType
      )
        .replace("{offeringId}", offeringId)
        .replace("{fetchContext}", fetchContext),
      ApiMethod.GET
    );
  }

  public static getCommonDetails(
    offeringId: string,
    fetchContext: OfferingFetchContext
  ): Api<void, OfferingCommonDetails> {
    return new Api<void, OfferingCommonDetails>(
      OfferingConstants.API_OFFERING_FETCH_SINGLE_COMMON_DETAILS.replace(
        "{offeringId}",
        offeringId
      ).replace("{fetchContext}", fetchContext),
      ApiMethod.GET
    );
  }

  public static getOfferings(
    userId: string,
    inclusion?: Inclusion
  ): Promise<Offerings> {
    let api = LinkHelpers.addQueryParam(
      apiLinkConstants.API_USER_OFFERINGS.replace("{userId}", userId),
      InclusionHelper.getQp(inclusion)
    );

    return new Api<void, Offerings>(api, ApiMethod.GET).execute();
  }

  public static getOfferingShareableImage(
    offering: IOfferingV2
  ): Promise<string> {
    let api = OfferingConstants.API_ENGINE_OFFERING_IMAGES_SHAREABLE.replace(
      "{offeringId}",
      offering.commonDetails.target.targetId
    ).replace("{offeringType}", offering.commonDetails.target.targetType);
    return new Api<void, string>(api, ApiMethod.GET).execute();
  }

  public static getOfferingShareableContent(
    offering: IOfferingV2
  ): Promise<ShareableContent> {
    let api = OfferingConstants.API_ENGINE_OFFERING_CONTENT_SHAREABLE.replace(
      "{offeringId}",
      offering.commonDetails.target.targetId
    ).replace("{offeringType}", offering.commonDetails.target.targetType);
    return new Api<void, ShareableContent>(api, ApiMethod.GET).execute();
  }

  public static publish(
    postedById: string,
    offeringType: OfferingType,
    offeringId: string
  ): Api<void, IOfferingV2> {
    let apiLink =
      OfferingConstants.API_ENGINE_OFFERING_STATUS_PUBLISH.replaceAll(
        "{postedById}",
        postedById
      )
        .replaceAll("{offeringType}", offeringType.typeName)
        .replaceAll("{offeringId}", offeringId);

    return new Api<void, IOfferingV2>(apiLink, ApiMethod.PUT);
  }

  public static unPublish(
    postedById: string,
    offeringType: OfferingType,
    offeringId: string
  ): Api<void, IOfferingV2> {
    let apiLink =
      OfferingConstants.API_ENGINE_OFFERING_STATUS_UNPUBLISH.replaceAll(
        "{postedById}",
        postedById
      )
        .replaceAll("{offeringType}", offeringType.typeName)
        .replaceAll("{offeringId}", offeringId);

    return new Api<void, IOfferingV2>(apiLink, ApiMethod.PUT);
  }

  public static getOfferingBogies(
    offeringType: AttachmentTargetType,
    offeringId: string
  ): Api<void, UserBasicDetails[]> {
    let api = OfferingConstants.API_USER_OFFERING_BOGIES.replace(
      "{offeringId}",
      offeringId
    ).replaceAll("{offeringType}", offeringType);

    return new Api<void, UserBasicDetails[]>(api, ApiMethod.GET);
  }

  static getFeedbackForms(
    offeringTarget: Target
  ): Api<{ excludeIfHasThisPermission?: AuthzAction[] }, FormResponse[]> {
    let link = OfferingConstants.API_OFFERING_FEEDBACK_FORM_LIST.replaceAll(
      "{offeringType}",
      offeringTarget.targetType
    ).replaceAll("{offeringId}", offeringTarget.targetId);

    return new Api<
      { excludeIfHasThisPermission?: AuthzAction[] },
      FormResponse[]
    >(link, ApiMethod.POST);
  }

  public static applyCoupon(
    offeringTarget: Target
  ): Api<any, CouponApplyResponse> {
    let api = OfferingConstants.API_OFFERING_COUPON_APPLY.replaceAll(
      "{offeringId}",
      offeringTarget.targetId
    ).replace("{offeringTargetType}", offeringTarget.targetType);
    return new Api<any, CouponApplyResponse>(api, ApiMethod.POST);
  }

  public static order(offeringTarget: Target): Api<any, OrderResponseV1> {
    let api = OfferingConstants.API_OFFERING_ORDER.replaceAll(
      "{offeringId}",
      offeringTarget.targetId
    ).replace("{offeringTargetType}", offeringTarget.targetType);
    return new Api<any, OrderResponseV1>(api, ApiMethod.POST);
  }

  public static add(
    offeringTargetType: AttachmentTargetType
  ): Api<IOfferingV2, IOfferingV2> {
    let apiLink = OfferingConstants.API_OFFERINGS_ADD.replace(
      "{offeringTargetType}",
      offeringTargetType
    );

    return new Api<IOfferingV2, IOfferingV2>(apiLink, ApiMethod.POST);
  }

  public static update(
    offeringTargetType: AttachmentTargetType,
    offeringId: string
  ): Api<IOfferingV2, IOfferingV2> {
    let apiLink = OfferingConstants.API_OFFERING_UPDATE.replaceAll(
      "{offeringTargetType}",
      offeringTargetType
    ).replaceAll("{offeringId}", offeringId);

    return new Api<IOfferingV2, IOfferingV2>(apiLink, ApiMethod.PUT);
  }

  public static list(
    engineId: string,
    fetchContext: FetchContext,
    offeringTargetType?: AttachmentTargetType
  ): Api<void, IOfferingV2[]> {
    let api = OfferingConstants.API_OFFERINGS_LIST;
    api = LinkHelpers.addQueryParam(api, {
      key: "engineId",
      value: engineId,
    });

    api = LinkHelpers.addQueryParam(api, {
      key: "fetchContext",
      value: fetchContext,
    });
    if (offeringTargetType) {
      api = LinkHelpers.addQueryParam(api, {
        key: "offeringTargetType",
        value: offeringTargetType,
      });
    }

    return new Api<void, IOfferingV2[]>(api, ApiMethod.GET);
  }
}
