import React, { useEffect, useState } from "react";
import { UrlHelpers } from "../../../helpers/Helpers";
import { WindowHelpers } from "../../../helpers/Window.helper";
import { PageProps } from "../../../model/views/PageProps";
import { Topic } from "../../../topic/Topic.model";
import { TopicAPI } from "../../../topic/TopicAPI";
import { CallerContext } from "../../common/CallerContext";
import { Post } from "./Charchaa.model";
import { CharchaaAddOrUpdatePostContent } from "./CharchaaAddOrUpdatePost.page-content";

export interface CharchaaAddPostPageContentProps extends PageProps {}
export function CharchaaAddPostPageContent(
  props: CharchaaAddPostPageContentProps
) {
  let callBack = (post: Post, callerContext: CallerContext): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const successMessage = "Post saved succesfully!!!";
      const redirectUrl = UrlHelpers.getPostDetailsUrl(
        post.url,
        successMessage
      );
      WindowHelpers.redirect(redirectUrl);
      resolve();
    });
  };

  let [topics, setTopics] = useState<Topic[]>();

  useEffect(() => {
    TopicAPI.getListingTopics().then((topics) => {
      setTopics(topics);
    });
  }, []);

  return (
    <div className="main-container CharchaaAddPostPageContent">
      {topics && props.user && (
        <>
          <h3>Add Post</h3>

          <div className="card">
            <div className="card-body">
              <CharchaaAddOrUpdatePostContent
                callerContext={{}}
                callback={callBack}
                allTopics={topics}
                user={props.user}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
