import React from "react";
import { HTMLProps } from "../model/HTMLProps";

export function TopExpertBadge(props: HTMLProps) {
  return (
    <span
      className={`p-2 badge bg-light-yellow border border-dark position-absolute  right-10 top--10 ${props.className}`}
    >
      <i className={`fas fa-crown mr-1`}></i>
      Top
    </span>
  );
}
