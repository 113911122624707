import React from "react";
import { EBButtonV2, EBButtonV2Props } from "./EBButtonV2";
import { EBModalV2 } from "./EBModalV2";

interface ConfirmationModalProps {
  buttonId: string;
  modalId: string;
  headerTitle?: string;
  primaryButtonProps: EBButtonV2Props;
  modalBody: React.ReactNode;
  closeButtonTitle?: string;
}

export function ConfirmationModal(props: ConfirmationModalProps) {
  return (
    <>
      <EBButtonV2
        id={props.buttonId}
        type={"button"}
        otherProps={{
          "data-bs-toggle": "modal",
          "data-bs-target": `#${props.modalId}`,
        }}
        className="d-none"
        rowClassName="d-none"
        content={{ normal: "" }}
      />
      <EBModalV2
        body={props.modalBody}
        id={props.modalId}
        header={props.headerTitle ?? "Are you sure?"}
        modalSize="md"
        primaryButtonProps={props.primaryButtonProps}
        closeButtonTitle={props.closeButtonTitle}
      ></EBModalV2>
    </>
  );
}
