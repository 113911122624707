import { PriceHelper } from "../components/common/price/Price.Helper";
import { FormTemplate } from "../form/model/formTemplate.model";
import { FormTemplateHelper } from "../form/template-builder/Form.Template.Helper";
import { Target } from "../model/target.model";
import { User, UserBasicDetails } from "../user/User";
import {
  IOfferingV2,
  OfferingCommonDetails,
  OfferingDigitalProduct,
  OfferingOption,
  OfferingOptionData,
  OfferingOptionDurationData,
  OfferingOptionSlaData,
  OfferingOptionType,
  OfferingTextQuery,
  OfferingTypeName,
  OfferingVideoSession,
  Offerings,
} from "./Offering";

export class OfferingHelper {
  public static getMergedList(offerings?: Offerings): IOfferingV2[] {
    let finalOfferings: IOfferingV2[] = [];
    if (offerings?.videoSessions) {
      finalOfferings = finalOfferings.concat(offerings.videoSessions);
    }

    if (offerings?.offlineQueries) {
      finalOfferings = finalOfferings.concat(offerings.offlineQueries);
    }

    if (offerings?.products) {
      finalOfferings = finalOfferings.concat(offerings.products);
    }

    if (offerings?.paths) {
      finalOfferings = finalOfferings.concat(offerings.paths);
    }
    return finalOfferings;
  }

  public static getInitialOffering(
    offeringType: OfferingTypeName,
    user: User
  ): IOfferingV2 {
    if (offeringType === OfferingTypeName.TEXT_QUERY) {
      return this.getInitialOfferingTq(offeringType, user);
    }

    if (offeringType === OfferingTypeName.VIDEO_SESSION) {
      return this.getInitialOfferingVs(offeringType, user);
    }

    if (offeringType === OfferingTypeName.DIGITAL_PRODUCT) {
      return this.getInitialOfferingDp(offeringType, user);
    }
    return this.getInitialOfferingDefault(offeringType, user);
  }

  public static getInitialOfferingDp(
    offeringType: OfferingTypeName,
    user: User
  ): IOfferingV2 {
    let optionData = {
      isEnabled: true,
      durationInSeconds: 60 * 60,
      price: PriceHelper.getDefaultOfferingPrice(user.basicDetails.currency),
    } as OfferingOptionData;

    let options: OfferingOption[] = [
      {
        data: optionData,
        type: OfferingOptionType.DEFAULT,
      },
    ];

    return {
      commonDetails: {
        type: { typeName: offeringType },
        price: PriceHelper.getDefaultOfferingPrice(user.basicDetails.currency),
        options: options,
      },
      attachments: [{}],
    } as OfferingDigitalProduct;
  }

  public static getInitialOfferingDefault(
    offeringType: OfferingTypeName,
    user: User
  ): IOfferingV2 {
    let optionData = {
      isEnabled: true,
      durationInSeconds: 60 * 60,
      price: PriceHelper.getDefaultOfferingPrice(user.basicDetails.currency),
    } as OfferingOptionData;

    let options: OfferingOption[] = [
      {
        data: optionData,
        type: OfferingOptionType.DEFAULT,
      },
    ];

    return {
      commonDetails: {
        type: { typeName: offeringType },
        price: PriceHelper.getDefaultOfferingPrice(user.basicDetails.currency),
        options: options,
      },
    } as IOfferingV2;
  }

  public static getInitialOfferingVs(
    offeringType: OfferingTypeName,
    user: User,
    linkedTarget?: Target
  ): IOfferingV2 {
    let optionData = {
      isEnabled: true,
      durationInSeconds: 60 * 60,
      price: PriceHelper.getDefaultOfferingPrice(user.basicDetails.currency),
    } as OfferingOptionDurationData;

    let options: OfferingOption[] = [
      {
        data: optionData,
        type: OfferingOptionType.DURATION,
      },
    ];

    return {
      commonDetails: {
        type: { typeName: offeringType },
        options: options,
        preBookingFormTemplate: {
          templateData:
            OfferingHelper.getInitialVsOfferingPrebookingFormTemplateData(),
        },
        linkedTarget: linkedTarget,
      } as OfferingCommonDetails,
      sessionType: { id: "other" },
    } as OfferingVideoSession;
  }

  public static getInitialOfferingTq(
    offeringType: OfferingTypeName,
    user: User
  ): IOfferingV2 {
    let optionData = {
      isEnabled: true,
      responseSlaInSecs: 2 * 24 * 60 * 60,
      price: PriceHelper.getDefaultOfferingPrice(user.basicDetails.currency),
    } as OfferingOptionSlaData;

    let options: OfferingOption[] = [
      {
        data: optionData,
        type: OfferingOptionType.SLA,
      },
    ];

    return {
      commonDetails: {
        type: { typeName: offeringType },
        options: options,
        preBookingFormTemplate: {
          templateData:
            OfferingHelper.getInitialTqOfferingPrebookingFormTemplateData(),
        },
      } as OfferingCommonDetails,
      sessionType: { id: "text_other" },
      textQuestionTitleForMentee: "",
    } as OfferingTextQuery;
  }

  public static getInitialVsOfferingPrebookingFormTemplateData(): FormTemplate {
    return {
      id: "",
      name: "",
      displayTitle: { view: "" },
      displayDescription: { view: "" },
      saveButtonDisplayTitle: "",
      editButtonDisplayTitle: "",
      sections: [
        {
          displayTitle: { view: "" },
          displayDescription: { view: "" },
          order: 1,
          name: "basic",
          lineItems: [
            FormTemplateHelper.getTextAreaInputLi(
              "agenda",
              {
                displayTitle: {
                  view: "What topics or objectives would you like to include in the agenda for this booking? Please provide a brief description?",
                },
                placeholder: "",
              },
              true
            ),
          ],
        },
      ],
    };
  }

  public static getInitialTqOfferingPrebookingFormTemplateData(): FormTemplate {
    return {
      id: "",
      name: "",
      displayTitle: { view: "" },
      displayDescription: { view: "" },
      saveButtonDisplayTitle: "",
      editButtonDisplayTitle: "",
      sections: [
        {
          displayTitle: { view: "" },
          displayDescription: { view: "" },
          order: 1,
          name: "basic",
          lineItems: [
            FormTemplateHelper.getTextAreaInputLi(
              "agenda",
              {
                displayTitle: {
                  view: "What would you like to ask?",
                },
                placeholder: "",
              },
              true
            ),
          ],
        },
      ],
    };
  }

  public static getInitialDefaultOfferingPrebookingFormTemplateData(): FormTemplate {
    return {
      id: "",
      name: "",
      displayTitle: { view: "" },
      displayDescription: { view: "" },
      saveButtonDisplayTitle: "",
      editButtonDisplayTitle: "",
      sections: [
        {
          displayTitle: { view: "" },
          displayDescription: { view: "" },
          order: 1,
          name: "basic",
          lineItems: [
            FormTemplateHelper.getTextAreaInputLi(
              "goal",
              {
                displayTitle: {
                  view: "What is your goal in mind?",
                },
                placeholder: "",
              },
              true
            ),
          ],
        },
      ],
    };
  }
}
