import { message } from "antd";
import React, { useState } from "react";
import { EBButtonV2 } from "../../../components/common/EBButtonV2";
import { Target } from "../../../model/target.model";
import { VideoApi } from "../../../video/Video.Api";
import { VideoBunnyCdn } from "../../../video/Video.Model";
import { VideoUploadComponent } from "../../../video/VideoUpload.Component";
import { ContentItemDataVideoUpload } from "../../Content.model";
import { ContentItemInputProps } from "../ContentItem.model";

export function ContentItemInputVideoUpload(props: ContentItemInputProps) {
  let data: ContentItemDataVideoUpload = props.item.data;
  let [reRenderCount, setRenderCount] = useState<number>(0);

  function reRender() {
    setRenderCount(reRenderCount + 1);
  }

  function updateVideoId(videoId: string) {
    let existingData = props.item.data as ContentItemDataVideoUpload;
    if (existingData) {
      existingData.videoId = videoId;
    } else {
      props.item.data = { videoId: videoId };
    }
    reRender();
  }
  return (
    <div className="ContentItemInputVideoUpload">
      <h6 className="m-2">Upload Video:</h6>
      {!data.videoId && (
        <>
          <ContentItemInputVideoUploadCreateVideo
            target={props.target}
            onVideoCreate={function (video: VideoBunnyCdn): Promise<void> {
              updateVideoId(video.id!);
              return props.onChange().then(props.onTriggerSave);
            }}
          />
        </>
      )}
      {data.videoId && (
        <>
          <VideoUploadComponent videoId={data.videoId} />
        </>
      )}
    </div>
  );
}

interface ContentItemInputVideoUploadCreateVideoProps {
  target: Target;
  onVideoCreate: (video: VideoBunnyCdn) => Promise<void>;
}

function ContentItemInputVideoUploadCreateVideo(
  props: ContentItemInputVideoUploadCreateVideoProps
) {
  let [videoDl, setVideoDl] = useState<string>("");
  let [submitting, setSubmitting] = useState<boolean>();

  function createVideo() {
    if (videoDl.trim() === "") {
      message.error("Valid video title is required.");
      return;
    }

    setSubmitting(true);
    VideoApi.createCollection()
      .execute(props.target)
      .then((videoCollectionResponse) => {
        return VideoApi.createVideo().execute({
          title: videoDl,
          collectionId: videoCollectionResponse.collectionId,
        });
      })
      .then((video) => {
        return props.onVideoCreate(video);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <div className="ContentItemInputVideoUploadCreateVideo hstack gap-2 m-2 mt-0">
      <input
        className="form-control"
        placeholder="Enter title of the video"
        required={true}
        onChange={(event: any) => {
          setVideoDl(event.target.value);
        }}
      />
      <EBButtonV2
        loading={submitting}
        content={{ normal: <>Create</>, submitting: <>Creating...</> }}
        onClick={() => {
          createVideo();
        }}
      />
    </div>
  );
}
