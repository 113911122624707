export interface Content {
  id?: string;
  items: ContentItem[];
}

export interface ContentItem {
  id?: string;
  type: ContentItemType;
  data: IContentItemData;
  displayOrder: number;
}

export enum ContentItemTypeEnum {
  MARKDOWN = "MARKDOWN",
  QUESTION = "QUESTION",
  YOUTUBE = "YOUTUBE",
  VIDEO_UPLOAD = "VIDEO_UPLOAD",
  OFFERING_VIDEO_SESSION = "OFFERING_VIDEO_SESSION",
  GROUP_CALL = "GROUP_CALL",
}

export interface ContentItemType {
  typeEnum: ContentItemTypeEnum;
  dl: string;
}

export interface IContentItemData {}

export interface ContentItemDataMarkdown extends IContentItemData {
  md: string;
}

export interface ContentItemDataYouTube extends IContentItemData {
  url: string;
}

export interface ContentItemDataQuestion extends IContentItemData {
  questionId: number;
}

export interface ContentItemDataOfferingVideoSession extends IContentItemData {
  offeringId?: string;
}

export interface ContentItemDataGroupCall extends IContentItemData {
  videoSessionId?: string;
}

export interface ContentItemDataVideoUpload extends IContentItemData {
  videoId?: string;
}

export interface ContentMetrics {
  paragraph?: number;
  word?: number;

  video?: number;
  liveCall?: number;

  questions?: number;

  dl?: string;
}
