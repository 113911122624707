import React from "react";
import { EBTextAreaV2 } from "../../components/common/Form/FormElements";
import {
  Mode,
  ModeBasedString,
} from "../../components/common/Form/model/mode.model";
import { TextViewComponent } from "../../components/common/TextView";
import { ModeHelpers } from "../../helpers/Mode.helper";
import { FormLineItemComponentProps } from "./FormLineItemComponent";

export interface FormLineItemInputTextAreaTemplateData {
  displayTitle: ModeBasedString;
  placeholder?: string;
}

interface FormLineItemInputTextAreaInputData {
  value: string;
}

interface FormLineItemInputTextAreaCustomValidations {
  maxLength?: number;
}

export function FormLineItemInputTextAreaComponent(
  props: FormLineItemComponentProps
) {
  let templateData =
    props.templateData as FormLineItemInputTextAreaTemplateData;
  let inputData = props.inputData as FormLineItemInputTextAreaInputData;
  let cusValidations =
    props.customValidationsData as FormLineItemInputTextAreaCustomValidations;

  return (
    <>
      {props.mode == Mode.EDIT && (
        <EBTextAreaV2
          name={props.name}
          maxLength={cusValidations?.maxLength ?? 5000}
          rows={3}
          required={props.required}
          placeholder={templateData.placeholder}
          label={ModeHelpers.getString(props.mode, templateData.displayTitle)}
          enablePerformance={true}
          showCount={cusValidations?.maxLength != undefined}
        />
      )}
      {props.mode == Mode.VIEW && inputData && (
        <div>
          <div className="bold">
            {ModeHelpers.getString(props.mode, templateData.displayTitle)}
          </div>
          <div>
            <TextViewComponent text={inputData.value} />
          </div>
        </div>
      )}
    </>
  );
}
