import { AxiosError } from "axios";

export interface ApiErrorData {
  message: string;
  status: number;
  errors?: SpringError[];
}

interface SpringError {
  defaultMessage?: string;
}

export class ApiErrorHelper {
  public static getDisplayErrorMessage(
    error: AxiosError<ApiErrorData>
  ): string {
    if (error && error.response) {
      let data = error.response.data;
      if (data && data.status === 400) {
        if (
          data.errors &&
          data.errors.length > 0 &&
          data.errors[0].defaultMessage
        ) {
          return data.errors[0].defaultMessage;
        }

        return data.message;
      }
    }

    if (error && error.response && error.response.status == 401) {
      return "You need to 'Login' to perform this ation";
    }

    if (error && error.response && error.response.status == 403) {
      return "You are not authorized";
    }

    if (error && error.response && error.response.status == 401) {
      return "You need to 'Login' to perform this ation";
    }

    if (error && error.response && error.response.status == 404) {
      return "Not found";
    }

    return "Error Occurred. Please try again!\n If error persists, please contact us through help section";
  }
}
