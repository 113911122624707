import React from "react";
import Moment from "react-moment";
import { admiApiLinkConstants } from "../../constants";
import { Question } from "../../question/Question.Model";
import { Api, ApiLinkedButton, ApiMethod } from "../common/ApiLinkedButton";
import { reloadWindowPromise } from "../common/reloadCallback";
import { QuestionsAPI } from "../../question/QuestionsAPI";
import { QuestionDl } from "../../question/Question.Dl";

interface AdminQuestionsListProps {
  questions: Question[];
}

export class AdminQuestionsList extends React.Component<AdminQuestionsListProps> {
  render() {
    return (
      <div className="">
        <h3 className="h3 mb-4 text-gray-800">Questions</h3>
        {this.props.questions.length > 0 ? (
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Title</th>
                <th scope="col">Created At</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {this.props.questions.map((question, index) => (
                <tr key={question.id}>
                  <th scope="row">
                    <a href={question.url} target="_blank" aria-current="true">
                      {question.id}
                    </a>
                  </th>
                  <td>
                    <QuestionDl ques={question} />
                  </td>
                  <td>
                    <Moment format="DD-MM-YYYY HH:mm">
                      {question.createdAt}
                    </Moment>
                  </td>
                  <td>
                    <div className="hstack gap-2">
                      {!question.approvedAt ? (
                        <ApiLinkedButton
                          api={QuestionsAPI.approve(question.id)}
                          uniqueKey={"question" + question.id + "-approve"}
                          callback={reloadWindowPromise}
                          buttonProps={{
                            text: {
                              normal: "Approve",
                              submitting: "Please Wait...",
                            },
                            type: "success",
                          }}
                        />
                      ) : (
                        <ApiLinkedButton
                          api={QuestionsAPI.disapprove(question.id)}
                          uniqueKey={"question" + question.id + "-disapprove"}
                          callback={reloadWindowPromise}
                          buttonProps={{
                            text: {
                              normal: "Disapprove",
                              submitting: "Please Wait...",
                            },
                            type: "danger",
                          }}
                        />
                      )}

                      {!question.archivedAt ? (
                        <ApiLinkedButton
                          uniqueKey={"question" + question.id + "-archive"}
                          api={QuestionsAPI.archive(question.id)}
                          callback={reloadWindowPromise}
                          buttonProps={{
                            text: {
                              normal: "Archive",
                              submitting: "Please Wait...",
                            },
                            type: "info",
                          }}
                        />
                      ) : (
                        <ApiLinkedButton
                          uniqueKey={"question" + question.id + "-unarchive"}
                          api={QuestionsAPI.unarchive(question.id)}
                          callback={reloadWindowPromise}
                          buttonProps={{
                            text: {
                              normal: "Unarchive",
                              submitting: "Please Wait...",
                            },
                            type: "info",
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>No data Found</>
        )}
      </div>
    );
  }
}
