import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { InterviewExperienceAPI } from "../api/InterviewExperienceAPI";
import { CommentsComponent } from "../components/common/comments/Comments.component";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { InterviewExperienceResponse } from "../model/InterviewExperience";
import { ReactMatch } from "../model/ReactMatchObj";
import { PageProps } from "../model/views/PageProps";
import { RelatedExpertsComponent } from "../promotion/RelatedExperts.Component";
import { ViewsManager } from "../reactions/Views.Manager";
import { InterviewExperienceDetails } from "./InterviewExperienceDetails";

interface InterviewExperienceDetailsPagePathParams {
  interviewExperienceId: number;
}

interface InterviewExperienceDetailsPageProps extends PageProps {
  match: ReactMatch<InterviewExperienceDetailsPagePathParams>;
}

export function InterviewExperienceDetailsPage(
  props: InterviewExperienceDetailsPageProps
) {
  let [ie, setIe] = useState<InterviewExperienceResponse>();
  useEffect(() => {
    InterviewExperienceAPI.fetchInterviewExperienceDetails(
      props.match.params.interviewExperienceId
    ).then((interviewExperience) => {
      setIe(interviewExperience);
    });

    ViewsManager.log({
      targetType: AttachmentTargetType.INTERVIEW_EXPERIENCE,
      targetId: props.match.params.interviewExperienceId + "",
    });
  }, []);

  return (
    <div className="main-container">
      <Row gutter={[15, 10]}>
        <Col xs={24} sm={24} md={14} lg={16} xxl={18}>
          {ie && (
            <>
              <InterviewExperienceDetails interviewExperience={ie} />
              <div className="mt-4">
                <CommentsComponent
                  dl="Discussion"
                  targetType={AttachmentTargetType.INTERVIEW_EXPERIENCE}
                  targetId={ie.id + ""}
                  loggedInuser={props.user}
                />
              </div>
            </>
          )}
        </Col>
        <Col xs={24} sm={24} md={10} lg={8} xxl={6}>
          {ie && (
            <>
              <RelatedExpertsComponent
                context={{
                  topics: [ie.company!, ie.designation!],
                }}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}
