import { QueryParam } from "../helpers/Link.Helpers";
import { Inclusion, UserInclusion } from "./Inclusion.Model";

export class InclusionHelper {
  public static getQp(inclusion?: Inclusion): QueryParam | undefined {
    if (!inclusion) {
      return undefined;
    }
    let val = "";
    function addToVal(subVal: string) {
      if (val.length != 0) {
        val += ",";
      }
      val += subVal;
    }
    if (inclusion.unpublished) {
      addToVal("unpublished:true");
    }

    if (inclusion.agenda) {
      addToVal("agenda:true");
    }

    if (inclusion.content) {
      addToVal("content:true");
    }

    if (inclusion.deleted) {
      addToVal("deleted:true");
    }

    if (inclusion.hidden) {
      addToVal("hidden:true");
    }

    if (inclusion.linked) {
      addToVal("linked:true");
    }

    if (inclusion.reviews) {
      addToVal("reviews:true");
    }

    if (inclusion.editPrice) {
      addToVal("editPrice:true");
    }

    return {
      key: "inclusion",
      value: val,
    };
  }

  public static getUserQp(inclusion?: UserInclusion): QueryParam | undefined {
    if (!inclusion) {
      return undefined;
    }
    let val = "";
    function addToVal(subVal: string) {
      if (val.length != 0) {
        val += ",";
      }
      val += subVal;
    }
    if (inclusion.homepageEnabled) {
      addToVal("homepageEnabled:true");
    }

    if (inclusion.mainListingPageEnabled) {
      addToVal("mainListingPageEnabled:true");
    }

    if (inclusion.excludeRelatedDisabled) {
      addToVal("excludeRelatedDisabled:true");
    }

    return {
      key: "inclusion",
      value: val,
    };
  }
}
