import React, { useState } from "react";
import ReactPlayer from "react-player";
import { TextInputV3 } from "../../../components/common/input/TextV3.Input";
import { ContentItemDataYouTube } from "../../Content.model";
import { ContentItemInputProps } from "../ContentItem.model";

export function ContentItemInputYouTubeVideo(props: ContentItemInputProps) {
  let [reRenderCount, setRenderCount] = useState<number>(0);

  function reRender() {
    setRenderCount(reRenderCount + 1);
  }
  function onChange(url: string) {
    let existingData = props.item.data as ContentItemDataYouTube;
    if (existingData) {
      existingData.url = url;
    } else {
      props.item.data = { url: url };
    }
    props.onChange();
    reRender();
  }

  let data = props.item.data as ContentItemDataYouTube;

  return (
    <div className="ContentItemInputYouTubeVideo">
      <TextInputV3
        className="form-control rounded-0"
        placeholder="Paste in YouTube video url https://youtube.com/..."
        onChange={onChange}
        value={data.url}
      />

      <ReactPlayer url={data.url} className="w-100" height={500} />
    </div>
  );
}
