import { useField } from "formik";
import React, { useState } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { FormInputLabel } from "../eb-ui-components";
import { FormikInputParams } from "../input/FormikInputParams.model";

export interface EBSelectOptionDataType {
  selectOptionValue: string;
}

export interface EBSelectOption<T extends EBSelectOptionDataType> {
  data: T;
  value: string;
  label: string;
}

interface EBSelectV2Props<T extends EBSelectOptionDataType>
  extends FormikInputParams {
  options: EBSelectOption<T>[];
  isMulti?: boolean;
  onCreate?: (input: string) => Promise<EBSelectOption<T>>;
  isDataEqual: (d1: T, d2: T) => boolean;
}

export const EBSelectV2 = <T extends EBSelectOptionDataType>(
  props: EBSelectV2Props<T>
) => {
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [options, setOptions] = useState<EBSelectOption<T>[]>(props.options);

  const [field, meta] = useField(props.name);

  function setFieldValue(value: T) {
    field.onChange({
      target: {
        name: props.name,
        value: value,
      },
    });
  }

  function setFieldValues(value: T[]) {
    field.onChange({
      target: {
        name: props.name,
        value: value,
      },
    });
  }

  const handleCreate = (inputValue: string) => {
    if (props.onCreate) {
      setIsCreating(true);
      props
        .onCreate(inputValue)
        .then((newOption) => {
          setIsCreating(false);
          setOptions([...options, newOption]);
          if (props.isMulti) {
            if (field.value) {
              setFieldValues([...field.value, newOption.data]);
            } else {
              setFieldValues([newOption.data]);
            }
          } else {
            setFieldValue(newOption.data);
          }
        })
        .catch((error) => {
          setIsCreating(false);
        });
    }
  };

  function apiToSelect(apiOption: T) {
    if (!apiOption) {
      return apiOption;
    }
    for (let option of options) {
      if (props.isDataEqual && props.isDataEqual(option.data, apiOption)) {
        return { label: option.label, value: option.data.selectOptionValue };
      }
      if (option.data.selectOptionValue == apiOption.selectOptionValue) {
        return { label: option.label, value: option.data.selectOptionValue };
      }
    }

    return "";
  }

  function selectToApi(selectOptionData: EBSelectOption<T>) {
    for (let option of options) {
      if (option.data.selectOptionValue == selectOptionData.value) {
        return option.data;
      }
    }
    return selectOptionData.data;
  }

  const onChange = (e: any) => {
    if (props.isMulti) {
      setFieldValues(e.map(selectToApi));
    } else {
      setFieldValue(selectToApi(e));
    }
  };

  function getValue() {
    return props.isMulti && field.value
      ? field.value.map(apiToSelect)
      : apiToSelect(field.value);
  }

  return (
    <>
      <div className="EBSelectV2">
        {props.label && (
          <FormInputLabel
            label={props.label}
            name={props.name}
            required={props.required}
            disabled={props.disabled}
          />
        )}
        {props.onCreate && (
          <Creatable
            {...field}
            {...props}
            options={options}
            onChange={onChange}
            value={getValue()}
            required={props.required}
            isDisabled={props.disabled || isCreating}
            isLoading={isCreating}
            onCreateOption={handleCreate}
          />
        )}
        {!props.onCreate && (
          <Select
            {...field}
            {...props}
            options={options}
            onChange={onChange}
            value={getValue()}
            required={props.required}
            isDisabled={props.disabled || isCreating}
            isLoading={isCreating}
          />
        )}
        {props.helpText ? (
          <small className="form-text text-muted">{props.helpText}</small>
        ) : null}
      </div>

      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
