import { Col, Row } from "antd";
import { useField } from "formik";
import React, { useState } from "react";
import { Label } from "./elements/FormInputLabel";

interface EBTextAreaV2Props {
  label: string;
  name: string;

  maxLength?: number;
  minLength?: number;
  rows?: number;
  placeholder?: string;
  helpText?: string;
  required?: boolean;
  disabled?: boolean;

  showCount?: boolean;
  enablePerformance?: boolean;

  className?: string;
}

export const EBTextAreaV2 = ({
  helpText,
  label,
  ...props
}: EBTextAreaV2Props) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);

  /**
   * For performance reasons (possible due to CSS in JS issues), heavy views
   * affect re-renders (Formik changes state in every re-render), bringing keyboard
   * input to its knees. To control this, we create a setState that handles the field's inner
   * (otherwise you wouldn't be able to type) and then propagate the change to Formik onBlur and
   * onFocus.
   */
  const [fieldValue, setFieldValue] = useState<string | number>(field.value);

  const onChange = (evt: any) => {
    setFieldValue(evt.target.value);
  };
  const onBlur = (evt: any) => {
    field.onChange({
      target: {
        name: props.name,
        value: evt.target.value || "",
      },
    });
  };

  // Will set depending on the performance props
  const performanceProps = props.enablePerformance
    ? {
        ...field,
        value: fieldValue,
        onChange,
        onBlur,
        onFocus: onBlur,
      }
    : field;

  return (
    <>
      <div className={props.className}>
        <Label label={label} required={props.required} />
        {meta.touched && meta.error ? (
          <div>
            <span className="text-danger">{meta.error}</span>
          </div>
        ) : null}

        <textarea
          className="form-control"
          {...performanceProps}
          maxLength={props.maxLength}
          minLength={props.minLength}
          rows={props.rows}
          required={props.required}
          disabled={props.disabled}
          placeholder={props.placeholder}
        />
        <Row>
          <Col flex={"auto"}>
            {helpText ? (
              <small className="form-text text-muted">{helpText}</small>
            ) : null}
          </Col>
          <Col>
            {props.showCount && (
              <>
                <small className="form-text text-muted">
                  {fieldValue ? fieldValue.toLocaleString().length : 0}
                  {props.maxLength != undefined && `/${props.maxLength}`}
                </small>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
