import { CloseCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { HTMLProps } from "../../model/HTMLProps";

interface OptionsSelectProps<T> extends HTMLProps {
  options: T[];
  onSelectionUpdate: (selectedOptions: T[]) => Promise<void>;
  isEqual: (a: T, b: T) => boolean;
  render: (option: T) => React.ReactNode;
  initialSelectedOptions?: T[];
  compact?: boolean;
  gap?: "1" | "2" | "3" | "4" | "5";
  isSingle?: boolean;
  normalOpClassName?: string;
  selectedOpClassName?: string;
}

export function OptionsSelect<T>(props: OptionsSelectProps<T>) {
  let [selectedOptions, setSelectedOptions] = useState<T[]>(
    props.initialSelectedOptions ?? []
  );
  useEffect(() => {
    setSelectedOptions(props.initialSelectedOptions ?? []);
  }, [props.initialSelectedOptions]);
  function isSelected(option: T) {
    return selectedOptions.some((selectedOp) =>
      props.isEqual(option, selectedOp)
    );
  }

  function onOptionClicked(optionClicked: T) {
    let updatedOps = [];
    if (props.isSingle) {
      updatedOps.push(optionClicked);
    } else {
      if (isSelected(optionClicked)) {
        for (let selectedOp of selectedOptions) {
          if (!props.isEqual(selectedOp, optionClicked)) {
            updatedOps.push(selectedOp);
          }
        }
      } else {
        updatedOps = [...selectedOptions, optionClicked];
      }
    }
    updatedOps = updatedOps.filter((op) =>
      props.options.some((propOp) => props.isEqual(propOp, op))
    );
    setSelectedOptions(updatedOps);
    props.onSelectionUpdate(updatedOps);
  }

  return (
    <div className={`OptionsSelect ${props.className}`}>
      <div className={`overflow-auto `}>
        <div className={`hstack gap-${props.gap ?? 2}`}>
          {props.options.map((option, index) => (
            <div
              key={index}
              onClick={() => onOptionClicked(option)}
              className={`cursor-pointer border border-1  ${
                props.compact ? " px-2 py-1 " : " px-3 py-2 "
              } rounded-pill ${
                isSelected(option)
                  ? ` bold border-dark bg-dark text-white ${props.selectedOpClassName}`
                  : `bg-white text-dark ${props.normalOpClassName}`
              }`}
            >
              <div className="text-nowrap">
                {isSelected(option) && !props.isSingle && (
                  <CloseCircleOutlined className="mr-1" />
                )}
                {props.render(option)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
