import React from "react";
import { InputProps } from "./Input.model";

export interface TextV3InputProps extends InputProps {
  prependNode?: React.ReactNode;
  appendNode?: React.ReactNode;
}

export function TextInputV3(props: TextV3InputProps) {
  const onChange = (event: any) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  function onPressEnter() {
    if (props.onPressEnter) {
      props.onPressEnter();
    }
  }

  return (
    <>
      {props.prependNode || props.appendNode ? (
        <div className="input-group">
          {props.prependNode}
          <input
            initialValue={props.initialValue}
            value={props.value}
            className="form-control"
            type="text"
            {...props}
            onChange={onChange}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                onPressEnter();
              }
            }}
          />
          {props.appendNode}
        </div>
      ) : (
        <input
          initialValue={props.initialValue}
          value={props.value}
          className="form-control"
          type="text"
          {...props}
          onChange={onChange}
          onKeyUp={(event) => {
            if (event.key === "Enter") {
              onPressEnter();
            }
          }}
        />
      )}
      {props.helpText && (
        <small className="form-text text-muted">{props.helpText}</small>
      )}

      {props.error && <div className="error">{props.error}</div>}
    </>
  );
}
