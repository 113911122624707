import React from "react";
import { publicLinkConstants } from "../constants";
import { PublicPageRoute } from "../routes/PublicPage.route";
import { AddQuestionPageContent } from "./AddQuestionPage";
import { ListQuestionsPageContent } from "./ListQuestionsPageContent";
import { QuestionDetailPage } from "./QuestionDetailPage";
import { UpdateQuestionPageContent } from "./UpdateQuestionPageContent";

export function QuestionRoutes() {
  return (
    <>
      <PublicPageRoute
        path={publicLinkConstants.QUESTIONS}
        contentComponent={ListQuestionsPageContent}
      />
      <PublicPageRoute
        path={publicLinkConstants.QUESTION_DETAILS}
        contentComponent={QuestionDetailPage}
      />
      <PublicPageRoute
        exact
        path={publicLinkConstants.QUESTION_ADD}
        contentComponent={AddQuestionPageContent}
      />
      <PublicPageRoute
        exact
        path={publicLinkConstants.QUESTION_EDIT}
        contentComponent={UpdateQuestionPageContent}
      />
    </>
  );
}
