import { Form, Formik } from "formik";
import React, { useState } from "react";
import { InterviewLoopAPI } from "../../../api/InterviewLoopAPI";
import { UserHelpers } from "../../../helpers/Helpers";
import { User } from "../../../user/User";
import { CallerContext } from "../CallerContext";
import { SearchUserView } from "../SearchUserView";
import { EBTextInput } from "../eb-ui-components";

interface AddInterviewLoopFormProps {
  callback: (callerContext: CallerContext) => void;
  companyId: string;
}

function CreateLoopWithNewUser(props: AddInterviewLoopFormProps) {
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        InterviewLoopAPI.createInterviewLoop(props.companyId, values)
          .then(() => {
            actions.setSubmitting(false);
            props.callback({});
          })
          .catch((err) => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formProps) => (
        <Form>
          <div className="row">
            <div className="col">
              <EBTextInput
                label="First Name"
                name="user.firstName"
                placeholder="First name of user"
                helpText=""
                required
              />
            </div>

            <div className="col">
              <EBTextInput
                label="Last Name"
                name="user.lastName"
                placeholder="Last name of user"
                helpText=""
                required
              />
            </div>

            <div className="col">
              <EBTextInput
                label="Email"
                name="user.email"
                placeholder="Email of user"
                helpText=""
                required
              />
            </div>

            <div className="col">
              <EBTextInput
                label="Phone"
                name="user.phone"
                placeholder="Phone of the user"
                helpText=""
                required
              />
            </div>
          </div>

          <button
            className="btn btn-primary"
            type="submit"
            disabled={formProps.isSubmitting}
          >
            {formProps.isSubmitting
              ? "Creating. Please Wait..."
              : "Create Loop"}
          </button>
        </Form>
      )}
    </Formik>
  );
}

export function AddInterviewLoopForm(mainProps: AddInterviewLoopFormProps) {
  let [currentCandidate, setCurrentCandidate] = useState<User>({
    basicDetails: { id: "" },
  });

  function UserRow(props: { user: User }) {
    return (
      <li className="list-group-item">
        <Formik
          initialValues={{}}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            InterviewLoopAPI.createInterviewLoop(mainProps.companyId, {
              candidateUserId: props.user.basicDetails.id,
            })
              .then(() => {
                actions.setSubmitting(false);
                mainProps.callback({});
              })
              .catch((err) => {
                actions.setSubmitting(false);
              });
          }}
        >
          {(formProps) => (
            <Form>
              <button
                className="btn btn-sm btn-primary mr-2"
                type="submit"
                disabled={formProps.isSubmitting}
              >
                {formProps.isSubmitting
                  ? "Creating. Please Wait..."
                  : "Create Loop"}
              </button>
              {UserHelpers.getUserFullName(props.user)} (
              {props.user.contactDetails?.email})
            </Form>
          )}
        </Formik>
      </li>
    );
  }

  return (
    <div className="card">
      <div className="card-header">
        <h5>Create new interview loop for a candidate</h5>
      </div>
      <div className="card-body">
        <p>
          <button
            className="btn btn-primary mr-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#multiCollapseExample1"
            aria-expanded="false"
            aria-controls="multiCollapseExample1"
          >
            Add new loop by searching existing user
          </button>
          <button
            className="btn btn-primary"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#multiCollapseExample2"
            aria-expanded="false"
            aria-controls="multiCollapseExample2"
          >
            Add new loop by adding new candidate
          </button>
        </p>
        <div className="collapse multi-collapse" id="multiCollapseExample1">
          <div className="row">
            <div className="col">
              <div className="card card-body">
                Search existing user here usig email:
                <SearchUserView callerContext={{}} RowItemComponent={UserRow} />
              </div>
            </div>
          </div>
        </div>
        <div className="collapse multi-collapse" id="multiCollapseExample2">
          <div className="row">
            <div className="col">
              <div className="card card-body">
                Schedule with new user (Will only work if email is new. Please
                search for the existing user using email first.):
                <CreateLoopWithNewUser {...mainProps} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
