import React from "react";
import { TimestampView } from "../../components/common/TimestampView";
import { UserLink } from "../../user/User.link";
import { Withdrawal, WithdrawalStatus } from "./Withdrawal.model";
import { PriceView } from "../../components/common/Price.view";

interface WithdrawalListComponentProps {
  withdrawals: Withdrawal[];
}

export function WithdrawalListComponent(props: WithdrawalListComponentProps) {
  return (
    <div className="WithdrawalListComponent">
      {props.withdrawals.map((withdrawal, index) => (
        <WithdrawalListItemComponent key={index} withdrawal={withdrawal} />
      ))}
    </div>
  );
}

interface WithdrawalListItemComponentProps {
  withdrawal: Withdrawal;
}

export function WithdrawalListItemComponent(
  props: WithdrawalListItemComponentProps
) {
  return (
    <div
      className={`WithdrawalListItemComponent card card-body mb-2 border-left-${
        props.withdrawal.status == WithdrawalStatus.PAID && "success"
      } border-left-${
        props.withdrawal.status == WithdrawalStatus.PENDING && "warning"
      } shadow`}
    >
      <div className={`row gy-2`}>
        <div className="col-md-3">
          Created At: <TimestampView timestamp={props.withdrawal.createdAt} />
        </div>
        <div className="col-md-1">{props.withdrawal.status}</div>

        <div className="col-md-1">
          <PriceView price={props.withdrawal.paymentAmount} />
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-2">
          <UserLink user={props.withdrawal.user} />
        </div>
        <div className="col-md-2">
          {props.withdrawal.paymentDestination?.type}{" "}
          {props.withdrawal.paymentDestination?.data}
        </div>
      </div>
    </div>
  );
}
