export class ArrayHelper {
  public static move(arr: any[], fromIndex: number, toIndex: number) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  public static remove(arr: any[], itemToRemove: any) {
    const index = arr.indexOf(itemToRemove);
    if (index > -1) {
      // only splice array when item is found
      arr.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  public static removeByIndex(arr: any[], index: number) {
    if (index > -1) {
      // only splice array when item is found
      arr.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  public static insertAtIndex(arr: any[], item: any, index: number) {
    arr.splice(index, 0, item);
  }
}
