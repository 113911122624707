import {
  DigitalProductDl,
  PathDl,
  TextQueryDl,
  VideoSessionDl,
} from "../Global.Names";
import { WindowHelpers } from "../helpers/Window.helper";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { AssetLocation } from "../model/Picture";

export interface TargetType {
  type: AttachmentTargetType;
  dl: React.ReactNode;
  dp?: AssetLocation;
}

export class TargetTypeHelper {
  static targets: TargetType[] = [
    {
      type: AttachmentTargetType.VIDEO_SESSION,
      dl: VideoSessionDl.SENTENCE_START,
    },
    {
      type: AttachmentTargetType.TEXT_QUERY,
      dl: TextQueryDl.SENTENCE_START,
    },
    {
      type: AttachmentTargetType.DIGITAL_PRODUCT,
      dl: DigitalProductDl.SENTENCE_START,
    },
    {
      type: AttachmentTargetType.BOOKING_PATH,
      dl: PathDl.SENTENCE_START,
    },
  ];

  static get(type: AttachmentTargetType): TargetType {
    for (let target of this.targets) {
      if (target.type == type) {
        return target;
      }
    }

    throw new Error("Unexpected");
  }

  static bookingListFilterList(): TargetType[] {
    return [
      this.get(AttachmentTargetType.VIDEO_SESSION),
      this.get(AttachmentTargetType.TEXT_QUERY),
      this.get(AttachmentTargetType.DIGITAL_PRODUCT),
      this.get(AttachmentTargetType.BOOKING_PATH),
    ];
  }

  static listFromQParam(
    qParamKey: string,
    delimiter: string
  ): TargetType[] | undefined {
    let str: string = WindowHelpers.qParam(qParamKey);
    if (!str) {
      return undefined;
    }

    return str
      .split(delimiter)
      .map((type) => this.get(type as AttachmentTargetType));
  }
}
