import React from "react";
import { Center } from "../../components/common/layout/Center.component";
import { BottomFixedComponent } from "../../layout/BottomFixed.Component";
import { LeftAndMainLayout } from "../../layout/LeftAndMain.Layout";
import { OfferingDetailPageLayoutProps } from "./Offering.DetailPage.Layout.Model";

export function OfferingDetailPageDefaultLayout(
  props: OfferingDetailPageLayoutProps
) {
  return (
    <>
      <div className="OfferingDetailPageDefaultLayout">
        <LeftAndMainLayout
          gutter={[15, 15]}
          left={props.sideBarTopContent}
          main={
            <>
              {props.headerContent}
              <div>{props.mainContent}</div>
              <div>{props.reviewContent}</div>
            </>
          }
        />

        <div className="event-data-mobile">
          <BottomFixedComponent className="bg-main-body">
            <div className="py-3 bg-dark shimmer">
              <Center>{props.registrationContent}</Center>
            </div>
          </BottomFixedComponent>
        </div>
      </div>
    </>
  );
}
