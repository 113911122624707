import React from "react";
import { DeleteIcon } from "../components/common/Delete.Icon";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { HTMLProps } from "../model/HTMLProps";
import { SaveIcon } from "../icon/Save.Icon";

interface SaveButtonProps extends HTMLProps {
  submitting?: boolean;
}

export function SaveButton(props: SaveButtonProps) {
  return (
    <EBButtonV2
      loading={props.submitting}
      content={{
        normal: (
          <>
            <SaveIcon /> Save
          </>
        ),
        submitting: (
          <>
            <SaveIcon /> Saving...
          </>
        ),
      }}
      {...props}
      type="submit"
    />
  );
}
