import React from "react";
import { Route } from "react-router";
import { navbarLinks } from "../App";
import NormalNavBar from "../components/pages/NormalNavBar";
import { PageNotFoundPageContent } from "../components/pages/PageNotFound.PageContent";
import PublicPage from "../components/pages/PublicPage";
import { publicLinkConstants } from "../constants";
import { BecomeExpertPageContent } from "../expert-onboarding/BecomeExpert.PageContent";
import { FindExpertContent } from "../user/listing/FindExpertContent";
import { PublicPageRoute } from "./PublicPage.route";

function PageNotFoundPage(props: any) {
  return (
    <PublicPage
      contentComponent={PageNotFoundPageContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

export function GeneralRoutes() {
  return (
    <>
      <Route exact path={"/error"} component={PageNotFoundPage} />
      <PublicPageRoute
        exact
        path={publicLinkConstants.BECOME_EXPERT}
        contentComponent={BecomeExpertPageContent}
      />

      <PublicPageRoute
        exact
        path={publicLinkConstants.FIND_EXPERTS}
        contentComponent={FindExpertContent}
      />
    </>
  );
}
