import React from "react";
import { HTMLProps } from "../../model/HTMLProps";

export function ArrowUpIcon(props: HTMLProps) {
  return (
    <>
      <i className={`fa-solid fa-arrow-up ${props.className}`}></i>
    </>
  );
}
