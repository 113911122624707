import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import {
  CouponInstance,
  CouponInstanceStatus,
  UserCoupon,
} from "./Coupon.model";

export class CouponApi {
  public static getUserCoupons(userId: string): Promise<UserCoupon[]> {
    return new Api<void, UserCoupon[]>(
      apiLinkConstants.API_USER_COUPONS_FETCH.replace("{userId}", userId),
      ApiMethod.GET
    ).execute();
  }

  public static addCoupon(
    userId: string,
    inputCoupon: UserCoupon
  ): Promise<UserCoupon> {
    return new Api<UserCoupon, UserCoupon>(
      apiLinkConstants.API_USER_COUPON_ADD.replace("{userId}", userId),
      ApiMethod.POST
    ).execute(inputCoupon);
  }

  public static updateCoupon(
    userId: string,
    inputCoupon: UserCoupon
  ): Promise<UserCoupon> {
    return new Api<UserCoupon, UserCoupon>(
      apiLinkConstants.API_USER_COUPON_UPDATE.replace(
        "{userId}",
        userId
      ).replace("{couponCode}", inputCoupon.couponCode),
      ApiMethod.PUT
    ).execute(inputCoupon);
  }

  public static deleteCoupon(
    userId: string,
    couponCode: string
  ): Promise<void> {
    return new Api<void, void>(
      apiLinkConstants.API_USER_COUPON_DELETE.replace(
        "{userId}",
        userId
      ).replace("{couponCode}", couponCode),
      ApiMethod.DELETE
    ).execute();
  }

  public static getCouponInstances(
    userId: string,
    couponCode: string,
    ciStatuses: CouponInstanceStatus[]
  ): Promise<CouponInstance[]> {
    return new Api<void, CouponInstance[]>(
      apiLinkConstants.API_USER_COUPON_INSTANCES_FETCH.replaceAll(
        "{userId}",
        userId
      )
        .replaceAll("{couponCode}", couponCode)
        .replaceAll("{statuses}", ciStatuses.join(",")),
      ApiMethod.GET
    ).execute();
  }
}
