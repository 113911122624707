import { Col, Row } from "antd";
import React from "react";
import Moment from "react-moment";
import { Skill } from "../model/Skill";
import { Company } from "./Company";
import { CompanyImage } from "./Company.Image";
import { CompanyHelper } from "./Company.Helper";

interface CompanyListItemProps {
  company: Company;
  showAdminActions?: boolean;
}

export function CompanyListItem(props: CompanyListItemProps) {
  let company = props.company;
  return (
    <div className={`CompanyListItem`}>
      <Row gutter={[16, 10]}>
        <Col span={4}>{company.id}</Col>
        <Col span={4}>{company.dl}</Col>

        <Col span={4}>
          <CompanyImage width={30} company={company} />
        </Col>
        <Col span={4}>
          {company.liUrl && <a href={company.liUrl}>LinkedIn</a>}
          {!company.liUrl && <>LinkedIn Not Found</>}
        </Col>

        {props.showAdminActions && (
          <Col span={8}>
            <a href={CompanyHelper.getEditUrl(company)} target="_blank">
              Edit
            </a>
          </Col>
        )}
      </Row>
    </div>
  );
}
