import { WindowHelpers } from "../helpers/Window.helper";
import { BookingStatusEnumId } from "./Booking.model";

export interface FilterStatus {
  status: BookingStatusEnumId;
  dl: string;
}

export class BookingStatusHelper {
  static statuses: FilterStatus[] = [
    { status: BookingStatusEnumId.PENDING_SCHEDULE, dl: "Pending Schedule" },
    { status: BookingStatusEnumId.SCHEDULED, dl: "Scheduled" },
    { status: BookingStatusEnumId.PENDING_COMPLETE, dl: "Pending Complete" },
    { status: BookingStatusEnumId.COMPLETED, dl: "Completed" },
    { status: BookingStatusEnumId.CANCELLED, dl: "Cancelled" },
  ];

  static list(): FilterStatus[] {
    return this.statuses;
  }

  static get(statusEnumId: BookingStatusEnumId): FilterStatus {
    for (let status of this.statuses) {
      if (status.status == statusEnumId) {
        return status;
      }
    }

    throw new Error("Unexpected");
  }

  static listFromQParam(
    qParamKey: string,
    delimiter: string
  ): FilterStatus[] | undefined {
    let str: string = WindowHelpers.qParam(qParamKey);
    if (!str) {
      return undefined;
    }

    return str
      .split(delimiter)
      .map((status) => this.get(status as BookingStatusEnumId));
  }
}
