import { Card, Col, Row } from "antd";
import React from "react";
import { HomePageSection } from "./HomePage.Section";

export function HomePageStatsSection() {
  return (
    <HomePageSection
      id="home-page-stats"
      className="HomePageStatsSection bg-main-body"
      headingStartIcon={<i className="fa-solid fa-rocket"></i>}
      heading={<> Impacting Success One Session at a Time</>}
    >
      <Row className="text-center" justify={"center"}>
        {[
          {
            metric: "250+",
            dl: "Top Experts",
          },
          {
            metric: "5K+",
            dl: "Happy Users",
          },
          {
            metric: <>5/5</>,
            dl: (
              <>
                <i className="fas fa-star text-warning"></i> Rating
              </>
            ),
          },
          {
            metric: "100K+",
            dl: "Mentoring Minutes",
          },
        ].map((item, index) => (
          <Col className="home-page-stat-card pb-4 px-2" key={index}>
            <Card
              style={{
                width: "300px",
                height: "300px",
                backgroundColor: "rgba(256, 256, 256, .5)",
              }}
              className="rounded-5 shadow border"
            >
              <div className="mt-5 h1 fs-75">{item.metric}</div>
              <div className="fs-30 mt-4  text-secondary">{item.dl}</div>
            </Card>
          </Col>
        ))}
      </Row>
    </HomePageSection>
  );
}
