import React from "react";
import { HTMLProps } from "../model/HTMLProps";
import { Skill } from "../model/Skill";
import { BadgeDesignLightYellow } from "./Badge.Design";

interface SkillBadgeProps extends HTMLProps {
  skill: Skill;
}

export function SkillBadge(props: SkillBadgeProps) {
  let skill = props.skill;
  return <BadgeDesignLightYellow {...props}>{skill.dl}</BadgeDesignLightYellow>;
}
