import axios from "axios";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { FormResponse } from "./model/form.model";

export class FormAPI {
  public static getFormApi(formId: string): Api<void, FormResponse> {
    return new Api<void, FormResponse>(
      apiLinkConstants.FORM_FETCH.replace("{formId}", formId),
      ApiMethod.GET
    );
  }

  public static getForm(formId: string): Promise<FormResponse> {
    return new Promise<FormResponse>((resolve, reject) => {
      const apiLink = apiLinkConstants.FORM_FETCH.replace("{formId}", formId);
      return axios
        .get(apiLink)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {});
    });
  }

  public static saveApi(formId: string): Api<any, void> {
    return new Api<any, void>(
      apiLinkConstants.FORM_DATA_SAVE.replace("{formId}", formId),
      ApiMethod.PUT
    );
  }

  public static saveFormData(formId: string, formData: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const apiLink = apiLinkConstants.FORM_DATA_SAVE.replace(
        "{formId}",
        formId
      );
      return axios
        .put(apiLink, formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
