import { useField } from "formik";
import React from "react";
import { DeleteButton } from "../button/Delete.Button";
import { Label } from "../components/common/Form/elements/FormInputLabel";
import { SkillSelector } from "../components/common/SkillSelector.component";
import { FormikHiddenInput } from "../components/common/input/FormikHiddentInput";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { FormikSwitchInput } from "../components/common/input/FormikSwitch.input";
import { ListFormikInput } from "../components/common/input/List.FormikInput";
import { EditPriceComponent } from "../components/common/price/EditPrice.component";
import { PriceHelper } from "../components/common/price/Price.Helper";
import { EditTimeDurationFormikInput } from "../duration/EditTimeDuration.FormikInput";
import { TimeDurationOptionGranularity } from "../duration/TimeDuration.FormikInput";
import { Skill } from "../model/Skill";
import { User, UserBasicDetails } from "../user/User";
import { OfferingOption, OfferingOptionType, SessionType } from "./Offering";

interface EditOfferingOptionsComponentProps extends FormikInputParams {
  offeringSessionTypeFieldName: string;
  skills: Skill[];
  sessionTypes: SessionType[];
  disabled?: boolean;
  user: User;
  hideAddBtn?: boolean;
}

export function EditOfferingOptionsComponent(
  props: EditOfferingOptionsComponentProps
) {
  let [field] = useField(props.name);
  let currentOptions = field.value as OfferingOption[];
  let [sessionTypeField] = useField(props.offeringSessionTypeFieldName);
  let inputOfferingSessionType = sessionTypeField.value as SessionType;

  let matchingSessionTypes = props.sessionTypes.filter(
    (sessionType) =>
      inputOfferingSessionType && sessionType.id === inputOfferingSessionType.id
  );
  let offeringOptionType: OfferingOptionType | undefined =
    matchingSessionTypes.length > 0
      ? matchingSessionTypes[0].offeringOptionType
      : OfferingOptionType.DEFAULT;
  if (currentOptions && offeringOptionType) {
    for (let option of currentOptions) {
      option.type = offeringOptionType;
    }
  }
  return (
    <>
      {offeringOptionType && (
        <ListFormikInput
          minElementsBeforeAllowingDelete={1}
          emptyNode="No options"
          name={props.name}
          hideSeparateDeleteButton={true}
          hideAddBtn={props.hideAddBtn}
          itemNode={(
            itemProps: FormikInputParams,
            onDelete?: () => Promise<void>
          ) => {
            return (
              <>
                {offeringOptionType && (
                  <EditOfferingOptionFormikInput
                    {...itemProps}
                    offeringOptionType={offeringOptionType}
                    skills={props.skills}
                    user={props.user}
                    onDelete={onDelete}
                  />
                )}
              </>
            );
          }}
          getNewItem={() => {
            return {
              type: offeringOptionType,
              data: {
                isEnabled: true,
                price: PriceHelper.getDefaultOfferingPrice(
                  props.user.basicDetails.currency
                ),
              },
            };
          }}
        />
      )}
    </>
  );
}

interface EditOfferingOptionFormikInputProps extends FormikInputParams {
  offeringOptionType: OfferingOptionType;
  name: string;
  skills: Skill[];
  disabled?: boolean;
  user: User;
  onDelete?: () => Promise<void>;
}

function EditOfferingOptionFormikInput(
  props: EditOfferingOptionFormikInputProps
) {
  let offeringOptionType = props.offeringOptionType;
  let [field] = useField(props.name);
  let option = field.value as OfferingOption;
  return (
    <>
      <div className="bg-light p-2">
        <FormikHiddenInput
          name={`${props.name}.type`}
          value={offeringOptionType}
        />

        {offeringOptionType === OfferingOptionType.DURATION_SKILL && (
          <EditOfferingOptionDurationSkill
            name={`${props.name}.data`}
            skills={props.skills}
            disabled={props.disabled || !option.data.isEnabled}
            user={props.user}
          />
        )}
        {offeringOptionType === OfferingOptionType.DURATION && (
          <EditOfferingOptionDuration
            name={`${props.name}.data`}
            skills={props.skills}
            disabled={props.disabled || !option.data.isEnabled}
            user={props.user}
          />
        )}
        {offeringOptionType === OfferingOptionType.SLA && (
          <EditOfferingOptionSla
            name={`${props.name}.data`}
            skills={props.skills}
            disabled={props.disabled || !option.data.isEnabled}
            user={props.user}
          />
        )}
        {offeringOptionType === OfferingOptionType.DEFAULT && (
          <EditOfferingOptionDefault
            name={`${props.name}.data`}
            skills={props.skills}
            disabled={props.disabled || !option.data.isEnabled}
            user={props.user}
          />
        )}

        <div className="row">
          <div className="col"></div>
          <div className="col-auto hstack">
            <FormikSwitchInput
              name={`${props.name}.data.isEnabled`}
              disabled={props.disabled}
            />
            {props.onDelete && (
              <DeleteButton onClick={props.onDelete} className="p-1" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

interface EditOfferingOptionDurationSkillProps extends FormikInputParams {
  skills: Skill[];
  user: User;
}

function EditOfferingOptionDurationSkill(
  props: EditOfferingOptionDurationSkillProps
) {
  return (
    <div className={`EditOfferingOptionDurationSkill`}>
      <div className="row">
        <div className="col-lg-4 col-sm-12">
          <Label label={"Skill/Topic:"} required={true} />
          <SkillSelector
            skills={props.skills}
            name={`${props.name}.skill`}
            disabled={props.disabled}
            required={true}
          />
        </div>

        <div className="col-lg-4 col-sm-12 mob-mt-3">
          <Label label={"Duration:"} required={true} />
          <EditTimeDurationFormikInput
            timeDurationOptions={[
              {
                valueInSecs: 900,
                displayLabel: "15 mins",
              },
              {
                valueInSecs: 1800,
                displayLabel: "30 mins",
              },
              {
                valueInSecs: 3600,
                displayLabel: "1 hour",
              },
              {
                valueInSecs: 7200,
                displayLabel: "2 hours",
              },
            ]}
            required
            name={`${props.name}.durationInSeconds`}
            disabled={props.disabled}
            customInputGranularity={TimeDurationOptionGranularity.MINS}
            supportCustom={true}
          />
        </div>

        <div className="col-lg-4 col-sm-12 mob-mt-3">
          <Label label={"Price:"} required={true} />
          <EditPriceComponent
            showFree={true}
            name={`${props.name}.price`}
            disabled={props.disabled}
            supportCustom={true}
            defaultCurrency={props.user.basicDetails.currency}
          />
        </div>
      </div>
    </div>
  );
}

function EditOfferingOptionDuration(
  props: EditOfferingOptionDurationSkillProps
) {
  return (
    <div className={`EditOfferingOptionDuration`}>
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <Label label={"Duration:"} required={true} />
          <EditTimeDurationFormikInput
            timeDurationOptions={[
              {
                valueInSecs: 900,
                displayLabel: "15 mins",
              },
              {
                valueInSecs: 1800,
                displayLabel: "30 mins",
              },
              {
                valueInSecs: 3600,
                displayLabel: "1 hour",
              },
              {
                valueInSecs: 7200,
                displayLabel: "2 hours",
              },
            ]}
            required
            name={`${props.name}.durationInSeconds`}
            disabled={props.disabled}
            customInputGranularity={TimeDurationOptionGranularity.MINS}
            supportCustom={true}
          />
        </div>

        <div className="col-lg-6 col-sm-12 mob-mt-3">
          <Label label={"Price:"} required={true} />
          <EditPriceComponent
            showFree={true}
            name={`${props.name}.price`}
            disabled={props.disabled}
            supportCustom={true}
            defaultCurrency={props.user.basicDetails.currency}
          />
        </div>
      </div>
    </div>
  );
}

function EditOfferingOptionSla(props: EditOfferingOptionDurationSkillProps) {
  return (
    <div className={`EditOfferingOptionSla`}>
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <Label label={"Promised Response SLA:"} required={true} />
          <EditTimeDurationFormikInput
            timeDurationOptions={[
              {
                valueInSecs: 86400,
                displayLabel: "1 day",
              },
              {
                valueInSecs: 172800,
                displayLabel: "2 days",
              },
              {
                valueInSecs: 432000,
                displayLabel: "5 days",
              },
            ]}
            required
            name={`${props.name}.responseSlaInSecs`}
            disabled={props.disabled}
            customInputGranularity={TimeDurationOptionGranularity.DAYS}
            supportCustom={true}
          />
        </div>

        <div className="col-lg-6 col-sm-12 mob-mt-3">
          <Label label={"Price:"} required={true} />
          <EditPriceComponent
            showFree={true}
            name={`${props.name}.price`}
            disabled={props.disabled}
            supportCustom={true}
            defaultCurrency={props.user.basicDetails.currency}
          />
        </div>
      </div>
    </div>
  );
}

function EditOfferingOptionDefault(
  props: EditOfferingOptionDurationSkillProps
) {
  return (
    <div className={`EditOfferingOptionSla`}>
      <div className="row">
        <div className="col-lg-12 col-sm-12 mob-mt-3">
          <Label label={"Price:"} required={true} />
          <EditPriceComponent
            showFree={true}
            name={`${props.name}.price`}
            disabled={props.disabled}
            supportCustom={true}
            defaultCurrency={props.user.basicDetails.currency}
          />
        </div>
      </div>
    </div>
  );
}
