import React from "react";
import { BookDl } from "../../Global.Names";
import { LoginAPI } from "../../api/LoginAPI";
import { ModalLinkedButton } from "../../button/ModalLinked.Button";
import { EBButtonV2 } from "../../components/common/EBButtonV2";
import { KeyHelper } from "../../helpers/Key.Helper";
import { User } from "../../user/User";
import { IOfferingV2 } from "../Offering";
import { OfferingPrice } from "../Offering.Price";
import { OfferingRegistrationComponent } from "../Offering.Registration.Component";

export interface OfferingRegistrationSectionComponentProps {
  offering: IOfferingV2;
  user?: User;
}

export function OfferingRegistrationSectionComponent(
  props: OfferingRegistrationSectionComponentProps
) {
  return (
    <div id="OfferingRegisterSection">
      {!props.user && (
        <EBButtonV2
          className="w-100"
          content={{ normal: "Login to Book" }}
          onClick={() => {
            LoginAPI.redirectForLogin();
          }}
          rowProps={{ justify: "center" }}
        />
      )}
      {props.user && (
        <>
          <ModalLinkedButton
            modalBody={
              <OfferingRegistrationComponent
                offering={props.offering}
                user={props.user}
              />
            }
            header={
              BookDl.SENTENCE_START + " - " + props.offering.commonDetails.dl
            }
            modalSize="lg"
            uniqueKey={KeyHelper.getUniqueKey()}
            btnProps={{
              content: {
                normal: (
                  <OfferingPrice
                    offering={props.offering}
                    multiOptionDl="Book"
                  />
                ),
              },
              rowProps: { justify: "center" },
              className: "rounded-pill px-4",
            }}
          />
        </>
      )}
    </div>
  );
}
