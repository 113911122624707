import axios from "axios";
import React from "react";
import Navbar from "./AdminNavbar";
import "./admin.css";

class WrapperComponent extends React.Component {
  componentDidMount() {
    axios.get("/api/user").then((res) => {
      const user = res.data;
      this.setState({ user });
    });
    // TODO: Redirect to login page in case of 401.
  }

  render() {
    const ContentComponent = this.props.contentComponent;
    return (
      <div id="admin-wrapper">
        {this.state && this.state.user ? (
          <>
            <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                <Navbar user={this.state.user} />
                <div className="container-fluid">
                  <ContentComponent user={this.state.user} {...this.props} />
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

export default WrapperComponent;
