import { Col, Row, message } from "antd";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import TimeAgo from "timeago-react";
import { LoginAPI } from "../../../api/LoginAPI";
import { apiLinkConstants } from "../../../constants";
import { UserHelpers } from "../../../helpers/Helpers";
import { AttachmentTargetType } from "../../../model/AttachmentTargetType";
import { HTMLProps } from "../../../model/HTMLProps";
import { User } from "../../../user/User";
import { Api, ApiMethod } from "../ApiLinkedButton";
import { DeleteIcon } from "../Delete.Icon";
import { ApiLinkedButtonV3 } from "../Form/ApiLinked.button.V3";
import { Loader } from "../Loader.component";
import { MarkdownView } from "../MarkdownView";
import { FormikSwitchInput } from "../input/FormikSwitch.input";
import { MarkdownInput } from "../input/Markdown.input";
import { Center } from "../layout/Center.component";
import { CommentsAPI } from "./Comments.api";
import { Comment } from "./Comments.model";

interface CommentsComponentProps {
  targetType: AttachmentTargetType;
  targetId: string;
  dl?: string;
  loggedInuser?: User;
}

export function CommentsComponent(props: CommentsComponentProps) {
  let [comments, setComments] = useState<Comment[] | undefined>(undefined);

  function fetchComments(): Promise<any> {
    return CommentsAPI.getComments(
      props.targetType,
      props.targetId,
      0,
      100
    ).then((commentsResponse) => {
      setComments(commentsResponse);
    });
  }

  useEffect(() => {
    fetchComments();
  }, [props.targetType, props.targetId]);

  function refreshComments(): Promise<any> {
    return fetchComments();
  }

  return (
    <div className="CommentsComponent">
      <div className="h6">{props.dl}</div>
      <div className="">
        {props.loggedInuser && (
          <>
            <AddCommentComponent
              {...props}
              addCommentSuccessCallback={refreshComments}
            />
          </>
        )}

        {!props.loggedInuser && (
          <>
            <a href={LoginAPI.loginUrl()}>Login</a> to add comment!
          </>
        )}

        {comments && (
          <>
            <div className="mt-4">
              <CommentsListComponent
                comments={comments}
                targetType={props.targetType}
                targetId={props.targetId}
                deleteCommentSuccessCallback={refreshComments}
              />
            </div>
          </>
        )}

        {!comments && (
          <>
            <div className="mt-4">
              <Center>
                <Loader />
              </Center>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

interface CommentsListItemComponentProps
  extends CommentsComponentProps,
    HTMLProps {
  deleteCommentSuccessCallback: () => Promise<void>;
  comment: Comment;
}
function CommentsListItemComponent(props: CommentsListItemComponentProps) {
  let deleteApiLink = apiLinkConstants.API_COMMENTS_UPDATE.replaceAll(
    "{targetType}",
    props.targetType
  )
    .replaceAll("{targetId}", props.targetId)
    .replaceAll("{commentId}", props.comment.id + "");
  return (
    <div
      className={`hstack gap-2 CommentsListItemComponent ${props.className}`}
    >
      <div className="mb-auto py-1">
        <img
          width="50"
          src={UserHelpers.getProfilePicUrl(props.comment.postedBy)}
          className="rounded-circle"
        />
      </div>
      <div className="w-100 bg-light px-3 py-2 rounded-end">
        <Row className="mb-2">
          <Col className="review-block-name font-weight-bold" flex={"auto"}>
            {UserHelpers.getFullName(props.comment.postedBy)}
          </Col>
          {props.comment.createdAt && (
            <Col className="review-block-date text-right text-muted">
              <TimeAgo datetime={props.comment.createdAt} />
            </Col>
          )}
        </Row>
        <div className="d-flex w-100 justify-content-between">
          <div className="review-block-description text-change-line-for-newline">
            {props.comment.content && (
              <>
                <MarkdownView text={props.comment.content} />
              </>
            )}
          </div>
          <div className="float-right">
            {props.comment.canEdit && (
              <ApiLinkedButtonV3
                api={new Api(deleteApiLink, ApiMethod.DELETE)}
                buttonProps={{
                  className: "btn-sm btn-light mb-1",
                  content: {
                    normal: <DeleteIcon />,
                    submitting: "Deleting",
                  },
                  tooltip: "Delete comment",
                }}
                uniqueKey={"comments-" + props.comment.id + "-delete"}
                successCallback={props.deleteCommentSuccessCallback}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

interface CommentsListComponentProps extends CommentsComponentProps {
  deleteCommentSuccessCallback: () => Promise<any>;
  comments: Comment[];
}

export function CommentsListComponent(props: CommentsListComponentProps) {
  return (
    <div className="CommentsListComponent">
      {props.comments.map((comment, index) => {
        return (
          <CommentsListItemComponent
            className="mb-3"
            comment={comment}
            targetType={props.targetType}
            targetId={props.targetId}
            deleteCommentSuccessCallback={props.deleteCommentSuccessCallback}
          />
        );
      })}
    </div>
  );
}

interface AddCommentComponentProps extends CommentsComponentProps {
  addCommentSuccessCallback: () => void;
}

export function AddCommentComponent(props: AddCommentComponentProps) {
  let initialValues: Comment = { content: "" };
  return (
    <div className="AddCommentComponent">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          return CommentsAPI.addComment(
            props.targetType,
            props.targetId,
            values
          ).then(() => {
            message.success("Saved successfully!");
            values.content = "";
            props.addCommentSuccessCallback();
          });
        }}
      >
        {(formProps) => (
          <Form>
            <MarkdownInput
              name="content"
              user={props.loggedInuser!}
              minEditorHeight={100}
            />

            <div className="mt-3">
              <Row>
                <Col flex="auto">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={formProps.isSubmitting}
                  >
                    {formProps.isSubmitting ? "Saving. Please Wait..." : "Send"}
                  </button>
                </Col>
                <Col>
                  <div>
                    <FormikSwitchInput
                      name="isAnon"
                      label="Add as Anonymous?"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
