import React, { useEffect, useState } from "react";
import { Tab, TabView } from "../components/common/Tab.view";
import { publicLinkConstants } from "../constants";
import { LedgerApi } from "../payments/ledger/Ledger.api";
import { LedgerComponent } from "../payments/ledger/Ledger.component";
import { Ledger } from "../payments/ledger/Ledger.model";
import { PaymentReleaseComponent } from "../payments/payment-release-requests/PaymentRelease.component";
import { PaymentReleaseRequestApi } from "../payments/payment-release-requests/PaymentReleaseRequest.api";
import { PaymentReleaseRequestsResponse } from "../payments/payment-release-requests/PaymentReleaseRequest.model";
import { WithDrawalApi } from "../payments/withdrawal/Withdrawal.api";
import { WithdrawalsComponent } from "../payments/withdrawal/Withdrawal.component";
import { WithdrawalResponse } from "../payments/withdrawal/Withdrawal.model";
import { ExpertStudioContentProps } from "./ExpertStudio.page";

interface ExpertStudioPaymentsComponentProps extends ExpertStudioContentProps {}

enum PaymentTabName {
  LEDGER,
  WITHDRAWALS,
}

export function ExpertStudioPaymentsComponent(
  props: ExpertStudioPaymentsComponentProps
) {
  let [ledger, setLedger] = useState<Ledger>();
  let [withdrawals, setWithdrawals] = useState<WithdrawalResponse>();
  let [prrs, setPrrs] = useState<PaymentReleaseRequestsResponse>();

  useEffect(() => {
    LedgerApi.getUserLedger(props.user.basicDetails.id).then((ledger) => {
      setLedger(ledger);
    });
    WithDrawalApi.getUserWithdrawals(props.user.basicDetails.id).then(
      (withdrawals) => {
        setWithdrawals(withdrawals);
      }
    );
    PaymentReleaseRequestApi.getUserPRRs(props.user.basicDetails.id).then(
      (prrs) => {
        setPrrs(prrs);
      }
    );
  }, []);

  let tabs: Tab[] = [];

  if (prrs) {
    tabs.push({
      title: "Pending Verification",
      content: <PaymentReleaseComponent paymentReleaseRequests={prrs} />,
      uniqueKey: "prrs",
      isActive: true,
    });
  }

  if (ledger) {
    tabs.push({
      title: "Ledger",
      content: <LedgerComponent ledger={ledger} />,
      uniqueKey: "ledger",
      isActive: true,
    });
  }

  if (withdrawals) {
    tabs.push({
      title: "Withdrawals",
      content: <WithdrawalsComponent withdrawalResponse={withdrawals} />,
      uniqueKey: "withdrawals",
      isActive: true,
    });
  }

  return (
    <div className="ExpertStudioPaymentsComponent">
      <h5>Payments:</h5>
      <b>
        <i className="fa-solid fa-circle-exclamation"></i> How it works:
      </b>{" "}
      Once you complete your work, the amount is added for verification by
      enginEBogie team. Post that, it is credited to your ledger. During first
      week of every month, your ledger balance is transferred to the payment
      method you have provided.
      <br />
      Please make sure to update your{" "}
      <a href={publicLinkConstants.EXPERT_STUDIO_GENERAL_SETTINGS}>
        Payout Settings Here
      </a>
      . We will use these settings to make monthly tranfers.
      <br />
      <br />
      <TabView tabs={tabs} selectedTab={tabs[0]} />
    </div>
  );
}
