import React from "react";
import { Company } from "./Company";
import { User } from "../user/User";
import { Form, Formik } from "formik";
import { CompanyAPI } from "./CompanyAPI";
import { SaveButton } from "../button/Save.Button";
import { EBTextInputV2 } from "../components/common/input/TextLine.input";
import { Label } from "../components/common/Form/elements/FormInputLabel";
import { AttachmentInput } from "../components/common/upload/AttachmentInput";
import { EBTextAreaV2 } from "../components/common/Form/FormElements";
import { message } from "antd";

interface CompanyEditComponentProps {
  company: Company;
  liu: User;
}

export function CompanyEditComponent(props: CompanyEditComponentProps) {
  return (
    <span className="CompanyEditComponent">
      <Formik
        initialValues={props.company}
        onSubmit={(values) => {
          return CompanyAPI.updateCompany(props.company.id)
            .executeV2(values)
            .then(() => {
              message.success("Company updated");
            });
        }}
      >
        {(formProps) => (
          <Form>
            <Label label="Short Name:" />
            <EBTextInputV2 name="dl" />

            <Label label="Descriptive Name:" />
            <EBTextInputV2 name="desDl" />

            <Label label="Tagline:" />
            <EBTextInputV2 name="tl" />

            <Label label="Linkedin URL:" />
            <EBTextInputV2 name="liUrl" />

            <EBTextAreaV2 name="dd" label={"About"} />

            <Label label="Logo:" required={true} />
            <AttachmentInput name="dp" user={props.liu} required={true} />
            <SaveButton submitting={formProps.isSubmitting} />
          </Form>
        )}
      </Formik>
    </span>
  );
}
