import React from "react";
import { InterviewExperienceAPI } from "../api/InterviewExperienceAPI";
import { UrlHelpers } from "../helpers/Helpers";
import { WindowHelpers } from "../helpers/Window.helper";
import { InterviewExperienceMapper } from "../mappers/InterviewExperience.mapper";
import {
  InterviewExperience,
  InterviewExperienceResponse,
} from "../model/InterviewExperience";
import { ReactMatch } from "../model/ReactMatchObj";
import { PageProps } from "../model/views/PageProps";
import { InterviewExperienceForm } from "./InterviewExperience.Form";
import moment from "moment";

interface EditInterviewExperienceContentPathParams {
  interviewExperienceId: number;
}

interface EditInterviewExperienceContentProps extends PageProps {
  match: ReactMatch<EditInterviewExperienceContentPathParams>;
}

interface EditInterviewExperienceContentState extends PageProps {
  interviewExperience: InterviewExperience;
}

export class EditInterviewExperiencePageContent extends React.Component<
  EditInterviewExperienceContentProps,
  EditInterviewExperienceContentState
> {
  componentDidMount() {
    InterviewExperienceAPI.fetchInterviewExperienceDetails(
      this.props.match.params.interviewExperienceId
    ).then((response: InterviewExperienceResponse) => {
      this.setState({
        interviewExperience:
          InterviewExperienceMapper.mapResponseToModel(response),
      });
    });
  }

  render() {
    let interviewExperienceId = this.props.match.params.interviewExperienceId;

    if (
      this.state &&
      this.state.interviewExperience &&
      this.state.interviewExperience.interviewDate
    ) {
      this.state.interviewExperience.interviewDate = moment(
        this.state.interviewExperience.interviewDate
      ).format("YYYY-MM-DD");
    }

    return (
      <div className="main-container">
        {this.props.user && this.state && this.state.interviewExperience && (
          <>
            <h1 className="h3 mb-4">
              Edit - {this.state.interviewExperience.dl}
            </h1>

            <InterviewExperienceForm
              user={this.props.user}
              initialIE={this.state.interviewExperience}
              onSubmit={function (interviewExperience: InterviewExperience) {
                return new Promise((resolve, reject) => {
                  return InterviewExperienceAPI.updateInterviewExperience(
                    interviewExperienceId,
                    interviewExperience
                  )
                    .then((response) => {
                      const successMessage =
                        "Interview experience saved successfully!!!";
                      const ieUrl = UrlHelpers.getInterviewExperienceDetailsUrl(
                        response.slug,
                        response.id,
                        successMessage
                      );

                      WindowHelpers.redirect(ieUrl);
                      resolve();
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
              }}
            />
          </>
        )}
      </div>
    );
  }
}
