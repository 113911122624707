import { Form, Formik } from "formik";
import React from "react";
import { LoginAPI } from "../api/LoginAPI";
import { OrderAPI } from "../api/OrderAPI";
import { EventTicketRegistrationMessageComponent } from "../components/Generic.component";
import { ImageComponent } from "../components/common/Image.component";
import { MarkdownView } from "../components/common/MarkdownView";
import { TimestampDurationView } from "../components/common/TimestampView";
import { OfferingsUserSidebar } from "../components/pages/charchaa/OfferingsUser.Sidebar";
import { ApiErrorHelper } from "../helpers/ApiError.helper";
import {
  EventTicketHelpers,
  ImageHelpers,
  PriceHelpers,
} from "../helpers/Helpers";
import { PaymentHelper } from "../helpers/Payment.helper";
import { WindowHelpers } from "../helpers/Window.helper";
import { ReceivedReviewResponse } from "../model/Reviews";
import { VenueTypeEnum } from "../model/Venue.model";
import { EventOrderRequest } from "../model/requests/EventOrder.request";
import { User } from "../user/User";
import UserFullProfileReviews from "../user/UserFullProfileReviews";
import { UserProfileSidebar } from "../user/UserProfile.Sidebar";
import { EventBasicDetails, EventTicket, EventV2 } from "./Event.Model";

interface EventTicketBookButtonProps {
  ticketId: string;
  user?: User;
  disabled: boolean;
  text?: string;
  inProgressText?: string;
}

export function EventTicketBookButton(props: EventTicketBookButtonProps) {
  let text = props.text
    ? props.text
    : props.user
    ? "Register"
    : "Login to Register";
  let inProgressText = props.inProgressText
    ? props.inProgressText
    : "Processing. Please wait...";
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        let eventOrder: EventOrderRequest = {
          ticketRequests: [
            {
              ticketId: props.ticketId,
              quantity: 1,
            },
          ],
        };

        LoginAPI.loginIfNeeded()
          .then((user) => {
            return OrderAPI.createEventOrder(eventOrder).then((order) => {
              return PaymentHelper.processOrderPayment(order.orderId, user);
            });
          })
          .then((orderPaymentSuccess) => {
            if (orderPaymentSuccess.redirectUrl) {
              WindowHelpers.redirect(orderPaymentSuccess.redirectUrl);
            } else {
              alert(
                "Your order has been confirmed. Please check your email for further details"
              );
            }
          })
          .catch((error) => {
            let message = ApiErrorHelper.getDisplayErrorMessage(error);
            alert(message);
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formProps) => (
        <Form>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={formProps.isSubmitting || props.disabled}
          >
            {formProps.isSubmitting ? inProgressText : text}
          </button>
        </Form>
      )}
    </Formik>
  );
}

interface EventTicketCardProps {
  ticket: EventTicket;
  user?: User;
  hideRegistrationButton?: boolean;
}

export function EventTicketCard(props: EventTicketCardProps) {
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">
          {props.ticket.title} -{" "}
          {PriceHelpers.getDisplayPriceV2(props.ticket.price)}
        </h4>
        <p className="card-text">{props.ticket.description}</p>
        <p className="card-text small">
          <EventTicketRegistrationMessageComponent ticket={props.ticket} />
        </p>
      </div>
      {!props.hideRegistrationButton && (
        <div className="card-footer">
          <div className="hstack gap-3">
            <div>
              {props.ticket && props.ticket.id && (
                <EventTicketBookButton
                  ticketId={props.ticket.id}
                  user={props.user}
                  disabled={!EventTicketHelpers.isBookingAllowed(props.ticket)}
                />
              )}
            </div>
            <div>{EventTicketHelpers.availabilityStatus(props.ticket)}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export interface EventPreviewPageViewProps {
  event: EventV2;
  user?: User;
  instructorReviews?: ReceivedReviewResponse;
  preview?: boolean;
}

export class EventPreviewPageView extends React.Component<
  EventPreviewPageViewProps,
  {}
> {
  render() {
    let eventBasicDetails = this.props.event?.basicDetails;
    let instructor = this.props.event?.instructors[0];

    return (
      <div className="">
        <div className="row pt-2">
          <div className="col-md-3 mb-2">
            {instructor && (
              <>
                <UserProfileSidebar userId={instructor.id} />
                <OfferingsUserSidebar userId={instructor.id} />
              </>
            )}
          </div>
          <div className="col-md-9 mb-2">
            <div className="card shadow rounded-top">
              {eventBasicDetails.bannerImage &&
                ImageHelpers.validImage(eventBasicDetails.bannerImage.data) && (
                  <ImageComponent
                    image={eventBasicDetails.bannerImage}
                    width={"100%"}
                    height={"auto"}
                    className="rounded-top"
                  />
                )}
              <div className="card-body">
                <h2 className="card-title">{eventBasicDetails?.title}</h2>
                <hr />

                <div className="event-data-mobile">
                  <div className="mb-4">
                    <EventTicketsView
                      event={this.props.event}
                      user={this.props.user}
                      hideRegistrationButton={this.props.preview}
                    />
                  </div>
                  <div className="">
                    <div className="mb-4">
                      <div className="mb-4">
                        <EventTiemAndVenueView
                          eventBasicDetails={eventBasicDetails}
                        />
                      </div>{" "}
                    </div>
                    {eventBasicDetails?.description && (
                      <MarkdownView text={eventBasicDetails?.description} />
                    )}
                  </div>
                </div>

                <div className="row event-data-desktop">
                  <div className="col-md-8 col-sm-7">
                    <div className="mb-4">
                      <EventTiemAndVenueView
                        eventBasicDetails={eventBasicDetails}
                      />
                    </div>
                    {eventBasicDetails?.description && (
                      <MarkdownView text={eventBasicDetails?.description} />
                    )}
                  </div>
                  <div className="col-md-4 event-ticket-div-desktop">
                    <EventTicketsView
                      event={this.props.event}
                      user={this.props.user}
                      hideRegistrationButton={this.props.preview}
                    />
                  </div>
                </div>
                {eventBasicDetails?.completedAt && (
                  <div className="ribbon ribbon-top-right">
                    <span>Completed</span>
                  </div>
                )}
              </div>
            </div>

            {/* Reviews */}
            <div>
              {this.props.instructorReviews && (
                <div>
                  <div className="card shadow mt-2">
                    <div className="card-header">Reviews</div>
                    <div className="card-body">
                      <UserFullProfileReviews
                        reviews={this.props.instructorReviews.reviews}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface EventTicketsViewProps {
  event: EventV2;
  user?: User;
  hideRegistrationButton?: boolean;
}

function EventTicketsView(props: EventTicketsViewProps) {
  return (
    <>
      {!props.event.areSeatsAvailable && !props.hideRegistrationButton && (
        <div
          className="card card-body border-dashed text-center bold bg-light mb-2 fs-3 text-muted"
          style={{ borderStyle: "dashed" }}
        >
          Tickets Sold Out!
        </div>
      )}
      {props.event?.tickets?.map((ticket, index) => {
        return (
          <>
            {ticket.totalFreeQuantity != undefined &&
              ticket.totalFreeQuantity > 0 && (
                <>
                  <div className="mb-2" key={ticket.id}>
                    <EventTicketCard
                      ticket={ticket}
                      user={props.user}
                      hideRegistrationButton={props.hideRegistrationButton}
                    />
                  </div>
                </>
              )}
          </>
        );
      })}
    </>
  );
}

interface EventTiemAndVenueViewProps {
  eventBasicDetails?: EventBasicDetails;
}

function EventTiemAndVenueView(props: EventTiemAndVenueViewProps) {
  let eventBasicDetails = props.eventBasicDetails;
  return (
    <>
      <div>
        <i className="fa-solid fa-calendar-day" style={{ width: "20" }}></i>{" "}
        <b>Event's time: </b>
        <mark>
          {eventBasicDetails?.duration && (
            <TimestampDurationView
              startTimestamp={eventBasicDetails?.duration?.fromMillis}
              endTimestamp={eventBasicDetails?.duration?.toMillis}
            />
          )}
        </mark>
      </div>
      {eventBasicDetails?.venue && (
        <>
          <div>
            <i
              className="fa-solid fa-location-dot"
              style={{
                marginRight: "1px",
                marginLeft: "1px",
              }}
            ></i>{" "}
            <b>Venue: </b>
            <mark>
              {eventBasicDetails?.venue.venueType ===
                VenueTypeEnum.PHYSICAL_LOCATION && (
                <>{eventBasicDetails?.venue.venueData}</>
              )}
              {eventBasicDetails?.venue.venueType === VenueTypeEnum.ONLINE && (
                <>
                  Online (Meeting link will be shown on the dashboard post
                  booking){" "}
                </>
              )}
            </mark>
          </div>
        </>
      )}
    </>
  );
}
