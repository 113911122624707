import { Carousel } from "antd";
import React from "react";
import styled from "styled-components";
import { ImageComponent } from "../components/common/Image.component";
import { PriceView } from "../components/common/Price.view";
import { IOfferingV2 } from "./Offering";
import "./Offering.Carousel.css";

interface OfferingCarousel {
  title?: string;
  offerings?: IOfferingV2[];
}

export function OfferingsCarousel(props: OfferingCarousel) {
  const CarouselWrapper = styled(Carousel)`
    > .slick-dots {
      margin: 0px;
    }
    > .slick-dots li.slick-active button {
    }
  `;

  return (
    <>
      {props.offerings && props.offerings.length > 0 && (
        <div>
          <b>{props.title}</b>

          <div className="row">
            <CarouselWrapper autoplay autoplaySpeed={2000}>
              {props.offerings.map((offering, index) => (
                <a href={offering.url}>
                  <div
                    className="offering-carousel-slide col text-dark roundedg px-0 pt-1 pb-2"
                    style={{ height: "205px" }}
                  >
                    <div className="text-light text-center">
                      Connect With {offering.commonDetails.postedBy.dl}
                    </div>
                    <div className="bg-white rounded mt-1 p-2 px-3 mx-4 ">
                      <div
                        className="h5"
                        style={{
                          height: "100px",
                          overflow: "hidden",
                        }}
                      >
                        {offering.commonDetails.dl}
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <ImageComponent
                            image={offering.commonDetails.type.dp}
                          />{" "}
                          {offering.commonDetails.type.dl}
                        </div>
                        <div className="float-right">
                          <PriceView
                            price={offering.commonDetails.price}
                            showFree={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </CarouselWrapper>
          </div>
        </div>
      )}
    </>
  );
}
