import React from "react";
import { PriceView } from "../components/common/Price.view";
import { HTMLProps } from "../model/HTMLProps";
import { IOfferingV2, OfferingOption } from "./Offering";

export function OfferingPricePill(props: {
  offering: IOfferingV2;
  prefix?: string;
  multiOptionDl?: React.ReactNode;
}) {
  return (
    <OfferingPrice
      className="font-size-sm m-0 border border rounded-pill p-2 px-3"
      offering={props.offering}
      prefix={props.prefix}
      multiOptionDl={props.multiOptionDl}
    />
  );
}

interface OfferingPriceProps extends HTMLProps {
  offering: IOfferingV2;
  prefix?: string;
  multiOptionDl?: React.ReactNode;
}

export function OfferingPrice(props: OfferingPriceProps) {
  let options: OfferingOption[] = props.offering.commonDetails
    .options as OfferingOption[];
  let isMultiOption = options && options.length > 1;
  return (
    <span className={props.className}>
      {isMultiOption ? props.multiOptionDl ?? "Starts" : "Book"} @{" "}
      <PriceView price={props.offering.commonDetails.price} showFree={true} />
    </span>
  );
}
