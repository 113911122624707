import React from "react";
import { Mode } from "../../components/common/Form/model/mode.model";
import { FormSectionLineItemInputFileUploadFormikInput } from "./Form.Section.LineItem.Input.FileUpload.FormikInput";
import { FormSectionLineItemViewFileUpload } from "./Form.Section.LineItem.View.FileUpload";
import { FormLineItemComponentProps } from "./FormLineItemComponent";

export function FormLineItemInputFileUploadComponent(
  props: FormLineItemComponentProps
) {
  return (
    <>
      {props.mode == Mode.EDIT && (
        <FormSectionLineItemInputFileUploadFormikInput {...props} />
      )}
      {props.mode == Mode.VIEW && (
        <FormSectionLineItemViewFileUpload {...props} />
      )}
    </>
  );
}
