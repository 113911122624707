import React from 'react';
import TimeAgo from 'timeago-react';
import { UrlHelpers, UserHelpers } from '../../../helpers/Helpers';
import { InterviewLoop } from '../../../model/InterviewLoop';

interface InterviewLoopListProps {
    interviewLoops: InterviewLoop[]
}

export function InterviewLoopList(props: InterviewLoopListProps) {

    return (
        <div className="card mt-4">
            <div className="card-header">
                <h4>Existing loops</h4>
            </div>
            <div className="card-body">
                <ol className="list-group list-group-numbered">
                    

            {props.interviewLoops && props.interviewLoops.map((loop, index) => {
                return (
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">{UserHelpers.getUserFullName(loop.candidate)} ({loop.candidate.contactDetails?.email}, {loop.candidate.contactDetails?.phone})</div>
                            <span className="text-muted">
                                Created by: {UserHelpers.getUserFullName(loop.createdBy)}
                                <div>{loop.createdAt ? (<TimeAgo datetime={loop.createdAt} />) : null }</div>
                            </span>
                            
                        </div>
                        <a href={UrlHelpers.getInterviewLoopDetailsUrl(loop.companyId, loop.id)} className="btn btn-sm btn-secondary">View Details</a>
                        
                        
                    </li>
                    
                )
            })}
            </ol>
            </div>
        </div>
    )
}
