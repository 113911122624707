import React from "react";
import { ReactMatch } from "../model/ReactMatchObj";
import { PageProps } from "../model/views/PageProps";
import { User } from "../user/User";
import { ExpertStudioHelper } from "./ExpertStudio.model";
import { ExpertStudioMobileBottomTrayComponent } from "./ExpertStudioMobileBottomTray.component";
import { ExpertStudioSidebarComponent } from "./ExpertStudioSidebar.component";

export interface ExpertStudioContentProps {
  user: User;
  match?: ReactMatch<any>;
}

interface ExpertStudioPageContentProps extends PageProps {
  ContentComponent: React.ComponentType<ExpertStudioContentProps>;
  activeNavId?: string;
  match: ReactMatch<any>;
}

export function ExpertStudioPageContent(props: ExpertStudioPageContentProps) {
  let opts = ExpertStudioHelper.getOptions(props.user);

  return (
    <>
      <div className="ExpertStudioPageContent event-data-desktop row h-100">
        <ExpertStudioSidebarComponent
          activeNavId={props.activeNavId}
          user={props.user}
          opts={opts}
        />
        <div className="col p-0">
          <div className="p-4">
            {props.user && (
              <props.ContentComponent user={props.user} match={props.match} />
            )}
          </div>
        </div>
      </div>

      <div className="ExpertStudioPageContent event-data-mobile h-100">
        <div
          className="bg-dark"
          style={{
            width: "100%",
            position: "fixed",
            bottom: "0",
            left: "0",
            zIndex: 1,
          }}
        >
          <ExpertStudioMobileBottomTrayComponent
            activeNavId={props.activeNavId}
            user={props.user}
            opts={opts}
          />
        </div>
        <div></div>
        <div className="p-4" style={{ marginBottom: "100px" }}>
          {props.user && (
            <props.ContentComponent user={props.user} match={props.match} />
          )}
        </div>
      </div>
    </>
  );
}
