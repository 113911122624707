import React from "react";
import { CheckIcon } from "../icon/Check.Icon";
import { DraftIcon } from "../icon/Draft.Icon";
import { FormStatus } from "./model/form.model";

interface FormStatusBadgeProps {
  status: FormStatus;
  className?: string;
}
export function FormStatusBadge(props: FormStatusBadgeProps) {
  return (
    <>
      {props.status == FormStatus.DRAFT && (
        <span className={`badge bg-secondary ${[props.className]}`}>
          <DraftIcon /> Draft
        </span>
      )}

      {props.status == FormStatus.SUBMITTED && (
        <span className={`badge bg-success ${[props.className]}`}>
          <CheckIcon /> Submitted
        </span>
      )}
    </>
  );
}
