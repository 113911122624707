import React from "react";
import { DeleteIcon } from "../components/common/Delete.Icon";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { HTMLProps } from "../model/HTMLProps";

interface DeleteButtonProps extends HTMLProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function DeleteButton(props: DeleteButtonProps) {
  return (
    <EBButtonV2
      content={{
        normal: <DeleteIcon />,
      }}
      {...props}
      purpose="none"
      className={`${props.className} btn-link text-danger`}
    />
  );
}
