import React from "react";
import { CompaniesBadgeList } from "../badge/Companies.BadgeList";
import { PendingApprovalBadge } from "../badge/PendingApproval.Badge";
import { ReactionCountsViewComponent } from "../components/common/ReactionCountsViewComponent";
import { UrlHelpers } from "../helpers/Helpers";
import { Link } from "../links/Link";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { HTMLProps } from "../model/HTMLProps";
import { TopicBadge } from "../topic/Topic.Badge";
import { AnswerSubmissionStatusBadge } from "./AnswerSubmissionStatus.Badge";
import { DifficultyLabel } from "./Difficulty.label";
import { Question } from "./Question.Model";
import { QuestionBadgesViewTop } from "./Question.Badges";
import { User } from "../user/User";

interface QuestionsListItemProps extends HTMLProps {
  question: Question;
  dl: React.ReactNode;

  hideEdit?: boolean;
  hideReactionText?: boolean;
  hideTopics?: boolean;

  liu?: User;
}

export function QuestionsListItem(props: QuestionsListItemProps) {
  let question = props.question;
  return (
    <div className={`QuestionsListItem ${props.className}`}>
      <Link href={question.url} className="text-decoration-none text-dark">
        <div className="media">
          <div className="media-body">
            <div className="d-flex w-100 justify-content-between">
              <span className="mb-1">{props.dl}</span>

              {question.canUserEdit && !props.hideEdit && (
                <a
                  href={UrlHelpers.getEditQuestionDetailsUrl(
                    question.slug,
                    question.id
                  )}
                  className="link-secondary"
                  role="button"
                >
                  <i className="fas fa-edit"></i>
                </a>
              )}
            </div>
            <div className="d-flex w-100 justify-content-between">
              <div>
                <QuestionBadgesViewTop
                  question={question}
                  liu={props.liu}
                  showApproval={true}
                  showDifficulty={true}
                />
              </div>
            </div>
            {!props.hideTopics && (
              <>
                {question.topics && question.topics.length > 0 && (
                  <span className="px-0  mt-2">
                    {question.topics.map((topic, index) => (
                      <TopicBadge topic={topic} key={index} />
                    ))}
                  </span>
                )}
                {question.companies && question.companies.length > 0 && (
                  <span className="mob-pt-1">
                    <CompaniesBadgeList companies={question.companies} />
                  </span>
                )}
              </>
            )}
            <div className="d-flex w-100">
              {question.reactionCounts && (
                <div className="mt-2">
                  <ReactionCountsViewComponent
                    targetType={AttachmentTargetType.QUESTION}
                    targetId={"" + question.id}
                    reactionCounts={question.reactionCounts}
                    hideText={props.hideReactionText}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
