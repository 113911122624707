import React from "react";
import { Currency, Price } from "../../../model/Price";
import { PriceView } from "../Price.view";
import { FormikInputParams } from "../input/FormikInputParams.model";
import { PriceFormikInput } from "./Price.FormikInput";
import { PriceHelper } from "./Price.Helper";
import {
  RadioButtonGroupFormikInput,
  RadioButtonGroupFormikInputOption,
} from "./RadioButton.Group.FormikInput";

export interface PriceOption {
  price: Price;
}

interface EditPriceComponentProps extends FormikInputParams {
  supportCustom?: boolean;
  showFree?: boolean;
  defaultCurrency?: Currency;
}

export function EditPriceComponent(props: EditPriceComponentProps) {
  let priceOptions: PriceOption[] = PriceHelper.getEditOptions(
    props.defaultCurrency
  );

  let options: RadioButtonGroupFormikInputOption<Price>[] = [];

  for (let priceOption of priceOptions) {
    options.push({
      value: priceOption.price,
      dl: <PriceView price={priceOption.price} showFree={props.showFree} />,
    });
  }
  return (
    <div className="EditPriceComponent">
      <RadioButtonGroupFormikInput
        options={options}
        isEqual={(val1: Price, val2: Price) => {
          return (
            val1.amount == val2.amount && val1.currency.id == val2.currency.id
          );
        }}
        customInput={props.supportCustom && <PriceFormikInput {...props} />}
        {...props}
      />
    </div>
  );
}
