import axios from "axios";
import { apiLinkConstants } from "../constants";
import { EventOrderRequest } from "../model/requests/EventOrder.request";
import { OrderResponse } from "../model/response/Order.response";
import { PaymentSuccessResponse } from "../model/response/PaymentSuccess.response";
import { RazorpaymentProcessorPaymentResponse } from "../model/response/Razorpay.response";
import { FreePaymentPaymentSuccessRequestData } from "../payments/FreePayment.response";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";

export class PaymentAPI {
  public static markRazorpayPaymentSuccess(
    data: RazorpaymentProcessorPaymentResponse
  ): Promise<PaymentSuccessResponse> {
    return new Promise<PaymentSuccessResponse>((resolve, reject) => {
      axios
        .post(apiLinkConstants.RAZORPAY_PAYMENT_SUCESS, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static markFreePaymentSuccess(
    data: FreePaymentPaymentSuccessRequestData
  ): Promise<PaymentSuccessResponse> {
    return new Api<
      FreePaymentPaymentSuccessRequestData,
      PaymentSuccessResponse
    >(apiLinkConstants.FREE_PAYMENT_SUCESS, ApiMethod.POST).execute(data);
  }
}
