import { Tooltip } from "antd";
import React from "react";

interface LinkClickHandlerProps {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
  tooltip?: React.ReactNode;
  otherProps?: any;
}

export function LinkClickHandler(props: LinkClickHandlerProps) {
  let otherProps = props.otherProps;
  if (!otherProps) {
    otherProps = {};
  }

  return (
    <Tooltip title={props.tooltip}>
      <a
        href="#"
        className={props.className}
        onClick={(event) => {
          props.onClick();
          event.preventDefault();
          event.stopPropagation();
        }}
        {...otherProps}
      >
        {props.children}
      </a>
    </Tooltip>
  );
}
