import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import { TitleProps } from "./Title.Model";

export function CancelTitle(props: TitleProps) {
  return (
    <>
      <CloseOutlined /> {props.text ?? "Cancel"}
    </>
  );
}
