import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { Channel } from "./Channel.model";

export class ChannelApi {
  public static createChannel(newChannel: Channel): Promise<Channel> {
    return new Api<Channel, Channel>(
      apiLinkConstants.API_CHANNEL_ADD,
      ApiMethod.POST
    ).execute(newChannel);
  }
}
