import { Content } from "../../content/Content.model";

export interface PathStep {
  id: number;
  dl: string;
  isEnabled?: boolean;
  content?: Content;
  steps?: PathStep[];
  parentId?: number;
  completedAt?: string;
}

export interface PathStepType {
  typeEnum: PathStepTypeEnum;
  dl: string;
}

export enum PathStepTypeEnum {
  STEPS_GROUP = "STEPS_GROUP",
  QUESTION = "QUESTION",
  CONTENT = "CONTENT",
}

export interface IPathStepData {}

export interface PathStepDataContent extends IPathStepData {
  content: string;
}

export interface PathStepDataGroup extends IPathStepData {
  steps: PathStep[];
}

export enum MoveDirectionEnum {
  UP = "UP",
  DOWN = "DOWN",
}
