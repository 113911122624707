import React from "react";
import Moment from "react-moment";
import { CompanyImage } from "../company/Company.Image";
import { ListItemProps } from "../list/List";
import { InterviewExperienceResponse } from "../model/InterviewExperience";

export function UserInterviewExperienceListItem(props: ListItemProps) {
  let interviewExperience = props.item as InterviewExperienceResponse;
  return (
    <div className="UserListItem">
      <a className="link-dark " href={interviewExperience.url}>
        <div className="media">
          {interviewExperience.company && (
            <CompanyImage company={interviewExperience.company} />
          )}
          <div className="media-body pl-2">
            {interviewExperience.dl}
            {/* <span className="ml-2 text-end">
                {interviewExperience.receivedOffer && (
                  <span className="badge bg-success text-small">
                    Offer{" "}
                    {interviewExperience.offerDesignation?.title &&
                      " - " + interviewExperience.offerDesignation.title}
                  </span>
                )}
              </span> */}
            <p className="text-muted font-weight-light mb-0 font-size-sm">
              <small>
                {interviewExperience.interviewDate ? (
                  <Moment format="DD MMM YYYY">
                    {interviewExperience.interviewDate}
                  </Moment>
                ) : (
                  ""
                )}
              </small>
            </p>
          </div>
        </div>
      </a>
    </div>
  );
}
