import React from "react";
import { Question } from "../../question/Question.Model";
import { AdminQuestionsList } from "./AdminQuestionsList";

interface Tab {
  title: string;
  questions: Question[];
  uniqueKey: string;
  isActive?: boolean;
}

interface AdminQuestionsListWrapperProps {
  questions: Question[];
}
export class AdminQuestionsListWrapper extends React.Component<AdminQuestionsListWrapperProps> {
  render() {
    let archived: Question[] = [];
    let approved: Question[] = [];
    let pendingApproval: Question[] = [];

    for (let question of this.props.questions) {
      if (question.archivedAt == null) {
        if (question.approvedAt == null) {
          pendingApproval.push(question);
        } else {
          approved.push(question);
        }
      } else {
        archived.push(question);
      }
    }

    let tabs: Tab[] = [];
    tabs = [
      {
        title: "Pending Approval",
        questions: pendingApproval,
        uniqueKey: "pending-approval",
        isActive: pendingApproval.length > 0,
      },
      {
        title: "Approved",
        questions: approved,
        uniqueKey: "approved",
        isActive: pendingApproval.length == 0 && approved.length > 0,
      },
      {
        title: "Archived",
        questions: archived,
        uniqueKey: "archived",
      },
    ];
    return (
      <div className="questions-list-wrapper">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          {tabs.map((tab) => (
            <li className={"nav-item"} role="presentation" key={tab.uniqueKey}>
              <button
                className={"nav-link " + (tab.isActive ? "active" : "")}
                id={tab.uniqueKey + "-tab"}
                data-bs-toggle="tab"
                data-bs-target={"#" + tab.uniqueKey}
                type="button"
                role="tab"
                aria-controls={tab.uniqueKey}
                aria-selected="true"
              >
                {tab.title} ({tab.questions ? tab.questions.length : "0"}){" "}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="myTabContent">
          {tabs.map((tab) => (
            <div
              className={"tab-pane fade show " + (tab.isActive ? "active" : "")}
              id={tab.uniqueKey}
              role="tabpanel"
              aria-labelledby={tab.uniqueKey + "-tab"}
              key={tab.uniqueKey}
            >
              {tab && tab.questions && tab.questions.length > 0 && (
                <AdminQuestionsList {...this.props} questions={tab.questions} />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
