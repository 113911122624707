import { useField } from "formik";
import React from "react";
import { Booking } from "../booking/Booking.model";
import { Label } from "../components/common/Form/elements/FormInputLabel";
import { EBTextInputV2 } from "../components/common/input/TextLine.input";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { User } from "../user/User";
import { VideoSessionInput } from "./VideoSession.input";

interface BookingInputProps {
  user: User;
  name?: string;
}

export function BookingInput(props: BookingInputProps) {
  const namePrefix = props.name ? `${props.name}.` : "";
  let [field] = useField(props.name ?? "");
  let booking = field.value as Booking;
  return (
    <div>
      <div className="row">
        <div className="col">
          <Label label={"Title:"} required={true} />
          <EBTextInputV2
            name={`${namePrefix}bcd.dl`}
            placeholder="Title to be displayed to the users."
            required={true}
            maxLength={100}
          />
        </div>
      </div>

      {booking.bookingTarget.targetType ==
        AttachmentTargetType.VIDEO_SESSION && (
        <VideoSessionInput
          user={props.user}
          name={`${namePrefix}specificBookingTypeModel`}
        />
      )}
    </div>
  );
}
