import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React from "react";
import { HTMLProps } from "../model/HTMLProps";

export interface Faq {
  dl: React.ReactNode;
  dd: React.ReactNode;
}

interface FaqListProps extends HTMLProps {
  faqs: Faq[];
}
export function FaqList(props: FaqListProps) {
  let items: any[] = props.faqs.map((faq) => {
    return {
      label: <h6 className="my-3">{faq.dl}</h6>,
      children: faq.dd,
    };
  });

  return (
    <Collapse
      className="w-100"
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} className="py-3" />
      )}
      expandIconPosition="right"
      style={{ background: "transparent" }}
      items={items}
    />
  );
}
