import { AnswersType } from "../model/AnswerType.enum";
import { GenericFeedbackAnswer } from "../model/requests/InterviewRoundFeedback";

export class FormHelper {
  public static isAnswerPresent(answer: GenericFeedbackAnswer): boolean {
    return (
      answer &&
      answer.answer != undefined &&
      (answer.question.type !== AnswersType.BOOL ||
        (answer.question.type === AnswersType.BOOL && answer.answer != "na"))
    );
  }
}
