export class StringHelper {
  public static generateSlug(str: string): string {
    let slug = str
      .toLowerCase()
      .replace(/[^a-z0-9 ]/gi, "")
      .trim()
      .replaceAll(" ", "-");
    if (slug.length == 0) {
      return "-";
    }
    return slug;
  }
}
