import { Typography } from "antd";
import { DataNode } from "antd/es/tree";
import React from "react";
import { AddTitle } from "../../components/common/Add.title";
import { ArrowDownIcon } from "../../components/common/ArrowDown.Icon";
import { ArrowUpIcon } from "../../components/common/ArrowUp.Icon";
import { DeleteTitle } from "../../components/common/Delete.title";
import { EBButtonV2 } from "../../components/common/EBButtonV2";
import { ApiLinkedButtonV2 } from "../../components/common/Form/ApiLinked.button";
import {
  TreeComponent,
  TreeNodeKey,
} from "../../components/common/Tree.Component";
import { AddIcon } from "../../icon/Add.Icon";
import { CheckIcon } from "../../icon/Check.Icon";
import { User, UserBasicDetails } from "../../user/User";
import { MoveDirectionEnum, PathStep } from "./Offering.Path.Model";
import { OfferingPathStepAPI } from "./Offering.PathStep.api";
import "./Offerings.Path.Steps.Edit.Sidebar.css";
import { PathStepHelper } from "./PathStepHelper";

const { Text, Link } = Typography;

export interface StepContextMenuItem {
  dl: React.ReactNode;
  onClick: (step: PathStep) => Promise<void>;
  shouldShow: (step: PathStep) => boolean;
}

interface OfferingsPathStepsEditSidebarProps {
  user: User;
  offeringId: string;
  steps: PathStep[];
  selectedStep?: PathStep;
  onStepSelect: (step: PathStep) => void;
  onStepAdd?: (step: PathStep, parentStep?: PathStep) => void;
  onStepDelete?: (step: PathStep) => void;
  onStepMoveUp?: (step: PathStep) => void;
  onStepMoveDown?: (step: PathStep) => void;
  contextMenuItems?: StepContextMenuItem[];
  showCompleted?: boolean;
}

export function OfferingsPathStepsEditSidebar(
  props: OfferingsPathStepsEditSidebarProps
) {
  let treeData = getTreeData(props.steps, props.contextMenuItems);
  return (
    <div className="OfferingsPathStepsEditSidebar py-3">
      {
        <>
          {(!props.steps || props.steps.length === 0) && (
            <div className="alert-warning alert rounded-0">
              No steps added. Please start adding from below
            </div>
          )}

          <TreeComponent
            treeData={treeData}
            selectedKey={props.selectedStep?.id}
            onSelect={function (keys: TreeNodeKey[]): void {
              let step = PathStepHelper.searchStepById(
                props.steps,
                Number(keys[0])
              );
              if (step) {
                props.onStepSelect(step);
              }
            }}
          />
          {props.onStepAdd && (
            <div className="row border-top border-1 mt-4 pt-4 ">
              <div className="col text-center">
                <ApiLinkedButtonV2
                  buttonProps={{
                    type: "secondary",
                    text: {
                      normal: (
                        <>
                          <AddIcon /> Add Top Level Step
                        </>
                      ),
                      submitting: "Adding...",
                    },
                  }}
                  api={OfferingPathStepAPI.add(
                    props.user.basicDetails.id,
                    props.offeringId
                  )}
                  uniqueKey={""}
                  successCallback={(newAddedStep: PathStep) => {
                    if (props.onStepAdd) {
                      props.onStepAdd(newAddedStep);
                    }
                    return Promise.resolve();
                  }}
                />
              </div>
            </div>
          )}
        </>
      }
    </div>
  );

  function getTreeData(
    steps: PathStep[],
    contextMenuItems?: StepContextMenuItem[]
  ): DataNode[] {
    if (!steps) {
      return [];
    }
    const treeData: DataNode[] = [];
    for (let step of steps) {
      treeData.push({
        title: (data: DataNode) => (
          <div className="fs-6 p-1">
            <div className="path-steps-sidebar-item">
              {step.dl ?? "Untitled Step"}{" "}
              <span className="text-muted">
                {step.steps &&
                  step.steps.length > 0 &&
                  `(${step.steps.length})`}
              </span>
              {props.showCompleted && step.completedAt && (
                <span className="text-success ml-1">
                  <CheckIcon />
                </span>
              )}
              {(props.onStepAdd ||
                props.onStepDelete ||
                props.onStepMoveDown ||
                props.onStepMoveUp ||
                props.contextMenuItems) && (
                <span className="float-right path-steps-sidebar-item-menu-hide">
                  <div className="btn-group dropstart dropdown-hover">
                    <a
                      className="fs-5 mr-2 mb-1"
                      data-bs-toggle=""
                      aria-expanded="false"
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    >
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </a>
                    <ul
                      className="dropdown-menu dropdown-menu-start"
                      style={{ right: -5, left: "auto", top: 15 }}
                    >
                      {props.onStepAdd && (
                        <li>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={(e) => {
                              OfferingPathStepAPI.addSubStep(
                                props.user.basicDetails.id,
                                props.offeringId,
                                step
                              )
                                .execute()
                                .then((createdStep) => {
                                  if (props.onStepAdd) {
                                    props.onStepAdd(createdStep, step);
                                  }
                                });
                              e.preventDefault();
                              e.stopPropagation();
                              return false;
                            }}
                          >
                            <AddTitle text="Add Sub-Step" />
                          </button>
                        </li>
                      )}
                      {props.onStepMoveUp && (
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(event) => {
                              OfferingPathStepAPI.move(
                                props.user.basicDetails.id,
                                props.offeringId,
                                step.id + "",
                                MoveDirectionEnum.UP
                              )
                                .execute()
                                .then(() => {
                                  if (props.onStepMoveUp) {
                                    props.onStepMoveUp(step);
                                  }
                                });
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                          >
                            <ArrowUpIcon className="mr-1" /> Move Up
                          </a>
                        </li>
                      )}
                      {props.onStepMoveDown && (
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(event) => {
                              OfferingPathStepAPI.move(
                                props.user.basicDetails.id,
                                props.offeringId,
                                step.id + "",
                                MoveDirectionEnum.DOWN
                              )
                                .execute()
                                .then(() => {
                                  if (props.onStepMoveDown) {
                                    props.onStepMoveDown(step);
                                  }
                                });
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                          >
                            <ArrowDownIcon className="mr-1" /> Move Down
                          </a>
                        </li>
                      )}
                      {props.onStepDelete && (
                        <li>
                          <EBButtonV2
                            className="dropdown-item"
                            content={{
                              normal: <DeleteTitle text="Delete" />,
                            }}
                            onClick={() => {
                              if (props.onStepDelete) {
                                props.onStepDelete(step);
                              }
                            }}
                          />
                        </li>
                      )}
                      {props.contextMenuItems &&
                        props.contextMenuItems.map((item, index) => (
                          <>
                            {item.shouldShow(step) && (
                              <li key={index}>
                                <button
                                  type="button"
                                  className="dropdown-item"
                                  onClick={(event) => {
                                    item.onClick(step);
                                    event.stopPropagation();
                                    event.preventDefault();
                                  }}
                                >
                                  {item.dl}
                                </button>
                              </li>
                            )}
                          </>
                        ))}
                    </ul>
                  </div>
                </span>
              )}
            </div>
          </div>
        ),
        key: step.id,
        children: step.steps ? getTreeData(step.steps) : undefined,
      });
    }

    return treeData;
  }
}
