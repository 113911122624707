import { Time } from "./Calendar.model";

export class TimeHelper {
  public static getTimeList(stepInMins: number): Time[] {
    let timeList: Time[] = [];
    for (var mins = 0; mins < 24 * 60; mins += stepInMins) {
      let curHour = Math.floor(mins / 60);
      let curMins = mins % 60;

      timeList.push({
        h: curHour,
        m: curMins,
        displayString: this.getDisplayString({ h: curHour, m: curMins }),
      });
    }
    return timeList;
  }

  public static getDisplayString(time: Time): string {
    let curHour = time.h;
    let curMins = time.m;

    var strHour = "";
    if (curHour < 10) {
      strHour = "0" + curHour;
    } else {
      strHour = "" + curHour;
    }

    var strMins = "";
    if (curMins < 10) {
      strMins = "0" + curMins;
    } else {
      strMins = "" + curMins;
    }

    return strHour + ":" + strMins;
  }
}
