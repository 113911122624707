import { Form, Formik, FormikValues } from "formik";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { ApiErrorHelper } from "../../../helpers/ApiError.helper";
import { ButtonDisplayProps } from "../../../model/views/Button.props";
import { Api } from "../ApiLinkedButton";
import { EBButton } from "../EBButton";
import { EBModal } from "../EBModal";

interface ApiLinkedButtonV2Props {
  buttonProps: ButtonDisplayProps;
  api: Api<any, any>;
  uniqueKey: string;

  apiFormBody?: React.ReactNode;
  formModalSize?: "sm" | "xl";
  initialValues?: any;
  disabled?: boolean;
  successCallback?: (response: any) => Promise<void>;
}

export function ApiLinkedButtonV2(mainProps: ApiLinkedButtonV2Props) {
  let [isSubmitting, setSubmitting] = useState<boolean>(false);
  let modalId = mainProps.uniqueKey + "-modal";

  return (
    <>
      <Formik
        initialValues={mainProps.initialValues ?? {}}
        onSubmit={function (values: FormikValues) {
          setSubmitting(true);
          mainProps.api
            .executeV2(values)
            .then((response) => {
              if (mainProps.successCallback) {
                return mainProps.successCallback(response);
              }
              return Promise.resolve();
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {(formProps) => (
          <Form>
            {mainProps.apiFormBody ? (
              <>
                <EBModal
                  BodyComponent={<div>{mainProps.apiFormBody}</div>}
                  id={modalId}
                  modalSize={mainProps.formModalSize}
                  primaryButtonProps={{
                    classes: [
                      "btn mr-2",
                      mainProps.buttonProps.type
                        ? "btn-" + mainProps.buttonProps.type
                        : "btn-primary",
                    ],
                    type: "submit",
                    text: mainProps.buttonProps.text,
                    disabled: isSubmitting || mainProps.disabled,
                    loading: isSubmitting,
                  }}
                />
                <EBButton
                  classes={[
                    "btn mr-2",
                    mainProps.buttonProps.type
                      ? "btn-" + mainProps.buttonProps.type
                      : "btn-primary",
                  ]}
                  text={mainProps.buttonProps.text}
                  disabled={isSubmitting || mainProps.disabled}
                  loading={isSubmitting}
                  otherProps={{
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#" + modalId,
                  }}
                />
              </>
            ) : (
              <EBButton
                classes={[
                  "btn mr-2",
                  mainProps.buttonProps.type
                    ? "btn-" + mainProps.buttonProps.type
                    : "btn-primary",
                ]}
                text={mainProps.buttonProps.text}
                disabled={isSubmitting || mainProps.disabled}
                loading={isSubmitting}
                type="submit"
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
