import { Field } from "formik";
import React from "react";
import { Label } from "../../components/common/Form/elements/FormInputLabel";
import {
  Mode,
  ModeBasedString,
} from "../../components/common/Form/model/mode.model";
import { EBSelect } from "../../components/common/eb-ui-components";
import { ModeHelpers } from "../../helpers/Mode.helper";
import { SelectItem } from "../../model/views/Select.props";
import { FormLineItemComponentProps } from "./FormLineItemComponent";

let booleanOptions: SelectItem[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
  { value: "na", label: "Not applicable or Dont want to answer" },
];

function mapToBooleanOption(input?: string): SelectItem | undefined {
  if (!input) {
    return undefined;
  }
  for (let option of booleanOptions) {
    if (option.value === input) {
      return option;
    }
  }
  return undefined;
}

interface FormLineItemBoolTemplateData {
  displayTitle: ModeBasedString;
}

interface FormLineItemBoolInputData {
  value: string;
}

export function FormLineItemBoolComponent(props: FormLineItemComponentProps) {
  let templateData = props.templateData as FormLineItemBoolTemplateData;
  let inputData = props.inputData as FormLineItemBoolInputData;

  function mapToInput(selectItem: SelectItem): string {
    return selectItem.value;
  }

  return (
    <>
      {props.mode == Mode.EDIT && (
        <>
          <Label
            label={ModeHelpers.getString(props.mode, templateData.displayTitle)}
            required={props.required}
          />
          <Field
            name={props.name + ".value"}
            component={EBSelect}
            options={booleanOptions}
            isMulti={false}
            mappingRequired={true}
            selectToApiMapper={mapToInput}
            apiToSelectMapper={mapToBooleanOption}
            required={props.required}
          />
        </>
      )}
      {props.mode == Mode.VIEW &&
        inputData?.value != undefined &&
        inputData?.value !== "na" && (
          <>
            <span className="bold mr-2">
              {ModeHelpers.getString(props.mode, templateData.displayTitle)}
            </span>

            {mapToBooleanOption(inputData?.value)?.label}
          </>
        )}
    </>
  );
}
