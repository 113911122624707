import { Form, Formik } from "formik";
import React from "react";
import { LoginAPI } from "../../../api/LoginAPI";
import { OrderAPI } from "../../../api/OrderAPI";
import { PaymentHelper } from "../../../helpers/Payment.helper";
import { WindowHelpers } from "../../../helpers/Window.helper";
import { ApiErrorHelper } from "../../../helpers/ApiError.helper";

interface OrderCompletePaymentButtonProps {
  text?: string;
  inProgressText?: string;
  orderId: string;
}

export function OrderCompletePaymentButton(
  props: OrderCompletePaymentButtonProps
) {
  let text = props.text ? props.text : "Register";
  let inProgressText = props.inProgressText
    ? props.inProgressText
    : "Processing. Please wait...";
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        LoginAPI.loginIfNeeded()
          .then((user) => {
            return PaymentHelper.processOrderPayment(props.orderId, user);
          })
          .then((orderPaymentSuccess) => {
            if (orderPaymentSuccess.redirectUrl) {
              WindowHelpers.redirect(orderPaymentSuccess.redirectUrl);
            } else {
              alert(
                "Your order has been confirmed. Please check your email for further details"
              );
            }
          })
          .catch((error) => {
            let message = ApiErrorHelper.getDisplayErrorMessage(error);
            alert(message);
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formProps) => (
        <Form>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={formProps.isSubmitting}
          >
            {formProps.isSubmitting ? inProgressText : text}
          </button>
        </Form>
      )}
    </Formik>
  );
}
