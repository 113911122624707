import { Col, Row } from "antd";
import React from "react";
import { ApiLinkedButtonV3 } from "../components/common/Form/ApiLinked.button.V3";
import { reloadWindowPromise } from "../components/common/reloadCallback";
import { Skill } from "../model/Skill";
import { SkillAPI } from "./SkillAPI";

interface SkillAdminActionsProps {
  skill: Skill;
}

function ToggleMarkedForFilter(props: SkillAdminActionsProps) {
  return !props.skill.markedUseForFilterAt ? (
    <ApiLinkedButtonV3
      api={SkillAPI.update(props.skill.id)}
      initialValues={{
        ...props.skill,
        markedUseForFilterAt: Date.now(),
      }}
      uniqueKey={"skill" + props.skill.id + "-enable-use-filter"}
      successCallback={reloadWindowPromise}
      buttonProps={{
        content: {
          normal: "Enable for filter",
          submitting: "Please Wait...",
        },
        className: "btn-sm btn-success",
      }}
    />
  ) : (
    <ApiLinkedButtonV3
      api={SkillAPI.update(props.skill.id)}
      initialValues={{
        ...props.skill,
        markedUseForFilterAt: undefined,
      }}
      uniqueKey={"skill" + props.skill.id + "-disable-use-filter"}
      successCallback={reloadWindowPromise}
      buttonProps={{
        content: {
          normal: "Disable for filter",
          submitting: "Please Wait...",
        },
        className: "btn-sm btn-danger",
      }}
    />
  );
}

export function SkillAdminActions(props: SkillAdminActionsProps) {
  return (
    <Row gutter={[16, 10]}>
      <Col>
        <ToggleMarkedForFilter {...props} />
      </Col>
    </Row>
  );
}
