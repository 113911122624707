import React, { useEffect, useState } from "react";
import { InterviewFeedbackQuestions } from "../../components/common/InterviewFeedbackQuestions";
import { InterviewRoundFeedbackQuestion } from "../../model/requests/InterviewRoundFeedback";
import { QuestionCategoryResponse } from "../../model/response/QuestionCategory.response";
import { QuestionsCategoriesAPI } from "../../question/QuestionsCategoriesAPI";
import { FormLineItemComponentProps } from "./FormLineItemComponent";

interface FormLineItemQuestionFeedbacksTemplateData {
  singleQuestionTitle: string;
}

interface FormLineItemQuestionFeedbacksInputData {
  questions?: InterviewRoundFeedbackQuestion[];
}

export function FormLineItemQuestionFeedbacksComponent(
  props: FormLineItemComponentProps
) {
  let [allQuestionCategories, setAllQuestionCategories] = useState<
    QuestionCategoryResponse[]
  >([]);
  useEffect(function () {
    QuestionsCategoriesAPI.getAllQuestionsCategories().then((response) => {
      setAllQuestionCategories(response);
    });
  }, []);
  let templateData =
    props.templateData as FormLineItemQuestionFeedbacksTemplateData;
  let inputData = props.inputData as FormLineItemQuestionFeedbacksInputData;

  return (
    <div className="FormLineItemQuestionFeedbacksComponent">
      {allQuestionCategories && allQuestionCategories.length > 0 && (
        <InterviewFeedbackQuestions
          allQuestionCategories={allQuestionCategories}
          questions={inputData ? inputData.questions : []}
          name={props.name}
          mode={props.mode}
          user={props.user}
        />
      )}
    </div>
  );
}
