import { Currency, Price } from "../../model/Price";
import { Target } from "../../model/target.model";
import { UserBasicDetails } from "../../user/User";

export interface LedgerEntry {
  id: string;
  user: UserBasicDetails;
  creditAmount: Price;
  debitAmount: Price;
  createdAt: Date;
  creationComments: string;
  source: Target;
  type: LedgerEntryType;
}

export interface LedgerBalance {
  user: UserBasicDetails;
  balance: Price;
}

export enum LedgerEntryType {
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
}

export interface LedgerSummary {
  totalCredits: Price;
  totalDebits: Price;
  balance: Price;

  currency: Currency;
}

export interface Ledger {
  entries: LedgerEntry[];
  summaries: LedgerSummary[];
}
