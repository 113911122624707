import React from 'react'
import { publicLinkConstants } from '../../constants';
import './IndependenceDayEvent.css'

interface SingleFaqProps {
    id: string;
    title: string;
    text: string;
}

interface FaqsProps {
    faqs: SingleFaqProps[]
}

function SingleFaq(props: SingleFaqProps) {
    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                <h4 className="panel-title">
                    <a data-bs-toggle="collapse" href={"#" + props.id} aria-expanded="false" className="collapsed"> 
                        {props.title}
                    </a> 
                </h4>
            </div>
            <div id={props.id} className="panel-collapse collapse" aria-expanded="false" role="tablist">
                <div className="panel-body">
                    {/* <div className="img-accordion"> <img src="https://img.icons8.com/color/81/000000/person-female.png" alt="" /> </div> */}
                    <div className="text-accordion">
                        <p>{props.text}</p>
                    </div>
                </div> 
            </div>
        </div>
    );
}

function Faqs(props: FaqsProps) {
    return (
        <div className="accordion_one">
            <div className="panel-group">
                {props.faqs.map((faq, index) => (
                   <SingleFaq {...faq} /> 
                ))}                
            </div>
        </div>
    )
}

export class IndependenceDayEvent extends React.Component {

    public render() {
        let faqsProps: FaqsProps = {
            faqs: [
                {id: "faq1", title: "What is EngineBogie?", text: "EngineBogie is a top mentorship platform. Not everyone is privileged enough to have a good mentor in their career or life. And our mission is exactly to solve this. We are going to revolutionize the mentorship space by giving everyone a chance to have a mentor of their own choice. For different areas, they can choose from different mentors."},
                {id: "faq7", title: "What do we offer?", text: "Whatever kind of mentorship session you need. Be it mock interviews, career guidance or anything. We have got you covered. We have various experts with different backgrounds and expertise. Just find the one best suited for you and schedule a session."},
                {id: "faq6", title: "Who are experts on this platform?", text: "Our experts are first class professionals and potential leaders of their industry who believe that Mentorship is a universal experience and not just a job. All of our experts are working at top companies."},
                {id: "faq2", title: "Are there any hidden charges?", text: "Yes, we are doing this as part of our Independence day celebration. Winners will not have to pay anything. they will get their session completely FREE!"},
                {id: "faq3", title: "How will you decide the winners?", text: "Winners will be decided based on the quality of their posts. Better the content of the post, the higher the chances of winning"},
                {id: "faq4", title: "How many winners will be there?", text: "There will be around 20+ winners. All of them will get the mentorships from our experts."},
                {id: "faq5", title: "How will I get to know about the results?", text: "Winners list will be posted here on this page and we will also contact them to schedule their FREE sessions."},
            ]
        };

        return (
            <div className="main-container">

<div className="px-4 py-4 text-center">
    {/* <img className="d-block mx-auto mb-4" src="/docs/5.1/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57" /> */}
    <h1 className="display-5 fw-bold">🇮🇳 Independence Day Celebration: FREE Mentorships</h1>
    <div className="col-lg-12 mx-auto">
      <p className="lead mb-4">To get a free mentorship from one of our top experts, you just need to create a post online talking about Mentorship. 
      There are no specific requirements about what can be posted.
      You can post your thoughts on what mentorship means, what can be a good mentorship, or anything related. Only requirement is it has to be related to mentorship.</p>
      <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <a className="btn btn-primary btn-lg px-4 gap-3" href={publicLinkConstants.FIND_EXPERTS}>Checkout Experts Here</a>
      </div>
    </div>
  </div>

<div className="card text-dark bg-light">
  <h5 className="card-header">Winner</h5>
  <div className="card-body">
    <h5 className="card-title">Shubham Vashishtha </h5>
    <p className="card-text">Shubham wins free 1:1 mentorship session with one of our experts.</p>
    <a href="https://www.linkedin.com/feed/update/urn:li:activity:6831674947656585216/" target="_blank" className="btn btn-primary">
        View Shubham's Post
    </a>
  </div>
</div>

  <div className="list-group mt-4">
  <h5 className="text-center">Steps to participate:</h5>
  <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
    {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0"> */}
    <div className="d-flex gap-2 w-100 justify-content-between">
      <div>
        <h6 className="mb-0">Step 1:</h6>
        <p className="mb-0 opacity-75">
            Create a post about mentorship on LinkedIn. Make sure you add following at the end of the post. Otherwise your post will not be considered
            <textarea className="form-control" id="exampleFormControlTextarea1" rows={3}>
                {"Get FREE mentorship at https://enginebogie.com/independence-day-offer or checkout experts at https://enginebogie.com/find/experts \n#enginebogie #mentorship #happyindependenceday"}
            </textarea>
        </p>
      </div>
    </div>
  </a>
  <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
    {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0"> */}
    <div className="d-flex gap-2 w-100 justify-content-between">
      <div>
        <h6 className="mb-0">Step 2:</h6>
        <p className="mb-0 opacity-75">After creating your post, send us the details of it via <a href="https://forms.gle/rQpHY2QwSeTeJg7u7">https://forms.gle/rQpHY2QwSeTeJg7u7</a></p>
      </div>
    </div>
  </a>
  <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
    {/* <img src="https://github.com/twbs.png" alt="twbs" width="32" height="32" className="rounded-circle flex-shrink-0"> */}
    <div className="d-flex gap-2 w-100 justify-content-between">
      <div>
        <h6 className="mb-0">Step 3:</h6>
        <p className="mb-0 opacity-75">That's it. We will review all the posts one by one and will choose top posts by 16th Aug.
         Winners list will be posted here on this page and we will also contact them to schedule their FREE sessions.</p>
      </div>
    </div>
  </a>
</div>
<div className="my-5 alert alert-danger text-center">
    Event is over now.
</div>

<h5 className="text-center">Frequently Asked Questions:</h5>
<Faqs {...faqsProps} />

            </div>
        );
    }

}
