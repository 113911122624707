import React from "react";
import { EngineName } from "../Global.Names";

export function CreditsPageContent() {
  return (
    <div className="CreditsPageContent main-container bg-main-body h-100">
      <h5>{EngineName.SENTENCE_START_PLURAL}:</h5>
      We are thankful to all our {EngineName.SENTENCE_MID_PLURAL} for providing
      their great services on this platform.
      <br />
      <br />
      <h5>Images:</h5>
      We use images from <a href="https://www.freepik.com/">Freepik</a>
    </div>
  );
}
