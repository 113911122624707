const DEFAULT_USER_NAME = "Guest";

const userHelpers = {
  getName: function (user) {
    if (user.firstName == null && user.lastName == null) {
      return DEFAULT_USER_NAME;
    }
    var name = "";
    if (user.firstName != null) {
      name = user.firstName;
    }
    if (user.lastName != null) {
      name += " " + user.lastName;
    }
    return name.trim();
  },

  getProfilePicUrl: function (user) {
    if (user.dp && user.dp.locationType == "URL") {
      return user.dp.data ? user.dp.data : "/img/user-default.jpg";
    }
    return "/img/user-default.jpg";
  },
};

export default userHelpers;
