import axios from "axios";
import { apiLinkConstants } from "../constants";
import { ApiUrlHelpers } from "../helpers/Helpers";
import {
  UserContributions,
  UserContributionsSummary,
} from "../user/UserContributions";

export class UserContributionsAPI {
  public static getUserContributions(
    userId: string
  ): Promise<UserContributions> {
    return new Promise<UserContributions>((resolve, reject) => {
      axios
        .get(ApiUrlHelpers.getContributionsURLForUser(userId))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getExpertContributionsSummary(): Promise<{
    [id: string]: UserContributionsSummary;
  }> {
    return new Promise<{ [id: string]: UserContributionsSummary }>(
      (resolve, reject) => {
        axios
          .get(apiLinkConstants.API_USER_CONTRIBUTIONS_ALL)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      }
    );
  }

  public static getUserContributionsSummary(
    userId: string
  ): Promise<UserContributionsSummary> {
    return new Promise<UserContributionsSummary>((resolve, reject) => {
      axios
        .get(ApiUrlHelpers.getContributionsSummartURLForUser(userId))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
