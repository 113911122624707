import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { ReviewData } from "../components/common/Review.button";
import { admiApiLinkConstants } from "../constants";
import { CancelRequest } from "../model/ExpertSessionCancelRequest";
import { VideoSessionConstants } from "./VideoSessions.Constants";

export class VideoSessionApi {
  public static cancel(sessionId: string): Api<CancelRequest, void> {
    return new Api<CancelRequest, void>(
      VideoSessionConstants.API_VIDEO_SESSIONS_SINGLE_SCHEDULE_CANCEL.replace(
        "{sessionId}",
        sessionId
      ),
      ApiMethod.POST
    );
  }

  public static markSessionMenteeNoShow(sessionId: string): Api<void, void> {
    return new Api<void, void>(
      VideoSessionConstants.API_VIDEO_SESSIONS_SINGLE_MARK_NO_SHOW_EXPERT.replace(
        "{sessionId}",
        sessionId
      ),
      ApiMethod.POST
    );
  }

  public static createPrr(sessionId: string): Api<ReviewData, void> {
    return new Api<ReviewData, void>(
      admiApiLinkConstants.API_ADMIN_VIDEO_SESSION_CREATE_PRR.replaceAll(
        "{videoSessionId}",
        sessionId
      ),
      ApiMethod.POST
    );
  }
}
