import { Col, Row } from "antd";
import React from "react";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { WindowHelpers } from "../helpers/Window.helper";
import { GoogleColorIcon } from "../icon/GoogleColor.Icon";
import { LinkedInColorIcon } from "../icon/LinkedInColor.Icon";

export function EBLoginPageContent() {
  function navigateToGoogle() {
    WindowHelpers.redirect("/oauth2/authorization/google");
  }

  function navigateToLinkedIn() {
    WindowHelpers.redirect("/oauth2/authorization/linkedin");
  }
  let uaLc = window.navigator.userAgent.toLowerCase();
  let isLinkedinApp = uaLc.includes("iphone") && uaLc.includes("linkedin");

  return (
    <div className="EBLoginPageContent row h-100">
      <div className="col bg-black event-data-desktop">
        <div className="h-25">
          <center className="pt-5">
            <img
              className="img-fluid"
              width={70}
              src="/img/login/logo.png"
              alt=""
            />
          </center>
        </div>
        <div className="h-75">
          <center className="position-relative top-10p">
            <h4 className="text-light-primary mb-5">#LearnTogether</h4>
            <h1 className="text-primary">Welcome to enginEBogie</h1>
            <h4 className="text-light-primary mt-5">#GrowTogether</h4>
          </center>
        </div>
      </div>
      <div className="col">
        <div className="h-30">
          <center className="pt-8">
            <h1 className="">Login</h1>
          </center>
        </div>
        <div className="h-70">
          <div className="position-relative top-10p">
            {!isLinkedinApp && (
              <Row justify={"center"}>
                <Col>
                  <EBButtonV2
                    className="btn-lg btn-light border-secondary login-button"
                    content={{
                      normal: (
                        <>
                          <span className="mr-2">
                            <GoogleColorIcon></GoogleColorIcon>
                          </span>
                          Continue with Google
                        </>
                      ),
                    }}
                    onClick={() => {
                      navigateToGoogle();
                    }}
                  ></EBButtonV2>
                </Col>
              </Row>
            )}

            <Row className="mt-4" justify={"center"}>
              <Col>
                {" "}
                <EBButtonV2
                  className="btn-lg btn-light border-secondary login-button"
                  content={{
                    normal: (
                      <>
                        <span className="mr-2">
                          <LinkedInColorIcon />
                        </span>
                        Continue with LinkedIn
                      </>
                    ),
                  }}
                  onClick={() => {
                    navigateToLinkedIn();
                  }}
                ></EBButtonV2>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}
