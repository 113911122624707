import React from "react";
import Moment from "react-moment";
import { User } from "../user/User";
import { UserLink } from "../user/User.link";
import { UserAdminActionsComponent } from "./UserAdminActions.Component";

interface UserListItemComponentProps {
  user: User;
  showAdminActions?: boolean;
}

export function UserListItemComponent(props: UserListItemComponentProps) {
  return (
    <div className={`UserListItemComponent`}>
      <div className={`row gy-2`}>
        <div className="col-md-2">
          <UserLink user={props.user.basicDetails} />
        </div>
        <div className="col-md-3">Id: {props.user.basicDetails.id}</div>
        <div className="col-md-2">{props.user.contactDetails?.email}</div>
        <div className="col-md-1">{props.user.contactDetails?.phone}</div>
        <div className="col-md-1">
          Creation:
          <Moment format="DD-MM-YYYY HH:mm">
            {props.user.basicDetails.createdAt}
          </Moment>
        </div>

        {props.user.basicDetails.expertDetails?.onboardedAt && (
          <div className="col-md-1">
            Expert Onboarding:
            <Moment format="DD-MM-YYYY HH:mm">
              {props.user.basicDetails.expertDetails?.onboardedAt}
            </Moment>
          </div>
        )}

        {props.showAdminActions && (
          <div className="col-auto">
            <UserAdminActionsComponent user={props.user} />
          </div>
        )}
      </div>
    </div>
  );
}
