import React, { useEffect, useState } from "react";
import { CallbackButton } from "../components/common/ApiLinkedButton";
import CopyClipboardTextArea from "../components/common/CopyCliboard.textarea";
import { Loader } from "../components/common/Loader.component";
import { ImageApi } from "../image/Image.api";
import { ImageGeneratorResponse } from "../image/Image.model";
import { ReviewV1 } from "../model/Reviews";
import { ShareApi } from "./Share.api";
import { ShareableContent } from "./Share.model";

interface ReviewShareComponentProps {
  review: ReviewV1;
}

export function ReviewShareComponent(props: ReviewShareComponentProps) {
  let [imageResponse, setImageResponse] = useState<
    ImageGeneratorResponse | undefined
  >();
  let [shareContentResponse, setShareContentResponse] = useState<
    ShareableContent | undefined
  >();
  let [loading, setLoading] = useState<boolean | undefined>();

  useEffect(() => {
    setLoading(true);
    setShareContentResponse(undefined);
    ImageApi.generateReviewShareableImage(props.review.id!)
      .then((response) => {
        setImageResponse(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.review]);

  function createSharePostContent(): Promise<void> {
    return ShareApi.createReviewShareableContent(props.review.id!).then(
      (shareResponse) => {
        setShareContentResponse(shareResponse);
        return Promise.resolve();
      }
    );
  }

  return (
    <div className="ReviewShareComponent">
      {loading && <Loader />}
      {!loading && imageResponse && (
        <div className="row">
          <div className="col-md-6 mb-2">
            <img
              src={imageResponse.image.data}
              width={"100%"}
              style={{ maxWidth: 800 }}
            />
          </div>
          <div className="col-md-6">
            <CallbackButton
              buttonProps={{
                text: {
                  normal: "Create Content to Share For This Review",
                  submitting: "Creating!!",
                },
              }}
              callback={createSharePostContent}
              uniqueKey={"review-" + props.review.id + "-share"}
            />
            {shareContentResponse && (
              <div className="mt-2 fs-3">
                <CopyClipboardTextArea
                  initialValue={shareContentResponse.textContent}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
