import React from "react";
import ReactPlayer from "react-player";
import { ContentItemDataYouTube } from "../../Content.model";
import { ContentItemViewProps } from "../ContentItem.model";

export function ContentItemViewYouTubeVideo(props: ContentItemViewProps) {
  let youTubeData = props.data as ContentItemDataYouTube;

  return (
    <div className="ContentItemViewYouTubeVideo">
      <ReactPlayer url={youTubeData.url} className="w-100" height={500} />
    </div>
  );
}
