import { useField } from "formik";
import moment from "moment";
import React from "react";
import {
  EBSelectOption,
  EBSelectOptionDataType,
  EBSelectV2,
} from "../components/common/Form/EBSelectV2";
import { SelectorProps } from "../components/common/Selectors";
import { FormikHelpers } from "../helpers/Formik.Helpers";
import { LinkClickHandler } from "../links/Link.ClickHandler";
import { EBTimezone } from "./Calendar.model";

interface TZSelectorProps extends SelectorProps {
  tzs: EBTimezone[];
  isMulti?: boolean;
}

export interface TZSelectorOption extends EBSelectOptionDataType, EBTimezone {}

export function TZSelector(props: TZSelectorProps) {
  let options: EBSelectOption<TZSelectorOption>[] = [];

  props.tzs.forEach((tz: EBTimezone) => {
    options.push({
      label: tz.label,
      value: `${tz.id}`,
      data: {
        ...tz,
        selectOptionValue: `${tz.id}`,
      },
    });
  });

  let [field] = useField(props);

  let browserTzStr = moment().format("Z");
  let tzLabelToSearch = "GMT" + browserTzStr;
  let browserTz = props.tzs.find((tz) => tz.label.includes(tzLabelToSearch));

  function onPickTz(tz: EBTimezone) {
    FormikHelpers.updateField(field, props.name, tz);
  }

  return (
    <div className="row">
      <div className="col">
        {options && options.length > 0 && (
          <>
            <EBSelectV2
              label={props.label}
              name={props.name}
              required={props.required}
              options={options}
              disabled={props.disabled}
              isMulti={props.isMulti}
              isDataEqual={(d1: TZSelectorOption, d2: TZSelectorOption) => {
                return d1.id === d2.id;
              }}
            />
            {browserTz && (!field.value || field.value.id !== browserTz.id) && (
              <div className="mt-1">
                Change to{" "}
                <LinkClickHandler
                  onClick={() => {
                    onPickTz(browserTz!);
                  }}
                >
                  {browserTz.label}
                </LinkClickHandler>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
