import React from "react";
import { User } from "../user/User";
import { ExpertStudioMenuOption } from "./ExpertStudio.model";
import { UrlShareView } from "./UrlShare.view";

interface ExpertStudioSidebarComponentProps {
  activeNavId?: string;
  user?: User;
  opts: ExpertStudioMenuOption[];
}

export function ExpertStudioSidebarComponent(
  props: ExpertStudioSidebarComponentProps
) {
  return (
    <>
      <div
        className="d-flex flex-column flex-shrink-0 p-3 navbar-dark bg-dark"
        style={{ width: "280px" }}
      >
        <div className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
          <span className="fs-4 ml-3">Expert Studio</span>
        </div>
        <div className="text-white m-3 text-wrap text-break">
          <div className="nav-list">
            {props.user?.basicDetails.profileUrl && (
              <UrlShareView url={props.user?.basicDetails.profileUrl} />
            )}
          </div>
          <hr />
        </div>
        <ul className="nav nav-pills flex-column mb-auto">
          {props.opts.map((navItem) => {
            return (
              <li
                className={`nav-item ${navItem.desktopCustomClasses ?? ""}`}
                key={navItem.id}
              >
                <a
                  href={navItem.href}
                  className={`nav-link ${
                    props.activeNavId === navItem.id
                      ? "active text-dark"
                      : "text-white"
                  }`}
                  aria-current="page"
                >
                  <i className={`mr-4 ${navItem.faIconClass}`}></i>
                  {navItem.title}
                </a>
              </li>
            );
          })}
        </ul>
        <hr />
      </div>
    </>
  );
}
