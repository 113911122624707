import { useField } from "formik";
import React, { useRef } from "react";
import ReactMde, { Command, TextApi } from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { AssetLocation } from "../../../model/Picture";
import { FileUploadComponent } from "../../../upload/FileUpload.component";
import { User } from "../../../user/User";
import { EBButton } from "../EBButton";
import { EBModal } from "../EBModal";
import { MarkdownViewV1 } from "../MarkdownView";
import { FormikInputParams } from "./FormikInputParams.model";
import "./Markdown.input.css";

interface MarkdownInputProps extends FormikInputParams {
  minEditorHeight?: number;
  user: User;
}

export function MarkdownInput(props: MarkdownInputProps) {
  const currentTextApi = useRef<TextApi>();

  const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">(
    "write"
  );

  const [field, meta] = useField(props);

  function onChange(newValue: string) {
    field.onChange({
      target: {
        name: props.name,
        value: newValue,
      },
    });
  }

  const imageCommand: Command = {
    execute: (opts) => {
      currentTextApi.current = opts.textApi;
      toggleImageUploadModal();
    },
  };

  function toggleImageUploadModal() {
    document.getElementById(imageInputModalToggleButtonId)?.click();
  }

  function imagePicked(image: AssetLocation): Promise<void> {
    currentTextApi.current?.replaceSelection("![](" + image.data + ")");
    toggleImageUploadModal();
    return Promise.resolve();
  }

  let imageInputModalId =
    props.name.replaceAll(".", "-") + "-md-image-input-modal";
  let imageInputModalToggleButtonId = imageInputModalId + "-toggle-button";

  return (
    <div className="MarkdownInput">
      <EBModal
        modalSize="sm"
        BodyComponent={
          <>
            <FileUploadComponent
              onImageUpload={imagePicked}
              api={"/api/user/{userId}/file/upload/image".replaceAll(
                "{userId}",
                props.user.basicDetails.id
              )}
            />
          </>
        }
        id={imageInputModalId}
      />
      <EBButton
        classes={["d-none"]}
        otherProps={{
          "data-bs-toggle": "modal",
          "data-bs-target": "#" + imageInputModalId,
        }}
        text={{ normal: "a" }}
        id={imageInputModalToggleButtonId}
      />

      <ReactMde
        commands={{
          image: imageCommand,
        }}
        value={field.value}
        onChange={onChange}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        minEditorHeight={props.minEditorHeight}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(<MarkdownViewV1>{markdown}</MarkdownViewV1>)
        }
        childProps={{
          writeButton: {
            tabIndex: -1,
          },
        }}
      />
    </div>
  );
}
