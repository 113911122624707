let API_BASE = "/api";

export class EventsConstants {
  public static API_POSTED_BY_EVENTS =
    API_BASE + "/posted-by/{postedById}/events?fetchContext={fetchContext}";
  public static API_EVENTS = API_BASE + "/event";
  public static API_EVENT_DELETE = API_BASE + "/event/{eventId}";
  public static API_EVENT =
    API_BASE + "/event/{eventId}?fetchContext={fetchContext}";
  public static API_EVENT_DETAILS = API_BASE + "/event/{eventId}/details";
  public static API_EVENTS_DETAILS_BASIC =
    EventsConstants.API_EVENT_DETAILS + "/basic";
  public static API_EVENTS_DETAILS_INSTRUCTORS =
    EventsConstants.API_EVENT_DETAILS + "/instructors";
  public static API_EVENTS_DETAILS_TICKETS =
    EventsConstants.API_EVENT_DETAILS + "/tickets";
  public static API_EVENTS_DETAILS_PUBLISHED =
    EventsConstants.API_EVENT_DETAILS + "/published?published={published}";
}
