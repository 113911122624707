import { Api, ApiMethod } from "../../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../../constants";
import { Withdrawal, WithdrawalResponse } from "./Withdrawal.model";

export class WithDrawalApi {
  public static getUserWithdrawals(
    userId: string
  ): Promise<WithdrawalResponse> {
    let api = apiLinkConstants.API_USER_WITHDRAWALS.replaceAll(
      "{userId}",
      userId
    );
    return new Api<void, WithdrawalResponse>(api, ApiMethod.GET).execute();
  }
}
