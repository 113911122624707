import React from "react";
import { PriceHelpers } from "../../helpers/Helpers";
import { PriceView } from "../../components/common/Price.view";
import { WithdrawalSummary } from "./Withdrawal.model";
import { StatCardList } from "../../stat/Stat.Card.List";
import { HTMLProps } from "../../model/HTMLProps";
import { Stat } from "../../stat/Stat.Model";

interface WithdrawalSummaryListComponentProps {
  summaries: WithdrawalSummary[];
}

export function WithdrawalSummaryListComponent(
  props: WithdrawalSummaryListComponentProps
) {
  return (
    <div className="WithdrawalSummaryListComponent">
      {props.summaries.map((summary, index) => (
        <WithdrawalSummaryComponent
          key={index}
          summary={summary}
          className="mb-2"
        />
      ))}
    </div>
  );
}

interface WithdrawalSummaryComponentProps extends HTMLProps {
  summary: WithdrawalSummary;
}

export function WithdrawalSummaryComponent(
  props: WithdrawalSummaryComponentProps
) {
  let stats: Stat[] = [
    {
      title: "Total",
      value: <PriceView price={props.summary.total} />,
    },
    {
      title: "Paid",
      value: <PriceView price={props.summary.paid} className="text-success" />,
    },
    {
      title: "Pending",
      value: (
        <PriceView price={props.summary.pending} className="text-danger" />
      ),
    },
  ];

  return (
    <div className="LedgerSummaryComponent row">
      <StatCardList stats={stats} {...(props as HTMLProps)} />
    </div>
  );
}
