import { DownOutlined } from "@ant-design/icons";
import { Col, Dropdown, MenuProps, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { publicLinkConstants } from "../../../constants";
import { SearchTokenHelper } from "../../../helpers/Search.helper";
import { WindowHelpers } from "../../../helpers/Window.helper";
import { LinkClickHandler } from "../../../links/Link.ClickHandler";
import { Loader } from "../../../loader/Loader";
import { SearchToken } from "../../../model/search/SearchToken";
import { SortDirection, SortToken } from "../../../model/sort/SortToken";
import { SortTokenName } from "../../../model/sort/SortTokenName";
import { PageProps } from "../../../model/views/PageProps";
import { RelatedExpertsComponent } from "../../../promotion/RelatedExperts.Component";
import { Paginate } from "../../common/Paginate";
import { Center } from "../../common/layout/Center.component";
import { CharchaaAPI } from "./Charchaa.api";
import { PostResponse } from "./Charchaa.model";
import { PageTopContent } from "./PageTopContent";
import { PostFilterView } from "./PostFilterView";
import { PostListComponent } from "./PostsList.component";
import { RoundedPillButton } from "./RoundedPillButton";

export interface CharchaaPageContentProps extends PageProps {}
export function CharchaaPageContent(props: CharchaaPageContentProps) {
  let PAGE_SIZE: number = 10;
  let [posts, setPosts] = useState<PostResponse>();
  let [popularPosts, setPopularPosts] = useState<PostResponse>();
  let [totalPages, setTotalPages] = useState<number>(0);
  let [loading, setLoading] = useState<boolean>(false);
  let [page, setPage] = useState<number>(0);
  let [sortKey, setSortKey] = useState<SortTokenName>(SortTokenName.RECENT);

  let isShowMineInQp = WindowHelpers.qParam("my") === "true";
  let [searchTokens, setSearchTokens] = useState<SearchToken[]>(
    isShowMineInQp ? [SearchTokenHelper.getShowMineToken(isShowMineInQp)] : []
  );

  function fetchPosts(
    searchTokens: SearchToken[],
    sortTokens: SortToken[],
    page: number,
    size: number
  ): Promise<any> {
    setLoading(true);
    return CharchaaAPI.findAllFilteredPosts({
      searchTokens: searchTokens,
      pageParams: { page: page, size: size },
      sortTokens: sortTokens,
    }).then((postsResponse) => {
      setPosts(postsResponse);
      setTotalPages(Math.ceil(postsResponse.summary.count / PAGE_SIZE));
      setLoading(false);
    });
  }

  function fetchPopularPosts(): Promise<any> {
    return CharchaaAPI.findAllFilteredPosts({
      sortTokens: [{ name: SortTokenName.VIEW, direction: SortDirection.DESC }],
      pageParams: { page: page, size: 5 },
      useCaching: true,
    }).then((postsResponse) => {
      setPopularPosts(postsResponse);
    });
  }

  useEffect(() => {
    fetchPosts(
      searchTokens,
      [{ name: sortKey, direction: SortDirection.DESC }],
      page,
      PAGE_SIZE
    );
    fetchPopularPosts();
  }, []);

  let filtersCallback = (searchTokens: SearchToken[]): Promise<void> => {
    setPage(0);
    setSearchTokens(searchTokens);
    return fetchPosts(
      searchTokens,
      [{ name: sortKey, direction: SortDirection.DESC }],
      0,
      PAGE_SIZE
    );
  };

  function onPageChange(selectedItem: { selected: number }) {
    setPage(selectedItem.selected);
    fetchPosts(
      searchTokens,
      [{ name: sortKey, direction: SortDirection.DESC }],
      selectedItem.selected,
      PAGE_SIZE
    );
  }

  const onSortKeyChange: MenuProps["onClick"] = ({ key }) => {
    let sortKey = key as SortTokenName;
    setSortKey(sortKey);
    fetchPosts(
      searchTokens,
      [{ name: sortKey, direction: SortDirection.DESC }],
      page,
      PAGE_SIZE
    );
  };

  const items: MenuProps["items"] = [
    {
      label: <div className="p-2">Most Recent</div>,
      key: SortTokenName.RECENT,
    },
    {
      label: <div className="p-2">Most Viewed</div>,
      key: SortTokenName.VIEW,
    },
    {
      label: <div className="p-2">Most Commented</div>,
      key: SortTokenName.COMMENT,
    },
    {
      label: <div className="p-2">Most Liked</div>,
      key: SortTokenName.LIKE,
    },
  ];

  const [collapsed, setCollapsed] = useState<boolean>(true);
  return (
    <div className="main-container bg-main-body h-100">
      <Row gutter={[15, 10]}>
        <Col sm={12} md={14} lg={16} xxl={18}>
          <PageTopContent
            title={
              <>
                <i className="fa-solid fa-people-group"></i> Charchaa
              </>
            }
            otherLayout={
              <div className="text-muted py-3">
                Have a quick question? &nbsp;
              </div>
            }
            buttonText={{
              normal: (
                <>
                  <i className="fa-regular fa-comments"></i>
                  <span className="event-data-desktop"> Ask Question</span>
                </>
              ),
            }}
          ></PageTopContent>

          <Row className="mt-4">
            <Col>
              <div className="">
                <div className="mb-2">
                  <LinkClickHandler
                    className="text-dark"
                    onClick={() => {
                      setCollapsed(!collapsed);
                    }}
                  >
                    <i className="fa-solid fa-filter mr-2"></i>Filter
                  </LinkClickHandler>
                </div>
              </div>
            </Col>
            <Col flex="auto" className="px-4">
              <hr className="text-dark border border-secondary border-1 " />
            </Col>
            <Col>
              <Dropdown
                menu={{
                  items,
                  onClick: onSortKeyChange,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    Sort by
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Col>
          </Row>
          <div>
            {!collapsed && (
              <PostFilterView callback={filtersCallback} user={props.user} />
            )}
          </div>
          <div>
            {loading && (
              <Center>
                <Loader />
              </Center>
            )}

            {!loading && posts && (
              <PostListComponent popularPost={false} postResponse={posts} />
            )}

            <div className="hstack gap-2 mt-2">
              <div></div>
              <div className="ms-auto">
                <Paginate onPageChange={onPageChange} pageCount={totalPages} />
              </div>
            </div>
          </div>
        </Col>

        <Col sm={12} md={10} lg={8} xxl={6}>
          <RoundedPillButton
            redirectUrl={publicLinkConstants.CHARCHAA_POST_ADD}
            title=" Start a detailed charchaa..."
          ></RoundedPillButton>

          {popularPosts && (
            <div className="card rounded-5 mt-3 p-3">
              <PostListComponent
                popularPost={true}
                dl={
                  <div className="mt-2 bold text-muted">
                    <span> Popular Posts</span>
                  </div>
                }
                postResponse={popularPosts}
              />
            </div>
          )}

          <RelatedExpertsComponent context={{ topics: [] }} className="mt-3" />
        </Col>
      </Row>
    </div>
  );
}
