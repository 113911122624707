import { message } from "antd";
import React, { useRef } from "react";
import { EBTextAreaV2 } from "../components/common/Form/FormElements";
import { EBStep, EBStepper } from "../components/common/StepperV2.Component";
import { Tab, TabView } from "../components/common/Tab.view";
import {
  EBTextInput,
  FormInputLabel,
} from "../components/common/eb-ui-components";
import { DurationTimestampInput } from "../components/common/input/DurationTimestamp.input";
import { FormikHiddenInput } from "../components/common/input/FormikHiddentInput";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { MarkdownInput } from "../components/common/input/Markdown.input";
import { ApiErrorHelper } from "../helpers/ApiError.helper";
import { WindowHelpers } from "../helpers/Window.helper";
import { VenueTypeEnum } from "../model/Venue.model";
import { User } from "../user/User";
import { EventApi } from "./Event.Api";
import { EventTicket, EventV2 } from "./Event.Model";
import { EventCreateOrUpdateDetailsBasicStep } from "./EventCreateOrUpdateDetails.Basic.Step";
import { EventCreateOrUpdateDetailsInstructorsStep } from "./EventCreateOrUpdateDetails.Instructors.Step";
import { PublishEventComponent } from "./PublishEventComponent";
import { TicketListInput } from "./TIcketList.Input";

export interface Step {
  title: string;
  content: React.ReactNode;
}
export interface EventTypeInputProps {
  user: User;
  eventId?: string;
  event?: EventV2;
}

export function EventTypeInput(props: EventTypeInputProps) {
  let eventId = useRef<string | undefined>(props.eventId);

  function withEventId(): Promise<string> {
    if (eventId.current) {
      return Promise.resolve(eventId.current);
    } else {
      return EventApi.create().then((event) => {
        eventId.current = event.id!;
        return event.id!;
      });
    }
  }

  let eventSteps: EBStep[] = [
    {
      title: "Basic Information",
      content: <EventCreateOrUpdateDetailsBasicStep user={props.user} />,
      initialFormValues: props.event?.basicDetails,
      onFormSubmit: (formValues: any) => {
        return withEventId().then((eventId) => {
          return EventApi.updateBasic(eventId, formValues);
        });
      },
    },
    {
      title: "Instructors",
      content: <EventCreateOrUpdateDetailsInstructorsStep user={props.user} />,
      initialFormValues: { instructors: props.event?.instructors },
      onFormSubmit: (formValues: any) => {
        return withEventId().then((eventId: string) => {
          return EventApi.updateInstructors(eventId, formValues);
        });
      },
    },
    {
      title: "Tickets",
      content: <TicketListInput user={props.user} name={"tickets"} />,
      initialFormValues: { tickets: props.event?.tickets },
      onFormSubmit: (formValues: { tickets: EventTicket[] }) => {
        return withEventId().then((eventId: string) => {
          return EventApi.updateTickets(eventId, formValues).then(
            (updatedTickets) => {
              formValues.tickets = updatedTickets;
              return Promise.resolve();
            }
          );
        });
      },
    },
    {
      title: "Publish",
      content: <PublishEventComponent user={props.user} eventId={eventId} />,
    },
  ];

  function onDone(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      withEventId().then((eventId: string) => {
        EventApi.updatePublished(eventId, true)
          .then((event) => {
            resolve();
            message.success("Event Published!");
            if (event.landingUrl) {
              WindowHelpers.redirect(event.landingUrl);
            }
          })
          .catch((error) => {
            let displayError = ApiErrorHelper.getDisplayErrorMessage(error);
            message.error(displayError);
            reject(error);
          });
      });
    });
  }

  return (
    <div className="EventTypeInput">
      <EBStepper
        steps={eventSteps}
        saveAndNext={true}
        doneButtonText={"Preview"}
        onDone={() => {
          return onDone();
        }}
      ></EBStepper>
    </div>
  );
}

export interface EventBasicInformationInputProps {
  user: User;
}
export function EventBasicInformationInput(
  props: EventBasicInformationInputProps
) {
  return (
    <div className="EventBasicInformationComponent">
      <div className="row">
        <EBTextInput
          label="Event Title"
          name={`title`}
          placeholder="Add basic information of the event"
          helpText=""
          required
        />
      </div>
      <div className="row">
        <FormInputLabel
          label="Event Descritption"
          required="true"
        ></FormInputLabel>
        <MarkdownInput name={`description`} user={props.user} />
      </div>
      <div className="row">
        <EventLocationInput name={`venue`} />
      </div>
      <div className="row">
        <div className="col">
          <FormInputLabel
            label="Event Duration"
            required="true"
          ></FormInputLabel>
          <DurationTimestampInput name={`duration`} />
        </div>
      </div>
    </div>
  );
}
export interface EventLocationInputProps extends FormikInputParams {}
export function EventLocationInput(props: EventLocationInputProps) {
  let tabs: Tab[] = [
    {
      title: "Venue",
      content: (
        <>
          <FormikHiddenInput
            value={VenueTypeEnum.PHYSICAL_LOCATION}
            name={`${props.name}.venueType`}
          />
          <EBTextAreaV2
            label={"Venue Location"}
            name={`${props.name}.venueData`}
          ></EBTextAreaV2>
        </>
      ),
      uniqueKey: "venue",
      isActive: true,
    },
    {
      title: "Online Event",
      content: (
        <>
          <FormikHiddenInput
            value={VenueTypeEnum.ONLINE}
            name={`${props.name}.venueType`}
          />
          <EBTextInput
            label={"URL"}
            name={`${props.name}.venueData`}
            helpText={undefined}
          ></EBTextInput>
        </>
      ),
      uniqueKey: "online-event",
      isActive: true,
    },
  ];
  return (
    <div className="EventLocationComponent">
      <FormInputLabel label="Location" required="true"></FormInputLabel>
      <div>
        <TabView tabs={tabs} />
      </div>
    </div>
  );
}
