import React from "react";

interface BunnyVideoPlayerProps {
  libraryId: string;
  videoId: string;
  maxWidth?: string;
}

export function BunnyVideoPlayer(props: BunnyVideoPlayerProps) {
  let videoUrl = `https://iframe.mediadelivery.net/embed/${props.libraryId}/${props.videoId}?autoplay=false&loop=false&muted=false&preload=false`;
  return (
    <div style={{ maxWidth: props.maxWidth ?? "800px" }}>
      <div style={{ position: "relative", paddingTop: "56.25%" }}>
        <iframe
          src={videoUrl}
          loading="lazy"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
          }}
          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
          allowFullScreen={true}
        ></iframe>
      </div>
    </div>
  );
}
