import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Tus from "@uppy/tus";
import React, { useEffect } from "react";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import { VideoBunnyCdn } from "./Video.Model";
import "./VideoUploader.css";

interface VideoUploaderProps {
  video: VideoBunnyCdn;
  onDone: () => void;
}
export function VideoUploader(props: VideoUploaderProps) {
  let video = props.video;
  useEffect(() => {
    new Uppy({
      restrictions: {
        maxFileSize: 4 * 1024 * 1024 * 1024,
        maxNumberOfFiles: 1,
        allowedFileTypes: ["video/*"],
      },
    })
      .use(Dashboard, {
        inline: true,
        target: "#uppy" + props.video.id,
        height: "350px",
        width: "100%",
        doneButtonHandler: props.onDone,
      })
      .use(Tus, {
        endpoint: "https://video.bunnycdn.com/tusupload",
        retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
        headers: {
          AuthorizationSignature: video.uploadProps.signature, // SHA256 signature (library_id + api_key + expiration_time + video_id)
          AuthorizationExpire: "" + video.uploadProps.expirationTimeInSecs, // Expiration time as in the signature,
          VideoId: video.uploadProps.videoId, // The guid of a previously created video object through the Create Video API call
          LibraryId: video.uploadProps.libraryId,
        },
        onAfterResponse(req, res) {
          console.log(res);
        },
      });
  }, [props.video.id]);

  return (
    <div className="VideoUploader">
      <div id={"uppy" + props.video.id}></div>
    </div>
  );
}
