import React from "react";
import { Mode } from "../../components/common/Form/model/mode.model";
import { User, UserBasicDetails } from "../../user/User";
import {
  FormTemplateLineItemType,
  FormTemplateLineItemTypeId,
} from "../model/formTemplate.model";
import { FormLineItemBoolComponent } from "./FormLineItemBoolComponent";
import { FormLineItemInputFileUploadComponent } from "./FormLineItemInputFileUploadComponent";
import { FormLineItemInputTextAreaComponent } from "./FormLineItemInputTextAreaComponent";
import { FormLineItemInputTextLineComponent } from "./FormLineItemInputTextLineComponent";
import { FormLineItemQuestionFeedbacksComponent } from "./FormLineItemQuestionFeedbacksComponent";
import { FormLineItemReviewComponent } from "./FormLineItemReviewComponent";

export interface FormLineItemComponentProps {
  name: string;
  mode: Mode;
  type: FormTemplateLineItemType;
  inputData: any;
  templateData: any;
  required?: boolean;
  customValidationsData?: any;

  user: User;
}

export function FormLineItemComponent(props: FormLineItemComponentProps) {
  return (
    <div className="FormLineItemComponent mt-2">
      {props.type.id === FormTemplateLineItemTypeId.INPUT_TEXT_AREA && (
        <FormLineItemInputTextAreaComponent
          {...props}
          name={`${props.name}.value`}
        />
      )}
      {props.type.id === FormTemplateLineItemTypeId.INPUT_LINE && (
        <FormLineItemInputTextLineComponent
          {...props}
          name={`${props.name}.value`}
        />
      )}
      {props.type.id === FormTemplateLineItemTypeId.INPUT_MD && (
        <FormLineItemInputTextAreaComponent
          {...props}
          name={`${props.name}.value`}
        />
      )}
      {props.type.id === FormTemplateLineItemTypeId.QUESTION_FEEDBACKS && (
        <FormLineItemQuestionFeedbacksComponent {...props} />
      )}
      {props.type.id === FormTemplateLineItemTypeId.BOOL && (
        <FormLineItemBoolComponent {...props} name={props.name} />
      )}

      {props.type.id === FormTemplateLineItemTypeId.REVIEW && (
        <FormLineItemReviewComponent {...props} name={props.name} />
      )}

      {props.type.id === FormTemplateLineItemTypeId.FILE_UPLOAD && (
        <FormLineItemInputFileUploadComponent {...props} name={props.name} />
      )}
    </div>
  );
}
