import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { LinkHelpers } from "../helpers/Link.Helpers";
import { Target } from "../model/target.model";
import { CommunityConstants } from "./Community.Constants";
import { CommunityGroup } from "./CommunityGroup.Model";

export class CommunityGroupApi {
  public static add(target: Target): Api<CommunityGroup, CommunityGroup> {
    let apiUrl = LinkHelpers.addQueryParams(
      CommunityConstants.COMMUNITY_GROUPS,
      [
        { key: "targetType", value: target.targetType },
        { key: "targetId", value: target.targetId },
      ]
    );
    return new Api<CommunityGroup, CommunityGroup>(apiUrl, ApiMethod.POST);
  }

  public static update(
    communityGroupId: number
  ): Api<CommunityGroup, CommunityGroup> {
    return new Api<CommunityGroup, CommunityGroup>(
      CommunityConstants.COMMUNITY_GROUP_SINGLE.replace(
        "{communityGroupId}",
        communityGroupId + ""
      ),
      ApiMethod.PUT
    );
  }

  public static list(target: Target): Api<CommunityGroup, CommunityGroup> {
    let apiUrl = LinkHelpers.addQueryParams(
      CommunityConstants.COMMUNITY_GROUPS,
      [
        { key: "targetType", value: target.targetType },
        { key: "targetId", value: target.targetId },
      ]
    );
    return new Api<CommunityGroup, CommunityGroup>(apiUrl, ApiMethod.GET);
  }
}
