import { DownOutlined } from "@ant-design/icons";
import { Tree } from "antd";
import type { DataNode, TreeProps } from "antd/es/tree";
import React, { useEffect, useState } from "react";

export type TreeNodeKey = React.Key;

interface TreeComponentProps {
  treeData: DataNode[];
  onSelect: (keys: TreeNodeKey[]) => void;

  selectedKey?: TreeNodeKey;
}

export function TreeComponent(props: TreeComponentProps) {
  let [expandedKeys, setExpandedKeys] = useState<TreeNodeKey[]>([]);
  useEffect(() => {
    if (props.selectedKey && !expandedKeys.includes(props.selectedKey)) {
      expandedKeys.push(props.selectedKey);
      let heirarchyKeys = findHeirarchyInTree(
        props.treeData,
        props.selectedKey
      );
      if (heirarchyKeys) {
        expandedKeys = [...expandedKeys, ...heirarchyKeys];
      }

      setExpandedKeys([...expandedKeys]);
    }
  }, [props.selectedKey]);
  const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
    props.onSelect(selectedKeys);
  };

  return (
    <Tree
      showLine
      switcherIcon={<DownOutlined />}
      onSelect={onSelect}
      treeData={props.treeData}
      blockNode={true}
      expandAction="click"
      expandedKeys={expandedKeys}
      selectedKeys={props.selectedKey ? [props.selectedKey] : undefined}
      onExpand={(expandedKeys) => {
        setExpandedKeys(expandedKeys);
      }}
    />
  );
}

function findHeirarchyInTree(
  rootNodes: DataNode[],
  targetKey: TreeNodeKey
): TreeNodeKey[] | undefined {
  for (let rootNode of rootNodes) {
    let heirarchy = findHeirarchy(rootNode, targetKey, []);
    if (heirarchy) {
      return heirarchy;
    }
  }
  return undefined;
}

function findHeirarchy(
  root: DataNode,
  targetKey: TreeNodeKey,
  currentHeirarchy: TreeNodeKey[]
): TreeNodeKey[] | undefined {
  if (targetKey == root.key) {
    return currentHeirarchy;
  }
  if (root.children) {
    for (let child of root.children) {
      let heirarchy = findHeirarchy(child, targetKey, [
        ...currentHeirarchy,
        root.key,
      ]);
      if (heirarchy) {
        return heirarchy;
      }
    }
  }
  return undefined;
}
