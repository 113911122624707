import React from "react";

interface FaIconProps {
  name:
    | "eye-slash"
    | "bullhorn"
    | "upload"
    | "eye"
    | "arrow-up"
    | "arrow-down"
    | "arrow-left"
    | "arrow-right"
    | "star"
    | "users-line"
    | "users-viewfinder";
  type: "s" | "r";
}

export function FaIcon(props: FaIconProps) {
  return <i className={`fa${props.type} fa-${props.name}`}></i>;
}
