import React from 'react';
import './ForCompanies.css';

class Comp extends React.Component {

  render() {
    return (
      <div>
          <div className="headerbackgrounddiv">
          <header className="masthead darkbglayer">
              <div className="">
                  <div className="container">
                      <div className="masthead-subheading">You focus on your BUSINESS!</div>
                      <div className="masthead-heading text-uppercase">And let us handle your interviews</div>
                      <a className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#contact">Contact us!</a>
                  </div>

              </div>
          </header>
          </div>
          <section className="page-section" id="how-it-works">
            <div class="container">
                <div class="row align-items-center justify-content-center margin-80px-bottom xs-margin-40px-bottom">
                    <div class="col-lg-4 col-md-5 xs-margin-20px-bottom">
                        <div>
                            <img class="border-radius-4" src="/img/requirements.jpg" alt="Image" width="100%"/>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-7">
                        <div class="padding-40px-left sm-no-padding-left">
                            <div class="media">
                                <span class="number margin-20px-right">1</span>
                                <div class="media-body">
                                    <h5 class="mt-0">Specify Requirements</h5>
                                    You send us the details like what skills needs to be evaluated in the interview, candidate's details, etc.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center margin-80px-bottom xs-margin-40px-bottom">
                    <div class="col-lg-6 col-md-7 order-2 order-md-1 order-lg-1">
                        <div class="padding-40px-right sm-no-padding-right">
                            <div class="media">
                                <span class="number margin-20px-right">2</span>
                                <div class="media-body">
                                    <h5 class="mt-0">Interviews by EBExperts</h5>
                                    Depending on your requirements, our engine bogie experts will conduct interviews on your behalf. They will make sure all skills you wanted are checked.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-5 order-1 order-md-2 order-lg-2 xs-margin-20px-bottom">
                        <div>
                            <div class="text-right xs-text-center"><img class="border-radius-4" src="/img/interview.jpg" alt="Image" width="100%"/></div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-4 col-md-5 xs-margin-20px-bottom">
                        <div>
                            <img class="border-radius-4" src="/img/feedback.jpg" alt="Image" width="100%"/>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-7">
                        <div class="padding-40px-left sm-no-padding-left">
                            <div class="media">
                                <span class="number margin-20px-right">3</span>
                                <div class="media-body">
                                    <h5 class="mt-0">You get detailed feedback</h5>
                                    Post interivew is done, you get the detailed feedback. A video recording of the interview will also be shared with you.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </section>
          
          {/* <section className="page-section bg-light" id="aspirants">
              <div className="container">
                  <div className="text-center">
                      <h2 className="section-heading text-uppercase">Why should you choose us?</h2>
                      <h3 className="section-subheading text-muted"></h3>
                  </div>
                  <div className="row text-center">
                      <div className="col-md-4">
                          <div className="fa-stack fa-4x">
                              <i className="fas fa-user-graduate fa-stack-1x text-secondary"></i>
                          </div>
                          <h4 className="my-3">Hire Best Talent</h4>
                          <p className="text-muted">
                              By getting interviews done from people working in top, you will make sure that you hire best talent just like big companies hire.
                          </p>
                      </div>
                      <div className="col-md-4">
                          <span className="fa-stack fa-4x">
                              <i className="fas fa-laptop-code fa-stack-1x text-secondary"></i>
                          </span>
                          <h4 className="my-3">Save Time & Cost</h4>
                          <p className="text-muted">
                            Interviewing is one of the most time taking and costliest process. Let us help you with our expertise to give you best experience with cost effective interviews.
                          </p>
                      </div>
                      <div className="col-md-4">
                          <span className="fa-stack fa-4x">
                              <i className="fas fa-award fa-stack-1x text-secondary"></i>
                          </span>
                          <h4 className="my-3">Top Quality Experts</h4>
                          <p className="text-muted">
                              All our experts are handpicked by us and we assure you of top quality. Our experts will try their level best to help you achieve what you want.
                          </p>
                      </div>
                  </div>
              </div>
          </section> */}
          <section className="page-section bg-light" id="features">
            <div class="features-clean">
              <div class="container">
                  <div class="intro">
                      <h2 class="text-center">Why should you choose us?</h2>
                      {/* <p class="text-center">Nunc luctus in metus eget fringilla. Aliquam sed justo ligula. Vestibulum nibh erat, pellentesque ut laoreet vitae. </p> */}
                  </div>
                  <div class="row features">
                      <div class="col-sm-6 col-lg-4 item"><i class="fas fa-user-graduate icon"></i>
                          <h3 class="name">Hire Best Talent</h3>
                          <p class="description">By getting interviews done from people working in top, you will make sure that you hire best talent just like big companies hire.</p>
                      </div>
                      <div class="col-sm-6 col-lg-4 item"><i class="fas fa-clock icon"></i>
                          <h3 class="name">Save Time</h3>
                          <p class="description">
                          Interviewing is one of the most time taking process. By using our services, you can interview more candidates and reclaim your developer's productivity.
                          </p>
                      </div>
                      <div class="col-sm-6 col-lg-4 item"><i class="fas fa-dollar-sign icon"></i>
                          <h3 class="name">Cost Effective</h3>
                          <p class="description">
                            Out plans are very cost effective which will not make a dent on your pocket.
                          </p>
                      </div>
                      <div class="col-sm-6 col-lg-4 item"><i class="fas fa-award icon"></i>
                          <h3 class="name">Top Quality</h3>
                          <p class="description">
                            All our experts are handpicked by us and we assure you of top quality. Our experts will try their level best to help you achieve what you want.
                          </p>
                      </div>
                      <div class="col-sm-6 col-lg-4 item"><i class="fas fa-list-alt icon"></i>
                          <h3 class="name">Detailed Feedback</h3>
                          <p class="description">
                            Feedbacks provided will be very detailed to help you in making the right decision. Full recording of the interview will also be shared with you.
                          </p>
                      </div>
                      <div class="col-sm-6 col-lg-4 item"><i class="fas fa-plane icon"></i>
                          <h3 class="name">Fast Track Process</h3>
                          <p class="description">Best talent is always in demand. You will lose them if you don't fast track the interviews.</p>
                      </div>
                  </div>
              </div>
            </div>
          </section>

          {/* <section className="page-section bg-light" id="team">
              <div className="container">
                  <div className="text-center">
                      <h2 className="section-heading text-uppercase">Our Top Experts</h2>
                  </div>
                  <div className="row">
                      <div className="col-lg-12">
                          <div className="team-member">
                              <img className="mx-auto rounded-circle" src="/img/team/udit.jpg" alt="" />
                              <h4>Udit Agarwal</h4>
                              <p className="text-muted">Microsoft</p>
                              <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/anomaly2104"><i className="fab fa-linkedin-in"></i></a>
                              <a className="btn btn-dark btn-social mx-2" href="https://twitter.com/anomaly2104"><i className="fab fa-twitter"></i></a>
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-lg-8 mx-auto text-center">
                          <p className="large text-muted">
                              Your will be assigned an EXPERT working in the top companies who will talk to you 1 on 1 and provide you professional and personal guidance for helping you land into your dream job.
                          </p>
                      </div>
                  </div>
              </div>
          </section> */}
          <section className="page-section py-5">
              <div className="container">
                  <div className="text-center">
                      <h3 className="section-subheading text-muted">Experts are handpicked from esteemed tech companies.</h3>
                  </div>

                  <div className="row">
                      <div className="col-md-4 col-sm-8 my-3 px-5">
                          <a href="#!"><img className="img-fluid d-block mx-auto" src="/img/logos/amazon-thumbnail.jpg" alt="" /></a>
                      </div>
                      <div className="col-md-4 col-sm-8 my-3 px-5">
                          <a href="#!"><img className="img-fluid d-block mx-auto" src="/img/logos/microsoft-thumbnail.jpg" alt="" /></a>
                      </div>
                      <div className="col-md-4 col-sm-8 my-3 px-5">
                          <a href="#!"><img className="img-fluid d-block mx-auto" src="/img/logos/adobe-thumbnail.jpg" alt="" /></a>
                      </div>
                  </div>
              </div>
          </section>
          <section className="page-section" id="contact">
              <div className="container">
                  <div className="text-center">
                      <h2 className="section-heading">Let us help you in hiring best talent to build best products for you!</h2>
                  </div>
                  <div className="text-center text-primary mt-5">
                    <span className=" h4">hello@enginebogie.com</span>
                  </div>
              </div>
          </section>
          
      </div>
    );
  }

}


export default Comp;
