import React from "react";
import { CallbackButton } from "../components/common/ApiLinkedButton";
import { reloadWindowCallback } from "../components/common/reloadCallback";
import { ReviewView } from "../components/common/review/Review.view";
import { ReviewForm } from "../components/common/review/ReviewForm.view";
import { apiLinkConstants } from "../constants";
import { UserHelpers } from "../helpers/Helpers";
import { ReviewV1 } from "../model/Reviews";
import { User } from "../user/User";
import { EventApi } from "./Event.Api";
import { EventV2 } from "./Event.Model";

interface EventCompletionButtonProps {
  event: EventV2;
}
export function EventCompletionButton(props: EventCompletionButtonProps) {
  let getEventCompletionCallback = (eventId: string): (() => Promise<void>) => {
    return (): Promise<void> => {
      return new Promise((resolve, reject) => {
        EventApi.complete(eventId)
          .then((response) => {
            reloadWindowCallback();
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
  };

  return (
    <div>
      {!props.event.basicDetails.completedAt ? (
        <CallbackButton
          uniqueKey={props.event.id + "-complete-button"}
          buttonProps={{
            text: { normal: "Mark Completed", submitting: "Completing..." },
          }}
          callback={getEventCompletionCallback(props.event.id)}
        />
      ) : null}
    </div>
  );
}

interface EventAttendeesListViewProps {
  attendees: User[];
}

export function EventAttendeesListView(props: EventAttendeesListViewProps) {
  return (
    <div className="card">
      <div className="card-header">Participants</div>
      <div className="card-body">
        <div className="row text-center">
          {props.attendees.map((attendee, index) => {
            return (
              <div
                className="col-xl-4 col-sm-6 col-md-4 mb-4"
                key={attendee.basicDetails.id}
              >
                <a href={UserHelpers.getUserProfileUrl(attendee.basicDetails)}>
                  <div className="bg-white rounded shadow-sm py-2 px-2 text-dark">
                    <img
                      src={UserHelpers.getProfilePicUrl(attendee.basicDetails)}
                      alt=""
                      width="100"
                      className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                    />
                    <h5 className="mb-0">
                      {UserHelpers.getUserFullName(attendee)}
                    </h5>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

interface EventReviewsListProps {
  reviews: ReviewV1[];
}

export function EventReviewsListView(props: EventReviewsListProps) {
  return (
    <div className="card">
      <div className="card-header">Reviews</div>
      <div className="card-body">
        {props.reviews && props.reviews.length > 0 ? (
          <div className="review-block">
            {props.reviews.map((review, index) => (
              <>
                <ReviewView review={review} />
                <hr />
              </>
            ))}
          </div>
        ) : (
          <div>No Reviews Yet.</div>
        )}
      </div>
    </div>
  );
}

interface EventReviewFormProps {
  event: EventV2;
}
export function EventReviewForm(props: EventReviewFormProps) {
  let api = apiLinkConstants.EVENT_REVIEWS_ADD_ATTENDEE.replace(
    "{eventId}",
    props.event.id
  );
  return (
    <div>
      <ReviewForm reviewSubmitApiLink={api} askForImprovements={true} />
    </div>
  );
}
