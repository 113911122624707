import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { ButtonDisplayProps } from "../../../model/views/Button.props";
import { EBButtonV2, EBButtonV2Props } from "../EBButtonV2";

interface RadioButtonGroupComponentProps {
  buttonProps: ButtonDisplayProps[];
  onButtonClick: (index: number) => void;
  selectedIndex?: number;
  disabled?: boolean;
}

export function RadioButtonGroupComponent(
  props: RadioButtonGroupComponentProps
) {
  useEffect(() => {
    if (props.selectedIndex == undefined && props.buttonProps.length > 0) {
      props.onButtonClick(0);
    }
  }, [props.buttonProps]);
  return (
    <div className="RadioButtonGroupComponent">
      {props.buttonProps.map((buttonProp, index) => {
        return (
          <button
            type="button"
            className={`btn mob-px-1 mob-py-1 mt-1 btn${
              props.selectedIndex == index ? "" : "-outline"
            }-${buttonProp.type ?? "primary"} mr-1 ${
              buttonProp.extraClassNames
            }`}
            key={index}
            onClick={() => props.onButtonClick(index)}
            disabled={props.disabled}
          >
            {buttonProp.text.normal}
          </button>
        );
      })}
    </div>
  );
}

interface RadioButtonGroupComponentV2Props {
  items: RadioButtonGroupItem[];
  onButtonClick: (item: RadioButtonGroupItem, index: number) => void;
  selectedIndex?: number;
  disabled?: boolean;
}

export interface RadioButtonGroupItem {
  buttonProps: EBButtonV2Props;
  selected?: boolean;
  disabled?: boolean;

  callerContext?: any;
}

export function RadioButtonGroupComponentV2(
  props: RadioButtonGroupComponentV2Props
) {
  return (
    <div className="RadioButtonGroupComponentV2 form-group">
      <Row>
        {props.items.map((item, index) => {
          return (
            <Col key={index}>
              <EBButtonV2
                key={index}
                {...item.buttonProps}
                disabled={item.disabled || props.disabled}
                onClick={() => props.onButtonClick(item, index)}
                purpose="none"
                className={`mob-px-1 mob-py-1 mt-1 mr-1 ${
                  item.buttonProps.className
                } ${item.selected ? "btn-secondary" : "btn-outline-secondary"}`}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
