import React from "react";
import { ImageComponent } from "../components/common/Image.component";
import { LocationType } from "../model/Picture";
import { Topic, TopicType } from "./Topic.model";

interface TopicBadgeProps {
  topic: Topic;
}

export function TopicBadge(props: TopicBadgeProps) {
  return (
    <span
      className={`TopicBadge badge ${
        props.topic.topicType == TopicType.COMPANY
          ? "bg-light-gray"
          : "bg-light-yellow"
      } mr-1 px-1 align-middle`}
    >
      {props.topic.dp && props.topic.dp.locationType === LocationType.URL && (
        <>
          <ImageComponent
            image={props.topic.dp}
            width={10}
            className="text-middle mr-1 mb-0_5"
          />
        </>
      )}

      {props.topic.dl}
    </span>
  );
}
