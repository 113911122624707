import { UserBasicDetails } from "../user/User";
import { Question } from "./Question.Model";

export interface IQuestionTypeAnswerSubmissionData {}

export interface QuestionTypeAnswerSubmissionDataMcq
  extends IQuestionTypeAnswerSubmissionData {
  selectedOptions: string[];
}

export enum AnswerSubmissionStatus {
  AC = "AC", // Accepted
  WA = "WA", // Wrong Answer
}

export interface AnswerSubmission {
  answerSubmissionData: IQuestionTypeAnswerSubmissionData;
  status: AnswerSubmissionStatus;
  submittedBy: UserBasicDetails;
  question: Question;
  createdAt: string;
}
