import { useField } from "formik";
import React from "react";
import Select from "react-select";

/**
 * @deprecated Use Label insteads
 */

export function FormInputLabel(props) {
  return (
    <label htmlFor={props.id || props.name}>
      {props.label}
      {props.required ? <span className="text-danger">*</span> : null}
    </label>
  );
}

export const EBSelect = ({ helpText, field, form, label, ...props }) => {
  function apiToSelect(apiOption) {
    if (props.mappingRequired) {
      let val = props.apiToSelectMapper(apiOption);
      if (val && val.value) {
        return val;
      }
      return undefined;
    }

    for (let option of props.options) {
      if (option.value == apiOption) {
        return option;
      }
    }

    return apiOption;
  }

  function selectToApi(selectOption) {
    if (props.mappingRequired) {
      return props.selectToApiMapper(selectOption);
    }
    return selectOption.value;
  }

  return (
    <>
      <div className="form-group">
        {label && <FormInputLabel label={label} {...props} />}
        <Select
          name={field.name}
          {...field}
          {...props}
          def
          onChange={(e) => {
            var apiData = props.isMulti ? e.map(selectToApi) : selectToApi(e);
            form.setFieldValue(field.name, apiData);
          }}
          value={
            props.isMulti && field.value
              ? field.value.map(apiToSelect)
              : apiToSelect(field.value)
          }
          required={props.required}
        />
        {helpText ? (
          <small className="form-text text-muted">{helpText}</small>
        ) : null}
      </div>

      {/* {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null} */}
    </>
  );
};

export const EBTextInput = ({ helpText, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <div className="form-group">
        {label && <FormInputLabel label={label} {...props} />}
        {props.nodeInputPrepend || props.nodeInputAppend ? (
          <div className="input-group">
            {props.nodeInputPrepend}
            <input className="form-control" type="text" {...field} {...props} />
            {props.nodeInputAppend}
          </div>
        ) : (
          <input className="form-control" type="text" {...field} {...props} />
        )}
        {props.customNodeBelowInput}
        {helpText ? (
          <small className="form-text text-muted">{helpText}</small>
        ) : null}
      </div>

      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export const EBNumberInput = ({ helpText, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <div className="form-group">
        {label && <FormInputLabel label={label} {...props} />}
        {props.nodeInputPrepend || props.nodeInputAppend ? (
          <div className="input-group">
            {props.nodeInputPrepend}
            <input
              className="form-control"
              type="number"
              {...field}
              {...props}
            />
            {props.nodeInputAppend}
          </div>
        ) : (
          <input className="form-control" type="number" {...field} {...props} />
        )}
        {props.customNodeBelowInput}
        {helpText ? (
          <small className="form-text text-muted">{helpText}</small>
        ) : null}
      </div>

      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export const EBDateInput = ({ helpText, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <div className="">
        <FormInputLabel label={label} {...props} />
        <input className="form-control" type="date" {...field} {...props} />
        {helpText ? (
          <small className="form-text text-muted">{helpText}</small>
        ) : null}
      </div>

      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
