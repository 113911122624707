import React from "react";
import { ApiLinkedButtonV3 } from "../components/common/Form/ApiLinked.button.V3";
import { PublishIcon } from "../icon/Publish.Icon";
import { UnpublishIcon } from "../icon/Unpublish.Icon";
import { HTMLProps } from "../model/HTMLProps";
import { IOfferingV2 } from "./Offering";
import { OfferingAPI } from "./Offering.api";

interface OfferingPublishStatusToggleButtonProps extends HTMLProps {
  offering: IOfferingV2;
  onOfferingUpdate: (response: IOfferingV2) => Promise<void>;
}

export function OfferingPublishStatusToggleButton(
  props: OfferingPublishStatusToggleButtonProps
) {
  let offering = props.offering;
  return (
    <>
      {!offering.commonDetails.publishedAt && (
        <div>
          <ApiLinkedButtonV3
            formModalSize="md"
            apiFormBody={
              <>
                <h6>Are you sure you want to publish? </h6>
                <div className="alert alert-warning mt-2" role="alert">
                  Publishing will make your offering visible to users and
                  available for sale.
                </div>
              </>
            }
            buttonProps={{
              className: `btn-sm ${props.className}`,
              tooltip: "Publish Offering",
              content: {
                normal: (
                  <>
                    <PublishIcon />
                  </>
                ),
                submitting: (
                  <>
                    <PublishIcon /> Publishing
                  </>
                ),
              },
            }}
            api={OfferingAPI.publish(
              offering.commonDetails.postedBy.id,
              offering.commonDetails.type,
              offering.id
            )}
            successCallback={props.onOfferingUpdate}
            uniqueKey={"offering" + offering.id + "-publish"}
          />
        </div>
      )}

      {offering.commonDetails.publishedAt && (
        <div className={props.className}>
          <ApiLinkedButtonV3
            formModalSize="md"
            apiFormBody={
              <>
                <h6>Are you sure you want to un-publish? </h6>
                <div className="alert alert-warning mt-2" role="alert">
                  Un-Publishing will make your offering unavailable for sale.
                  While the ones who have already purchased them, will continue
                  using it.
                </div>
              </>
            }
            buttonProps={{
              className: `btn-sm btn-light border offering-publish-status-toggle-button ${props.className}`,
              tooltip: "Un-Publish Offering",
              content: {
                normal: (
                  <>
                    <UnpublishIcon />
                  </>
                ),
                submitting: (
                  <>
                    <UnpublishIcon /> Un-Publishing
                  </>
                ),
              },
            }}
            api={OfferingAPI.unPublish(
              offering.commonDetails.postedBy.id,
              offering.commonDetails.type,
              offering.id
            )}
            successCallback={props.onOfferingUpdate}
            uniqueKey={"offering" + offering.id + "-unpublish"}
          />
        </div>
      )}
    </>
  );
}
