export class EngineName {
  public static SENTENCE_MID = "expert";
  public static SENTENCE_START = "Expert";

  public static SENTENCE_MID_PLURAL = "experts";
  public static SENTENCE_START_PLURAL = "Experts";
}

export class BogieName {
  public static SENTENCE_MID = "attendee";
  public static SENTENCE_START = "Attendee";

  public static SENTENCE_MID_PLURAL = "attendees";
  public static SENTENCE_START_PLURAL = "Attendees";
}

export class PlatformDl {
  public static SENTENCE_MID = "enginEBogie";
  public static SENTENCE_START = "enginEBogie";
}

export class VideoSessionDl {
  public static SENTENCE_MID = "video session";
  public static SENTENCE_START = "Video session";

  public static SENTENCE_MID_PLURAL = "video sessions";
  public static SENTENCE_START_PLURAL = "Video sessions";
}

export class TextQueryDl {
  public static SENTENCE_MID = "offline query";
  public static SENTENCE_START = "Offline query";

  public static SENTENCE_MID_PLURAL = "offline queries";
  public static SENTENCE_START_PLURAL = "Offline queries";
}

export class PathDl {
  public static SENTENCE_MID = "path";
  public static SENTENCE_START = "Path";

  public static SENTENCE_MID_PLURAL = "paths";
  public static SENTENCE_START_PLURAL = "Paths";
}

export class DigitalProductDl {
  public static SENTENCE_MID = "digital product";
  public static SENTENCE_START = "Digital product";

  public static SENTENCE_MID_PLURAL = "digital products";
  public static SENTENCE_START_PLURAL = "Digital products";
}

export class BookDl {
  public static SENTENCE_MID = "book";
  public static SENTENCE_START = "Book";

  public static SENTENCE_MID_PLURAL = "book";
  public static SENTENCE_START_PLURAL = "Book";
}
