import axios from "axios"
import { apiLinkConstants } from "../constants"
import { ApiUrlHelpers } from "../helpers/Helpers"
import { InterviewRound } from "../model/InterviewRound"
import { InterviewRoundStatus } from "../model/InterviewRoundStatus.enum"
import { CreateInterviewRoundRequest } from "../model/requests/CreateInterviewRoundRequest"
import { CreateOrUpdateUpdateAttachmentRequest } from "../model/requests/CreateOrUpdateUpdateAttachmentRequest"
import { UpdateInterviewerRequest } from "../model/requests/UpdateInterviewerRequest"
import { UpdateInterviewRoundGuidelinesRequest } from "../model/requests/UpdateInterviewRoundGuidelinesRequest"
import { UpdateInterviewRoundRecordingRequest } from "../model/requests/UpdateInterviewRoundRecordingRequest"
import { UpdateInterviewRoundStartTimestampRequest } from "../model/requests/UpdateInterviewRoundStartTimestampRequest"
import { InterviewFeedbackResponse } from "../model/response/InterviewFeedback.response"

export class InterviewRoundAPI {

    public static createInterviewRound(companyId: string, interviewLoopId: string, interviewRoundRequest: CreateInterviewRoundRequest) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            axios.post(ApiUrlHelpers.getInterviewRoundAddURL(companyId, interviewLoopId), interviewRoundRequest).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    public static updateInterviewer(
        companyId: string,
        interviewLoopId: string,
        interviewRoundId: string,
        updateInterviewerRequest: UpdateInterviewerRequest) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            axios.put(
                ApiUrlHelpers.updateInterviewerURL(companyId, interviewLoopId, interviewRoundId),
                updateInterviewerRequest).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    public static updateStartTimestamp(
        companyId: string,
        interviewLoopId: string,
        interviewRoundId: string,
        updateStartTimestampRequest: UpdateInterviewRoundStartTimestampRequest) : Promise<void> {
            let url = apiLinkConstants.INTERVIEW_ROUND_UPDATE_START_TIMESTAMP
            .replace("{companyId}", companyId)
            .replace("{interviewLoopId}", interviewLoopId)
            .replace("{interviewRoundId}", interviewRoundId);
        return new Promise<void>((resolve, reject) => {
            axios.put(url, updateStartTimestampRequest).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    public static schedule(
        companyId: string,
        interviewLoopId: string,
        interviewRoundId: string) : Promise<void> {
            let url = apiLinkConstants.INTERVIEW_ROUND_SCHEDULE
            .replace("{companyId}", companyId)
            .replace("{interviewLoopId}", interviewLoopId)
            .replace("{interviewRoundId}", interviewRoundId);
        return new Promise<void>((resolve, reject) => {
            axios.post(url).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    }


    public static updateGuidelines(
        interviewRoundId: string,
        updateGuidelinesRequest: UpdateInterviewRoundGuidelinesRequest) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            axios.put(
                ApiUrlHelpers.interviewRoundUpdateGuideline(interviewRoundId),
                updateGuidelinesRequest).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    public static updateRecording(
        interviewRoundId: string,
        updateRecordingRequest: UpdateInterviewRoundRecordingRequest) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            axios.put(
                ApiUrlHelpers.interviewRoundUpdateRecording(interviewRoundId),
                updateRecordingRequest).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    public static updateInterviewerFeedback(
        interviewRoundId: string,
        updateRecordingRequest: UpdateInterviewRoundRecordingRequest) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            axios.put(
                ApiUrlHelpers.interviewRoundUpdateRecording(interviewRoundId),
                updateRecordingRequest).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    public static createOrUpdateAttachment(
        interviewRoundId: string,
        attachmentRequest: CreateOrUpdateUpdateAttachmentRequest) : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            axios.post(ApiUrlHelpers.interviewRoundUpdateAttachment(interviewRoundId),
                attachmentRequest).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    public static getInterviewRoundFetchAll(companyId: string, interviewLoopId: string) : Promise<InterviewRound[]> {
        return new Promise<InterviewRound[]>((resolve, reject) => {
            axios.get(ApiUrlHelpers.getInterviewRoundFetchAllURL(companyId, interviewLoopId)).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    public static getInterviewRounds(status: InterviewRoundStatus | undefined) : Promise<InterviewRound[]> {
        let query = ""; 
        if (status !== undefined) {
            query += "?status=" + status.toString();
        }
        let api = apiLinkConstants.INTERVIEW_ROUND_FETCH_ALL + query;
        return new Promise<InterviewRound[]>((resolve, reject) => {
            axios.get(api).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    public static getInterviewRoundsForCompany(companyId: string, status: InterviewRoundStatus | undefined) : Promise<InterviewRound[]> {
        let query = ""; 
        if (status !== undefined) {
            query += "?status=" + status.toString();
        }
        let api = apiLinkConstants.COMPANY_INTERVIEW_ROUND_FETCH_ALL.replace("{companyId}", companyId) + query;
        return new Promise<InterviewRound[]>((resolve, reject) => {
            axios.get(api).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    public static getInterviewRoundFetchById(interviewRoundId: string) : Promise<InterviewRound> {
        let api = apiLinkConstants.INTERVIEW_ROUND_FETCH_BY_ID.replace("{interviewRoundId}", interviewRoundId);
        return new Promise<InterviewRound>((resolve, reject) => {
            axios.get(api).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    }

    public static getInterviewRoundFeedback(interviewRoundId: string) : Promise<InterviewFeedbackResponse> {
        let api = apiLinkConstants.INTERVIEW_ROUND_GET_INTERVIEWER_FEEDBACK.replace("{interviewRoundId}", interviewRoundId);
        return new Promise<InterviewFeedbackResponse>((resolve, reject) => {
            axios.get(api).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    }
}
