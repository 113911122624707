import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { CopyClipboardButton } from "../components/common/CopyCliboard.button";
import { ExpertPaymentsSettingsForm } from "../components/common/ExpertPaymentsSettingsForm";
import { ApiErrorHelper } from "../helpers/ApiError.helper";
import { ExpertDetails } from "../model/ExpertDetails";
import { ExpertAPI } from "../user/Expert.api";
import { User } from "../user/User";
import { UserAPI } from "../user/UserAPI";
import { ExpertStudioContentProps } from "./ExpertStudio.page";

interface ExpertDetailsFormComponentProps {
  user: User;
}

function ExpertDetailsFormComponent(props: ExpertDetailsFormComponentProps) {
  const alert = useAlert();

  return (
    <div className="col mb-3">
      <Formik
        enableReinitialize
        initialValues={
          props.user.basicDetails.expertDetails || ({} as ExpertDetails)
        }
        onSubmit={(values, { setSubmitting }) => {
          ExpertAPI.updateExpertSettingsGeneral(
            props.user.basicDetails.id,
            values
          )
            .then(function (response) {
              alert.success("Details saved successfully!");
            })
            .catch(function (error) {
              let displayErr = ApiErrorHelper.getDisplayErrorMessage(error);
              alert.error(displayErr);
            })
            .finally(function () {
              setSubmitting(false);
            });
        }}
      >
        {({ values, isSubmitting, handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <>
              <div>
                <div className="row ">
                  <div className="col ">
                    <div className="alert alert-primary">
                      Your profile link which you can share with your network:{" "}
                      <span className="fw-bold">
                        {props.user.basicDetails.profileUrl}{" "}
                      </span>
                      <CopyClipboardButton
                        textToCopy={props.user?.basicDetails.profileUrl}
                      />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="custom-control custom-switch">
                          <Field
                            type="checkbox"
                            id="isExpertSelfEnabled"
                            className="custom-control-input"
                            name="isExpertSelfEnabled"
                          />

                          <label
                            className="custom-control-label"
                            htmlFor="isExpertSelfEnabled"
                          >
                            Available to take sessions?
                          </label>
                          {values.isExpertSelfEnabled ? (
                            <p className="text-success">
                              Your expert profile will be visible for booking.
                            </p>
                          ) : (
                            <p className="text-danger">
                              Your expert profile will not be visible for
                              session bookings.
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-8">
                        <ExpertPaymentsSettingsForm />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
              </div>

              <div className="row">
                <div className="col mt-3">
                  <div className="form-group">
                    <div className="col d-flex justify-content-end">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting
                          ? "Saving. Please Wait..."
                          : "Save Changes"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </form>
        )}
      </Formik>
    </div>
  );
}

interface ExpertStudioGeneralSettingsComponentProps
  extends ExpertStudioContentProps {}

export function ExpertStudioGeneralSettingsComponent(
  props: ExpertStudioGeneralSettingsComponentProps
) {
  let [fullUser, setFullUser] = useState<User>();
  useEffect(() => {
    UserAPI.fetchLoggedInUser(true, true).then((fullUser) => {
      setFullUser(fullUser);
    });
  }, [props.user]);
  return (
    <div className="ExpertStudioGeneralSettingsComponent">
      {fullUser ? <ExpertDetailsFormComponent user={fullUser} /> : null}
    </div>
  );
}
