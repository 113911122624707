import React from "react";
import { UrlHelpers } from "../../../helpers/Helpers";
import { WindowHelpers } from "../../../helpers/Window.helper";
import { ReactMatch } from "../../../model/ReactMatchObj";
import { ButtonText } from "../../../model/views/Button.props";
import { PageProps } from "../../../model/views/PageProps";
import { Topic } from "../../../topic/Topic.model";
import { TopicAPI } from "../../../topic/TopicAPI";
import { CallerContext } from "../../common/CallerContext";
import { CharchaaAPI } from "./Charchaa.api";
import { Post } from "./Charchaa.model";
import { CharchaaAddOrUpdatePostContent } from "./CharchaaAddOrUpdatePost.page-content";

interface CharchaaUpdatePostPageContentPathParams {
  postId: number;
}

interface CharchaaUpdatePostPageContenttProps extends PageProps {
  match: ReactMatch<CharchaaUpdatePostPageContentPathParams>;
}

interface CharchaaUpdatePostPageContentState {
  post: Post;
  allTopics: Topic[];
}
export class CharchaaUpdatePostPageContent extends React.Component<
  CharchaaUpdatePostPageContenttProps,
  CharchaaUpdatePostPageContentState
> {
  componentDidMount() {
    TopicAPI.getListingTopics().then((topics) => {
      this.setState({ allTopics: topics });
    });
    CharchaaAPI.fetchPostById(this.props.match.params.postId).then((post) => {
      this.setState({ post: post });
    });
  }

  render() {
    let addButtonText: ButtonText = {
      normal: "Update",
      submitting: "Updating ",
    };

    let callBack = (
      post: Post,
      callerContext: CallerContext
    ): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
        const successMessage = "Post updated succesfully!!!";
        const redirectUrl = UrlHelpers.getPostDetailsUrl(
          post.url,
          successMessage
        );
        WindowHelpers.redirect(redirectUrl);
        resolve();
      });
    };
    return (
      <div className="main-container CharchaaUpdatePostPageContent">
        {this.state && this.state.post && this.state.allTopics && (
          <>
            <h3>Update Charchaa Post</h3>

            <div className="card">
              <div className="card-body">
                {this.props.user && (
                  <CharchaaAddOrUpdatePostContent
                    callerContext={{}}
                    callback={callBack}
                    post={this.state.post}
                    addButtonText={addButtonText}
                    allTopics={this.state.allTopics}
                    user={this.props.user}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
