import axios from "axios";
import { admiApiLinkConstants } from "../../constants";
import { InterviewExperienceResponse } from "../../model/InterviewExperience";

export class AdminInterviewExperiencesAPI {
    public static async fetchInterviewExperiences(): Promise<InterviewExperienceResponse[]> {
        return new Promise<InterviewExperienceResponse[]>((resolve, reject) => {
            axios.get(admiApiLinkConstants.INTERVIEW_EXPERIENCE_FETCH_ALL)
            .then(res => {
                const responseData = res.data;
                resolve(responseData);
            })
            .catch(error => {
                reject(error);
            })
        })
    }
}
