import React from "react";
import { Label } from "../../components/common/Form/elements/FormInputLabel";
import {
  Mode,
  ModeBasedString,
} from "../../components/common/Form/model/mode.model";
import { TextViewComponent } from "../../components/common/TextView";
import { EBTextInputV2 } from "../../components/common/input/TextLine.input";
import { ModeHelpers } from "../../helpers/Mode.helper";
import { FormLineItemComponentProps } from "./FormLineItemComponent";

export interface FormLineItemInputTextLineTemplateData {
  displayTitle: ModeBasedString;
  placeholder?: string;
}

interface FormLineItemInputTextLineInputData {
  value: string;
}
export function FormLineItemInputTextLineComponent(
  props: FormLineItemComponentProps
) {
  let templateData =
    props.templateData as FormLineItemInputTextLineTemplateData;
  let inputData = props.inputData as FormLineItemInputTextLineInputData;
  return (
    <>
      {props.mode == Mode.EDIT && (
        <>
          <Label
            label={ModeHelpers.getString(props.mode, templateData.displayTitle)}
            required={props.required}
          />
          <EBTextInputV2
            name={props.name}
            required={props.required}
            placeholder={templateData.placeholder}
          />
        </>
      )}
      {props.mode == Mode.VIEW && inputData && (
        <div>
          <div className="bold">
            {ModeHelpers.getString(props.mode, templateData.displayTitle)}
          </div>
          <div>
            <TextViewComponent text={inputData.value} />
          </div>
        </div>
      )}
    </>
  );
}
