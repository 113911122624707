import React from "react";

export interface OfferingDetailPageLayoutProps {
  headerContent?: React.ReactNode;
  mainContent: React.ReactNode;
  registrationContent: React.ReactNode;
  sideBarTopContent: React.ReactNode;
  reviewContent?: React.ReactNode;
}

export enum OfferingDetailPageLayoutTypeId {
  RIGHT_STICKY = "RIGHT_STICKY",
  DEFAULT = "DEFAULT",
}

export interface OfferingDetailPageLayoutType {
  id: OfferingDetailPageLayoutTypeId;
  dl: string;
}
