let API_BASE = "/api";

export class OfferingConstants {
  public static API_ENGINE_OFFERING_STATUS_PUBLISH =
    API_BASE +
    "/posted-by/{postedById}/offerings/{offeringType}/{offeringId}/status/publish";
  public static API_ENGINE_OFFERING_STATUS_UNPUBLISH =
    API_BASE +
    "/posted-by/{postedById}/offerings/{offeringType}/{offeringId}/status/unpublish";

  public static API_ENGINE_OFFERING_IMAGES_SHAREABLE =
    API_BASE +
    "/posted-by/{postedById}/offerings/{offeringType}/{offeringId}/images/shareable";
  public static API_ENGINE_OFFERING_CONTENT_SHAREABLE =
    API_BASE +
    "/posted-by/{postedById}/offerings/{offeringType}/{offeringId}/content/shareable";

  public static API_OFFERING_FETCH_SINGLE =
    API_BASE + "/offerings/{offeringType}/{offeringId}?context={fetchContext}";

  public static API_OFFERING_FETCH_SINGLE_COMMON_DETAILS =
    API_BASE + "/offerings/{offeringId}?context={fetchContext}";

  public static API_USER_OFFERING_BOGIES =
    API_BASE + "/offerings/{offeringType}/{offeringId}/bogies";

  public static API_OFFERING_FEEDBACK_FORM_LIST =
    API_BASE + "/offerings/{offeringType}/{offeringId}/feedback-forms";

  public static API_OFFERING_LIST =
    API_BASE + "/{userId}/offerings/{offeringType}";

  public static API_OFFERING_ORDER =
    API_BASE + "/offering/{offeringTargetType}/{offeringId}/order";
  public static API_OFFERING_COUPON_APPLY =
    API_BASE + "/offering/{offeringTargetType}/{offeringId}/coupon-apply";

  public static API_OFFERINGS_LIST = API_BASE + "/offerings";
  public static API_OFFERINGS_ADD =
    API_BASE + "/offerings/{offeringTargetType}";
  public static API_OFFERING_UPDATE =
    API_BASE + "/offerings/{offeringTargetType}/{offeringId}";

  public static PAGE_USER_OFFERING = "/offerings/:offeringId";
  public static PAGE_USER_OFFERING_FRIENDLY =
    "/u/:userId/offerings/:offeringType/:offeringId";
}
