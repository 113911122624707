import React from "react";
import { LoginAPI } from "../../api/LoginAPI";
import { publicLinkConstants } from "../../constants";
import userHelpers from "../../helpers/userHelpers";
import { User } from "../../user/User";

interface NavbarProps {
  headerLinks: HeaderLink[];
  user: User;
}

interface HeaderLink {
  text: string;
  id: string;
  href: string;
  faIconClass?: string;
  isLoggedInUserRequired: Boolean;
}

interface UserNavItemProps {
  user: User;
}

function UserNavItem(props: UserNavItemProps) {
  return (
    <li className="nav-item dropdown dropstart no-arrow nav-item-user">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="userDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {props.user.basicDetails.firstName}{" "}
        <img
          className="img-profile rounded-circle"
          src={userHelpers.getProfilePicUrl(props.user.basicDetails)}
        />
      </a>
      {/* <!-- Dropdown - User Information --> */}
      <div
        className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
        aria-labelledby="userDropdown"
      >
        <a className="dropdown-item" href={props.user.basicDetails.profileUrl}>
          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
          Preview Profile
        </a>
        <a
          className="dropdown-item"
          href={publicLinkConstants.USER_PROFILE_EDIT}
        >
          <i className="fas fa-pen-to-square fa-sm fa-fw mr-2 text-gray-400"></i>
          Edit Profile
        </a>

        <a
          href={publicLinkConstants.USER_ORDERS_LIST}
          className="dropdown-item"
        >
          <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
          Orders
        </a>

        <a
          href={publicLinkConstants.EXPERT_STUDIO_EDIT_VIDEO_SESSIONS}
          className="dropdown-item"
        >
          <i className="fas fa-toolbox fa-sm fa-fw mr-2 text-gray-400"></i>
          Edit Offerings
        </a>
        <div className="dropdown-divider"></div>
        <a
          className="dropdown-item"
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#logoutModal"
        >
          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Logout
        </a>
      </div>
    </li>
  );
}

interface HeaderLinkNavItemProps {
  headerLink: HeaderLink;
  user: User;
}

function HeaderLinkNavItem(props: HeaderLinkNavItemProps) {
  let headerLink = props.headerLink;

  if (headerLink.isLoggedInUserRequired && !props.user) {
    return <></>;
  }

  return (
    <li className="nav-item" key={headerLink.id}>
      <a className="nav-link" href={headerLink.href}>
        {headerLink.faIconClass ? (
          <i className={headerLink.faIconClass}></i>
        ) : null}{" "}
        {headerLink.text}
      </a>
    </li>
  );
}

function LoginNavItem() {
  return (
    <li className="nav-item no-arrow">
      <a className="nav-link" href={LoginAPI.loginUrl()}>
        <i className="fas fa-sign-in-alt"></i> Login
      </a>
    </li>
  );
}

class Comp extends React.Component<NavbarProps, {}> {
  constructor(props: NavbarProps) {
    super(props);
    this.state = { isLoggedIn: false };
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <nav
          className="navbar navbar-expand-lg navbar-dark bg-dark"
          id="normalNav"
        >
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              {/* <div className="home-page-img"></div> */}
              {process.env.REACT_APP_WEBSITE_NAME}
            </a>

            <button
              className="navbar-toggler navbar-toggler-right"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              Menu<i className="fas fa-bars ml-1"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ms-auto">
                {this.props.headerLinks &&
                  this.props.headerLinks.map((headerLink, index) => (
                    <HeaderLinkNavItem
                      user={this.props.user}
                      headerLink={headerLink}
                      key={headerLink.id}
                    />
                  ))}

                {this.props && this.props.user ? (
                  <UserNavItem user={this.props.user} />
                ) : (
                  <LoginNavItem />
                )}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Comp;
