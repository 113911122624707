import { Company } from "../company/Company";
import { SearchTokenDataDesignation } from "../model/search/data/SearchTokenDataDesignation";
import { SearchTokenDataDifficultyLevel } from "../model/search/data/SearchTokenDataDifficultyLevel";
import { SearchTokenTopic } from "../model/search/data/SearchTokenTopic";
import { SearchToken } from "../model/search/SearchToken";
import { SearchTokenName } from "../model/search/SearchTokenName";

export class SearchTokenHelper {
  static getShowMineToken(include: boolean = false): SearchToken {
    return {
      name: SearchTokenName.SHOW_MINE,
      data: {
        showMine: include,
      },
    };
  }
  static getDifficultyLevelToken(
    difficultyTokenData: SearchTokenDataDifficultyLevel
  ): SearchToken {
    return {
      name: SearchTokenName.DIFFICULTY_LEVEL,
      data: difficultyTokenData,
    };
  }

  static getTopicToken(topicTokenData: SearchTokenTopic): SearchToken {
    return {
      name: SearchTokenName.TOPIC,
      data: topicTokenData,
    };
  }

  static getSearchStringToken(searchQuery: String): SearchToken {
    return {
      name: SearchTokenName.SEARCH_QUERY_STRING,
      data: { searchQuery: searchQuery },
    };
  }
  static getCompanyToken(companies: Company[]): SearchToken {
    return {
      name: SearchTokenName.CURRENT_COMPANY,
      data: {
        companies: companies,
      },
    };
  }
  static getDesignationToken(
    designationSearchData: SearchTokenDataDesignation
  ): SearchToken {
    return {
      name: SearchTokenName.CURRENT_DESIGNATION,
      data: designationSearchData,
    };
  }
}
