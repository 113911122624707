import React from "react";
import { BadgeDesignLightYellow } from "../../badge/Badge.Design";
import { BadgeList } from "../../badge/Badge.List";
import { SkillBadge } from "../../badge/Skill.Badge";
import { UserBasicDetails } from "../User";

export function UserCardSkillsList(props: { user: UserBasicDetails }) {
  let user = props.user;
  return (
    <>
      {user.skills && (
        <BadgeList
          size={user.skills.length}
          render={(index) => {
            return (
              user.skills &&
              user.skills[index] && (
                <SkillBadge
                  skill={user.skills[index]}
                  key={index}
                  className="mr-1"
                />
              )
            );
          }}
          viewLimit={6}
          ViewMoreDesign={BadgeDesignLightYellow}
        />
      )}
    </>
  );
}
