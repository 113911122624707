import React from "react";
import { ModeHelpers } from "../../helpers/Mode.helper";
import {
  FormSectionLineItemInputDataFileUpload,
  FormSectionLineItemTemplateDataFileUpload,
} from "./Form.Section.LineItem.Model";
import { FormLineItemComponentProps } from "./FormLineItemComponent";

export function FormSectionLineItemViewFileUpload(
  props: FormLineItemComponentProps
) {
  let templateData =
    props.templateData as FormSectionLineItemTemplateDataFileUpload;
  let inputData = props.inputData as FormSectionLineItemInputDataFileUpload;

  return (
    <>
      <div className="bold">
        {ModeHelpers.getString(props.mode, templateData.displayTitle)}
      </div>
      {inputData && (
        <a target="_blank" href={inputData.value.data}>
          Click here to view uploaded file
        </a>
      )}

      {!inputData && <>Not provided</>}
    </>
  );
}
