import axios from "axios";
import { apiLinkConstants } from "../constants";
import { ApiUrlHelpers } from "../helpers/Helpers";
import { EventOrderRequest } from "../model/requests/EventOrder.request";
import { CheckoutResponse } from "../model/response/Checkout.response";
import { OrderResponse } from "../model/response/Order.response";

export class CheckoutAPI {

    public static checkoutOrder(orderId: string): Promise<CheckoutResponse> {
        return new Promise<CheckoutResponse>((resolve, reject) => {
            axios.post(ApiUrlHelpers.orderCheckout(orderId)).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            })
        })
    }
}