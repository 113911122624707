import React, { useState } from "react";
import { MarkdownV2Input } from "../../../components/common/input/MarkdownV2.Input";
import { ContentItemDataMarkdown } from "../../Content.model";
import { ContentItemInputProps } from "../ContentItem.model";
import "./ContentItemInput.Markdown.css";

export function ContentItemInputMarkdown(props: ContentItemInputProps) {
  let [reRenderCount, setRenderCount] = useState<number>(0);

  function reRender() {
    setRenderCount(reRenderCount + 1);
  }

  function onChange(newMd: string) {
    type NewType = ContentItemDataMarkdown;

    let existingData = props.item.data as NewType;
    if (existingData) {
      existingData.md = newMd;
    } else {
      props.item.data = { md: newMd };
    }
    reRender();
    props.onChange();
  }

  let data = props.item.data as ContentItemDataMarkdown;
  return (
    <div className="ContentItemInputMarkdown">
      <MarkdownV2Input
        user={props.user}
        id={crypto.randomUUID()}
        onChange={onChange}
        value={data.md}
      />
    </div>
  );
}
