import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { ApiErrorHelper } from "../../helpers/ApiError.helper";
import { ButtonDisplayProps } from "../../model/views/Button.props";
import { EBButton } from "./EBButton";
import { EBModal } from "./EBModal";

interface CallbackButtonProps {
  buttonProps: ButtonDisplayProps;
  callback: () => Promise<void>;

  showConfirmation?: boolean;
  uniqueKey: string;
  disabled?: boolean;
}

export function CallbackButton(mainProps: CallbackButtonProps) {
  let [isSubmitting, setSubmitting] = useState<boolean>(false);

  let ConfirmationBodalBody = function () {
    return <div>Are you sure?</div>;
  };

  let confirmationModalId = mainProps.uniqueKey + "-confirmation-modal";

  function submit() {
    setSubmitting(true);
    mainProps.callback().finally(() => {
      setSubmitting(false);
    });
  }

  return (
    <>
      {mainProps.showConfirmation && (
        <>
          <EBModal
            BodyComponent={<ConfirmationBodalBody />}
            id={confirmationModalId}
            modalSize="sm"
            primaryButtonProps={{
              classes: [
                "btn mr-2",
                mainProps.buttonProps.type
                  ? "btn-" + mainProps.buttonProps.type
                  : "btn-primary",
              ],
              text: mainProps.buttonProps.text,
              disabled: isSubmitting || mainProps.disabled,
              loading: isSubmitting,
              onClick: function () {
                submit();
              },
              otherProps: {
                "data-bs-toggle": "modal",
                "data-bs-target": "#" + confirmationModalId,
              },
            }}
          />
          <EBButton
            classes={[
              "btn mr-2",
              mainProps.buttonProps.type
                ? "btn-" + mainProps.buttonProps.type
                : "btn-primary",
              mainProps.buttonProps.extraClassNames ?? "",
            ]}
            text={mainProps.buttonProps.text}
            disabled={isSubmitting || mainProps.disabled}
            loading={isSubmitting}
            otherProps={{
              "data-bs-toggle": "modal",
              "data-bs-target": "#" + confirmationModalId,
            }}
          />
        </>
      )}
      {!mainProps.showConfirmation && (
        <EBButton
          classes={[
            "btn",
            mainProps.buttonProps.type
              ? "btn-" + mainProps.buttonProps.type
              : "btn-primary",
            mainProps.buttonProps.extraClassNames ?? "",
          ]}
          text={mainProps.buttonProps.text}
          disabled={isSubmitting || mainProps.disabled}
          loading={isSubmitting}
          onClick={function () {
            submit();
          }}
        />
      )}
    </>
  );
}

export enum ApiMethod {
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT",
  PATCH = "PATCH",
  GET = "GET",
}

export class Api<I, R> {
  private link: string;
  private method: ApiMethod;

  constructor(link: string, method: ApiMethod) {
    this.link = link;
    this.method = method;
  }

  execute(data: I): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      let api = undefined;
      switch (this.method) {
        case ApiMethod.POST:
          api = axios.post(this.link, data);
          break;

        case ApiMethod.PUT:
          api = axios.put(this.link, data);
          break;

        case ApiMethod.PATCH:
          api = axios.patch(this.link, data);
          break;

        case ApiMethod.DELETE:
          api = axios.delete(this.link);
          break;

        case ApiMethod.GET:
          api = axios.get(this.link);
          break;
      }
      if (api) {
        api
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  executeV2(data: I): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      let api = undefined;
      switch (this.method) {
        case ApiMethod.POST:
          api = axios.post(this.link, data);
          break;

        case ApiMethod.PUT:
          api = axios.put(this.link, data);
          break;

        case ApiMethod.PATCH:
          api = axios.patch(this.link, data);
          break;

        case ApiMethod.DELETE:
          api = axios.delete(this.link);
          break;

        case ApiMethod.GET:
          api = axios.get(this.link);
          break;
      }
      if (api) {
        api
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            let displayError = ApiErrorHelper.getDisplayErrorMessage(error);
            message.error(displayError);
            reject(error);
          });
      }
    });
  }
}

interface ApiLinkedButtonProps<ApiInputType> {
  api: Api<void, ApiInputType>;
  buttonProps: ButtonDisplayProps;
  uniqueKey: string;

  inputData?: ApiInputType;
  callback?: () => Promise<void>;
  disabled?: boolean;
  showConfirmation?: boolean;
}
export function ApiLinkedButton(props: ApiLinkedButtonProps<any>) {
  let alert = useAlert();
  let getCompletionCallback = (): (() => Promise<void>) => {
    return (): Promise<void> => {
      return new Promise((resolve, reject) => {
        props.api
          .execute(props.inputData)
          .then((res) => {
            resolve();
            if (props.callback) {
              return props.callback();
            }
          })
          .catch((error) => {
            let displayError = ApiErrorHelper.getDisplayErrorMessage(error);
            alert.error(displayError);
            reject(error);
          });
      });
    };
  };

  return (
    <>
      <CallbackButton {...props} callback={getCompletionCallback()} />
    </>
  );
}
