import React from "react";
import TimeAgo from "timeago-react";
import { AttachmentTargetType } from "../../../model/AttachmentTargetType";
import { TopicBadge } from "../../../topic/Topic.Badge";
import { ActorLink } from "../../common/Actor.link";
import { MarkdownView } from "../../common/MarkdownView";
import { ReactionCountsViewComponent } from "../../common/ReactionCountsViewComponent";
import { Post } from "./Charchaa.model";

interface PostDetailComponentProps {
  post: Post;
}

export function PostDetailComponent(props: PostDetailComponentProps) {
  return (
    <div className="PostDetailComponent">
      <div className="card">
        <div className="card-body">
          <h1 className="h4">{props.post.title}</h1>
          <div className="row">
            <div className="col-md-6">
              <div>
                Posted By:{"  "}
                {props.post && props.post.actor && (
                  <ActorLink actor={props.post.actor} actorImageWidth={30} />
                )}
              </div>
              <small>
                {props.post.createdAt ? (
                  <TimeAgo datetime={props.post.createdAt} />
                ) : null}
              </small>
            </div>

            {props.post.topics && (
              <div className="col-md-6 mob-pt-1">
                <div className="float-right">
                  {props.post.topics.map((topic, index) => (
                    <TopicBadge topic={topic} key={index} />
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="mt-4">
            {props.post.content && <MarkdownView text={props.post.content} />}
          </div>
          {props.post.reactionCounts && (
            <div className="mt-4 pt-2">
              <ReactionCountsViewComponent
                targetType={AttachmentTargetType.POST}
                targetId={"" + props.post.id}
                reactionCounts={props.post.reactionCounts}
                isLiked={props.post.isLiked}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
