import { message } from "antd";
import React from "react";
import { GeneralHelpers } from "../../helpers/General.helper";
import { LinkClickHandler } from "../../links/Link.ClickHandler";
import { HTMLProps } from "../../model/HTMLProps";

interface CopyClipboardButtonProps extends HTMLProps {
  textToCopy?: string;
  tooltip?: React.ReactNode;
  dl?: React.ReactNode;
  successDl?: React.ReactNode;
}

export function CopyClipboardButton(props: CopyClipboardButtonProps) {
  return (
    <>
      {props.textToCopy && (
        <>
          <LinkClickHandler
            tooltip={props.tooltip ?? "Click to copy"}
            className={props.className}
            onClick={() => {
              if (props.textToCopy) {
                GeneralHelpers.copyCliboard(props.textToCopy);
                message.success(
                  props.successDl ?? `Content copied: '${props.textToCopy}'`
                );
              }
            }}
          >
            <i className="fa-solid fa-copy"></i> {props.dl}
          </LinkClickHandler>
        </>
      )}
    </>
  );
}
