import React, { useEffect, useState } from "react";
import { RelatedContentApi } from "./RelatedContent.api";
import { ContentContext, IContent } from "./RelatedContent.model";

interface RelatedContentComponentProps {
  context: ContentContext;
}

export function RelatedContentComponent(props: RelatedContentComponentProps) {
  let [contents, setContents] = useState<IContent[]>();
  useEffect(() => {
    RelatedContentApi.getRelatedContent({
      context: props.context,
      pageParams: { page: 0, size: 10 },
    }).then((response) => {
      setContents(response);
    });
  }, []);
  return (
    <div className="RelatedContentComponent">
      {contents && contents.length > 0 && (
        <div className="card p-2">
          <h6>Related:</h6>
          <div className=" ">
            {contents &&
              contents.map((content, index) => (
                <div
                  key={index}
                  className={`${index != contents!.length - 1 && "mb-3"}`}
                >
                  <a href={content.url} className="text-dark">
                    {">"} {content.dl}
                  </a>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
