import { message } from "antd";
import { FieldArray, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CompanyAPI } from "../company/CompanyAPI";
import { DesignationAPI } from "../api/DesignationAPI";
import { CalendarApi } from "../calendar/Calendar.api";
import { EBTimezone } from "../calendar/Calendar.model";
import { ImageComponent } from "../components/common/Image.component";
import { EBDateInput } from "../components/common/eb-ui-components";
import { CompanySelector } from "../company/CompanySelector.component";
import { publicLinkConstants } from "../constants";
import { CountryApi } from "../country/Country.api";
import { DesignationSelector } from "../designation/Designation.selector";
import userHelpers from "../helpers/userHelpers";
import { Company } from "../company/Company";
import { Country } from "../model/Country.model";
import { Designation } from "../model/Designation";
import { Skill } from "../model/Skill";
import { PageProps } from "../model/views/PageProps";
import { SkillAPI } from "../skills/SkillAPI";
import { SocialApi } from "../social/Social.api";
import { SocialType } from "../social/Social.model";
import { ContactDetails, Experience, User, UserBasicDetails } from "./User";
import { UserAPI } from "./UserAPI";
import { UserEditProfileBasicDetailsInput } from "./UserEditProfileBasicDetails.input";
import { UserImageEdit } from "./UserImage.Edit";
import { Label } from "../components/common/Form/elements/FormInputLabel";

interface ExpereriencesProps {
  companies: Company[];
  allDesignations: Designation[];
  experiences?: Experience[];
}

function Expereriences(props: ExpereriencesProps) {
  let experiences = props.experiences;
  if (experiences) {
    for (var experience of experiences) {
      if (experience.startDate) {
        experience.startDate = moment(experience.startDate).format(
          "YYYY-MM-DD"
        );
      }
      if (experience.endDate) {
        experience.endDate = moment(experience.endDate).format("YYYY-MM-DD");
      }
    }
  }
  return (
    <FieldArray name="experiences">
      {({ insert, remove, push }: any) => (
        <div>
          {props.experiences &&
            props.experiences.map((experience, index) => (
              <div key={index} className="row">
                <div className="col">
                  <div className="bg-light mt-2 p-2">
                    <div className="">
                      <div className="row">
                        <div className="col-md-3">
                          <CompanySelector
                            label="Company"
                            required={true}
                            companies={props.companies}
                            name={`experiences.${index}.company`}
                            allowCreate={true}
                          />
                        </div>
                        <div className="col-md-4">
                          <Label label="Designation:" required />
                          <DesignationSelector
                            allowCreate={true}
                            designations={props.allDesignations}
                            name={`experiences.${index}.designation`}
                            required={true}
                          />
                        </div>
                        <div className="col-md-2">
                          <EBDateInput
                            label="Start Date"
                            name={`experiences.${index}.startDate`}
                            required
                            placeholder="Start date"
                            helpText={undefined}
                          />
                        </div>
                        <div className="col-md-2">
                          <EBDateInput
                            label="End Date"
                            helpText="Leave it blank for present job."
                            name={`experiences.${index}.endDate`}
                            placeholder="End Date"
                          />
                        </div>
                        <div className="col-md-1 d-flex justify-content-end">
                          <button
                            className="btn secondary"
                            type="button"
                            onClick={() => remove(index)}
                          >
                            <i className="far fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                      <div className="row"></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div className="row mt-3">
            <div className="col">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => push({})}
              >
                Add Experience
              </button>
            </div>
          </div>
        </div>
      )}
    </FieldArray>
  );
}

interface UserProfileFormProps {
  skills: Skill[];
  tzs: EBTimezone[];
  countries: Country[];
  companies: Company[];
  allDesignations: Designation[];
  socialTypes: SocialType[];
  user: User;
  contactDetails?: ContactDetails;
}

function UserProfileForm(props: UserProfileFormProps) {
  let initialValues = { ...props.user.basicDetails, ...props.contactDetails };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        return UserAPI.update(props.user.basicDetails.id)
          .executeV2(values)
          .then(() => {
            message.success("Profile Updated");
          });
      }}
    >
      {({ values, isSubmitting, handleSubmit, handleChange }) => (
        <form onSubmit={handleSubmit}>
          <div className="">
            <div className="">
              <h6 className="card-title font-weight-bold">Basic Details:</h6>

              <UserEditProfileBasicDetailsInput
                skills={props.skills}
                tzs={props.tzs}
                countries={props.countries}
                socialTypes={props.socialTypes}
                user={props.user}
              />
            </div>
          </div>
          <hr></hr>
          <div className=" mt-3">
            <div className="">
              <h6 className="card-title font-weight-bold">Experience:</h6>

              <Expereriences
                experiences={values.experiences}
                companies={props.companies}
                allDesignations={props.allDesignations}
              />
            </div>
          </div>

          <div
            className="bg-white p-3"
            style={{ position: "sticky", bottom: 0 }}
          >
            <div className="d-flex justify-content-end">
              <div>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Saving. Please Wait..." : "Save Changes"}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

interface UserProfileEditProps extends PageProps {
  showBecomeExpert?: boolean;
}

export function UserProfileEdit(props: UserProfileEditProps) {
  let [allCompanies, setAllCompanies] = useState<Company[]>();
  let [allDesignations, setAllDesignations] = useState<Designation[]>();
  let [allSkills, setAllSkills] = useState<Skill[]>();
  let [allTzs, setAllTzs] = useState<EBTimezone[]>();
  let [allCountries, setAllCountries] = useState<Country[]>();
  let [allSocialTypes, setAllSocialTypes] = useState<SocialType[]>();
  let [user, setUser] = useState<User>();

  useEffect(() => {
    UserAPI.fetchLoggedInUser(true, true).then((user) => {
      setUser(user);
    });

    CompanyAPI.fetchAllCompanies().then((companies) => {
      setAllCompanies(companies);
    });

    SkillAPI.fetchAll().then((skills) => {
      setAllSkills(skills);
    });

    CalendarApi.getAllTimezones().then((tzs) => {
      setAllTzs(tzs);
    });
    CountryApi.getAllCountriesList().then((countries) => {
      setAllCountries(countries);
    });
    SocialApi.getAllSocialTypes().then((socialTypes) => {
      setAllSocialTypes(socialTypes);
    });
    DesignationAPI.fetchAllDesignations().then((designations) => {
      setAllDesignations(designations);
    });
  }, []);

  return (
    <>
      {user &&
        allSkills &&
        allCountries &&
        allTzs &&
        allCompanies &&
        allDesignations &&
        allSocialTypes && (
          <div className="">
            <div className="row">
              <div className="col row">
                <div className="col-12 col-sm-auto mb-2">
                  <div className="mx-auto" style={{ width: 100 + "px" }}>
                    <div
                      className="d-flex justify-content-center align-items-center rounded"
                      style={{ height: 100 + "px" }}
                    >
                      <img
                        src={userHelpers.getProfilePicUrl(user.basicDetails)}
                        className="rounded"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
                <div className="col d-flex flex-column flex-sm-row justify-content-between">
                  <div className="text-center text-sm-left mb-2 mb-sm-0">
                    <h4 className="pt-sm-2 pb-1 mb-0 text-nowrap">
                      {user.basicDetails.dl}{" "}
                      {user.basicDetails.country?.dp && (
                        <ImageComponent image={user.basicDetails.country.dp} />
                      )}
                    </h4>
                    <p className="mb-0">{user.contactDetails?.email}</p>
                    <p>
                      <UserImageEdit user={user.basicDetails} />
                    </p>
                  </div>
                </div>
              </div>
              {props.showBecomeExpert && (
                <div className="col-auto">
                  <p className="mt-2">
                    <a
                      className="btn btn-primary"
                      href={publicLinkConstants.BECOME_EXPERT}
                    >
                      Become An Expert?
                    </a>
                  </p>
                </div>
              )}
            </div>

            <UserProfileForm
              user={user}
              contactDetails={user.contactDetails}
              companies={allCompanies}
              allDesignations={allDesignations}
              skills={allSkills}
              tzs={allTzs}
              countries={allCountries}
              socialTypes={allSocialTypes}
            />
          </div>
        )}
    </>
  );
}
