import { EventV2 } from "../events/Event.Model";
import { UserBasicDetails } from "../user/User";
import { Price } from "./Price";

export enum OrderStatus {
  CREATED = "CREATED",
  PENDING_REVIEW = "PENDING_REVIEW",
  PAID = "PAID",
  CANCELLED = "CANCELLED",
}

export enum PaymentStatus {
  PENDING = "PENDING",
  PAID = "PAID",
  INITIATED = "INITIATED",
  APPROVAL_PENDING = "APPROVAL_PENDING",
  APPROVED = "APPROVED",
}

export enum PaymentProcessorName {
  RAZORPAY = "RAZORPAY",
  CASHFREE = "CASHFREE",
  PAYPAL = "PAYPAL",
  FREE = "FREE",
  MANUAL = "MANUAL",
}

export enum PaymentDestinationType {
  UPI = "UPI",
  GPAY = "GPAY",
  CASH = "CASH",
  CARD = "CARD",
}

export interface OrderResponseV1 {
  id: string;
  price: Price;
  status: OrderStatus;
  orderItems: IOrderItem[];
  createdAt: Date;
  payments: PaymentDetails[];
  detailUrl: string;
  user?: UserBasicDetails;
}
export interface PaymentDetails {
  paymentStatus: PaymentStatus;
  paymentDate: Date;
  paymentPrice: Price;
  paymentMethod: PaymentProcessorName;
}
export interface PaymentDetailsManual extends PaymentDetails {
  verified: boolean;
  paymentDestinationType: PaymentDestinationType;
}

export interface PaymentDetailsRazorPay extends PaymentDetails {
  razorPayOrderId: string;
  razorpayPaymentId: string;
}

export interface PaymentDetailsPaypal extends PaymentDetails {
  paypalOrderId: string;
}

export enum OrderItemType {
  GENERAL_ITEM = "GENERAL_ITEM",
}

export interface IOrderItem {
  id: string;
  orderPrice: Price;
  itemType: OrderItemType;
}

export interface OrderItemEventSeat extends IOrderItem {
  id: string;
  itemPrice: Price;
  associatedEvent: EventV2;
}

export interface OrderItem extends IOrderItem {
  id: string;
  dl: string;
  detail: string;
  itemPrice: Price;
  itemBookingLink: string;
}
