import React from "react";
import { Stat } from "./Stat.Model";
import { StatCard } from "./Stat.Card";
import { Col, Row } from "antd";
import { HTMLProps } from "../model/HTMLProps";

interface StatCardListProps extends HTMLProps {
  stats: Stat[];
}

export function StatCardList(props: StatCardListProps) {
  return (
    <div className={`StatusCardList ${props.className}`}>
      <Row gutter={[10, 5]}>
        {props.stats.map((stat, index) => (
          <Col key={index}>
            <StatCard stat={stat} />
          </Col>
        ))}
      </Row>
    </div>
  );
}
