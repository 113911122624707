import { SortTokenName } from "./SortTokenName";

export interface SortToken {
  name: SortTokenName;
  direction: SortDirection;
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}
