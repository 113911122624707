import React from "react";
import { PageProps } from "../../../model/views/PageProps";

import { Col, Row } from "antd";
import { ButtonText } from "../../../model/views/Button.props";
import { AskQuestionModal } from "./AskQuestionModal";

export interface PageTopContentProps extends PageProps {
  title: React.ReactNode;
  buttonText?: ButtonText;
  otherLayout?: React.ReactNode;
}
export function PageTopContent(props: PageTopContentProps) {
  return (
    <>
      <Row align={"middle"}>
        <Col>
          <h1 className="h3 m-0 pr-3">{props.title}</h1>
        </Col>

        <Col
          flex={"auto"}
          className="rounded-pill bg-white border px-4 bg-hover-light"
        >
          {props.buttonText && (
            <AskQuestionModal
              user={props.user?.basicDetails}
              buttonText={props.buttonText}
              otherlayout={props.otherLayout}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
