import React from "react";
import Moment from "react-moment";
import { Booking } from "../booking/Booking.model";
import VideoSession from "../model/ExpertSession";
import { StatusComponent } from "../status/Status.Component";
import { SessionDetailItemComponent } from "./SessionDetailItem.Component";

interface VideoSessionSummaryCardProps {
  booking: Booking;
  vs: VideoSession;
}

export function VideoSessionSummaryCard(props: VideoSessionSummaryCardProps) {
  let vs = props.vs;
  return (
    <div className="VideoSessionSummaryCard">
      <div className="row">
        {vs.shouldShowScheduleSection && (
          <div className="col-xl-6 col-md-6">
            <SessionDetailItemComponent
              items={[
                {
                  title: props.booking.bcd.engineCompletedAt
                    ? "Happened at"
                    : "Scheduled at",
                  content: vs.scheduleDateTime ? (
                    <Moment format="DD-MM-YYYY HH:mm">
                      {vs.scheduleDateTime}
                    </Moment>
                  ) : (
                    "Not scheduled."
                  ),
                },
              ]}
            />
          </div>
        )}
        {props.booking.statusDl && (
          <div className="col-xl-6 col-md-6">
            <SessionDetailItemComponent
              items={[
                {
                  title: "Status",
                  content: <StatusComponent status={props.booking.statusDl} />,
                },
              ]}
            />
          </div>
        )}
      </div>
      {vs.shouldShowScheduleSection && (
        <div className="row mt-3">
          <div className="col-xl-6 col-md-6">
            <SessionDetailItemComponent
              items={[
                {
                  title: "Duration",
                  content: vs.displayDuration,
                },
              ]}
            />
          </div>
          <div className="col-xl-6 col-md-6">
            <SessionDetailItemComponent
              items={[
                {
                  title: "Meeting Link",
                  content: vs.scheduleMeetingLink ? (
                    <a href={vs.scheduleMeetingLink} target="_blank">
                      Click Here to Join
                    </a>
                  ) : (
                    "Not available ."
                  ),
                },
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
}
