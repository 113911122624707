import React from "react";
import { UserProfileEdit } from "../user/UserProfile.Edit";
import { ExpertStudioContentProps } from "./ExpertStudio.page";

interface UserProfileEditExpertStudioContentProps
  extends ExpertStudioContentProps {}

export function UserProfileEditExpertStudioContent(
  props: UserProfileEditExpertStudioContentProps
) {
  return (
    <div className="main-container">
      <UserProfileEdit {...props} />
    </div>
  );
}
