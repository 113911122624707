import { Currency, Price } from "../../../model/Price";
import { PriceOption } from "./EditPrice.component";

export class PriceHelper {
  public static getEditOptions(currency?: Currency): PriceOption[] {
    if (currency?.id == "USD") {
      return [
        {
          price: {
            amount: 0,
            currency: currency,
            isFree: true,
          },
        },
        {
          price: {
            amount: 100,
            currency: currency,
          },
        },
        {
          price: {
            amount: 500,
            currency: currency,
          },
        },
        {
          price: {
            amount: 1000,
            currency: currency,
          },
        },
        {
          price: {
            amount: 2500,
            currency: currency,
          },
        },
        {
          price: {
            amount: 5000,
            currency: currency,
          },
        },
      ];
    }
    return [
      {
        price: {
          amount: 0,
          currency: { id: "INR" } as Currency,
          isFree: true,
        },
      },
      {
        price: {
          amount: 10000,
          currency: { id: "INR" } as Currency,
        },
      },
      {
        price: {
          amount: 50000,
          currency: { id: "INR" } as Currency,
        },
      },
      {
        price: {
          amount: 100000,
          currency: { id: "INR" } as Currency,
        },
      },
    ];
  }

  public static getDefaultOfferingPrice(currency?: Currency): Price {
    if (currency?.id == "USD") {
      return { amount: 1000, currency: currency };
    }
    return { amount: 100000, currency: { id: "INR" } as Currency };
  }

  public static getDefaultCouponMinPrice(currency?: Currency): Price {
    if (currency?.id == "USD") {
      return { amount: 0, currency: currency };
    }
    return { amount: 0, currency: { id: "INR" } as Currency };
  }
}
