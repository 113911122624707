import React, { useEffect, useState } from "react";
import { OrderResponseV1 } from "../../model/OrderDetails";
import { OrderListComponent } from "../../orders/OrderList.component";
import { OrderAPI } from "../../api/OrderAPI";

export function AdminOrdersPageContent() {
  let [orders, setOrders] = useState<OrderResponseV1[]>();

  useEffect(() => {
    OrderAPI.fetchOrders().then((orders) => {
      setOrders(orders);
    });
  }, []);

  return (
    <div className="main-container">
      {orders && (
        <>
          <div>
            <OrderListComponent orders={orders} />
          </div>
        </>
      )}
    </div>
  );
}
