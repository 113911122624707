import React from "react";
import { InterviewRoundAPI } from "../../../api/InterviewRoundAPI";
import { Attachment, AttachmentLocationType } from "../../../model/Attachment";
import { EBModal } from "../EBModal";
import { URLInputForm } from "../Form/URLInputForm";
import { reloadWindowCallback } from "../reloadCallback";

interface UpdateInterviewRoundAttchmentViewProps {
  interviewRoundId: string;
  callback: () => void;
  uniqueKey: string;
  attachment: Attachment;
  attachmentDisplayName: string;
}

function UpdateAttachmentView(
  mainProps: UpdateInterviewRoundAttchmentViewProps
) {
  let submitCallback = (url: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      let updatedAttachment = mainProps.attachment;
      updatedAttachment.attachmentLocationData = url;
      updatedAttachment.attachmentLocationType = AttachmentLocationType.URL;
      InterviewRoundAPI.createOrUpdateAttachment(
        mainProps.interviewRoundId,
        updatedAttachment
      )
        .then(() => {
          resolve();
          mainProps.callback();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function Body({}) {
    return (
      <URLInputForm
        initialValue={mainProps.attachment.attachmentLocationData}
        submitCallback={submitCallback}
      />
    );
  }

  let modalId = mainProps.uniqueKey + "-attachment";
  return (
    <div className="update-attachment-view">
      <EBModal
        id={modalId}
        title={"Update " + mainProps.attachmentDisplayName}
        BodyComponent={<Body />}
      />
      <button
        type="button"
        className="btn btn-sm btn-secondary"
        data-bs-toggle="modal"
        data-bs-target={"#" + modalId}
      >
        <i className="fas fa-edit"></i>
      </button>
    </div>
  );
}

interface InterviewRoundAttachmentViewProps {
  interviewRoundId: string;
  attachment: Attachment;
  attachmentDisplayName: string;
  uniqueKey: string;
}
export function InterviewRoundAttachmentView(
  props: InterviewRoundAttachmentViewProps
) {
  return (
    <div className="hstack gap-2">
      {props.attachment.attachmentLocationData ? (
        <a
          target="_blank"
          className="text-dark"
          href={props.attachment.attachmentLocationData}
        >
          Link
        </a>
      ) : (
        "Not Present"
      )}
      <UpdateAttachmentView
        interviewRoundId={props.interviewRoundId}
        callback={reloadWindowCallback}
        attachmentDisplayName={props.attachmentDisplayName}
        uniqueKey={props.uniqueKey + "-" + props.attachment.attachmentName}
        attachment={props.attachment}
      />
    </div>
  );
}
