import { Col, Row } from "antd";
import React from "react";
import Moment from "react-moment";
import { Skill } from "../model/Skill";
import { SkillAdminActions } from "./Skill.AdminActions";

interface SkillListProps {
  skill: Skill;
  showAdminActions?: boolean;
}

export function SkillList(props: SkillListProps) {
  return (
    <div className={`SkillList`}>
      <Row gutter={[16, 10]}>
        <Col span={1}>{props.skill.id}</Col>
        <Col span={4}>{props.skill.dl}</Col>

        <Col span={4}>Parent: {props.skill.parent?.id}</Col>
        <Col span={7}>
          Marked Use for filter: &nbsp;
          {props.skill.markedUseForFilterAt && (
            <Moment format="DD-MM-YYYY HH:mm">
              {props.skill.markedUseForFilterAt}
            </Moment>
          )}
        </Col>

        {props.showAdminActions && (
          <Col span={8}>
            <SkillAdminActions skill={props.skill} />
          </Col>
        )}
      </Row>
    </div>
  );
}
