import React, { useEffect, useState } from "react";
import { Loader } from "../components/common/Loader.component";
import { Center } from "../components/common/layout/Center.component";
import { HTMLProps } from "../model/HTMLProps";
import { ListItemLayoutProps } from "./ListItem.Layout";

export interface ListItemProps extends HTMLProps {
  item: any;

  index: number;
  length: number;

  customListObject?: any;
  selectedIndex?: number;
}

interface ListProps extends HTMLProps {
  onFetch: () => Promise<any[]>;

  ItemLayoutComponent: React.ComponentType<ListItemLayoutProps>;
  emptyListContent?: React.ReactNode;
  customListObject?: any;
  onClickItem?: (item: any, index: number) => Promise<void>;
  selectedIndex?: number;

  itemNode?: (itemProps: ListItemProps) => React.ReactNode;

  /**
   * @deprecated Use itemNode instead
   */
  ItemComponent?: React.ComponentType<ListItemProps>;
}

export function List(props: ListProps) {
  let [items, setItems] = useState<any[]>();
  let [loading, setLoading] = useState<boolean>();
  let [selectedIndex, setSelectedIndex] = useState(props.selectedIndex);

  useEffect(() => {
    setLoading(true);
    props
      .onFetch()
      .then((items) => {
        setItems(items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  function handleOnClickItem(item: any, index: number) {
    if (props.onClickItem) {
      props.onClickItem(item, index);
    }
    setSelectedIndex(index);
  }

  return (
    <div className="List">
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {items &&
        items.map((item, index) => (
          <span
            className={`${props.onClickItem && "cursor-pointer"}`}
            onClick={
              props.onClickItem
                ? () => {
                    handleOnClickItem(item, index);
                  }
                : undefined
            }
            key={index}
          >
            <props.ItemLayoutComponent
              index={index}
              listLength={!items ? 0 : items.length}
              selectedIndex={selectedIndex}
            >
              {props.ItemComponent && (
                <props.ItemComponent
                  item={item}
                  customListObject={props.customListObject}
                  index={index}
                  length={!items ? 0 : items.length}
                  selectedIndex={selectedIndex}
                />
              )}
              {props.itemNode &&
                props.itemNode({
                  item: item,
                  index: index,
                  length: !items ? 0 : items.length,
                  selectedIndex: selectedIndex,
                })}
            </props.ItemLayoutComponent>
          </span>
        ))}
      {items && items.length == 0 && (
        <div className="p-3">{props.emptyListContent}</div>
      )}
    </div>
  );
}
