import axios from "axios";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { Designation } from "../model/Designation";

export class DesignationAPI {
  public static async fetchAllDesignations(): Promise<Designation[]> {
    return new Promise<Designation[]>((resolve, reject) => {
      axios
        .get("/api/designation/all")
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static add(designation: Designation): Promise<Designation> {
    return new Api<Designation, Designation>(
      apiLinkConstants.API_DESIGNATION_ADD,
      ApiMethod.POST
    ).execute(designation);
  }
}
