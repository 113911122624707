import React from "react";
import { EBButton, EBButtonProps } from "./EBButton";

interface EBModalProps {
  BodyComponent: React.ReactNode;
  id: string;
  title?: string;
  modalSize?: "sm" | "xl";
  primaryButtonProps?: EBButtonProps;
}

export function EBModal(modalProps: EBModalProps) {
  let titleId = modalProps.id + "-title";
  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      id={modalProps.id}
      tabIndex={-1}
      aria-labelledby={titleId}
      aria-hidden="true"
    >
      <div
        className={
          "modal-dialog " +
          (modalProps.modalSize ? "modal-" + modalProps.modalSize : "modal-xl")
        }
      >
        <div className="modal-content">
          {modalProps && modalProps.title && (
            <div className="modal-header">
              <h5 className="modal-title" id={titleId}>
                {modalProps.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          )}

          <div className="modal-body">{modalProps.BodyComponent}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              id={modalProps.id + "-close-button"}
            >
              Close
            </button>
            {modalProps.primaryButtonProps && (
              // This modal calls callback
              <EBButton {...modalProps.primaryButtonProps} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
