import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { Country } from "../model/Country.model";

export class CountryApi {
  public static getAllCountriesList(): Promise<Country[]> {
    return new Api<void, Country[]>(
      apiLinkConstants.API_COUNTRIES_LIST_FETCH,
      ApiMethod.GET
    ).execute();
  }
}
