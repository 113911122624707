import React, { useState } from "react";
import { LinkClickHandler } from "../links/Link.ClickHandler";
import { BadgeDesignProps } from "./Badge.Design";

interface BadgeListProps {
  size: number;
  render: (index: number) => React.ReactNode;

  viewLimit?: number;
  ViewMoreDesign?: React.ComponentType<BadgeDesignProps>;
}

export function BadgeList(props: BadgeListProps) {
  let [showAll, setShowAll] = useState<boolean>();
  let viewLimit = props.viewLimit ?? props.size;
  return (
    <>
      {showAll || viewLimit >= props.size ? (
        <>
          {[...Array(props.size)].map((x, i) => (
            <span key={i}>{props.render(i)}</span>
          ))}
        </>
      ) : (
        <>
          {[...Array(viewLimit - 1)].map((x, i) => (
            <span key={i}>{props.render(i)}</span>
          ))}
          <LinkClickHandler
            onClick={() => {
              setShowAll(true);
            }}
            className="text-dark"
          >
            <small>({props.size - viewLimit + 1} More)</small>
          </LinkClickHandler>
        </>
      )}
    </>
  );
}
