import React from "react";
import { BookingApi } from "../booking/Booking.Api";
import { Booking } from "../booking/Booking.model";
import { EBButtonV2Props } from "../components/common/EBButtonV2";
import { ApiLinkedButtonV3 } from "../components/common/Form/ApiLinked.button.V3";
import { FormikHiddenInput } from "../components/common/input/FormikHiddentInput";
import { VideoSessionCancelRequestInput } from "./VideoSession.CancelRequest.Input";

interface BookingCancelButtonProps {
  buttonProps: EBButtonV2Props;
  bookingId: string;
  notifyParticipants?: boolean;
  onSessionUpdate: (updatedBooking: Booking) => Promise<void>;
}
export function BookingCancelButton(props: BookingCancelButtonProps) {
  return (
    <div className="VideoSessionCancelButton">
      <ApiLinkedButtonV3
        buttonProps={props.buttonProps}
        api={BookingApi.cancel(props.bookingId)}
        uniqueKey={`booking-${props.bookingId}-cancel2`}
        apiFormBody={
          <div>
            <FormikHiddenInput
              name="notifyParticipants"
              value={props.notifyParticipants}
            />
            <VideoSessionCancelRequestInput />
            <div className="alert alert-danger mt-2">
              Note: This action cannot be reversed
            </div>
          </div>
        }
        successCallback={props.onSessionUpdate}
        formModalSize="md"
      />
    </div>
  );
}
