import { ModeBasedString } from "../../components/common/Form/model/mode.model";

export interface FormTemplateLineItemType {
  id: FormTemplateLineItemTypeId;
  dl: string;
}

export enum FormTemplateLineItemTypeId {
  QUESTION_FEEDBACKS = "QUESTION_FEEDBACKS",
  INPUT_LINE = "INPUT_LINE",
  INPUT_TEXT_AREA = "INPUT_TEXT_AREA",
  INPUT_MD = "INPUT_MD",
  BOOL = "BOOL",
  REVIEW = "REVIEW",
  FILE_UPLOAD = "FILE_UPLOAD",
}

export interface FormTemplateLineItemValidations {
  required: boolean;
  customValidations: any;
}

export interface FormTemplateLineItem {
  type: FormTemplateLineItemType;
  name: string;
  data: any;
  validations?: FormTemplateLineItemValidations;
}

export interface FormTemplateSection {
  order: number;
  displayTitle: ModeBasedString;
  displayDescription: ModeBasedString;
  lineItems: FormTemplateLineItem[];
  name: string;
}

export interface FormTemplate {
  id: string;
  name: string;
  displayTitle: ModeBasedString;
  displayDescription: ModeBasedString;
  saveButtonDisplayTitle: string;
  editButtonDisplayTitle: string;
  sections: FormTemplateSection[];
}

export interface FormTemplateResponse {
  id: string;
  createdAt: Date;
  templateData: FormTemplate;
}
