import React from "react";
import { ListItemLayoutDefault } from "../../list/ListItem.Layout";
import { ListItemProps, ListV2 } from "../../list/ListV2";
import { SearchRequest } from "../../model/search/UserSearchRequestV1";
import { PageResponse } from "../../paging/Paging.Model";
import { ExpertAPI } from "../../user/Expert.api";
import { UserListItemComponent } from "../../users/UserList.component";

export function AdminExpertsPageContent() {
  return (
    <div className="main-container">
      <ListV2
        onFetch={function (
          searchRequest?: SearchRequest | undefined
        ): Promise<PageResponse<any>> {
          return ExpertAPI.listExperts()
            .executeV2()
            .then((experts) => {
              return Promise.resolve({
                content: experts,
                totalSize: experts.length,
              });
            });
        }}
        ItemLayoutComponent={ListItemLayoutDefault}
        itemNode={(itemProps: ListItemProps) => {
          let user = itemProps.item;
          return <UserListItemComponent user={user} showAdminActions={true} />;
        }}
      />
    </div>
  );
}
