import { message } from "antd";
import { Form, Formik, useField, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { EBButton } from "../components/common/EBButton";
import { ImageComponent } from "../components/common/Image.component";
import { Tab, TabView } from "../components/common/Tab.view";
import {
  EBTextInput,
  FormInputLabel,
} from "../components/common/eb-ui-components";
import { DurationTimestampInput } from "../components/common/input/DurationTimestamp.input";
import { FormikHiddenInput } from "../components/common/input/FormikHiddentInput";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { FormikSwitchInput } from "../components/common/input/FormikSwitch.input";
import { EditPriceComponent } from "../components/common/price/EditPrice.component";
import { PriceHelper } from "../components/common/price/Price.Helper";
import { ApiErrorHelper } from "../helpers/ApiError.helper";
import { CountType } from "../model/Count.model";
import {
  IOfferingV2,
  OfferingTypeName,
  Offerings,
} from "../offerings/Offering";
import { UserBasicDetails } from "../user/User";
import {
  CouponEntity,
  CouponEntityType,
  CouponReward,
  CouponRewardType,
  UserCoupon,
} from "./Coupon.model";

interface AddOrEditCouponFormProps {
  onSubmit: (values: UserCoupon) => Promise<UserCoupon>;
  user: UserBasicDetails;
  offerings: Offerings;

  existingCoupon?: UserCoupon;
}

export function AddOrEditCouponForm(props: AddOrEditCouponFormProps) {
  return (
    <div className="AddOrEditCouponComponent">
      <Formik
        initialValues={
          props.existingCoupon ??
          ({
            isEnabled: true,
            maxCount: { type: CountType.LIMITED, value: 10 },
            reward: {
              rewardType: CouponRewardType.DISCOUNT_PERCENT,
              value: 10,
            },
            minPrice: PriceHelper.getDefaultCouponMinPrice(props.user.currency),
            allowedEntities: [
              {
                type: CouponEntityType.USER,
                id: props.user.id,
              },
            ],
          } as UserCoupon)
        }
        onSubmit={(values, actions) => {
          props
            .onSubmit(values)
            .catch((error) => {
              let displayError = ApiErrorHelper.getDisplayErrorMessage(error);
              message.error(displayError);
              return error;
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {(formProps) => (
          <Form>
            <h6>Basic details</h6>
            <div className="row">
              <div className="col-md-4">
                {!props.existingCoupon && (
                  <>
                    <EBTextInput
                      name="couponCode"
                      helpText={""}
                      label={"Code"}
                      required={true}
                    />
                  </>
                )}

                {props.existingCoupon && (
                  <>
                    <FormikHiddenInput
                      name="couponCode"
                      value={props.existingCoupon.couponCode}
                    />
                    Coupon code: {props.existingCoupon.couponCode}
                  </>
                )}
              </div>
              <div className="col-md-4">
                <FormInputLabel
                  label="Coupon Validity? (When will the coupon be active?)"
                  required={true}
                />
                <DurationTimestampInput name={"duration"} required={true} />
              </div>
              <div className="col-md-4">
                <CountInput name={"maxCount"} required={true} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormikSwitchInput name={`isEnabled`} label="Coupon enabled?" />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <h6>Reward (What will user get by using this coupon?)</h6>
                <CouponRewardInput name={"reward"} required={true} />
              </div>
            </div>
            <div className="row mt-2">
              <h6>Redeem rules (How can this coupon be redeemed?)</h6>
              <div className="col-md-6">
                <RedeemEntitiesInput
                  user={props.user}
                  offerings={props.offerings}
                  name={"allowedEntities"}
                />
              </div>
              <div className="col-md-6">
                <div>
                  <FormInputLabel label="Minimum Buy Amount (Coupon would be applied only if user's purchase is above this amount):" />
                  <EditPriceComponent
                    name="minPrice"
                    helpText=""
                    supportCustom={true}
                    defaultCurrency={props.user.currency}
                  />
                </div>

                <div className="mt-4">
                  Want to allow only specific user?
                  <FormInputLabel label="Provide email id of the user to whom you want to allow (This means user will be able to use only if they apply this coupon after logging in from below mentioned email):" />
                  <EBTextInput
                    helpText={undefined}
                    label={undefined}
                    name="specificUserEmail"
                  />
                </div>
              </div>
            </div>
            <EBButton
              classes={["btn btn-primary"]}
              text={{
                normal: "Save",
                submitting: "Saving...",
              }}
              disabled={formProps.isSubmitting}
              loading={formProps.isSubmitting}
              type="submit"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

interface RedeemEntitiesProps extends FormikInputParams {
  user: UserBasicDetails;
  offerings: Offerings;
}

export function RedeemEntitiesInput(props: RedeemEntitiesProps) {
  function checkIfEntityPresent(entity: CouponEntity): boolean {
    return (
      allowedEntities &&
      allowedEntities.some((allowedEntity: CouponEntity) => {
        return (
          entity.type == allowedEntity.type && entity.id === allowedEntity.id
        );
      })
    );
  }

  function getCouponEntityForOffering(offering: IOfferingV2) {
    switch (offering.commonDetails.type.typeName) {
      case OfferingTypeName.VIDEO_SESSION:
        return CouponEntityType.VIDEO_OFFERING;
      case OfferingTypeName.DIGITAL_PRODUCT:
        return CouponEntityType.DIGITAL_PRODUCT;
      case OfferingTypeName.TEXT_QUERY:
        return CouponEntityType.TEXT_OFFERING;
      case OfferingTypeName.PATH:
        return CouponEntityType.OFFERING_PATH;
      case OfferingTypeName.EVENT:
        return CouponEntityType.OFFERING_EVENT;
    }
  }

  function applyCouponToOffering(selected: boolean, offering: IOfferingV2) {
    if (selected) {
      addEntity({
        type: getCouponEntityForOffering(offering),
        id: offering.id,
      });
    } else {
      removeEntity({
        type: getCouponEntityForOffering(offering),
        id: offering.id,
      });
    }
  }

  function applyCouponToAll(allSelected: boolean) {
    if (allSelected) {
      addEntity({
        type: CouponEntityType.USER,
        id: props.user.id,
      });
    } else {
      removeEntity({
        type: CouponEntityType.USER,
        id: props.user.id,
      });
    }
  }

  function removeEntity(entity: CouponEntity) {
    let alreadyHasUsersAll = checkIfEntityPresent(entity);
    if (!alreadyHasUsersAll) {
      return;
    }
    if (!allowedEntities) {
      return;
    }
    let newAllowedEntities: CouponEntity[] = [];
    for (let currentAllowedEntity of allowedEntities) {
      if (
        currentAllowedEntity.type === entity.type &&
        currentAllowedEntity.id === entity.id
      ) {
        continue;
      }
      newAllowedEntities.push(currentAllowedEntity);
    }
    setFieldValue(newAllowedEntities);
  }

  function addEntity(entity: CouponEntity) {
    let alreadyHasUsersAll = checkIfEntityPresent({
      type: CouponEntityType.USER,
      id: props.user.id,
    });
    if (alreadyHasUsersAll) {
      return;
    }
    if (!allowedEntities) {
      allowedEntities = [];
    }
    allowedEntities.push(entity);
    setFieldValue(allowedEntities);
  }

  const [field] = useField(props);

  let allowedEntities: CouponEntity[] = field.value;

  let allUserSelected = checkIfEntityPresent({
    type: CouponEntityType.USER,
    id: props.user.id,
  });

  function setFieldValue(updatedAllowedEntities: CouponEntity[]) {
    field.onChange({
      target: {
        name: props.name,
        value: updatedAllowedEntities,
      },
    });
  }

  function getPropName(offering: IOfferingV2) {
    return `${props.name}_dummy.${offering.commonDetails.type.typeName}${offering.id}`;
  }

  const formikCtx = useFormikContext();
  useEffect(() => {
    function updateForOfferings(offerings?: IOfferingV2[]) {
      offerings?.map((offering, index) => {
        if (
          checkIfEntityPresent({
            type: getCouponEntityForOffering(offering),
            id: offering.id,
          })
        ) {
          formikCtx.setFieldValue(getPropName(offering), true);
        }
      });
    }

    if (allUserSelected) {
      formikCtx.setFieldValue(`${props.name}_dummy.allMyOfferings`, true);
    }
    updateForOfferings(props.offerings.videoSessions);
    updateForOfferings(props.offerings.offlineQueries);
    updateForOfferings(props.offerings.products);
    updateForOfferings(props.offerings.paths);
  }, []);

  return (
    <div className="RedeemEntitiesInput">
      <div className="hstack ">
        <FormikSwitchInput
          name={`${props.name}_dummy.allMyOfferings`}
          onChange={applyCouponToAll}
        />{" "}
        Apply on all My Offerings
      </div>
      {!allUserSelected && (
        <>
          <hr />
          Select offerings to which you want to apply this coupon:
          {[
            {
              offerings: props.offerings.videoSessions,
            },
            {
              offerings: props.offerings.offlineQueries,
            },
            {
              offerings: props.offerings.products,
            },
            {
              offerings: props.offerings.paths,
            },
          ].map((item, index) => (
            <>
              {item.offerings && item.offerings.length > 0 && (
                <div key={index}>
                  <span className="text-muted">
                    <ImageComponent
                      image={item.offerings[0].commonDetails.type.dp}
                      width={"14px"}
                    />
                  </span>
                  &nbsp;
                  {item.offerings[0].commonDetails.type.dl}:
                  {item.offerings.map((offering, index) => (
                    <div key={index} className="hstack">
                      <FormikSwitchInput
                        name={getPropName(offering)}
                        onChange={(selected) => {
                          applyCouponToOffering(selected, offering);
                        }}
                      />
                      {offering.commonDetails.dl}
                    </div>
                  ))}
                </div>
              )}
            </>
          ))}
        </>
      )}
    </div>
  );
}

interface CountInputProps extends FormikInputParams {}

export function CountInput(props: CountInputProps) {
  let [field] = useField(props);
  let currentValue = field.value;

  return (
    <div className="CountInput">
      <FormikHiddenInput
        name={`${props.name}.type`}
        value={CountType.LIMITED}
        required={props.required}
      />
      <EBTextInput
        name={`${props.name}.value`}
        helpText={undefined}
        label={"Quantity (How many times this coupon can be used?)"}
        required={props.required}
      />
    </div>
  );
}

interface CouponRewardInputProps extends FormikInputParams {}

export function CouponRewardInput(props: CouponRewardInputProps) {
  let [field] = useField(props);
  let currentValue: CouponReward = field.value;

  let tabs: Tab[] = [
    {
      title: "% Discount",
      content: (
        <div className="">
          <FormikHiddenInput
            name={`${props.name}.rewardType`}
            value={CouponRewardType.DISCOUNT_PERCENT}
          />
          <EBTextInput
            name={`${props.name}.value`}
            helpText={undefined}
            label={
              "Dicount % (Please put only whole number % without any decimal, like 10%, 20%, etc.)"
            }
            required={props.required}
          />
        </div>
      ),
      uniqueKey: "percent_discount",
    },
    {
      title: "Flat Discount",
      content: (
        <div className="">
          <FormikHiddenInput
            name={`${props.name}.rewardType`}
            value={CouponRewardType.DISCOUNT_AMOUNT}
          />
          <EBTextInput
            name={`${props.name}.value`}
            helpText={undefined}
            label={
              "Discount Amount (This much amount will be reduced if user applies the coupon.)"
            }
            required={props.required}
          />
        </div>
      ),
      uniqueKey: "amount_discount",
    },
  ];

  return (
    <div className="CouponRewardInput">
      <TabView
        tabs={tabs}
        selectedTab={
          currentValue &&
          currentValue.rewardType == CouponRewardType.DISCOUNT_PERCENT
            ? tabs[0]
            : tabs[1]
        }
      />
    </div>
  );
}
