import React, { useEffect, useState } from "react";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import { BunnyVideoPlayer } from "../components/common/BunnyVideo.Component";
import { Loader } from "../components/common/Loader.component";
import { VideoApi } from "./Video.Api";
import { VideoBunnyCdn, VideoStatus } from "./Video.Model";
import { VideoUploader } from "./VideoUploader";

interface VideoUploadComponentProps {
  videoId: string;
  onChange?: () => Promise<void>;
}
export function VideoUploadComponent(props: VideoUploadComponentProps) {
  let [video, setVideo] = useState<VideoBunnyCdn>();
  let [loading, setLoading] = useState<boolean>();

  function fetchVideo() {
    setLoading(true);
    VideoApi.getVideo(props.videoId)
      .execute()
      .then((video) => {
        setVideo(video);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  useEffect(fetchVideo, [props.videoId]);

  function onVideoUploadDone() {
    fetchVideo();
    if (props.onChange) {
      props.onChange();
    }
  }

  return (
    <div className="VideoUploadComponent">
      {video && (
        <>
          {video.status?.enumValue === VideoStatus.UPLOAD_COMPLETED && (
            <>
              <BunnyVideoPlayer
                maxWidth="100%"
                libraryId={video.libraryId}
                videoId={video.bunnyId}
              />
            </>
          )}

          {video.status?.enumValue === VideoStatus.PROCESSING && (
            <>
              <div className="alert alert-info rounded-0 m-0">
                <Loader size="sm" />{" "}
                <span className="ml-1 fs-6 bold"> Please wait!</span>
                <div className="mt-2">
                  At this time, we are processing your video so that it can be
                  optimally viewed by several users depending on their internet
                  speed.
                  <br />
                  This process can take few minutes to upto an hour. Please
                  check back in sometime to see the updated status.
                </div>
              </div>
            </>
          )}

          {(video.status?.enumValue === VideoStatus.UPLOAD_IN_PROGRESS ||
            video.status?.enumValue === VideoStatus.UPLOAD_FAILED) && (
            <>
              <VideoUploader video={video} onDone={onVideoUploadDone} />
            </>
          )}
        </>
      )}
    </div>
  );
}
