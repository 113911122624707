import { Api, ApiMethod } from "../../components/common/ApiLinkedButton";
import { admiApiLinkConstants, apiLinkConstants } from "../../constants";
import {
  PaymentReleaseRequest,
  PaymentReleaseRequestsResponse,
} from "./PaymentReleaseRequest.model";

export class PaymentReleaseRequestAdminApi {
  public static getAllPRRs(): Promise<PaymentReleaseRequestsResponse> {
    let api = admiApiLinkConstants.API_ADMIN_PAYMENT_RELEASE_REQUESTS_ALL;
    return new Api<void, PaymentReleaseRequestsResponse>(
      api,
      ApiMethod.GET
    ).execute();
  }
}
