import { message } from "antd";
import React from "react";
import {
  Api,
  ApiLinkedButton,
  ApiMethod,
  CallbackButton,
} from "../components/common/ApiLinkedButton";
import { ApiLinkedButtonV3 } from "../components/common/Form/ApiLinked.button.V3";
import { reloadWindowPromise } from "../components/common/reloadCallback";
import { admiApiLinkConstants } from "../constants";
import { SpoofHelper } from "../helpers/Spoof.Helper";
import { ExpertAPI } from "../user/Expert.api";
import { User } from "../user/User";

interface UserAdminActionsComponentProps {
  user: User;
}

function SpoofButton(props: UserAdminActionsComponentProps) {
  return (
    <CallbackButton
      buttonProps={{
        text: { normal: "Spoof" },
        extraClassNames: "btn-sm",
      }}
      callback={function (): Promise<void> {
        SpoofHelper.spoof(props.user.basicDetails);
        message.success("Spoofing started");
        return Promise.resolve();
      }}
      uniqueKey={"spood" + props.user.basicDetails.id}
    />
  );
}

function EngineEnableToggle(props: UserAdminActionsComponentProps) {
  return !props.user.basicDetails.expertDetails?.isExpertDisabled ? (
    <ApiLinkedButton
      api={
        new Api(
          admiApiLinkConstants.USER_EXPERT_DISABLE_TOGGLE.replaceAll(
            "{userId}",
            props.user.basicDetails.id
          ),
          ApiMethod.PUT
        )
      }
      uniqueKey={"user" + props.user.basicDetails.id + "-expert-disable"}
      callback={reloadWindowPromise}
      buttonProps={{
        text: {
          normal: "Expert Disable",
          submitting: "Please Wait...",
        },
        type: "danger",
        extraClassNames: "btn-sm",
      }}
    />
  ) : (
    <ApiLinkedButton
      api={
        new Api(
          admiApiLinkConstants.USER_EXPERT_DISABLE_TOGGLE.replaceAll(
            "{userId}",
            props.user.basicDetails.id
          ),
          ApiMethod.PUT
        )
      }
      uniqueKey={"user" + props.user.basicDetails.id + "-expert-enable"}
      callback={reloadWindowPromise}
      buttonProps={{
        text: {
          normal: "Expert Enable",
          submitting: "Please Wait...",
        },
        type: "success",
        extraClassNames: "btn-sm",
      }}
    />
  );
}

function TopEngineToggle(props: UserAdminActionsComponentProps) {
  return props.user.basicDetails.expertDetails?.isTopExpert ? (
    <ApiLinkedButton
      api={
        new Api(
          admiApiLinkConstants.USER_TOP_EXPERT_TOGGLE.replaceAll(
            "{userId}",
            props.user.basicDetails.id
          ),
          ApiMethod.PUT
        )
      }
      uniqueKey={"user" + props.user.basicDetails.id + "-top-expert-disable"}
      callback={reloadWindowPromise}
      buttonProps={{
        text: {
          normal: "Top Expert Disable",
          submitting: "Please Wait...",
        },
        type: "danger",
        extraClassNames: "btn-sm",
      }}
    />
  ) : (
    <ApiLinkedButton
      api={
        new Api(
          admiApiLinkConstants.USER_TOP_EXPERT_TOGGLE.replaceAll(
            "{userId}",
            props.user.basicDetails.id
          ),
          ApiMethod.PUT
        )
      }
      uniqueKey={"user" + props.user.basicDetails.id + "-expert-enable"}
      callback={reloadWindowPromise}
      buttonProps={{
        text: {
          normal: "Top Expert Enable",
          submitting: "Please Wait...",
        },
        type: "success",
        extraClassNames: "btn-sm",
      }}
    />
  );
}

function ToggleHomePage(props: UserAdminActionsComponentProps) {
  return !props.user.basicDetails.expertDetails
    ?.isMarkedEngineEnableHomePageListing ? (
    <ApiLinkedButtonV3
      api={ExpertAPI.updateEngineSettings(props.user.basicDetails.id)}
      initialValues={{
        ...props.user.basicDetails.expertDetails,
        isMarkedEngineEnableHomePageListing: true,
      }}
      uniqueKey={"user" + props.user.basicDetails.id + "-enable-homepage"}
      successCallback={reloadWindowPromise}
      buttonProps={{
        content: {
          normal: "Homepage Enable",
          submitting: "Please Wait...",
        },
        className: "btn-sm",
      }}
    />
  ) : (
    <ApiLinkedButtonV3
      api={ExpertAPI.updateEngineSettings(props.user.basicDetails.id)}
      initialValues={{
        ...props.user.basicDetails.expertDetails,
        isMarkedEngineEnableHomePageListing: false,
      }}
      uniqueKey={"user" + props.user.basicDetails.id + "-disable-homepage"}
      successCallback={reloadWindowPromise}
      buttonProps={{
        content: {
          normal: "Homepage Disable",
          submitting: "Please Wait...",
        },
        className: "btn-sm btn-danger",
      }}
    />
  );
}

function ToggleDisableRelated(props: UserAdminActionsComponentProps) {
  return !props.user.basicDetails.expertDetails
    ?.isMarkedEngineDisableRelatedListing ? (
    <ApiLinkedButtonV3
      api={ExpertAPI.updateEngineSettings(props.user.basicDetails.id)}
      initialValues={{
        ...props.user.basicDetails.expertDetails,
        isMarkedEngineDisableRelatedListing: true,
      }}
      uniqueKey={"user" + props.user.basicDetails.id + "-disable-related"}
      successCallback={reloadWindowPromise}
      buttonProps={{
        content: {
          normal: "Related Disable",
          submitting: "Please Wait...",
        },
        className: "btn-sm btn-danger",
      }}
    />
  ) : (
    <ApiLinkedButtonV3
      api={ExpertAPI.updateEngineSettings(props.user.basicDetails.id)}
      initialValues={{
        ...props.user.basicDetails.expertDetails,
        isMarkedEngineDisableRelatedListing: false,
      }}
      uniqueKey={"user" + props.user.basicDetails.id + "-enable-related"}
      successCallback={reloadWindowPromise}
      buttonProps={{
        content: {
          normal: "Related Enable",
          submitting: "Please Wait...",
        },
        className: "btn-sm",
      }}
    />
  );
}

function MarkOnboardedToday(props: UserAdminActionsComponentProps) {
  return (
    <ApiLinkedButtonV3
      api={ExpertAPI.updateEngineSettings(props.user.basicDetails.id)}
      initialValues={{
        ...props.user.basicDetails.expertDetails,
        onboardedAt: Date.now(),
      }}
      uniqueKey={"user" + props.user.basicDetails.id + "-makr-onboarded-today"}
      successCallback={reloadWindowPromise}
      buttonProps={{
        content: {
          normal: "Make onboarded today",
          submitting: "Please Wait...",
        },
        className: "btn-sm",
      }}
    />
  );
}

function ToggleMainListingPage(props: UserAdminActionsComponentProps) {
  return !props.user.basicDetails.expertDetails
    ?.isMarkedEngineEnableMainListing ? (
    <ApiLinkedButtonV3
      api={ExpertAPI.updateEngineSettings(props.user.basicDetails.id)}
      initialValues={{
        ...props.user.basicDetails.expertDetails,
        isMarkedEngineEnableMainListing: true,
      }}
      uniqueKey={"user" + props.user.basicDetails.id + "-enable-main-listing"}
      successCallback={reloadWindowPromise}
      buttonProps={{
        content: {
          normal: "Main Listing Page Enable",
          submitting: "Please Wait...",
        },
        className: "btn-sm",
      }}
    />
  ) : (
    <ApiLinkedButtonV3
      api={ExpertAPI.updateEngineSettings(props.user.basicDetails.id)}
      initialValues={{
        ...props.user.basicDetails.expertDetails,
        isMarkedEngineEnableMainListing: false,
      }}
      uniqueKey={"user" + props.user.basicDetails.id + "-disable-main-listing"}
      successCallback={reloadWindowPromise}
      buttonProps={{
        content: {
          normal: "Main Listing Page Disable",
          submitting: "Please Wait...",
        },
        className: "btn-sm btn-danger",
      }}
    />
  );
}

export function UserAdminActionsComponent(
  props: UserAdminActionsComponentProps
) {
  return (
    <div className="hstack gap-2">
      <SpoofButton {...props} />
      <EngineEnableToggle {...props} />
      <TopEngineToggle {...props} />
      <ToggleMainListingPage {...props} />
      <ToggleHomePage {...props} />
      <ToggleDisableRelated {...props} />
      <MarkOnboardedToday {...props} />
    </div>
  );
}
