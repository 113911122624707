import React from 'react';
import { InterviewLoopAPI } from '../../../api/InterviewLoopAPI';
import { InterviewLoop } from '../../../model/InterviewLoop';
import { AddInterviewLoopForm } from '../../common/ats/AddInterviewLoopForm';
import { InterviewLoopList } from '../../common/ats/InterviewLoopList';

interface InterviewLoopsPageState {
    interviewLoops?: InterviewLoop[];
}

interface InterviewLoopsPageProps {
    companyId: string;   
}

export class InterviewLoopsPage extends React.Component<InterviewLoopsPageProps, InterviewLoopsPageState> {

    componentDidMount() {
        this.fetchLoops();
    }

    callback() {
        window.location.reload();
    }

    fetchLoops() {
        InterviewLoopAPI.fetchInterviewLoops(this.props.companyId).then((loops) => {
            this.setState({interviewLoops: loops});
        })
    }

    render() {
        return (
            <div className="main-container">
                <AddInterviewLoopForm callback={this.callback} companyId={this.props.companyId}/>

                {this.state && this.state.interviewLoops && this.state.interviewLoops.length > 0 && (<>
                    <InterviewLoopList interviewLoops={this.state.interviewLoops}/>
                </>)}
            </div>
        )
    }
    
}