import React, { useEffect, useState } from "react";

import { Loader } from "../components/common/Loader.component";
import { Center } from "../components/common/layout/Center.component";
import { CouponApi } from "../coupon/Coupon.api";
import { CouponInstance, CouponInstanceStatus } from "../coupon/Coupon.model";
import { CouponInstancesList } from "../coupon/CouponInstances.list";
import { ExpertStudioContentProps } from "./ExpertStudio.page";

interface CouponUsagesExpertStudioContentProps
  extends ExpertStudioContentProps {}

export function CouponUsagesExpertStudioContent(
  props: CouponUsagesExpertStudioContentProps
) {
  let [couponInstances, setCouponInstances] = useState<CouponInstance[]>();
  let [loading, setLoading] = useState<boolean>();

  let couponCode = props.match?.params.couponCode;
  useEffect(() => {
    setLoading(true);
    CouponApi.getCouponInstances(props.user.basicDetails.id, couponCode, [
      CouponInstanceStatus.REDEEMED,
      CouponInstanceStatus.BLOCKED,
    ])
      .then((instances) => {
        setCouponInstances(instances);
      })
      .finally(() => {
        setLoading(false);
      });
  }, couponCode);
  return (
    <div className="CouponUsagesExpertStudioContent">
      <h3>
        <i className="fa-solid fa-tag"></i> {couponCode} Usages
      </h3>

      {loading && (
        <>
          <Center>
            <Loader />
          </Center>
        </>
      )}

      {!loading && (
        <>
          {couponInstances && (
            <div className="">
              <CouponInstancesList couponInstances={couponInstances} />
            </div>
          )}
          {(!couponInstances || couponInstances.length === 0) && (
            <>
              No usages found. Please let your network know about this coupon.
            </>
          )}
        </>
      )}
    </div>
  );
}
