import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useAlert } from "react-alert";
import { ratingHelpers } from "../../../helpers/commonHelpers";
import { ReviewV1 } from "../../../model/Reviews";
import { SelectItem } from "../../../model/views/Select.props";
import { EBTextAreaV2 } from "../Form/FormElements";
import { EBSelect } from "../eb-ui-components";
import { reloadWindowCallback } from "../reloadCallback";

interface ReviewEditProps {
  currentValues?: ReviewV1;
  askForImprovements?: boolean;
  namePrefix?: string;
}

function mapRatingSelectToAPI(selectVal: SelectItem) {
  return selectVal.value;
}

function mapRatingAPIToSelect(apiVal: number) {
  if (!apiVal) {
    return apiVal;
  }
  let selectValue = ratingHelpers.getDisplayRatingForValue(apiVal);
  return selectValue;
}

export function ReviewEdit(props: ReviewEditProps) {
  let namePrefix = "";
  if (props.namePrefix) {
    namePrefix = props.namePrefix + ".";
  }
  return (
    <>
      <Field
        label="Rating (5 being the highest)"
        name={namePrefix + "starRatingFive"}
        component={EBSelect}
        options={ratingHelpers.ratings}
        isMulti={false}
        mappingRequired={true}
        selectToApiMapper={mapRatingSelectToAPI}
        apiToSelectMapper={mapRatingAPIToSelect}
        required
      />
      <EBTextAreaV2
        label="Review"
        helpText=""
        name={namePrefix + "text"}
        maxLength={1000}
        rows={4}
        enablePerformance={true}
        required
      />

      {props.askForImprovements &&
      props.currentValues &&
      props.currentValues.starRatingFive &&
      props.currentValues.starRatingFive !== 5 ? (
        <EBTextAreaV2
          className="mt-2"
          label="What improvement do you think can to make it 5 star?"
          name={namePrefix + "improvements"}
          helpText=""
          maxLength={1000}
          minLength={100}
          rows={4}
          showCount={true}
          enablePerformance={true}
          required
        />
      ) : null}
    </>
  );
}

interface ReviewFormProps {
  reviewSubmitApiLink: string;
  askForImprovements?: boolean;
}
export function ReviewForm(props: ReviewFormProps) {
  let alert = useAlert();
  let initialValues: ReviewV1 = { text: "", starRatingFive: 5 };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          axios
            .post(props.reviewSubmitApiLink, values)
            .then((response) => {
              reloadWindowCallback();
              alert.success("Review saved!");
              actions.setSubmitting(false);
            })
            .catch((error) => {
              alert.error("Could not save review!");
              actions.setSubmitting(false);
            });
        }}
      >
        {(formProps) => (
          <Form>
            <ReviewEdit
              currentValues={formProps.values}
              askForImprovements={props.askForImprovements}
            />
            <div className="">
              <button
                className="btn btn-sm btn-primary"
                type="submit"
                disabled={formProps.isSubmitting}
              >
                {formProps.isSubmitting ? "Saving... Please wait." : "Save"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
