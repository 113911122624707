import { useField } from "formik";
import React, { useState } from "react";
import { Label } from "../components/common/Form/elements/FormInputLabel";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { RadioButtonGroupComponent } from "../components/common/input/RadioButtonGroup.component";
import { ExpertSessionHelpers } from "../helpers/Helpers";
import { ButtonDisplayProps } from "../model/views/Button.props";
import {
  IOfferingV2,
  OfferingOption,
  OfferingOptionDurationSkillData,
} from "./Offering";

export interface OfferingOptionsDurationSkillsComponentProps
  extends FormikInputParams {
  options: OfferingOption[];
  offering: IOfferingV2;
  onOptionSelect: (option: OfferingOptionDurationSkillData) => void;
}

export function OfferingOptionsDurationSkillsComponent(
  props: OfferingOptionsDurationSkillsComponentProps
) {
  const [field, meta, helpers] = useField({
    ...props,
    validate: (value: any) => {
      if (props.required && !value) {
        return "Required";
      }
    },
  });
  let [selectedOption, setSelectedOption] =
    useState<OfferingOptionDurationSkillData>();
  let [selectedDurationIndex, setSelectedDurationIndex] = useState<number>(0);
  let [selectedSkillIndex, setSelectedSkillIndex] = useState<number>();

  let optionDatas: OfferingOptionDurationSkillData[] = props.options
    .map((option) => option.data)
    .filter(
      (optionData) => optionData.isEnabled && !optionData.deletedAt
    ) as OfferingOptionDurationSkillData[];
  optionDatas.sort((op1, op2) => {
    return op1.price.amount - op2.price.amount;
  });

  let durations: number[] = [];
  let durationButtons: ButtonDisplayProps[] = [];
  for (let optionData of optionDatas) {
    if (!durations.includes(optionData.durationInSeconds)) {
      durations.push(optionData.durationInSeconds);
      durationButtons.push({
        text: {
          normal: ExpertSessionHelpers.getDisplayDuration(
            optionData.durationInSeconds
          ),
          submitting: "",
        },
        type: "secondary",
      });
    }
  }

  let skillOptions: OfferingOptionDurationSkillData[] = [];
  let skillButtons: ButtonDisplayProps[] = [];
  for (let optionData of optionDatas) {
    if (optionData.durationInSeconds === durations[selectedDurationIndex]) {
      skillOptions.push(optionData);
      skillButtons.push({
        text: {
          normal: optionData.skill.dl,
          submitting: "",
        },
        type: "secondary",
      });
    }
  }

  function onDurationOptionChange(index: number) {
    setSelectedDurationIndex(index);
    setSelectedSkillIndex(undefined);
    setSelectedOption(undefined);
  }

  function onSkillChange(index: number) {
    setSelectedSkillIndex(index);
    field.onChange({
      target: {
        name: props.name,
        value: skillOptions[index],
      },
    });
    props.onOptionSelect(skillOptions[index]);
    // setSelectedOption(skillOptions[index]);
  }

  return (
    <div className="OfferingOptionsDurationSkillsComponent">
      <div>
        <Label
          label="Please select the duration for which you want to book:"
          required={true}
        />
        <RadioButtonGroupComponent
          buttonProps={durationButtons}
          onButtonClick={onDurationOptionChange}
          selectedIndex={selectedDurationIndex}
        />
      </div>
      <div className="mt-4">
        {skillOptions.length > 0 && (
          <>
            <Label label="Select topic:" required={true}></Label>
            <RadioButtonGroupComponent
              buttonProps={skillButtons}
              onButtonClick={onSkillChange}
              selectedIndex={selectedSkillIndex}
            />
          </>
        )}
      </div>
    </div>
  );
}
