import { Col, Divider, Row } from "antd";
import { useField } from "formik";
import React from "react";
import { DeleteButton } from "../../button/Delete.Button";
import { Label } from "../../components/common/Form/elements/FormInputLabel";
import { FormikInputParams } from "../../components/common/input/FormikInputParams.model";
import { FormikSwitchInput } from "../../components/common/input/FormikSwitch.input";
import {
  FormTemplateLineItem,
  FormTemplateLineItemTypeId,
} from "../model/formTemplate.model";
import { FormSectionLineItemBoolTemplateBuilderFormikInput } from "./Form.Section.LineItem.Bool.TemplateBuilder.FormikInput";
import { FormSectionLineItemFileUploadTemplateBuilderFormikInput } from "./Form.Section.LineItem.FileUpload.TemplateBuilder.FormikInput";
import { FormSectionLineItemTextAreaTemplateBuilderFormikInput } from "./Form.Section.LineItem.TextArea.TemplateBuilder.FormikInput";
import { FormSectionLineItemTextLineTemplateBuilderFormikInput } from "./Form.Section.LineItem.TextLine.TemplateBuilder.FormikInput";
import { FormSectionLineItemTypeSelector } from "./Form.Section.LineItem.Type.Selector";
import { FormTemplateBuilderProps } from "./Form.Template.Builder";
import { FormTemplateHelper } from "./Form.Template.Helper";

export interface FormSectionLineItemSpecificTypeTemplateBuilderFormikInputProps
  extends FormikInputParams {
  lineItem: FormTemplateLineItem;
}

interface FormTemplateSectionLineItemBuilderProps
  extends FormTemplateBuilderProps {
  onDelete?: () => Promise<void>;
}

export function FormTemplateSectionLineItemBuilder(
  props: FormTemplateSectionLineItemBuilderProps
) {
  const [field] = useField(props);
  let lineItem = field.value as FormTemplateLineItem;

  return (
    <div
      className={`card card-body border-0 bg-light pb-0 FormTemplateSectionLineItemBuilder ${props.className}`}
    >
      <Row gutter={[12, 12]}>
        <Col>
          <FormSectionLineItemTypeSelector
            types={FormTemplateHelper.allLiTypes()}
            name={`${props.name}.type`}
          />
        </Col>
        <Col flex="auto">
          {lineItem.type.id == FormTemplateLineItemTypeId.INPUT_TEXT_AREA && (
            <FormSectionLineItemTextAreaTemplateBuilderFormikInput
              lineItem={lineItem}
              name={props.name}
            />
          )}

          {lineItem.type.id == FormTemplateLineItemTypeId.INPUT_LINE && (
            <FormSectionLineItemTextLineTemplateBuilderFormikInput
              lineItem={lineItem}
              name={props.name}
            />
          )}

          {lineItem.type.id == FormTemplateLineItemTypeId.BOOL && (
            <FormSectionLineItemBoolTemplateBuilderFormikInput
              lineItem={lineItem}
              name={props.name}
            />
          )}

          {lineItem.type.id == FormTemplateLineItemTypeId.FILE_UPLOAD && (
            <FormSectionLineItemFileUploadTemplateBuilderFormikInput
              lineItem={lineItem}
              name={props.name}
            />
          )}
        </Col>
      </Row>
      <Row className="py-2 mt-2" align={"middle"}>
        <Col flex={"auto"}></Col>
        <Col className="">
          <Row align={"middle"}>
            <Col className="">
              <Label label="Required" className="mr-2" />

              <FormikSwitchInput
                className="d-inline pt-3"
                name={`${props.name}.validations.required`}
              />
            </Col>
            <Col>
              {" "}
              <Divider type="vertical" />
            </Col>
            <Col>
              <DeleteButton onClick={props.onDelete} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
