import { Booking } from "../booking/Booking.model";
import { SchedulingPreference } from "../calendar/Calendar.model";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import VideoSession from "../model/ExpertSession";
import { Target } from "../model/target.model";

export class BookingHelper {
  public static getInitialGroupCall(
    source: Target,
    expertId: string,
    schedulingPref: SchedulingPreference
  ): Booking {
    let vs: VideoSession = {
      schedulingPreference: schedulingPref,
      durationSeconds: 3600,
    } as VideoSession;

    let booking: Booking = {
      bcd: {
        source: source,
        engineId: expertId,
        engineFeedbackFormTemplateId: "default_expert_session_by_expert",
        bogieFeedbackFormTemplateId: "default_expert_session_by_mentee",
        isSkipBogieCompletion: true,
      },
      bookingTarget: {
        targetType: AttachmentTargetType.VIDEO_SESSION,
      } as Target,
      specificBookingTypeModel: vs,
    } as Booking;
    return booking;
  }
}
