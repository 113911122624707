import { commonConstants, paymentConstants } from "../constants";
import { UserHelpers } from "../helpers/Helpers";
import { PaymentProcessorName } from "../model/response/Checkout.response";
import {
  RazorpaymentProcessorCheckoutResponse,
  RazorpaymentProcessorPaymentResponse,
} from "../model/response/Razorpay.response";
import { User } from "../user/User";

export class RazorpayAPI {
  public static processPayment(
    data: RazorpaymentProcessorCheckoutResponse,
    user: User
  ): Promise<RazorpaymentProcessorPaymentResponse> {
    return new Promise<RazorpaymentProcessorPaymentResponse>(
      (resolve, reject) => {
        this.loadScript("https://checkout.razorpay.com/v1/checkout.js")
          .then((scriptLoaded) => {
            this.book(data, user)
              .then((response) => {
                resolve(response);
              })
              .catch((err) => reject(err));
          })
          .catch((err) => {
            reject(err);
          });
      }
    );
  }

  static loadScript(src: string): Promise<boolean> {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  static book(
    data: RazorpaymentProcessorCheckoutResponse,
    user: User
  ): Promise<RazorpaymentProcessorPaymentResponse> {
    return new Promise<RazorpaymentProcessorPaymentResponse>(
      (resolve, reject) => {
        const options = {
          key: data.rpKey,
          name: commonConstants.APP_NAME,
          order_id: data.rpOrderId,
          handler: async function (response: any) {
            const data: RazorpaymentProcessorPaymentResponse = {
              name: PaymentProcessorName.RAZORPAY,
              razorpayPaymentId: response.razorpay_payment_id.toString(),
              razorpayOrderId: response.razorpay_order_id.toString(),
              razorpaySignature: response.razorpay_signature.toString(),
            };
            resolve(data);
          },
          modal: {
            ondismiss: function () {
              reject();
            },
          },
          prefill: {
            name: UserHelpers.getFullName(user.basicDetails),
            email: user.contactDetails?.email,
            contact: user.contactDetails?.phone,
          },
          theme: {
            color: paymentConstants.RAZORPAY_THEME_COLOR,
          },
        };
        const paymentObject = (window as any).Razorpay(options);
        paymentObject.open();
      }
    );
  }
}
