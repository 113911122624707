import axios from "axios";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { Company } from "./Company";
import { CompanyConstants } from "./Company.Constants";

export class CompanyAPI {
  public static async fetchAllCompanies(): Promise<Company[]> {
    return new Promise<Company[]>((resolve, reject) => {
      axios
        .get(CompanyConstants.API_COMPANY_LIST)
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static addCompany(company: Company): Promise<Company> {
    return new Api<Company, Company>(
      CompanyConstants.API_COMPANY_ADD,
      ApiMethod.POST
    ).execute(company);
  }

  public static getCompany(companyId: string): Api<void, Company> {
    return new Api<void, Company>(
      CompanyConstants.API_COMPANY_GET.replaceAll("{companyId}", companyId),
      ApiMethod.GET
    );
  }

  public static updateCompany(companyId: string): Api<Company, Company> {
    return new Api<Company, Company>(
      CompanyConstants.API_COMPANY_UPDATE.replace("{companyId}", companyId),
      ApiMethod.PUT
    );
  }
}
