import React from "react";
import { TitleProps } from "./title.model";

export function EditTitle(props: TitleProps) {
  return (
    <>
      <i className="fas fa-edit"></i> {props.text}
    </>
  );
}
