import React from "react";

export function EmptyOfferingsMessage() {
  return (
    <>
      Oops! It seems you haven't added any offerings yet. Don't worry, we're
      here to help you showcase your amazing products/services. Take a moment to
      add your offerings and let the world discover what you have to offer. Your
      next customer could be just a few clicks away!
    </>
  );
}
