import { ConfigProvider, Steps, message } from "antd";
import React, { useState } from "react";
import { Center } from "../common/layout/Center.component";

export interface Step {
  title: string;
  content: React.ReactNode;
}

export interface StepperComponentProps {
  steps: Step[];
  isSubmitting?: boolean;
  onDone: () => Promise<void>;
}
export function StepperComponent(props: StepperComponentProps) {
  let [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <div className="CreateEventComponent card">
      <div className="card-header event-data-desktop">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#fed136",
            },
          }}
        >
          <Steps current={current} items={props.steps} />
        </ConfigProvider>
      </div>
      <div className="card-header event-data-mobile">
        <div className=" text-center pt-20 pe-0 pb-10 ps-0 fw-bold">
          Step {current + 1}/{props.steps.length}
        </div>
      </div>
      <div className="card-body">
        <div>{props.steps[current].content}</div>
      </div>
      <div>
        <Center>
          {current > 0 && (
            <button className="btn btn-secondary m-1" onClick={() => prev()}>
              Previous
            </button>
          )}
          {current < props.steps.length - 1 && (
            <button className="btn btn-primary m-1" onClick={() => next()}>
              Next
            </button>
          )}

          {current === props.steps.length - 1 && (
            <button
              className="btn btn-primary m-1"
              onClick={() => {
                message.success("Processing complete!");
                props.onDone();
              }}
            >
              Done
            </button>
          )}
        </Center>
      </div>
    </div>
  );
}
