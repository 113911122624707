import React, { useEffect, useState } from "react";
import { ListItemProps } from "../../list/List";
import { CompanyAPI } from "../../company/CompanyAPI";
import { ListItemLayoutDefault } from "../../list/ListItem.Layout";
import { ListV2 } from "../../list/ListV2";
import { PageResponse } from "../../paging/Paging.Model";
import { CompanyListItem } from "../../company/Company.ListItem";

export function AdminCompaniesPage() {
  return (
    <div className="main-container">
      <ListV2
        onFetch={function (): Promise<PageResponse<any>> {
          return CompanyAPI.fetchAllCompanies().then(function (result) {
            return { content: result, totalSize: result.length };
          });
        }}
        ItemLayoutComponent={ListItemLayoutDefault}
        itemNode={(itemProps: ListItemProps) => {
          let company = itemProps.item;
          return <CompanyListItem company={company} showAdminActions={true} />;
        }}
      />
    </div>
  );
}
