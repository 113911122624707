import React from "react";
import { TargetLink } from "../../components/common/Target.link";
import { TimestampView } from "../../components/common/TimestampView";
import { PriceHelpers } from "../../helpers/Helpers";
import { UserLink } from "../../user/User.link";
import { LedgerEntry, LedgerEntryType } from "./Ledger.model";
import { PriceView } from "../../components/common/Price.view";

interface LedgerEntryListComponentProps {
  entries: LedgerEntry[];
}

export function LedgerEntryListComponent(props: LedgerEntryListComponentProps) {
  return (
    <div className="OrderListComponent">
      {props.entries.map((entry, index) => (
        <LedgerEntryListItemComponent key={index} entry={entry} />
      ))}
    </div>
  );
}

interface LedgerEntryListItemComponentProps {
  entry: LedgerEntry;
}

export function LedgerEntryListItemComponent(
  props: LedgerEntryListItemComponentProps
) {
  return (
    <div
      className={`LedgerEntryListItemComponent card card-body mb-2 border-left-${
        props.entry.type == LedgerEntryType.CREDIT && "success"
      } border-left-${
        props.entry.type == LedgerEntryType.DEBIT && "danger"
      } shadow`}
    >
      <div className={`row gy-2`}>
        <div className="col-md-2">
          <TimestampView timestamp={props.entry.createdAt} tzInToolTip={true} />
        </div>
        <div className="col-md-1">{props.entry.type}</div>

        {props.entry.type === LedgerEntryType.CREDIT && (
          <>
            <div className="col-md-2">
              <PriceView price={props.entry.creditAmount} />
            </div>
            <div className="col-md-2"></div>
          </>
        )}

        {props.entry.type === LedgerEntryType.DEBIT && (
          <>
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <PriceView price={props.entry.debitAmount} />
            </div>
          </>
        )}

        <div className="col-md-2">
          <UserLink user={props.entry.user} />
        </div>
        <div className="col-md-2">{props.entry.creationComments}</div>
        {props.entry.source && props.entry.source.label && (
          <>
            <div className="col-md-1">
              <TargetLink target={props.entry.source} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
