import { Tooltip } from "antd";
import React from "react";

export function ArchivedBadge() {
  return (
    <Tooltip title={"Archived is not shown to any!"}>
      <span className="text-danger small">(Archived)</span>
    </Tooltip>
  );
}
