import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { BookingConstants } from "../../booking/Booking.Constants";
import { BookingStatusEnumId } from "../../booking/Booking.model";
import { admiApiLinkConstants, adminLinkConstants } from "../../constants";
import { UserHelpers } from "../../helpers/Helpers";
import { LinkHelpers } from "../../helpers/Link.Helpers";
import { WithDrawalAdminApi } from "../../payments/withdrawal/Withdrawal.admin.api";
import {
  WithdrawalResponse,
  WithdrawalStatus,
} from "../../payments/withdrawal/Withdrawal.model";
import { WithdrawalSummaryListComponent } from "../../payments/withdrawal/WithdrawalSumary.component";
import { Api, ApiLinkedButton, ApiMethod } from "../common/ApiLinkedButton";
import { PriceView } from "../common/Price.view";
import { reloadWindowPromise } from "../common/reloadCallback";

export function AdminUserWithdrawalList() {
  let [withdrawalsResponse, setWithdrawalsResonse] =
    useState<WithdrawalResponse>();

  useEffect(() => {
    WithDrawalAdminApi.getAllWithdrawals().then((withdrawalsResponse) => {
      setWithdrawalsResonse(withdrawalsResponse);
    });
  }, []);

  return (
    <div className="">
      {withdrawalsResponse && withdrawalsResponse.summaries && (
        <>
          <WithdrawalSummaryListComponent
            summaries={withdrawalsResponse.summaries}
          />
        </>
      )}

      {withdrawalsResponse &&
      withdrawalsResponse.withdrawals &&
      withdrawalsResponse.withdrawals.length > 0 ? (
        <table className="table table-striped table-hover mt-4">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">User</th>
              <th scope="col">Amount</th>
              <th scope="col">Destination</th>
              <th scope="col">Creation Date</th>
              <th scope="col">Payment Date</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {withdrawalsResponse.withdrawals.map((payment, index) => (
              <tr key={payment.id}>
                <th scope="row">{index + 1}</th>
                <td>
                  {payment.user ? (
                    <>
                      <a
                        href={UserHelpers.getUserProfileUrl(payment.user)}
                        className="text-dark fw-bold"
                      >
                        {UserHelpers.getFullName(payment.user)}
                      </a>
                      <br />
                      <a
                        target="_blank"
                        href={LinkHelpers.addQueryParams(
                          adminLinkConstants.BOOKINGS,
                          [
                            {
                              key: BookingConstants.Q_PARAM_ENGINE_IDS_KEY,
                              value: [payment.user.id],
                            },
                            {
                              key: BookingConstants.Q_PARAM_STATUSES_KEY,
                              value: [BookingStatusEnumId.COMPLETED],
                            },
                          ]
                        )}
                      >
                        All completed bookings
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <PriceView price={payment.paymentAmount} />
                </td>
                <td>
                  {payment.paymentDestination?.type}{" "}
                  {payment.paymentDestination?.data}
                </td>
                <td>
                  <Moment format="DD-MM-YYYY">{payment.createdAt}</Moment>
                </td>
                <td>
                  <Moment format="DD-MM-YYYY">{payment.paymentDate}</Moment>
                </td>
                <td>{payment.status}</td>
                <td>
                  {payment.status === WithdrawalStatus.PENDING ? (
                    <ApiLinkedButton
                      uniqueKey={"payment-" + payment.id + "-mark-paid"}
                      api={
                        new Api(
                          admiApiLinkConstants.API_ADMIN_WITHDRAWAL_MARK_PAID.replace(
                            "{withdrawalId}",
                            payment.id
                          ),
                          ApiMethod.POST
                        )
                      }
                      callback={reloadWindowPromise}
                      buttonProps={{
                        text: {
                          normal: "Mark Paid",
                          submitting: "Please Wait...",
                        },
                        type: "info",
                      }}
                    />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>No Payments Found</>
      )}
    </div>
  );
}
