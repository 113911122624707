import React from "react";
import { Time } from "../../../calendar/Calendar.model";
import { TimeHelper } from "../../../calendar/Time.helper";
import {
  EBSelectOption,
  EBSelectOptionDataType,
  EBSelectV2,
} from "../Form/EBSelectV2";
import { SelectorProps } from "../Selectors";

interface TimeSelectorProps extends SelectorProps {
  times: Time[];
}

export interface TimeSelectorOption extends EBSelectOptionDataType, Time {}

export function TimeSelector(props: TimeSelectorProps) {
  function onCreate(
    title: string
  ): Promise<EBSelectOption<TimeSelectorOption>> {
    return Promise.resolve({} as EBSelectOption<TimeSelectorOption>);
  }
  let options: EBSelectOption<TimeSelectorOption>[] = [];

  props.times.forEach((time: Time) => {
    options.push({
      label: TimeHelper.getDisplayString(time),
      value: TimeHelper.getDisplayString(time),
      data: {
        ...time,
        selectOptionValue: TimeHelper.getDisplayString(time),
      },
    });
  });

  return (
    <>
      {options && (
        <EBSelectV2
          label={props.fieldTitle ?? "Time: "}
          name={`${props.name}`}
          required={props.required}
          options={options}
          disabled={props.disabled}
          onCreate={onCreate}
          isDataEqual={(d1: TimeSelectorOption, d2: TimeSelectorOption) =>
            d1.h === d2.h && d1.m === d2.m
          }
        />
      )}
    </>
  );
}
