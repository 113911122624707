import React, { useEffect, useState } from "react";
import { BookingApi } from "../../../booking/Booking.Api";
import { Booking } from "../../../booking/Booking.model";
import { Loader } from "../../../components/common/Loader.component";
import { Center } from "../../../components/common/layout/Center.component";
import { GroupCallIcon } from "../../../icon/GroupCall.Icon";
import { BookingSummaryCard } from "../../../video-sessions/BookingSummary.Card";
import { ContentItemDataGroupCall } from "../../Content.model";
import { ContentItemViewProps } from "../ContentItem.model";

export function ContentItemViewGroupCall(props: ContentItemViewProps) {
  let data: ContentItemDataGroupCall = props.data;

  let [booking, setBooking] = useState<Booking>();
  let [loading, setLoading] = useState<boolean>();

  function fetch() {
    if (data && data.videoSessionId !== undefined) {
      setLoading(true);
      BookingApi.get(data.videoSessionId)
        .executeV2()
        .then((booking) => {
          setBooking(booking);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="ContentItemViewOfferingVideoSession">
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!loading && booking && (
        <>
          <h6>
            <GroupCallIcon /> Group Call
          </h6>
          <div className="card card-body bg-light">
            <BookingSummaryCard
              booking={booking}
              notifyParticipants={true}
              onSessionUpdate={(booking) => {
                setBooking(booking);
                return Promise.resolve();
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
