import React from "react";
import { HTMLProps } from "../../model/HTMLProps";
import { Currency, Price } from "../../model/Price";

interface PriceViewProps extends HTMLProps {
  price: Price;
  showFree?: boolean;
}

export function PriceView(props: PriceViewProps) {
  return (
    <span className={props.className} data-tag="allowRowEvents">
      {props.price.amount === 0 && props.showFree ? (
        <>Free</>
      ) : (
        <>
          <CurrencyView currency={props.price.currency} />

          {(props.price.amount / 100).toLocaleString()}
        </>
      )}
    </span>
  );
}

interface CurrencyViewProps {
  currency: Currency;
}

export function CurrencyView(props: CurrencyViewProps) {
  return (
    <>
      {props.currency.id === "USD" && "$"}
      {props.currency.id === "INR" && "₹"}
    </>
  );
}
