import React from "react";
import { PublicPageRoute } from "../routes/PublicPage.route";
import { CompanyConstants } from "./Company.Constants";
import { CompanyViewPage } from "./Company.View.Page";
import { CompanyEditPage } from "./Company.Edit.Page";

export function CompanyRoutes() {
  return (
    <>
      <PublicPageRoute
        path={CompanyConstants.PAGE_COMPANY_EDIT}
        contentComponent={CompanyEditPage}
      />

      <PublicPageRoute
        path={CompanyConstants.PAGE_COMPANY_VIEW}
        contentComponent={CompanyViewPage}
      />
    </>
  );
}
