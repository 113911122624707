import { useField } from "formik";
import React from "react";
import { User, UserBasicDetails } from "../../../user/User";
import { UserLink } from "../../../user/User.link";
import { UserPickerViewDefaultDesign } from "../UserPickerViewDefaultDesign";
import { FormikInputParams } from "./FormikInputParams.model";

interface UserInputProps extends FormikInputParams {
  user: User;
  selectedUsers: UserBasicDetails[];
}
export function UserInput(props: UserInputProps) {
  let [field] = useField(props);

  let selectedUser: UserBasicDetails = field.value;
  return (
    <div className="UserInput hstack gap-2">
      {selectedUser && selectedUser.id && (
        <>
          <UserLink user={selectedUser} />
        </>
      )}
      <UserPickerViewDefaultDesign
        uniqueKey={props.name.replaceAll(".", "-")}
        initiatorButtonText={{
          normal: `${
            selectedUser && selectedUser.id ? "Edit User" : "Pick User"
          }`,
        }}
        callback={function (user: User): Promise<void> {
          field.onChange({
            target: {
              name: props.name,
              value: user.basicDetails,
            },
          });

          return Promise.resolve();
        }}
        user={props.user}
      />
    </div>
  );
}
