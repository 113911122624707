import React, { useState } from "react";
import { PageProps } from "../../model/views/PageProps";

export interface Tab {
  title: string;
  content: React.ReactNode;
  uniqueKey: string;
  isActive?: boolean;
}

interface TabViewProps extends PageProps {
  tabs: Tab[];
  selectedTab?: Tab;
}

export function TabView(props: TabViewProps) {
  let [currentTab, setCurrentTab] = useState<Tab | undefined>(
    props.selectedTab
  );

  React.useEffect(() => {
    setCurrentTab(props.selectedTab);
  }, [props.selectedTab]);

  return (
    <div className="TabView">
      <div className="hstack gap-2 overflow-auto">
        {props.tabs.map((tab) => (
          <div className={""} role="presentation" key={tab.uniqueKey}>
            <button
              type="button"
              className={
                "btn btn-light text-nowrap " +
                (currentTab && tab.uniqueKey == currentTab.uniqueKey
                  ? "active"
                  : "")
              }
              id={tab.uniqueKey + "-tab"}
              onClick={() => {
                setCurrentTab(tab);
              }}
            >
              {tab.title}
            </button>
          </div>
        ))}
      </div>
      <div className="mt-3">
        {currentTab && (
          <>
            <div id={currentTab.uniqueKey} key={currentTab.uniqueKey}>
              {currentTab.content}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
