import React, { useEffect, useState } from "react";
import { Question } from "../../../question/Question.Model";
import { QuestionAccordionView } from "../../../question/Question.view";
import { QuestionsAPI } from "../../../question/QuestionsAPI";
import { ContentItemDataQuestion } from "../../Content.model";
import { ContentItemViewProps } from "../ContentItem.model";

export function ContentItemViewQuestion(props: ContentItemViewProps) {
  let data: ContentItemDataQuestion = props.data as ContentItemDataQuestion;
  let [question, setQuestion] = useState<Question>();

  function fetchQuestion() {
    if (data && data.questionId !== undefined && data.questionId !== null) {
      QuestionsAPI.fetchQuestionDetails(data.questionId).then(
        (questionResponse) => {
          setQuestion(questionResponse);
        }
      );
    }
  }

  useEffect(() => {
    fetchQuestion();
  }, []);

  return (
    <div className="ContentItemViewQuestion">
      {question && (
        <QuestionAccordionView
          question={question}
          onSubmitAnswer={fetchQuestion}
          user={props.user}
        />
      )}
    </div>
  );
}
