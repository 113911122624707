import React from "react";
import { EBTextAreaV2 } from "../components/common/Form/FormElements";
import { FormikHiddenInput } from "../components/common/input/FormikHiddentInput";

export function VideoSessionCancelRequestInput() {
  return (
    <div className="VideoSessionCancelRequestInput row">
      <FormikHiddenInput name="cancellationCode" value="OTHER" />
      {/* <div className="col">
        <FormInputLabel label="Cancellation Code" required />
        <EBTextInputV2
          name="cancellationCode"
          required
          placeholder="Enter Cancellation Code. Put 'OTHER' without quotes if not known."
          helpText=""
        />
      </div> */}

      <div className="col">
        <EBTextAreaV2
          label="Cancellation Reason"
          name="cancellationReason"
          required
          placeholder="Enter reason of cancellation"
          helpText=""
        />
      </div>
    </div>
  );
}
