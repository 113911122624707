import React from "react";
import { UrlHelpers } from "../../../helpers/Helpers";
import { KeyHelper } from "../../../helpers/Key.Helper";
import { WindowHelpers } from "../../../helpers/Window.helper";
import { ButtonText } from "../../../model/views/Button.props";
import { AskQuestionForm } from "../../../question/AskQuestionForm";
import { UserBasicDetails } from "../../../user/User";
import { ApiLinkedButtonV3 } from "../../common/Form/ApiLinked.button.V3";
import { CharchaaAPI } from "./Charchaa.api";
import { Post } from "./Charchaa.model";

interface AskQuestionModalProps {
  user?: UserBasicDetails;
  otherlayout?: React.ReactNode;
  buttonText: ButtonText;
}
export function AskQuestionModal(props: AskQuestionModalProps) {
  let callBack = (reponse: Post): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const redirectUrl = UrlHelpers.getPostDetailsUrl(
        reponse.url,
        "Question asked successfully"
      );
      WindowHelpers.redirect(redirectUrl);
      resolve();
    });
  };
  return (
    <ApiLinkedButtonV3
      uniqueKey={KeyHelper.getUniqueKey()}
      otherlayout={props.otherlayout}
      buttonProps={{
        content: props.buttonText,
      }}
      api={CharchaaAPI.add()}
      successCallback={callBack}
      apiFormBody={<AskQuestionForm />}
      formModalSize="md"
    />
  );
}
