import { message } from "antd";
import { Form, Formik } from "formik";
import React from "react";
import { ApiErrorHelper } from "../../../helpers/ApiError.helper";
import { EBButtonV2, EBButtonV2Props } from "../EBButtonV2";

interface EBFormProps {
  submitButton: EBButtonV2Props;
  formBody?: React.ReactNode;
  initialValues?: any;
  onSubmit?: (values: any) => Promise<void>;
  className?: string;
  onChange?: () => Promise<void>;
}

export function EBForm(mainProps: EBFormProps) {
  return (
    <>
      <Formik
        initialValues={mainProps.initialValues ?? {}}
        onSubmit={function (values, actions) {
          actions.setSubmitting(true);
          if (mainProps.onSubmit) {
            mainProps
              .onSubmit(values)
              .catch((error) => {
                let displayError = ApiErrorHelper.getDisplayErrorMessage(error);
                message.error(displayError);
                return error;
              })
              .finally(function () {
                actions.setSubmitting(false);
              });
          }
        }}
      >
        {(formProps) => (
          <Form onChange={mainProps.onChange}>
            {mainProps.formBody}
            <div className={`${mainProps.formBody && "mt-3"}`}>
              <EBButtonV2
                {...mainProps.submitButton}
                disabled={
                  formProps.isSubmitting || mainProps.submitButton.disabled
                }
                loading={
                  formProps.isSubmitting || mainProps.submitButton.loading
                }
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
