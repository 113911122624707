import { Api, ApiMethod } from "../../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../../constants";
import { PaymentReleaseRequestsResponse } from "./PaymentReleaseRequest.model";

export class PaymentReleaseRequestApi {
  public static getUserPRRs(
    userId: string
  ): Promise<PaymentReleaseRequestsResponse> {
    let api = apiLinkConstants.API_USER_PAYMENT_RELEASE_REQUESTS.replaceAll(
      "{userId}",
      userId
    );
    return new Api<void, PaymentReleaseRequestsResponse>(
      api,
      ApiMethod.GET
    ).execute();
  }
}
