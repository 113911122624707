import { Tooltip } from "antd";
import React from "react";
import { TimestampView } from "../components/common/TimestampView";
import { CheckIcon } from "../icon/Check.Icon";
import { UncheckIcon } from "../icon/Uncheck.Icon";
import { HTMLProps } from "../model/HTMLProps";
import { OfferingCommonDetails } from "./Offering";

interface OfferingPublishStatusBadgeProps extends HTMLProps {
  offering: OfferingCommonDetails;
}
export function OfferingPublishStatusBadge(
  props: OfferingPublishStatusBadgeProps
) {
  return (
    <span className={props.className}>
      {!props.offering.publishedAt && (
        <span className="badge bg-secondary">
          <UncheckIcon /> Draft
        </span>
      )}
      {props.offering.publishedAt && (
        <Tooltip
          title={
            <>
              Published at{" "}
              <TimestampView timestamp={props.offering.publishedAt} />
            </>
          }
        >
          <span className="badge bg-success">
            <CheckIcon /> Published
          </span>
        </Tooltip>
      )}
    </span>
  );
}
