import React from "react";
import { Label } from "../../components/common/Form/elements/FormInputLabel";
import { Mode } from "../../components/common/Form/model/mode.model";
import { AttachmentInput } from "../../components/common/upload/AttachmentInput";
import { ModeHelpers } from "../../helpers/Mode.helper";
import { FormSectionLineItemTemplateDataFileUpload } from "./Form.Section.LineItem.Model";
import { FormLineItemComponentProps } from "./FormLineItemComponent";

export function FormSectionLineItemInputFileUploadFormikInput(
  props: FormLineItemComponentProps
) {
  let templateData =
    props.templateData as FormSectionLineItemTemplateDataFileUpload;

  return (
    <>
      <Label
        label={ModeHelpers.getString(props.mode, templateData.displayTitle)}
        required={props.required}
      />
      <AttachmentInput
        name={props.name + ".value"}
        user={props.user}
        required={props.required}
        mode={Mode.EDIT}
      />
    </>
  );
}
