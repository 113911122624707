import { Field } from 'formik';
import React from 'react';
import { SelectItem } from '../../../model/views/Select.props';
import { EBSelect } from '../eb-ui-components';
import { FormikInputParams } from './FormikInputParams.model';

export interface FormikRatingNumberSelectProps extends FormikInputParams {
    minRating: number;
    maxRating: number;
    label?: string
    isMulti?: boolean
}
export function FormikRatingNumberSelect(props: FormikRatingNumberSelectProps) {
    function mapToInput(selectItem: SelectItem): number {
        return parseInt(selectItem.value);
    }
    
    function mapToSelectItem(input: number): SelectItem | undefined {
        if (!input) {
            return undefined;
        }
        return {value: input.toString(), label: input.toString()};
    }

    let selectOptions: (SelectItem | undefined)[] = [];
    for (let i = props.minRating; i <= props.maxRating; i++) {
        selectOptions.push({label: i.toString(), value: i.toString()});
    }

    return (
        <Field
        label={props.label}
        name={props.name}
        component={EBSelect}
        options={selectOptions}
        isMulti={false}
        mappingRequired={true}
        selectToApiMapper={mapToInput}
        apiToSelectMapper={mapToSelectItem}
        required={props.required}
      />
    )
}
