import React from "react";
import { IActor } from "../pages/charchaa/Charchaa.model";

interface ActorLinkProps {
  actor: IActor;
  actorImageWidth?: number;
}

export function ActorLink(props: ActorLinkProps) {
  return (
    <a href={props.actor.profileUrl} target="_blank" className="text-dark">
      {props.actor.dp && (
        <>
          <img
            width={props.actorImageWidth ?? 35}
            className="mr-1 rounded-circle"
            src={props.actor.dp.data}
          />
        </>
      )}
      {props.actor.dl}
    </a>
  );
}
