import React from "react";
import { PageProps } from "../model/views/PageProps";
import { UserProfileEdit } from "./UserProfile.Edit";

interface UserEditProfileContentProps extends PageProps {}

export function UserEditProfileContent(props: UserEditProfileContentProps) {
  return (
    <>
      <div className="main-container">
        <UserProfileEdit {...props} showBecomeExpert={true} />
      </div>
    </>
  );
}
