import { publicLinkConstants } from "../constants";
import { User } from "../user/User";
import { UserAPI } from "../user/UserAPI";

export class LoginAPI {
  public static loginIfNeeded() {
    return new Promise<User>((resolve) => {
      UserAPI.fetchLoggedInUser()
        .then((user) => {
          resolve(user);
        })
        .catch((error) => {
          this.redirectForLogin();
        });
    });
  }

  public static loginUrl(): string {
    return publicLinkConstants.LOGIN + "?redirect_url=" + window.location.href;
  }

  public static redirectForLogin() {
    window.location.href = this.loginUrl();
  }
}
