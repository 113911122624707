import React from "react";
import { Ledger } from "./Ledger.model";
import { LedgerEntryListComponent } from "./LedgerEntry.list.component";
import { LedgerSummaryListComponent } from "./LedgerSumary.component";

interface LedgerComponentProps {
  ledger: Ledger;
}

export function LedgerComponent(props: LedgerComponentProps) {
  return (
    <div className="LedgerComponent">
      <LedgerSummaryListComponent summaries={props.ledger.summaries} />
      <div className="mt-4">
        {props.ledger.entries.length > 0 ? (
          <LedgerEntryListComponent entries={props.ledger.entries} />
        ) : (
          <>
            No transactions found. Please share your page to get more bookings.
          </>
        )}
      </div>
    </div>
  );
}
