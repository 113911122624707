import axios from "axios";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import {
  InterviewExperience,
  InterviewExperienceResponse,
} from "../model/InterviewExperience";
import { SearchRequest } from "../model/search/UserSearchRequestV1";
import { PageResponse } from "../paging/Paging.Model";

export class InterviewExperienceAPI {
  public static async fetchInterviewExperienceDetails(
    experienceId: number
  ): Promise<InterviewExperienceResponse> {
    return new Promise<InterviewExperienceResponse>((resolve, reject) => {
      axios
        .get("/api/interview/experience/" + experienceId)
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async addInterviewExperience(
    interviewExperience: InterviewExperience
  ): Promise<InterviewExperienceResponse> {
    return new Promise<InterviewExperienceResponse>((resolve, reject) => {
      axios
        .post("/api/interview/experience", interviewExperience)
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async updateInterviewExperience(
    experienceId: number,
    interviewExperience: InterviewExperience
  ): Promise<InterviewExperienceResponse> {
    return new Promise<InterviewExperienceResponse>((resolve, reject) => {
      axios
        .put("/api/interview/experience/" + experienceId, interviewExperience)
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static search(): Api<
    SearchRequest,
    PageResponse<InterviewExperienceResponse>
  > {
    return new Api<SearchRequest, PageResponse<InterviewExperienceResponse>>(
      "/api/interview/experiences/search",
      ApiMethod.POST
    );
  }
}
