import { Target } from "../model/target.model";
import { ViewsAPI } from "./Views.api";

export class ViewsManager {
  private static pendingViews: Target[] = [];
  private static scheduled = false;

  public static log(target: Target): void {
    this.pendingViews.push(target);
    this.scheduleIfNeeded();
  }

  private static scheduleIfNeeded() {
    if (this.scheduled) {
      return;
    }

    setInterval(() => {
      if (this.pendingViews.length > 0) {
        ViewsAPI.batchLog().executeV2({ targets: this.pendingViews });
        this.pendingViews = [];
      }
    }, 1000);
    this.scheduled = true;
  }
}
