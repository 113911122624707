import React, { useEffect, useState } from "react";
import { ImageComponent } from "../components/common/Image.component";
import { Loader } from "../components/common/Loader.component";
import { RadioButtonGroupComponent } from "../components/common/input/RadioButtonGroup.component";
import { Center } from "../components/common/layout/Center.component";
import { publicLinkConstants } from "../constants";
import { ButtonDisplayProps } from "../model/views/Button.props";
import { IOfferingV2, Offerings } from "../offerings/Offering";
import { OfferingHelper } from "../offerings/Offering.Helper";
import { OfferingAPI } from "../offerings/Offering.api";
import { UserBasicDetails } from "../user/User";
import { ShareOfferingComponent } from "./ShareOffering.component";

interface ShareOfferingsComponentProps {
  user: UserBasicDetails;
}

export function ShareOfferingsComponent(props: ShareOfferingsComponentProps) {
  let [offerings, setOfferings] = useState<Offerings | undefined>();
  let [selectedOffering, setSelectedOffering] = useState<
    IOfferingV2 | undefined
  >();
  let [loading, setLoading] = useState<boolean | undefined>();
  useEffect(() => {
    setLoading(true);
    OfferingAPI.getOfferings(props.user.id, { hidden: true })
      .then((offerings) => {
        setOfferings(offerings);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function getButtons(offeringList?: IOfferingV2[]): ButtonDisplayProps[] {
    if (!offeringList || offeringList.length === 0) {
      return [];
    }

    return offeringList.map((offering) => {
      return {
        text: {
          normal: (
            <>
              <ImageComponent
                image={offering.commonDetails.type.dp}
                maxWidth={10}
              />{" "}
              {offering.commonDetails.dl}
            </>
          ),
          submitting: "",
        },
        type: "secondary",
      };
    });
  }

  let mergedOfferings: IOfferingV2[] = OfferingHelper.getMergedList(offerings);
  let buttons = getButtons(mergedOfferings);

  return (
    <div className="ExpertStudioShareTestimonialComponent">
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!loading && mergedOfferings && mergedOfferings.length > 0 && (
        <>
          Select an offering from below for which you want to create the post:
          <div className="flex-nowrap overflow-auto bg-light">
            <RadioButtonGroupComponent
              buttonProps={buttons}
              onButtonClick={function (index: number): void {
                if (mergedOfferings) {
                  setSelectedOffering(mergedOfferings[index]);
                }
              }}
            />
          </div>
        </>
      )}
      {!loading && (!mergedOfferings || mergedOfferings.length == 0) && (
        <>
          You have not added any video offerings yet. Please add them{" "}
          <a href={publicLinkConstants.EXPERT_STUDIO_EDIT_VIDEO_SESSIONS}>
            here
          </a>
          .
        </>
      )}
      {selectedOffering && (
        <div className="mt-2">
          <ShareOfferingComponent offering={selectedOffering} />
        </div>
      )}
    </div>
  );
}
