import { FieldArray, Form, Formik, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { AddTitle } from "../components/common/Add.title";
import { EBButton } from "../components/common/EBButton";
import {
  EBTextInput,
  FormInputLabel,
} from "../components/common/eb-ui-components";
import { DurationTimestampInput } from "../components/common/input/DurationTimestamp.input";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { FormikNumberOptionsInput } from "../components/common/input/FormikNumberOptions.Input";
import { FormikSwitchInput } from "../components/common/input/FormikSwitch.input";
import { TimeSlotInput } from "../components/common/input/TimeSlot.input";
import { publicLinkConstants } from "../constants";
import { EditTimeDurationFormikInput } from "../duration/EditTimeDuration.FormikInput";
import { TimeDurationOptionGranularity } from "../duration/TimeDuration.FormikInput";
import { ApiErrorHelper } from "../helpers/ApiError.helper";
import { UserBasicDetails } from "../user/User";
import { CalendarApi } from "./Calendar.api";
import { CalendarHelper } from "./Calendar.helper";
import {
  CalendarSchedule,
  CalendarSettings,
  DayAvailability,
  SchedulingPreference,
  UnavailabilitySettings,
} from "./Calendar.model";

interface CalendarSettingsEditComponentProps {
  user: UserBasicDetails;
  calendarSettings: CalendarSettings;
}

export function CalendarSettingsEditComponent(
  props: CalendarSettingsEditComponentProps
) {
  let alert = useAlert();
  CalendarHelper.setDefaultsIfNotSet(props.calendarSettings);

  return (
    <div>
      <Formik
        initialValues={props.calendarSettings}
        onSubmit={function (values, { setSubmitting }) {
          setSubmitting(true);
          CalendarApi.updateUserCalendarSettings(props.user.id, values)
            .then(() => {
              alert.success("Updated!");
            })
            .catch((error) => {
              let displayError = ApiErrorHelper.getDisplayErrorMessage(error);
              alert.error(displayError);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {(formProps) => (
          <Form>
            <div className="row ">
              <div className="col">
                <SchedulingPreferenceSwitch name={"schedulingPreference"} />
              </div>
              <div className="col-auto">
                <div className="float-right">
                  <EBButton
                    classes={["btn btn-primary"]}
                    text={{
                      normal: "Save",
                      submitting: "Saving ",
                    }}
                    disabled={formProps.isSubmitting}
                    loading={formProps.isSubmitting}
                    type="submit"
                  />
                </div>
              </div>
            </div>
            {formProps.values.schedulingPreference ===
              SchedulingPreference.MANUAL && (
              <div className="mt-2">
                <h5>Manual session process:</h5>
                You have decided to handle the scheduling of sessions manually
                by yourself. This works like follows:{" "}
                <ul>
                  <li>
                    Post booking of the session, a mail thread will be started
                    between you and the mentee.
                  </li>
                  <li>
                    On that mail, you can discuss various details like session
                    slot, agenda etc with the mentee
                  </li>
                  <li>
                    Post finalizing the slot, you will have to update it in
                    session page link (you will get this link in the mail and
                    its available through expert studio also.)
                  </li>
                  <li>
                    Then the usual process follow where you have to complete the
                    session by joining the meeting and filling the feedback post
                    it.
                  </li>
                </ul>
              </div>
            )}

            {formProps.values.schedulingPreference ===
              SchedulingPreference.CALENDAR && (
              <div>
                <CalendarSettingsEditSchedulingPreferenceCalendarComponent
                  user={props.user}
                  calendarSettings={formProps.values}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

interface SchedulingPreferenceSwitchProps extends FormikInputParams {}

function SchedulingPreferenceSwitch(props: SchedulingPreferenceSwitchProps) {
  const formikCtx = useFormikContext();

  function onChange(useCalendar: boolean) {
    if (useCalendar) {
      formikCtx.setFieldValue(props.name, SchedulingPreference.CALENDAR);
    } else {
      formikCtx.setFieldValue(props.name, SchedulingPreference.MANUAL);
    }
  }

  useEffect(() => {
    formikCtx.setFieldValue(
      "shedulingPreferenceSwitch",
      formikCtx.getFieldProps(props.name).value ===
        SchedulingPreference.CALENDAR
    );
  }, []);

  return (
    <FormikSwitchInput
      name={"shedulingPreferenceSwitch"}
      onChange={onChange}
      label="Do you want to use enginEBogie's calendar capability?"
    />
  );
}

export function CalendarSettingsEditSchedulingPreferenceCalendarComponent(
  props: CalendarSettingsEditComponentProps
) {
  let alert = useAlert();
  const calendarSettings = props.calendarSettings;

  return (
    <div className="CalendarSettingsEditComponent">
      {!props.user.tz && (
        <>
          To use calendar feature, please update your time zone by going to{" "}
          <a href={publicLinkConstants.USER_PROFILE_EDIT}>Edit profile</a> page.
        </>
      )}
      {props.user.tz && calendarSettings && (
        <>
          <div className="row mb-4">
            <div className="col">
              We will handle the scheduling of the session using the calendar
              settings provided by you here. We will use your current timezone
              settings as <b>{props.user.tz?.label}</b> to show below slots to
              the users. If you think this is not correct, we request you to
              please update your time zone by going to{" "}
              <a href={publicLinkConstants.USER_PROFILE_EDIT}>Edit profile</a>{" "}
              page.
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h6>Meeting Link:</h6>
              <EBTextInput
                helpText={
                  "This link will be shared with the mentees for all your video meetings."
                }
                label={""}
                name="meetingLink"
                placeholder="You can use whatever you prefer like google meets link, zoom link, etc."
                required={true}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <h6>Availabilities:</h6>
              <CalendarScheduleEditComponent
                name={"schedule"}
                schedule={calendarSettings.schedule}
              />
            </div>
            <div className="col-md-6 mob-pt-4">
              <h6>Durations during which you are unavailable:</h6>
              <UnavailabilitySettingsComponent
                unavailabilitySettings={
                  calendarSettings.unavailabilitySettings ?? {
                    unavailableDurations: [],
                  }
                }
                name={"unavailabilitySettings"}
              />
            </div>
          </div>
          <div className="mt-4">
            <h6>Rescheduling settings</h6>
            <CalendarReschedulingSettingsComponent
              name={"reschedulingSettings"}
            />
          </div>
          <div className="mt-4">
            <h6>Advanced settings</h6>
            <CalendarOtherSettingsComponent name={"otherSettings"} />
          </div>
        </>
      )}
    </div>
  );
}

interface SettingCardProps {
  children: React.ReactNode;
}

function SettingCard(props: SettingCardProps) {
  return (
    <>
      <div className="p-2 card bg-light">{props.children}</div>
    </>
  );
}

interface CalendarOtherSettingsComponentProps extends FormikInputParams {}

export function CalendarOtherSettingsComponent(
  props: CalendarOtherSettingsComponentProps
) {
  return (
    <div className="CalendarOtherSettingsComponent">
      <div className="row">
        <div className="col-md-4">
          <SettingCard>
            <FormInputLabel label="Booking Period (How far in the future can invitees book)." />
            <EditTimeDurationFormikInput
              timeDurationOptions={[
                {
                  valueInSecs: 86400 * 7,
                  displayLabel: "7 days",
                },
                {
                  valueInSecs: 86400 * 30,
                  displayLabel: "30 days",
                },
                {
                  valueInSecs: 86400 * 60,
                  displayLabel: "60 days",
                },
              ]}
              required
              name={`${props.name}.maxAllowedBookingFromCurrentSecs`}
              customInputGranularity={TimeDurationOptionGranularity.DAYS} // Support Hours also here.
              supportCustom={true}
            />
          </SettingCard>
        </div>
        <div className="col-md-4">
          <SettingCard>
            <FormInputLabel label="Minimum heads up period (How much minimum time you need in advance for a booking)." />
            <EditTimeDurationFormikInput
              timeDurationOptions={[
                {
                  valueInSecs: 7200,
                  displayLabel: "2 hours",
                },
                {
                  valueInSecs: 43200,
                  displayLabel: "12 hours",
                },
                {
                  valueInSecs: 86400,
                  displayLabel: "24 hours",
                },
                {
                  valueInSecs: 172800,
                  displayLabel: "48 hours",
                },
              ]}
              required
              name={`${props.name}.minimumHeadsUpSecs`}
              customInputGranularity={TimeDurationOptionGranularity.HOURS} // Support Hours also here.
              supportCustom={true}
            />
          </SettingCard>
        </div>
        <div className="col-md-4">
          <SettingCard>
            <FormInputLabel label="Start time increments (Frequency of available time slots for invitees)." />
            <EditTimeDurationFormikInput
              timeDurationOptions={[
                {
                  valueInSecs: 900,
                  displayLabel: "15 mins",
                },
                {
                  valueInSecs: 1800,
                  displayLabel: "30 mins",
                },
                {
                  valueInSecs: 3600,
                  displayLabel: "1 hour",
                },
              ]}
              required
              name={`${props.name}.startTimeIncrementSecs`}
              customInputGranularity={TimeDurationOptionGranularity.MINS}
              supportCustom={true}
            />
          </SettingCard>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-4">
          <SettingCard>
            <FormInputLabel label="Minimum gap between Video Meeting Booking (You can use this is to avoid back-to-back meetings)." />
            <EditTimeDurationFormikInput
              timeDurationOptions={[
                {
                  valueInSecs: 0,
                  displayLabel: "No Gap",
                },
                {
                  valueInSecs: 5,
                  displayLabel: "5 mins",
                },
                {
                  valueInSecs: 15,
                  displayLabel: "15 mins",
                },
                {
                  valueInSecs: 30,
                  displayLabel: "30 mins",
                },
              ]}
              required
              name={`${props.name}.minimumGapBetweenConsecutiveMeetingsMinutes`}
              customInputGranularity={TimeDurationOptionGranularity.MINS}
              supportCustom={false}
            />
          </SettingCard>
        </div>
        <div className="col-md-4">
          <SettingCard>
            <FormInputLabel label="Maximum number of video bookings to take for a day." />
            <FormikNumberOptionsInput
              options={[
                {
                  value: 1,
                  dl: "1",
                },
                {
                  value: 2,
                  dl: "2",
                },
                {
                  value: 5,
                  dl: "5",
                },
                {
                  value: 10,
                  dl: "10",
                },
              ]}
              required
              name={`${props.name}.maximumVideoSessionBookingsCountInADay`}
              supportCustom={true}
            />
          </SettingCard>
        </div>
      </div>
    </div>
  );
}

export function CalendarReschedulingSettingsComponent(
  props: FormikInputParams
) {
  return (
    <div className="CalendarReschedulingSettingsComponent">
      <div className="row">
        <div className="col-md-4">
          <SettingCard>
            <FormInputLabel label="Reschedule allowed until? (Till how much time before, you want to allow reschedule?)." />
            <EditTimeDurationFormikInput
              timeDurationOptions={[
                {
                  valueInSecs: 7200,
                  displayLabel: "2 hours",
                },
                {
                  valueInSecs: 43200,
                  displayLabel: "12 hours",
                },
                {
                  valueInSecs: 86400,
                  displayLabel: "24 hours",
                },
                {
                  valueInSecs: 172800,
                  displayLabel: "48 hours",
                },
              ]}
              required
              name={`${props.name}.rescheduleAllowedUntilSecs`}
              customInputGranularity={TimeDurationOptionGranularity.HOURS} // Support Hours also here.
              supportCustom={true}
            />
          </SettingCard>
        </div>
        <div className="col-md-4">
          <SettingCard>
            <FormInputLabel label="Maximum number of reschedules allowed from invitees side?" />
            <FormikNumberOptionsInput
              options={[
                {
                  value: 1,
                  dl: "1",
                },
                {
                  value: 2,
                  dl: "2",
                },
                {
                  value: 5,
                  dl: "5",
                },
                {
                  value: 10,
                  dl: "10",
                },
              ]}
              required
              name={`${props.name}.maxReschedulesAllowedByMentees`}
              supportCustom={true}
            />
          </SettingCard>
        </div>
      </div>
    </div>
  );
}

interface CalendarScheduleEditComponentProps extends FormikInputParams {
  schedule?: CalendarSchedule;
}

export function CalendarScheduleEditComponent(
  props: CalendarScheduleEditComponentProps
) {
  return (
    <>
      {props.schedule && (
        <>
          <div>
            <DayAvailabiiltyEditComponent
              label="Monday"
              name={`${props.name}.monday`}
              dayAvailabiilty={props.schedule.monday}
            />

            <DayAvailabiiltyEditComponent
              label="Tuesday"
              name={`${props.name}.tuesday`}
              dayAvailabiilty={props.schedule.tuesday}
            />

            <DayAvailabiiltyEditComponent
              label="Wednesday"
              name={`${props.name}.wednesday`}
              dayAvailabiilty={props.schedule.wednesday}
            />
            <DayAvailabiiltyEditComponent
              label="Thursday"
              name={`${props.name}.thursday`}
              dayAvailabiilty={props.schedule.thursday}
            />
            <DayAvailabiiltyEditComponent
              label="Friday"
              name={`${props.name}.friday`}
              dayAvailabiilty={props.schedule.friday}
            />
            <DayAvailabiiltyEditComponent
              label="Saturday"
              name={`${props.name}.saturday`}
              dayAvailabiilty={props.schedule.saturday}
            />
            <DayAvailabiiltyEditComponent
              label="Sunday"
              name={`${props.name}.sunday`}
              dayAvailabiilty={props.schedule.sunday}
            />
          </div>
        </>
      )}
    </>
  );
}

interface UnavailabilitySettingsComponentProps extends FormikInputParams {
  unavailabilitySettings: UnavailabilitySettings;
}

export function UnavailabilitySettingsComponent(
  props: UnavailabilitySettingsComponentProps
) {
  let name = `${props.name}.unavailableDurations`;

  return (
    <div className="UnavailabilitySettingsComponent">
      <FieldArray
        name={`${name}`}
        render={(arrayHelpers: any) => (
          <div className="row ">
            <div className="col float-right">
              <div className="col-auto p-0">
                <button
                  className="btn text-secondary "
                  type="button"
                  onClick={() => arrayHelpers.push({})}
                  disabled={props.disabled}
                >
                  <AddTitle text={"Add"} />
                </button>
              </div>

              <div className="row">
                <div className="col p-0 ml-2">
                  {(!props.unavailabilitySettings.unavailableDurations ||
                    props.unavailabilitySettings.unavailableDurations.length ==
                      0) && <div className="pt-2">No unavailabilities</div>}
                  {props.unavailabilitySettings.unavailableDurations?.map(
                    (unavailableDuration, index) => (
                      <div className="mt-2 card card-body">
                        <DurationTimestampInput
                          name={`${name}.${index}`}
                          key={index}
                          onDelete={() => {
                            arrayHelpers.remove(index);
                          }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
}

interface DayAvailabiiltyEditComponentProps extends FormikInputParams {
  dayAvailabiilty?: DayAvailability;
}

export function DayAvailabiiltyEditComponent(
  props: DayAvailabiiltyEditComponentProps
) {
  return (
    <div className="DayAvailabiiltyComponent card card-body mt-2 p-3">
      <FieldArray name={`${props.name}.timeSlots`}>
        {(arrayHelpers: any) => (
          <div className="row ">
            <div className="col-lg-3 col-sm-12">{props.label}</div>
            <div className="col-lg-9 col-sm-12 float-right">
              <div className="row">
                <div className="col p-0 ml-2">
                  {(!props.dayAvailabiilty?.timeSlots ||
                    props.dayAvailabiilty.timeSlots.length == 0) && (
                    <div className="pt-2">Unavailable</div>
                  )}
                  {props.dayAvailabiilty?.timeSlots.map((timeSlot, index) => (
                    <TimeSlotInput
                      name={`${props.name}.timeSlots.${index}`}
                      key={index}
                      onDelete={() => {
                        arrayHelpers.remove(index);
                      }}
                    />
                  ))}
                </div>
                <div className="col-auto p-0">
                  <button
                    className="btn text-secondary "
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        start: { h: 9, m: 0 },
                        end: { h: 18, m: 0 },
                      })
                    }
                    disabled={props.disabled}
                  >
                    <AddTitle text={""} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}
