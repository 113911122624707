import React from 'react';
import { InterviewRoundAPI } from '../../../api/InterviewRoundAPI';
import { InterviewRound } from '../../../model/InterviewRound';
import { InterviewRoundStatus } from '../../../model/InterviewRoundStatus.enum';
import { PageProps } from '../../../model/views/PageProps';
import { InterviewRoundList } from '../../common/ats/InterviewRoundList';

interface InterviewRoundTypeTab {
    title: string;
    rounds: InterviewRound[];
    uniqueKey: InterviewRoundStatus;
    isActive?: boolean;
}

interface CompanyAllInterviewRoundsPageProps extends PageProps {
    companyId: string;
}
interface CompanyAllInterviewRoundsPageState {
    interviewRounds: InterviewRound[];
    pendingSchedule: InterviewRound[];
    scheduled: InterviewRound[];
    pendingComplete: InterviewRound[];
    completed: InterviewRound[];
    cancelled: InterviewRound[];
    archived: InterviewRound[];
}
export class CompanyAllInterviewRoundsPage extends React.Component<CompanyAllInterviewRoundsPageProps, CompanyAllInterviewRoundsPageState> {

    componentDidMount() {
        InterviewRoundAPI.getInterviewRoundsForCompany(this.props.companyId, InterviewRoundStatus.PENDING_SCHEDULE).then((response) => {
            this.setState({pendingSchedule: response})
        });
        InterviewRoundAPI.getInterviewRoundsForCompany(this.props.companyId, InterviewRoundStatus.SCHEDULED).then((response) => {
            this.setState({scheduled: response})
        });
        InterviewRoundAPI.getInterviewRoundsForCompany(this.props.companyId, InterviewRoundStatus.PENDING_COMPLETE).then((response) => {
            this.setState({pendingComplete: response})
        });
        InterviewRoundAPI.getInterviewRoundsForCompany(this.props.companyId, InterviewRoundStatus.COMPLETED).then((response) => {
            this.setState({completed: response})
        });
        InterviewRoundAPI.getInterviewRoundsForCompany(this.props.companyId, InterviewRoundStatus.CANCELLED).then((response) => {
            this.setState({cancelled: response})
        });
        InterviewRoundAPI.getInterviewRoundsForCompany(this.props.companyId, InterviewRoundStatus.ARCHIVED).then((response) => {
            this.setState({archived: response})
        });
    }

    render() {
        let interviewRoundTabs: InterviewRoundTypeTab[] = [];
        if (this.state && this.props.user) {
            interviewRoundTabs = [
                {
                    title: "Pending Schedule",
                    rounds: this.state.pendingSchedule,
                    uniqueKey: InterviewRoundStatus.PENDING_SCHEDULE,
                    isActive: this.state.pendingSchedule && this.state.pendingSchedule.length > 0,
                },
                {
                    title: "Scheduled",
                    rounds: this.state.scheduled,
                    uniqueKey: InterviewRoundStatus.SCHEDULED,
                    isActive: (this.state.pendingSchedule && this.state.pendingSchedule.length == 0 
                        && this.state.pendingComplete && this.state.pendingComplete.length == 0),
                },
                {
                    title: "Pending Complete",
                    rounds: this.state.pendingComplete,
                    uniqueKey: InterviewRoundStatus.PENDING_COMPLETE,
                    isActive: (this.state.pendingSchedule && this.state.pendingSchedule.length == 0 
                        && this.state.pendingComplete && this.state.pendingComplete.length > 0),
                },
                {
                    title: "Completed",
                    rounds: this.state.completed,
                    uniqueKey: InterviewRoundStatus.COMPLETED,
                    isActive: (this.state.pendingSchedule && this.state.pendingSchedule.length == 0 
                        && this.state.pendingComplete && this.state.pendingComplete.length == 0
                        && this.state.scheduled && this.state.scheduled.length == 0),
                },
                {
                    title: "Cancelled",
                    rounds: this.state.cancelled,
                    uniqueKey: InterviewRoundStatus.CANCELLED
                },
                {
                    title: "Archived",
                    rounds: this.state.archived,
                    uniqueKey: InterviewRoundStatus.ARCHIVED
                },
            ]
        }
        return (
            <div className="main-container">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                {interviewRoundTabs.map((interviewRoundTab) => (
                    <li className={"nav-item"} role="presentation" key={interviewRoundTab.uniqueKey}>
                      <button className={"nav-link " + (interviewRoundTab.isActive ?  "active" : "")} id={interviewRoundTab.uniqueKey + "-tab"} data-bs-toggle="tab" 
                      data-bs-target={"#" + interviewRoundTab.uniqueKey} type="button" role="tab" aria-controls={interviewRoundTab.uniqueKey}
                       aria-selected="true">{interviewRoundTab.title} ({interviewRoundTab.rounds ? interviewRoundTab.rounds.length : "0"}) </button>
                    </li>
                ))}
                </ul>
                <div className="tab-content" id="myTabContent">
                {interviewRoundTabs.map((interviewRoundTab) => (
                    <div className={"tab-pane fade show " + (interviewRoundTab.isActive ?  "active" : "")} 
                    id={interviewRoundTab.uniqueKey} role="tabpanel" aria-labelledby={interviewRoundTab.uniqueKey + "-tab"} key={interviewRoundTab.uniqueKey}>
                        {interviewRoundTab && interviewRoundTab.rounds && interviewRoundTab.rounds.length > 0 && (
                            <InterviewRoundList {...this.props} interviewRounds={interviewRoundTab.rounds} uniqueKey={interviewRoundTab.uniqueKey + "-body"} />
                        )}
                    </div>
                ))}
                </div>
            </div>
        )
    }
}
