import type { InputNumberProps } from "antd";
import { InputNumber, Space } from "antd";
import React from "react";
import { EBPercentInput } from "./EBPercent.Input";
import { useForm } from "antd/es/form/Form";
import { useField, useFormik } from "formik";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { FormikHelpers } from "../helpers/Formik.Helpers";

export interface EBPercentFormikInputProps extends FormikInputParams {
  defaultValue?: number;
}

export function EBPercentFormikInput(props: EBPercentFormikInputProps) {
  let [field] = useField(props);

  function onChange(value: number | null) {
    FormikHelpers.updateField(field, props.name, value);
  }

  return (
    <>
      <EBPercentInput onChange={onChange} value={field.value} />
    </>
  );
}
