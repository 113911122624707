import React from "react";
import PublicPage from "../components/pages/PublicPage";
import NormalNavBar from "../components/pages/NormalNavBar";
import { navbarLinks } from "../App";
import { Route } from "react-router";
import { publicLinkConstants } from "../constants";
import { TextQueryDetailsPageContent } from "../text-queries/TextQueryDetails.PageContent";

function TextQueryDetails(props: any) {
  return (
    <PublicPage
      contentComponent={TextQueryDetailsPageContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

export function TextQueryRoutes() {
  return (
    <>
      <Route
        exact
        path={publicLinkConstants.TEXT_QUERY_DETAILS_LINK}
        component={TextQueryDetails}
      />
    </>
  );
}
