import { expertSessionConstants } from "../constants";
import { serviceOfferingHelpers } from "./serviceOfferingHelpers";

export const ratingHelpers = {
  ratings: [
    { value: 1, label: "1 Star" },
    { value: 2, label: "2 Star" },
    { value: 3, label: "3 Star" },
    { value: 4, label: "4 Star" },
    { value: 5, label: "5 Star" },
  ],
  getAllDisplayRatings: function () {
    return this.ratings;
  },
  getDisplayRatingForValue: function (value) {
    return this.getAllDisplayRatings().filter(
      (rating) => rating.value === value
    )[0];
  },
};

export const sessionHelpers = {
  getUserRole: function (session, user) {
    if (session.bookedByUserId == user.id) {
      return expertSessionConstants.EXPERT_SESSION_USER_ROLE_MENTEE;
    }
    if (session.expertUserId == user.id) {
      return expertSessionConstants.EXPERT_SESSION_USER_ROLE_EXPERT;
    }
    return session.role;
  },
  isUserExpert: function (session, user) {
    return (
      this.getUserRole(session, user) ==
      expertSessionConstants.EXPERT_SESSION_USER_ROLE_EXPERT
    );
  },
  isUserMentee: function (session, user) {
    return (
      this.getUserRole(session, user) ==
      expertSessionConstants.EXPERT_SESSION_USER_ROLE_MENTEE
    );
  },
  getDisplayDate: function (session) {
    return session.createdAt;
  },
};

export const documentHelpers = {
  addScript: function (document, scriptPath) {
    const script = document.createElement("script");
    script.src = scriptPath;
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);
  },
};
