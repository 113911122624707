import React, { useState } from "react";
import { HTMLProps } from "../model/HTMLProps";
import { OptionsSelect } from "../user/listing/OptionsSelect";
import { TargetType } from "./Target.Type.Helper";

export interface TargetInputOption {
  type: TargetType;
  suffix?: React.ReactNode;
}

interface TargetFilterOption {
  targetType: TargetType;
  dl: React.ReactNode;
}

interface TargetOptionsSelectV2Props extends HTMLProps {
  initialSelectedOptions: TargetInputOption[];
  targetOptions: TargetInputOption[];
  onSelectionUpdate: (selectedOptions: TargetType[]) => Promise<void>;
  compact?: boolean;
}

export function TargetOptionsSelectV2(props: TargetOptionsSelectV2Props) {
  let filterTypeOptions: TargetFilterOption[] = props.targetOptions.map(
    (option) => {
      return {
        targetType: option.type,
        dl: (
          <>
            {option.type.dl} {option.suffix}
          </>
        ),
      };
    }
  );

  let [filterTypes, setFilterTypes] = useState<TargetFilterOption[]>(
    props.initialSelectedOptions.map((inputOp) => {
      return filterTypeOptions.filter(
        (filterTypeOp) => filterTypeOp.targetType.type == inputOp.type.type
      )[0];
    })
  );

  return (
    <OptionsSelect<TargetFilterOption>
      options={filterTypeOptions}
      initialSelectedOptions={filterTypes}
      onSelectionUpdate={function (
        selectedOptions: TargetFilterOption[]
      ): Promise<void> {
        setFilterTypes(selectedOptions);
        return props.onSelectionUpdate(
          selectedOptions.map((option) => option.targetType)
        );
      }}
      isEqual={function (
        a: TargetFilterOption,
        b: TargetFilterOption
      ): boolean {
        return a.targetType.type == b.targetType.type;
      }}
      render={function (option: TargetFilterOption): React.ReactNode {
        return option.dl;
      }}
      className={props.className}
      compact={props.compact}
    />
  );
}
