import React from "react";
import Moment from "react-moment";
import { BookingCommonComponent } from "../booking/Booking.Common.Component";
import { BookingComponentWrapper } from "../booking/Booking.ComponentWrapper";
import { Booking } from "../booking/Booking.model";
import { ReactMatch } from "../model/ReactMatchObj";
import { PageProps } from "../model/views/PageProps";
import { StatusComponent } from "../status/Status.Component";
import { User } from "../user/User";
import { UserLink } from "../user/User.link";
import { TextQuery } from "./TextQuery.model";

interface TextQueryDetailsProps {
  booking: Booking;

  user: User;
}

export function TextQueryDetails(props: TextQueryDetailsProps) {
  let booking = props.booking;
  const bcd = props.booking.bcd;
  let tq = booking.specificBookingTypeModel as TextQuery;

  return (
    <>
      <div className="text-gray-800">
        <div className="row">
          <div className="col-xl-4 col-md-6 mb-4">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center mb-3">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Title
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {bcd.dl}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-clipboard-list fa-2x text-secondary"></i>
                  </div>
                </div>

                <div className="row no-gutters align-items-center mt-3">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Response SLA
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {tq.displayResponseSla}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-hourglass-start fa-2x text-secondary"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-md-6 mb-4">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center mb-3">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Expert
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <UserLink user={bcd.engine} />
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-user fa-2x text-secondary"></i>
                  </div>
                </div>

                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Mentee
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <UserLink user={bcd.bogie} />
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-user fa-2x text-secondary"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-md-6 mb-4">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Status
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {booking.statusDl && (
                        <StatusComponent status={booking.statusDl} />
                      )}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-tasks fa-2x text-secondary"></i>
                  </div>
                </div>

                {!bcd.cancelledAt ? (
                  <div className="row no-gutters align-items-center mt-3">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Completed at
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {bcd.engineCompletedAt ? (
                          <Moment format="DD-MM-YYYY HH:mm">
                            {bcd.engineCompletedAt}
                          </Moment>
                        ) : (
                          "Not completed."
                        )}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-clock fa-2x text-secondary"></i>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {!bcd.cancelledAt ? (
          <>
            {tq.textQuestionTitleForMentee && (
              <div className="card">
                <div className="card-header">Details from mentee</div>
                <div className="card-body">
                  <div className="bold">{tq.textQuestionTitleForMentee}</div>
                  <div>{tq.detailsFromMentee}</div>
                </div>
              </div>
            )}

            <BookingCommonComponent booking={booking} user={props.user} />
          </>
        ) : null}
      </div>
    </>
  );
}

interface TextQueryDetailsPageContentProps extends PageProps {
  match: ReactMatch<TextQueryDetailsPageContentPathParams>;
}
interface TextQueryDetailsPageContentPathParams {
  textQueryId: string;
}

export function TextQueryDetailsPageContent(
  props: TextQueryDetailsPageContentProps
) {
  return (
    <div className="main-container TextQueryDetailsPageContent">
      <BookingComponentWrapper
        bookingId={props.match.params.textQueryId}
        user={props.user}
      />
    </div>
  );
}
