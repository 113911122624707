import React from "react";
import { HTMLProps } from "../model/HTMLProps";

interface BottomFixedComponentProps extends HTMLProps {
  children: React.ReactNode;
}

export function BottomFixedComponent(props: BottomFixedComponentProps) {
  return (
    <div
      className={`position-fixed bottom-0 left-0 right-0 z-100 ${props.className}`}
    >
      {props.children}
    </div>
  );
}
