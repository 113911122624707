import { message } from "antd";
import { Form, Formik } from "formik";
import React from "react";
import { LoginAPI } from "../api/LoginAPI";
import { Api } from "../components/common/ApiLinkedButton";
import { EBButton } from "../components/common/EBButton";
import { EBTextInput } from "../components/common/eb-ui-components";
import { ApiErrorHelper } from "../helpers/ApiError.helper";
import { PaymentHelper } from "../helpers/Payment.helper";
import { WindowHelpers } from "../helpers/Window.helper";
import { OrderResponseV1 } from "../model/OrderDetails";
import { OfferingRegisterSectionLayoutModelProps } from "./layouts/Offering.RegisterSection.Layout.Model";

interface OfferingBookNowButtonProps {
  text?: React.ReactNode;
  inProgressText?: string;
  priceVariationFormElement?: React.ReactNode;
  preBookingFormElement?: React.ReactNode;
  orderApi: Api<any, OrderResponseV1>;
  showRegisterButton?: boolean;
  onCouponApply?: (bookingData: any) => Promise<void>;

  Layout: React.ComponentType<OfferingRegisterSectionLayoutModelProps>;
}

export function OfferingBookNowButton(props: OfferingBookNowButtonProps) {
  let text = props.text ? props.text : "Register";
  let inProgressText = props.inProgressText
    ? props.inProgressText
    : "Processing. Please wait...";

  return (
    <div className="OfferingBookNowButton" id="OfferingBookNowButton">
      <Formik
        initialValues={{ couponCode: "" }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);

          LoginAPI.loginIfNeeded()
            .then((user) => {
              return props.orderApi.execute(values).then((order) => {
                return PaymentHelper.processOrderPayment(order.id, user);
              });
            })
            .then((orderPaymentSuccess) => {
              if (orderPaymentSuccess.redirectUrl) {
                WindowHelpers.redirect(orderPaymentSuccess.redirectUrl);
              } else {
                alert(
                  "Your order has been confirmed. Please check your email for further details"
                );
              }
            })
            .catch((error) => {
              let errMsg = ApiErrorHelper.getDisplayErrorMessage(error);
              message.error(errMsg);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {(formProps) => (
          <Form>
            {props.Layout && (
              <props.Layout
                priceVariationFormElement={props.priceVariationFormElement}
                preBookingFormElement={props.preBookingFormElement}
                registerButton={
                  props.showRegisterButton && (
                    <>
                      <EBButton
                        classes={["btn btn-primary"]}
                        text={{ normal: text, submitting: inProgressText }}
                        disabled={formProps.isSubmitting}
                        loading={formProps.isSubmitting}
                        type="submit"
                      />
                      {props.onCouponApply && (
                        <>
                          <div className="hstack gap-2 mt-2">
                            <EBTextInput
                              helpText={""}
                              label={""}
                              name="couponCode"
                              placeholder="Have a coupon?"
                            />
                            <div className="align-middle form-group">
                              <button
                                className="btn btn-secondary"
                                type="button"
                                onClick={() => {
                                  props.onCouponApply!(formProps.values);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )
                }
              ></props.Layout>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
