import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { ShareConstants } from "./Share.Constants";
import { ShareableContent } from "./Share.model";

export class ShareApi {
  public static createReviewShareableContent(
    reviewId: string
  ): Promise<ShareableContent> {
    return new Api<void, ShareableContent>(
      apiLinkConstants.API_REVIEW_SHAREABLE_CONTENT.replace(
        "{reviewId}",
        reviewId
      ),
      ApiMethod.POST
    ).execute();
  }

  public static generateYirPdf(
    userId: string,
    year: number
  ): Api<void, string> {
    let api =
      ShareConstants.API_YEAR_REVIEW_SHAREABLE_URL.replaceAll(
        "{userId}",
        userId
      ) +
      "?year=" +
      year;

    return new Api<void, string>(api, ApiMethod.GET);
  }
}
