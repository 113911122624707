import React from "react";
import { User, UserBasicDetails } from "../user/User";
import { EventTypeInput } from "./EventBasicInformation.input";

export interface CreateEventComponentProps {
  user: User;
}

export function CreateEventComponent(props: CreateEventComponentProps) {
  return (
    <div className="CreateEventComponent">
      {<EventTypeInput user={props.user} />}
    </div>
  );
}
