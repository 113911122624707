import React from "react";
import { PaymentDetailsManual } from "../../model/OrderDetails";
import Moment from "react-moment";
import { PriceHelpers } from "../../helpers/Helpers";

interface PaymentManualComponentProps {
  paymentDetails: PaymentDetailsManual;
}
export class PaymentManualComponent extends React.Component<PaymentManualComponentProps> {
  render() {
    return (
      <>
        <div className="">
          <div className="card-body p-0">
            {this.props.paymentDetails.paymentMethod && (
              <div className="col no-gutters align-items-center">
                <span className="font-weight-bold">Payment Method : </span>
                <span className="text-xs mb-1">
                  {this.props.paymentDetails.paymentMethod}
                </span>
              </div>
            )}
            {this.props.paymentDetails.paymentDate && (
              <div className="col no-gutters align-items-center">
                <span className="font-weight-bold">Payment Date: </span>
                <span className=" text-xs mb-1">
                  <Moment format="DD-MM-YYYY">
                    {this.props.paymentDetails.paymentDate}
                  </Moment>
                </span>
              </div>
            )}
            {this.props.paymentDetails.paymentMethod && (
              <div className="col no-gutters align-items-center">
                <span className="font-weight-bold">Payment Status: </span>
                {this.props.paymentDetails.paymentStatus}
              </div>
            )}
            <div className="col no-gutters align-items-center">
              <span className="font-weight-bold">Payment Verified: </span>

              {this.props.paymentDetails.verified && (
                <span className="text-xs mb-1"> Yes </span>
              )}
              {!this.props.paymentDetails.verified && (
                <span className="text-xs mb-1"> No </span>
              )}
            </div>

            {this.props.paymentDetails.paymentPrice && (
              <div className="col no-gutters align-items-center">
                <span className="font-weight-bold">Payment Amount: </span>

                <span className="text-xs mb-1">
                  {PriceHelpers.getDisplayPriceV2(
                    this.props.paymentDetails.paymentPrice
                  )}
                </span>
              </div>
            )}

            {this.props.paymentDetails.paymentDestinationType && (
              <div className="col no-gutters align-items-center">
                <span className="font-weight-bold">Payment Recived Type: </span>

                <span className="text-xs mb-1">
                  {this.props.paymentDetails.paymentDestinationType}
                </span>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
