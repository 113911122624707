import React from "react";
import { GroupLink } from "../group/Group.Link";
import { HTMLProps } from "../model/HTMLProps";
import { UserLink } from "../user/User.link";
import { AttendeeFormat } from "../video-sessions/Attendee.model";
import { Booking } from "./Booking.model";

interface BookingAttendeeLinkProps extends HTMLProps {
  booking: Booking;
}
export function BookingAttendeeLink(props: BookingAttendeeLinkProps) {
  let booking = props.booking;
  return (
    <span className={`BookingAttendeeLink ${props.className}`}>
      {booking.bcd.attendeeFormat == AttendeeFormat.GROUP ? (
        <GroupLink bookingTarget={booking.bookingTarget} />
      ) : (
        <> {booking.bcd.bogie && <UserLink user={booking.bcd.bogie} />}</>
      )}
    </span>
  );
}
