import { Col, Pagination, Row } from "antd";
import { ColSize } from "antd/es/col";
import { Gutter } from "antd/es/grid/row";
import React, { useEffect, useState } from "react";
import { Center } from "../components/common/layout/Center.component";
import { LinkClickHandler } from "../links/Link.ClickHandler";
import { Loader } from "../loader/Loader";
import { HTMLProps } from "../model/HTMLProps";
import { SearchToken } from "../model/search/SearchToken";
import { PageParams, SearchRequest } from "../model/search/UserSearchRequestV1";
import { PageResponse } from "../paging/Paging.Model";
import { SearchProps } from "../search/Search.Model";
import { UserBasicDetails } from "../user/User";
import { ListItemLayoutProps } from "./ListItem.Layout";

type ColSpanType = number | string;

export interface HorizontalSpan {
  span?: ColSpanType;
  order?: ColSpanType;
  offset?: ColSpanType;
  push?: ColSpanType;
  pull?: ColSpanType;
  xs?: ColSpanType | ColSize;
  sm?: ColSpanType | ColSize;
  md?: ColSpanType | ColSize;
  lg?: ColSpanType | ColSize;
  xl?: ColSpanType | ColSize;
  xxl?: ColSpanType | ColSize;
}

export interface ListItemProps extends HTMLProps {
  item: any;

  index: number;
  length: number;
  selectedIndex?: number;
}

interface ListV2Props extends HTMLProps {
  onFetch: (searchRequest?: SearchRequest) => Promise<PageResponse<any>>;

  SearchComponent?: React.ComponentType<SearchProps>;
  ItemLayoutComponent: React.ComponentType<ListItemLayoutProps>;
  emptyListContent?: React.ReactNode;
  customListObject?: any;
  onClickItem?: (item: any, index: number) => Promise<void>;
  selectedIndex?: number;

  itemNode?: (itemProps: ListItemProps) => React.ReactNode;

  /**
   * @deprecated Use itemNode instead
   */
  ItemComponent?: React.ComponentType<ListItemProps>;
  hidePaging?: boolean;
  hideLoading?: boolean;

  horizontalLayout?: boolean;
  horizontalSpan?: HorizontalSpan;
  header?: React.ReactNode;
  contentClassName?: string;
  gutter?: Gutter | [Gutter, Gutter];
  paginationPosition?: "top" | "bottom";
  user?: UserBasicDetails;
}

export function ListV2(props: ListV2Props) {
  let [searchTokens, setSearchTokens] = useState<SearchToken[]>();
  let [pageParams, setPageParams] = useState<PageParams>({ page: 0, size: 10 });

  let [items, setItems] = useState<PageResponse<any>>();
  let [loading, setLoading] = useState<boolean>();
  let [selectedIndex, setSelectedIndex] = useState(props.selectedIndex);

  let [filterShow, setFilterShow] = useState<boolean>(false);

  function fetch(
    searchTokens?: SearchToken[],
    pageParams?: PageParams
  ): Promise<void> {
    let searchRequest: SearchRequest = {
      searchTokens: searchTokens,
      pageParams: pageParams,
    };

    setLoading(true);
    return props
      .onFetch(searchRequest)
      .then((items) => {
        setItems(items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function onPageChange(page: number, pageSize: number) {
    let newPageParams: PageParams = { page: page - 1, size: pageSize };
    setPageParams(newPageParams);
    fetch(searchTokens, newPageParams);
  }

  function onSearchTokensChange(searchTokens: SearchToken[]): Promise<void> {
    setSearchTokens(searchTokens);
    let newPageParams: PageParams = { page: 0, size: pageParams.size };
    setPageParams(newPageParams);

    return fetch(searchTokens, newPageParams);
  }

  useEffect(() => {
    fetch(searchTokens, pageParams);
  }, []);

  function handleOnClickItem(item: any, index: number) {
    if (props.onClickItem) {
      props.onClickItem(item, index);
    }
    setSelectedIndex(index);
  }

  let pagePos = props.paginationPosition ?? "top";

  return (
    <div className="ListV2">
      {props.SearchComponent && (
        <>
          <Row>
            <Col>
              <LinkClickHandler
                className="text-dark"
                onClick={() => {
                  setFilterShow(!filterShow);
                }}
              >
                <i className="fa-solid fa-filter mr-2"></i>Filter
              </LinkClickHandler>
            </Col>
            <Col flex="auto" className="px-2">
              <hr className="text-dark border border-secondary border-1 " />
            </Col>
          </Row>
          {filterShow && (
            <props.SearchComponent
              onSearch={onSearchTokensChange}
              user={props.user}
            />
          )}
        </>
      )}

      {items && !props.hidePaging && pagePos == "top" && (
        <Row className="mt-2 mb-3">
          <Col flex={"auto"}></Col>
          <Col>
            <Pagination
              onChange={onPageChange}
              total={items?.totalSize}
              current={pageParams?.page + 1}
            />
          </Col>
        </Row>
      )}
      {!props.hideLoading && loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!loading && items?.content && items.content.length > 0 && (
        <div className={props.contentClassName}>
          {props.header}
          <Row gutter={props.gutter}>
            {!loading &&
              items?.content.map((item, index) => (
                <Col
                  span={
                    props.horizontalLayout ? props.horizontalSpan?.span : 24
                  }
                  {...props.horizontalSpan}
                  className="h-100"
                >
                  <ListItemInternal
                    className="h-100"
                    item={item}
                    index={index}
                    items={items}
                    handleOnClickItem={
                      props.onClickItem ? handleOnClickItem : undefined
                    }
                    ItemLayoutComponent={props.ItemLayoutComponent}
                    selectedIndex={selectedIndex}
                    itemNode={props.itemNode}
                    ItemComponent={props.ItemComponent}
                  />
                </Col>
              ))}
          </Row>
        </div>
      )}

      {items && !props.hidePaging && pagePos == "bottom" && (
        <Row className="mt-2 mb-3">
          <Col flex={"auto"}></Col>
          <Col>
            <Pagination
              onChange={onPageChange}
              total={items?.totalSize}
              current={pageParams?.page + 1}
            />
          </Col>
        </Row>
      )}

      {items?.content && items.content.length == 0 && (
        <div className="p-3">{props.emptyListContent}</div>
      )}
    </div>
  );
}

interface ListItemIternalProps extends HTMLProps {
  item: any;
  index: number;
  items?: PageResponse<any>;
  handleOnClickItem?: (item: any, index: number) => void;
  ItemLayoutComponent: React.ComponentType<ListItemLayoutProps>;
  selectedIndex?: number;

  itemNode?: (itemProps: ListItemProps) => React.ReactNode;

  /**
   * @deprecated Use itemNode instead
   */
  ItemComponent?: React.ComponentType<ListItemProps>;
}

export function ListItemInternal(props: ListItemIternalProps) {
  return (
    <span
      className={`${props.handleOnClickItem && "cursor-pointer"} ${
        props.className
      }`}
      onClick={
        props.handleOnClickItem
          ? () => {
              props.handleOnClickItem!(props.item, props.index);
            }
          : undefined
      }
      key={props.index}
    >
      <props.ItemLayoutComponent
        className={props.className}
        index={props.index}
        listLength={!props.items ? 0 : props.items.content.length}
        selectedIndex={props.selectedIndex}
      >
        {props.itemNode &&
          props.itemNode({
            item: props.item,
            index: props.index,
            length: !props.items ? 0 : props.items.content.length,
            selectedIndex: props.selectedIndex,
          })}
      </props.ItemLayoutComponent>
    </span>
  );
}
