import React from "react";
import TimeAgo from "timeago-react";
import { HTMLProps } from "../../../model/HTMLProps";
import { ReviewV1 } from "../../../model/Reviews";
import StarRatingView from "../StarRatingView";

interface ReviewModernViewProps extends HTMLProps {
  review: ReviewV1;
  hideReviewer?: boolean;
  hideCreatedAt?: boolean;
}

export function ReviewModernView(props: ReviewModernViewProps) {
  return (
    <div className={`${props.className}`}>
      <div className="card card-body rounded-4">
        <div className="review-block-title font-weight-bold">
          {props.review.contextDisplayName}
        </div>
        <div className="review-block-rate ">
          {props.review.starRatingFive && (
            <StarRatingView
              starRating={props.review.starRatingFive}
              maxRating={5}
            />
          )}
        </div>

        <div className="review-block-description text-change-line-for-newline mt-3 mb-2 text-body-emphasis">
          <i className="fas fa-quote-left pr-1 text-secondary mr-1 h5"></i>

          {props.review.text}
        </div>
        <div className="row ">
          <div className="col review-block-name float-left">
            {!props.hideReviewer && props.review.reviewer && (
              <> - {props.review.reviewer.dl}</>
            )}
          </div>

          <div className="col-auto review-block-date text-right">
            {!props.hideCreatedAt && props.review.createdAt && (
              <TimeAgo datetime={props.review.createdAt} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
