import React from "react";
import Moment from "react-moment";
import { AdminInterviewExperiencesAPI } from "../../api/admin/AdminInterviewExperiencesAPI";
import { admiApiLinkConstants } from "../../constants";
import { UrlHelpers } from "../../helpers/Helpers";
import { InterviewExperience } from "../../model/InterviewExperience";
import { Api, ApiLinkedButton, ApiMethod } from "../common/ApiLinkedButton";
import { reloadWindowPromise } from "../common/reloadCallback";

interface AdminInterviewExperiencesListState {
  interviewExperiences: InterviewExperience[];
}

export class AdminInterviewExperiencesList extends React.Component<
  {},
  AdminInterviewExperiencesListState
> {
  componentDidMount() {
    AdminInterviewExperiencesAPI.fetchInterviewExperiences().then(
      (interviewExperiences) => {
        this.setState({ interviewExperiences: interviewExperiences });
      }
    );
  }

  render() {
    return (
      <div className="">
        <h3 className="h3 mb-4 text-gray-800">Interview Experiences</h3>
        {this.state &&
        this.state.interviewExperiences &&
        this.state.interviewExperiences.length > 0 ? (
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Company</th>
                <th scope="col">Designation</th>
                <th scope="col">Created At</th>
                <th scope="col">Approved At</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {this.state.interviewExperiences.map(
                (interviewExperience, index) => (
                  <tr key={interviewExperience.id}>
                    <th scope="row">
                      <a
                        href={UrlHelpers.getInterviewExperienceDetailsUrl(
                          interviewExperience.slug,
                          interviewExperience.id
                        )}
                        aria-current="true"
                      >
                        {interviewExperience.id}
                      </a>
                    </th>
                    <td>{interviewExperience.company?.dl}</td>
                    <td>{interviewExperience.designation?.title}</td>
                    <td>
                      <Moment format="DD-MM-YYYY HH:mm">
                        {interviewExperience.createdAt}
                      </Moment>
                    </td>
                    <td>
                      {interviewExperience.approvedAt && (
                        <Moment format="DD-MM-YYYY HH:mm">
                          {interviewExperience.approvedAt}
                        </Moment>
                      )}
                    </td>
                    <td>
                      {!interviewExperience.approvedAt &&
                      interviewExperience.id ? (
                        <ApiLinkedButton
                          api={
                            new Api(
                              admiApiLinkConstants.INTERVIEW_EXPERIENCE_APPROVE.replace(
                                ":interviewExperienceId",
                                "" + interviewExperience.id
                              ),
                              ApiMethod.POST
                            )
                          }
                          uniqueKey={
                            "interview-experience" +
                            interviewExperience.id +
                            "-approve"
                          }
                          callback={reloadWindowPromise}
                          buttonProps={{
                            text: {
                              normal: "Approve",
                              submitting: "Please Wait...",
                            },
                            type: "success",
                          }}
                        />
                      ) : (
                        <ApiLinkedButton
                          api={
                            new Api(
                              admiApiLinkConstants.INTERVIEW_EXPERIENCE_DISAPPROVE.replace(
                                ":interviewExperienceId",
                                "" + interviewExperience.id
                              ),
                              ApiMethod.POST
                            )
                          }
                          uniqueKey={
                            "interview-experience" +
                            interviewExperience.id +
                            "-approve"
                          }
                          callback={reloadWindowPromise}
                          buttonProps={{
                            text: {
                              normal: "Disapprove",
                              submitting: "Please Wait...",
                            },
                            type: "danger",
                          }}
                        />
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        ) : (
          <>No data Found</>
        )}
      </div>
    );
  }
}
