import React, { useState } from "react";
import { LinkClickHandler } from "../../links/Link.ClickHandler";

interface MobileCollapsableComponentProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

export function MobileCollapsableComponent(
  props: MobileCollapsableComponentProps
) {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  return (
    <>
      <div className="event-data-mobile">
        <div className="mb-2">
          <LinkClickHandler
            className="text-dark"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            {props.title}
          </LinkClickHandler>
        </div>
        {!collapsed && props.children}
      </div>
      <div className="event-data-desktop">{props.children}</div>
    </>
  );
}
