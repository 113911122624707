import React, { useState } from "react";
import { BogieName } from "../Global.Names";
import { BookingApi } from "../booking/Booking.Api";
import { UserDrawer } from "../components/drawer/User.Drawer";
import { LinkClickHandler } from "../links/Link.ClickHandler";
import { Target } from "../model/target.model";
import { GroupCallTitle } from "../title/GroupCall.Title";

interface GroupLinkProps {
  bookingTarget: Target;
}

export function GroupLink(props: GroupLinkProps) {
  let [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <LinkClickHandler
        className="text-dark"
        onClick={() => {
          setOpenDrawer(true);
        }}
      >
        <GroupCallTitle />
      </LinkClickHandler>
      {openDrawer && (
        <UserDrawer
          title={`${BogieName.SENTENCE_START_PLURAL}`}
          onFetch={() => {
            return BookingApi.getBogies(props.bookingTarget).executeV2();
          }}
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
        />
      )}
    </>
  );
}
