import React from "react";
import { Stat } from "./Stat.Model";

interface StatCardProps {
  stat: Stat;
}

export function StatCard(props: StatCardProps) {
  return (
    <div className="card card-stats mb-xl-0">
      <div className="card-body">
        <h5 className="card-title text-muted mb-0">{props.stat.title}</h5>
        <span className="h2 font-weight-bold mb-0">{props.stat.value}</span>
      </div>
    </div>
  );
}
