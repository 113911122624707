import { Field } from "formik";
import React from "react";
import { DeleteTitle } from "../Delete.title";
import { FormikDatePicker } from "./FormikDatePicker";
import { FormikInputParams } from "./FormikInputParams.model";

interface DurationTimestampInputProps extends FormikInputParams {
  onDelete?: () => void;
}

export function DurationTimestampInput(props: DurationTimestampInputProps) {
  return (
    <div className="DurationTimestampEditComponent">
      <div className="row">
        <div className="col">
          <div className="">
            {/* From:{" "} */}
            <Field
              name={`${props.name}.fromMillis`}
              placeholder="From"
              component={FormikDatePicker}
              className="form-control"
              required={true}
            />
          </div>
          {/* <div className="col-auto"></div> */}
          <div className="pt-2">
            {/* Until:{" "} */}
            <Field
              name={`${props.name}.toMillis`}
              placeholder="Until"
              component={FormikDatePicker}
              className="form-control"
              required={true}
            />
          </div>
        </div>
        {props.onDelete && (
          <div className="col-auto">
            <button
              className="btn text-danger form-group"
              type="button"
              onClick={() => props.onDelete!()}
              disabled={props.disabled}
            >
              <DeleteTitle />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
