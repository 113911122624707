export class FormikHelpers {
  public static updateField(field: any, name: any, value: any) {
    field.onChange({
      target: {
        name: name,
        value: value,
      },
    });
  }
}
