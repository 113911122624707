import { Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { SearchAPI } from "../../api/SearchAPI";
import ExpertCardMedium from "../../components/common/ExpertCardMedium";
import { Loader } from "../../components/common/Loader.component";
import { MobileCollapsableComponent } from "../../components/common/MobileCollapsable.component";
import { Center } from "../../components/common/layout/Center.component";
import { publicLinkConstants } from "../../constants";
import { SearchTokenHelper } from "../../helpers/Search.helper";
import { SearchToken } from "../../model/search/SearchToken";
import { Topic } from "../../topic/Topic.model";
import { TopicAPI } from "../../topic/TopicAPI";
import { UserBasicDetails } from "../User";
import { TopicEasyFilter } from "./Topic.EasyFilter";
import { UserFiltersView } from "./UserFiltersView";

interface FindExpertContentProps {}

export function FindExpertContent(props: FindExpertContentProps) {
  let [loading, setLoading] = useState<boolean>();
  let [experts, setExperts] = useState<UserBasicDetails[]>([]);
  let [topics, setTopics] = useState<Topic[]>([]);

  function updateExperts(searchTokens: SearchToken[]): Promise<void> {
    setLoading(true);
    return SearchAPI.searchExpert({ searchTokens: searchTokens })
      .then((experts) => {
        setExperts(experts);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    updateExperts([]);

    TopicAPI.getListingTopics().then((topics) => {
      setTopics(topics);
    });
  }, []);

  return (
    <>
      <div className="main-container bg-main-body h-100">
        <Row gutter={[20, 20]}>
          <Col xs={24} md={8} xl={6} xxl={4} className="mob-mt-0">
            <MobileCollapsableComponent
              title={
                <>
                  <i className="fa-solid fa-filter"></i>&nbsp; Show Filter
                </>
              }
            >
              <UserFiltersView
                callback={updateExperts}
                className="bg-white card p-3 mob-mb-3"
              />
            </MobileCollapsableComponent>
          </Col>
          <Col xs={24} md={16} xl={18} xxl={20}>
            <div>
              <TopicEasyFilter
                className="mb-4"
                allTopics={topics}
                onSelect={function (selectedTopics: Topic[]): Promise<void> {
                  let topicToken = SearchTokenHelper.getTopicToken({
                    topics: selectedTopics,
                  });
                  // searchTokens.push(topicToken);
                  return updateExperts([topicToken]);
                }}
              />
            </div>

            {loading && (
              <div className="mt-4">
                <Center>
                  <Loader />
                </Center>
              </div>
            )}
            <Row gutter={[20, 20]}>
              {!loading &&
                experts &&
                experts.map((expert, index) => (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={8}
                    xxl={6}
                    key={expert.id}
                  >
                    <ExpertCardMedium expert={expert} />
                  </Col>
                ))}

              {!loading && (!experts || experts.length === 0) && (
                <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                  <Card className="card card-body rounded-5 bg-transparent text-muted text-center mt-3">
                    No experts found with the given criteria!
                    <div className="h5 mt-5">Are you an expert?</div>
                    <div className="mt-3">
                      <a
                        className="btn btn-primary bold rounded-3"
                        href={publicLinkConstants.BECOME_EXPERT}
                      >
                        <i className="fa-solid fa-people-pulling"></i> Start
                        helping others
                      </a>
                    </div>
                    <div className="mt-5">#LearnTogetherGrowTogether</div>
                  </Card>
                </Col>
              )}

              {!loading && experts && experts.length > 0 && (
                <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                  <Card className="card card-body rounded-5 bg-transparent text-muted text-center mt-3">
                    <div className="h5">Are you an expert?</div>
                    <div className="mt-3">
                      <a
                        className="btn btn-primary bold rounded-3"
                        href={publicLinkConstants.BECOME_EXPERT}
                      >
                        <i className="fa-solid fa-people-pulling"></i> Start
                        helping others
                      </a>
                    </div>
                    <div className="mt-5">#LearnTogetherGrowTogether</div>
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
