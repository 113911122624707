import { ISpecificBookingType } from "../booking/Booking.model";
import { SchedulingPreference } from "../calendar/Calendar.model";
import { Skill } from "./Skill";

export enum ExpertSessionRole {
  EXPERT = "EXPERT",
  MENTEE = "MENTEE",
  ADMIN = "ADMIN",
}

export interface VideoSessionType {
  id: string;
  displayTitle: string;
}

export default interface VideoSession extends ISpecificBookingType {
  id: string;
  durationSeconds: number;
  skill?: Skill;

  scheduleDateTime?: string;
  scheduleMeetingLink?: string;
  schedulingPreference?: SchedulingPreference;
  expertRescheduleMarked?: boolean;
  expertRescheduleNotes?: string;
  menteeRescheduleNotes?: string;
  agenda?: string;
  displayDuration?: string;
  markedNoShowAt: string;
  canMarkNoShow?: boolean;
  shouldShowScheduleSection?: boolean;
}

export interface VideoSessionRescheduleByMenteeStatus {
  isRescheduleAllowed: boolean;
  disallowDisplayMsg: string;
}
