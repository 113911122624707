import React from "react";
import TimeAgo from "timeago-react";
import { UserHelpers } from "../../../helpers/Helpers";
import { ReviewV1 } from "../../../model/Reviews";
import StarRatingView from "../StarRatingView";

interface ReviewViewProps {
  review: ReviewV1;
  hideReviewer?: boolean;
  hideCreatedAt?: boolean;
  hideContextDl?: boolean;
}

export function ReviewView(props: ReviewViewProps) {
  return (
    <div className="row">
      <div className="col-sm-12">
        {!props.hideContextDl && (
          <div className="review-block-title font-weight-bold">
            {props.review.contextDisplayName}
          </div>
        )}

        <div className="review-block-rate ">
          {props.review.starRatingFive && (
            <StarRatingView
              starRating={props.review.starRatingFive}
              maxRating={5}
            />
          )}
        </div>

        <div className="review-block-description text-change-line-for-newline">
          {props.review.text}
        </div>
        <div className="row">
          <div className="col-md-6 review-block-name font-weight-light float-left">
            {!props.hideReviewer && props.review.reviewer && (
              <> - {UserHelpers.getDisplayFirstName(props.review.reviewer)}</>
            )}
          </div>

          <div className="col-md-6 review-block-date font-weight-light mt-1 text-right">
            {!props.hideCreatedAt && props.review.createdAt && (
              <>
                <TimeAgo datetime={props.review.createdAt} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
