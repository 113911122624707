import React from "react";
import { Route, RouteProps } from "react-router";
import { navbarLinks } from "../App";
import NormalNavBar from "../components/pages/NormalNavBar";
import PublicPage from "../components/pages/PublicPage";

interface PublicPageRouteProps extends RouteProps {
  path: string;
  contentComponent: React.ComponentType<any>;
  hideNavBar?: boolean;
  hideFooter?: boolean;
}

export function PublicPageRoute(props: PublicPageRouteProps) {
  return (
    <>
      <Route
        exact
        component={(contentPops: any) => {
          return (
            <PublicPage
              contentComponent={props.contentComponent}
              hideNavBar={props.hideNavBar}
              hideFooter={props.hideFooter}
              navBarComponent={NormalNavBar}
              {...contentPops}
              headerLinks={navbarLinks()}
            />
          );
        }}
        {...props}
      />
    </>
  );
}
