import { CompanyAPI } from "../company/CompanyAPI";
import { DesignationAPI } from "../api/DesignationAPI";
import { ChannelApi } from "../channel/Channel.api";
import { Channel } from "../channel/Channel.model";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { Company } from "../company/Company";
import { Designation } from "../model/Designation";
import { SkillAPI } from "../skills/SkillAPI";
import { Topic, TopicType } from "./Topic.model";

export class TopicAPI {
  public static getHomePageTopics(): Promise<Topic[]> {
    return Promise.resolve([
      {
        dl: "Leadership",
        topicId: { type: TopicType.SKILL, id: "14" },
        topicType: TopicType.SKILL,
        markedUseForFilterAt: 1,
      },
      {
        dl: "Software Engineering",
        topicId: { type: TopicType.SKILL, id: "244" },
        topicType: TopicType.SKILL,
        markedUseForFilterAt: 2,
      },
      {
        dl: "Product",
        topicId: { type: TopicType.SKILL, id: "5" },
        topicType: TopicType.SKILL,
        markedUseForFilterAt: 5,
      },
      {
        dl: "Recruitment",
        topicId: { type: TopicType.SKILL, id: "261" },
        topicType: TopicType.SKILL,
        markedUseForFilterAt: 261,
      },
    ]);
  }

  public static getListingTopics(): Promise<Topic[]> {
    return new Api<void, Topic[]>(
      apiLinkConstants.API_TOPIC_LIST,
      ApiMethod.GET
    ).execute();
  }

  public static createTopic(title: string, type: TopicType): Promise<Topic> {
    switch (type) {
      case TopicType.SKILL:
        return SkillAPI.add(title);
      case TopicType.COMPANY:
        return CompanyAPI.addCompany({ dl: title } as Company);
      case TopicType.DESIGNATION:
        return DesignationAPI.add({ title: title } as Designation);
      case TopicType.CHANNEL:
        return ChannelApi.createChannel({ dl: title } as Channel);
    }
    throw new Error("Unknown topic type");
  }
}
