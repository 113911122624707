import React from 'react';
import ForCompaniesContent from './ForCompaniesContent'
import PublicPage from './PublicPage'

class Comp extends React.Component {
    render() {
        const headerLinks = [
            {id: "how-it-works", text: "How it works", href: "#how-it-works"},
            {id: "features", text: "Features", href: "#features"},
            {id: "contact", text: "Contact Us", href: "#contact"},
        ];
        return (
            <PublicPage contentComponent={ForCompaniesContent} headerLinks={headerLinks} />
        )
    }
}

export default Comp;
