import { Col, Row } from "antd";
import { useField } from "formik";
import React from "react";
import { BogieName } from "../Global.Names";
import { Label } from "../components/common/Form/elements/FormInputLabel";
import { FormikCountInput } from "../components/common/input/Formik.Count.Input";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { FormikSwitchInput } from "../components/common/input/FormikSwitch.input";
import { ListFormikInput } from "../components/common/input/List.FormikInput";
import { MarkdownFormikInput } from "../components/common/input/Markdown.FormikInput";
import { EBTextInputV2 } from "../components/common/input/TextLine.input";
import { AttachmentInput } from "../components/common/upload/AttachmentInput";
import { FormTemplateBuilderFormikInput } from "../form/template-builder/Form.Template.Builder";
import { User, UserBasicDetails } from "../user/User";
import { OfferingCommonDetails } from "./Offering";
import { OfferingInputSection } from "./Offering.Input.Section";

interface OfferingCommonDetailsFormikInputProps extends FormikInputParams {
  user: User;

  titleSideNode?: React.ReactNode;
  postTitleNode?: React.ReactNode;
  hideOptionForHiddenInListing?: boolean;
  takeMaxNumBookingsPerUser?: boolean;
  hideDetailPageMetadata?: boolean;
}

export function OfferingCommonDetailsFormikInput(
  props: OfferingCommonDetailsFormikInputProps
) {
  const namePrefix = props.name ? `${props.name}.` : "";

  const [field] = useField(props);
  let offeringCommonDetails = field.value as OfferingCommonDetails;

  return (
    <div>
      <Row gutter={24}>
        <Col lg={12}>
          <OfferingInputSection
            title={<Label label={"Title:"} required={true} />}
            content={
              <EBTextInputV2
                name={`${namePrefix}dl`}
                placeholder="Title of the offering to be displayed to the users."
                required
                maxLength={100}
                showCount={true}
              />
            }
          />
        </Col>
        <Col lg={12}>{props.titleSideNode}</Col>
      </Row>
      {props.postTitleNode}

      <Row gutter={[20, 10]}>
        <Col>
          <OfferingInputSection
            title={
              <Label
                label="Overview:"
                required={true}
                tooltip="Used in list page as well as in detail page"
              />
            }
            content={
              <MarkdownFormikInput
                placeholder="Briefly tell what will you provide in this."
                required={true}
                name={`${namePrefix}detailShort`}
                user={props.user}
                minEditorHeight={150}
              />
            }
          />
        </Col>
        {!props.hideDetailPageMetadata && (
          <Col>
            <OfferingInputSection
              title={
                <Label
                  label="Description:"
                  tooltip="Used only on details page and not on listing page"
                />
              }
              content={
                <MarkdownFormikInput
                  placeholder="Explain in detail (try to add as much details as possible)"
                  required={true}
                  name={`${namePrefix}detailLong`}
                  user={props.user}
                  minEditorHeight={150}
                />
              }
            />
          </Col>
        )}
      </Row>
      {!props.hideDetailPageMetadata && (
        <>
          <OfferingInputSection
            title={<Label label="Banner Image:" />}
            content={
              <div className="border p-2">
                <AttachmentInput
                  name={`${namePrefix}bannerImage`}
                  user={props.user}
                  required={false}
                />
              </div>
            }
          />
          <OfferingInputSection
            title={
              <Label
                label={`What will ${BogieName.SENTENCE_MID_PLURAL} learn in this offering?`}
              />
            }
            content={
              <ListFormikInput
                name={`${namePrefix}genericMetadata.learningObjectives`}
                itemComponent={GenericMetadataInfoFormikInput}
              />
            }
          />
          <div className="row">
            <div className="col-xl-6">
              <OfferingInputSection
                title={
                  <Label label="What are the requirements or prerequisites for taking this?" />
                }
                content={
                  <ListFormikInput
                    name={`${namePrefix}genericMetadata.prerequisites`}
                    itemComponent={GenericMetadataInfoFormikInput}
                  />
                }
              />
            </div>
            <div className="col-xl-6">
              <OfferingInputSection
                title={<Label label="Who is this for?" />}
                content={
                  <ListFormikInput
                    name={`${namePrefix}genericMetadata.targetAudiences`}
                    itemComponent={GenericMetadataInfoFormikInput}
                  />
                }
              />
            </div>
          </div>
        </>
      )}
      {offeringCommonDetails?.preBookingFormTemplate?.templateData
        ?.sections && (
        <OfferingInputSection
          title={
            <Label
              label={`${BogieName.SENTENCE_START} Questions`}
              tooltip={`These questions will be presented to the ${BogieName.SENTENCE_MID} during the booking time`}
            />
          }
          content={
            <FormTemplateBuilderFormikInput
              name={`${namePrefix}preBookingFormTemplate`}
            />
          }
        />
      )}

      {!props.hideOptionForHiddenInListing && (
        <OfferingInputSection
          title={"Configuration"}
          content={
            <>
              <FormikSwitchInput
                name={`${namePrefix}isHiddenFromListing`}
                label={
                  <Label
                    label="Hide this on your profile?"
                    tooltip={`You can copy the link and privately share with the ${BogieName.SENTENCE_MID}`}
                  />
                }
              />
            </>
          }
        />
      )}
      {props.takeMaxNumBookingsPerUser && (
        <>
          <OfferingInputSection
            title={
              <Label label="Allowed # of bookings per user:" required={true} />
            }
            content={
              <FormikCountInput
                limitedOptions={[
                  {
                    value: 1,
                    dl: "1",
                  },
                  {
                    value: 2,
                    dl: "2",
                  },
                  {
                    value: 5,
                    dl: "5",
                  },
                  {
                    value: 10,
                    dl: "10",
                  },
                ]}
                name={`${namePrefix}bookingLimitPerUser`}
                supportUnlimited={true}
                supportCustom={true}
              />
            }
          />
        </>
      )}
    </div>
  );
}

export function GenericMetadataInfoFormikInput(props: FormikInputParams) {
  return (
    <span className="GenericMetadataInfoFormikInput">
      <EBTextInputV2 name={`${props.name}.dl`} />
    </span>
  );
}
