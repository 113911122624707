import { Form, Formik, FormikValues } from "formik";
import React, { useState } from "react";
import { createPortal } from "react-dom";
import { Api } from "../ApiLinkedButton";
import { EBButtonV2, EBButtonV2Props } from "../EBButtonV2";
import { EBModalV2 } from "../EBModalV2";

interface ApiLinkedButtonV3Props {
  buttonProps: EBButtonV2Props;
  api: Api<any, any>;
  uniqueKey: string;
  apiFormBody?: React.ReactNode;
  formModalSize?: "sm" | "md" | "xl";
  initialValues?: any;
  successCallback?: (response: any) => Promise<void>;
  otherlayout?: React.ReactNode;
  className?: string; // This class will be applied to the button
  rowClassName?: string;
}

export function ApiLinkedButtonV3(mainProps: ApiLinkedButtonV3Props) {
  let [isSubmitting, setSubmitting] = useState<boolean>(false);
  let modalId = mainProps.uniqueKey + "-modal";
  let modalCloseBtnId = mainProps.uniqueKey + "-modal-close-button";

  function submit() {
    setSubmitting(true);
    mainProps.api
      .executeV2(mainProps.initialValues)
      .then((response) => {
        if (mainProps.successCallback) {
          return mainProps.successCallback(response);
        }
        return response;
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <>
      {!mainProps.apiFormBody && (
        <EBButtonV2
          className={mainProps.className}
          rowClassName={mainProps.rowClassName}
          {...mainProps.buttonProps}
          disabled={isSubmitting || mainProps.buttonProps.disabled}
          loading={isSubmitting}
          onClick={(event) => {
            submit();
            event.stopPropagation();
          }}
          otherlayout={mainProps.otherlayout}
        />
      )}
      {mainProps.apiFormBody && (
        <>
          {createPortal(
            <>
              <Formik
                initialValues={mainProps.initialValues ?? {}}
                onSubmit={function (values: FormikValues) {
                  setSubmitting(true);
                  mainProps.api
                    .executeV2(values)
                    .then((response) => {
                      document.getElementById(modalCloseBtnId)?.click();
                      if (mainProps.successCallback) {
                        return mainProps.successCallback(response);
                      }
                      return response;
                    })
                    .catch(() => {
                      document.getElementById(modalCloseBtnId)?.click();
                    })
                    .finally(() => {
                      setSubmitting(false);
                    });
                }}
              >
                {(formProps) => (
                  <Form>
                    <EBModalV2
                      body={<div>{mainProps.apiFormBody}</div>}
                      id={modalId}
                      modalSize={mainProps.formModalSize}
                      primaryButtonProps={{
                        ...mainProps.buttonProps,
                        type: "submit",
                        disabled:
                          isSubmitting || mainProps.buttonProps.disabled,
                        loading: isSubmitting,
                      }}
                      closeButtonClass={mainProps.buttonProps.className}
                      closeButtonId={modalCloseBtnId}
                    />
                  </Form>
                )}
              </Formik>
            </>,
            document.body
          )}
          <EBButtonV2
            className={mainProps.className}
            rowClassName={mainProps.rowClassName}
            {...mainProps.buttonProps}
            disabled={isSubmitting || mainProps.buttonProps.disabled}
            loading={isSubmitting}
            otherProps={{
              "data-bs-toggle": "modal",
              "data-bs-target": "#" + modalId,
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            otherlayout={mainProps.otherlayout}
          />
        </>
      )}
    </>
  );
}
