import React, { useEffect, useState } from "react";
import { EyeIcon } from "../../icon/Eye.Icon";
import { Link } from "../../links/Link";
import { ReactMatch } from "../../model/ReactMatchObj";
import { PageProps } from "../../model/views/PageProps";
import {
  OfferingFetchContext,
  OfferingPath,
  OfferingTypeName,
} from "../Offering";
import { OfferingPublishStatusBadge } from "../Offering.PublishStatus.Badge";
import { OfferingPublishStatusToggleButton } from "../Offering.PublishStatus.ToggleButton";
import { OfferingAPI } from "../Offering.api";
import { OfferingsPathStepsEditPageContent } from "./Offerings.Path.Steps.Edit.PageContent";

interface OfferingsPathContentEditPageContentProps extends PageProps {
  match: ReactMatch<OfferingsPathContentEditPageContentPathParams>;
}

interface OfferingsPathContentEditPageContentPathParams {
  userId: string;
  offeringId: string;
}

export function OfferingsPathContentEditPageContent(
  props: OfferingsPathContentEditPageContentProps
) {
  const [offering, setOffering] = useState<OfferingPath>();
  useEffect(() => {
    OfferingAPI.get(
      OfferingTypeName.PATH,
      props.match.params.offeringId,
      OfferingFetchContext.EDIT
    )
      .executeV2()
      .then((offering) => {
        setOffering(offering);
      });
  }, []);
  return (
    <span className="OfferingsPathContentEditPageContent">
      {offering && props.user && (
        <div className="d-flex flex-column h-100">
          <div className="border-bottom w-100 py-3 px-4">
            <div className="col-auto m-0 p-0 d-flex justify-content-between">
              <div>
                <span className="h4 m-0 p-0">
                  Content Builder: {offering.commonDetails.dl}{" "}
                </span>
                <OfferingPublishStatusBadge
                  className="ml-2"
                  offering={offering.commonDetails}
                />
              </div>
              <div className="hstack gap-2">
                <OfferingPublishStatusToggleButton
                  offering={offering}
                  onOfferingUpdate={(updatedOffering) => {
                    setOffering(updatedOffering);
                    return Promise.resolve();
                  }}
                />
                <Link
                  className="ml-2 btn btn-secondary btn-sm"
                  href={offering.contentViewUrl}
                  tooltip="Click to preview path"
                  target="_blank"
                >
                  <EyeIcon />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex-grow-1">
            <OfferingsPathStepsEditPageContent
              user={props.user}
              offering={offering}
              offeringId={props.match.params.offeringId}
            />
          </div>
        </div>
      )}
    </span>
  );
}
