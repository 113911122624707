import { Col, Row } from "antd";
import React from "react";
import { InterviewExperienceAPI } from "../api/InterviewExperienceAPI";
import { InterviewExperienceFilterView } from "../components/common/filters/InterviewExperienceFilterView";
import { RoundedPillButton } from "../components/pages/charchaa/RoundedPillButton";
import { publicLinkConstants } from "../constants";
import { WindowHelpers } from "../helpers/Window.helper";
import { MainAndRightLayout } from "../layout/MainAndRight.Layout";
import { ListItemProps } from "../list/List";
import {
  ListItemLayoutCard,
  ListItemLayoutDefault,
} from "../list/ListItem.Layout";
import { ListV2 } from "../list/ListV2";
import { InterviewExperienceResponse } from "../model/InterviewExperience";
import { SearchRequest } from "../model/search/UserSearchRequestV1";
import { SortDirection } from "../model/sort/SortToken";
import { SortTokenName } from "../model/sort/SortTokenName";
import { PageResponse } from "../paging/Paging.Model";
import { RelatedExpertsComponent } from "../promotion/RelatedExperts.Component";
import { User } from "../user/User";
import { InterviewExperiencesListItem } from "./InterviewExperiencesList";

interface InterviewExperiencesPageContentProps {
  user?: User;
}

export function InterviewExperiencesPageContent(
  props: InterviewExperiencesPageContentProps
) {
  function onFetch(
    searchRequest: SearchRequest
  ): Promise<PageResponse<InterviewExperienceResponse>> {
    return InterviewExperienceAPI.search().executeV2(searchRequest);
  }

  return (
    <div className="main-container bg-main-body h-100">
      <Row align={"middle"}>
        <Col className="mr-5">
          <h1 className="h3">Interview Experiences</h1>
        </Col>
        <Col flex={"auto"}>
          <RoundedPillButton
            redirectUrl={publicLinkConstants.INTERVIEW_EXPERIENCE_ADD}
            title={
              WindowHelpers.isMobile()
                ? "Add Interview Experience"
                : "Have a interview experience to add? Contribute now"
            }
          ></RoundedPillButton>
        </Col>
      </Row>

      <MainAndRightLayout
        gutter={[15, 10]}
        className="mt-3"
        main={
          <ListV2
            paginationPosition="bottom"
            user={props.user?.basicDetails}
            SearchComponent={InterviewExperienceFilterView}
            gutter={[10, 10]}
            onFetch={(searchRequest) => {
              return onFetch(searchRequest ?? {});
            }}
            itemNode={(itemProps: ListItemProps) => {
              let interviewExperience =
                itemProps.item as InterviewExperienceResponse;
              return (
                <InterviewExperiencesListItem
                  interviewExperience={interviewExperience}
                  dl={
                    <span className="h5">
                      {interviewExperience.id}. {interviewExperience.shortDl}
                    </span>
                  }
                />
              );
            }}
            emptyListContent="No questions found"
            ItemLayoutComponent={ListItemLayoutCard}
          />
        }
        right={
          <>
            <div className="card rounded-5 p-0 overflow-hidden py-3">
              <div className="ml-3 bold text-muted">
                <span> Popular Interview Experience</span>
              </div>
              <ListV2
                hidePaging={true}
                onFetch={() => {
                  return onFetch({
                    sortTokens: [
                      {
                        name: SortTokenName.VIEW,
                        direction: SortDirection.DESC,
                      },
                    ],
                    pageParams: { page: 0, size: 5 },
                  });
                }}
                itemNode={(itemProps: ListItemProps) => {
                  let interviewExperience =
                    itemProps.item as InterviewExperienceResponse;
                  return (
                    <InterviewExperiencesListItem
                      dl={
                        <span className="font-weight-bold">
                          {interviewExperience.id}.{" "}
                          {interviewExperience.shortDl}
                        </span>
                      }
                      interviewExperience={interviewExperience}
                      hideEdit={true}
                      hideReactionText={true}
                      hidePostedBy={true}
                    />
                  );
                }}
                emptyListContent="No questions found"
                ItemLayoutComponent={ListItemLayoutDefault}
              />
            </div>
            <RelatedExpertsComponent
              context={{ topics: [] }}
              className="mt-3"
            />
          </>
        }
      />
    </div>
  );
}
