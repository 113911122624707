import { Col, Row } from "antd";
import React from "react";
import Moment from "react-moment";
import { BogieName, EngineName } from "../../../Global.Names";
import { BookingAttendeeLink } from "../../../booking/Booking.Attendee.Link";
import { BookingCommonComponent } from "../../../booking/Booking.Common.Component";
import { Booking } from "../../../booking/Booking.model";
import VideoSession from "../../../model/ExpertSession";
import { StatusComponent } from "../../../status/Status.Component";
import { User } from "../../../user/User";
import { UserLink } from "../../../user/User.link";
import { VideoSessionMarkNoShowComponent } from "../../../video-sessions/VideoSessionMarkNoShow.component";
import { TextViewComponent } from "../TextView";
import { SessionScheduleDetailsComponent } from "./SessionDetailsSchedule";

interface SessionDetailsComponentProps {
  booking: Booking;
  user: User;
}

export function VideoSessionBookingHeaderActions(
  props: SessionDetailsComponentProps
) {
  let booking = props.booking;
  const bcd = props.booking.bcd;

  let vs = booking.specificBookingTypeModel as VideoSession;
  return (
    <>
      {vs.canMarkNoShow && (
        <VideoSessionMarkNoShowComponent
          booking={booking}
          className="mb-2 btn-sm"
        />
      )}
    </>
  );
}

export function SessionDetailsComponent(props: SessionDetailsComponentProps) {
  let booking = props.booking;
  const bcd = props.booking.bcd;

  let vs = booking.specificBookingTypeModel as VideoSession;

  return (
    <>
      <div className="text-gray-800">
        <Row gutter={[12, 12]}>
          <Col flex="auto">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center mb-3">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Title
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {bcd.dl}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-clipboard-list fa-2x text-secondary"></i>
                  </div>
                </div>

                <div className="row no-gutters align-items-center mt-3">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Duration
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {vs.displayDuration}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-hourglass-start fa-2x text-secondary"></i>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col flex="auto">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center mb-3">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      {EngineName.SENTENCE_START}
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <UserLink user={bcd.engine} />
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-user fa-2x text-secondary"></i>
                  </div>
                </div>

                <>
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        {BogieName.SENTENCE_START_PLURAL}
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        <BookingAttendeeLink booking={booking} />
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-user fa-2x text-secondary"></i>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </Col>

          <Col flex="auto">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Status
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {booking.statusDl && (
                        <StatusComponent status={booking.statusDl} />
                      )}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-tasks fa-2x text-secondary"></i>
                  </div>
                </div>

                {!bcd.cancelledAt ? (
                  <div className="row no-gutters align-items-center mt-3">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Completed at
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {vs.markedNoShowAt && (
                          <Moment format="DD-MM-YYYY HH:mm">
                            {vs.markedNoShowAt}
                          </Moment>
                        )}
                        {bcd.engineCompletedAt && (
                          <Moment format="DD-MM-YYYY HH:mm">
                            {bcd.engineCompletedAt}
                          </Moment>
                        )}

                        {!bcd.engineCompletedAt && !vs.markedNoShowAt && "-"}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-clock fa-2x text-secondary"></i>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
        </Row>

        {((vs.skill && vs.skill.dl) || vs.agenda) && (
          <>
            <div className="card card-body border-left-primary shadow h-100 mt-4 py-2">
              {vs.skill && vs.skill.dl && (
                <div>
                  Skill selected: <b>{vs.skill.dl}</b>
                </div>
              )}
              {vs.agenda && (
                <div>
                  Agenda
                  {bcd.bogie?.dl && ` from ${bcd.bogie.dl}`}:
                  <div>
                    <TextViewComponent text={vs.agenda} />
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {!bcd.cancelledAt && !vs.markedNoShowAt && (
          <>
            <div className="mt-4">
              <SessionScheduleDetailsComponent
                session={vs}
                booking={booking}
                user={props.user.basicDetails}
              />
            </div>
            <BookingCommonComponent booking={booking} user={props.user} />
          </>
        )}
      </div>
    </>
  );
}
