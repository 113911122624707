import React from "react";
import TimeAgo from "timeago-react";
import { AttachmentTargetType } from "../../../model/AttachmentTargetType";
import { TopicBadge } from "../../../topic/Topic.Badge";
import { ActorLink } from "../../common/Actor.link";
import { ReactionCountsViewComponent } from "../../common/ReactionCountsViewComponent";
import { Post, PostResponse } from "./Charchaa.model";

export interface PostListComponentProps {
  postResponse: PostResponse;
  popularPost: boolean;
  dl?: React.ReactNode;
}
export function PostListComponent(props: PostListComponentProps) {
  return (
    <div className="PostListComponent">
      {props.dl}
      {props.postResponse.posts.map((post, index) => (
        <div className="mt-1">
          <PostListItemComponent
            post={post}
            index={index}
            popularPost={props.popularPost}
          ></PostListItemComponent>
        </div>
      ))}
    </div>
  );
}

export interface PostListItemComponentProps {
  post: Post;
  popularPost: boolean;
  index: number;
}

export function PostListItemComponent(props: PostListItemComponentProps) {
  return (
    <>
      {!props.popularPost && (
        <div className="PostListItemComponent rounded-5">
          <a
            href={props.post.url}
            className="list-group-item list-group-item-action"
          >
            <div className="py-2" key={props.post.id}>
              <div className="media">
                <div className="media-body pl-2">
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">{props.post.title}</h5>

                    {props.post.topics && (
                      <div className="event-data-desktop">
                        <div className="float-right text-end">
                          {props.post.topics.map((topic, index) => (
                            <TopicBadge topic={topic} key={index} />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="d-flex w-100 justify-content-between">
                    <div>
                      <small className="text-muted">
                        Posted By:{" "}
                        {props.post && props.post.actor && (
                          <ActorLink
                            actor={props.post.actor}
                            actorImageWidth={18}
                          />
                        )}
                      </small>
                    </div>
                    <div>
                      <small className="float-right">
                        {props.post.createdAt ? (
                          <TimeAgo datetime={props.post.createdAt} />
                        ) : null}
                      </small>
                    </div>
                  </div>

                  <div className="mob-mt-2 text-muted">
                    {props.post.shortContent}
                  </div>

                  {props.post.topics && (
                    <div className="px-0 event-data-mobile mt-2">
                      <div className="">
                        {props.post.topics.map((topic, index) => (
                          <TopicBadge topic={topic} key={index} />
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="d-flex w-100 justify-content-between mt-2">
                    <div className="d-flex w-100">
                      {props.post.reactionCounts && (
                        <div className="">
                          <ReactionCountsViewComponent
                            targetType={AttachmentTargetType.POST}
                            targetId={"" + props.post.id}
                            reactionCounts={props.post.reactionCounts}
                            isLiked={props.post.isLiked}
                          />
                        </div>
                      )}
                    </div>
                    <div className="float-right">
                      {props.post.canUserEdit && (
                        <div className="align-text-bottom">
                          <a
                            href={props.post.editUrl}
                            className="align-text-bottom"
                            role="button"
                          >
                            <i className="fas fa-edit"></i>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      )}
      {props.popularPost && (
        <div
          className={`PostListItemComponent ${
            props.index < 4 ? "border-bottom" : "border-none"
          }`}
        >
          <a href={props.post.url} className="list-group-item-action ">
            <div className="py-2" key={props.post.id}>
              <div className="media">
                <div className="media-body">
                  <div className="d-flex w-100 justify-content-between">
                    <span className="mb-1 font-weight-bold">
                      {props.post.title}
                    </span>
                  </div>
                  <div className="mob-mt-2 text-muted fs-14">
                    {props.post.shortContent}
                  </div>

                  {props.post.reactionCounts && (
                    <div className="float-right">
                      <ReactionCountsViewComponent
                        targetType={AttachmentTargetType.POST}
                        targetId={"" + props.post.id}
                        reactionCounts={props.post.reactionCounts}
                        isLiked={props.post.isLiked}
                        hideText={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </a>
        </div>
      )}
    </>
  );
}
