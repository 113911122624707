import React from "react";
import { CopyClipboardButton } from "../components/common/CopyCliboard.button";

interface UrlShareViewProps {
  url: string;
}

export function UrlShareView(props: UrlShareViewProps) {
  return (
    <div className="UrlShareView">
      {props.url}{" "}
      <span className="ml-2">
        <CopyClipboardButton textToCopy={props.url} />
      </span>
      <a target="_blank" href={props.url} className="ml-2 ">
        <i className="fa-solid fa-eye"></i>
      </a>
    </div>
  );
}
