import { Form, Formik } from "formik";
import React, { useState } from "react";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { User, UserBasicDetails } from "../user/User";
import { IOfferingV2 } from "./Offering";
import { OfferingCommonDetailsFormikInput } from "./Offering.CommonDetails.FormikInput";
import { Col, Row } from "antd";

interface OfferingAddOrEditFormProps {
  existingOffering: IOfferingV2;
  getOfferingSpecificFormikInputs?: (offering: IOfferingV2) => React.ReactNode;
  onSave: (values: IOfferingV2) => Promise<IOfferingV2>;
  user: User;
  titleSideNode?: React.ReactNode;
  postTitleNode?: React.ReactNode;

  hideOptionForHiddenInListing?: boolean;
  takeMaxNumBookingsPerUser?: boolean;
  hideDetailPageMetadata?: boolean;
}

export function OfferingAddOrEditForm(props: OfferingAddOrEditFormProps) {
  let [savingDraft, setSavingDraft] = useState<boolean>(false);
  let [savingPublished, setSavingPublished] = useState<boolean>(false);
  return (
    <div className="OfferingAddOrEditForm">
      <Formik
        initialValues={props.existingOffering}
        onSubmit={function (values) {
          return props.onSave(values).finally(function () {
            setSavingDraft(false);
            setSavingPublished(false);
          });
        }}
      >
        {(formProps) => (
          <Form>
            <div>
              <OfferingCommonDetailsFormikInput
                name={"commonDetails"}
                user={props.user}
                postTitleNode={props.postTitleNode}
                titleSideNode={props.titleSideNode}
                hideOptionForHiddenInListing={
                  props.hideOptionForHiddenInListing
                }
                takeMaxNumBookingsPerUser={props.takeMaxNumBookingsPerUser}
                hideDetailPageMetadata={props.hideDetailPageMetadata}
              />
              {props.getOfferingSpecificFormikInputs && (
                <>{props.getOfferingSpecificFormikInputs(formProps.values)}</>
              )}
            </div>
            <Row>
              <Col flex={"auto"}></Col>
              <Col>
                {" "}
                {props.existingOffering.commonDetails.id && (
                  <EBButtonV2
                    className=" mt-2"
                    content={{
                      normal: "Save",
                      submitting: "Saving...",
                    }}
                    disabled={formProps.isSubmitting}
                    loading={formProps.isSubmitting}
                    type="submit"
                  />
                )}
                {!props.existingOffering.commonDetails.id && (
                  <div className="mt-2">
                    <EBButtonV2
                      id={"add-offering-submit-btn"}
                      className="d-none"
                      content={{
                        normal: "",
                      }}
                      type="submit"
                    />
                    <Row gutter={[10, 5]}>
                      <Col>
                        <EBButtonV2
                          className="btn-secondary"
                          content={{
                            normal: "Save as Draft",
                            submitting: "Saving...",
                          }}
                          disabled={formProps.isSubmitting}
                          loading={savingDraft && formProps.isSubmitting}
                          onClick={() => {
                            formProps.values.commonDetails.isPublished = false;
                            setSavingDraft(true);
                            document
                              .getElementById("add-offering-submit-btn")
                              ?.click();
                          }}
                        />
                      </Col>
                      <Col>
                        <EBButtonV2
                          className=""
                          content={{
                            normal: "Save and Publish",
                            submitting: "Saving...",
                          }}
                          disabled={formProps.isSubmitting}
                          loading={savingPublished && formProps.isSubmitting}
                          onClick={() => {
                            formProps.values.commonDetails.isPublished = true;
                            setSavingPublished(true);
                            document
                              .getElementById("add-offering-submit-btn")
                              ?.click();
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}
