import React from "react";
import TimeAgo from "timeago-react";
import { InterviewLoopAPI } from "../../../api/InterviewLoopAPI";
import { InterviewRoundAPI } from "../../../api/InterviewRoundAPI";
import { apiLinkConstants } from "../../../constants";
import { UserHelpers } from "../../../helpers/Helpers";
import { InterviewLoop } from "../../../model/InterviewLoop";
import { InterviewRound } from "../../../model/InterviewRound";
import { User } from "../../../user/User";
import { UserPickerViewDefaultDesign } from "../../common/UserPickerViewDefaultDesign";
import { AddInterviewRoundView } from "../../common/ats/AddInterviewRoundView";
import { AttachmentView } from "../../common/ats/AttachmentView";
import { InterviewRoundList } from "../../common/ats/InterviewRoundList";
import {
  reloadWindowCallback,
  reloadWindowPromise,
} from "../../common/reloadCallback";

interface InterviewLoopDetailsPageProps {
  companyId: string;
  interviewLoopId: string;
}

interface InterviewLoopDetailsPageState {
  interviewLoop?: InterviewLoop;
  interviewRounds?: InterviewRound[];
}

export interface InterviewLoopAddRecruiterButtonProps {
  companyId: string;
  interviewLoopId: string;
}
export function InterviewLoopAddRecruiterButton(
  props: InterviewLoopAddRecruiterButtonProps
) {
  let callback = function (user: User): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      return InterviewLoopAPI.addRecruiter(
        props.companyId,
        props.interviewLoopId,
        user
      ).then((response) => {
        resolve();
        reloadWindowCallback();
      });
    });
  };

  let key =
    "il-" + props.companyId + "-" + props.interviewLoopId + "recruiter-add";

  return (
    <div>
      {/* <UserPickerViewDefaultDesign
        callback={callback}
        uniqueKey={key}
        initiatorButtonText={{ normal: "Add Recuiter", submitting: "Adding" }}
      /> */}
    </div>
  );
}

export class InterviewLoopDetailsPage extends React.Component<
  InterviewLoopDetailsPageProps,
  InterviewLoopDetailsPageState
> {
  componentDidMount() {
    InterviewLoopAPI.fetchInterviewLoop(
      this.props.companyId,
      this.props.interviewLoopId
    )
      .then((response) => {
        this.setState({ interviewLoop: response });
      })
      .catch((error) => {});

    InterviewRoundAPI.getInterviewRoundFetchAll(
      this.props.companyId,
      this.props.interviewLoopId
    )
      .then((interviewRounds) => {
        this.setState({ interviewRounds });
      })
      .catch((error) => {});
  }

  interviewRoundAddCallback() {
    window.location.reload();
  }

  render() {
    let interviewLoopAttachmentsEndpoint =
      apiLinkConstants.INTERVIEW_ROUND_ATTACHMENTS_ADD_OR_UPDATE.replace(
        "{companyId}",
        this.props.companyId
      ).replace("{interviewLoopId}", this.props.interviewLoopId);
    return (
      <div className="main-container">
        {this.state && this.state.interviewLoop && (
          <div className="row mb-4">
            <div className="col-md-6">
              <h1 className="h4">
                {UserHelpers.getUserFullName(
                  this.state.interviewLoop.candidate
                )}
                's Interview Loop
              </h1>
              <small className="text-small">
                Created by:{" "}
                {UserHelpers.getUserFullName(
                  this.state.interviewLoop.createdBy
                )}
                ,{" "}
                {this.state.interviewLoop.createdAt ? (
                  <TimeAgo datetime={this.state.interviewLoop.createdAt} />
                ) : null}
              </small>
              <p>
                <div className="pt-2 hstack">
                  Candidate Email:{" "}
                  {this.state.interviewLoop.candidate.contactDetails?.email}
                </div>
                <div className="pt-2 hstack">
                  Candidate Phone:{" "}
                  {this.state.interviewLoop.candidate.contactDetails?.phone}
                </div>
                <div className="pt-2 hstack">
                  Resume: &nbsp;{" "}
                  <AttachmentView
                    attachment={this.state.interviewLoop.resume}
                    attachmentDisplayName="Resume"
                    attachmentsUpdateEndpoint={interviewLoopAttachmentsEndpoint}
                    updateCallback={reloadWindowPromise}
                    uniqueKey={
                      "il-" +
                      this.props.companyId +
                      "-" +
                      this.props.interviewLoopId +
                      "-candidate-resume"
                    }
                  />
                </div>
              </p>
            </div>

            <div className="col-md-6">
              Recruiters in the loop:
              {this.state.interviewLoop.recruiters &&
                this.state.interviewLoop.recruiters.map((recruiter, index) => {
                  return (
                    <>
                      <span className="badge bg-secondary ml-2">
                        {UserHelpers.getUserFullName(recruiter)}
                      </span>
                    </>
                  );
                })}
              <div className="mt-2">
                <InterviewLoopAddRecruiterButton {...this.props} />
              </div>
            </div>
          </div>
        )}
        <div></div>
        <div>
          {this.state && this.state.interviewRounds && (
            <div className="card">
              <div className="card-header">
                <div className="hstack"></div>
                <div className="me-auto">Rounds in this interview loop</div>
                <div className="ms-auto"></div>
              </div>
              <div className="card-body">
                <AddInterviewRoundView
                  {...this.props}
                  callback={this.interviewRoundAddCallback}
                />
                <div className="pt-4">
                  {this.state.interviewRounds.length > 0 ? (
                    <InterviewRoundList
                      interviewRounds={this.state.interviewRounds}
                      companyId={this.props.companyId}
                      uniqueKey={"c-" + this.props.companyId + "-rounds"}
                    />
                  ) : (
                    "No rounds found. Start adding new"
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
