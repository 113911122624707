import React from "react";
import { LinkClickHandler } from "../../links/Link.ClickHandler";
import { PathStep } from "./Offering.Path.Model";
import { PathStepHelper } from "./PathStepHelper";

interface PathContentPreviousNextNavigationProps {
  steps: PathStep[];
  selectedStep?: PathStep;
  onStepSelect: (step: PathStep) => void;
}

export function PathContentPreviousNextNavigation(
  props: PathContentPreviousNextNavigationProps
) {
  const nextStep =
    props.selectedStep &&
    PathStepHelper.nextStep(props.steps, props.selectedStep);
  const prevStep =
    props.selectedStep &&
    PathStepHelper.prevStep(props.steps, props.selectedStep);
  return (
    <div
      className={`d-flex justify-content-between fs-6 bold ${
        (nextStep || prevStep) && " py-2"
      }`}
    >
      <div>
        {prevStep && (
          <LinkClickHandler
            className="text-dark"
            onClick={() => {
              props.onStepSelect(prevStep);
            }}
          >
            {"< "} {prevStep.dl ?? "Untitled Step"}
          </LinkClickHandler>
        )}
      </div>
      <div>
        {nextStep && (
          <LinkClickHandler
            className="text-dark"
            onClick={() => {
              props.onStepSelect(nextStep!);
            }}
          >
            {nextStep.dl ?? "Untitled Step"}
            {" >"}
          </LinkClickHandler>
        )}
      </div>
    </div>
  );
}
