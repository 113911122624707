import React, { useRef, useState } from "react";
import { useAlert } from "react-alert";
import {
  EBSelectOption,
  EBSelectOptionDataType,
  EBSelectV2,
} from "../components/common/Form/EBSelectV2";
import { SelectorProps } from "../components/common/Selectors";
import { ApiErrorHelper } from "../helpers/ApiError.helper";
import { Topic, TopicType } from "./Topic.model";
import { TopicAPI } from "./TopicAPI";
import { TopicTypeButtonSelector } from "./TopicType.ButtonSelector";

interface TopicSelectorProps extends SelectorProps {
  topics: Topic[];
  isMulti?: boolean;
}

export interface TopicSelectorOption extends EBSelectOptionDataType, Topic {}

export function TopicSelector(props: TopicSelectorProps) {
  let [showTopicTypeSelector, setShowTopicTypeSelector] = useState<boolean>();
  const onTopicTypeSelect = useRef<(type: TopicType) => void>();
  let alert = useAlert();

  function onCreate(
    title: string
  ): Promise<EBSelectOption<TopicSelectorOption>> {
    return new Promise<EBSelectOption<TopicSelectorOption>>(
      (resolve, reject) => {
        onTopicTypeSelect.current = (type: TopicType): void => {
          setShowTopicTypeSelector(false);
          TopicAPI.createTopic(title, type)
            .then((topic) => {
              resolve({
                label: topic.dl,
                value: `${topic.topicId.type}-${topic.topicId.id}`,
                data: {
                  ...topic,
                  selectOptionValue: `${topic.topicId.type}-${topic.topicId.id}`,
                },
              });
            })
            .catch((error) => {
              let displayError = ApiErrorHelper.getDisplayErrorMessage(error);
              alert.error(displayError);
              reject(error);
            });
        };
        setShowTopicTypeSelector(true);
      }
    );
  }
  let options: EBSelectOption<TopicSelectorOption>[] = [];

  props.topics.forEach((topic: Topic) => {
    const topicId = topic.topicId;
    options.push({
      label: topic.dl,
      value: `${topicId.type}-${topicId.id}`,
      data: {
        ...topic,
        selectOptionValue: `${topicId.type}-${topicId.id}`,
      },
    });
  });

  return (
    <div>
      {options && (
        <EBSelectV2
          placeholder={props.placeholder}
          label={props.label ?? "Topic:"}
          name={`${props.name}`}
          required={props.required}
          options={options}
          disabled={props.disabled}
          isMulti={props.isMulti}
          onCreate={onCreate}
          isDataEqual={(d1: TopicSelectorOption, d2: TopicSelectorOption) => {
            return (
              d1.topicId.type === d2.topicId.type &&
              d1.topicId.id === d2.topicId.id
            );
          }}
        />
      )}
      {showTopicTypeSelector && onTopicTypeSelect.current && (
        <div className="mt-2 pt-2 px-2 bg-light">
          What best describes this topic?
          <TopicTypeButtonSelector
            onSelect={onTopicTypeSelect.current}
            name={""}
          />
        </div>
      )}
    </div>
  );
}
