import { Api, ApiMethod } from "../../components/common/ApiLinkedButton";
import { admiApiLinkConstants, apiLinkConstants } from "../../constants";
import { WithdrawalResponse } from "./Withdrawal.model";

export class WithDrawalAdminApi {
  public static getAllWithdrawals(): Promise<WithdrawalResponse> {
    let api = admiApiLinkConstants.API_ADMIN_WITHDRAWALS_ALL;
    return new Api<void, WithdrawalResponse>(api, ApiMethod.GET).execute();
  }
}
