import React, { useEffect, useState } from "react";
import { Booking } from "../../booking/Booking.model";
import { Loader } from "../../components/common/Loader.component";
import { Center } from "../../components/common/layout/Center.component";
import { User, UserBasicDetails } from "../../user/User";
import { OfferingPath } from "../Offering";
import { PathStep } from "./Offering.Path.Model";
import { OfferingPathStepAPI } from "./Offering.PathStep.api";
import { OfferingsPathStepView } from "./Offerings.Path.Step.View";
import { OfferingsPathStepsEditSidebar } from "./Offerings.Path.Steps.Edit.Sidebar";
import { OfferingsPathStepsPageContentLayout } from "./Offerings.Path.Steps.PageContent.Layout";
import { PathContentPreviousNextNavigation } from "./Path.Content.PreviousNext.Navigation";

interface OfferingsPathStepsViewPageContentProps {
  user: User;
  offeringId: string;
  offering?: OfferingPath;
  postedById: string;
  sidebarTitle?: string;
  booking?: Booking;
}

export function OfferingsPathStepsViewPageContent(
  props: OfferingsPathStepsViewPageContentProps
) {
  let [selectedStep, setSelectedStep] = useState<PathStep>();
  let [steps, setSteps] = useState<PathStep[]>([]);
  let [loading, setLoading] = useState<boolean>();

  function fetchList() {
    setLoading(true);
    OfferingPathStepAPI.publicList(props.user.basicDetails.id, props.offeringId)
      .execute()
      .then((steps) => {
        setSteps(steps);
        if (steps && steps.length > 0) {
          setSelectedStep(steps[0]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchList();
  }, []);

  function onStepSelect(step: PathStep) {
    setSelectedStep(step);
  }

  return (
    <span className="OfferingsPathStepsViewPageContent">
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!loading && (
        <>
          <OfferingsPathStepsPageContentLayout
            booking={props.booking}
            liu={props.user}
            offeringId={props.offeringId}
            offering={props.offering?.commonDetails}
            sidebar={
              <OfferingsPathStepsEditSidebar
                user={props.user}
                offeringId={props.offeringId}
                steps={steps}
                onStepSelect={onStepSelect}
                selectedStep={selectedStep}
                showCompleted={true}
                contextMenuItems={[
                  {
                    dl: "Mark Complete",
                    shouldShow: (step) => {
                      return !step.completedAt;
                    },
                    onClick: (step) => {
                      return OfferingPathStepAPI.markComplete(
                        props.postedById,
                        props.offeringId,
                        step.id
                      ).then(() => {
                        step.completedAt = new Date().toString();
                        setSteps([...steps]);
                        if (selectedStep && selectedStep.id === step.id) {
                          setSelectedStep(undefined);
                          setSelectedStep(step);
                        }
                      });
                    },
                  },
                  {
                    dl: "Mark Incomplete",
                    shouldShow: (step) => {
                      return !!step.completedAt;
                    },
                    onClick: (step) => {
                      return OfferingPathStepAPI.markIncomplete(
                        props.postedById,
                        props.offeringId,
                        step.id
                      ).then(() => {
                        step.completedAt = undefined;
                        setSteps([...steps]);
                        if (selectedStep && selectedStep.id === step.id) {
                          setSelectedStep(undefined);
                          setSelectedStep(step);
                        }
                      });
                    },
                  },
                ]}
              />
            }
            content={
              <>
                {selectedStep && (
                  <OfferingsPathStepView
                    inputStep={selectedStep}
                    user={props.user}
                    offeringId={props.offeringId}
                    onStepSelect={onStepSelect}
                  />
                )}
                {!selectedStep && (
                  <>
                    <div className="alert alert-secondary h3 m-4" role="alert">
                      No step selected. Please start by selecting a step from
                      the side panel.
                    </div>
                  </>
                )}
              </>
            }
            contentFooter={
              <PathContentPreviousNextNavigation
                steps={steps}
                selectedStep={selectedStep}
                onStepSelect={onStepSelect}
              />
            }
          />
        </>
      )}
    </span>
  );
}
