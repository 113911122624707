import {
  Mode,
  ModeBasedString,
} from "../components/common/Form/model/mode.model";

export class ModeHelpers {
  public static getString(
    mode: Mode,
    modeBasedString?: ModeBasedString
  ): string {
    if (!modeBasedString) {
      return "";
    }
    if (mode == Mode.EDIT && modeBasedString.edit) {
      return modeBasedString.edit;
    }
    return modeBasedString.view;
  }
}
