import React from "react";
import { AdminInterviewExperiencesList } from "./AdminInterviewExperiencesList";

export class AdminInterviewExperiencesContent extends React.Component {
  render() {
    return (
      <div className="main-container">
        <div>
          <AdminInterviewExperiencesList />
        </div>
      </div>
    );
  }
}
