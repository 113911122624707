import React, { useEffect, useState } from "react";
import { Label } from "../../../components/common/Form/elements/FormInputLabel";
import { VideoIcon } from "../../../icon/Video.Icon";
import { AttachmentTargetType } from "../../../model/AttachmentTargetType";
import { Skill } from "../../../model/Skill";
import { EditOfferingOptionsComponent } from "../../../offerings/EditOfferingOptions.component";
import {
  IOfferingV2,
  OfferingFetchContext,
  OfferingTypeName,
  OfferingVideoSession,
  SessionType,
} from "../../../offerings/Offering";
import { OfferingAddOrEditForm } from "../../../offerings/Offering.AddOrEdit.Form";
import { OfferingHelper } from "../../../offerings/Offering.Helper";
import { OfferingInputSection } from "../../../offerings/Offering.Input.Section";
import { OfferingAPI } from "../../../offerings/Offering.api";
import { SessionTypeSelector } from "../../../offerings/SessionTyoeSelector.component";
import { OfferingVideoSessionAPI } from "../../../offerings/video-session/Offering.VideoSession.Api";
import { SkillAPI } from "../../../skills/SkillAPI";
import { ContentItemDataOfferingVideoSession } from "../../Content.model";
import { ContentItemInputProps } from "../ContentItem.model";

export function ContentItemInputOfferingVideoSession(
  props: ContentItemInputProps
) {
  let [reRenderCount, setRenderCount] = useState<number>(0);

  function reRender() {
    setRenderCount(reRenderCount + 1);
  }
  let data: ContentItemDataOfferingVideoSession = props.item.data;
  let [offering, setOffering] = useState<IOfferingV2>();
  let [loading, setLoading] = useState<boolean>(false);

  function updateStepData(offering: IOfferingV2) {
    if (data) {
      data.offeringId = offering.id;
    } else {
      props.item.data = { offeringId: offering.id };
    }
  }

  let [sessionTypes, setSessionTypes] = useState<SessionType[]>([]);
  let [skills, setSkills] = useState<Skill[]>([]);

  useEffect(() => {
    OfferingVideoSessionAPI.getVideoSessionTypes().then((sessionTypes) => {
      setSessionTypes(sessionTypes);
    });

    SkillAPI.fetchAll().then((skills) => {
      setSkills(skills);
    });
  }, []);

  useEffect(() => {
    if (data && data.offeringId) {
      setLoading(true);
      OfferingAPI.get(
        OfferingTypeName.VIDEO_SESSION,
        data.offeringId,
        OfferingFetchContext.EDIT
      )
        .executeV2()
        .then((offering) => {
          setOffering(offering as OfferingVideoSession);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <div className="ContentItemInputOfferingVideoSession p-3">
      <h6>
        <VideoIcon /> Configure 1:1 Call:
      </h6>

      <div className="">
        {props.user && data && data.offeringId && offering && (
          <OfferingAddOrEditForm
            user={props.user}
            hideOptionForHiddenInListing={true}
            hideDetailPageMetadata={true}
            takeMaxNumBookingsPerUser={true}
            onSave={(offering) => {
              return OfferingAPI.update(
                AttachmentTargetType.OFFERING_VIDEO_SESSION,
                offering.id
              )
                .executeV2(offering)
                .then((createdOffering) => {
                  updateStepData(createdOffering);
                  return props.onTriggerSave().then(() => {
                    return createdOffering;
                  });
                })
                .then((createdOffering) => {
                  setOffering(createdOffering);
                  return createdOffering;
                });
            }}
            existingOffering={offering}
            titleSideNode={
              <OfferingInputSection
                title={<Label label={"Type:"} required={true} />}
                content={
                  <SessionTypeSelector
                    all={sessionTypes}
                    name={`sessionType`}
                    required={true}
                    fieldTitle=""
                  />
                }
              />
            }
            postTitleNode={
              <OfferingInputSection
                title={<Label label={"Options:"} required={true} />}
                content={
                  <EditOfferingOptionsComponent
                    offeringSessionTypeFieldName={"sessionType"}
                    name={`commonDetails.options`}
                    skills={skills}
                    sessionTypes={sessionTypes}
                    user={props.user}
                  />
                }
              />
            }
          />
        )}
        {props.user && (!data || !data.offeringId) && (
          <OfferingAddOrEditForm
            user={props.user}
            hideOptionForHiddenInListing={true}
            hideDetailPageMetadata={true}
            takeMaxNumBookingsPerUser={true}
            onSave={(offering) => {
              return OfferingAPI.add(
                AttachmentTargetType.OFFERING_VIDEO_SESSION
              )
                .executeV2(offering)
                .then((createdOffering) => {
                  updateStepData(createdOffering);
                  return props.onTriggerSave().then(() => {
                    return createdOffering;
                  });
                })
                .then((createdOffering) => {
                  setOffering(createdOffering);
                  return createdOffering;
                });
            }}
            existingOffering={OfferingHelper.getInitialOfferingVs(
              OfferingTypeName.VIDEO_SESSION,
              props.user,
              {targetType: AttachmentTargetType.OFFERING, targetId: props.target.targetType + "-" + props.target.targetId} // TODO: Fix post reaching home later.
            )}
            getOfferingSpecificFormikInputs={(offering: IOfferingV2) => <></>}
            titleSideNode={
              <OfferingInputSection
                title={<Label label={"Type:"} required={true} />}
                content={
                  <SessionTypeSelector
                    all={sessionTypes}
                    name={`sessionType`}
                    required={true}
                    fieldTitle=""
                  />
                }
              />
            }
            postTitleNode={
              <OfferingInputSection
                title={<Label label={"Options:"} required={true} />}
                content={
                  <EditOfferingOptionsComponent
                    offeringSessionTypeFieldName={"sessionType"}
                    name={`commonDetails.options`}
                    skills={skills}
                    sessionTypes={sessionTypes}
                    user={props.user}
                  />
                }
              />
            }
          />
        )}
      </div>
    </div>
  );
}
