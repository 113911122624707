import { CommunityGroup } from "../community/CommunityGroup.Model";
import { ContentMetrics } from "../content/Content.model";
import { FormTemplateResponse } from "../form/model/formTemplate.model";
import { AttachmentV1 } from "../model/Attachment";
import { Count } from "../model/Count.model";
import { AssetLocation } from "../model/Picture";
import { Price } from "../model/Price";
import { RatingsSummary } from "../model/Reviews";
import { Skill } from "../model/Skill";
import { Target } from "../model/target.model";
import { UserBasicDetails } from "../user/User";
import { OfferingDetailPageLayoutType } from "./layouts/Offering.DetailPage.Layout.Model";

export interface AgendaItem {
  dl: string;
  contentMetrics?: ContentMetrics;
  items?: AgendaItem[];
}

export interface Agenda {
  items?: AgendaItem[];
}

export interface OfferingGenericMetadata {
  targetAudiences?: GenericMetadataInfo[];
  prerequisites?: GenericMetadataInfo[];
  learningObjectives?: GenericMetadataInfo[];
}

export interface GenericMetadataInfo {
  dl: string;
  dd?: string;
}

export interface OfferingCommonDetails {
  oldId: string;
  id: string;
  displayId: number;
  type: OfferingType;
  target: Target;
  postedBy: UserBasicDetails;
  dl: string;
  detailShort: string;
  detailLong: string;
  agenda?: Agenda;
  metaImage: AssetLocation;
  bannerImage?: AssetLocation;
  thumbnailImage?: AssetLocation;
  genericMetadata?: OfferingGenericMetadata;
  preBookingFormTemplate?: FormTemplateResponse;

  isHiddenFromListing: boolean;
  hiddenFromListingAt?: string;

  linkedTarget: Target;
  bookingLimitPerUser?: Count;
  bookingLimitPerUserAvailable?: boolean;
  bookingLimitPerUserConsumed?: number;

  linkedCommunityId?: string;

  publishedAt?: string;
  isPublished: boolean;

  deletedAt: string;

  url: string;
  ratingsSummary?: RatingsSummary;
  layout?: OfferingDetailPageLayoutType;

  options: OfferingOption[];
  optionsType: OfferingOptionType;
  price: Price;
}

/**
 * @deprecated Use IOfferingV2 insteads
 */
export interface Offering {
  id: string;
  type: OfferingType;
  price: Price;
  detail: string;
  dl: string;
  isEnabled: boolean;
  isHiddenFromListing: boolean;
  disabledAt: string;
  deletedAt: string;
  postedBy: UserBasicDetails;
  url: string;
  metaImage: AssetLocation;
  bannerImage?: AssetLocation;
  thumbnailImage?: AssetLocation;
  publishedAt?: string;
}

export interface OfferingOptionsBased {}

/**
 * @deprecated Use AttachmentTargetType instead
 */
export enum OfferingTypeName {
  VIDEO_SESSION = "VIDEO_SESSION",
  TEXT_QUERY = "TEXT_QUERY",
  DIGITAL_PRODUCT = "DIGITAL_PRODUCT",
  PATH = "PATH",
  EVENT = "EVENT",
}

/**
 * @deprecated Use Target instead
 */
export interface OfferingType {
  typeName: OfferingTypeName;
  dl: string;
  dp: AssetLocation;
}

export interface OfferingTextQuery extends IOfferingV2 {
  textQuestionTitleForMentee: string;
  sessionType: SessionType;
}

export interface OfferingVideoSession extends IOfferingV2 {
  sessionType: SessionType;
}

export interface OfferingDigitalProduct extends IOfferingV2 {
  attachments: AttachmentV1[];
}

export interface IOfferingV2 {
  id: string;
  commonDetails: OfferingCommonDetails;
  url: string;
  tags?: string[];
}

export interface OfferingPath extends IOfferingV2 {
  contentEditUrl?: string;
  contentViewUrl?: string;
  communityUrl?: string;
}

export interface OfferingOption {
  id?: string;
  type: OfferingOptionType;
  data: OfferingOptionData;
}

export interface OfferingOptionData {
  id?: string;
  isEnabled: boolean;
  deletedAt?: string;
  price: Price;
}

export enum OfferingOptionType {
  DURATION_SKILL = "DURATION_SKILL",
  DURATION = "DURATION",
  SLA = "SLA",
  DEFAULT = "DEFAULT",
}

export interface OfferingOptionDurationSkillData extends OfferingOptionData {
  durationInSeconds: number;
  skill: Skill;
}

export interface OfferingOptionDurationData extends OfferingOptionData {
  durationInSeconds: number;
}

export interface OfferingOptionSlaData extends OfferingOptionData {
  responseSlaInSecs: number;
  displayResponseSla: string;
}

export interface SessionType {
  id: string;
  displayTitle: string;
  offeringOptionType: OfferingOptionType;
  offeringTypeEnum: OfferingTypeName;
}

export interface Offerings {
  videoSessions?: OfferingVideoSession[];
  offlineQueries?: OfferingTextQuery[];
  products?: OfferingDigitalProduct[];
  paths?: OfferingPath[];
}

export interface OfferingCommunity extends IOfferingV2 {
  groups: CommunityGroup[];
}

export enum OfferingFetchContext {
  EDIT = "EDIT",
  PUBLIC = "PUBLIC",
}
