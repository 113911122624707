import { useField } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { EngineName } from "../Global.Names";
import { Loader } from "../components/common/Loader.component";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { Center } from "../components/common/layout/Center.component";
import { publicLinkConstants } from "../constants";
import { FormikHelpers } from "../helpers/Formik.Helpers";
import { LinkClickHandler } from "../links/Link.ClickHandler";
import { CalendarApi } from "./Calendar.api";
import { SchedulingPreference, TimestampSlot } from "./Calendar.model";

interface UserAvailabilityDateTimeSlotsProps extends FormikInputParams {
  userIdToShow: string;
  durationMins: number;
  onTimeSlotChange: (slot?: TimestampSlot) => void;
}

export function UserAvailabilityDateTimeSlotsComponent(
  props: UserAvailabilityDateTimeSlotsProps
) {
  const [field] = useField({
    ...props,
    validate: (value: any) => {
      if (props.required && !value) {
        return "Required";
      }
    },
  });

  let [loading, setLoading] = useState<boolean>();
  let [schedulingPreference, setSchedulingPreference] =
    useState<SchedulingPreference>();
  let [slots, setSlots] = useState<UserAvailabilityDisplayDate[]>();
  let [selectedDate, setSelectedDate] = useState<UserAvailabilityDisplayDate>();
  let [selectedTime, setSelectedTime] = useState<UserAvailabilityDisplayTime>();

  function onDateChange(date: UserAvailabilityDisplayDate) {
    setSelectedDate(date);
    FormikHelpers.updateField(field, props.name, undefined);
    setSelectedTime(undefined);
    props.onTimeSlotChange(undefined);
  }

  function onTimeSlotChange(time: UserAvailabilityDisplayTime) {
    FormikHelpers.updateField(field, props.name, time.dataForApi);
    setSelectedTime(time);
    props.onTimeSlotChange(time.dataForApi);
  }

  function initWithFirst(slots: UserAvailabilityDisplayDate[]) {
    if (
      !slots ||
      slots.length == 0 ||
      !slots[0].times ||
      slots[0].times.length == 0
    ) {
      return;
    }
    setSelectedDate(slots[0]);
    onTimeSlotChange(slots[0].times[0]);
  }

  useEffect(() => {
    setLoading(true);
    setSelectedDate(undefined);
    setSelectedTime(undefined);
    props.onTimeSlotChange(undefined);
    CalendarApi.getUserAvailableDateTimeSlots(
      props.userIdToShow,
      props.durationMins
    )
      .then((slotsResponse) => {
        let processedSlots = processDataInUserDisplayableFormat(
          slotsResponse.slots
        );
        setSlots(processedSlots);
        initWithFirst(processedSlots);
        setSchedulingPreference(slotsResponse.schedulingPreference);
        if (slotsResponse.schedulingPreference == SchedulingPreference.MANUAL) {
          props.onTimeSlotChange({} as TimestampSlot);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.durationMins]);
  return (
    <div className="UserAvailabilityDateTimeSlots pr-4 mob-mt-4 card-body bg-light">
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!loading && schedulingPreference == SchedulingPreference.MANUAL && (
        <div>
          This expert has decided to handle the scheduling of sessions manually
          by themselves. This works like follows:{" "}
          <ul>
            <li>
              Post booking of the session, a mail thread will be started between
              you and the expert.
            </li>
            <li>
              On that mail, you can discuss various details like session slot,
              agenda etc with the expert
            </li>
            <li>
              Post finalizing the slot, expert will update the details alongwith
              the meeting link in the session page link. You will also get a
              notification when that is done.
            </li>
            <li>
              Then the usual process follow where you have to complete the
              session by joining the meeting and filling the feedback post it.
            </li>
          </ul>
          Note: Please feel free to reach out to us through{" "}
          <a href={publicLinkConstants.HELP_LINK} target="_blank">
            help
          </a>{" "}
          in case you have any questions.
        </div>
      )}
      {!loading && slots && (
        <div>
          {schedulingPreference == SchedulingPreference.CALENDAR &&
            slots.length <= 0 && (
              <>
                No available slots found. If you know the
                {EngineName.SENTENCE_MID}, please contact them to add slots. If
                you don't have any means, let us know through the help section
                so that we can reach out to them to add slots.
              </>
            )}
          {schedulingPreference == SchedulingPreference.CALENDAR &&
            slots.length > 0 && (
              <>
                <div className="alert alert-warning">
                  Below slots are shown in <b>{moment().format("Z")}</b>{" "}
                  timezone.
                </div>
                Pick a date:
                <div className="row flex-nowrap overflow-auto">
                  {slots.map((slot, index) => (
                    <div className="col-auto" key={index}>
                      <LinkClickHandler
                        className="text-dark"
                        onClick={() => {
                          onDateChange(slot);
                        }}
                      >
                        <div
                          className={`card card-body p-3 ${
                            slot.date === selectedDate?.date &&
                            "bg-secondary text-white"
                          }`}
                        >
                          {slot.date}
                        </div>
                      </LinkClickHandler>
                    </div>
                  ))}
                </div>
                {selectedDate && (
                  <div
                    className="timeSelect mt-4 pl-2"
                    style={{ maxHeight: "200px", overflow: "auto" }}
                  >
                    Pick a time:
                    <div className="row">
                      {selectedDate.times.map((time, index) => (
                        <div className="col-auto p-1" key={index}>
                          <LinkClickHandler
                            onClick={() => {
                              onTimeSlotChange(time);
                            }}
                            className="text-dark"
                          >
                            <div
                              className={`p-2 border mb-1 ${
                                time.time === selectedTime?.time
                                  ? "bg-secondary text-white"
                                  : "bg-white"
                              }`}
                            >
                              {time.time}
                            </div>
                          </LinkClickHandler>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
        </div>
      )}
    </div>
  );
}

function processDataInUserDisplayableFormat(
  slots: TimestampSlot[]
): UserAvailabilityDisplayDate[] {
  if (!slots) {
    return [];
  }
  let displayData: UserAvailabilityDisplayDate[] = [];

  let curDisplayData: UserAvailabilityDisplayDate | undefined = undefined;
  for (let slot of slots) {
    let startDate = moment.unix(slot.start).format("ddd[,] MMM DD").toString();
    let startTime = moment.unix(slot.start).format("HH:mm").toString();

    if (curDisplayData == null) {
      curDisplayData = {
        date: startDate,
        times: [{ time: startTime, dataForApi: slot }],
      };
    } else if (curDisplayData.date == startDate) {
      curDisplayData.times.push({ time: startTime, dataForApi: slot });
    } else {
      displayData.push(curDisplayData);
      curDisplayData = {
        date: startDate,
        times: [{ time: startTime, dataForApi: slot }],
      };
    }
  }
  return displayData;
}

interface UserAvailabilityDisplayTime {
  time: string;
  dataForApi: TimestampSlot;
}

interface UserAvailabilityDisplayDate {
  date: string;
  times: UserAvailabilityDisplayTime[];
}
