import React, { useEffect, useState } from "react";
import { ConfirmationModal } from "../../components/common/Confirmation.Modal";
import { Loader } from "../../components/common/Loader.component";
import { Center } from "../../components/common/layout/Center.component";
import { User, UserBasicDetails } from "../../user/User";
import { OfferingPath } from "../Offering";
import { MoveDirectionEnum, PathStep } from "./Offering.Path.Model";
import { OfferingPathStepAPI } from "./Offering.PathStep.api";
import { OfferingsPathStepEdit } from "./Offerings.Path.Step.Edit";
import { OfferingsPathStepsEditSidebar } from "./Offerings.Path.Steps.Edit.Sidebar";
import { OfferingsPathStepsPageContentLayout } from "./Offerings.Path.Steps.PageContent.Layout";
import { PathContentPreviousNextNavigation } from "./Path.Content.PreviousNext.Navigation";
import { PathStepHelper } from "./PathStepHelper";

interface OfferingsPathStepsEditPageContentProps {
  user: User;
  offeringId: string;
  offering: OfferingPath;
  sidebarTitle?: string;
}

export function OfferingsPathStepsEditPageContent(
  props: OfferingsPathStepsEditPageContentProps
) {
  let [dirty, setDirty] = useState<boolean>();
  let [selectedStep, setSelectedStep] = useState<PathStep>();
  let [steps, setSteps] = useState<PathStep[]>([]);
  let [loading, setLoading] = useState<boolean>();
  let [saveDirtyChanges, setSaveDirtyChanges] = useState<boolean>(false);
  let [dirtyStep, setDirtyStep] = useState<PathStep>();
  let [deleteStep, setDeleteStep] = useState<PathStep>();

  function fetchList() {
    setLoading(true);
    OfferingPathStepAPI.editList(props.user.basicDetails.id, props.offeringId)
      .execute()
      .then((steps) => {
        setSteps(steps);
        if (steps && steps.length > 0) {
          setSelectedStep(steps[0]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchList();
  }, []);

  function onStepSelect(step: PathStep) {
    if (dirty) {
      document.getElementById("unsavedChangesPopupButton")?.click();
      setDirtyStep(step);
      return;
    }
    setSelectedStep(step);
  }

  function onTopStepAdd(newStep: PathStep) {
    steps.push(newStep);
    setSteps([...steps]);
  }
  function onStepAdd(newStep: PathStep, parentStep?: PathStep) {
    if (parentStep) {
      if (!parentStep.steps) {
        parentStep.steps = [];
      }
      parentStep.steps.push(newStep);
      setSteps([...steps]);
    } else {
      onTopStepAdd(newStep);
    }
  }

  function onStepDelete(step: PathStep) {
    if (step.id == selectedStep?.id) {
      setDirty(false);
    }
    document.getElementById("DeleteSetupPopupButton")?.click();
    setDeleteStep(step);
  }

  function onStepUpdate(updatedStep: PathStep) {
    PathStepHelper.updateStep(steps, updatedStep);
    setSteps([...steps]);

    console.log("Clearing dirty state");
    setDirty(false);
  }

  function onStepMoveUp(step: PathStep) {
    PathStepHelper.moveStep(steps, step, MoveDirectionEnum.UP);
    setSteps([...steps]);
  }

  function onStepMoveDown(step: PathStep) {
    PathStepHelper.moveStep(steps, step, MoveDirectionEnum.DOWN);
    setSteps([...steps]);
  }

  return (
    <span className="OfferingsPathStepsEditPageContent">
      <ConfirmationModal
        buttonId="unsavedChangesPopupButton"
        modalId={"unsaved-popup"}
        modalBody={
          <div>
            Unsaved Changes: You have unsaved modifications. Do you want to stay
            and save changes, or continue without saving?
          </div>
        }
        primaryButtonProps={{
          className: "btn mr-2 btn-secondary",
          content: {
            normal: "Continue",
          },
          onClick: () => {
            setDirty(false);
            setSelectedStep(dirtyStep);
          },
          otherProps: {
            "data-bs-toggle": "modal",
            "data-bs-target": "#unsaved-popup",
          },
        }}
        closeButtonTitle="Stay"
      ></ConfirmationModal>
      <ConfirmationModal
        buttonId="DeleteSetupPopupButton"
        modalId={"deletestep-popup"}
        modalBody={<div>You want to delete this step?</div>}
        primaryButtonProps={{
          className: "btn mr-2 btn-secondary",
          content: {
            normal: "Delete",
          },
          onClick: () => {
            if (deleteStep) {
              OfferingPathStepAPI.delete(
                props.user.basicDetails.id,
                props.offeringId,
                deleteStep.id + ""
              )
                .execute()
                .then(() => {
                  setDirty(false);
                  if (deleteStep) {
                    let updatedSteps = PathStepHelper.deleteStep(
                      steps,
                      deleteStep
                    );
                    setSteps(updatedSteps);
                    if (selectedStep?.id == deleteStep.id) {
                      setSelectedStep(undefined);
                    }
                  }
                });
            }
          },
          otherProps: {
            "data-bs-toggle": "modal",
            "data-bs-target": "#deletestep-popup",
          },
        }}
      ></ConfirmationModal>
      {loading && (
        <Center className="p-4">
          <Loader />
        </Center>
      )}
      {!loading && (
        <>
          <OfferingsPathStepsPageContentLayout
            liu={props.user}
            offeringId={props.offeringId}
            offering={props.offering.commonDetails}
            sidebar={
              <OfferingsPathStepsEditSidebar
                user={props.user}
                offeringId={props.offeringId}
                steps={steps}
                selectedStep={selectedStep}
                onStepSelect={onStepSelect}
                onStepAdd={onStepAdd}
                onStepDelete={onStepDelete}
                onStepMoveUp={onStepMoveUp}
                onStepMoveDown={onStepMoveDown}
              />
            }
            content={
              <>
                {selectedStep && (
                  <OfferingsPathStepEdit
                    inputStep={selectedStep}
                    user={props.user}
                    offeringId={props.offeringId}
                    offering={props.offering}
                    onStepUpdate={onStepUpdate}
                    saveDirtyChanges={saveDirtyChanges}
                    onChange={() => {
                      setDirty(true);
                      console.log("Marking Dirty");
                      return Promise.resolve();
                    }}
                  />
                )}
                {!selectedStep && (
                  <>
                    <div className="alert alert-secondary h3 m-4" role="alert">
                      No step selected. Please select a step from the side panel
                      to edit it.
                    </div>
                  </>
                )}
              </>
            }
            contentFooter={
              <PathContentPreviousNextNavigation
                steps={steps}
                selectedStep={selectedStep}
                onStepSelect={(step: PathStep) => {
                  onStepSelect(step);
                }}
              />
            }
          />
        </>
      )}
    </span>
  );
}
