import { useField } from "formik";
import React, { useState } from "react";
import { FormikHelpers } from "../../../helpers/Formik.Helpers";
import { Count, CountType } from "../../../model/Count.model";
import { FormikInputParams } from "./FormikInputParams.model";
import {
  RadioButtonGroupComponentV2,
  RadioButtonGroupItem,
} from "./RadioButtonGroup.component";
import { TextInputV3 } from "./TextV3.Input";

export interface CountLimitedOption {
  value: number;
  dl: string;
}

interface FormikCountInputProps extends FormikInputParams {
  limitedOptions: CountLimitedOption[];
  supportUnlimited?: boolean;
  supportCustom?: boolean;
}

export function FormikCountInput(props: FormikCountInputProps) {
  const [field, meta, helpers] = useField({
    ...props,
    validate: (value: any) => {
      if (props.required && !value) {
        return "Required";
      }
    },
  });

  function setLimtiedFieldValue(newValue: number) {
    setFieldValue({
      type: CountType.LIMITED,
      value: newValue,
    });
  }

  function setFieldValue(newValue: Count) {
    FormikHelpers.updateField(field, props.name, newValue);
  }

  interface CountCallerContext {
    type: string;
    value: number;
  }

  function onOptionChange(item: RadioButtonGroupItem, index: number) {
    let callerContext: CountCallerContext = item.callerContext;

    if (callerContext.type == "custom") {
      setCustomSelected(true);
    } else if (callerContext.type == "unlimited") {
      setCustomSelected(false);
      setFieldValue({ type: CountType.UNLIMITED, value: 0 });
    } else {
      setCustomSelected(false);
      setLimtiedFieldValue(callerContext.value);
    }
  }

  function onValueChange(value: number) {
    setLimtiedFieldValue(value);
  }

  function getOptionButton(
    dl: string,
    callerContext?: CountCallerContext,
    selected?: boolean
  ): RadioButtonGroupItem {
    return {
      buttonProps: {
        content: { normal: dl },
        className: "btn-sm",
      },
      callerContext: callerContext,
      selected: selected,
    };
  }

  let fieldCount: Count | undefined = field.value;
  let index = 0;
  let buttonItems: RadioButtonGroupItem[] = [];
  let nonCustomSelected = false;

  if (props.supportUnlimited) {
    let selected = !fieldCount || fieldCount.type == CountType.UNLIMITED;
    nonCustomSelected = nonCustomSelected || selected;
    buttonItems.push(
      getOptionButton("Unlimited", { type: "unlimited", value: 0 }, selected)
    );
    index++;
  }
  for (let option of props.limitedOptions) {
    let selected =
      fieldCount != undefined &&
      fieldCount.type == CountType.LIMITED &&
      fieldCount.value == option.value;
    nonCustomSelected = nonCustomSelected || selected;

    buttonItems.push(
      getOptionButton(
        option.dl,
        { type: "limited", value: option.value },
        selected
      )
    );
    index++;
  }

  if (props.supportCustom) {
    buttonItems.push(
      getOptionButton(
        "Custom",
        { type: "custom", value: fieldCount?.value ?? 0 },
        !nonCustomSelected
      )
    );
  }

  let [customSelected, setCustomSelected] = useState<boolean>(
    !nonCustomSelected
  );

  return (
    <span className="FormikNumberOptionsInput">
      <div>
        <RadioButtonGroupComponentV2
          items={buttonItems}
          onButtonClick={onOptionChange}
          disabled={props.disabled}
        />
      </div>
      {props.supportCustom == true && customSelected && (
        <div className="mt-2">
          <div className="input-group">
            <TextInputV3
              required={props.required}
              disabled={props.disabled}
              onChange={(value: number) => {
                onValueChange(value);
              }}
              value={fieldCount?.value}
            />
          </div>
        </div>
      )}
      {meta.error ? <div className="text-danger">{meta.error}</div> : null}
    </span>
  );
}
