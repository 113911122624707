import React from "react";
import { HTMLProps } from "../../model/HTMLProps";
import { AssetLocation, LocationType } from "../../model/Picture";

interface ImageComponentProps extends HTMLProps {
  image: AssetLocation;
  showDownloadForUrlType?: boolean;
}

export function ImageComponent(props: ImageComponentProps) {
  let imgStyle = {
    width: props.width,
    maxWidth: props.maxWidth,
    height: props.height,
    maxHeight: props.height,
  };
  return (
    <span className="AssetLocationImageComponent">
      {props.image.locationType === LocationType.FA_CLASS && (
        <>
          <i
            className={props.image.data + ` ${props.className}`}
            style={imgStyle}
          ></i>
        </>
      )}

      {props.image.locationType === LocationType.URL && (
        <>
          <img
            src={props.image.data}
            className={props.className}
            style={imgStyle}
          />
          {props.showDownloadForUrlType && (
            <>
              <div
                style={{ position: "absolute", bottom: "5px", right: "15px" }}
              >
                <a
                  className="btn btn-primary btn-sm"
                  href={props.image.data}
                  download={"img"}
                >
                  <i className="fa-solid fa-download"></i>
                </a>
              </div>
            </>
          )}
        </>
      )}
      {props.image.locationType === LocationType.DEFAULT_COMPANY && (
        <>
          <img
            src={"https://eb-pb.s3.us-east-2.amazonaws.com/company-default.png"}
            className={props.className}
            style={imgStyle}
          />
        </>
      )}
      {props.image.locationType === LocationType.EMJ && props.image.data}
    </span>
  );
}
