import React from "react";
import { CallerContext } from "../components/common/CallerContext";
import { UrlHelpers } from "../helpers/Helpers";
import { WindowHelpers } from "../helpers/Window.helper";
import { ReactMatch } from "../model/ReactMatchObj";
import { PageProps } from "../model/views/PageProps";
import { AddOrUpdateQuestionForm } from "./AddOrUpdateQuestionForm";
import { Question } from "./Question.Model";
import { QuestionsAPI } from "./QuestionsAPI";

interface UpdateQuestionPageContentPathParams {
  questionId: number;
}

interface UpdateQuestionPageContentProps extends PageProps {
  match: ReactMatch<UpdateQuestionPageContentPathParams>;
}

interface UpdateQuestionPageContentState {
  question: Question;
}
export class UpdateQuestionPageContent extends React.Component<
  UpdateQuestionPageContentProps,
  UpdateQuestionPageContentState
> {
  componentDidMount() {
    QuestionsAPI.fetchQuestionDetails(this.props.match.params.questionId).then(
      (question) => {
        this.setState({ question: question });
      }
    );
  }

  render() {
    let callBack = (
      question: Question,
      callerContext: CallerContext
    ): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
        const successMessage = "Question saved succesfully!!!";
        const redirectUrl = UrlHelpers.getQuestionDetailsUrl(
          question.slug,
          question.id,
          successMessage
        );
        WindowHelpers.redirect(redirectUrl);
        resolve();
      });
    };
    return (
      <div className="main-container UpdateQuestionPage">
        {this.props.user && this.state && this.state.question && (
          <>
            <h3>Update Question</h3>

            <div className="card">
              <div className="card-body">
                <AddOrUpdateQuestionForm
                  callerContext={{}}
                  callback={callBack}
                  question={this.state.question}
                  user={this.props.user}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
