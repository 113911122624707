import { QuestionType, QuestionTypeEnum } from "./Question.Model";

export class QuestionHelper {
  public static getQuestionTypeList(): QuestionType[] {
    return [
      {
        typeEnum: QuestionTypeEnum.MCQ,
        dl: "Quiz",
      },
      {
        typeEnum: QuestionTypeEnum.CODING,
        dl: "Coding and Problem Solving",
      },
      {
        typeEnum: QuestionTypeEnum.THEORETICAL,
        dl: "Theoretical",
      },
      {
        typeEnum: QuestionTypeEnum.OTHER,
        dl: "Other",
      },
    ];
  }
}
