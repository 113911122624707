import React from "react";
import { ContentItemTypeEnum } from "../../Content.model";
import { ContentItemInputProps } from "../ContentItem.model";
import { ContentItemInputGroupCall } from "./ContentItemInput.GroupCall";
import { ContentItemInputMarkdown } from "./ContentItemInput.Markdown";
import { ContentItemInputOfferingVideoSession } from "./ContentItemInput.OfferingVideoSesion";
import { ContentItemInputQuestion } from "./ContentItemInput.Question";
import { ContentItemInputVideoUpload } from "./ContentItemInput.VideoUpload";
import { ContentItemInputYouTubeVideo } from "./ContentItemInput.YouTubeVideo";

export function ContentItemInput(props: ContentItemInputProps) {
  let item = props.item;
  return (
    <div className="content-input-item-inner shadow mt-4 ContentItemInput">
      {item.type && item.type.typeEnum === ContentItemTypeEnum.MARKDOWN && (
        <ContentItemInputMarkdown {...props} />
      )}
      {item.type && item.type.typeEnum === ContentItemTypeEnum.QUESTION && (
        <ContentItemInputQuestion {...props} />
      )}

      {item.type && item.type.typeEnum === ContentItemTypeEnum.YOUTUBE && (
        <ContentItemInputYouTubeVideo {...props} />
      )}
      {item.type && item.type.typeEnum === ContentItemTypeEnum.VIDEO_UPLOAD && (
        <ContentItemInputVideoUpload {...props} />
      )}

      {item.type &&
        item.type.typeEnum === ContentItemTypeEnum.OFFERING_VIDEO_SESSION && (
          <ContentItemInputOfferingVideoSession {...props} />
        )}

      {item.type && item.type.typeEnum === ContentItemTypeEnum.GROUP_CALL && (
        <ContentItemInputGroupCall {...props} />
      )}
    </div>
  );
}
