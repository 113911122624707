import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { Target } from "../model/target.model";
import { VideoConstants } from "./Video.Constants";
import {
  VideoBunnyCdn,
  VideoCollectionCreateResponse,
  VideoCreateRequest,
} from "./Video.Model";

export class VideoApi {
  public static createCollection(): Api<Target, VideoCollectionCreateResponse> {
    return new Api<Target, VideoCollectionCreateResponse>(
      VideoConstants.VIDEO_COLLECTION_CREATE,
      ApiMethod.POST
    );
  }

  public static createVideo(): Api<VideoCreateRequest, VideoBunnyCdn> {
    return new Api<VideoCreateRequest, VideoBunnyCdn>(
      VideoConstants.VIDEO_CREATE,
      ApiMethod.POST
    );
  }

  public static getVideo(videoId: string): Api<void, VideoBunnyCdn> {
    return new Api<void, VideoBunnyCdn>(
      VideoConstants.VIDEO_FETCH.replace("{videoId}", videoId),
      ApiMethod.GET
    );
  }
}
