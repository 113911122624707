import { publicLinkConstants } from "../constants";
import { ArrayHelper } from "../helpers/Array.Helper";
import { CountryCode } from "../model/Country.model";
import { TestUserHelper } from "../test/TestUser.Helper";
import { User } from "../user/User";
import { ExpertStudioConstants } from "./ExpertStudio.constants";

export interface ExpertStudioMenuOption {
  id: string;
  title: string;
  faIconClass: string;
  href?: string;
  onClick?: () => void;
  desktopCustomClasses?: string;
}

export class ExpertStudioHelper {
  public static getOptions(liu?: User): ExpertStudioMenuOption[] {
    let opts = [
      {
        id: "bookings",
        title: "All Bookings",
        faIconClass: "fa-solid fa-money-bill-trend-up",
        href: ExpertStudioConstants.PAGE_EXPERT_STUDIO_BOOKINGS_GROUP_BY_BOGIE,
      },
      {
        id: "expert-studio-share",
        title: "Get More Bookings",
        faIconClass: "fa-solid fa-share-from-square",
        href: publicLinkConstants.EXPERT_STUDIO_SHARE,
        desktopCustomClasses: "border-bottom border-light-subtle mb-4 pb-4",
      },

      {
        id: "edit-video-sessions",
        title: "Edit Video Sessions",
        faIconClass: "fas fa-pen-to-square",
        href: publicLinkConstants.EXPERT_STUDIO_EDIT_VIDEO_SESSIONS,
      },
      {
        id: "edit-text-queries",
        title: "Edit Offline Queries",
        faIconClass: "fas fa-pen-to-square",
        href: publicLinkConstants.EXPERT_STUDIO_TEXT_QUERY,
      },
      {
        id: "edit-digital-products",
        title: "Edit Digital Products",
        faIconClass: "fas fa-pen-to-square",
        href: publicLinkConstants.EXPERT_STUDIO_EDIT_DIGITAL_PRODUCT,
      },
      {
        id: "expert-studio-event",
        title: "Events/Webinars",
        faIconClass: "fas fa-calendar-alt",
        desktopCustomClasses: "border-bottom border-light-subtle mb-4 pb-4",
        href: publicLinkConstants.EXPERT_STUDIO_MANAGE_EVENTS,
      },
      {
        id: "expert-studio-edit-profile",
        title: "Edit Profile",
        faIconClass: "fas fa-user",
        href: publicLinkConstants.EXPERT_STUDIO_PROFILE,
      },

      {
        id: "expert-studio-calendar",
        title: "Calendar",
        faIconClass: "fas fa-calendar",
        desktopCustomClasses: "border-bottom border-light-subtle mb-4 pb-4",
        href: publicLinkConstants.EXPERT_STUDIO_CALENDAR,
      },
      {
        id: ExpertStudioMenuOptionId.COUPONS,
        title: "Coupons",
        faIconClass: "fa-solid fa-tag",
        href: publicLinkConstants.EXPERT_STUDIO_COUPONS,
      },

      {
        id: "expert-studio-payments",
        title: "Payments",
        faIconClass: "fas fa-money-bill",
        href: publicLinkConstants.EXPERT_STUDIO_PAYMENTS,
      },
      {
        id: "general-settings",
        title: "General Settings",
        faIconClass: "fas fa-toolbox",
        href: publicLinkConstants.EXPERT_STUDIO_GENERAL_SETTINGS,
      },
    ];

    if (TestUserHelper.isTestUser(liu?.basicDetails)) {
      ArrayHelper.insertAtIndex(
        opts,
        {
          id: "expert-studio-edit-path",
          title: "Edit Paths",
          faIconClass: "fas fa-pen-to-square",
          href: ExpertStudioConstants.PATH_EXPERT_STUDIO_EDIT_PATH,
        },
        5
      );
    }

    console.log(liu);

    if (liu?.basicDetails.country?.countryCode == CountryCode.IN) {
      insertAfter(
        {
          id: ExpertStudioMenuOptionId.PDS_EDIT,
          title: "Differential Pricing",
          faIconClass: "fas fa-pen-to-square",
          href: ExpertStudioConstants.PATH_EXPERT_STUDIO_PDS_EDIT,
        },
        ExpertStudioMenuOptionId.COUPONS,
        opts
      );
    }

    return opts;
  }
}

function insertAfter(
  newOpt: ExpertStudioMenuOption,
  afterId: string,
  opts: ExpertStudioMenuOption[]
) {
  console.log("Inserting opt after " + afterId);
  let idx = 0;
  for (; idx < opts.length; idx++) {
    let opt = opts[idx];
    if (opt.id == afterId) {
      idx++;
      break;
    }
  }

  console.log("idx " + idx);

  ArrayHelper.insertAtIndex(opts, newOpt, idx);
}

export class ExpertStudioMenuOptionId {
  public static PDS_EDIT = "expert-studio-pds-edit";
  public static COUPONS = "expert-studio-coupons";
}

export enum ExpertStudioSharePageTabName {
  ANNOUNCEMENT = "ANNOUNCEMENT",
  TESTIMONIAL = "TESTIMONIAL",
  OFFERINGS = "OFFERINGS",
  ACHIEVEMENTS = "ACHIEVEMENTS",
  YEAR_REVIEW = "YEAR_REVIEW",
  MARKETING = "MARKETING",
}
