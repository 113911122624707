import { Api, ApiMethod } from "../../components/common/ApiLinkedButton";
import { MoveDirectionEnum, PathStep } from "./Offering.Path.Model";
import { OfferingPathConstants } from "./Offering.Path.constants";

export class OfferingPathStepAPI {
  public static add(userId: string, offeringId: string): Api<void, PathStep> {
    let apiLink =
      OfferingPathConstants.API_USER_OFFERINGS_PATH_STEP_ADD.replaceAll(
        "{userId}",
        userId
      ).replaceAll("{offeringId}", offeringId);

    return new Api<void, PathStep>(apiLink, ApiMethod.POST);
  }

  public static addSubStep(
    userId: string,
    offeringId: string,
    parentStep: PathStep
  ): Api<void, PathStep> {
    let apiLink =
      OfferingPathConstants.API_USER_OFFERINGS_PATH_STEP_SUB_STEP_ADD.replaceAll(
        "{userId}",
        userId
      )
        .replaceAll("{offeringId}", offeringId)
        .replaceAll("{stepId}", parentStep.id + "");

    return new Api<void, PathStep>(apiLink, ApiMethod.POST);
  }

  public static update(
    userId: string,
    offeringId: string,
    stepId: string,
    pathStepInput: PathStep
  ): Promise<PathStep> {
    let apiLink =
      OfferingPathConstants.API_USER_OFFERINGS_PATH_STEP_UPDATE.replaceAll(
        "{userId}",
        userId
      )
        .replaceAll("{offeringId}", offeringId)
        .replaceAll("{stepId}", stepId);

    return new Api<PathStep, PathStep>(apiLink, ApiMethod.PUT).execute(
      pathStepInput
    );
  }

  public static move(
    userId: string,
    offeringId: string,
    stepId: string,
    moveDirection: MoveDirectionEnum
  ): Api<void, void> {
    let apiLink =
      OfferingPathConstants.API_USER_OFFERINGS_PATH_STEP_MOVE.replaceAll(
        "{userId}",
        userId
      )
        .replaceAll("{offeringId}", offeringId)
        .replaceAll("{stepId}", stepId)
        .replaceAll("{moveDirection}", moveDirection);

    return new Api<void, void>(apiLink, ApiMethod.PATCH);
  }

  public static delete(
    userId: string,
    offeringId: string,
    stepId: string
  ): Api<void, void> {
    let apiLink = OfferingPathConstants.API_USER_OFFERINGS_PATH_STEP.replaceAll(
      "{userId}",
      userId
    )
      .replaceAll("{offeringId}", offeringId)
      .replaceAll("{stepId}", stepId);

    return new Api<void, void>(apiLink, ApiMethod.DELETE);
  }

  public static get(
    userId: string,
    offeringId: string,
    stepId: string
  ): Promise<PathStep> {
    let apiLink = OfferingPathConstants.API_USER_OFFERINGS_PATH_STEP.replaceAll(
      "{userId}",
      userId
    )
      .replaceAll("{offeringId}", offeringId)
      .replaceAll("{stepId}", stepId);

    return new Api<void, PathStep>(apiLink, ApiMethod.GET).execute();
  }

  public static markComplete(
    postedById: string,
    offeringId: string,
    stepId: number
  ): Promise<void> {
    let apiLink =
      OfferingPathConstants.API_USER_OFFERINGS_PATH_STEP_MARK_COMPLETE.replaceAll(
        "{postedById}",
        postedById
      )
        .replaceAll("{offeringId}", offeringId)
        .replaceAll("{stepId}", stepId + "");

    return new Api<void, void>(apiLink, ApiMethod.POST).execute();
  }

  public static markIncomplete(
    postedById: string,
    offeringId: string,
    stepId: number
  ): Promise<void> {
    let apiLink =
      OfferingPathConstants.API_USER_OFFERINGS_PATH_STEP_MARK_INCOMPLETE.replaceAll(
        "{postedById}",
        postedById
      )
        .replaceAll("{offeringId}", offeringId)
        .replaceAll("{stepId}", stepId + "");

    return new Api<void, void>(apiLink, ApiMethod.POST).execute();
  }

  public static editList(
    userId: string,
    offeringId: string
  ): Api<void, PathStep[]> {
    let apiLink =
      OfferingPathConstants.API_USER_OFFERINGS_PATH_STEPS_EDIT_LIST.replaceAll(
        "{userId}",
        userId
      ).replaceAll("{offeringId}", offeringId);

    return new Api<void, PathStep[]>(apiLink, ApiMethod.GET);
  }

  public static subStepsEditList(
    userId: string,
    offeringId: string,
    stepId: string
  ): Api<void, PathStep[]> {
    let apiLink =
      OfferingPathConstants.API_USER_OFFERINGS_PATH_STEP_SUB_STEPS_EDIT_LIST.replaceAll(
        "{userId}",
        userId
      )
        .replaceAll("{offeringId}", offeringId)
        .replaceAll("{stepId}", stepId);

    return new Api<void, PathStep[]>(apiLink, ApiMethod.GET);
  }

  public static publicList(
    userId: string,
    offeringId: string
  ): Api<void, PathStep[]> {
    let apiLink =
      OfferingPathConstants.API_USER_OFFERINGS_PATH_STEPS.replaceAll(
        "{userId}",
        userId
      ).replaceAll("{offeringId}", offeringId);

    return new Api<void, PathStep[]>(apiLink, ApiMethod.GET);
  }
}
