import React, { useEffect, useState } from "react";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { ReactMatch } from "../model/ReactMatchObj";
import { PageProps } from "../model/views/PageProps";
import { ViewsManager } from "../reactions/Views.Manager";
import { UserBasicDetails } from "./User";
import { UserAPI } from "./UserAPI";
import { UserFullProfileContent } from "./UserFullProfileContent";

interface UserProfileProps extends PageProps {
  match: ReactMatch<UserProfilePropsPathParams>;
}

interface UserProfilePropsPathParams {
  userId: string;
}

export function UserProfilePageContent(props: UserProfileProps) {
  let [userToShow, setUserToShow] = useState<UserBasicDetails>();
  let userId = props.match.params.userId;

  useEffect(() => {
    UserAPI.fetchBasicUserDetails(userId, true).then((userToShow) => {
      setUserToShow(userToShow);
      ViewsManager.log({
        targetType: AttachmentTargetType.USER_PROFILE,
        targetId: userToShow.id,
      });
    });
  }, []);

  return (
    <div className="main-container bg-main-body h-100 px-0">
      {userToShow && (
        <UserFullProfileContent
          userToShow={userToShow}
          loggedInUser={props.user}
        />
      )}
    </div>
  );
}
