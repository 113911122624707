import React from "react";
import { BogieName, EngineName } from "../Global.Names";
import { reloadWindowPromise } from "../components/common/reloadCallback";
import { FormList } from "../form/Form.List";
import { FormAPI } from "../form/Form.api";
import { FormWrapperComponent } from "../form/FormWrapper.Component";
import { ExpertSessionRole } from "../model/ExpertSession";
import { AuthzAction } from "../model/enums/AuthzActions.enum";
import { User } from "../user/User";
import { BookingApi } from "./Booking.Api";
import { Booking } from "./Booking.model";

interface BookingFeedbackComponentProps {
  booking: Booking;
  user: User;
}

export function BookingCommonComponent(props: BookingFeedbackComponentProps) {
  let booking = props.booking;
  let bcd = booking.bcd;

  function markBookingComplete(userId: string): Promise<void> {
    return BookingApi.markComplete(booking.bookingTarget, userId).executeV2();
  }

  return (
    <div className="BookingFeedbackComponent">
      {bcd.preBookingFormId && (
        <FormWrapperComponent
          className="card card-body mt-4"
          initialFetchApi={FormAPI.getFormApi(bcd.preBookingFormId)}
          user={props.user}
          hideStatusBadge={true}
          customerFormDl={`Details from ${BogieName.SENTENCE_MID}`}
          emptyFormDl={<>Not filled</>}
        />
      )}
      <div className="mt-4">
        {(bcd.role == ExpertSessionRole.EXPERT ||
          (bcd.role == ExpertSessionRole.MENTEE &&
            (bcd.bogieCompletedAt != null || bcd.isSkipBogieCompletion)) ||
          bcd.role == ExpertSessionRole.ADMIN) &&
          bcd.engineFeedbackFormTemplateId && (
            <>
              <h6 className="m-0 font-weight-bold text-primary">
                {bcd.role == ExpertSessionRole.EXPERT
                  ? "Your Feedback"
                  : `Feedback given by ${EngineName.SENTENCE_MID}`}
              </h6>

              <FormWrapperComponent
                className="card card-body"
                initialFetchApi={BookingApi.getForm(
                  booking.bookingTarget,
                  bcd.engineId
                )}
                submitProps={{
                  title: `${
                    bcd.isSkipEngineCompletion
                      ? "Submit"
                      : "Submit and Mark Complete"
                  }`,
                  callback: () => {
                    return bcd.isSkipEngineCompletion
                      ? reloadWindowPromise()
                      : markBookingComplete(bcd.engineId).then(() => {
                          return reloadWindowPromise();
                        });
                  },
                }}
                user={props.user}
              />
            </>
          )}

        {(bcd.role == ExpertSessionRole.MENTEE ||
          (bcd.role == ExpertSessionRole.ADMIN && bcd.bogieId)) &&
          bcd.bogieFeedbackFormTemplateId && (
            <div className="mt-4">
              <h6 className="m-0 font-weight-bold text-primary">
                Your Feedback
              </h6>

              <FormWrapperComponent
                className="card card-body"
                initialFetchApi={BookingApi.getForm(
                  booking.bookingTarget,
                  bcd.role == ExpertSessionRole.ADMIN
                    ? bcd.bogieId!
                    : props.user.basicDetails.id
                )}
                submitProps={{
                  title: `${
                    bcd.isSkipBogieCompletion
                      ? "Submit"
                      : "Submit and Mark Complete"
                  }`,
                  callback: () => {
                    return bcd.isSkipBogieCompletion
                      ? reloadWindowPromise()
                      : markBookingComplete(
                          bcd.role == ExpertSessionRole.ADMIN
                            ? bcd.bogieId!
                            : props.user.basicDetails.id
                        ).then(() => {
                          return reloadWindowPromise();
                        });
                  },
                }}
                user={props.user}
              />
            </div>
          )}
        {bcd.role === ExpertSessionRole.MENTEE &&
          !bcd.bogieCompletedAt &&
          !bcd.isSkipBogieCompletion &&
          !bcd.isSkipEngineCompletion &&
          bcd.bogieFeedbackFormTemplateId &&
          bcd.engineFeedbackFormTemplateId && (
            <div className="text-danger mt-2">
              You will be able to see expert's feedback once you complete
              submission of your feedback.
            </div>
          )}

        {bcd.role == ExpertSessionRole.EXPERT && (
          <div className="mt-4">
            <h6 className="m-0 font-weight-bold text-primary">
              Feedbacks Received:
            </h6>

            <FormList
              onFetch={() => {
                return BookingApi.getFeedbackFormList(
                  booking.bookingTarget
                ).executeV2({ excludeIfHasThisPermission: [AuthzAction.EDIT] });
              }}
              user={props.user}
            />
          </div>
        )}

        {bcd.role == ExpertSessionRole.ADMIN && (
          <div className="mt-4">
            <h6 className="m-0 font-weight-bold text-primary">
              All received on this booking:
            </h6>

            <FormList
              onFetch={() => {
                return BookingApi.getFeedbackFormList(
                  booking.bookingTarget
                ).executeV2({ excludeIfHasThisPermission: [] });
              }}
              user={props.user}
            />
          </div>
        )}
      </div>
    </div>
  );
}
