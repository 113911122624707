import React from "react";
import {
  EBSelectOption,
  EBSelectOptionDataType,
  EBSelectV2,
} from "../components/common/Form/EBSelectV2";
import { SelectorProps } from "../components/common/Selectors";
import { QuestionType } from "./Question.Model";

interface QuestionTypeSelectorProps extends SelectorProps {
  questionTypes: QuestionType[];
}

export interface QuestionTypeSelectorOption
  extends EBSelectOptionDataType,
    QuestionType {}

export function QuestionTypeSelector(props: QuestionTypeSelectorProps) {
  let options: EBSelectOption<QuestionTypeSelectorOption>[] = [];

  props.questionTypes.forEach((qt: QuestionType) => {
    options.push({
      label: qt.dl,
      value: `${qt.typeEnum}`,
      data: {
        ...qt,
        selectOptionValue: `${qt.typeEnum}`,
      },
    });
  });

  return (
    <div className="row">
      <div className="col">
        {options && options.length > 0 && (
          <EBSelectV2
            label={props.label}
            name={props.name}
            required={props.required}
            options={options}
            disabled={props.disabled}
            isMulti={props.isMulti}
            isDataEqual={(
              d1: QuestionTypeSelectorOption,
              d2: QuestionTypeSelectorOption
            ) => {
              return d1.typeEnum === d2.typeEnum;
            }}
          />
        )}
      </div>
    </div>
  );
}
