import React from "react";
import { EBTextInputV2 } from "../../components/common/input/TextLine.input";
import { FormSectionLineItemSpecificTypeTemplateBuilderFormikInputProps } from "./Form.Section.LineItem.TemplateBuilder";

export function FormSectionLineItemTextAreaTemplateBuilderFormikInput(
  props: FormSectionLineItemSpecificTypeTemplateBuilderFormikInputProps
) {
  let lineItem = props.lineItem;
  return (
    <span className="FormSectionLineItemTextAreaTemplateBuilderFormikInput">
      <EBTextInputV2
        name={`${props.name}.data.displayTitle.view`}
        placeholder="Question"
      />
    </span>
  );
}
