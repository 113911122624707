import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { OfferingCommunity } from "../offerings/Offering";
import { CommunityConstants } from "./Community.Constants";

export class CommunityApi {
  public static get(communityId: string): Api<void, OfferingCommunity> {
    return new Api<void, OfferingCommunity>(
      CommunityConstants.COMMUNITY_SINGLE.replace(
        "{communityId}",
        communityId + ""
      ),
      ApiMethod.GET
    );
  }
}
