import React from "react";
import { Route } from "react-router";
import { navbarLinks } from "../App";
import NormalNavBar from "../components/pages/NormalNavBar";
import PublicPage from "../components/pages/PublicPage";
import { publicLinkConstants } from "../constants";
import { ExpertStudioEventContent } from "../events/Event.ExpertStudio.Content";
import { UpdateEventComponent } from "../events/UpdateEvent.Component";
import { CalendarExpertStudioContent } from "../expert-studio/Calendar.ExpertStudio.Content";
import { CouponExpertStudioContent } from "../expert-studio/Coupon.ExpertStudio.Content";
import { CouponUsagesExpertStudioContent } from "../expert-studio/CouponUsages.ExpertStudio.Content";
import { ExpertStudioConstants } from "../expert-studio/ExpertStudio.constants";
import { ExpertStudioPageContent } from "../expert-studio/ExpertStudio.page";
import { ExpertStudioGeneralSettingsComponent } from "../expert-studio/ExpertStudioGeneralSettings.component";
import { ExpertStudioPaymentsComponent } from "../expert-studio/ExpertStudioPayments.component";
import { ExpertStudioShareComponent } from "../expert-studio/ExpertStudioShare.component";
import { OfferingsBookingsExpertStudioContent } from "../expert-studio/Offerings.Bookings.ExpertStudio.Content";
import { OfferingsDigitalProductEditExpertStudioContent } from "../expert-studio/Offerings.DigitalProduct.Edit.ExpertStudio.Content";
import { OfferingsPathsEditExpertStudioContent } from "../expert-studio/Offerings.Paths.Edit.ExpertStudio.Content";
import { OfferingsTextQueryEditExpertStudioContent } from "../expert-studio/Offerings.TextQuery.Edit.ExpertStudio.Content";
import { OfferingsVideoSessionEditExpertStudioContent } from "../expert-studio/Offerings.VideoSession.Edit.ExpertStudio.Content";
import { UserProfileEditExpertStudioContent } from "../expert-studio/UserProfile.Edit.ExpertStudio.Content copy";
import { PublicPageRoute } from "./PublicPage.route";
import { ExpertStudioPriceDifferentialSettingsComponent } from "../expert-studio/ExpertStudio.PriceDifferentialSettings.Component";
import { ExpertStudioMenuOptionId } from "../expert-studio/ExpertStudio.model";

function ExpertStudioPageWithGeneralSettingsContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={ExpertStudioGeneralSettingsComponent}
      activeNavId="general-settings"
      {...props}
    />
  );
}

function ExpertStudioGeneralSettingsPage(props: any) {
  return (
    <PublicPage
      contentComponent={ExpertStudioPageWithGeneralSettingsContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function ExpertStudioPageWithVideoSessionsContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={OfferingsVideoSessionEditExpertStudioContent}
      activeNavId="edit-video-sessions"
      {...props}
    />
  );
}

function ExpertStudioVideoSessionsPage(props: any) {
  return (
    <PublicPage
      contentComponent={ExpertStudioPageWithVideoSessionsContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function ExpertStudioPageWithTextQueryContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={OfferingsTextQueryEditExpertStudioContent}
      activeNavId="edit-text-queries"
      {...props}
    />
  );
}

function ExpertStudioTextQueryPage(props: any) {
  return (
    <PublicPage
      contentComponent={ExpertStudioPageWithTextQueryContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function ExpertStudioOfferingsEditDigitalProductPageContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={OfferingsDigitalProductEditExpertStudioContent}
      activeNavId="edit-digital-products"
      {...props}
    />
  );
}

function ExpertStudioOfferingsEditDigitalProductPage(props: any) {
  return (
    <PublicPage
      contentComponent={ExpertStudioOfferingsEditDigitalProductPageContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function ExpertStudioPageWithPathEditContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={OfferingsPathsEditExpertStudioContent}
      {...props}
      activeNavId="expert-studio-edit-path"
    />
  );
}

function ExpertStudioPageWithPriceDifferentialSettingsContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={ExpertStudioPriceDifferentialSettingsComponent}
      {...props}
      activeNavId={ExpertStudioMenuOptionId.PDS_EDIT}
    />
  );
}

function ExpertStudioPageWithBookingsContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={OfferingsBookingsExpertStudioContent}
      {...props}
      activeNavId="bookings"
    />
  );
}

function ExpertStudioPageWithShareContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={ExpertStudioShareComponent}
      {...props}
      activeNavId="expert-studio-share"
    />
  );
}

function ExpertStudioSharePage(props: any) {
  return (
    <PublicPage
      contentComponent={ExpertStudioPageWithShareContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function ExpertStudioPageWithPaymentsContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={ExpertStudioPaymentsComponent}
      {...props}
      activeNavId="expert-studio-payments"
    />
  );
}

function ExpertStudioPaymentsPage(props: any) {
  return (
    <PublicPage
      contentComponent={ExpertStudioPageWithPaymentsContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function ExpertStudioPageWithCouponsContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={CouponExpertStudioContent}
      {...props}
      activeNavId="expert-studio-coupons"
    />
  );
}

function ExpertStudioCouponsPage(props: any) {
  return (
    <PublicPage
      contentComponent={ExpertStudioPageWithCouponsContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function ExpertStudioPageWithCouponUsagesContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={CouponUsagesExpertStudioContent}
      {...props}
      activeNavId="expert-studio-coupons"
    />
  );
}

function ExpertStudioCouponUsagesPage(props: any) {
  return (
    <PublicPage
      contentComponent={ExpertStudioPageWithCouponUsagesContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function ExpertStudioPageWithCalendarContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={CalendarExpertStudioContent}
      {...props}
      activeNavId="expert-studio-calendar"
    />
  );
}

function ExpertStudioCalendarPage(props: any) {
  return (
    <PublicPage
      contentComponent={ExpertStudioPageWithCalendarContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function ExpertStudioPageWithUserProfileEditContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={UserProfileEditExpertStudioContent}
      {...props}
      activeNavId="expert-studio-edit-profile"
    />
  );
}

function ExpertStudioPageWithEventContent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={ExpertStudioEventContent}
      {...props}
      activeNavId="expert-studio-event"
    />
  );
}

function ExpertStudioEventPage(props: any) {
  return (
    <PublicPage
      contentComponent={ExpertStudioPageWithEventContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function ExpertStudioPageUpdateEventComponent(props: any) {
  return (
    <ExpertStudioPageContent
      ContentComponent={UpdateEventComponent}
      {...props}
      activeNavId="expert-studio-event"
    />
  );
}

export function ExpertStudioRoutes() {
  return (
    <>
      <Route
        exact
        path={publicLinkConstants.EXPERT_STUDIO_EDIT_VIDEO_SESSIONS}
        component={ExpertStudioVideoSessionsPage}
      />
      <Route
        exact
        path={publicLinkConstants.EXPERT_STUDIO_TEXT_QUERY}
        component={ExpertStudioTextQueryPage}
      />
      <Route
        exact
        path={publicLinkConstants.EXPERT_STUDIO_EDIT_DIGITAL_PRODUCT}
        component={ExpertStudioOfferingsEditDigitalProductPage}
      />

      <PublicPageRoute
        exact
        path={publicLinkConstants.EXPERT_STUDIO_PROFILE}
        contentComponent={ExpertStudioPageWithUserProfileEditContent}
      />

      <PublicPageRoute
        exact
        path={ExpertStudioConstants.PATH_EXPERT_STUDIO_EDIT_PATH}
        contentComponent={ExpertStudioPageWithPathEditContent}
      />

      <PublicPageRoute
        exact
        path={ExpertStudioConstants.PATH_EXPERT_STUDIO_PDS_EDIT}
        contentComponent={ExpertStudioPageWithPriceDifferentialSettingsContent}
      />

      <PublicPageRoute
        exact
        path={ExpertStudioConstants.PAGE_EXPERT_STUDIO_BOOKINGS}
        contentComponent={ExpertStudioPageWithBookingsContent}
      />

      <Route
        exact
        path={publicLinkConstants.EXPERT_STUDIO_GENERAL_SETTINGS}
        component={ExpertStudioGeneralSettingsPage}
      />
      <Route
        exact
        path={publicLinkConstants.EXPERT_STUDIO_SHARE}
        component={ExpertStudioSharePage}
      />
      <Route
        exact
        path={publicLinkConstants.EXPERT_STUDIO_PAYMENTS}
        component={ExpertStudioPaymentsPage}
      />

      <Route
        exact
        path={publicLinkConstants.EXPERT_STUDIO_CALENDAR}
        component={ExpertStudioCalendarPage}
      />

      <Route
        exact
        path={publicLinkConstants.EXPERT_STUDIO_COUPONS}
        component={ExpertStudioCouponsPage}
      />

      <Route
        exact
        path={publicLinkConstants.EXPERT_STUDIO_COUPON_USAGES}
        component={ExpertStudioCouponUsagesPage}
      />
      <Route
        exact
        path={publicLinkConstants.EXPERT_STUDIO_MANAGE_EVENTS}
        component={ExpertStudioEventPage}
      />
      <PublicPageRoute
        exact
        path={publicLinkConstants.EXPERT_STUDIO_EDIT_EVENT}
        contentComponent={ExpertStudioPageUpdateEventComponent}
      />
    </>
  );
}
