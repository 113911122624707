import { Field } from "formik";
import React from "react";
import { SelectorOption, SelectorProps } from "../components/common/Selectors";
import { EBSelect } from "../components/common/eb-ui-components";
import { SessionType } from "./Offering";

interface SessionTypeSelectorProps extends SelectorProps {
  all: SessionType[];
}

export function SessionTypeSelector(props: SessionTypeSelectorProps) {
  let options: SelectorOption[] = [];

  props.all.forEach((sessionType) => {
    options.push({ value: sessionType.id, label: sessionType.displayTitle });
  });

  return (
    <div className="row">
      <div className="col">
        {options && (
          <Field
            label={props.fieldTitle ?? "Type"}
            name={`${props.name}.id`}
            component={EBSelect}
            required={props.required}
            options={options}
            isDisabled={props.disabled}
          />
        )}
      </div>
    </div>
  );
}
