import React, { useState } from "react";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { UserBasicDetails } from "../user/User";
import { ShareApi } from "./Share.api";

interface ShareYIRComponentProps {
  user: UserBasicDetails;
  year: number;
}

export function ShareYIRComponent(props: ShareYIRComponentProps) {
  let [yirPdfUrl, setYirPdfUrl] = useState<string>();
  let [loading, setLoading] = useState<boolean | undefined>();

  function generatePdf() {
    setLoading(true);
    ShareApi.generateYirPdf(props.user.id, props.year)
      .executeV2()
      .then((response) => {
        setYirPdfUrl(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="ShareYIRComponent">
      <EBButtonV2
        content={{ normal: `Generate ${[props.year]} Review` }}
        loading={loading}
        onClick={generatePdf}
      />

      {!loading && yirPdfUrl && (
        <div className="mt-3">
          <b>
            You can <a href={yirPdfUrl}>download</a> your full year review pdf
            and share on your social media by adding your own text content!
            (Don't forget to tag us!)
          </b>
          <div className="event-data-desktop">
            <object
              data={yirPdfUrl}
              type="application/pdf"
              width="600"
              height="655"
              className="mt-2"
            >
              <p>
                View your full year review <a href={yirPdfUrl}>here!</a>
              </p>
            </object>
          </div>

          <div className="event-data-mobile">
            <object
              data={yirPdfUrl}
              type="application/pdf"
              width="300"
              height="355"
              className="mt-2"
            >
              <p>
                View your full year review <a href={yirPdfUrl}>here!</a>
              </p>
            </object>
          </div>
        </div>
      )}
    </div>
  );
}
