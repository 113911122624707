import React from "react";
import { QuestionDifficulty } from "./Question.Model";

interface DifficultyBadgeProps {
  difficulty: QuestionDifficulty;
}

export function DifficultyBadge(props: DifficultyBadgeProps) {
  return (
    <>
      {props.difficulty === QuestionDifficulty.HARD && (
        <div className="badge rounded-pill bg-danger">Hard</div>
      )}
      {props.difficulty === QuestionDifficulty.MEDIUM && (
        <div className="badge rounded-pill badge-warning">Medium</div>
      )}
      {props.difficulty === QuestionDifficulty.EASY && (
        <div className="badge rounded-pill badge-success">Easy</div>
      )}
    </>
  );
}
