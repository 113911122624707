import { Api, ApiMethod } from "../../components/common/ApiLinkedButton";
import { admiApiLinkConstants, apiLinkConstants } from "../../constants";
import { Ledger, LedgerBalance } from "./Ledger.model";

export class LedgerAdminApi {
  public static getFullLedger(): Promise<Ledger> {
    let api = admiApiLinkConstants.API_ADMIN_LEDGER_ALL;
    return new Api<void, Ledger>(api, ApiMethod.GET).execute();
  }

  public static getLedgerBalances(): Promise<LedgerBalance[]> {
    let api = admiApiLinkConstants.API_ADMIN_LEDGER_BALANCES;
    return new Api<void, LedgerBalance[]>(api, ApiMethod.GET).execute();
  }
}
