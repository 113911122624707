import React, { useEffect, useState } from "react";
import { Loader } from "../components/common/Loader.component";
import { Center } from "../components/common/layout/Center.component";
import { User } from "../user/User";

export interface RemoteListItemProps {
  item: any;
  user: User;

  index: number;
  length: number;

  customListObject?: any;
}

interface RemoteListProps {
  onFetch: () => Promise<any[]>;
  ItemComponent: React.ComponentType<RemoteListItemProps>;
  user: User;
  emptyListContent?: React.ReactNode;
  customListObject?: any;
}

export function RemoteList(props: RemoteListProps) {
  let [items, setItems] = useState<any[]>();
  let [loading, setLoading] = useState<boolean>();
  useEffect(() => {
    setLoading(true);
    props
      .onFetch()
      .then((items) => {
        setItems(items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="RemoteListComponent">
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {items &&
        items.map((item, index) => (
          <span key={index}>
            <props.ItemComponent
              item={item}
              user={props.user}
              customListObject={props.customListObject}
              index={index}
              length={!items ? 0 : items.length}
            />
          </span>
        ))}
      {items && items.length == 0 && <>{props.emptyListContent}</>}
    </div>
  );
}
