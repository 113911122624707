import React from "react";
import { Booking } from "../booking/Booking.model";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import VideoSession from "../model/ExpertSession";
import { BookingCancelButton } from "./Booking.Cancel.Button";
import { VideoSessionSummaryCard } from "./VideoSessionSummary.Card";

interface BookingSummaryCardProps {
  booking: Booking;
  notifyParticipants?: boolean;
  onSessionUpdate: (updatedSession: Booking) => Promise<void>;
}

export function BookingSummaryCard(props: BookingSummaryCardProps) {
  let booking = props.booking;
  return (
    <div className="VideoSessionSummaryCard">
      <div className="row pb-3">
        <div className="col">
          <h4>{booking.bcd.dl}</h4>
        </div>
        <div className="col-auto hstack gap-2">
          {booking.canCancel === true && (
            <BookingCancelButton
              notifyParticipants={props.notifyParticipants}
              bookingId={booking.id!}
              buttonProps={{
                className: "btn-sm btn-light",
                content: { normal: "Cancel", submitting: "Cancelling" },
              }}
              onSessionUpdate={props.onSessionUpdate}
            />
          )}
          <a
            href={booking.url}
            target="_blank"
            className="btn btn-secondary btn-sm"
          >
            Details
          </a>{" "}
        </div>
      </div>
      {booking.bookingTarget.targetType ==
        AttachmentTargetType.VIDEO_SESSION && (
        <VideoSessionSummaryCard
          booking={booking}
          vs={booking.specificBookingTypeModel as VideoSession}
        />
      )}
    </div>
  );
}
