import { Tooltip } from "antd";
import React, { HTMLAttributeAnchorTarget } from "react";

interface LinkProps {
  children: React.ReactNode;
  href?: string;
  className?: string;
  target?: HTMLAttributeAnchorTarget | undefined;
  tooltip?: React.ReactNode;
}

export function Link(props: LinkProps) {
  return (
    <Tooltip title={props.tooltip}>
      <a
        target={props.target}
        href={props.href}
        className={props.className}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {props.children}
      </a>
    </Tooltip>
  );
}
