import { FieldArray, useField } from "formik";
import React from "react";
import { DeleteButton } from "../../../button/Delete.Button";
import { AddTitle } from "../Add.title";
import { EBButtonV2 } from "../EBButtonV2";
import { FormikInputParams } from "./FormikInputParams.model";

interface ListFormikInputProps extends FormikInputParams {
  itemComponent?: React.ComponentType<FormikInputParams>;

  itemNode?: (
    itemProps: FormikInputParams,
    onDelete?: () => Promise<void>
  ) => React.ReactNode;

  emptyNode?: React.ReactNode;
  getNewItem?: (size: number) => any;
  hideSeparateDeleteButton?: boolean;
  minElementsBeforeAllowingDelete?: number;
  hideAddBtn?: boolean;
}

export function ListFormikInput(props: ListFormikInputProps) {
  const [field, meta] = useField(props);

  let currentList: any[] | undefined = field.value;

  function deleteAllowed() {
    return (
      currentList &&
      (props.minElementsBeforeAllowingDelete == undefined ||
        props.minElementsBeforeAllowingDelete < currentList?.length)
    );
  }

  return (
    <FieldArray name={props.name}>
      {(arrayHelpers: any) => (
        <div key={arrayHelpers.index}>
          {(!currentList || currentList.length === 0) && props.emptyNode && (
            <>{props.emptyNode}</>
          )}
          {currentList &&
            currentList.map((_, index) => (
              <div className="row mx-0 mt-2">
                <div className="col p-0">
                  {props.itemComponent && (
                    <props.itemComponent
                      name={`${props.name}.${index}`}
                      disabled={props.disabled}
                    />
                  )}

                  {props.itemNode &&
                    props.itemNode(
                      {
                        name: `${props.name}.${index}`,
                        disabled: props.disabled,
                      },
                      deleteAllowed()
                        ? () => {
                            arrayHelpers.remove(index);
                            return Promise.resolve();
                          }
                        : undefined
                    )}
                </div>

                {!props.hideSeparateDeleteButton && deleteAllowed() && (
                  <div className="col-auto p-0">
                    <DeleteButton onClick={() => arrayHelpers.remove(index)} />
                  </div>
                )}
              </div>
            ))}

          {!props.hideAddBtn && (
            <div className="row ">
              <div className="col mt-2">
                <EBButtonV2
                  className="btn btn-light btn-sm"
                  content={{ normal: <AddTitle text={"Add"} /> }}
                  onClick={() => {
                    if (props.getNewItem) {
                      arrayHelpers.push(
                        props.getNewItem(currentList?.length ?? 0)
                      );
                      return;
                    }
                    arrayHelpers.push({});
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </FieldArray>
  );
}
