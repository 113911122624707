import { Tooltip } from "antd";
import React from "react";
import Moment from "react-moment";
import { HTMLProps } from "../../model/HTMLProps";

interface DateViewProps extends HTMLProps {
  timestamp: string | Date | number;
}
export function DateView(props: DateViewProps) {
  return (
    <Moment
      format="DD MMM YYYY"
      className={props.className}
      data-tag="allowRowEvents"
    >
      {props.timestamp}
    </Moment>
  );
}

interface TimeViewProps {
  timestamp: string | Date | number;
}
export function TimeView(props: TimeViewProps) {
  return (
    <Moment format="hh:mm a" data-tag="allowRowEvents">
      {props.timestamp}
    </Moment>
  );
}

interface TimezoneViewProps {
  timestamp: string | Date | number;
}
export function TimezoneView(props: TimezoneViewProps) {
  return (
    <Moment format="Z" data-tag="allowRowEvents">
      {props.timestamp}
    </Moment>
  );
}

interface TimestampViewProps {
  timestamp: string | Date | number;
  tzInToolTip?: boolean;
}
export function TimestampView(props: TimestampViewProps) {
  return (
    <>
      {props.tzInToolTip && (
        <Tooltip
          title={
            <>
              In <TimezoneView timestamp={props.timestamp} /> Timezone
            </>
          }
        >
          <span className="mr-1">
            <DateView timestamp={props.timestamp} />,
          </span>
          <TimeView timestamp={props.timestamp} />
        </Tooltip>
      )}

      {!props.tzInToolTip && (
        <>
          <span className="mr-1">
            <DateView timestamp={props.timestamp} />,
          </span>
          <TimeView timestamp={props.timestamp} /> (
          <TimezoneView timestamp={props.timestamp} />)
        </>
      )}
    </>
  );
}

interface TimeDurationViewProps {
  startTimestamp: string | number;
  endTimestamp: string | number;
}
export function TimeDurationView(props: TimeDurationViewProps) {
  return (
    <>
      <TimeView timestamp={props.startTimestamp} /> to{" "}
      <TimeView timestamp={props.endTimestamp} />
    </>
  );
}

interface TimestampDurationViewProps {
  startTimestamp: number | string;
  endTimestamp: number | string;
}
export function TimestampDurationView(props: TimestampDurationViewProps) {
  let startDate = new Date(props.startTimestamp);
  startDate.setHours(0, 0, 0, 0);
  let endDate = new Date(props.endTimestamp);
  endDate.setHours(0, 0, 0, 0);
  if (startDate.toDateString() == endDate.toDateString()) {
    return (
      <>
        <DateView timestamp={props.startTimestamp} />,{" "}
        <TimeDurationView
          startTimestamp={props.startTimestamp}
          endTimestamp={props.endTimestamp}
        />{" "}
        (<TimezoneView timestamp={props.endTimestamp} />)
      </>
    );
  } else {
    return (
      <>
        <TimestampView timestamp={props.startTimestamp} /> to{" "}
        <TimestampView timestamp={props.endTimestamp} />
      </>
    );
  }
}
