import axios from "axios";
import { apiLinkConstants } from "../../../constants";
import { SearchRequest } from "../../../model/search/UserSearchRequestV1";
import { Api, ApiMethod } from "../../common/ApiLinkedButton";
import { Post, PostResponse } from "./Charchaa.model";

export class CharchaaAPI {
  public static async fetchPostById(postId: number): Promise<Post> {
    let api = apiLinkConstants.API_POST_FETCH_SINGLE_BY_ID.replace(
      "{postId}",
      postId + ""
    );
    return new Promise<Post>((resolve, reject) => {
      axios
        .get(api)
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async findAllPosts(): Promise<Post[]> {
    return new Promise<Post[]>((resolve, reject) => {
      axios
        .get(apiLinkConstants.API_POST_LIST_ALL)
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async findAllFilteredPosts(
    searchQuery: SearchRequest
  ): Promise<PostResponse> {
    return new Promise<PostResponse>((resolve, reject) => {
      axios
        .post(apiLinkConstants.API_POST_SEARCH, searchQuery)
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async deletePostById(postId: number): Promise<void> {
    let api = apiLinkConstants.API_POST_DELETE.replace("{postId}", postId + "");
    return new Promise<void>((resolve, reject) => {
      axios
        .delete(api)
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public static async updatePostById(
    postId: number,
    post: Post
  ): Promise<Post> {
    let api = apiLinkConstants.API_POST_UPDATE.replace("{postId}", postId + "");
    return new Promise<Post>((resolve, reject) => {
      axios
        .put(api, post)
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async addPost(post: Post): Promise<Post> {
    let api = apiLinkConstants.API_POST_ADD;
    return new Promise<Post>((resolve, reject) => {
      axios
        .post(api, post)
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static add(): Api<Post, Post> {
    let api = apiLinkConstants.API_POST_ADD;
    return new Api<Post, Post>(api, ApiMethod.POST);
  }
}
