import { StringHelper } from "../helpers/String.Helper";
import { Company } from "./Company";
import { CompanyConstants } from "./Company.Constants";

export class CompanyHelper {
  public static getViewUrl(company: Company): string {
    return CompanyConstants.PAGE_COMPANY_VIEW.replaceAll(
      ":companyId",
      company.id
    ).replaceAll(
      ":slug",
      StringHelper.generateSlug(company.desDl ?? company.dl)
    );
  }

  public static getEditUrl(company: Company): string {
    return CompanyConstants.PAGE_COMPANY_EDIT.replaceAll(
      ":companyId",
      company.id
    ).replaceAll(
      ":slug",
      StringHelper.generateSlug(company.desDl ?? company.dl)
    );
  }
}
