import React from "react";
import { BookingList } from "../booking/Booking.List";
import { ExpertStudioContentProps } from "./ExpertStudio.page";

export function OfferingsBookingsExpertStudioContent(
  props: ExpertStudioContentProps
) {
  return (
    <div className="OfferingsBookingsExpertStudioContent">
      <h1 className="h3 mb-4 text-gray-800">Bookings</h1>
      <BookingList
        layoutProps={{ showBogie: true, showEnginePrice: true }}
        engineIds={[props.user.basicDetails.id]}
      />
    </div>
  );
}
