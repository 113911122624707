import React, { useEffect, useState } from "react";
import { BookingApi } from "../../../booking/Booking.Api";
import { Booking } from "../../../booking/Booking.model";
import { SchedulingPreference } from "../../../calendar/Calendar.model";
import { EBButtonV2 } from "../../../components/common/EBButtonV2";
import { EditTitle } from "../../../components/common/Edit.title";
import { Mode } from "../../../components/common/Form/model/mode.model";
import { GroupCallIcon } from "../../../icon/GroupCall.Icon";
import { BookingAddOrEditForm } from "../../../video-sessions/Booking.AddOrEdit.Form";
import { BookingHelper } from "../../../video-sessions/Booking.Helper";
import { BookingSummaryCard } from "../../../video-sessions/BookingSummary.Card";
import { ContentItemDataGroupCall } from "../../Content.model";
import { ContentItemInputProps } from "../ContentItem.model";

export function ContentItemInputGroupCall(props: ContentItemInputProps) {
  let data: ContentItemDataGroupCall = props.item.data;
  let [booking, setBookig] = useState<Booking>();
  let [loading, setLoading] = useState<boolean>(false);
  let [mode, setMode] = useState<Mode>(Mode.VIEW);

  function toggleMode() {
    if (mode == Mode.VIEW) {
      setMode(Mode.EDIT);
    } else {
      setMode(Mode.VIEW);
    }
  }

  function updateStepData(booking: Booking) {
    if (data) {
      data.videoSessionId = booking.id;
    } else {
      props.item.data = { videoSession: booking.id };
    }
  }

  useEffect(() => {
    if (data && data.videoSessionId) {
      setLoading(true);
      BookingApi.get(data.videoSessionId)
        .execute()

        .then((booking) => {
          setBookig(booking);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [data?.videoSessionId]);

  return (
    <div className="ContentItemInputGroupCall p-3">
      <div className="mb-3 row">
        <div className="col">
          <h6>
            <GroupCallIcon /> Configure Group Call:
          </h6>
          (Group call enables all participants to join it.)
        </div>
        {data && data.videoSessionId && booking && (
          <div className="col-auto">
            <EBButtonV2
              className="btn-sm"
              content={{ normal: <EditTitle /> }}
              onClick={toggleMode}
            />
          </div>
        )}
      </div>
      {data && data.videoSessionId && booking && props.user && (
        <>
          {mode == Mode.VIEW && (
            <>
              <BookingSummaryCard
                booking={booking}
                notifyParticipants={true}
                onSessionUpdate={(booking: Booking) => {
                  setBookig(booking);
                  return Promise.resolve();
                }}
              />
            </>
          )}
          {mode === Mode.EDIT && (
            <BookingAddOrEditForm
              existingBooking={
                booking ??
                BookingHelper.getInitialGroupCall(
                  props.target,
                  props.postedBy.id,
                  SchedulingPreference.MANUAL
                )
              }
              user={props.user}
              onSubmit={function (values: Booking): Promise<Booking> {
                if (data.videoSessionId) {
                  return BookingApi.update(data.videoSessionId)
                    .execute(values)
                    .then((updatedBooking) => {
                      toggleMode();
                      return updatedBooking;
                    });
                }
                return Promise.resolve(values);
              }}
              onChange={props.onChange}
            />
          )}
        </>
      )}
      {props.user && (!data || !data.videoSessionId) && (
        <>
          <BookingAddOrEditForm
            existingBooking={
              booking ??
              BookingHelper.getInitialGroupCall(
                props.target,
                props.postedBy.id,
                SchedulingPreference.MANUAL
              )
            }
            user={props.user}
            onSubmit={function (values: Booking): Promise<Booking> {
              return BookingApi.add()
                .execute(values)
                .then((createdBooking) => {
                  updateStepData(createdBooking);
                  return props.onTriggerSave().then(() => {
                    return createdBooking;
                  });
                })
                .then((createdBooking) => {
                  setBookig(createdBooking);
                  return createdBooking;
                });
            }}
          />
        </>
      )}
    </div>
  );
}
