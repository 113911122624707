import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { BogieName, EngineName } from "../Global.Names";
import { PriceView } from "../components/common/Price.view";
import { TargetLink } from "../components/common/Target.link";
import { TimestampView } from "../components/common/TimestampView";
import { EmptyBookingsComponent } from "../expert-studio/EmptyBookings.component";
import { ExpertStudioConstants } from "../expert-studio/ExpertStudio.constants";
import { LinkHelpers } from "../helpers/Link.Helpers";
import { WindowHelpers } from "../helpers/Window.helper";
import { Link } from "../links/Link";
import { Loader } from "../loader/Loader";
import { StatusComponent } from "../status/Status.Component";
import { TargetOptionsSelectV2 } from "../target/Target.OptionsSelectV2";
import { TargetType, TargetTypeHelper } from "../target/Target.Type.Helper";
import { UserLink } from "../user/User.link";
import { OptionsSelect } from "../user/listing/OptionsSelect";
import { BookingAdminActions } from "./Booking.AdminActions";
import { BookingApi } from "./Booking.Api";
import { BookingAttendeeLink } from "./Booking.Attendee.Link";
import { BookingConstants } from "./Booking.Constants";
import {
  BookingListGroupByHelper,
  FilterGroupBy,
  FilterGroupByEnumId,
} from "./Booking.List.GroupBy.Helper";
import { BookingStatusHelper, FilterStatus } from "./Booking.Status.Helper";
import {
  Booking,
  BookingListRequest,
  BookingStatusEnumId,
} from "./Booking.model";

const customStyles = {
  cells: {
    style: {
      fontSize: "16px",
    },
  },
  headCells: {
    style: {
      fontWeight: "bold",
      fontSize: "16px",
    },
  },
};

interface BookingListProps {
  engineIds?: string[];
  bogieIds?: string[];
  offeringIds?: string[];
  groupBy?: FilterGroupBy;
  layoutProps?: BookingListLayoutProps;
  emptyBookingsComponent?: React.ReactNode;
}

interface BookingListLayoutProps {
  showEngine?: boolean;
  showBogie?: boolean;
  showEnginePrice?: boolean;
  showAdminActions?: boolean;
}

export function BookingList(props: BookingListProps) {
  let [bookings, setBookings] = useState<Booking[]>([]);
  let [loading, setLoading] = useState<boolean>(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [paginationResetDefaultPage, setPaginationResetDefaultPage] =
    useState(false);

  let defaultFilterStatuses: FilterStatus[] = [
    BookingStatusHelper.get(BookingStatusEnumId.PENDING_SCHEDULE),
    BookingStatusHelper.get(BookingStatusEnumId.SCHEDULED),
    BookingStatusHelper.get(BookingStatusEnumId.PENDING_COMPLETE),
  ];
  let filterStatuesFromQp = BookingStatusHelper.listFromQParam(
    BookingConstants.Q_PARAM_STATUSES_KEY,
    LinkHelpers.Q_PARAM_LIST_DELIMETER
  );
  let [filterStatuses, setFilterStatuses] = useState<FilterStatus[]>(
    filterStatuesFromQp && filterStatuesFromQp.length > 0
      ? filterStatuesFromQp
      : defaultFilterStatuses
  );

  let defaultFilterTypes: TargetType[] = [];
  let filterTypesFromQp = TargetTypeHelper.listFromQParam(
    BookingConstants.Q_PARAM_TYPES_KEY,
    LinkHelpers.Q_PARAM_LIST_DELIMETER
  );
  let [filterTypes, setFilterTypes] = useState<TargetType[]>(
    filterTypesFromQp && filterTypesFromQp.length > 0
      ? filterTypesFromQp
      : defaultFilterTypes
  );

  let [filterGroupBy, setFilterGroupBy] = useState<FilterGroupBy>(
    BookingListGroupByHelper.getFromQParam(BookingConstants.Q_PARAM_GROUP_BY) ??
      props.groupBy ??
      BookingListGroupByHelper.get(FilterGroupByEnumId.NONE)
  );

  let bogieIds: string[] = [];
  {
    if (props.bogieIds) {
      bogieIds = [...bogieIds, ...props.bogieIds];
    }
    let bogieIdsQParamStr: string = WindowHelpers.qParam(
      BookingConstants.Q_PARAM_BOGIE_IDS_KEY
    );
    if (bogieIdsQParamStr) {
      let bogieIdsFromQParam: string[] = bogieIdsQParamStr.split(
        LinkHelpers.Q_PARAM_LIST_DELIMETER
      );
      bogieIds = [...bogieIds, ...bogieIdsFromQParam];
    }
  }

  let engineIds: string[] = [];
  {
    if (props.engineIds) {
      engineIds = [...engineIds, ...props.engineIds];
    }
    let engineIdsQParamStr: string = WindowHelpers.qParam(
      BookingConstants.Q_PARAM_ENGINE_IDS_KEY
    );
    if (engineIdsQParamStr) {
      let engineIdsFromQParam: string[] = engineIdsQParamStr.split(
        LinkHelpers.Q_PARAM_LIST_DELIMETER
      );
      engineIds = [...engineIds, ...engineIdsFromQParam];
    }
  }

  useEffect(() => {
    fetch(pageNum, pageSize, filterStatuses, filterTypes, filterGroupBy);
  }, []);

  function fetch(
    pageNum: number,
    pageSize: number,
    filterStatuses: FilterStatus[],
    filterTypes: TargetType[],
    filterGroupBy: FilterGroupBy
  ) {
    setLoading(true);
    let request: BookingListRequest = {
      engineIds: engineIds,
      bogieIds: bogieIds,
      offeringIds: props.offeringIds,
      pageParams: { page: pageNum, size: pageSize },
      groupByBogie: filterGroupBy.groupBy == FilterGroupByEnumId.BOGIE,
      groupByEngine: filterGroupBy.groupBy == FilterGroupByEnumId.ENGINE,
    };

    if (filterStatuses && filterStatuses.length > 0) {
      request.statuses = filterStatuses.map(
        (filterStatus) => filterStatus.status
      );
    }

    if (filterTypes && filterTypes.length > 0) {
      request.bookingTypes = filterTypes.map((type) => type.type);
    }

    BookingApi.list()
      .executeV2(request)
      .then((bookings) => {
        setBookings(bookings.content);
        setTotalRows(bookings.totalSize);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onPageNumChange(pageNum: number) {
    setPageNum(pageNum - 1);
    fetch(pageNum - 1, pageSize, filterStatuses, filterTypes, filterGroupBy);
  }

  function onPageSizeChange(pageSize: number) {
    setPageSize(pageSize);
    fetch(pageNum, pageSize, filterStatuses, filterTypes, filterGroupBy);
  }

  return (
    <div className="BookingList">
      {(!props.offeringIds || props.offeringIds.length == 0) && (
        <>
          <TargetOptionsSelectV2
            initialSelectedOptions={filterTypes.map((type) => {
              return { type: type };
            })}
            compact={true}
            targetOptions={TargetTypeHelper.bookingListFilterList().map(
              (type) => {
                return { type: type };
              }
            )}
            onSelectionUpdate={function (
              selectedOptions: TargetType[]
            ): Promise<void> {
              setPaginationResetDefaultPage(true);
              setFilterTypes(selectedOptions);
              fetch(
                0,
                pageSize,
                filterStatuses,
                selectedOptions,
                filterGroupBy
              );
              return Promise.resolve();
            }}
            className="mb-2"
          />
        </>
      )}
      <OptionsSelect<FilterStatus>
        compact={true}
        options={BookingStatusHelper.list()}
        initialSelectedOptions={filterStatuses}
        onSelectionUpdate={function (
          selectedOptions: FilterStatus[]
        ): Promise<void> {
          setPaginationResetDefaultPage(true);
          setFilterStatuses(selectedOptions);
          fetch(0, pageSize, selectedOptions, filterTypes, filterGroupBy);
          return Promise.resolve();
        }}
        isEqual={function (a: FilterStatus, b: FilterStatus): boolean {
          return a.status == b.status;
        }}
        render={function (option: FilterStatus): React.ReactNode {
          return option.dl;
        }}
        className="mb-2"
      />

      <OptionsSelect<FilterGroupBy>
        compact={true}
        options={BookingListGroupByHelper.list()}
        isSingle={true}
        initialSelectedOptions={[filterGroupBy]}
        onSelectionUpdate={function (
          selectedOptions: FilterGroupBy[]
        ): Promise<void> {
          setPaginationResetDefaultPage(true);
          setFilterGroupBy(selectedOptions[0]);
          fetch(0, pageSize, filterStatuses, filterTypes, selectedOptions[0]);
          return Promise.resolve();
        }}
        isEqual={function (a: FilterGroupBy, b: FilterGroupBy): boolean {
          return a.groupBy == b.groupBy;
        }}
        render={function (option: FilterGroupBy): React.ReactNode {
          return option.dl;
        }}
        className="mb-3"
      />

      <DataTable
        columns={getBookingListColumns(pageNum, pageSize, props.layoutProps)}
        data={bookings}
        fixedHeader={true}
        progressPending={loading}
        progressComponent={<Loader width={"60px"} height={"60px"} />}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        highlightOnHover
        pointerOnHover
        striped
        onChangeRowsPerPage={onPageSizeChange}
        onChangePage={onPageNumChange}
        paginationResetDefaultPage={paginationResetDefaultPage}
        customStyles={customStyles}
        onRowClicked={(booking: Booking) => {
          if (booking.bookingsCnt == undefined || booking.bookingsCnt == 1) {
            WindowHelpers.openInNewTab(booking.url!);
          } else {
            let url = ExpertStudioConstants.PAGE_EXPERT_STUDIO_BOOKINGS;
            if (filterGroupBy.groupBy == FilterGroupByEnumId.BOGIE) {
              url = LinkHelpers.addQueryParam(url, {
                key: BookingConstants.Q_PARAM_BOGIE_IDS_KEY,
                value: booking.bcd.bogieId!,
              });
            }

            if (filterGroupBy.groupBy == FilterGroupByEnumId.ENGINE) {
              url = LinkHelpers.addQueryParam(url, {
                key: BookingConstants.Q_PARAM_ENGINE_IDS_KEY,
                value: booking.bcd.engineId!,
              });
            }

            if (filterStatuses && filterStatuses.length > 0) {
              url = LinkHelpers.addQueryParam(url, {
                key: BookingConstants.Q_PARAM_STATUSES_KEY,
                value: filterStatuses
                  .map((status) => status.status)
                  .join(LinkHelpers.Q_PARAM_LIST_DELIMETER),
              });
            }

            if (filterTypes && filterTypes.length > 0) {
              url = LinkHelpers.addQueryParam(url, {
                key: BookingConstants.Q_PARAM_TYPES_KEY,
                value: filterTypes
                  .map((type) => type.type)
                  .join(LinkHelpers.Q_PARAM_LIST_DELIMETER),
              });
            }

            WindowHelpers.openInNewTab(url);
          }
        }}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        noDataComponent={
          props.emptyBookingsComponent ?? <EmptyBookingsComponent />
        }
      />
    </div>
  );
}

function getBookingListColumns(
  pageNum: number,
  pageSize: number,
  layoutProps?: BookingListLayoutProps
): TableColumn<Booking>[] {
  const columns: TableColumn<Booking>[] = [
    {
      name: "#",
      cell: (row, index) => (
        <Link href={row.url} target="_blank">
          {pageNum * pageSize + index + 1}
        </Link>
      ),
      width: "70px",
    },
    {
      name: "Created At",
      selector: (booking: Booking) => booking.bcd.createdAt ?? "",
      cell: (booking: Booking) => (
        <>
          {booking.bcd.createdAt && (
            <>
              {booking.bookingsCnt == undefined || booking.bookingsCnt == 1 ? (
                <TimestampView timestamp={booking.bcd.createdAt} tzInToolTip />
              ) : (
                "-"
              )}
            </>
          )}
        </>
      ),
      width: "205px",
      wrap: true,
    },
    {
      name: "Type",
      selector: (booking: Booking) => booking.bookingTarget.label ?? "",
      cell: (booking: Booking) =>
        booking.bookingsCnt == undefined || booking.bookingsCnt == 1 ? (
          <TargetLink target={booking.bookingTarget} />
        ) : (
          "-"
        ),
      width: "180px",
      wrap: true,
    },
    {
      name: "Status",
      cell: (booking: Booking) =>
        booking.statusDl && (
          <div className="py-2" data-tag="allowRowEvents">
            {booking.bookingsCnt == undefined || booking.bookingsCnt == 1 ? (
              <StatusComponent status={booking.statusDl} />
            ) : (
              "-"
            )}
          </div>
        ),
      width: "280px",
      wrap: true,
    },
    {
      name: "Title",
      selector: (row: Booking) => row.bcd.dl ?? "",
      width: "350px",
      wrap: true,
      cell: (booking: Booking) => (
        <div className="py-2" data-tag="allowRowEvents">
          {booking.bookingsCnt == undefined || booking.bookingsCnt == 1
            ? booking.bcd.dl
            : `${booking.bookingsCnt} bookings. Click to view.`}
        </div>
      ),
    },
  ];

  if (layoutProps?.showEnginePrice) {
    columns.push({
      name: "Price",
      cell: (booking: Booking) => (
        <div className="py-2">
          {(booking.bookingsCnt == undefined || booking.bookingsCnt == 1) &&
          booking.bcd.enginePrice ? (
            <PriceView price={booking.bcd.enginePrice} />
          ) : (
            "-"
          )}
        </div>
      ),

      width: "150px",
    });
  }

  if (layoutProps?.showEngine) {
    columns.push({
      name: EngineName.SENTENCE_START,
      cell: (booking: Booking) => <UserLink user={booking.bcd.engine} />,
      width: "220px",
      wrap: true,
    });
  }

  if (layoutProps?.showBogie) {
    columns.push({
      name: BogieName.SENTENCE_START,
      cell: (booking: Booking) => (
        <BookingAttendeeLink booking={booking} className="py-2" />
      ),
      width: "220px",
      wrap: true,
    });
  }

  if (layoutProps?.showAdminActions) {
    columns.push({
      name: BogieName.SENTENCE_START,
      cell: (booking: Booking) => <BookingAdminActions booking={booking} />,
      width: "320px",
      wrap: true,
    });
  }
  return columns;
}
