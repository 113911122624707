import { useField } from "formik";
import React from "react";
import { FormTemplate } from "../model/formTemplate.model";
import { FormTemplateSectionBuilder } from "./Form.Section.TemplateBuilder";
import { FormTemplateBuilderProps } from "./Form.Template.Builder";

export function FormTemplateDataBuilder(props: FormTemplateBuilderProps) {
  const [field] = useField(props);
  let template = field.value as FormTemplate;
  return (
    <span className="FormTemplateBuilderFormikInput">
      {template && template.sections && template.sections.length > 0 && (
        <FormTemplateSectionBuilder name={`${props.name}.sections.0`} />
      )}
    </span>
  );
}
