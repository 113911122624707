import React from "react";
import { InterviewRoundAPI } from "../../../api/InterviewRoundAPI";
import { InterviewFeedbackResponse } from "../../../model/response/InterviewFeedback.response";
import { PageProps } from "../../../model/views/PageProps";

interface InterviewRoundInterviewerFeedbackPageState {
  feedback: InterviewFeedbackResponse;
}

interface InterviewRoundInterviewerFeedbackPageProps extends PageProps {
  interviewRoundId: string;
}

export class InterviewRoundInterviewerFeedbackPage extends React.Component<
  InterviewRoundInterviewerFeedbackPageProps,
  InterviewRoundInterviewerFeedbackPageState
> {
  componentDidMount() {
    this.fetchFeedback();
  }

  callback() {
    window.location.reload();
  }

  fetchFeedback() {
    InterviewRoundAPI.getInterviewRoundFeedback(
      this.props.interviewRoundId
    ).then((response) => {
      this.setState({ feedback: response });
    });
  }

  render() {
    return (
      <div className="main-container">
        {this.state && this.state.feedback && this.props.user && (
          <div>
            {/* <InterviewFeedbackForm callback={} feedback={{}} questionCategories={[]} /> */}
          </div>
        )}
      </div>
    );
  }
}
