import React from "react";
import { ButtonText } from "../../model/views/Button.props";

export interface EBButtonProps {
  text: ButtonText;
  disabled?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
  classes?: string[];
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  otherProps?: any;
  id?: string;
}
export function EBButton(props: EBButtonProps) {
  let otherProps = props.otherProps;
  if (!otherProps) {
    otherProps = {};
  }
  return (
    <>
      {props.loading && (
        <button
          disabled={props.disabled}
          onClick={props.onClick}
          {...otherProps}
          className={
            props.classes ? props.classes.join(" ") : "btn btn-primary"
          }
          type={props.type ? props.type : "button"}
          id={props.id}
        >
          <div>
            <div className="hstack gap-2">
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              {props.text.submitting ?? "Please wait..."}
            </div>
          </div>
        </button>
      )}
      {!props.loading && (
        <button
          disabled={props.disabled}
          onClick={props.onClick}
          {...otherProps}
          className={
            props.classes ? props.classes.join(" ") : "btn btn-primary"
          }
          type={props.type ? props.type : "button"}
          id={props.id}
        >
          {props.text.normal}
        </button>
      )}
    </>
  );
}
