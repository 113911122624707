import React from "react";
import PublicPage from "../components/pages/PublicPage";
import { publicLinkConstants } from "../constants";
import { ExpertStudioConstants } from "../expert-studio/ExpertStudio.constants";
import { HomeRemaining } from "./HomeContent";

class Comp extends React.Component<{}, {}> {
  render() {
    const headerLinks = [
      // {
      //   id: "about",
      //   text: "About Us",
      //   href: "#about",
      // },
      // { id: "forcompanies", text: "For Companies", href: "/for-companies" },
      {
        id: "expert-studio",
        text: "Expert Studio",
        href: ExpertStudioConstants.PAGE_EXPERT_STUDIO_BOOKINGS_GROUP_BY_BOGIE,
      },
      {
        id: "interview-experiences",
        text: "Interview Experiences",
        href: publicLinkConstants.INTERVIEW_EXPERIENCES,
      },
      {
        id: "question",
        text: "Problems",
        href: publicLinkConstants.QUESTIONS,
      },
      {
        id: "charchaa",
        text: "Charchaa",
        href: publicLinkConstants.CHARCHAA,
      },
    ];
    return (
      <PublicPage
        contentComponent={HomeRemaining}
        headerLinks={headerLinks}
        showFooterOnMobile={true}
      />
    );
  }
}

export default Comp;
