import React from "react";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import {
  RadioButtonGroupFormikInput,
  RadioButtonGroupFormikInputOption,
} from "../components/common/price/RadioButton.Group.FormikInput";
import {
  TimeDurationFormikInput,
  TimeDurationOptionGranularity,
} from "./TimeDuration.FormikInput";

export interface TimeDurationOption {
  valueInSecs: number;
  displayLabel: string;
}

interface EditTimeDurationFormikInputProps extends FormikInputParams {
  timeDurationOptions: TimeDurationOption[];
  customInputGranularity: TimeDurationOptionGranularity;
  supportCustom?: boolean;
}

export function EditTimeDurationFormikInput(
  props: EditTimeDurationFormikInputProps
) {
  let options: RadioButtonGroupFormikInputOption<number>[] = [];

  for (let timeOption of props.timeDurationOptions) {
    options.push({
      value: timeOption.valueInSecs,
      dl: timeOption.displayLabel,
    });
  }

  return (
    <div className="FormikTimeDurationInput">
      <RadioButtonGroupFormikInput
        options={options}
        isEqual={(val1: number, val2: number) => {
          return val1 == val2;
        }}
        customInput={
          props.supportCustom && <TimeDurationFormikInput {...props} />
        }
        {...props}
      />
    </div>
  );
}
