import React, { useEffect, useState } from "react";
import { AchievementApi } from "../achievements/Achievement.Api";
import { UserAchievement } from "../achievements/Achievement.Model";
import { CallbackButton } from "../components/common/ApiLinkedButton";
import CopyClipboardTextArea from "../components/common/CopyCliboard.textarea";
import { ImageComponent } from "../components/common/Image.component";
import { Loader } from "../components/common/Loader.component";
import { ImageGeneratorResponse } from "../image/Image.model";
import { ShareableContent } from "./Share.model";

interface ShareAchievementComponentProps {
  userAchievement: UserAchievement;
}

export function ShareAchievementComponent(
  props: ShareAchievementComponentProps
) {
  let [image, setImage] = useState<ImageGeneratorResponse | undefined>();
  let [shareContentResponse, setShareContentResponse] = useState<
    ShareableContent | undefined
  >();
  let [loading, setLoading] = useState<boolean | undefined>();

  useEffect(() => {
    setLoading(true);
    setShareContentResponse(undefined);

    AchievementApi.getUserAchievementImage(props.userAchievement.id)
      .execute()
      .then((response) => {
        setImage(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.userAchievement]);

  function createSharePostContent(): Promise<void> {
    return AchievementApi.getUserAchievementContent(props.userAchievement.id)
      .execute()
      .then((shareResponse) => {
        setShareContentResponse(shareResponse);
        return Promise.resolve();
      });
  }

  return (
    <div className="ShareAchievementComponent">
      {loading && <Loader />}
      {!loading && image && (
        <div className="row">
          <div className="col-md-6 mb-2">
            <ImageComponent
              image={image.image}
              maxWidth={"100%"}
              showDownloadForUrlType={true}
            />
          </div>
          <div className="col-md-6">
            <CallbackButton
              buttonProps={{
                text: {
                  normal: "Create Content to Share",
                  submitting: "Creating!!",
                },
              }}
              callback={createSharePostContent}
              uniqueKey={
                "user-achievement-" + props.userAchievement.id + "-share"
              }
            />
            {shareContentResponse && (
              <div className="mt-2 fs-3">
                <CopyClipboardTextArea
                  initialValue={shareContentResponse.textContent}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
