import { AssetLocation } from "../model/Picture";

export interface Topic {
  topicId: TopicId;
  dl: string;
  dp?: AssetLocation;
  topicType: TopicType;
  parent?: Topic;
  markedUseForFilterAt?: number;
}

export interface TopicId {
  type: TopicType;
  id: string;
}

export enum TopicType {
  CHANNEL = "CHANNEL",
  SKILL = "SKILL",
  COMPANY = "COMPANY",
  DESIGNATION = "DESIGNATION",
  COUNTRY = "COUNTRY",
  OTHER = "OTHER",
}

export interface TopicTypePojo {
  type: TopicType;
  dl: string;
}
