import React, { useEffect } from "react";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { ApiLinkedButtonV3 } from "../components/common/Form/ApiLinked.button.V3";
import { User } from "../user/User";
import { MarketingApi } from "./Marketing.Api";

interface MarketingComponentProps {
  liu: User;
}

export function MarketingComponent(props: MarketingComponentProps) {
  function onClickCreate() {
    // MarketingApi.post()
    //   .executeV2({ content: "" })
    //   .then(() => {
    //     message.success("posted");
    //   });
  }
  useEffect(() => {}, []);
  return (
    <div className="MarketingComponent">
      <EBButtonV2 content={{ normal: "create post" }} onClick={onClickCreate} />

      <ApiLinkedButtonV3
        buttonProps={{ content: { normal: "Schedule Post" } }}
        api={MarketingApi.post()}
        uniqueKey={"marketing-schedule"}
      />
    </div>
  );
}
