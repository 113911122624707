import React from "react";
import { AttachmentsAPI } from "../../../api/AttachmentsAPI";
import { Attachment, AttachmentLocationType } from "../../../model/Attachment";
import { EBModal } from "../EBModal";
import { URLInputForm } from "../Form/URLInputForm";

interface UpdateAttchmentViewProps {
  attachmentsUpdateEndpoint: string;
  callback: () => Promise<void>;
  uniqueKey: string;
  attachment: Attachment;
  attachmentDisplayName: string;
}

function UpdateAttachmentView(mainProps: UpdateAttchmentViewProps) {
  let submitCallback = (url: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      let updatedAttachment = mainProps.attachment;
      updatedAttachment.attachmentLocationData = url;
      updatedAttachment.attachmentLocationType = AttachmentLocationType.URL;
      AttachmentsAPI.createOrUpdateAttachment(
        mainProps.attachmentsUpdateEndpoint,
        updatedAttachment
      )
        .then(() => {
          resolve();
          mainProps.callback();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  function Body({}) {
    return (
      <URLInputForm
        initialValue={mainProps.attachment.attachmentLocationData}
        submitCallback={submitCallback}
      />
    );
  }

  let modalId = mainProps.uniqueKey + "-attachment";
  return (
    <div className="update-attachment-view">
      <EBModal
        id={modalId}
        title={"Update " + mainProps.attachmentDisplayName}
        BodyComponent={<Body />}
      />
      <button
        type="button"
        className="btn btn-sm btn-secondary"
        data-bs-toggle="modal"
        data-bs-target={"#" + modalId}
      >
        <i className="fas fa-edit"></i>
      </button>
    </div>
  );
}

interface AttachmentViewProps {
  attachment: Attachment;
  attachmentDisplayName: string;
  uniqueKey: string;
  updateCallback?: () => Promise<void>;
  attachmentsUpdateEndpoint?: string;
}

export function AttachmentView(props: AttachmentViewProps) {
  return (
    <div className="hstack gap-2">
      {props.attachment.attachmentLocationData ? (
        <a
          target="_blank"
          className="text-dark"
          href={props.attachment.attachmentLocationData}
        >
          Link
        </a>
      ) : (
        "Not Present"
      )}
      {props.attachmentsUpdateEndpoint && props.updateCallback && (
        <UpdateAttachmentView
          attachmentsUpdateEndpoint={props.attachmentsUpdateEndpoint}
          callback={props.updateCallback}
          attachmentDisplayName={props.attachmentDisplayName}
          uniqueKey={props.uniqueKey}
          attachment={props.attachment}
        />
      )}
    </div>
  );
}
