import React from "react";
import { AdminUserWithdrawalList } from "./AdminUserWithdrawalList";

export class AdminWithdrawalsPageContent extends React.Component {
  render() {
    return (
      <div className="main-container">
        <h1>Withdrwals</h1>

        <div>
          <AdminUserWithdrawalList />
        </div>
      </div>
    );
  }
}
