import React from "react";
import { OfferingConstants } from "../offerings/Offering.Constants";
import { OfferingDetailPageContent } from "../offerings/detail-page/Offering.Detail.PageContent";
import { OfferingPathConstants } from "../offerings/path/Offering.Path.constants";
import { OfferingsPathContentEditPageContent } from "../offerings/path/Offerings.PathContent.Edit.PageContent";
import { OfferingsPathContentViewPageContent } from "../offerings/path/Offerings.PathContent.View.PageContent";
import { PublicPageRoute } from "./PublicPage.route";

export function OfferingRoutes() {
  return (
    <>
      <PublicPageRoute
        path={OfferingPathConstants.PAGE_USER_OFFERING_PATH_CONTENT_EDIT}
        contentComponent={OfferingsPathContentEditPageContent}
      />

      <PublicPageRoute
        path={OfferingPathConstants.PAGE_USER_OFFERING_PATH_CONTENT_VIEW}
        contentComponent={OfferingsPathContentViewPageContent}
      />

      <PublicPageRoute
        path={OfferingConstants.PAGE_USER_OFFERING}
        contentComponent={OfferingDetailPageContent}
      />

      <PublicPageRoute
        path={OfferingConstants.PAGE_USER_OFFERING_FRIENDLY}
        contentComponent={OfferingDetailPageContent}
      />
    </>
  );
}
