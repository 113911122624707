import React from "react";
import { publicLinkConstants } from "../constants";
import { AddInterviewExperiencePageContent } from "../interivew-experience/AddInterviewExperience.PageContent";
import { EditInterviewExperiencePageContent } from "../interivew-experience/EditInterviewExperience.PageContent";
import { InterviewExperienceDetailsPage } from "../interivew-experience/InterviewExperienceDetailsPage";
import { InterviewExperiencesPageContent } from "../interivew-experience/InterviewExperiencesPageContent";
import { PublicPageRoute } from "./PublicPage.route";

export function InterviewExperienceRoutes() {
  return (
    <>
      <PublicPageRoute
        path={publicLinkConstants.INTERVIEW_EXPERIENCE_ADD}
        contentComponent={AddInterviewExperiencePageContent}
      />
      <PublicPageRoute
        path={publicLinkConstants.INTERVIEW_EXPERIENCE_EDIT}
        contentComponent={EditInterviewExperiencePageContent}
      />
      <PublicPageRoute
        exact
        path={publicLinkConstants.INTERVIEW_EXPERIENCE_DETAILS}
        contentComponent={InterviewExperienceDetailsPage}
      />
      <PublicPageRoute
        exact
        path={publicLinkConstants.INTERVIEW_EXPERIENCES}
        contentComponent={InterviewExperiencesPageContent}
      />
    </>
  );
}
