import React from "react";
import { User, UserBasicDetails } from "../user/User";
import {
  Question,
  QuestionTypeDataMcq,
  QuestionTypeEnum,
  QuestionTypeInfo,
} from "./Question.Model";
import { AnswerSubmission } from "./QuestionAnswerSubmission.model";
import { QuestionTypeMcqComponent } from "./QuestionTypeInfoMcq.Component";

interface QuestionTypeInfoComponentProps {
  typeInfo?: QuestionTypeInfo;
  question: Question;
  user?: User;
  onSubmitAnswer?: (answer: AnswerSubmission) => void;
}

export function QuestionTypeInfoComponent(
  props: QuestionTypeInfoComponentProps
) {
  return (
    <div className="QuestionTypeInfoInput">
      {props.typeInfo?.type?.typeEnum && (
        <div className="mt-3">
          {props.typeInfo.type?.typeEnum === QuestionTypeEnum.MCQ && (
            <>
              <QuestionTypeMcqComponent
                mcqData={props.typeInfo.data as QuestionTypeDataMcq}
                question={props.question}
                user={props.user}
                onSubmitAnswer={props.onSubmitAnswer}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}
