import React from "react";
import { LoginAPI } from "../api/LoginAPI";
import { EBButton } from "../components/common/EBButton";
import { EBModal } from "../components/common/EBModal";
import { User } from "../user/User";
import { IOfferingV2 } from "./Offering";
import { OfferingCardV2 } from "./Offering.Card";
import "./OfferingCard.css";

export interface OfferingCardPopUpV2Props {
  offering: IOfferingV2;
  registrationNode: React.ReactNode;
  loggedInUser?: User;
  hideRegistrations?: boolean;
  className?: string;
}

export function OfferingV2PopUpCard(props: OfferingCardPopUpV2Props) {
  let modalId =
    "offeirng-" +
    props.offering.commonDetails.type.typeName +
    "-" +
    (props.offering.id + "").replaceAll(".", "-") +
    "-modal";
  let modalToggleButtonId = modalId + "-toggle-button";

  function onOfferingSelect() {
    if (
      props.hideRegistrations ||
      !props.offering.commonDetails.bookingLimitPerUserAvailable
    ) {
      // Do nothing in case registration is not supported on this.
      // Do nothing in case booking limit per user is already consumed.
      return;
    }
    if (!props.loggedInUser) {
      LoginAPI.redirectForLogin();
      return;
    }

    document.getElementById(modalToggleButtonId)?.click();
  }

  const offering = props.offering;
  return (
    <>
      <OfferingCardV2
        offering={props.offering}
        className={props.className}
        onOfferingSelect={onOfferingSelect}
      />
      <EBButton
        type="button"
        classes={["d-none"]}
        otherProps={{
          "data-bs-toggle": "modal",
          "data-bs-target": "#" + modalId,
        }}
        text={{ normal: "a" }}
        id={modalToggleButtonId}
      />
      <EBModal
        BodyComponent={<>{props.registrationNode}</>}
        title={props.offering.commonDetails.dl}
        id={modalId}
      />
    </>
  );
}
