import { Col, Row } from "antd";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { CompanyAPI } from "../../../company/CompanyAPI";
import { DesignationAPI } from "../../../api/DesignationAPI";
import { DesignationSelector } from "../../../designation/Designation.selector";
import { SearchTokenHelper } from "../../../helpers/Search.helper";
import { WindowHelpers } from "../../../helpers/Window.helper";
import { Company } from "../../../company/Company";
import { Designation } from "../../../model/Designation";
import { SearchToken } from "../../../model/search/SearchToken";
import { SearchTokenDataDesignation } from "../../../model/search/data/SearchTokenDataDesignation";
import { SearchProps } from "../../../search/Search.Model";
import { EBButtonV2 } from "../EBButtonV2";
import { CompanySelector } from "../../../company/CompanySelector.component";

interface InterviewExperienceFilterViewInitialValues {
  companyIds?: string[];
  designationId?: string;
}

export function InterviewExperienceFilterView(filterProps: SearchProps) {
  let initialValues =
    filterProps.initialValues as InterviewExperienceFilterViewInitialValues;

  let [allDesignations, setAllDesignations] = useState<Designation[]>();
  let [allCompanies, setAllCompanies] = useState<Company[]>();
  useEffect(() => {
    DesignationAPI.fetchAllDesignations().then((designations) => {
      setAllDesignations(designations);
    });

    CompanyAPI.fetchAllCompanies().then((companies) => {
      setAllCompanies(companies);
    });
  }, []);

  return (
    <div className="InterviewExperienceFilterView card bg-transparent mb-3">
      <Formik
        initialValues={{
          companies: [],
          designation: undefined,
          showMine: WindowHelpers.qParam("my") === "true",
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          let searchTokens: SearchToken[] = [];
          if (values.companies && values.companies.length > 0) {
            searchTokens.push(
              SearchTokenHelper.getCompanyToken(values.companies)
            );
          }
          if (values.designation) {
            const designationSearchData: SearchTokenDataDesignation = {
              designation: values.designation,
            };
            searchTokens.push(
              SearchTokenHelper.getDesignationToken(designationSearchData)
            );
          }
          searchTokens.push(
            SearchTokenHelper.getShowMineToken(values.showMine)
          );
          filterProps.onSearch(searchTokens).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="">
              <Row
                align={"middle"}
                gutter={[20, 20]}
                className="mt-3 mb-3 mx-2"
              >
                <Col flex={"auto"}>
                  {allCompanies && (
                    <CompanySelector
                      name="companies"
                      isMulti={true}
                      companies={allCompanies}
                      placeholder="Select company to filter"
                    />
                  )}
                </Col>
                <Col flex={"auto"}>
                  {allDesignations && (
                    <DesignationSelector
                      name="designation"
                      required={false}
                      designations={allDesignations}
                      placeholder="Select designaton to filter"
                    />
                  )}
                </Col>
                <Col>
                  {filterProps.user && (
                    <>
                      <Field
                        type="checkbox"
                        id="showMine"
                        className="custom-control-input"
                        name="showMine"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="showMine"
                      >
                        Show only mine
                      </label>
                    </>
                  )}
                </Col>
                <Col>
                  <EBButtonV2
                    className="btn btn-secondary"
                    content={{
                      normal: props.isSubmitting
                        ? "Filtering. Please Wait..."
                        : "Filter",
                    }}
                    disabled={props.isSubmitting}
                    type="submit"
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
