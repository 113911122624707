import { DurationTimestamp } from "../calendar/Calendar.model";
import { Count } from "../model/Count.model";
import { Price } from "../model/Price";
import { Target } from "../model/target.model";
import { UserBasicDetails } from "../user/User";

export interface UserCoupon {
  userId: string;
  couponCode: string;
  duration: DurationTimestamp;
  maxCount: Count;
  reward: CouponReward;
  isEnabled: boolean;
  allowedEntities: CouponEntity[];
  minPrice: Price;
  specificUserEmail?: string;
}

export enum CouponEntityType {
  USER = "USER",
  VIDEO_OFFERING = "VIDEO_OFFERING",
  TEXT_OFFERING = "TEXT_OFFERING",
  DIGITAL_PRODUCT = "DIGITAL_PRODUCT",
  OFFERING_PATH = "OFFERING_PATH",
  OFFERING_EVENT = "OFFERING_EVENT",
}

export interface CouponEntity {
  type: CouponEntityType;
  id: string;
}

export enum CouponRewardType {
  DISCOUNT_PERCENT = "DISCOUNT_PERCENT",
  DISCOUNT_AMOUNT = "DISCOUNT_AMOUNT",
}

export interface CouponReward {
  rewardType: CouponRewardType;
  value: number;
}

export interface CouponApplyResponse {
  newPrice: Price;
  message: string;
}

export interface CouponInstance {
  id: number;
  userId: string;
  couponCode: string;
  blockedAt: string;
  blockedExpiryAt: string;
  blockedBy: string;
  redeemedAt: string;
  redeemedBy: UserBasicDetails;
  redeemTarget: Target;
  status: CouponInstanceStatus;
}

export enum CouponInstanceStatus {
  BLOCKED = "BLOCKED",
  REDEEMED = "REDEEMED",
  UNUSED_ACTIVE = "UNUSED_ACTIVE",
}
