import React from "react";
import { Center } from "../../components/common/layout/Center.component";
import { BottomFixedComponent } from "../../layout/BottomFixed.Component";
import { OfferingDetailPageLayoutProps } from "./Offering.DetailPage.Layout.Model";

export function OfferingDetailPageRightStickyLayout(
  props: OfferingDetailPageLayoutProps
) {
  return (
    <>
      <div className="OfferingDetailPageRightStickyLayout">
        <div className="event-data-desktop">
          <div className="row">
            <div className="col-md-8 col-xl-9">
              {props.headerContent}
              <div className="">{props.mainContent}</div>
              <div>{props.reviewContent}</div>
            </div>
            <div className="col-md-4 col-xl-3 position-fixed right-20px z-100">
              <Center>
                <div className="mb-3 card card-body border-primary bg-dark shimmer">
                  {props.registrationContent}
                </div>
              </Center>
              {props.sideBarTopContent}
            </div>
          </div>
        </div>
        <div className="event-data-mobile">
          <div className="pb-60px">
            {props.headerContent}
            {props.mainContent && <div>{props.mainContent}</div>}

            {props.sideBarTopContent && (
              <div className="mt-3">{props.sideBarTopContent}</div>
            )}
            {props.reviewContent}
          </div>
          <BottomFixedComponent className="bg-main-body">
            <div className="py-3 bg-dark shimmer">
              <Center>{props.registrationContent}</Center>
            </div>
          </BottomFixedComponent>
        </div>
      </div>
    </>
  );
}
