import axios from "axios";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { LinkHelpers } from "../helpers/Link.Helpers";
import { ImageGeneratorResponse } from "../image/Image.model";
import { SearchRequest } from "../model/search/UserSearchRequestV1";
import { PageResponse } from "../paging/Paging.Model";
import { User, UserBasicDetails } from "./User";
import { UserConstants } from "./User.Constants";

export class UserAPI {
  static loggedInUser: User;

  public static getUserShareableImage(
    userId: string
  ): Promise<ImageGeneratorResponse> {
    let api = apiLinkConstants.API_USER_SHAREABLE_IMAGE.replace(
      "{userId}",
      userId
    );
    return new Api<void, ImageGeneratorResponse>(api, ApiMethod.GET).execute();
  }

  public static async fetchLoggedInUser(
    includeRelations?: boolean,
    forceRefresh?: boolean
  ): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      if (UserAPI.loggedInUser != null && !forceRefresh) {
        resolve(UserAPI.loggedInUser);
      } else {
        let api = "/api/user";
        if (includeRelations) {
          api = LinkHelpers.addQueryParam(api, {
            key: "includeRelations",
            value: "true",
          });
        }
        axios
          .get(api)
          .then((res) => {
            const user = res.data;
            UserAPI.loggedInUser = user;
            resolve(UserAPI.loggedInUser);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  public static async fetchBasicUserDetails(
    userId: string,
    includeUserSummary: boolean
  ): Promise<UserBasicDetails> {
    return new Promise<UserBasicDetails>((resolve, reject) => {
      axios
        .get(
          "/api/user/" +
            userId +
            "?" +
            `${includeUserSummary && "includeUserSummary=true"}`
        )
        .then((res) => {
          const user = res.data;
          resolve(user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static searchUsers(): Api<SearchRequest, PageResponse<User>> {
    return new Api<SearchRequest, PageResponse<User>>(
      UserConstants.API_USER_SEARCH,
      ApiMethod.POST
    );
  }

  public static update(userId: string): Api<any, void> {
    return new Api<any, void>("/api/user/" + userId, ApiMethod.PUT);
  }
}
