import React from "react";
import { ImageComponent } from "../components/common/Image.component";
import { UserBasicDetails } from "./User";

interface UserLinkProps {
  user?: UserBasicDetails;

  imgSize?: number;
  imgRight?: boolean;
}

export function UserLink(props: UserLinkProps) {
  return (
    <a href={props.user?.profileUrl} target="_blank" className="text-dark">
      {!props.imgRight && props.user?.dp && (
        <ImageComponent
          className="mr-2 rounded-circle"
          image={props.user?.dp}
          width={props.imgSize ?? 35}
        />
      )}

      {props.user?.dl}

      {props.imgRight && props.user?.dp && (
        <ImageComponent
          className="ml-2 rounded-circle"
          image={props.user?.dp}
          width={props.imgSize ?? 35}
        />
      )}
    </a>
  );
}
