import React from "react";
import { EBTimezone } from "../calendar/Calendar.model";
import { TZSelector } from "../calendar/TZSelector.component";
import { Label } from "../components/common/Form/elements/FormInputLabel";
import { SkillSelector } from "../components/common/SkillSelector.component";
import { MarkdownInput } from "../components/common/input/Markdown.input";
import { EBTextInputV2 } from "../components/common/input/TextLine.input";
import { CountrySelector } from "../country/CountrySelector.component";
import { Country } from "../model/Country.model";
import { Skill } from "../model/Skill";
import { SocialType } from "../social/Social.model";
import { SocialLinksInput } from "../social/SocialLinks.input";
import { User } from "./User";
import { UserNameInput } from "./UserName.input";

interface UserEditProfileBasicDetailsInputProps {
  skills: Skill[];
  tzs: EBTimezone[];
  countries: Country[];
  socialTypes: SocialType[];
  user: User;
}

export function UserEditProfileBasicDetailsInput(
  props: UserEditProfileBasicDetailsInputProps
) {
  return (
    <div className="UserEditProfileBasicDetailsInput">
      <div className="row">
        <div className="col-md-3 mb-3">
          <Label label="First name:" required />
          <EBTextInputV2
            name="firstName"
            required
            placeholder="Enter your first name here"
          />
        </div>
        <div className="col-md-3 mb-3">
          <Label label="Last name:" required />
          <EBTextInputV2
            name="lastName"
            placeholder="Enter your last name here"
          />
        </div>
        <div className="col-md-6 mb-3">
          <Label label="Tagline:" required />
          <EBTextInputV2 name="tagline" required />
        </div>
      </div>

      <div className="row">
        <div className="col-md-2 mb-3">
          <Label label="Phone: " required />
          <EBTextInputV2
            name="phone"
            required
            placeholder="Example: +91-9876543210"
          />
        </div>
        <div className="col-md-2 mb-3">
          <TZSelector
            label="Timezone:"
            tzs={props.tzs}
            name={`tz`}
            required={true}
          />
        </div>
        <div className="col-md-2 mb-3">
          <CountrySelector
            label="Country:"
            countries={props.countries}
            name={`country`}
            required={true}
          />
        </div>
        <div className="col-md-6">
          <UserNameInput
            label="Username:"
            name="username"
            required
            placeholder="Will be used for your page link: https://enginebogie/u/<username>"
          />
        </div>
      </div>

      <div className="row">
        <div className="col mb-3">
          <Label label="About:" required />
          <MarkdownInput name={"aboutMe"} user={props.user} />
        </div>
      </div>
      <div className="row">
        <div className="col mb-3">
          <SkillSelector
            label="Skills"
            skills={props.skills}
            name={`skills`}
            isMulti={true}
            required={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Label label={"Social Profile Links:"} required />
          <SocialLinksInput
            allSocialTypes={props.socialTypes}
            name={"socialLinks"}
          />
        </div>
      </div>
    </div>
  );
}
