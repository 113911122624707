import React from "react";
import { WindowHelpers } from "../../../helpers/Window.helper";
import { EBButtonV2 } from "../../common/EBButtonV2";

export interface RoundedPillButtonProps {
  redirectUrl: string;
  title?: string;
}
export function RoundedPillButton(props: RoundedPillButtonProps) {
  return (
    <div className="rounded-pill bg-white border px-2 bg-hover-light">
      <a
        href={props.redirectUrl}
        className="hstack gap-2 text-decoration-none text-muted"
      >
        <EBButtonV2
          content={{ normal: <i className="fas fa-plus"></i> }}
          className="btn btn-primary rounded-circle ml-1 mr-2"
        />
        <span
          className="cursor-pointer py-3"
          onClick={() => {
            WindowHelpers.redirect(props.redirectUrl);
          }}
        >
          {props.title}
        </span>
      </a>
    </div>
  );
}
