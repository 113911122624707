let API_BASE = "/api";

export class BookingConstants {
  public static API_BOOKING_LIST = API_BASE + "/bookings";
  public static API_BOOKING_SINGLE = API_BASE + "/bookings/{bookingId}";

  public static API_BOOKING_ADD = API_BASE + "/bookings:add";

  public static API_BOOKING_FEEDBACK_FORM_USER =
    API_BASE +
    "/bookings/{bookingType}/{bookingId}/feedback-form/user/{userId}";
  public static API_BOOKING_FEEDBACK_FORM_LIST =
    API_BASE + "/bookings/{bookingType}/{bookingId}/feedback-forms";
  public static API_BOOKING_SINGLE_MARK_COMPLETE =
    API_BASE + "/bookings/{bookingType}/{bookingId}/complete/user/{userId}";

  public static API_BOOKING_SINGLE_NOTIF_ENGINE_SUPPRESS =
    API_BASE +
    "/bookings/{bookingType}/{bookingId}/notifications/engine/suppress";
  public static API_BOOKING_SINGLE_NOTIF_ENGINE_UNSUPPRESS =
    API_BASE +
    "/bookings/{bookingType}/{bookingId}/notifications/engine/unsuppress";
  public static API_BOOKING_BOGIES =
    API_BASE + "/bookings/{bookingType}/{bookingId}/bogies";
  public static API_BOOKING_NOTIF_SEND_SESSION_CREATED =
    API_BASE + "/bookings/{bookingId}/notifications/send/created";

  public static PAGE_BOOKING = "/bookings/{bookingId}";

  public static Q_PARAM_STATUSES_KEY = "statuses";
  public static Q_PARAM_TYPES_KEY = "types";
  public static Q_PARAM_BOGIE_IDS_KEY = "bIds";
  public static Q_PARAM_ENGINE_IDS_KEY = "eIds";
  public static Q_PARAM_GROUP_BY = "group";
}
