import { message } from "antd";
import React from "react";
import { Booking } from "../booking/Booking.model";
import { EBForm } from "../components/common/Form/EBForm";
import { User } from "../user/User";
import { BookingInput } from "./Booking.Input";

interface BookingAddOrEditFormProps {
  existingBooking?: Booking;
  user: User;
  onSubmit: (values: Booking) => Promise<Booking>;
  onChange?: () => Promise<void>;
}

export function BookingAddOrEditForm(props: BookingAddOrEditFormProps) {
  return (
    <div className="BookingAddOrEditForm">
      <EBForm
        onChange={props.onChange}
        submitButton={{
          className: "float-right mb-3",
          content: {
            normal: "Save",
            submitting: "Saving...",
          },
        }}
        initialValues={props.existingBooking}
        formBody={<BookingInput user={props.user} />}
        onSubmit={(booking) => {
          return props.onSubmit(booking).then(() => {
            message.success("Booking Saved!");
          });
        }}
      />
    </div>
  );
}
