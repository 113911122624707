import React from "react";

export function SessionDetailItemComponent(props: {
  items: {
    title: React.ReactNode;
    content: React.ReactNode;
  }[];
}) {
  return (
    <div className="card border-left-warning shadow h-100 py-2">
      <div className="card-body">
        {props.items.map((item, index) => (
          <div className="row no-gutters align-items-center" key={index}>
            <div className="col mr-2">
              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                {item.title}
              </div>
              <div className="h5 mb-0 font-weight-bold text-gray-800">
                {item.content}
              </div>
            </div>
            <div className="col-auto"></div>
          </div>
        ))}
      </div>
    </div>
  );
}
