import { BookingConstants } from "../booking/Booking.Constants";
import { FilterGroupByEnumId } from "../booking/Booking.List.GroupBy.Helper";
import { LinkHelpers } from "../helpers/Link.Helpers";

export class ExpertStudioConstants {
  public static PATH_EXPERT_STUDIO_PDS_EDIT = "/expert/studio/pds/edit";

  public static PATH_EXPERT_STUDIO_EDIT_PATH = "/expert/studio/paths/edit";
  public static PATH_EXPERT_STUDIO_BOOKINGS_PATH =
    "/expert/studio/paths/bookings";

  public static PAGE_EXPERT_STUDIO_BOOKINGS = "/expert/studio/bookings";
  public static PAGE_EXPERT_STUDIO_BOOKINGS_GROUP_BY_BOGIE =
    LinkHelpers.addQueryParam(this.PAGE_EXPERT_STUDIO_BOOKINGS, {
      key: BookingConstants.Q_PARAM_GROUP_BY,
      value: FilterGroupByEnumId.BOGIE,
    });
}
