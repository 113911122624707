import { AssetLocation } from "./Picture";

export enum AttachmentLocationType {
  URL,
}

export interface Attachment {
  attachmentName: string;
  attachmentLocationType: AttachmentLocationType;
  attachmentLocationData: string;
  createdAt: string;
}

export interface AttachmentV1 {
  id: string;
  title: string;
  location: AssetLocation;
  createdAt: string;
}
