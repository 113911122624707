import React, { useEffect, useState } from "react";
import { apiLinkConstants } from "../../constants";
import { LedgerAdminApi } from "../../payments/ledger/Ledger.admin.api";
import { LedgerBalance } from "../../payments/ledger/Ledger.model";
import { Withdrawal } from "../../payments/withdrawal/Withdrawal.model";
import { UserLink } from "../../user/User.link";
import { Api, ApiLinkedButton, ApiMethod } from "../common/ApiLinkedButton";
import { Loader } from "../common/Loader.component";
import { PriceView } from "../common/Price.view";
import { Center } from "../common/layout/Center.component";
import { reloadWindowPromise } from "../common/reloadCallback";

export function AdminLedgerBalancesPageContent() {
  let [ledgerBalances, setLedgerBalances] = useState<LedgerBalance[]>();
  let [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    setLoading(true);
    LedgerAdminApi.getLedgerBalances().then((ledgerBalances) => {
      setLedgerBalances(ledgerBalances);
      setLoading(false);
    });
  }, []);

  return (
    <div className="main-container">
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {ledgerBalances && ledgerBalances.length > 0 && (
        <>
          <div>
            {ledgerBalances.map((ledgerBalance, index) => (
              <div className="mt-2 card card-body">
                <div className="row">
                  <div className="col-md-3">
                    <UserLink user={ledgerBalance.user} />
                  </div>

                  <div className="col-md-3">
                    <PriceView price={ledgerBalance.balance} />
                  </div>
                  <div className="col-md-3">
                    <ApiLinkedButton
                      api={
                        new Api<void, Withdrawal>(
                          apiLinkConstants.API_USER_LEDGER_BALANCE_WITHDRAW.replaceAll(
                            "{userId}",
                            ledgerBalance.user.id
                          ),
                          ApiMethod.POST
                        )
                      }
                      inputData={
                        {
                          paymentAmount: ledgerBalance.balance,
                        } as Withdrawal
                      }
                      uniqueKey={"user" + ledgerBalance.user.id + "-withdraw"}
                      callback={reloadWindowPromise}
                      buttonProps={{
                        text: {
                          normal: "Withdraw",
                          submitting: "Please Wait...",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {ledgerBalances && ledgerBalances.length == 0 && (
        <>No ledger balances found.</>
      )}
    </div>
  );
}
