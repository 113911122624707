import { Price } from "../../model/Price";
import { Target } from "../../model/target.model";
import { StatusDl } from "../../status/Status.model";
import { UserBasicDetails } from "../../user/User";

export interface PaymentReleaseRequest {
  id: string;
  user: UserBasicDetails;
  paymentAmount: Price;
  createdAt: Date;
  creationComments: string;
  verifiedAt: Date;
  verificationComments: string;
  rejectedAt: Date;
  rejectionComments: string;
  cancelledAt: Date;
  workCompletedAt: Date;
  source: Target;
  status: PaymentReleaseRequestStatus;
  statusDl?: StatusDl;
}

export enum PaymentReleaseRequestStatus {
  CREATED = "CREATED",
  VERIFIED = "VERIFIED",
  REJECTED = "REJECTED",
  WORK_COMPLETED = "WORK_COMPLETED",
  CANCELLED = "CANCELLED",
}

export interface PaymentReleaseRequestSummary {
  total: Price;
  verified: Price;
  rejected: Price;
  pending: Price;
  workCompleted: Price;
  cancelled: Price;
}

export interface PaymentReleaseRequestsResponse {
  paymentReleaseRequests: PaymentReleaseRequest[];
  summaries: PaymentReleaseRequestSummary[];
}
