import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { SearchTokenHelper } from "../../../helpers/Search.helper";
import { WindowHelpers } from "../../../helpers/Window.helper";
import { SearchToken } from "../../../model/search/SearchToken";
import { SearchTokenTopic } from "../../../model/search/data/SearchTokenTopic";
import { Topic } from "../../../topic/Topic.model";
import { TopicAPI } from "../../../topic/TopicAPI";
import { TopicSelector } from "../../../topic/TopicSelector.component";
import { User } from "../../../user/User";
import { EBTextInput } from "../../common/eb-ui-components";
import { Col, Row } from "antd";
import { EBButtonV2 } from "../../common/EBButtonV2";
import { EBTextInputV2 } from "../../common/input/TextLine.input";

interface PostFilterViewProps {
  callback: (searchTokens: SearchToken[]) => Promise<void>;
  user?: User;
}

export function PostFilterView(filterProps: PostFilterViewProps) {
  let [topics, setTopics] = useState<Topic[]>();
  useEffect(() => {
    TopicAPI.getListingTopics().then((topics) => {
      setTopics(topics);
    });
  }, []);
  return (
    <div className="PostFilterView card bg-transparent p-3 mb-3">
      <Formik
        initialValues={{
          topics: [],
          searchQuery: undefined,
          showMine: WindowHelpers.qParam("my") === "true",
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          let searchTokens: SearchToken[] = [];
          searchTokens.push(
            SearchTokenHelper.getShowMineToken(values.showMine)
          );
          if (values.topics && values.topics.length > 0) {
            let topicTokenData: SearchTokenTopic = {
              topics: values.topics,
            };
            searchTokens.push(SearchTokenHelper.getTopicToken(topicTokenData));
          }
          if (values.searchQuery) {
            searchTokens.push(
              SearchTokenHelper.getSearchStringToken(values.searchQuery)
            );
          }

          filterProps.callback(searchTokens).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="">
              <Row align={"middle"} gutter={[20, 20]}>
                <Col flex={"auto"}>
                  {topics && (
                    <TopicSelector
                      placeholder="Select topic to filter"
                      label=""
                      topics={topics}
                      name={"topics"}
                      isMulti={true}
                    />
                  )}
                </Col>
                <Col flex={"auto"}>
                  {/* <label className="modal-title">Search Post:</label> */}
                  <EBTextInputV2
                    label=""
                    name="searchQuery"
                    placeholder="Enter search query to filter"
                    helpText=""
                  />
                </Col>
                <Col>
                  {filterProps.user && (
                    <>
                      <Field
                        type="checkbox"
                        id="showMine"
                        className="custom-control-input"
                        name="showMine"
                      />

                      <label
                        className="custom-control-label"
                        htmlFor="showMine"
                      >
                        Show only mine
                      </label>
                    </>
                  )}
                </Col>
                <Col>
                  <EBButtonV2
                    className="btn btn-secondary"
                    content={{
                      normal: props.isSubmitting
                        ? "Filtering. Please Wait..."
                        : "Filter",
                    }}
                    disabled={props.isSubmitting}
                    type="submit"
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
