import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { ReviewsAPI } from "../api/Reviews.api";
import { CallbackButton } from "../components/common/ApiLinkedButton";
import { Loader } from "../components/common/Loader.component";
import { Center } from "../components/common/layout/Center.component";
import { ReviewModernView } from "../components/common/review/Review.MordernView";
import { HTMLProps } from "../model/HTMLProps";
import { RatingsSummary, ReviewV1 } from "../model/Reviews";
import "./UserFullProfileReviews.css";

export interface ReviewsListComponentProps {
  reviews: ReviewV1[];
  showLoadMore: boolean;
  loadMoreCallback: () => Promise<void>;
}

export function ReviewsListComponent(props: ReviewsListComponentProps) {
  return (
    <div>
      {props.reviews && props.reviews.length > 0 ? (
        <div className="fw-light ">
          <div className="row">
            {props.reviews.map((review, index) => (
              <ReviewModernView
                review={review}
                className="col-md-12 col-lg-6 col-xl-4 col-xxl-3 mt-2 mb-2 "
              />
            ))}
          </div>
          <div>
            {props.showLoadMore && (
              <div className="mt-3">
                <Center>
                  <CallbackButton
                    buttonProps={{
                      text: {
                        normal: "Load more reviews",
                        submitting: "Loading",
                      },
                    }}
                    callback={props.loadMoreCallback}
                    uniqueKey={"reviews-load-more"}
                  ></CallbackButton>
                </Center>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>No Reviews Yet.</div>
      )}
    </div>
  );
}

export interface ReviewsComponentProps {
  userId: string;
}

export function ReviewsComponent(props: ReviewsComponentProps) {
  let [reviews, setReviews] = useState<ReviewV1[]>();
  let [ratingsSummary, setRatingsSummary] = useState<RatingsSummary>();
  let [loading, setLoading] = useState(false);

  let [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  let [page, setPage] = useState<number>(0);

  let pageSize = 9;

  function fetchNextReviews(): Promise<void> {
    setLoading(true);
    return ReviewsAPI.getReceivedReviews(props.userId, page, pageSize)
      .then((reviewsResponse) => {
        setShowLoadMore(reviewsResponse.reviews.length == pageSize);
        if (reviews) {
          setReviews(reviews.concat(reviewsResponse.reviews));
        } else {
          setReviews(reviewsResponse.reviews);
        }
        setRatingsSummary(reviewsResponse.ratingsSummary);
        setPage(page + 1);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function loadMoreCallback(): Promise<void> {
    return fetchNextReviews();
  }

  useEffect(function () {
    fetchNextReviews();
  }, []);

  return (
    <div className="ReviewsComponent">
      {!reviews && (
        <Center>
          <Loader></Loader>
        </Center>
      )}
      {ratingsSummary && ratingsSummary.ratingsCount > 0 && (
        <>
          <div className="event-data-desktop">
            <RatingsSummaryLargeView
              padding={"pt-4 px-4 pb-3"}
              titleClass={"h2 h-100 align-middle"}
              iconClass={"h1 ml-4"}
              ratingsSummary={ratingsSummary}
              className="mb-3"
            />
          </div>
          <div className="event-data-mobile">
            <RatingsSummaryLargeView
              padding={"pt-3 px-3 pb-2"}
              titleClass={"h4  h-100 align-middle"}
              iconClass={"h3 ml-3"}
              ratingsSummary={ratingsSummary}
              className="mb-3"
            />
          </div>
        </>
      )}

      {reviews && reviews.length > 0 && (
        <ReviewsListComponent
          reviews={reviews}
          showLoadMore={showLoadMore}
          loadMoreCallback={loadMoreCallback}
        />
      )}
    </div>
  );
}

interface RatingsSummaryProps extends HTMLProps {
  padding: string;
  titleClass: string;
  iconClass: string;
  ratingsSummary: RatingsSummary;
}

function RatingsSummaryLargeView(props: RatingsSummaryProps) {
  return (
    <Row gutter={[16, 16]} className={props.className}>
      <Col>
        <div
          className={`card card-body rounded-3 ${props.titleClass} ${props.padding} `}
        >
          <div className="hstack">
            {props.ratingsSummary?.ratingsCount !== undefined &&
              props.ratingsSummary?.ratingsCount > 0 &&
              `${props.ratingsSummary?.ratingsCount}`}{" "}
            Reviews
            <i
              className={`fas fa-jet-fighter-up text-secondary  ${props.iconClass}`}
            ></i>
          </div>
        </div>
      </Col>
      <Col>
        <div
          className={`card card-body rounded-3 ${props.titleClass} ${props.padding}`}
        >
          {props.ratingsSummary?.dlAvgRating && (
            <div className="hstack">
              {props.ratingsSummary.dlAvgRating}

              <i className={`fas fa-star text-primary ${props.iconClass}`}></i>
            </div>
          )}
        </div>
      </Col>
      {props.ratingsSummary.expertAvgRating > 4.5 &&
        props.ratingsSummary.ratingsCount > 20 && (
          <>
            <Col>
              <div
                className={`card card-body rounded-3 ${props.titleClass} ${props.padding} `}
              >
                {props.ratingsSummary?.dlAvgRating && (
                  <div className="hstack">
                    Top Rated
                    <i
                      className={`fas fa-ranking-star text-secondary ${props.iconClass}`}
                    ></i>
                  </div>
                )}
              </div>
            </Col>
          </>
        )}
    </Row>
  );
}
