import React from "react";
import { EventTicket } from "../events/Event.Model";
import { TimestampDurationView, TimestampView } from "./common/TimestampView";

interface EventTicketRegistrationMessageProps {
  ticket: EventTicket;
}

export class EventTicketRegistrationMessageComponent extends React.Component<
  EventTicketRegistrationMessageProps,
  {}
> {
  render() {
    return (
      <>
        {this.props.ticket.saleDuration &&
        new Date(this.props.ticket.saleDuration?.fromMillis) > new Date() ? (
          <>
            Registration will open from{" "}
            <mark>
              <TimestampDurationView
                startTimestamp={this.props.ticket.saleDuration?.fromMillis}
                endTimestamp={this.props.ticket.saleDuration?.toMillis}
              />
            </mark>
          </>
        ) : (
          <>
            {this.props.ticket.saleDuration &&
            new Date(this.props.ticket.saleDuration?.toMillis) < new Date() ? (
              <>Registration closed</>
            ) : (
              <>
                Registration open till{" "}
                <mark>
                  {this.props.ticket.saleDuration && (
                    <TimestampView
                      timestamp={this.props.ticket.saleDuration?.toMillis}
                    />
                  )}
                </mark>
              </>
            )}
          </>
        )}
      </>
    );
  }
}
