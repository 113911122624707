import { Price } from "../Price";

export enum PaymentProcessorName {
  RAZORPAY = "RAZORPAY",
  FREE = "FREE",
  PAYPAL = "PAYPAL",
}

export interface CheckoutResponse {
  name: PaymentProcessorName;
}
