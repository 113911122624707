import React from "react";
import { GroupCallIcon } from "../icon/GroupCall.Icon";

export function GroupCallTitle() {
  return (
    <>
      <GroupCallIcon /> Group Call
    </>
  );
}
