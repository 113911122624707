let API_BASE = "/api";
export class CompanyConstants {
  public static API_COMPANY_LIST = API_BASE + "/companies";
  public static API_COMPANY_ADD = API_BASE + "/companies";
  public static API_COMPANY_UPDATE = API_BASE + "/companies/{companyId}";
  public static API_COMPANY_GET = API_BASE + "/companies/{companyId}";

  public static PAGE_COMPANY_EDIT = "/companies/:companyId/:slug/edit";
  public static PAGE_COMPANY_VIEW = "/companies/:companyId/:slug";
}
