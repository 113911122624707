import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React, { useEffect } from "react";
import { CompaniesBadgeList } from "../badge/Companies.BadgeList";
import { PendingApprovalBadge } from "../badge/PendingApproval.Badge";
import { EditTitle } from "../components/common/Edit.title";
import { MarkdownViewV1 } from "../components/common/MarkdownView";
import { UrlHelpers } from "../helpers/Helpers";
import { Link } from "../links/Link";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { HTMLProps } from "../model/HTMLProps";
import { ViewsManager } from "../reactions/Views.Manager";
import { TopicBadge } from "../topic/Topic.Badge";
import { User, UserBasicDetails } from "../user/User";
import { AnswerSubmissionStatusBadge } from "./AnswerSubmissionStatus.Badge";
import { DifficultyBadge } from "./Difficulty.badge";
import { Question } from "./Question.Model";
import { AnswerSubmission } from "./QuestionAnswerSubmission.model";
import { QuestionTypeInfoComponent } from "./QuestionTypeInfo.Component";
import { QuestionAdminOptions } from "./Question.AdminOptions";
import { QuestionDl } from "./Question.Dl";
import { ArchivedBadge } from "../badge/Archived.Badge";
import {
  QuestionBadgesViewTop,
  QuestionBagdesViewBottom,
} from "./Question.Badges";
import { ReactionCountsViewComponent } from "../components/common/ReactionCountsViewComponent";

interface QuestionViewProps extends HTMLProps {
  question: Question;
  user?: User;
  hideEditBtn?: boolean;
  onSubmitAnswer?: (answer: AnswerSubmission) => void;
}

export function QuestionView(props: QuestionViewProps) {
  let question = props.question;
  return (
    <div className={`QuestionView card card-body ${props.className}`}>
      <div className="d-flex">
        <div className="flex-grow-1">
          <h1 className="h4">
            <QuestionDl ques={question} />
          </h1>
        </div>
        <div>
          {question.canUserEdit && !props.hideEditBtn && (
            <div className="justify-content-end">
              <Link
                href={UrlHelpers.getEditQuestionDetailsUrl(
                  question.slug,
                  question.id
                )}
                className="float-right btn btn-light btn-sm"
              >
                <EditTitle text="Edit" />
              </Link>
            </div>
          )}
        </div>
      </div>
      <QuestionBadgesViewTop
        question={question}
        liu={props.user}
        showApproval={true}
        showDifficulty={true}
      />
      <div className="mt-2">
        <QuestionContentView
          question={question}
          user={props.user}
          onSubmitAnswer={props.onSubmitAnswer}
        />
      </div>
      <div className="mt-2">
        <QuestionBagdesViewBottom question={question} />
      </div>

      {question.reactionCounts && (
        <div className="mt-2">
          <ReactionCountsViewComponent
            targetType={AttachmentTargetType.QUESTION}
            targetId={"" + question.id}
            reactionCounts={question.reactionCounts}
            // hideText={props.hideReactionText}
          />
        </div>
      )}

      <QuestionAdminOptions
        question={question}
        liu={props.user}
        className="mt-2"
      />
    </div>
  );
}

interface QuestionContentViewProps extends HTMLProps {
  question: Question;
  user?: User;
  onSubmitAnswer?: (answer: AnswerSubmission) => void;
}

export function QuestionContentView(props: QuestionContentViewProps) {
  useEffect(() => {
    ViewsManager.log({
      targetType: AttachmentTargetType.QUESTION,
      targetId: props.question.id + "",
    });
  }, []);
  return (
    <span className={`QuestionContentView ${props.className}`}>
      <MarkdownViewV1>{props.question.statement}</MarkdownViewV1>
      <QuestionTypeInfoComponent
        typeInfo={props.question.typeInfo}
        question={props.question}
        user={props.user}
        onSubmitAnswer={props.onSubmitAnswer}
      />
    </span>
  );
}

interface QuestionAccordionViewProps {
  question: Question;
  user?: User;
  onSubmitAnswer?: (answer: AnswerSubmission) => void;
  expanded?: boolean;
}

export function QuestionAccordionView(props: QuestionAccordionViewProps) {
  let question = props.question;
  return (
    <span className="QuestionAccordionView">
      <Collapse
        defaultActiveKey={props.expanded ? [question.id] : []}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={[
          {
            key: question.id,
            label: (
              <div className="d-flex">
                <div className="flex-grow-1">
                  <div className="h5">
                    <QuestionDl ques={question} />
                  </div>
                  <div>
                    <QuestionBagdesViewBottom question={question} />
                  </div>
                </div>

                <div className="justify-content-end pl-4 pr-2 hstack gap-2">
                  {question.answerStatus && (
                    <AnswerSubmissionStatusBadge
                      status={question.answerStatus}
                    />
                  )}
                  <DifficultyBadge difficulty={question.difficulty} />
                </div>
              </div>
            ),
            children: (
              <QuestionContentView
                question={question}
                user={props.user}
                onSubmitAnswer={props.onSubmitAnswer}
              />
            ),
          },
        ]}
      />
    </span>
  );
}
