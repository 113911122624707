export const serviceOfferingHelpers = {
  offeringsMapping: {
    mock_interview: {
      displayName: "Mock Interview",
    },
    mentorship: {
      displayName: "Mentorship",
    },
    teaching: {
      displayName: "Teaching",
    },
    case_preparation: {
      displayName: "Case Preparation",
    },
    resume_review: {
      displayName: "Resume Review",
    },
  },

  getServiceNameFromId: function (offeringId) {
    // TODO: Handle not found case.
    return this.offeringsMapping[offeringId].displayName;
  },
  getDashboardEditOfferingsMap: function () {
    var response = [];
    for (var key in this.offeringsMapping) {
      response.push({
        value: key,
        label: this.offeringsMapping[key].displayName,
      });
    }
    return response;
  },
};
