import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { FormikHelpers } from "../../../helpers/Formik.Helpers";
import { ButtonDisplayProps } from "../../../model/views/Button.props";
import { FormikInputParams } from "../input/FormikInputParams.model";
import { RadioButtonGroupComponent } from "../input/RadioButtonGroup.component";

export interface RadioButtonGroupFormikInputProps<T> extends FormikInputParams {
  options: RadioButtonGroupFormikInputOption<T>[];
  isEqual: (val: any, val2: any) => boolean;
  customInput?: React.ReactNode;
}

export interface RadioButtonGroupFormikInputOption<T> {
  value: T;
  dl: React.ReactNode;
}

export const RadioButtonGroupFormikInput = <T extends any>(
  props: RadioButtonGroupFormikInputProps<T>
) => {
  let options: RadioButtonGroupFormikInputOption<T>[] = props.options;

  const [field, meta] = useField({
    ...props,
    validate: (value: any) => {
      if (props.required && !value) {
        return "Required";
      }
    },
  });

  function setFieldValue(value: any) {
    FormikHelpers.updateField(field, props.name, value);
  }

  let valueIndex: number | undefined = undefined;
  let index = 0;
  let opBtns: ButtonDisplayProps[] = [];
  for (let option of options) {
    if (field && field.value && props.isEqual(field.value, option.value)) {
      valueIndex = index;
    }
    index++;
    opBtns.push({
      text: {
        normal: option.dl,
        submitting: "",
      },
      extraClassNames: "btn-sm",
      type: "secondary",
    });
  }

  let customIndex = index;
  if (props.customInput) {
    opBtns.push({
      text: {
        normal: "Custom",
        submitting: "",
      },
      extraClassNames: "btn-sm",
      type: "secondary",
    });
  }

  let [selectedButtonIndex, setSelectedButtonIndex] = useState<
    number | undefined
  >(customIndex);

  function onOptionChange(index: number) {
    if (index == customIndex) {
      setSelectedButtonIndex(index);
    } else {
      setSelectedButtonIndex(index);
      setFieldValue(options[index].value);
    }
  }

  useEffect(() => {
    if (valueIndex != undefined) {
      setSelectedButtonIndex(valueIndex);
    }
  }, [valueIndex]);
  return (
    <div className="RadioButtonGroupFormikInput">
      <div>
        <RadioButtonGroupComponent
          buttonProps={opBtns}
          onButtonClick={onOptionChange}
          selectedIndex={selectedButtonIndex}
          disabled={props.disabled}
        />
      </div>

      {props.customInput && selectedButtonIndex == customIndex && (
        <>{props.customInput}</>
      )}
      {meta.error ? <div className="text-danger">{meta.error}</div> : null}
    </div>
  );
};
