import axios from "axios";
import { UserBasicDetails } from "../user/User";

export class SpoofHelper {
  private static SPOOF_HEADER_KEY = "x-spoof-user";
  /**
   * spoof
   */
  public static spoof(user: UserBasicDetails) {
    console.log(localStorage);
    localStorage.setItem(this.SPOOF_HEADER_KEY, JSON.stringify(user));
    console.log(localStorage);
  }

  public static unspoof() {
    localStorage.removeItem(this.SPOOF_HEADER_KEY);
  }

  public static currentSpoof(): UserBasicDetails | undefined {
    let spoofStoredVal = localStorage.getItem(this.SPOOF_HEADER_KEY);
    if (!spoofStoredVal) {
      return undefined;
    }
    try {
      return JSON.parse(spoofStoredVal);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  public static addCurrentSpoofingToAxiosHeaders() {
    let spoof = this.currentSpoof();
    if (spoof) {
      axios.defaults.headers.common[this.SPOOF_HEADER_KEY] = spoof.id;
    }
  }
}
