import { Field } from "formik";
import React from "react";
import { AnswersType } from "../../../model/AnswerType.enum";
import { GenericFeedbackAnswer } from "../../../model/requests/InterviewRoundFeedback";
import { SelectItem } from "../../../model/views/Select.props";
import { Mode } from "../Form/model/mode.model";
import { EBSelect, EBTextInput } from "../eb-ui-components";
import { FormikInputParams } from "./FormikInputParams.model";
import { FormikRatingNumberSelect } from "./FormikRatingSelect";

interface FormikGenericQuestionInputProps extends FormikInputParams {
  genericAnswer: GenericFeedbackAnswer;
}
export function FormikGenericQuestionNumberInput(
  props: FormikGenericQuestionInputProps
) {
  return (
    <>
      {props.mode == Mode.EDIT && (
        <EBTextInput helpText={""} label={""} name={props.name} />
      )}

      {props.mode == Mode.VIEW && <>{props.genericAnswer.answer}</>}
    </>
  );
}

let booleanOptions: SelectItem[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
  { value: "na", label: "Not Applicable" },
];

function mapToBooleanOption(input?: string): SelectItem | undefined {
  if (!input) {
    return undefined;
  }
  for (let option of booleanOptions) {
    if (option.value === input) {
      return option;
    }
  }
  return undefined;
}

export function FormikGenericQuestionBooleanInput(
  props: FormikGenericQuestionInputProps
) {
  function mapToInput(selectItem: SelectItem): string {
    return selectItem.value;
  }

  return (
    <>
      {props.mode == Mode.EDIT && (
        <Field
          name={props.name}
          component={EBSelect}
          options={booleanOptions}
          isMulti={false}
          mappingRequired={true}
          selectToApiMapper={mapToInput}
          apiToSelectMapper={mapToBooleanOption}
        />
      )}
      {props.mode == Mode.VIEW && (
        <>{mapToBooleanOption(props.genericAnswer.answer)?.label}</>
      )}
    </>
  );
}
export function FormikGenericQuestionQuestionRatingInput(
  props: FormikGenericQuestionInputProps
) {
  return (
    <>
      {props.mode == Mode.EDIT && (
        <FormikRatingNumberSelect
          name={props.name}
          minRating={1}
          maxRating={10}
        />
      )}

      {props.mode == Mode.VIEW && <>{props.genericAnswer.answer}/10</>}
    </>
  );
}
export function FormikGenericQuestionInput(
  props: FormikGenericQuestionInputProps
) {
  if (props.genericAnswer.question.type === AnswersType.NUMBER) {
    return <FormikGenericQuestionNumberInput {...props} />;
  } else if (props.genericAnswer.question.type === AnswersType.BOOL) {
    return <FormikGenericQuestionBooleanInput {...props} />;
  } else if (
    props.genericAnswer.question.type === AnswersType.QUESTION_RATING
  ) {
    return <FormikGenericQuestionQuestionRatingInput {...props} />;
  }
  throw new Error(
    "Incorrect answer type received: " + props.genericAnswer.question.type
  );
}
