import React, { useEffect, useState } from "react";
import { AddTitle } from "../components/common/Add.title";
import { ApiLinkedButtonV3 } from "../components/common/Form/ApiLinked.button.V3";
import { Loader } from "../components/common/Loader.component";
import { FormInputLabel } from "../components/common/eb-ui-components";
import { EBTextInputV2 } from "../components/common/input/TextLine.input";
import { Center } from "../components/common/layout/Center.component";
import { SidebarLayoutSliding } from "../layout/Sidebar.Layout";
import { List, ListItemProps } from "../list/List";
import { ListItemLayoutDefault } from "../list/ListItem.Layout";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { OfferingCommunity } from "../offerings/Offering";
import { User } from "../user/User";
import { CommunityApi } from "./Community.Api";
import { CommunityGroupApi } from "./CommunityGroup.Api";
import { CommunityGroupComponent } from "./CommunityGroup.Component";
import { CommunityGroup } from "./CommunityGroup.Model";

interface CommunityComponentProps {
  communityId: string;
  liu?: User;
}

export function CommunityComponent(props: CommunityComponentProps) {
  let [community, setCommunity] = useState<OfferingCommunity>();
  let [curCg, setCurCg] = useState<CommunityGroup>();
  let [loading, setLoading] = useState<boolean>();
  let [renderCount, setRenderCount] = useState(1);

  function onSelectCommunityGroup(group: CommunityGroup): Promise<void> {
    setCurCg(group);
    return Promise.resolve();
  }

  useEffect(
    function () {
      setLoading(true);
      CommunityApi.get(props.communityId)
        .executeV2()
        .then(function (communityResp) {
          setCommunity(communityResp);
          if (communityResp.groups && communityResp.groups.length > 0) {
            setCurCg(communityResp.groups[0]);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [props.communityId]
  );
  return (
    <span className="CommunityComponent">
      {loading && (
        <Center className="p-2">
          <Loader />
        </Center>
      )}
      {!loading && community && (
        <SidebarLayoutSliding
          sidebar={
            <>
              <div className="p-3 bg-light fw-bolder">
                {community.commonDetails.dl}
              </div>
              <CommunitySidebar
                community={community}
                onSelectCommunityGroup={onSelectCommunityGroup}
                onAddCommunityGroupSuccess={(cg, index) => {
                  if (community?.groups) {
                    community.groups.push(cg);
                    setRenderCount(renderCount + 1);
                  }
                  return Promise.resolve();
                }}
              />
            </>
          }
          mainContent={
            <div className="p-4">
              {curCg && <CommunityGroupComponent cg={curCg} liu={props.liu} />}
            </div>
          }
        />
      )}
    </span>
  );
}

interface CommunitySidebarProps {
  community: OfferingCommunity;
  onSelectCommunityGroup?: (
    item: CommunityGroup,
    index: number
  ) => Promise<void>;
  onAddCommunityGroupSuccess?: (
    item: CommunityGroup,
    index: number
  ) => Promise<void>;
}

function CommunitySidebar(props: CommunitySidebarProps) {
  let community = props.community;
  return (
    <div>
      <CommunityGroupsList {...props} />
      <Center className="">
        <ApiLinkedButtonV3
          className="btn-secondary btn-sm my-2 mb-3"
          buttonProps={{
            content: {
              normal: (
                <>
                  <AddTitle text="Add Group" />
                </>
              ),
            },
          }}
          successCallback={(cg: CommunityGroup) => {
            if (props.onAddCommunityGroupSuccess) {
              return props.onAddCommunityGroupSuccess(cg, -1);
            }
            return Promise.resolve();
          }}
          api={CommunityGroupApi.add({
            targetType: AttachmentTargetType.OFFERING_COMMUNITY,
            targetId: props.community.id + "",
          })}
          formModalSize="md"
          apiFormBody={
            <div className="form-group">
              <FormInputLabel
                label="Display label for the group that you are creating:"
                required={true}
              />
              <EBTextInputV2
                name={"dl"}
                placeholder="Ex: Software Engineers, System Design, etc"
                required={true}
              />
            </div>
          }
          uniqueKey={"comm-" + props.community.id + "-add-group-btn"}
        />
      </Center>
    </div>
  );
}

function CommunityGroupsList(props: CommunitySidebarProps) {
  return (
    <div className="CommunityGroupsList">
      <List
        onFetch={function (): Promise<any[]> {
          return Promise.resolve(props.community.groups);
        }}
        ItemComponent={CommunityGroupsListItem}
        ItemLayoutComponent={ListItemLayoutDefault}
        onClickItem={props.onSelectCommunityGroup}
      />
    </div>
  );
}

function CommunityGroupsListItem(props: ListItemProps) {
  let grp = props.item as CommunityGroup;
  return <div>{grp.dl}</div>;
}
