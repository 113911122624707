import React, { useEffect, useState } from "react";

import { HTMLProps } from "../../../model/HTMLProps";
import { IOfferingV2, Offerings } from "../../../offerings/Offering";
import { OfferingsCarousel } from "../../../offerings/Offering.Carousel";
import { OfferingAPI } from "../../../offerings/Offering.api";

interface OfferingsUserSidebarProps extends HTMLProps {
  userId: string;
}

export function OfferingsUserSidebar(props: OfferingsUserSidebarProps) {
  let [offerings, setOfferings] = useState<Offerings>();
  useEffect(() => {
    OfferingAPI.getOfferings(props.userId + "").then((offerings) => {
      setOfferings(offerings);
    });
  }, []);

  let finalOfferings: IOfferingV2[] = [];
  if (offerings?.videoSessions) {
    finalOfferings = finalOfferings.concat(offerings.videoSessions);
  }

  if (offerings?.offlineQueries) {
    finalOfferings = finalOfferings.concat(offerings.offlineQueries);
  }

  if (offerings?.products) {
    finalOfferings = finalOfferings.concat(offerings.products);
  }
  if (offerings?.paths) {
    finalOfferings = finalOfferings.concat(offerings.paths);
  }

  return (
    <>
      {finalOfferings && finalOfferings.length > 0 && (
        <div className={`OfferingsUserSidebar ${props.className}`}>
          <OfferingsCarousel offerings={finalOfferings} />
        </div>
      )}
    </>
  );
}
