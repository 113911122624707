import React from "react";
import { UserCouponsEditComponent } from "../coupon/UserCouponsEdit.component";
import { ExpertStudioContentProps } from "./ExpertStudio.page";

interface CouponExpertStudioContentProps extends ExpertStudioContentProps {}

export function CouponExpertStudioContent(
  props: CouponExpertStudioContentProps
) {
  return (
    <div className="CouponExpertStudioContentProps">
      <h3>
        <i className="fa-solid fa-tag"></i> Coupons
      </h3>

      <div className="">
        <UserCouponsEditComponent user={props.user.basicDetails} />
      </div>
    </div>
  );
}
