import React from "react";
import { BookingComponentWrapper } from "../../../booking/Booking.ComponentWrapper";
import { ReactMatch } from "../../../model/ReactMatchObj";
import { PageProps } from "../../../model/views/PageProps";

interface SessionDetailsPageContentPathParams {
  sessionId: string;
}

interface SessionDetailsPageContentProps extends PageProps {
  match: ReactMatch<SessionDetailsPageContentPathParams>;
}

export class SessionDetailsPageContent extends React.Component<SessionDetailsPageContentProps> {
  render() {
    return (
      <div className="main-container">
        <BookingComponentWrapper
          bookingId={this.props.match.params.sessionId}
          user={this.props.user}
        />
      </div>
    );
  }
}
