import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { MarketingConstants } from "./Marketing.Constants";
import { MarketingPost } from "./Marketing.Model";

let API_BASE = "/api";
export class MarketingApi {
  public static post(): Api<MarketingPost, void> {
    return new Api<MarketingPost, void>(
      MarketingConstants.API_MARKETING_CREATE_POST,
      ApiMethod.POST
    );
  }
}
