import React from "react";
import { ListItemPlainLayout } from "../list/ListItem.Layout";
import { HorizontalSpan, ListItemProps, ListV2 } from "../list/ListV2";
import { HTMLProps } from "../model/HTMLProps";
import { PageResponse } from "../paging/Paging.Model";
import { UserBasicDetails } from "../user/User";
import { UserSidebarProfile } from "../user/UserProfile.Sidebar";
import { RelatedContentApi } from "./RelatedContent.api";
import { ContentContext } from "./RelatedContent.model";

interface RelatedExpertsComponentProps extends HTMLProps {
  context: ContentContext;
  horizontalLayout?: boolean;
  horizontalSpan?: HorizontalSpan;

  header?: React.ReactNode;
  size?: number;
}

export function RelatedExpertsComponent(props: RelatedExpertsComponentProps) {
  return (
    <div className="RelatedExpertsComponent">
      <ListV2
        gutter={[20, 20]}
        contentClassName={props.className}
        onFetch={function (): Promise<PageResponse<any>> {
          return RelatedContentApi.getRelatedExperts({
            context: props.context,
            pageParams: { page: 0, size: props.size ?? 4 },
          }).then((experts) => {
            return { content: experts, totalSize: experts.length };
          });
        }}
        ItemLayoutComponent={ListItemPlainLayout}
        hidePaging={true}
        itemNode={(itemProps: ListItemProps) => {
          let expert: UserBasicDetails = itemProps.item;
          return <UserSidebarProfile user={expert} />;
        }}
        horizontalLayout={props.horizontalLayout}
        horizontalSpan={props.horizontalSpan}
        hideLoading={true}
        header={props.header}
      />
    </div>
  );
}
