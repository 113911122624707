import { Col, Row } from "antd";
import React from "react";
import { ImageComponent } from "../../components/common/Image.component";
import { Link } from "../../links/Link";
import { CountType } from "../../model/Count.model";
import { HTMLProps } from "../../model/HTMLProps";
import { IOfferingV2 } from "../Offering";
import { OfferingPricePill } from "../Offering.Price";

export interface OfferingDetailPageDlComponentProps extends HTMLProps {
  offering: IOfferingV2;
  registrationContent?: React.ReactNode;
}

export function OfferingDetailPageDlComponent(
  props: OfferingDetailPageDlComponentProps
) {
  const offeringCommonDetails = props.offering.commonDetails;
  return (
    <div
      className={`bg-main-body py-3 mob-py-3 position-sticky top--1px z-100 ${props.className}`}
    >
      <div className="card card-body bg-transparent rounded-4">
        <Row align={"middle"}>
          <Col flex="auto">
            <h1 className={`h5 m-0 font-weight-bold text-dark`}>
              <ImageComponent
                image={offeringCommonDetails.type.dp}
                className="mr-2"
              />
              {offeringCommonDetails.dl}{" "}
              {offeringCommonDetails.ratingsSummary && (
                <span className="">
                  (<i className="fas fa-star text-primary"></i>{" "}
                  {offeringCommonDetails.ratingsSummary.dlAvgRating})
                </span>
              )}
            </h1>
          </Col>
          <Col>
            {offeringCommonDetails.bookingLimitPerUser &&
              offeringCommonDetails.bookingLimitPerUser.type ===
                CountType.LIMITED &&
              offeringCommonDetails.bookingLimitPerUserConsumed !=
                undefined && (
                <span
                  className={`${
                    offeringCommonDetails.bookingLimitPerUserAvailable
                      ? "text-success"
                      : "text-danger"
                  } mr-2`}
                >
                  {`${
                    offeringCommonDetails.bookingLimitPerUserConsumed ?? 0
                  } / ${
                    offeringCommonDetails.bookingLimitPerUser.value
                  } Consumed`}
                </span>
              )}

            {!props.registrationContent &&
              offeringCommonDetails.bookingLimitPerUserAvailable && (
                <span className="event-data-desktop">
                  <Link href={`#OfferingRegisterSection`}>
                    <OfferingPricePill offering={props.offering} />
                  </Link>
                </span>
              )}
            {props.registrationContent && (
              <div className="event-data-desktop">
                {props.registrationContent}
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}
