import React from "react";
import Moment from "react-moment";
import { OrderAPI } from "../../api/OrderAPI";
import { PriceHelpers } from "../../helpers/Helpers";
import {
  OrderItem,
  OrderItemType,
  OrderResponseV1,
  OrderStatus,
  PaymentStatus,
} from "../../model/OrderDetails";
import { ReactMatch } from "../../model/ReactMatchObj";
import { PageProps } from "../../model/views/PageProps";
import { OrderItemComponent } from "../../orders/OrderItem.Component";
import { UserLink } from "../../user/User.link";
import { OrderCompletePaymentButton } from "../common/expert-session/OrderCompletePaymentButton";
import { PaymentSummaryComponent } from "./PaymentSummaryComponent";

interface OrderDetailsPageContentPathParams {
  orderId: string;
}

interface OrderDetailsPageContentProps extends PageProps {
  match: ReactMatch<OrderDetailsPageContentPathParams>;
}
export interface OrderDetailsPageContentState {
  order?: OrderResponseV1;
}

export class OrderDetailsPageContent extends React.Component<
  OrderDetailsPageContentProps,
  OrderDetailsPageContentState
> {
  constructor(props: OrderDetailsPageContentProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    OrderAPI.getOrderByorderId(this.props.match.params.orderId).then(
      (order) => {
        this.setState({ order: order });
      }
    );
  }

  render() {
    return (
      <>
        {this.state && this.state.order && (
          <div className="main-container">
            <OrderSummaryComponent order={this.state.order} />
            {this.state.order.payments &&
              this.state.order.payments.length > 0 && (
                <div className="mt-4">
                  <h5 className="font-weight-bold">Payment Details</h5>
                  <div className="row">
                    {this.state.order.payments.map((payment, index) => (
                      <div className="col-md-6 col-sm-12 col-lg-4 col-xl-3">
                        <div
                          className={`border-left-${
                            payment.paymentStatus == PaymentStatus.PAID
                              ? "success"
                              : "danger"
                          } card shadow card-body mb-4`}
                        >
                          <PaymentSummaryComponent
                            payment={payment}
                            index={index}
                            key={index}
                            liu={this.props.user}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            {this.state.order.orderItems &&
              this.state.order.orderItems.length > 0 && (
                <>
                  <div className="mt-4">
                    <h5 className="font-weight-bold">Order Items</h5>
                    {this.state.order.orderItems.map((orderItem, index) => (
                      <div className="mb-4">
                        {orderItem.itemType == OrderItemType.GENERAL_ITEM && (
                          <>
                            {this.props.user && (
                              <OrderItemComponent
                                orderItem={orderItem as OrderItem}
                                index={index}
                              />
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              )}
          </div>
        )}
      </>
    );
  }
}

interface OrderStatusComponentProps {
  orderStatus: OrderStatus;
  orderId: string;
}
function OrderStatusComponent(props: OrderStatusComponentProps) {
  return (
    <>
      {props.orderStatus == OrderStatus.PAID && (
        <>
          <h3 className="text-success">
            <i className="fa fa-check-circle"></i> Booking Confirmed !
          </h3>
          <p className="card-text">
            Thanks for using this platform. Your booking has been processed.
            Shorlt you will receive an email from us having all the details.
            <span className="font-weight-bold text-primary"></span>
          </p>
        </>
      )}

      {props.orderStatus == OrderStatus.CANCELLED && (
        <>
          <h3 className="text-danger">
            {" "}
            <i className="fa fa-close"></i>Booking Cancelled !
          </h3>
          <p className="card-text">
            Thanks for using this platform. Your booking has been cancelled. We
            look forward to have you on the platform once again.
            <span className="font-weight-bold text-primary"></span>
          </p>
        </>
      )}

      {(props.orderStatus == OrderStatus.CREATED ||
        props.orderStatus == OrderStatus.PENDING_REVIEW) && (
        <>
          <div className="hstack gap-4">
            <h3 className="text-warning">
              {" "}
              <i className="fas fa-clock"></i> Booking Pending !
            </h3>
            {(props.orderStatus == OrderStatus.CREATED ||
              props.orderStatus == OrderStatus.PENDING_REVIEW) && (
              <div className=" ms-auto ">
                <OrderCompletePaymentButton
                  text="Make Payment"
                  orderId={props.orderId}
                />
              </div>
            )}
          </div>
          <p className="card-text">
            Thanks for using this platform. Your booking is pending to be
            processed. Please complete the payment if you have not done that
            already. If payment is done, but still you are seeing pending state,
            please reach out to us through help section.
            <span className="font-weight-bold text-primary"></span>
          </p>
        </>
      )}
    </>
  );
}

interface OrderSummaryComponentProps {
  order: OrderResponseV1;
}
function OrderSummaryComponent(props: OrderSummaryComponentProps) {
  return (
    <div className="card text-dark bg-white mb-3 OrderSummaryComponent">
      <div className="card-body row">
        <OrderStatusComponent
          orderStatus={props.order.status}
          orderId={props.order.id}
        />
        <div className="col-md-6">
          <div className="card-body p-0">
            <h5 className="text-xs font-weight-bold">Order Summary</h5>
            <div className="no-gutters align-items-center">
              <span className="font-weight-bold">ID : </span>
              <span className="text-xs mb-1">{props.order.id}</span>
            </div>
            <div className="no-gutters align-items-center">
              <span className="font-weight-bold">Amount: </span>
              <span className=" text-xs mb-1">
                {PriceHelpers.getDisplayPriceV2(props.order.price)}
              </span>
            </div>
            <div className="no-gutters align-items-center">
              <span className="font-weight-bold">Status: </span>
              {props.order.status == OrderStatus.PAID && (
                <span className="text-xs mb-1">{props.order.status}</span>
              )}
              {(props.order.status == OrderStatus.CREATED ||
                props.order.status == OrderStatus.PENDING_REVIEW) && (
                <span className="text-xs mb-1">Pending</span>
              )}
            </div>
            <div className="no-gutters align-items-center">
              <span className="font-weight-bold">Date: </span>

              <span className="text-xs mb-1">
                <Moment format="DD-MM-YYYY">{props.order.createdAt}</Moment>
              </span>
            </div>
          </div>
        </div>

        {props.order.user && (
          <div className="col-md-6">
            <UserLink user={props.order.user} />
          </div>
        )}
      </div>
    </div>
  );
}
