import React from "react";
import Moment from "react-moment";
import { CompanyImage } from "../company/Company.Image";
import { ListItemProps } from "../list/List";
import { Experience } from "../user/User";

export function UserExperienceListItem(props: ListItemProps) {
  let experience = props.item as Experience;
  return (
    <div className="UserListItem">
      <div className="row" key={experience.id}>
        <div className="col">
          <div className="media">
            <CompanyImage company={experience.company} />
            <div className="media-body pl-2">
              <h6 className="mb-0">{experience.company.dl}</h6>
              <p className="font-weight-light mb-0">
                {experience.designation.dl}
              </p>
              <div className="text-muted font-weight-light font-size-sm">
                <small>
                  <Moment format="MMM YYYY">{experience.startDate}</Moment> -{" "}
                  {experience.endDate ? (
                    <Moment format="MMM YYYY">{experience.endDate}</Moment>
                  ) : (
                    "Present"
                  )}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
