import axios from "axios";
import { apiLinkConstants } from "../constants";
import { ApiUrlHelpers } from "../helpers/Helpers";
import { InterviewLoop } from "../model/InterviewLoop";
import { CreateInterviewLoopRequest } from "../model/requests/CreateInterviewLoopRequest";
import { InterviewLoopCUDRecruitersRequest } from "../model/requests/InterviewLoopCUDRecruitersRequest";
import { User } from "../user/User";

export class InterviewLoopAPI {
  public static createInterviewLoop(
    companyId: string,
    interviewLoopRequest: CreateInterviewLoopRequest
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(
          ApiUrlHelpers.getAddCompanyInterviewLoopsURL(companyId),
          interviewLoopRequest
        )
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static fetchInterviewLoops(
    companyId: string
  ): Promise<InterviewLoop[]> {
    return new Promise<InterviewLoop[]>((resolve, reject) => {
      axios
        .get(ApiUrlHelpers.getCompanyInterviewLoopsURL(companyId))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static fetchInterviewLoop(
    companyId: string,
    interviewLoopId: string
  ): Promise<InterviewLoop> {
    return new Promise<InterviewLoop>((resolve, reject) => {
      axios
        .get(
          ApiUrlHelpers.getSpecificInterviewLoopURL(companyId, interviewLoopId)
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static addRecruiter(
    companyId: string,
    interviewLoopId: string,
    recruiter: User
  ): Promise<void> {
    let postData: InterviewLoopCUDRecruitersRequest = {
      recruiter: { id: recruiter.basicDetails.id },
    };
    let url = apiLinkConstants.INTERVIEW_LOOP_RECRUITER_ADD.replace(
      "{companyId}",
      companyId
    ).replace("{interviewLoopId}", interviewLoopId);
    return new Promise<void>((resolve, reject) => {
      axios
        .post(url, postData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static removeRecruiter(
    companyId: string,
    interviewLoopId: string,
    recruiterId: string
  ): Promise<void> {
    let postData: InterviewLoopCUDRecruitersRequest = {
      recruiter: { id: recruiterId },
    };
    let url = apiLinkConstants.INTERVIEW_LOOP_RECRUITER_DELETE.replace(
      "{companyId}",
      companyId
    )
      .replace("{interviewLoopId}", interviewLoopId)
      .replace("{recruiterId}", recruiterId);
    return new Promise<void>((resolve, reject) => {
      axios
        .delete(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
