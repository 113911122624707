import React from "react";
import { CheckIcon } from "../icon/Check.Icon";
import { AnswerSubmissionStatus } from "./QuestionAnswerSubmission.model";

interface AnswerSubmissionStatusBadgeProps {
  status: AnswerSubmissionStatus;
}

export function AnswerSubmissionStatusBadge(
  props: AnswerSubmissionStatusBadgeProps
) {
  return (
    <>
      {props.status === AnswerSubmissionStatus.AC && (
        <span className="text-success">
          <CheckIcon /> Accepted
        </span>
      )}

      {props.status === AnswerSubmissionStatus.WA && (
        <span className="text-danger">
          <i className="fa-regular fa-circle-xmark"></i> Attempted
        </span>
      )}
    </>
  );
}
