import { UserBasicDetails } from "../user/User";

export interface Attendee {
  user: UserBasicDetails;
  role: AttendeeRole;
}

export enum AttendeeRole {
  ENGINE = "ENGINE",
  BOGIE = "BOGIE",
}

export enum AttendeeFormat {
  GROUP = "GROUP",
  ONE_ON_ONE = "ONE_ON_ONE",
}
