import React, { useState } from "react";
import { useAlert } from "react-alert";
import { ApiErrorHelper } from "../../helpers/ApiError.helper";
import { AttachmentTargetType } from "../../model/AttachmentTargetType";
import { ReactionCounts } from "../../model/ReactionCounts.mode";
import { LikesAPI } from "../../reactions/Likes.api";
import { ToggleLikeResponse } from "../../reactions/Likes.model";

interface ReactionCountsViewComponentProps {
  reactionCounts: ReactionCounts;
  isLiked?: boolean;
  targetType: AttachmentTargetType;
  targetId: string;

  hideComments?: boolean;
  hideLikes?: boolean;

  hideText?: boolean;
}

export function ReactionCountsViewComponent(
  props: ReactionCountsViewComponentProps
) {
  let [likesCount, setLikesCount] = useState<number>(
    props.reactionCounts.likesCount
  );
  let [isLiked, setIsLiked] = useState<boolean>(props.isLiked ?? false);
  let alert = useAlert();
  return (
    <div className="ReactionCountsViewComponent fw-light hstack gap-4">
      {!props.hideComments && (
        <div>
          <i className="far fa-comment"></i>{" "}
          {props.reactionCounts.commentsCount.toLocaleString()}{" "}
          {!props.hideText && (
            <span className="event-data-desktop">
              {props.reactionCounts.commentsCount == 1 ? "Comment" : "Comments"}
            </span>
          )}
        </div>
      )}

      <div>
        <i className="far fa-eye"></i>{" "}
        {props.reactionCounts.viewsCount.toLocaleString()}{" "}
        {!props.hideText && (
          <span className="event-data-desktop">
            {props.reactionCounts.viewsCount == 1 ? "View" : "Views"}
          </span>
        )}
      </div>

      {!props.hideLikes && props.targetType === AttachmentTargetType.POST && (
        <div>
          <a
            href="javascript:;"
            className="text-secondary"
            onClick={function () {
              LikesAPI.toggleLike(props.targetType, props.targetId)
                .then((toggleLikeResponse: ToggleLikeResponse) => {
                  setLikesCount(toggleLikeResponse.likesCount);
                  setIsLiked(toggleLikeResponse.isLiked);
                })
                .catch((error) => {
                  let errorMessage =
                    ApiErrorHelper.getDisplayErrorMessage(error);
                  alert.error(errorMessage);
                });
              return false;
            }}
          >
            {isLiked && (
              <div className="text-danger">
                <i className={` fas fa-heart `}></i>{" "}
                {likesCount.toLocaleString()}{" "}
                {!props.hideText && (
                  <span className="event-data-desktop">
                    {props.reactionCounts.likesCount == 1 ? "Like" : "Likes"}{" "}
                  </span>
                )}
              </div>
            )}

            {!isLiked && (
              <div>
                <i className={` far fa-heart`}></i>{" "}
                {likesCount.toLocaleString()}{" "}
                {!props.hideText && (
                  <span className="event-data-desktop">
                    {props.reactionCounts.likesCount == 1 ? "Like" : "Likes"}
                  </span>
                )}
              </div>
            )}
          </a>
        </div>
      )}
    </div>
  );
}
