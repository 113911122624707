import axios from "axios";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { Target } from "../model/target.model";
import { ReactionConstants } from "./Reaction.Constants";

export class ViewsAPI {
  public static addView(
    targetType: AttachmentTargetType,
    targetId: string
  ): Promise<Comment[]> {
    let api = apiLinkConstants.API_VIEWS_ADD.replaceAll(
      "{targetType}",
      targetType
    ).replaceAll("{targetId}", targetId);

    return new Promise<Comment[]>((resolve, reject) => {
      return axios.post(api).then((response) => {
        resolve(response.data);
      });
    });
  }

  public static batchLog(): Api<{ targets: Target[] }, void> {
    let api = ReactionConstants.API_VIEWS_BATCH_LOG;
    return new Api<{ targets: Target[] }, void>(api, ApiMethod.POST);
  }
}
