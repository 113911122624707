import React from "react";
import { HTMLProps } from "../model/HTMLProps";

export interface BadgeDesignProps extends HTMLProps {
  children: React.ReactNode;
}

export function BadgeDesignLightYellow(props: BadgeDesignProps) {
  return (
    <span className={`badge rounded-pill bg-light-yellow ${props.className}`}>
      {props.children}
    </span>
  );
}

export function BadgeDesignLight(props: BadgeDesignProps) {
  return (
    <span className={`badge badge-light ${props.className}`}>
      {props.children}
    </span>
  );
}
