import React from "react";
import { ApiLinkedButtonV3 } from "../components/common/Form/ApiLinked.button.V3";
import { List, ListItemProps } from "../list/List";
import { ListItemLayoutDefault } from "../list/ListItem.Layout";
import { PaymentApi } from "./Payment.Api";
import { Payment } from "./Payment.Model";

interface PaymentsListProps {
  payments: Payment[];
}

export function PaymentsList(props: PaymentsListProps) {
  return (
    <div className="PaymentsList">
      <List
        onFetch={function (): Promise<any[]> {
          return Promise.resolve(props.payments);
        }}
        itemNode={(itemProps: ListItemProps) => {
          let payment = itemProps.item as Payment;

          return (
            <div>
              {payment.id}
              <ApiLinkedButtonV3
                buttonProps={{ content: { normal: "Update Status" } }}
                api={PaymentApi.updatePaymentStatus()}
                initialValues={payment}
                uniqueKey={"payment-" + payment.id + "-update-status"}
              />
            </div>
          );
        }}
        ItemLayoutComponent={ListItemLayoutDefault}
      />
    </div>
  );
}
