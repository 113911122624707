import React from "react";
import { publicLinkConstants } from "../constants";
import { EventDashboardContent } from "../events/EventDashboardContent";
import { EventLandingPageContent } from "../events/EventLandingPageContent";
import { PublicPageRoute } from "./PublicPage.route";
import HomePage from "../home/HomePage";
import ForCompaniesPage from "../components/pages/ForCompaniesPage";
import { Route } from "react-router";
import { CreditsPageContent } from "../credits/Credits.PageContent";

export function StaticRoutes() {
  return (
    <>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/for-companies" component={ForCompaniesPage} />

      <PublicPageRoute
        exact
        path={publicLinkConstants.CREDITS}
        contentComponent={CreditsPageContent}
      />
    </>
  );
}
