import React, { useEffect, useState } from "react";
import { PaymentReleaseComponent } from "../../payments/payment-release-requests/PaymentRelease.component";
import { PaymentReleaseRequestAdminApi } from "../../payments/payment-release-requests/PaymentReleaseRequest.admin.api";
import {
  PaymentReleaseRequest,
  PaymentReleaseRequestsResponse,
} from "../../payments/payment-release-requests/PaymentReleaseRequest.model";
import { Loader } from "../common/Loader.component";
import { Center } from "../common/layout/Center.component";

export function AdminPaymentReleaseRequestsPageContent() {
  let [prrs, setPrrs] = useState<PaymentReleaseRequestsResponse>();
  let [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    setLoading(true);
    PaymentReleaseRequestAdminApi.getAllPRRs().then((prrs) => {
      setPrrs(prrs);
      setLoading(false);
    });
  }, []);

  return (
    <div className="main-container">
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {prrs && (
        <PaymentReleaseComponent
          paymentReleaseRequests={prrs}
          showReviewOptions={true}
          onPrrUpdate={function (index: number, prr: PaymentReleaseRequest) {
            if (prrs) {
              prrs.paymentReleaseRequests[index] = prr;
              setPrrs({ ...prrs });
            }

            return Promise.resolve();
          }}
        />
      )}
    </div>
  );
}
