import { Col, Row, RowProps, Tooltip } from "antd";
import React, { CSSProperties } from "react";
import { ButtonText } from "../../model/views/Button.props";

export interface EBButtonV2Props {
  content: ButtonText;
  type?: "submit" | "reset" | "button" | undefined;
  className?: string;
  style?: CSSProperties | undefined;
  disabled?: boolean;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  otherProps?: any;
  otherlayout?: React.ReactNode;
  id?: string;
  tooltip?: React.ReactNode;
  purpose?:
    | "primary"
    | "danger"
    | "success"
    | "info"
    | "light"
    | "secondary"
    | "dark"
    | "link"
    | "none";
  rowClassName?: string;
  rowProps?: RowProps;
}
export function EBButtonV2(props: EBButtonV2Props) {
  let otherProps = props.otherProps;
  if (!otherProps) {
    otherProps = {};
  }
  return (
    <>
      {props.loading && (
        <Row
          align={"middle"}
          className={props.rowClassName}
          {...props.rowProps}
        >
          {props.otherlayout && (
            <Col flex={"auto"}>
              <span
                className="cursor-pointer"
                onClick={(event) => {
                  if (props.onClick) {
                    props.onClick(event);
                  }
                  event.preventDefault();
                  event.stopPropagation();
                }}
                {...otherProps}
              >
                {props.otherlayout}
              </span>
            </Col>
          )}
          <Col>
            <button
              disabled={props.disabled}
              onClick={(event) => {
                if (props.onClick) {
                  props.onClick(event);
                }
                event.stopPropagation();
              }}
              className={`btn btn-${props.purpose ?? "primary"} ${
                props.className
              }`}
              type={props.type ? props.type : "button"}
              id={props.id}
              {...otherProps}
              style={props.style}
            >
              <div>
                <div className="hstack gap-2">
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  {props.content.submitting ?? "Please wait..."}
                </div>
              </div>
            </button>
          </Col>
        </Row>
      )}
      {!props.loading && (
        <Tooltip title={props.tooltip}>
          <Row
            align={"middle"}
            className={props.rowClassName}
            {...props.rowProps}
          >
            {props.otherlayout && (
              <Col flex={"auto"}>
                <span
                  className="cursor-pointer"
                  onClick={(event) => {
                    if (props.onClick) {
                      props.onClick(event);
                    }
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  {...otherProps}
                >
                  {props.otherlayout}
                </span>
              </Col>
            )}
            <Col>
              <button
                disabled={props.disabled}
                onClick={(event) => {
                  if (props.onClick) {
                    props.onClick(event);
                  }
                  event.stopPropagation();
                }}
                {...otherProps}
                className={`btn btn-${props.purpose ?? "primary"} ${
                  props.className
                }`}
                type={props.type ? props.type : "button"}
                id={props.id}
              >
                {props.content.normal}
              </button>
            </Col>
          </Row>
        </Tooltip>
      )}
    </>
  );
}
