import React from "react";
import { BookingApi } from "../booking/Booking.Api";
import { GroupCallIcon } from "../icon/GroupCall.Icon";
import { VideoIcon } from "../icon/Video.Icon";
import {
  ContentItemDataGroupCall,
  ContentItemDataOfferingVideoSession,
  ContentItemTypeEnum,
  IContentItemData,
} from "./Content.model";

export interface IContentItemHandler {
  typeEnum: ContentItemTypeEnum;
  sidebarListView: React.ReactNode;

  getInitialContentData: () => IContentItemData;
  canDelete?: (
    data: IContentItemData
  ) => Promise<IContentItemHandlerDeleteCallbackData>;
}

interface IContentItemHandlerDeleteCallbackData {
  canDelete: boolean;
  reasonForDisallowingDelete?: string;
}

export const contentItemHandlerMarkdown: IContentItemHandler = {
  typeEnum: ContentItemTypeEnum.MARKDOWN,
  sidebarListView: (
    <>
      <i className="fa-brands fa-markdown"></i> &nbsp; Markdown
    </>
  ),
  getInitialContentData: function () {
    return { md: "" };
  },
};

export const contentItemHandlerYouTube: IContentItemHandler = {
  typeEnum: ContentItemTypeEnum.YOUTUBE,
  sidebarListView: (
    <>
      <i className="fa-brands fa-youtube"></i> &nbsp; YouTube Video
    </>
  ),
  getInitialContentData: function () {
    return { url: "" };
  },
};

export const contentItemHandlerVideoUpload: IContentItemHandler = {
  typeEnum: ContentItemTypeEnum.VIDEO_UPLOAD,
  sidebarListView: (
    <>
      <i className="fa-solid fa-video"></i> &nbsp; Upload Video
    </>
  ),
  getInitialContentData: function () {
    return { url: "" };
  },
};

export const contentItemHandlerOfferingVideoSession: IContentItemHandler = {
  typeEnum: ContentItemTypeEnum.OFFERING_VIDEO_SESSION,
  sidebarListView: (
    <>
      <VideoIcon /> &nbsp; 1:1 Call
    </>
  ),
  getInitialContentData: function (): ContentItemDataOfferingVideoSession {
    return { offeringId: undefined };
  },
};

export const contentItemHandlerGroupCall: IContentItemHandler = {
  typeEnum: ContentItemTypeEnum.GROUP_CALL,
  sidebarListView: (
    <>
      <GroupCallIcon /> &nbsp; Group Call
    </>
  ),
  getInitialContentData: function (): ContentItemDataGroupCall {
    return { videoSessionId: undefined };
  },

  canDelete: function (
    data: IContentItemData
  ): Promise<IContentItemHandlerDeleteCallbackData> {
    let videoSessionData = data as ContentItemDataGroupCall;
    if (!videoSessionData || !videoSessionData.videoSessionId) {
      return Promise.resolve({ canDelete: true });
    }
    return BookingApi.get(videoSessionData.videoSessionId)
      .execute()
      .then((booking) => {
        if (booking.bcd.cancelledAt || booking.bcd.engineCompletedAt) {
          return { canDelete: true };
        }
        return {
          canDelete: false,
          reasonForDisallowingDelete: `You need to cancel the associated group call '${booking.bcd.dl}' first before deleting`,
        };
      });
  },
};

export const contentItemHandlerQuestion: IContentItemHandler = {
  typeEnum: ContentItemTypeEnum.QUESTION,
  sidebarListView: (
    <>
      <i className="fa-solid fa-user-graduate"></i> &nbsp; Question
    </>
  ),

  getInitialContentData: function () {
    return { questionId: undefined };
  },
};

export class ContentItemTypeFactory {
  public static getAllContentItemHandlers(): IContentItemHandler[] {
    return [
      contentItemHandlerMarkdown,
      contentItemHandlerQuestion,
      contentItemHandlerYouTube,
      contentItemHandlerVideoUpload,
      contentItemHandlerOfferingVideoSession,
      contentItemHandlerGroupCall,
    ];
  }

  public static getContentItemHandler(
    typeEnum: ContentItemTypeEnum
  ): IContentItemHandler {
    let allHandlers = this.getAllContentItemHandlers();
    for (let handler of allHandlers) {
      if (handler.typeEnum === typeEnum) {
        return handler;
      }
    }

    throw new Error("Unknown content item type handler.");
  }
}
