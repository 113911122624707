import React from "react";

interface TextViewComponentProps {
  text: string;
}

export class TextViewComponent extends React.Component<
  TextViewComponentProps,
  {}
> {
  render() {
    return (
      <span className="text-change-line-for-newline">{this.props.text}</span>
    );
  }
}
