import React, { useState } from "react";
import {
  DigitalProductDl,
  PathDl,
  TextQueryDl,
  VideoSessionDl,
} from "../Global.Names";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { HTMLProps } from "../model/HTMLProps";
import { OptionsSelect } from "../user/listing/OptionsSelect";

export interface TargetInputOption {
  type: AttachmentTargetType;
  suffix?: React.ReactNode;
}

interface TargetFilterOption {
  targetType: AttachmentTargetType;
  dl: React.ReactNode;
}

interface TargetOptionsSelectProps extends HTMLProps {
  targetOptions: TargetInputOption[];
  onSelectionUpdate: (selectedOptions: AttachmentTargetType[]) => Promise<void>;
  compact?: boolean;
}

export function TargetOptionsSelect(props: TargetOptionsSelectProps) {
  let [filterTypes, setFilterTypes] = useState<TargetFilterOption[]>([]);

  let filterTypeOptions: TargetFilterOption[] = [];

  function findTargetOption(type: AttachmentTargetType) {
    return props.targetOptions.find((op) => op.type == type);
  }

  function addFilterOption(type: AttachmentTargetType, label: string) {
    let inputOp = findTargetOption(type);
    if (inputOp != null) {
      filterTypeOptions.push({
        targetType: inputOp.type,
        dl: (
          <>
            {label} {inputOp.suffix}
          </>
        ),
      });
    }
  }

  addFilterOption(
    AttachmentTargetType.VIDEO_SESSION,
    VideoSessionDl.SENTENCE_START
  );

  addFilterOption(AttachmentTargetType.TEXT_QUERY, TextQueryDl.SENTENCE_START);

  addFilterOption(
    AttachmentTargetType.DIGITAL_PRODUCT,
    DigitalProductDl.SENTENCE_START
  );

  addFilterOption(AttachmentTargetType.BOOKING_PATH, PathDl.SENTENCE_START);

  return (
    <OptionsSelect<TargetFilterOption>
      options={filterTypeOptions}
      initialSelectedOptions={filterTypes}
      onSelectionUpdate={function (
        selectedOptions: TargetFilterOption[]
      ): Promise<void> {
        setFilterTypes(selectedOptions);
        return props.onSelectionUpdate(
          selectedOptions.map((option) => option.targetType)
        );
      }}
      isEqual={function (
        a: TargetFilterOption,
        b: TargetFilterOption
      ): boolean {
        return a.targetType == b.targetType;
      }}
      render={function (option: TargetFilterOption): React.ReactNode {
        return option.dl;
      }}
      className={props.className}
      compact={props.compact}
    />
  );
}
