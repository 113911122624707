import { useField } from "formik";
import React, { useState } from "react";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { Loader } from "../components/common/Loader.component";
import { EBTextInput } from "../components/common/eb-ui-components";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { apiLinkConstants } from "../constants";

interface UserNameInputProps extends FormikInputParams {}

interface UsernameCheckResponse {
  isAvailable: boolean;
  errorMessage: string;
}

export function UserNameInput(props: UserNameInputProps) {
  const [field, meta] = useField(props);
  let [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean>();
  let [errorMessage, setErrorMessage] = useState<string>();

  let [isChecking, setIsChecking] = useState<boolean>();
  function checkIfUserNameAvailable() {
    setIsChecking(true);
    let api = apiLinkConstants.API_CHECK_USERNAME;
    new Api<{ username: string }, UsernameCheckResponse>(api, ApiMethod.POST)
      .execute({ username: field.value })
      .then((response: UsernameCheckResponse) => {
        setIsUsernameAvailable(response.isAvailable);
        setErrorMessage(response.errorMessage);
      })
      .finally(() => {
        setIsChecking(false);
      });
  }

  return (
    <div className="">
      <div className="row no-gutters">
        <div className="col">
          <EBTextInput
            label={props.label}
            name={props.name}
            required={props.required}
            helpText={props.helpText}
            nodeInputPrepend={
              <>
                <div className="input-group-text">enginebogie.com/u/</div>
              </>
            }
            customNodeBelowInput={
              <>
                {isChecking && <Loader />}
                {isUsernameAvailable !== undefined && !isChecking && (
                  <>
                    {isUsernameAvailable ? (
                      <div className="text-success">Available</div>
                    ) : (
                      <div className="text-danger">{errorMessage}</div>
                    )}
                  </>
                )}{" "}
                <a
                  href="javascript:;"
                  onClick={() => {
                    checkIfUserNameAvailable();
                    return false;
                  }}
                >
                  Check Availability
                </a>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}
