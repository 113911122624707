import { useField } from "formik";
import React from "react";
import { FormikInputParams } from "../input/FormikInputParams.model";
import { CurrencySelector } from "./CurrencySelector.component";

export function PriceFormikInput(props: FormikInputParams) {
  return (
    <div className="row no-gutters mt-2">
      <div className="col">
        <PriceAmountInput
          name={`${props.name}.amount`}
          disabled={props.disabled}
          required={true}
        />
      </div>
      <div className="col-auto pl-1">
        <CurrencySelector
          currencies={[
            { id: "INR", title: "Indian Rupee (₹)" },
            { id: "USD", title: "US Dollar ($)" },
          ]}
          name={`${props.name}.currency`}
          fieldTitle=""
          disabled={props.disabled}
        />
      </div>
    </div>
  );
}

interface PriceAmountInputProps extends FormikInputParams {
  placeholder?: string;
  helpText?: string;
  enablePerformance?: boolean;
}

export const PriceAmountInput = (props: PriceAmountInputProps) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);

  const onChange = (evt: any) => {
    field.onChange({
      target: {
        name: props.name,
        value: evt.target.value * 100,
      },
    });
  };

  return (
    <>
      <div className="form-group">
        {meta.touched && meta.error ? (
          <div>
            <span className="text-danger">{meta.error}</span>
          </div>
        ) : null}

        <input
          className="form-control"
          type="text"
          value={field && field.value ? field.value / 100 : ""}
          required={props.required}
          disabled={props.disabled}
          onChange={onChange}
          placeholder="Amount"
        />
        {props.helpText ? (
          <small className="form-text text-muted">{props.helpText}</small>
        ) : null}
      </div>
    </>
  );
};
