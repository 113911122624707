export interface Picture {
  data: string;
  locationType: LocationType;
}
export interface AssetLocation {
  data: string;
  locationType: LocationType;
}

export enum LocationType {
  URL = "URL",
  DEFAULT_COMPANY = "DEFAULT_COMPANY",
  FA_CLASS = "FA_CLASS",
  CLOUDINARY_PUBLIC_ID = "CLOUDINARY_PUBLIC_ID",
  EMJ = "EMJ",
}
