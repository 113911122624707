import { Flex } from "antd";
import React from "react";
import { Center } from "../../components/common/layout/Center.component";
import { WindowHelpers } from "../../helpers/Window.helper";
import { OfferingRegisterSectionLayoutModelProps } from "./Offering.RegisterSection.Layout.Model";

export function OfferingRegisterSectionDefaultLayout(
  props: OfferingRegisterSectionLayoutModelProps
) {
  return (
    <div className={`OfferingRegisterSectionDefaultLayout ${props.className}`}>
      <Flex gap={`${WindowHelpers.isMobile() ? "large" : "middle"}`} vertical>
        {props.preBookingFormElement}
        {props.priceVariationFormElement}
        <Center>
          <div className="text-center"> {props.registerButton}</div>
        </Center>
      </Flex>
    </div>
  );
}
