import { BuildFilled } from "@ant-design/icons";
import React from "react";
import { Link } from "../links/Link";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { EditOfferingOptionsComponent } from "../offerings/EditOfferingOptions.component";
import { OfferingPath, OfferingTypeName } from "../offerings/Offering";
import { OfferingInputSection } from "../offerings/Offering.Input.Section";
import { OfferingsEditComponent } from "../offerings/Offerings.Edit.Component";
import { ExpertStudioContentProps } from "./ExpertStudio.page";

interface OfferingsPathsEditExpertStudioContentProps
  extends ExpertStudioContentProps {}

export function OfferingsPathsEditExpertStudioContent(
  props: OfferingsPathsEditExpertStudioContentProps
) {
  return (
    <div className="OfferingsPathsEditExpertStudioContent">
      <h6>Path Offerings:</h6>
      <OfferingsEditComponent
        user={props.user}
        offeringType={OfferingTypeName.PATH}
        offeringTargetType={AttachmentTargetType.OFFERING_PATH}
        titleSideNode={
          <OfferingInputSection
            title={<></>}
            content={
              <EditOfferingOptionsComponent
                offeringSessionTypeFieldName={"sessionType"}
                name={`commonDetails.options`}
                skills={[]}
                sessionTypes={[]}
                user={props.user}
                hideAddBtn={true}
              />
            }
          />
        }
        getAdditionalOfferingActions={(offering: OfferingPath) => {
          return (
            <>
              <Link
                className="text-secondary"
                href={offering.contentEditUrl}
                target="_blank"
                tooltip="Edit Path Steps"
              >
                <BuildFilled />
              </Link>

              <Link
                className="text-secondary"
                href={offering.contentEditUrl}
                target="_blank"
                tooltip="Discussion"
              >
                <i className="fa-solid fa-comments"></i>
              </Link>
            </>
          );
        }}
      />
    </div>
  );
}
