import { Topic } from "../topic/Topic.model";

export interface Country extends Topic {
  code: string;
  countryCode: CountryCode;
}

export enum CountryCode {
  IN = "IN",
  US = "US",
  OTHER = "OTHER",
}
