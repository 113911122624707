import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { CompanyAPI } from "../../company/CompanyAPI";
import { SkillsPickerView } from "../../components/common/SkillsPickerView";
import { FormInputLabel } from "../../components/common/eb-ui-components";
import { CompanySelector } from "../../company/CompanySelector.component";
import { FormikSwitchInput } from "../../components/common/input/FormikSwitch.input";
import { Company } from "../../company/Company";
import { HTMLProps } from "../../model/HTMLProps";
import { SearchToken } from "../../model/search/SearchToken";
import { SearchTokenName } from "../../model/search/SearchTokenName";

interface UserFiltersViewProps extends HTMLProps {
  callback: (searchTokens: SearchToken[]) => Promise<void>;
}

export function UserFiltersView(props: UserFiltersViewProps) {
  // let [allDesignations, setAllDesignations] = useState<Designation[]>();
  let [allCompanies, setAllCompanies] = useState<Company[]>();
  useEffect(() => {
    // DesignationAPI.fetchAllDesignations().then((designations) => {
    //   setAllDesignations(designations);
    // });

    CompanyAPI.fetchAllCompanies().then((companies) => {
      setAllCompanies(companies);
    });
  }, []);
  let MAX_PRICE_POSSIBLE = 10000;
  let PRICE_STEP = 10;

  return (
    <div className={props.className}>
      <Formik
        initialValues={{
          minExp: 0,
          maxExp: 25,
          companies: [],
          skills: [],
          includeIfPreviousCompany: true,
          minPrice: 0,
          maxPrice: MAX_PRICE_POSSIBLE,
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          let searchTokens: SearchToken[] = [];

          if (values.skills && values.skills.length > 0) {
            searchTokens.push({
              name: SearchTokenName.SKILLS,
              data: {
                skills: values.skills,
              },
            });
          }
          if (values.companies && values.companies.length > 0) {
            searchTokens.push({
              name: SearchTokenName.CURRENT_COMPANY,
              data: {
                companies: values.companies,
                includeIfPreviousCompany: values.includeIfPreviousCompany,
              },
            });
          }
          if (values.minExp && values.maxExp) {
            searchTokens.push({
              name: SearchTokenName.YOE,
              data: {
                minYOEDurationInDays: values.minExp * 365,
                maxYOEDurationInDays: values.maxExp * 365,
              },
            });
          } else if (values.minExp) {
            searchTokens.push({
              name: SearchTokenName.YOE,
              data: {
                minYOEDurationInDays: values.minExp * 365,
              },
            });
          } else if (values.maxExp) {
            searchTokens.push({
              name: SearchTokenName.YOE,
              data: {
                maxYOEDurationInDays: values.maxExp * 365,
              },
            });
          }

          if (values.minPrice && values.maxPrice) {
            searchTokens.push({
              name: SearchTokenName.PRICE,
              data: {
                minAmount: values.minPrice * 100,
                maxAmount: values.maxPrice * 100,
              },
            });
          } else if (values.minPrice) {
            searchTokens.push({
              name: SearchTokenName.PRICE,
              data: {
                minAmount: values.minPrice * 100,
              },
            });
          } else if (values.maxPrice) {
            searchTokens.push({
              name: SearchTokenName.PRICE,
              data: {
                maxAmount: values.maxPrice * 100,
              },
            });
          }
          props
            .callback(searchTokens)
            .then(() => {
              actions.setSubmitting(false);
            })
            .catch(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {(props) => (
          <Form>
            <div className="">
              <div className="row mb-4">
                <div className="col d-grid">
                  <button
                    className="btn btn-secondary"
                    type="submit"
                    disabled={props.isSubmitting}
                  >
                    {props.isSubmitting
                      ? "Filtering. Please Wait..."
                      : "Filter"}
                  </button>
                </div>
              </div>

              <hr className="text-warning" />
              {/* Current Company */}
              <div className="row">
                {" "}
                <div className="col">
                  {allCompanies && (
                    <>
                      <FormInputLabel label="Working In:" />
                      <CompanySelector
                        isMulti={true}
                        name="companies"
                        companies={allCompanies}
                      />
                      <div className="mt-2">
                        <FormikSwitchInput
                          name="includeIfPreviousCompany"
                          label="Include past company"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <hr className="text-warning" />
              <div className="row">
                <div className="col">
                  <label htmlFor="minExp" className="form-label">
                    Min Exp: {props.values.minExp}
                  </label>
                  <Field
                    type="range"
                    name="minExp"
                    className="form-range"
                    min="0"
                    max="25"
                    step="1"
                    id="minExp"
                  />
                </div>
              </div>

              <div className="row">
                {" "}
                <div className="col">
                  <label htmlFor="maxExp" className="form-label">
                    Max Exp: {props.values.maxExp}
                  </label>
                  <Field
                    type="range"
                    name="maxExp"
                    className="text-warning form-range"
                    min="0"
                    max="25"
                    step="1"
                    id="maxExp"
                  />
                </div>
              </div>

              <hr className="text-warning" />
              {/* Skills */}
              <div className="row">
                {" "}
                <div className="col">
                  <SkillsPickerView />
                </div>
              </div>

              {/* Price */}
              <hr className="text-warning" />
              <div className="row">
                <div className="col">
                  <label htmlFor="minPrice" className="form-label">
                    Min Price: {props.values.minPrice}
                  </label>
                  <Field
                    type="range"
                    name="minPrice"
                    className="form-range"
                    min="0"
                    max={MAX_PRICE_POSSIBLE}
                    step="1"
                    id="minPrice"
                  />
                </div>
              </div>

              <div className="row">
                {" "}
                <div className="col">
                  <label htmlFor="maxPrice" className="form-label">
                    Max Price: {props.values.maxPrice}
                  </label>
                  <Field
                    type="range"
                    name="maxPrice"
                    className="text-warning form-range"
                    min="0"
                    max={MAX_PRICE_POSSIBLE}
                    step={PRICE_STEP}
                    id="maxPrice"
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
