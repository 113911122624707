import React, { useState } from "react";
import { ArrayHelper } from "../../helpers/Array.Helper";
import { HTMLProps } from "../../model/HTMLProps";
import { Topic } from "../../topic/Topic.model";
import { OptionsSelect } from "./OptionsSelect";

interface TopicEasyFilterProps extends HTMLProps {
  allTopics: Topic[];
  onSelect: (selectedTopics: Topic[]) => Promise<void>;
  isSingle?: boolean;
  normalOpClassName?: string;
  selectedOpClassName?: string;
}

interface FilterTopicsTreeNode {
  topic: Topic;
  level: number;
  children: FilterTopicsTreeNode[];
}

function buildTree(node: FilterTopicsTreeNode, topics: Topic[]) {
  let childrenTopics = topics.filter(
    (topic) => topic.parent?.topicId.id == node.topic.topicId.id
  );
  childrenTopics.forEach((childTopic) => {
    node.children.push({
      topic: childTopic,
      children: [],
      level: node.level + 1,
    });
  });

  node.children.forEach((childNode) => {
    buildTree(childNode, topics);
  });
}

function childrenNodes(nodes: FilterTopicsTreeNode[]): FilterTopicsTreeNode[] {
  let result: FilterTopicsTreeNode[] = [];
  nodes.forEach((node) => {
    result.push(...node.children);
  });
  return result;
}

export function TopicEasyFilter(props: TopicEasyFilterProps) {
  let [selectedTopicsNodes, setSelectedTopicsNodes] = useState<
    FilterTopicsTreeNode[][]
  >([]);

  let [renderCount, setRenderCount] = useState<number>(1);

  function onSelect() {
    let maxLevelSelectedNodes =
      selectedTopicsNodes[selectedTopicsNodes.length - 1];
    if (maxLevelSelectedNodes) {
      // maxLevelSelectedNodes = allSubtreeNods(maxLevelSelectedNodes);
      let maxLevelSelectedTopics = maxLevelSelectedNodes.map(
        (node) => node.topic
      );
      props.onSelect(maxLevelSelectedTopics);
    } else {
      props.onSelect([]);
    }
  }

  let filterTopics = props.allTopics.filter(
    (topic) => topic.markedUseForFilterAt != undefined
  );
  let topFilterTopics = filterTopics.filter(
    (topic) => topic.parent == undefined
  );
  let topFilterTopicsNodes: FilterTopicsTreeNode[] = topFilterTopics.map(
    (topic) => {
      let node: FilterTopicsTreeNode = { topic: topic, children: [], level: 0 };
      buildTree(node, filterTopics);
      return node;
    }
  );

  function onTopTopicsSelectionUpdate(
    levelSelectedTopicsNodes: FilterTopicsTreeNode[],
    level: number
  ) {
    let updatedTopicsNodes = selectedTopicsNodes;
    for (let i = level + 1; i < updatedTopicsNodes.length; i++) {
      ArrayHelper.removeByIndex(updatedTopicsNodes, i);
    }
    updatedTopicsNodes[level] = levelSelectedTopicsNodes;

    for (let i = 0; i < updatedTopicsNodes.length; i++) {
      if (!updatedTopicsNodes[i] || updatedTopicsNodes[i].length == 0) {
        ArrayHelper.removeByIndex(updatedTopicsNodes, i);
      }
    }
    setSelectedTopicsNodes(updatedTopicsNodes);
    setRenderCount(renderCount + 1);
    onSelect();
  }

  return (
    <div className={`TopicEasyFilter ${props.className}`}>
      <div className="mb-3">
        <OptionsSelect<FilterTopicsTreeNode>
          isSingle={props.isSingle}
          options={topFilterTopicsNodes}
          initialSelectedOptions={selectedTopicsNodes[0]}
          onSelectionUpdate={function (
            selectedOptions: FilterTopicsTreeNode[]
          ): Promise<void> {
            onTopTopicsSelectionUpdate(selectedOptions, 0);
            return Promise.resolve();
          }}
          isEqual={function (
            a: FilterTopicsTreeNode,
            b: FilterTopicsTreeNode
          ): boolean {
            return a.topic.topicId.id == b.topic.topicId.id;
          }}
          render={function (option: FilterTopicsTreeNode): React.ReactNode {
            return option.topic.dl;
          }}
          normalOpClassName={props.normalOpClassName}
          selectedOpClassName={props.selectedOpClassName}
        />
      </div>
      {selectedTopicsNodes.map((levelSelectedTopicsNodes, index) => (
        <div className="mb-2">
          <OptionsSelect<FilterTopicsTreeNode>
            compact={true}
            options={childrenNodes(levelSelectedTopicsNodes)}
            initialSelectedOptions={selectedTopicsNodes[index + 1]}
            onSelectionUpdate={function (
              selectedOptions: FilterTopicsTreeNode[]
            ): Promise<void> {
              onTopTopicsSelectionUpdate(selectedOptions, index + 1);
              return Promise.resolve();
            }}
            isEqual={function (
              a: FilterTopicsTreeNode,
              b: FilterTopicsTreeNode
            ): boolean {
              return a.topic.topicId.id == b.topic.topicId.id;
            }}
            render={function (option: FilterTopicsTreeNode): React.ReactNode {
              return option.topic.dl;
            }}
            normalOpClassName={props.normalOpClassName}
            selectedOpClassName={props.selectedOpClassName}
          />
        </div>
      ))}
    </div>
  );
}
