export enum SearchTokenName {
  YOE = "YOE",
  CURRENT_COMPANY = "CURRENT_COMPANY",
  PAST_COMPANY = "PAST_COMPANY",
  SKILLS = "SKILLS",
  PRICE = "PRICE",
  CURRENT_DESIGNATION = "CURRENT_DESIGNATION",
  SHOW_MINE = "SHOW_MINE",
  SEARCH_QUERY_STRING = "SEARCH_QUERY_STRING",
  DIFFICULTY_LEVEL = "DIFFICULTY_LEVEL",
  TOPIC = "TOPIC",
  SEARCH_POST_STRING = "SEARCH_POST_STRING",
  NAME = "NAME",
}
