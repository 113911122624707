import React, { useState } from "react";
import { useAlert } from "react-alert";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { Loader } from "../components/common/Loader.component";
import { ApiErrorHelper } from "../helpers/ApiError.helper";
import { ImageGeneratorResponse } from "../image/Image.model";
import { AssetLocation } from "../model/Picture";

interface FileUploadComponentProps {
  onImageUpload: (image: AssetLocation) => Promise<void>;
  api: string;
  required?: boolean;
}

export function FileUploadComponent(props: FileUploadComponentProps) {
  let [uploading, setUploading] = useState<boolean>();
  let alert = useAlert();
  function onChangeHandler(event: any) {
    let file = event.target.files[0];

    var MAX_FILE_SIZE = 20 * 1024 * 1024;

    if (event.target && file.size <= MAX_FILE_SIZE) {
      const data = new FormData();
      data.append("file", file);
      setUploading(true);
      new Api<FormData, ImageGeneratorResponse>(props.api, ApiMethod.POST)
        .execute(data)
        .then((imageResponse) => {
          return props.onImageUpload(imageResponse.image);
        })
        .catch((error) => {
          let displayErr = ApiErrorHelper.getDisplayErrorMessage(error);
          alert.error(displayErr);
        })
        .finally(() => {
          setUploading(false);
        });
    } else {
      alert.error("Maximum file upload limit is 20MB.");
    }
  }

  return (
    <>
      {uploading && (
        <>
          <small>
            <Loader />
          </small>{" "}
          Uploading. Please wait...
        </>
      )}
      {!uploading && (
        <input
          type="file"
          onChange={onChangeHandler}
          className="form-control"
          required={props.required}
        />
      )}
    </>
  );
}
