import React from "react";
import { publicLinkConstants } from "../constants";
import { DigitalProductDetailsPageContent } from "../digital-product/DigitalProduct.PageContent";
import { PublicPageRoute } from "./PublicPage.route";

export function DigitalProductRoutes() {
  return (
    <>
      <PublicPageRoute
        path={publicLinkConstants.DIGITAL_PRODUCT_DETAIL_LINK}
        contentComponent={DigitalProductDetailsPageContent}
      />
    </>
  );
}
