import { Api, ApiMethod } from "../../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../../constants";
import { Withdrawal } from "../withdrawal/Withdrawal.model";
import { Ledger, LedgerEntry } from "./Ledger.model";

export class LedgerApi {
  public static getUserLedger(userId: string): Promise<Ledger> {
    let api = apiLinkConstants.API_USER_LEDGER.replaceAll("{userId}", userId);
    return new Api<void, Ledger>(api, ApiMethod.GET).execute();
  }

  public static withdrawLedgerBalance(
    userId: string,
    withrawal: Withdrawal
  ): Promise<void> {
    let api = apiLinkConstants.API_USER_LEDGER_BALANCE_WITHDRAW.replace(
      "{userId}",
      userId
    );
    return new Api<Withdrawal, void>(api, ApiMethod.POST).execute(withrawal);
  }
}
