import React from "react";
import { RatingsHelpers } from "../../helpers/Helpers";
import { RatingsSummary } from "../../model/Reviews";

interface RatingsSummaryViewProps {
  ratingsSummary: RatingsSummary;
}

export class RatingsSummaryView extends React.Component<
  RatingsSummaryViewProps,
  {}
> {
  render() {
    return (
      <div>
        <i className="fas fa-star text-warning"></i>{" "}
        <span className="font-weight-normal">
          {RatingsHelpers.getDisplayRating(
            this.props.ratingsSummary.expertAvgRating
          )}
          /5
        </span>{" "}
        <span>
          ({this.props.ratingsSummary.ratingsCount}{" "}
          {this.props.ratingsSummary.ratingsCount == 1 ? "Review" : "Reviews"})
        </span>
      </div>
    );
  }
}
