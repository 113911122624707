import React from "react";
import { ReviewsAPI } from "../api/Reviews.api";
import { EventPreviewPageView } from "./EventPreviewPageView";
import { ReactMatch } from "../model/ReactMatchObj";
import { ReceivedReviewResponse } from "../model/Reviews";
import { FetchContext } from "../model/enums/FetchContext.enum";
import { PageProps } from "../model/views/PageProps";
import { EventApi } from "./Event.Api";
import { EventV2 } from "./Event.Model";

interface EventLandingPageContentState {
  event: EventV2;
  instructorReviews?: ReceivedReviewResponse;
}

interface EventLandingPageContentPathParams {
  eventId: string;
}

interface EventLandingPageContentProps extends PageProps {
  match: ReactMatch<EventLandingPageContentPathParams>;
}

export class EventLandingPageContent extends React.Component<
  EventLandingPageContentProps,
  EventLandingPageContentState
> {
  componentDidMount() {
    EventApi.get(this.props.match.params.eventId, FetchContext.PUBLIC).then(
      (event: EventV2) => {
        this.setState({ event: event });
        ReviewsAPI.getReceivedReviews(event.instructors[0].id).then(
          (response) => {
            this.setState({ instructorReviews: response });
          }
        );
      }
    );
  }

  render() {
    return (
      <div className="main-container">
        {this.state && this.state.event ? (
          <>
            <div className="alert alert-warning" role="alert">
              If you have any concerns or facing any issues, feel free to send
              us your concern at{" "}
              <span className="text-bold">
                {process.env.REACT_APP_CONTACT_EMAIL}
              </span>
            </div>
            <EventPreviewPageView
              instructorReviews={this.state.instructorReviews}
              event={this.state.event}
              user={this.props.user}
            />
          </>
        ) : null}
      </div>
    );
  }
}
