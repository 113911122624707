import { FieldArray, useField } from "formik";
import React from "react";
import { AddTitle } from "../components/common/Add.title";
import { DeleteTitle } from "../components/common/Delete.title";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { Label } from "../components/common/Form/elements/FormInputLabel";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { EBTextInputV2 } from "../components/common/input/TextLine.input";
import { AttachmentInput } from "../components/common/upload/AttachmentInput";
import { AttachmentV1 } from "../model/Attachment";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { EditOfferingOptionsComponent } from "../offerings/EditOfferingOptions.component";
import { OfferingTypeName } from "../offerings/Offering";
import { OfferingInputSection } from "../offerings/Offering.Input.Section";
import { OfferingsEditComponent } from "../offerings/Offerings.Edit.Component";
import { User } from "../user/User";
import { ExpertStudioContentProps } from "./ExpertStudio.page";

interface OfferingsDigitalProductEditExpertStudioContentProps
  extends ExpertStudioContentProps {}

export function OfferingsDigitalProductEditExpertStudioContent(
  props: OfferingsDigitalProductEditExpertStudioContentProps
) {
  return (
    <div className="OfferingsDigitalProductEditExpertStudioContent">
      <h6>Edit Digital Product Offerings:</h6>

      <OfferingsEditComponent
        user={props.user}
        offeringType={OfferingTypeName.DIGITAL_PRODUCT}
        offeringTargetType={AttachmentTargetType.OFFERING_DIGITAL_PRODUCT}
        titleSideNode={
          <OfferingInputSection
            title={<></>}
            content={
              <EditOfferingOptionsComponent
                offeringSessionTypeFieldName={"sessionType"}
                name={`commonDetails.options`}
                skills={[]}
                sessionTypes={[]}
                user={props.user}
                hideAddBtn={true}
              />
            }
          />
        }
        postTitleNode={
          <OfferingInputSection
            title={
              <Label
                label="Content"
                tooltip="Digital product which users will get on purchasing this"
              />
            }
            content={<ContentInput user={props.user} name={`attachments`} />}
          />
        }
      />
    </div>
  );
}

interface ContentInputProps extends FormikInputParams {
  user: User;
}
function ContentInput(props: ContentInputProps) {
  const [field] = useField(props);
  const existingAttachments: AttachmentV1[] = field.value ?? [];
  return (
    <>
      <FieldArray name={props.name}>
        {({ insert, remove, push }: any) => (
          <div className="row">
            {existingAttachments.length == 0 && (
              <div className="col text-danger">
                You have not added any content yet. Please add the digital items
                using the add button below.
              </div>
            )}
            {existingAttachments.map((existingAttachment, index) => (
              <div className="col-md-6">
                <div className="card p-2 mb-2 " key={index}>
                  <div>
                    <Label label="Title:" required={true} />
                    <EBTextInputV2
                      name={`${props.name}.${index}.title`}
                      required={true}
                    />
                  </div>
                  <div className="mt-2">
                    <Label label="Item:" required={true} />
                    <AttachmentInput
                      name={`${props.name}.${index}.location`}
                      user={props.user}
                      required={true}
                    />
                  </div>

                  <EBButtonV2
                    className=" btn-light"
                    onClick={() => remove(index)}
                    disabled={props.disabled}
                    content={{ normal: <DeleteTitle /> }}
                  />
                </div>
              </div>
            ))}
            <div className="">
              <EBButtonV2
                className="btn-secondary btn-sm mt-1"
                onClick={() => push({})}
                disabled={props.disabled}
                content={{ normal: <AddTitle text="Add Content" /> }}
              />
            </div>
          </div>
        )}
      </FieldArray>
    </>
  );
}
