import React from "react";
import { ImageComponent } from "../components/common/Image.component";
import { Company } from "./Company";
import { HTMLProps } from "../model/HTMLProps";

interface CompanyImageProps extends HTMLProps {
  company: Company;
}
export function CompanyImage(props: CompanyImageProps) {
  return (
    <>
      {props.company.dp && (
        <ImageComponent
          image={props.company.dp}
          maxWidth={50}
          {...(props as HTMLProps)}
          className={`rounded ${props.className}`}
        />
      )}
    </>
  );
}
