import React, { useEffect, useState } from "react";
import { PaymentApi } from "../../payments/Payment.Api";
import { Payment } from "../../payments/Payment.Model";
import { PaymentsList } from "../../payments/Payments.List";

export function AdminPaymentsContent() {
  let [payments, setPayments] = useState<Payment[]>();
  useEffect(() => {
    PaymentApi.list()
      .executeV2()
      .then((payments) => {
        setPayments(payments);
      });
  }, []);
  return (
    <div className="main-container">
      <h1>Payments</h1>

      <div>{payments && <PaymentsList payments={payments} />}</div>
    </div>
  );
}
