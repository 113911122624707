import React from "react";
import { Skill } from "../../model/Skill";
import { SkillAPI } from "../../skills/SkillAPI";
import {
  EBSelectOption,
  EBSelectOptionDataType,
  EBSelectV2,
} from "./Form/EBSelectV2";
import { SelectorProps } from "./Selectors";

interface SkillSelectorProps extends SelectorProps {
  skills: Skill[];
  isMulti?: boolean;
}

export interface SkillSelectorOption extends EBSelectOptionDataType, Skill {}

export function SkillSelector(props: SkillSelectorProps) {
  function onCreate(
    title: string
  ): Promise<EBSelectOption<SkillSelectorOption>> {
    return SkillAPI.add(title).then((skill) => {
      return {
        label: skill.dl,
        value: `${skill.id}`,
        data: {
          ...skill,
          selectOptionValue: `${skill.id}`,
        },
      };
    });
  }
  let options: EBSelectOption<SkillSelectorOption>[] = [];

  props.skills.forEach((skill: Skill) => {
    options.push({
      label: skill.dl,
      value: `${skill.id}`,
      data: {
        ...skill,
        selectOptionValue: `${skill.id}`,
      },
    });
  });

  return (
    <div className="row">
      <div className="col">
        {options && options.length > 0 && (
          <EBSelectV2
            label={props.label}
            name={props.name}
            required={props.required}
            options={options}
            disabled={props.disabled}
            isMulti={props.isMulti}
            onCreate={onCreate}
            isDataEqual={(d1: SkillSelectorOption, d2: SkillSelectorOption) => {
              return d1.id === d2.id;
            }}
          />
        )}
      </div>
    </div>
  );
}
