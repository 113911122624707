import React, { useEffect, useState } from "react";
import {
  Operator,
  PriceDifferentialSettings,
  PriceDifferentialSettingsApplyType,
  PriceDifferentialSettingsApplyTypePercent,
} from "./PriceDifferentialSettings.Model";
import { PriceDifferentialSettingsApi } from "./PriceDifferentialSettings.Api";
import { AttachmentTargetType } from "../../model/AttachmentTargetType";
import { Form, Formik } from "formik";
import { message, Row, Space } from "antd";
import { EBButtonV2 } from "../../components/common/EBButtonV2";
import { reloadWindowCallback } from "../../components/common/reloadCallback";
import { CountryCode } from "../../model/Country.model";
import { Loader } from "../../loader/Loader";
import { EBPercentFormikInput } from "../../percent/EBPercent.FormikInput";

export interface PriceDifferentialSettingsListProps {}

export function EditPriceDifferentialSettings(
  props: PriceDifferentialSettingsListProps
) {
  let [userPds, setUserPds] = useState<PriceDifferentialSettings[]>([]);
  let [loading, setLoading] = useState<boolean>(false);

  useEffect(function () {
    setLoading(true);
    PriceDifferentialSettingsApi.list()
      .executeV2()
      .then((userPds: PriceDifferentialSettings[]) => {
        setUserPds(userPds);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="EditPriceDifferentialSettings">
      {loading && <Loader />}
      {!loading && (
        <EditPriceDifferentialSettingsCountryBased allPds={userPds} />
      )}
    </div>
  );
}

export interface EditPriceDifferentialSettingsCountryBasedProps {
  allPds: PriceDifferentialSettings[];
}

export function EditPriceDifferentialSettingsCountryBased(
  props: EditPriceDifferentialSettingsCountryBasedProps
) {
  let countryBasedPdses = props.allPds.filter(
    (pds) => pds.matchInfo.matchType == AttachmentTargetType.COUNTRY
  );

  // Currently we expect to have only 1 max pds for country based differential pricing.
  let countryBasedPds =
    countryBasedPdses && countryBasedPdses.length >= 1
      ? countryBasedPdses[0]
      : undefined;

  return (
    <div className="EditPriceDifferentialSettingsCountryBased">
      <Formik
        enableReinitialize={true}
        initialValues={
          countryBasedPds ??
          ({
            matchInfo: {
              matchType: AttachmentTargetType.COUNTRY,
              matchData: {
                countryCode: CountryCode.IN,
                operator: Operator.NOT,
              },
            },
            applyInfo: {
              applyType: PriceDifferentialSettingsApplyType.PERCENT,
              applyData: { value: 0 },
            },
          } as PriceDifferentialSettings)
        }
        onSubmit={function (values) {
          if (values.id) {
            return PriceDifferentialSettingsApi.update(values.id)
              .executeV2(values)
              .then((updatedPds) => {
                message.success("Settings Updated Successfully");
                return updatedPds;
              });
          } else {
            return PriceDifferentialSettingsApi.add()
              .executeV2(values)
              .then((addedPds) => {
                message.success("Settings Updated Successfully");
                reloadWindowCallback();
                return addedPds;
              });
          }
        }}
      >
        {(formProps) => (
          <Form>
            Do you want to use different price outside India? Give by how much
            percent you want to increase it?
            <div>
              <Space>
                <EBPercentFormikInput name={"applyInfo.applyData.value"} />
                <EBButtonV2
                  content={{ normal: "Save" }}
                  loading={formProps.isSubmitting}
                  type="submit"
                />
              </Space>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
