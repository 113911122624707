import { Form, Formik } from "formik";
import React from "react";
import { useAlert } from "react-alert";
import TimeAgo from "timeago-react";
import { UserHelpers } from "../../../helpers/Helpers";
import { WindowHelpers } from "../../../helpers/Window.helper";
import { MessageResponse } from "../../../model/response/Message.response";
import { User, UserBasicDetails } from "../../../user/User";
import { MarkdownViewV1 } from "../MarkdownView";
import { MarkdownInput } from "../input/Markdown.input";
import { EventApi } from "../../../events/Event.Api";

interface MessageViewProps {
  message: MessageResponse;
}
function MessageView(props: MessageViewProps) {
  return (
    <div className="hstack gap-2">
      <div className="mb-auto">
        <img
          width="50"
          src={UserHelpers.getProfilePicUrl(props.message.user)}
          className="rounded-circle"
        />
      </div>
      <div className="w-100">
        <div className="row">
          <div className="col-md-6 review-block-name float-left font-weight-bold">
            {UserHelpers.getFullName(props.message.user)}
          </div>
          {props.message.createdAt && (
            <div className="col-md-6 review-block-date font-weight-light mt-1 text-right">
              <TimeAgo datetime={props.message.createdAt} />
            </div>
          )}
        </div>

        <div className="review-block-description">
          <MarkdownViewV1>{props.message.message}</MarkdownViewV1>
        </div>
      </div>
    </div>
  );
}

interface MessagesLisViewProps {
  messages: MessageResponse[];
}

export function MessagesLisView(props: MessagesLisViewProps) {
  return (
    <div>
      {props.messages.map((message, index) => {
        return (
          <>
            <MessageView message={message} />
            <hr />
          </>
        );
      })}
    </div>
  );
}

interface AddMessageViewProps {
  eventId: string;
  user: User;
}
export function AddMessageView(props: AddMessageViewProps) {
  let alert = useAlert();
  return (
    <div>
      <div>
        <Formik
          initialValues={{ message: "" }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            EventApi.addMessage(props.eventId, values)
              .then(() => {
                actions.setValues({ message: "" });
                actions.setSubmitting(false);
                alert.success("Message added");
                WindowHelpers.reload();
              })
              .catch(() => {
                actions.setSubmitting(false);
                alert.error("Message could not be added");
              });
          }}
        >
          {(formProps) => (
            <Form>
              <MarkdownInput
                label="Add Message"
                name="message"
                // maxLength={1000}
                // rows={4}
                helpText=""
                required
                user={props.user}
              />
              <button
                className="btn btn-primary mt-2"
                type="submit"
                disabled={formProps.isSubmitting}
              >
                {formProps.isSubmitting ? "Saving..." : "Send"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div></div>
    </div>
  );
}
