import axios from "axios";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { ApiUrlHelpers } from "../helpers/Helpers";
import { ReviewV1 } from "../model/Reviews";
import { FetchContext } from "../model/enums/FetchContext.enum";
import { MessageCURequest } from "../model/requests/MessageCU.request";
import { MessageResponse } from "../model/response/Message.response";
import { User, UserBasicDetails } from "../user/User";
import { EventBasicDetails, EventTicket, EventV2 } from "./Event.Model";
import { EventsConstants } from "./Events.Constants";

export class EventApi {
  public static async create(): Promise<EventV2> {
    return new Api<void, EventV2>(
      EventsConstants.API_EVENTS,
      ApiMethod.POST
    ).execute();
  }

  public static async updateBasic(
    eventId: string,
    basicDetails: EventBasicDetails
  ): Promise<void> {
    return new Api<EventBasicDetails, void>(
      EventsConstants.API_EVENTS_DETAILS_BASIC.replaceAll("{eventId}", eventId),
      ApiMethod.PUT
    ).execute(basicDetails);
  }

  public static async updateInstructors(
    eventId: string,
    instructorsInput: { instructors: UserBasicDetails[] }
  ): Promise<void> {
    return new Api<{ instructors: UserBasicDetails[] }, void>(
      EventsConstants.API_EVENTS_DETAILS_INSTRUCTORS.replaceAll(
        "{eventId}",
        eventId
      ),
      ApiMethod.PUT
    ).execute(instructorsInput);
  }

  public static async updateTickets(
    eventId: string,
    ticketsInput: { tickets: EventTicket[] }
  ): Promise<EventTicket[]> {
    return new Api<{ tickets: EventTicket[] }, EventTicket[]>(
      EventsConstants.API_EVENTS_DETAILS_TICKETS.replaceAll(
        "{eventId}",
        eventId
      ),
      ApiMethod.PUT
    ).execute(ticketsInput);
  }

  public static async updatePublished(
    eventId: string,
    published: boolean
  ): Promise<EventV2> {
    return new Api<void, EventV2>(
      EventsConstants.API_EVENTS_DETAILS_PUBLISHED.replaceAll(
        "{eventId}",
        eventId
      ).replaceAll("{published}", "" + published),
      ApiMethod.PUT
    ).execute();
  }

  public static async get(
    eventId: string,
    fetchContext: FetchContext
  ): Promise<EventV2> {
    let api = EventsConstants.API_EVENT.replace("{eventId}", eventId).replace(
      "{fetchContext}",
      fetchContext
    );
    return new Api<void, EventV2>(api, ApiMethod.GET).execute();
  }

  public static async getEvents(
    postedById: string,
    fetchContext: FetchContext,
    includePast?: boolean
  ): Promise<EventV2[]> {
    let api = EventsConstants.API_POSTED_BY_EVENTS.replace(
      "{postedById}",
      postedById
    ).replace("{fetchContext}", fetchContext);
    if (includePast != undefined) {
      api += "&includePast=" + includePast;
    }
    return new Api<void, EventV2[]>(api, ApiMethod.GET).execute();
  }

  public static deleteEvent(eventId: string): Api<void, void> {
    let api = EventsConstants.API_EVENT_DELETE.replace("{eventId}", eventId);
    return new Api<void, void>(api, ApiMethod.DELETE);
  }

  public static async getAttendees(eventId: string): Promise<User[]> {
    return new Promise<User[]>((resolve, reject) => {
      axios
        .get(ApiUrlHelpers.eventAttendeesFetchAll(eventId))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async getMessages(eventId: string): Promise<MessageResponse[]> {
    let api = apiLinkConstants.EVENT_MESSAGES_FETCH_ALL.replace(
      "{eventId}",
      eventId
    );
    return new Promise<MessageResponse[]>((resolve, reject) => {
      axios
        .get(api)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async getReviews(eventId: string): Promise<ReviewV1[]> {
    let api = apiLinkConstants.EVENT_REVIEWS_FETCH_ALL.replace(
      "{eventId}",
      eventId
    );
    return new Promise<ReviewV1[]>((resolve, reject) => {
      axios
        .get(api)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async addMessage(
    eventId: string,
    messageRequest: MessageCURequest
  ): Promise<MessageResponse[]> {
    let api = apiLinkConstants.EVENT_MESSAGES_ADD.replace("{eventId}", eventId);
    return new Promise<MessageResponse[]>((resolve, reject) => {
      axios
        .post(api, messageRequest)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async complete(eventId: string): Promise<void> {
    let api = apiLinkConstants.EVENT_MESSAGES_COMPLETE.replace(
      "{eventId}",
      eventId
    );
    return new Promise<void>((resolve, reject) => {
      axios
        .post(api)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
