import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { SocialType } from "./Social.model";

export class SocialApi {
  public static getAllSocialTypes(): Promise<SocialType[]> {
    return new Api<void, SocialType[]>(
      "/api/social/types",
      ApiMethod.GET
    ).execute();
  }
}
