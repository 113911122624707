import React from "react";
import { SocialType } from "../../../social/Social.model";
import {
  EBSelectOption,
  EBSelectOptionDataType,
  EBSelectV2,
} from "../Form/EBSelectV2";
import { SelectorProps } from "../Selectors";

interface SocialTypeSelectorProps extends SelectorProps {
  socialTypes: SocialType[];
  isMulti?: boolean;
}

export interface SocialTypeSelectorOption
  extends EBSelectOptionDataType,
    SocialType {}

export function SocialTypeSelector(props: SocialTypeSelectorProps) {
  let options: EBSelectOption<SocialTypeSelectorOption>[] = [];

  props.socialTypes.forEach((socialType: SocialType) => {
    options.push({
      label: socialType.dl,
      value: `${socialType.id}`,
      data: {
        ...socialType,
        selectOptionValue: `${socialType.id}`,
      },
    });
  });

  return (
    <div className="row">
      <div className="col">
        {options && options.length > 0 && (
          <EBSelectV2
            label={props.label}
            name={props.name}
            required={props.required}
            options={options}
            disabled={props.disabled}
            isMulti={props.isMulti}
            isDataEqual={(
              d1: SocialTypeSelectorOption,
              d2: SocialTypeSelectorOption
            ) => {
              return d1.id === d2.id;
            }}
          />
        )}
      </div>
    </div>
  );
}
