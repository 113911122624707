import React, { useEffect, useState } from "react";
import { BookingCommonComponent } from "../../booking/Booking.Common.Component";
import { Booking } from "../../booking/Booking.model";
import { CommunityComponent } from "../../community/Community.Component";
import { EBButtonV2 } from "../../components/common/EBButtonV2";
import { ImageComponent } from "../../components/common/Image.component";
import { FormList } from "../../form/Form.List";
import { WindowHelpers } from "../../helpers/Window.helper";
import { SidebarLayoutSliding } from "../../layout/Sidebar.Layout";
import { AttachmentTargetType } from "../../model/AttachmentTargetType";
import { ExpertSessionRole } from "../../model/ExpertSession";
import { LocationType } from "../../model/Picture";
import { User } from "../../user/User";
import { OfferingCommonDetails, OfferingFetchContext } from "../Offering";
import { OfferingAPI } from "../Offering.api";
import { OfferingPathAttendeeDrawerButton } from "./Offering.Path.AttendeeDrawer.Button";

interface OfferingsPathStepsPageContentLayoutProps {
  sidebar: React.ReactNode;
  content: React.ReactNode;
  contentFooter: React.ReactNode;
  offeringId: string;
  offering?: OfferingCommonDetails;
  booking?: Booking;
  liu: User;
}

export enum OfferingsPathStepsPageContentLayoutTabName {
  STEPS = "STEPS",
  RATING = "RATING",
  DISCUSSION = "DISCUSSION",
}

export function OfferingsPathStepsPageContentLayout(
  props: OfferingsPathStepsPageContentLayoutProps
) {
  let [currentTab, setCurrentTab] = useState(
    WindowHelpers.qParam("mt") ??
      OfferingsPathStepsPageContentLayoutTabName.STEPS
  );

  return (
    <span className="OfferingsPathStepsPageContentLayout">
      <div className="row m-0 h-100">
        <div className="col-auto px-0 border-right">
          <div className="w-100">
            <EBButtonV2
              className={`btn btn-lg btn-light rounded-0 w-100 ${
                currentTab ==
                  OfferingsPathStepsPageContentLayoutTabName.STEPS && "active"
              }`}
              content={{
                normal: (
                  <ImageComponent
                    image={{
                      locationType: LocationType.FA_CLASS,
                      data: "fa-solid fa-list-ol",
                    }}
                    width={20}
                    height={20}
                  />
                ),
              }}
              onClick={() =>
                setCurrentTab(OfferingsPathStepsPageContentLayoutTabName.STEPS)
              }
            />
          </div>{" "}
          <div className="w-100">
            <OfferingPathAttendeeDrawerButton
              className={`btn btn-lg btn-light rounded-0 w-100`}
              offeringId={props.offeringId}
            />
          </div>{" "}
          <div className="w-100">
            <EBButtonV2
              className={`btn btn-lg btn-light rounded-0 w-100 ${
                currentTab ==
                  OfferingsPathStepsPageContentLayoutTabName.RATING && "active"
              }`}
              content={{
                normal: (
                  <ImageComponent
                    image={{
                      locationType: LocationType.FA_CLASS,
                      data: "fa-solid fa-star",
                    }}
                    width={20}
                    height={20}
                  />
                ),
              }}
              onClick={() =>
                setCurrentTab(OfferingsPathStepsPageContentLayoutTabName.RATING)
              }
            />
          </div>
          {props.offering?.linkedCommunityId && (
            <EBButtonV2
              className={`btn btn-lg btn-light rounded-0 w-100 ${
                currentTab ==
                  OfferingsPathStepsPageContentLayoutTabName.DISCUSSION &&
                "active"
              }`}
              content={{
                normal: (
                  <ImageComponent
                    image={{
                      locationType: LocationType.FA_CLASS,
                      data: "fa-solid fa-comments",
                    }}
                    width={20}
                    height={20}
                  />
                ),
              }}
              onClick={() =>
                setCurrentTab(
                  OfferingsPathStepsPageContentLayoutTabName.DISCUSSION
                )
              }
            />
          )}
        </div>
        <div className="col p-0">
          {currentTab == OfferingsPathStepsPageContentLayoutTabName.STEPS && (
            <StepsContent {...props} />
          )}

          {currentTab == OfferingsPathStepsPageContentLayoutTabName.RATING && (
            <RatingContent {...props} />
          )}

          {currentTab ==
            OfferingsPathStepsPageContentLayoutTabName.DISCUSSION && (
            <DiscussionContent {...props} />
          )}
        </div>
      </div>
    </span>
  );
}

function RatingContent(props: OfferingsPathStepsPageContentLayoutProps) {
  return (
    <div className="pt-4 pl-3">
      {props.booking && props.booking.bcd.role == ExpertSessionRole.MENTEE && (
        <BookingCommonComponent booking={props.booking} user={props.liu} />
      )}

      {((props.booking &&
        (props.booking.bcd.role == ExpertSessionRole.EXPERT ||
          props.booking.bcd.role == ExpertSessionRole.ADMIN)) ||
        !props.booking) && (
        <>
          <div className="">
            <h6 className="m-0 font-weight-bold text-primary">
              All received for this offering:
            </h6>

            <FormList
              onFetch={() => {
                return OfferingAPI.getFeedbackForms({
                  targetType: AttachmentTargetType.OFFERING_PATH,
                  targetId: props.offeringId,
                }).executeV2({ excludeIfHasThisPermission: [] });
              }}
              user={props.liu}
            />
          </div>
        </>
      )}
    </div>
  );
}

function StepsContent(props: OfferingsPathStepsPageContentLayoutProps) {
  return (
    <span className="StepsContent">
      <SidebarLayoutSliding
        sidebar={props.sidebar}
        mainContent={
          <div className="d-flex flex-column h-100">
            <div className="flex-grow-1">{props.content}</div>
            <div className="border-top px-2 bg-light">
              {props.contentFooter}
            </div>
          </div>
        }
      />
    </span>
  );
}

function DiscussionContent(props: OfferingsPathStepsPageContentLayoutProps) {
  let [offering, setOffering] = useState<OfferingCommonDetails>();
  useEffect(
    function () {
      if (props.offering) {
        setOffering(props.offering);
      } else {
        OfferingAPI.getCommonDetails(
          props.offeringId,
          OfferingFetchContext.PUBLIC
        )
          .executeV2()
          .then(function (commonDetails) {
            setOffering(commonDetails);
          });
      }
    },
    [props.offeringId]
  );

  return (
    <span className="DiscussionContent">
      {offering && offering.linkedCommunityId && (
        <CommunityComponent
          communityId={offering.linkedCommunityId}
          liu={props.liu}
        />
      )}
    </span>
  );
}
