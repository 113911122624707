import { Form, Formik } from "formik";
import React from "react";
import Moment from "react-moment";
import { InterviewRoundAPI } from "../../../api/InterviewRoundAPI";
import { apiLinkConstants, publicLinkConstants } from "../../../constants";
import { AttachmentHelper } from "../../../helpers/Attachments.helper";
import { InterviewRoundHelpers, UserHelpers } from "../../../helpers/Helpers";
import { InterviewRound } from "../../../model/InterviewRound";
import { User } from "../../../user/User";
import {
  Api,
  ApiLinkedButton,
  ApiMethod,
  CallbackButton,
} from "../ApiLinkedButton";
import { EBModal } from "../EBModal";
import { DateTimeUpdateForm } from "../Form/DateTimeUpdate.form";
import { UserPickerView } from "../UserPickerView";
import { reloadWindowCallback, reloadWindowPromise } from "../reloadCallback";
import { InterviewRoundAttachmentView } from "./InterviewRoundAttachment";

interface ScheduleButtonProps {
  companyId: string;
  interviewRound: InterviewRound;
}

function ScheduleButton(mainProps: ScheduleButtonProps) {
  let ir = mainProps.interviewRound;
  let getScheduleCallback = (
    companyId: string,
    interviewLoopId: string,
    interviewRoundId: string
  ): (() => Promise<void>) => {
    return (): Promise<void> => {
      return new Promise((resolve, reject) => {
        InterviewRoundAPI.schedule(companyId, interviewLoopId, interviewRoundId)
          .then((response) => {
            reloadWindowCallback();
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  };

  return (
    <CallbackButton
      uniqueKey={ir.id + "-schedule-button"}
      disabled={
        !AttachmentHelper.isAttachmentPresent(ir.resume) ||
        !AttachmentHelper.isAttachmentPresent(ir.guideline) ||
        !AttachmentHelper.isAttachmentPresent(ir.location) ||
        !AttachmentHelper.isAttachmentPresent(ir.interviewerFeedback) ||
        !ir.startTimestamp ||
        !ir.interviewer
      }
      buttonProps={{
        text: { normal: "Schedule", submitting: "Scheduling..." },
      }}
      callback={getScheduleCallback(
        mainProps.companyId,
        mainProps.interviewRound.interviewLoopId,
        mainProps.interviewRound.id
      )}
    />
  );
}

interface AssignInterviewerViewProps {
  companyId: string;
  interviewRound: InterviewRound;
  uniqueKey: string;
  callback: () => void;
}

function UpdateInterviewerView(mainProps: AssignInterviewerViewProps) {
  function UserRow(props: { user: User }) {
    return (
      <li className="list-group-item">
        <Formik
          initialValues={{}}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            InterviewRoundAPI.updateInterviewer(
              mainProps.companyId,
              mainProps.interviewRound.interviewLoopId,
              mainProps.interviewRound.id,
              { interviewerUserId: props.user.basicDetails.id }
            )
              .then(() => {
                actions.setSubmitting(false);
                mainProps.callback();
              })
              .catch((err) => {
                actions.setSubmitting(false);
              });
          }}
        >
          {(formProps) => (
            <Form>
              <button
                className="btn btn-sm btn-primary mr-2"
                type="submit"
                disabled={formProps.isSubmitting}
              >
                {formProps.isSubmitting
                  ? "Assigning. Please Wait..."
                  : "Assign as Interviewer"}
              </button>
              {UserHelpers.getUserFullName(props.user)} (
              {props.user.contactDetails?.email})
            </Form>
          )}
        </Formik>
      </li>
    );
  }

  return (
    <></>
    // <UserPickerView
    //   RowItemComponent={UserRow}
    //   uniqueKey={mainProps.uniqueKey + "-user-picker"}
    //   modalCloseButtonId={
    //     mainProps.uniqueKey + "-user-picker-modal-close-button"
    //   }
    // />
  );
}

interface InterviewRoundInterviewerViewProps {
  companyId: string;
  interviewRound: InterviewRound;
  interviewer?: User;
  uniqueKey: string;
}
function InterviewRoundInterviewerView(
  props: InterviewRoundInterviewerViewProps
) {
  let interviewer = props.interviewer;

  return (
    <div className="hstack gap-2">
      {interviewer
        ? UserHelpers.getUserFullName(interviewer)
        : "Not yet assigned"}
      <UpdateInterviewerView
        callback={reloadWindowCallback}
        {...props}
        uniqueKey={props.uniqueKey + "-update-interviewer"}
      />
    </div>
  );
}

interface UpdateInterviewRoundStartTimestampViewProps {
  companyId: string;
  interviewRound: InterviewRound;
  date?: string;
  callback: () => void;

  uniqueKey: string;
}

function UpdateInterviewStartTimestampView(
  props: UpdateInterviewRoundStartTimestampViewProps
) {
  let submitCallback = (pickedDate: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      InterviewRoundAPI.updateStartTimestamp(
        props.companyId,
        props.interviewRound.interviewLoopId,
        props.interviewRound.id,
        { startTimestamp: pickedDate }
      )
        .then((response) => {
          resolve();
          props.callback();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  function Body({}) {
    return (
      <DateTimeUpdateForm
        initialDateValue={props.date}
        submitCallback={submitCallback}
      />
    );
  }

  let modalId = props.uniqueKey + "-attachment";
  return (
    <div className="update-attachment-view">
      <EBModal
        id={modalId}
        title={"Update Start Timestamp"}
        BodyComponent={<Body />}
      />
      <button
        type="button"
        className="btn btn-sm btn-secondary"
        data-bs-toggle="modal"
        data-bs-target={"#" + modalId}
      >
        <i className="fas fa-edit"></i>
      </button>
    </div>
  );
}

interface IntervieRoundStartTimestampViewProps {
  companyId: string;
  interviewRound: InterviewRound;
  date?: string;
  uniqueKey: string;
}
function IntervieRoundStartTimestampView(
  props: IntervieRoundStartTimestampViewProps
) {
  let uniqueKey = props.uniqueKey + "-prop-start-timestamp";
  return (
    <div className="hstack gap-2">
      {props.date ? (
        <Moment format="DD MMM YYYY - hh:mm a">{props.date}</Moment>
      ) : (
        "Not Present"
      )}
      <UpdateInterviewStartTimestampView
        {...props}
        callback={reloadWindowCallback}
        uniqueKey={uniqueKey}
      />
    </div>
  );
}

interface InterviewRoundListProps {
  companyId: string;
  interviewRounds: InterviewRound[];
  uniqueKey: string;
}
export class InterviewRoundList extends React.Component<
  InterviewRoundListProps,
  {}
> {
  render() {
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Round #</th>
              <th scope="col">Loop</th>
              <th scope="col">Candidate</th>
              <th scope="col">Round Name</th>
              <th scope="col">Status</th>
              <th scope="col">Recording</th>
              <th scope="col">Guideline</th>
              <th scope="col">Interviewer</th>
              <th scope="col">Meeting Link</th>
              <th scope="col">Interviewer Feedback</th>
              <th scope="col">Start timestamp</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {this.props.interviewRounds.map((interviewRound, index) => {
              let completeApiUrl =
                apiLinkConstants.INTERVIEW_ROUND_COMPLETE.replace(
                  "{companyId}",
                  this.props.companyId
                )
                  .replace("{interviewLoopId}", interviewRound.interviewLoopId)
                  .replace("{interviewRoundId}", interviewRound.id);

              let cancelApiUrl =
                apiLinkConstants.INTERVIEW_ROUND_CANCEL.replace(
                  "{companyId}",
                  this.props.companyId
                )
                  .replace("{interviewLoopId}", interviewRound.interviewLoopId)
                  .replace("{interviewRoundId}", interviewRound.id);

              let loopLink =
                publicLinkConstants.SPECIFIC_INTERVIEW_LOOP_PAGE.replace(
                  ":companyId",
                  this.props.companyId
                ).replace(":interviewLoopId", interviewRound.interviewLoopId);
              let roundUniqueKey =
                this.props.uniqueKey + "ir-" + interviewRound.id;
              return (
                <tr key={interviewRound.id}>
                  <td>{index + 1}</td>
                  <td>{interviewRound.id}</td>
                  <td>
                    <a href={loopLink}>Loop</a>
                  </td>
                  <td>
                    {UserHelpers.getUserFullName(interviewRound.candidate)}
                  </td>
                  <td>{interviewRound.name}</td>
                  <td>
                    {InterviewRoundHelpers.getDisplayStatus(interviewRound)}
                  </td>
                  <td>
                    <InterviewRoundAttachmentView
                      attachmentDisplayName="Recording"
                      attachment={interviewRound.recording}
                      interviewRoundId={interviewRound.id}
                      uniqueKey={roundUniqueKey}
                    />
                  </td>
                  <td>
                    <InterviewRoundAttachmentView
                      attachmentDisplayName="Guideline"
                      attachment={interviewRound.guideline}
                      interviewRoundId={interviewRound.id}
                      uniqueKey={roundUniqueKey}
                    />
                  </td>
                  <td>
                    <InterviewRoundInterviewerView
                      {...this.props}
                      interviewRound={interviewRound}
                      interviewer={interviewRound.interviewer}
                      uniqueKey={roundUniqueKey}
                    />
                  </td>
                  <td>
                    <InterviewRoundAttachmentView
                      attachmentDisplayName="Meeting Link"
                      attachment={interviewRound.location}
                      interviewRoundId={interviewRound.id}
                      uniqueKey={roundUniqueKey}
                    />
                  </td>
                  <td>
                    <InterviewRoundAttachmentView
                      attachmentDisplayName="Interviewer Feedback"
                      attachment={interviewRound.interviewerFeedback}
                      interviewRoundId={interviewRound.id}
                      uniqueKey={roundUniqueKey}
                    />
                  </td>
                  <td>
                    <IntervieRoundStartTimestampView
                      {...this.props}
                      interviewRound={interviewRound}
                      date={interviewRound.startTimestamp}
                      uniqueKey={roundUniqueKey}
                    />
                  </td>
                  <td>
                    {!interviewRound.scheduledAt &&
                      !interviewRound.completedAt && (
                        <ScheduleButton
                          {...this.props}
                          interviewRound={interviewRound}
                        />
                      )}

                    {interviewRound.scheduledAt &&
                      !interviewRound.completedAt &&
                      !interviewRound.cancelledAt &&
                      !interviewRound.archivedAt && (
                        <ApiLinkedButton
                          uniqueKey={
                            this.props.uniqueKey +
                            "ir-" +
                            interviewRound.id +
                            "-complete-button"
                          }
                          api={new Api(completeApiUrl, ApiMethod.POST)}
                          callback={reloadWindowPromise}
                          buttonProps={{
                            text: {
                              normal: "Complete",
                              submitting: "Completing...",
                            },
                            type: "success",
                          }}
                          disabled={
                            !AttachmentHelper.isAttachmentPresent(
                              interviewRound.recording
                            ) ||
                            !AttachmentHelper.isAttachmentPresent(
                              interviewRound.interviewerFeedback
                            )
                          }
                        />
                      )}

                    {!interviewRound.completedAt &&
                      !interviewRound.cancelledAt && (
                        <div className="mt-1">
                          <ApiLinkedButton
                            showConfirmation={true}
                            uniqueKey={
                              this.props.uniqueKey +
                              "ir-" +
                              interviewRound.id +
                              "-cancel-button"
                            }
                            api={new Api(cancelApiUrl, ApiMethod.POST)}
                            callback={reloadWindowPromise}
                            buttonProps={{
                              text: {
                                normal: "Cancel",
                                submitting: "Cancelling...",
                              },
                              type: "danger",
                            }}
                          />
                        </div>
                      )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
