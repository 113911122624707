import React from "react";
import ReactDOM from "react-dom";
import { EBButtonV2, EBButtonV2Props } from "../components/common/EBButtonV2";
import { EBModalV2 } from "../components/common/EBModalV2";

interface ModalLinkedButtonProps {
  modalBody: React.ReactNode;
  modalSize?: "sm" | "md" | "lg" | "xl";
  uniqueKey: string;
  btnProps: EBButtonV2Props;
  header?: React.ReactNode;
}

export function ModalLinkedButton(props: ModalLinkedButtonProps) {
  const modalsRoot = document.getElementById("modals") as HTMLElement;

  return (
    <>
      {ReactDOM.createPortal(
        <EBModalV2
          modalSize={props.modalSize}
          body={props.modalBody}
          id={`${props.uniqueKey}`}
          header={props.header}
        />,
        modalsRoot
      )}

      <EBButtonV2
        {...props.btnProps}
        otherProps={{
          "data-bs-toggle": "modal",
          "data-bs-target": `#${props.uniqueKey}`,
        }}
      />
    </>
  );
}
