import React from "react";
import { PublicPageRoute } from "../routes/PublicPage.route";
import { BookingPathConstants } from "./BookingPath.Constants";
import { BookingPathPageContent } from "./BookingPath.PageContent";

export function BookingPathRoutes() {
  return (
    <>
      <PublicPageRoute
        path={BookingPathConstants.PAGE_BOOKING_PATH}
        contentComponent={BookingPathPageContent}
      />
    </>
  );
}
