export class EBLogger {
  public static error(message: string) {
    console.error(message);
  }

  public static log(message: any, object?: any) {
    if (object) {
      console.log(message + ":");
      console.log(object);
    } else {
      console.log(message);
    }
  }
}
