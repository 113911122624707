import React from "react";
import { ApiLinkedButtonV3 } from "../components/common/Form/ApiLinked.button.V3";
import { ReviewButton } from "../components/common/Review.button";
import { reloadWindowPromise } from "../components/common/reloadCallback";
import { VideoSessionApi } from "../video-sessions/VideoSessions.api";
import { BookingApi } from "./Booking.Api";
import { Booking } from "./Booking.model";

export function BookingAdminActions(props: { booking: Booking }) {
  let booking = props.booking;
  return (
    <div className="">
      {!booking.bcd.engineNotificationsSuppressedAt && (
        <ApiLinkedButtonV3
          className="mb-2"
          buttonProps={{ content: { normal: "Suppress Expert Notifications" } }}
          api={BookingApi.notifExpertSuppress(booking.bookingTarget)}
          uniqueKey={`booking-${booking.id}-notif-expert-suppress`}
          successCallback={reloadWindowPromise}
        />
      )}

      {booking.bcd.engineNotificationsSuppressedAt && (
        <ApiLinkedButtonV3
          className="mb-2"
          buttonProps={{
            content: { normal: "UnSuppress Expert Notifications" },
          }}
          api={BookingApi.notifExpertUnSuppress(booking.bookingTarget)}
          uniqueKey={`booking-${booking.id}-notif-expert-unsuppress`}
          successCallback={reloadWindowPromise}
        />
      )}

      <ApiLinkedButtonV3
        className="mb-2"
        buttonProps={{
          content: { normal: "Send Session Created Notif" },
        }}
        api={BookingApi.notifSendSessionCreated(booking.id!)}
        uniqueKey={`booking-${booking.id}-notif-send-session-created`}
        successCallback={reloadWindowPromise}
      />

      {booking.bcd.engineCompletedAt != null && (
        <>
          <ReviewButton
            uniqueKey={`booking-${booking.id}-create-prr`}
            buttonProps={{
              content: {
                normal: "Create PRR",
                submitting: "Creating...",
              },
            }}
            api={VideoSessionApi.createPrr(booking.id!)}
            successCallback={reloadWindowPromise}
          />
        </>
      )}
    </div>
  );
}
