import React from "react";
import { User } from "../user/User";
import { ModalLinkedButton } from "../button/ModalLinked.Button";
import { Question } from "./Question.Model";
import { SearchQuestionView } from "./SearchQuestionView";
import { CallerContext } from "../components/common/CallerContext";
import { QuestionsAPI } from "./QuestionsAPI";
import { reloadWindowPromise } from "../components/common/reloadCallback";
import { message } from "antd";
import { HTMLProps } from "../model/HTMLProps";
import { ApiLinkedButton } from "../components/common/ApiLinkedButton";

export interface QuestionAdminOptionsProps extends HTMLProps {
  question: Question;
  liu?: User;
}
export function QuestionAdminOptions(props: QuestionAdminOptionsProps) {
  let question = props.question;
  return (
    <>
      {props.liu?.basicDetails.isAdmin && (
        <div
          className={`QuestionAdminOptions card card-body bg-light ${props.className}`}
        >
          Admin options:
          <div className="hstack gap-2">
            <ModalLinkedButton
              modalBody={
                <QuestionMerge
                  questionToMergeInto={props.question}
                  question={question} // not used.
                  liu={props.liu}
                />
              }
              uniqueKey={"q" + props.question.id + "-merge-question"}
              btnProps={{ content: { normal: "Merge" }, className: "btn-sm" }}
            />

            {!question.approvedAt ? (
              <ApiLinkedButton
                api={QuestionsAPI.approve(question.id)}
                uniqueKey={"question" + question.id + "-approve"}
                callback={reloadWindowPromise}
                buttonProps={{
                  text: {
                    normal: "Approve",
                  },
                  type: "success",
                  extraClassNames: "btn-sm",
                }}
              />
            ) : (
              <ApiLinkedButton
                api={QuestionsAPI.disapprove(question.id)}
                uniqueKey={"question" + question.id + "-disapprove"}
                callback={reloadWindowPromise}
                buttonProps={{
                  text: {
                    normal: "Disapprove",
                  },
                  type: "danger",
                  extraClassNames: "btn-sm",
                }}
              />
            )}

            {!question.archivedAt ? (
              <ApiLinkedButton
                uniqueKey={"question" + question.id + "-archive"}
                api={QuestionsAPI.archive(question.id)}
                callback={reloadWindowPromise}
                buttonProps={{
                  text: {
                    normal: "Archive",
                  },
                  type: "info",
                  extraClassNames: "btn-sm",
                }}
              />
            ) : (
              <ApiLinkedButton
                uniqueKey={"question" + question.id + "-unarchive"}
                api={QuestionsAPI.unarchive(question.id)}
                callback={reloadWindowPromise}
                buttonProps={{
                  text: {
                    normal: "Unarchive",
                  },
                  type: "info",
                  extraClassNames: "btn-sm",
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

interface QuestionMergeProps extends QuestionAdminOptionsProps {
  questionToMergeInto: Question;
}

export function QuestionMerge(props: QuestionMergeProps) {
  return (
    <div className="QuestionMerge">
      <SearchQuestionView
        liu={props.liu}
        ctaBtDl={(question: Question, callerContext: CallerContext) => {
          return `Pick to merge`;
        }}
        callback={function (
          questionToMerge: Question,
          callerContext: CallerContext
        ): void {
          QuestionsAPI.mergeQuestion(
            props.questionToMergeInto.id,
            questionToMerge.id
          )
            .executeV2()
            .then((updatedQuestion) => {
              message.success("Merged");
              return reloadWindowPromise();
            });
        }}
        callerContext={{}}
      />
    </div>
  );
}
