import React from "react";
import { Question } from "./Question.Model";
import { ArchivedBadge } from "../badge/Archived.Badge";
import { PendingApprovalBadge } from "../badge/PendingApproval.Badge";
import { AnswerSubmissionStatusBadge } from "./AnswerSubmissionStatus.Badge";
import { DifficultyBadge } from "./Difficulty.badge";
import { User } from "../user/User";
import { CompaniesBadgeList } from "../badge/Companies.BadgeList";
import { TopicBadge } from "../topic/Topic.Badge";

interface QuestionBagdesViewProps {
  question: Question;
  liu?: User;
  showDifficulty?: boolean;
  showApproval?: boolean;
}
export function QuestionBadgesViewTop(props: QuestionBagdesViewProps) {
  let question = props.question;
  return (
    <div className="QuestionBadgesViewTop hstack gap-2">
      {props.showDifficulty && (
        <DifficultyBadge difficulty={question.difficulty} />
      )}

      {question.answerStatus && (
        <AnswerSubmissionStatusBadge status={question.answerStatus} />
      )}

      {props.showApproval && question.canUserEdit && !question.approvedAt && (
        <PendingApprovalBadge />
      )}

      {props.liu?.basicDetails.isAdmin && question.archivedAt && (
        <ArchivedBadge />
      )}
    </div>
  );
}

export function QuestionBagdesViewBottom(props: QuestionBagdesViewProps) {
  return (
    <span className="QuestionBagdesView">
      {props.question.topics && (
        <span className="mob-pt-1">
          {props.question.topics.map((topic, index) => (
            <TopicBadge topic={topic} key={index} />
          ))}
        </span>
      )}

      {props.question.companies && (
        <span className="mob-pt-1">
          <CompaniesBadgeList companies={props.question.companies} />
        </span>
      )}
    </span>
  );
}
