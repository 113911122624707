import React, { useState } from "react";
import { TimestampSlot } from "../../calendar/Calendar.model";
import { UserAvailabilityDateTimeSlotsComponent } from "../../calendar/UserAvailabilityDateTimeSlots.component";
import { Price } from "../../model/Price";
import {
  OfferingOptionDurationData,
  OfferingOptionDurationSkillData,
  OfferingOptionType,
  OfferingVideoSession,
} from "../Offering";
import { OfferingOptionsDurationComponent } from "../OfferingOptionsDuration.component";
import { OfferingOptionsDurationSkillsComponent } from "../OfferingOptionsDurationSkills.component";

interface OfferingVideoSessionPreBookingFormElementsProps {
  offering: OfferingVideoSession;
  onPriceFinalization: (price: Price) => void;
  onMandatoryOptionsSelection: (selected: boolean) => void;
}

export function OfferingVideoSessionPreBookingFormElements(
  props: OfferingVideoSessionPreBookingFormElementsProps
) {
  let [sessionDurationInMinutes, setSessionDurationInMinutes] =
    useState<number>();

  let [timeSlot, setTimeSlot] = useState<TimestampSlot>();

  function onOptionSelect(
    optionData: OfferingOptionDurationSkillData | OfferingOptionDurationData
  ) {
    props.onPriceFinalization(optionData.price);
    setSessionDurationInMinutes(optionData.durationInSeconds / 60);

    if (timeSlot) {
      props.onMandatoryOptionsSelection(true);
    }
  }

  function onTimeSlotChange(slot?: TimestampSlot) {
    setTimeSlot(slot);
    if (slot && sessionDurationInMinutes) {
      props.onMandatoryOptionsSelection(true);
    } else {
      props.onMandatoryOptionsSelection(false);
    }
  }

  return (
    <div className="OfferingVideoSessionPreBookingFormElements">
      <div className="row">
        <div className="col-md-12">
          {props.offering.commonDetails.optionsType ===
            OfferingOptionType.DURATION_SKILL && (
            <OfferingOptionsDurationSkillsComponent
              options={props.offering.commonDetails.options}
              offering={props.offering}
              name={"optionsData"}
              onOptionSelect={onOptionSelect}
            />
          )}
          {props.offering.commonDetails.optionsType ===
            OfferingOptionType.DURATION && (
            <OfferingOptionsDurationComponent
              options={props.offering.commonDetails.options}
              offering={props.offering}
              name={"optionsData"}
              onOptionSelect={onOptionSelect}
            />
          )}
        </div>
        <div className="col-md-12 mt-3">
          {sessionDurationInMinutes && (
            <UserAvailabilityDateTimeSlotsComponent
              userIdToShow={props.offering.commonDetails.postedBy.id}
              durationMins={sessionDurationInMinutes}
              onTimeSlotChange={onTimeSlotChange}
              name={"timeSlotData"}
            />
          )}
          {!sessionDurationInMinutes && <></>}
        </div>
      </div>
    </div>
  );
}
