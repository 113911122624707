import React from "react";
import { HTMLProps } from "../../model/HTMLProps";

export function ArrowDownIcon(props: HTMLProps) {
  return (
    <>
      <i className={`fa-solid fa-arrow-down ${props.className}`}></i>
    </>
  );
}
