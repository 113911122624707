import React from "react";
import { CompanyAPI } from "./CompanyAPI";
import { Company } from "./Company";
import {
  EBSelectOption,
  EBSelectOptionDataType,
  EBSelectV2,
} from "../components/common/Form/EBSelectV2";
import { SelectorProps } from "../components/common/Selectors";

interface CompanySelectorProps extends SelectorProps {
  companies: Company[];
}

export interface CompanySelectorOption
  extends EBSelectOptionDataType,
    Company {}

export function CompanySelector(props: CompanySelectorProps) {
  function onCreate(
    companyName: string
  ): Promise<EBSelectOption<CompanySelectorOption>> {
    return CompanyAPI.addCompany({ dl: companyName } as Company).then(
      (company) => {
        return {
          label: company.dl,
          value: `${company.id}`,
          data: {
            ...company,
            selectOptionValue: `${company.id}`,
          },
        };
      }
    );
  }
  let options: EBSelectOption<CompanySelectorOption>[] = [];

  props.companies.forEach((company: Company) => {
    options.push({
      label: company.dl,
      value: `${company.id}`,
      data: {
        ...company,
        selectOptionValue: `${company.id}`,
      },
    });
  });

  return (
    <div className="row">
      <div className="col">
        {options && options.length > 0 && (
          <EBSelectV2
            label={props.label}
            name={props.name}
            required={props.required}
            options={options}
            disabled={props.disabled}
            isMulti={props.isMulti}
            onCreate={props.allowCreate ? onCreate : undefined}
            placeholder={props.placeholder}
            isDataEqual={(
              d1: CompanySelectorOption,
              d2: CompanySelectorOption
            ) => {
              return d1.id === d2.id;
            }}
          />
        )}
      </div>
    </div>
  );
}
