import React, { useState } from "react";
import { useAlert } from "react-alert";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { Loader } from "../components/common/Loader.component";
import { apiLinkConstants } from "../constants";
import { ApiErrorHelper } from "../helpers/ApiError.helper";
import { WindowHelpers } from "../helpers/Window.helper";
import { ImageGeneratorResponse } from "../image/Image.model";
import { AssetLocation } from "../model/Picture";
import { FileUploadComponent } from "../upload/FileUpload.component";
import { User, UserBasicDetails } from "./User";

interface UserImageEditProps {
  user: UserBasicDetails;
}

export function UserImageEdit(props: UserImageEditProps) {
  let [updatingImage, setUpdatingImage] = useState<boolean>();
  let alert = useAlert();
  function onImageUpload(image: AssetLocation): Promise<void> {
    setUpdatingImage(true);

    let api = "/api/user/{userId}/dp".replaceAll("{userId}", props.user.id);
    return new Api<ImageGeneratorResponse, User>(api, ApiMethod.PUT)
      .execute({ image: image })
      .then(() => {
        alert.success("Updated!");
        WindowHelpers.reload();
        return Promise.resolve();
      })
      .catch((error) => {
        let displayErr = ApiErrorHelper.getDisplayErrorMessage(error);
        alert.error(displayErr);
      })
      .finally(() => {
        setUpdatingImage(false);
      });
  }
  return (
    <div className="hstack gap-2">
      <span className="">
        {updatingImage && (
          <>
            <small>
              <Loader />
            </small>{" "}
            Updating. Please wait...
          </>
        )}
        {!updatingImage && (
          <FileUploadComponent
            onImageUpload={onImageUpload}
            api={apiLinkConstants.API_DP_UPLOAD_BY_USER.replaceAll(
              "{userId}",
              props.user.id
            )}
          />
        )}
      </span>
    </div>
  );
}
