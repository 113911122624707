import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { EBTextAreaV2 } from "../components/common/Form/FormElements";
import { Tab, TabView } from "../components/common/Tab.view";
import {
  EBTextInput,
  FormInputLabel,
} from "../components/common/eb-ui-components";
import { FormikHiddenInput } from "../components/common/input/FormikHiddentInput";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { Venue, VenueTypeEnum } from "../model/Venue.model";

export function VenueFormikInput(props: FormikInputParams) {
  let [field] = useField(props);
  let venue: Venue = field.value;
  let tabs: Tab[] = [
    {
      title: "Venue",
      content: (
        <>
          <FormikHiddenInput
            value={VenueTypeEnum.PHYSICAL_LOCATION}
            name={`${props.name}.venueType`}
          />
          <EBTextAreaV2
            label={"Venue Location"}
            name={`${props.name}.venueData`}
            required={props.required}
          ></EBTextAreaV2>
        </>
      ),
      uniqueKey: "venue",
      isActive: true,
    },
    {
      title: "Online Event",
      content: (
        <>
          <FormikHiddenInput
            value={VenueTypeEnum.ONLINE}
            name={`${props.name}.venueType`}
          />
          <EBTextInput
            label={"URL"}
            name={`${props.name}.venueData`}
            helpText={undefined}
            required={props.required}
          ></EBTextInput>
        </>
      ),
      uniqueKey: "online-event",
      isActive: true,
    },
  ];
  let [selectedtab, setSelectedTab] = useState<Tab | undefined>(tabs[0]);
  useEffect(() => {
    if (venue && venue.venueType && venue.venueType == VenueTypeEnum.ONLINE) {
      setSelectedTab(tabs[1]);
    }
  }, [venue]);
  return (
    <div className="EventLocationComponent">
      <FormInputLabel label="Location" required="true"></FormInputLabel>
      <div>{<TabView tabs={tabs} selectedTab={selectedtab} />}</div>
    </div>
  );
}
