import { Price } from "../../model/Price";
import { UserBasicDetails } from "../../user/User";

export enum WithdrawalStatus {
  PENDING = "PENDING",
  PAID = "PAID",
}

export enum PaymentDestinationType {
  UPI = "UPI",
  GPAY = "GPAY",
}

export interface PaymentDestination {
  type: PaymentDestinationType;
  data: string;
}

export interface Withdrawal {
  id: string;
  user?: UserBasicDetails;
  paymentAmount: Price;
  paymentDate?: Date;
  otherDetails?: string;
  status?: WithdrawalStatus;
  createdAt: string;
  paymentDestination?: PaymentDestination;
}

export interface WithdrawalSummary {
  total: Price;
  paid: Price;
  pending: Price;
}

export interface WithdrawalResponse {
  withdrawals: Withdrawal[];
  summaries: WithdrawalSummary[];
}
