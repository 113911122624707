import { Card, Col, Row } from "antd";
import React from "react";
import { EngineName } from "../Global.Names";
import { ImageComponent } from "../components/common/Image.component";
import { WindowHelpers } from "../helpers/Window.helper";
import { AssetLocation, LocationType } from "../model/Picture";
import { HomePageSection, HomePageSectionProps } from "./HomePage.Section";

interface Step {
  id: string;
  dl: React.ReactNode;
  dd: React.ReactNode;
  dp: AssetLocation;
}

export function HomePageStepsSection(props: HomePageSectionProps) {
  let items: Step[] = [
    {
      id: "1",
      dl: <>Discover Your {EngineName.SENTENCE_MID}</>,
      dd: (
        <>
          Browse through our diverse expert community. Filter by company,
          expertise, experience, etc.
        </>
      ),
      dp: {
        locationType: LocationType.URL,
        data: "https://eb-pb.s3.us-east-2.amazonaws.com/static/steps-1.jpg",
      },
    },
    {
      id: "2",
      dl: <>Engage & Learn</>,
      dd: (
        <>
          Choose from 1:1 discussions, live courses, webinars, and more to start
          your learning journey.
        </>
      ),
      dp: {
        locationType: LocationType.URL,
        data: "https://eb-pb.s3.us-east-2.amazonaws.com/static/steps-2.jpg",
      },
    },
    {
      id: "3",
      dl: <>Achieve your goal </>,
      dd: (
        <>
          Apply what you learn. Track your progress with personalized roadmaps
          and continuous mentor support.
        </>
      ),
      dp: {
        locationType: LocationType.URL,
        data: "https://eb-pb.s3.us-east-2.amazonaws.com/static/step-3.jpg",
      },
    },
  ];

  return (
    <HomePageSection
      className="HomePageStepsSection bg-dark text-primary"
      id="hp-step"
      headingStartIcon={<i className="fa-solid fa-bullseye"></i>}
      heading={<>How It Works (Three-Step Process)</>}
    >
      <div className="container">
        <Row gutter={[30, 30]}>
          {items.map((item, index) => (
            <Col
              className={`text-center`}
              key={index}
              span={WindowHelpers.isMobile() ? 24 : 24 / items.length}
            >
              <Card className="rounded-5 shadow">
                <div className="badge badge-secondary bg-light text-dark rounded-pill mb-3 border p-2 px-3">
                  Step {index + 1}
                </div>
                <h4>{item.dl}</h4>
                <div className="mt-4">
                  <ImageComponent
                    image={item.dp}
                    width={"200px"}
                    maxWidth={"100%"}
                  />
                </div>
                <div className="text-secondary mt-4">{item.dd}</div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </HomePageSection>
  );
}
