import React from "react";
import { publicLinkConstants } from "../constants";

export function EmptyBookingsComponent() {
  return (
    <div className="text-center">
      <div className="display-1">
        <i className="fa-solid fa-bullhorn"></i>
      </div>
      <div>
        No bookings found for you. You can share your page with your network to
        get more bookings.
        <div className="mt-4">
          <a
            href={publicLinkConstants.EXPERT_STUDIO_SHARE}
            className="btn btn-primary "
          >
            Share Your Page Now!
          </a>
        </div>
      </div>
    </div>
  );
}
