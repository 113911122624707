import { CalendarSettings, SchedulingPreference } from "./Calendar.model";

export class CalendarHelper {
  public static setDefaultsIfNotSet(calendarSettings: CalendarSettings): void {
    if (!calendarSettings.schedulingPreference) {
      calendarSettings.schedulingPreference = SchedulingPreference.MANUAL;
    }

    if (!calendarSettings.schedule) {
      calendarSettings.schedule = {};
    }

    if (!calendarSettings.unavailabilitySettings) {
      calendarSettings.unavailabilitySettings = { unavailableDurations: [] };
    }

    let schedule = calendarSettings.schedule;

    if (!schedule.monday) {
      schedule.monday = { timeSlots: [] };
    }

    if (!schedule.tuesday) {
      schedule.tuesday = { timeSlots: [] };
    }

    if (!schedule.wednesday) {
      schedule.wednesday = { timeSlots: [] };
    }

    if (!schedule.thursday) {
      schedule.thursday = { timeSlots: [] };
    }

    if (!schedule.friday) {
      schedule.friday = { timeSlots: [] };
    }

    if (!schedule.saturday) {
      schedule.saturday = { timeSlots: [] };
    }

    if (!schedule.sunday) {
      schedule.sunday = { timeSlots: [] };
    }
  }
}
