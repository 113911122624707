import React, { useEffect, useState } from "react";
import { OrderResponseV1 } from "../model/OrderDetails";
import { OrderAPI } from "../api/OrderAPI";
import { PageProps } from "../model/views/PageProps";
import { OrderListComponent } from "./OrderList.component";

export interface UserOrdersPageContentProps extends PageProps {}

export function UserOrdersPageContent(props: UserOrdersPageContentProps) {
  let [orders, setOrders] = useState<OrderResponseV1[]>();

  useEffect(() => {
    if (props.user) {
      OrderAPI.fetchOrders(props.user.basicDetails.id).then((orders) => {
        setOrders(orders);
      });
    }
  }, [props.user]);

  return (
    <div className="main-container">
      {props.user && orders && (
        <>
          <div>
            <h2>Your orders</h2>
            <OrderListComponent orders={orders} />
          </div>
        </>
      )}
    </div>
  );
}
