import { Card, Col, Row, Typography } from "antd";
import React from "react";
import { BogieName } from "../../Global.Names";
import { ImageComponent } from "../../components/common/Image.component";
import { HTMLProps } from "../../model/HTMLProps";
import { AssetLocation, LocationType } from "../../model/Picture";
import { UserSummary } from "../User";
import "./EngineSummary.Card.css";

const { Text, Link } = Typography;

interface EngineSummaryCardProps extends HTMLProps {
  es: UserSummary;
}

export function EngineSummaryCard(props: EngineSummaryCardProps) {
  let es = props.es;
  return (
    <Card className={`border EngineSummaryCard ${props.className}`}>
      <h5 className="mb-2">Statistics</h5>

      <Row gutter={15}>
        {es.totalMentoringDurationInSeconds !== undefined &&
          es.totalMentoringDurationInSeconds > 0 && (
            <EngineSummaryCardItem
              image={{
                locationType: LocationType.FA_CLASS,
                data: "fas fa-clock text-info border border-info",
              }}
              dv={es.totalMentoringDurationDl?.toLocaleString()}
              dl="Mentoring time"
              className="mt-2"
            />
          )}

        {es.numBookings !== undefined && es.numBookings > 0 && (
          <EngineSummaryCardItem
            image={{
              locationType: LocationType.FA_CLASS,
              data: "fas fa-rocket text-success border border-success",
            }}
            dv={es.numBookings}
            dl={es.numBookings > 1 ? "Bookings" : "Booking"}
            className="mt-3"
          />
        )}

        {es.numBogies !== undefined && es.numBogies > 0 && (
          <EngineSummaryCardItem
            image={{
              locationType: LocationType.FA_CLASS,
              data: "fas fa-handshake-angle border border-danger text-danger ",
            }}
            dv={es.numBogies}
            dl={`${
              es.numBogies > 1
                ? BogieName.SENTENCE_START_PLURAL
                : BogieName.SENTENCE_START
            } helped`}
            className="mt-3"
          />
        )}

        {/* {es.rebookingPercent100 !== undefined &&
          es.rebookingPercent100 > 10 && (
            <EngineSummaryCardItem
              image={{
                locationType: LocationType.FA_CLASS,
                data: "fas fa-wand-magic-sparkles text-secondary border border-secondary",
              }}
              dv={es.rebookingPercentDl}
              dl={`Rebooking rate`}
              className="mt-3"
            />
          )} */}

        {es.ratingsSummary?.expertAvgRating && (
          <EngineSummaryCardItem
            image={{
              locationType: LocationType.FA_CLASS,
              data: "fas fa-star text-warning border-warning border",
            }}
            dv={es.ratingsSummary.dlAvgRating}
            dl={es.ratingsSummary.dlCount}
            className="mt-3"
          />
        )}
      </Row>
    </Card>
  );
}

interface EngineSummaryCardItemProps extends HTMLProps {
  image: AssetLocation;
  dv: React.ReactNode;
  dl: React.ReactNode;
}

export function EngineSummaryCardItem(props: EngineSummaryCardItemProps) {
  return (
    <Col span={24}>
      <div className={`EngineSummaryCardItem ${props.className}`}>
        <Row gutter={14}>
          <Col>
            <ImageComponent
              image={props.image}
              width="32px"
              height={"32px"}
              className="p-2 rounded-3"
            />
          </Col>
          <Col>
            <div className="dv bold">{props.dv}</div>

            <div className="text-muted dl">{props.dl}</div>
          </Col>
        </Row>
      </div>
    </Col>
  );
}

export function EngineSummaryHomePageCardItem(
  props: EngineSummaryCardItemProps
) {
  return (
    <Col>
      <div className={`EngineSummaryHomePageCardItem ${props.className}`}>
        <Row gutter={7}>
          <Col>
            <ImageComponent image={props.image} width="18px" height={"18px"} />
          </Col>
          <Col>
            <div className="dv bold">{props.dv}</div>
          </Col>
        </Row>
      </div>
    </Col>
  );
}

export function EngineSummaryHomePageCard(props: EngineSummaryCardProps) {
  let es = props.es;
  return (
    <div className={`EngineSummaryHomePageCard ${props.className}`}>
      <Row gutter={15} align={"middle"}>
        {es.ratingsSummary?.expertAvgRating && (
          <EngineSummaryHomePageCardItem
            image={{
              locationType: LocationType.FA_CLASS,
              data: "fas fa-star text-warning",
            }}
            dv={es.ratingsSummary.dlAvgRating}
            dl={es.ratingsSummary.dlCount}
          />
        )}

        {es.totalMentoringDurationInSeconds !== undefined &&
          es.totalMentoringDurationInSeconds > 0 && (
            <EngineSummaryHomePageCardItem
              image={{
                locationType: LocationType.FA_CLASS,
                data: "fas fa-clock text-info",
              }}
              dv={es.totalMentoringDurationDl?.toLocaleString()}
              dl="Mentoring time"
            />
          )}
      </Row>
    </div>
  );
}
