import React from "react";
import { ImageComponent } from "../components/common/Image.component";
import {
  SessionDetailsComponent,
  VideoSessionBookingHeaderActions,
} from "../components/common/expert-session/SessionDetails.component";
import { reloadWindowPromise } from "../components/common/reloadCallback";
import { DigitalProductDetails } from "../digital-product/DigitalProduct.PageContent";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { TextQueryDetails } from "../text-queries/TextQueryDetails.PageContent";
import { User } from "../user/User";
import { BookingCancelButton } from "../video-sessions/Booking.Cancel.Button";
import { BookingEngineMarkCompleteButton } from "./Booking.EngineMarkComplete.Button";
import { Booking } from "./Booking.model";

interface BookingComponentProps {
  booking: Booking;
  user: User;
}
export function BookingComponent(props: BookingComponentProps) {
  let booking = props.booking;
  return (
    <div className="BookingComponent">
      <div className="row">
        <div className="col-md-9">
          <h1 className="h4">
            {booking.bookingTarget.dp && (
              <ImageComponent
                image={booking.bookingTarget.dp}
                className="mr-2 text-secondary"
              />
            )}
            {booking.bcd.dl}
          </h1>
        </div>
        <div className="col-md-3 hstack gap-2 justify-content-end">
          {booking.bcd.shouldShowEngineCompletionButton && (
            <BookingEngineMarkCompleteButton
              booking={booking}
              className="btn-sm mb-2"
            />
          )}

          {booking.canCancel === true && (
            <BookingCancelButton
              notifyParticipants={false}
              bookingId={booking.id!}
              buttonProps={{
                className: "btn-sm btn-light mb-2",
                content: { normal: "Cancel", submitting: "Cancelling" },
              }}
              onSessionUpdate={reloadWindowPromise}
            />
          )}

          {booking.bookingTarget.targetType ==
            AttachmentTargetType.VIDEO_SESSION && (
            <VideoSessionBookingHeaderActions
              booking={booking}
              user={props.user}
            />
          )}
        </div>
      </div>

      {booking.bookingTarget.targetType ==
        AttachmentTargetType.VIDEO_SESSION && (
        <SessionDetailsComponent booking={booking} user={props.user} />
      )}

      {booking.bookingTarget.targetType == AttachmentTargetType.TEXT_QUERY && (
        <TextQueryDetails booking={booking} user={props.user} />
      )}

      {booking.bookingTarget.targetType ==
        AttachmentTargetType.DIGITAL_PRODUCT && (
        <DigitalProductDetails booking={booking} user={props.user} />
      )}
    </div>
  );
}
