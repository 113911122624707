import { Form, Formik } from "formik";
import React from "react";
import { InterviewRoundAPI } from "../../../api/InterviewRoundAPI";
import { CallerContext } from "../CallerContext";
import { EBTextInput } from "../eb-ui-components";

interface AddInterviewRoundView {
    callback: ((callerContext: CallerContext) => void);
    companyId: string;
    interviewLoopId: string;
}

function CreateInterviewRoundForm(props: AddInterviewRoundView) {
    return (
        <Formik initialValues={{}} 
        onSubmit={(values, actions) => {
            actions.setSubmitting(true);

            InterviewRoundAPI.createInterviewRound(props.companyId, props.interviewLoopId, values).then(() => {
                actions.setSubmitting(false);
                props.callback({});
            }).catch((err) => {
                actions.setSubmitting(false);
            });
        }} > 
        {formProps => (
            <Form>

                <div className="row">
                    <div className="col">
                        <EBTextInput label="Round title" name="name" placeholder="Some title for round like 'Coding and Problem Solving'" 
                        helpText="" required />
                    </div>
                    <div className="col">
                        <EBTextInput label="Round number" name="number" placeholder="1" helpText="" required />
                    </div>
                    <div className="col">
                        <EBTextInput label="Duration of round (in minutes)" name="durationInMinutes" placeholder="Enter count of minutes. Ex: '60'" helpText="" required />
                    </div>
                </div>

                
                <button className="btn btn-primary" type="submit" 
                disabled={formProps.isSubmitting}>{formProps.isSubmitting ? "Creating. Please Wait..." : "Create Round"}</button>
            </Form>
        )}

        </Formik>
    )
}

export function AddInterviewRoundView(props: AddInterviewRoundView) {
    return (
        <div className="">
            <div className="">
                <button className="btn btn-primary mr-2" type="button" data-bs-toggle="collapse" data-bs-target="#addNewRoundForm" 
                        aria-expanded="false" aria-controls="addNewRoundForm">Add new round for this candidate</button>
                
                <div className="collapse pt-2" id="addNewRoundForm">
                    <div className="row">
                        <div className="col">
                            <div className="card card-body">
                                <CreateInterviewRoundForm {...props} />
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    )
}
