import React from "react";
import { FormikInputParams } from "../../components/common/input/FormikInputParams.model";
import { HTMLProps } from "../../model/HTMLProps";
import { FormTemplateDataBuilder } from "./Form.TemplateData.Builder";

export interface FormTemplateBuilderProps
  extends HTMLProps,
    FormikInputParams {}

export function FormTemplateBuilderFormikInput(
  props: FormTemplateBuilderProps
) {
  return (
    <span className="FormTemplateBuilderFormikInput">
      <FormTemplateDataBuilder name={`${props.name}.templateData`} />
    </span>
  );
}
