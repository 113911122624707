import axios from "axios";
import { QuestionCategoryResponse } from "../model/response/QuestionCategory.response";

export class QuestionsCategoriesAPI {

    public static async getAllQuestionsCategories(): Promise<QuestionCategoryResponse[]> {
        return new Promise<QuestionCategoryResponse[]>((resolve, reject) => {
            axios.get("/api/questions-categories")
            .then(res => {
                const responseData = res.data;
                resolve(responseData);
            }).catch(error => {
                reject(error);
            });
        })
    }
}