import { Col, Row } from "antd";
import React from "react";
import { EngineName, PlatformDl } from "../../Global.Names";
import { publicLinkConstants, socialLinks } from "../../constants";
import { WindowHelpers } from "../../helpers/Window.helper";
import { PageProps } from "../../model/views/PageProps";

interface PublicFooterProps extends PageProps {
  showOnMobile?: boolean;
}

export function PublicFooter(props: PublicFooterProps) {
  return (
    <footer
      className={`footer py-4 ${!props.showOnMobile && `event-data-desktop`}`}
    >
      <div className="main-container">
        <Row>
          <Col md={10} className="px-5">
            Your trusted & world's most premium platform that enables
            peer-to-peer connections. It is a platform connecting learners
            withexperts across various fields for 1:1 mentorship sessions, mock
            interviews, courses, and much more.
            <div className="mt-3 mb-4">
              <a
                className="btn btn-light btn-social mx-2 text-dark"
                href={socialLinks.TWITTER}
                target="_blank"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                className="btn btn-light btn-social mx-2 text-dark"
                href={socialLinks.FACEBOOK}
                target="_blank"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn btn-light btn-social mx-2 text-dark"
                href={socialLinks.LINKEDIN}
                target="_blank"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
            Copyright © {PlatformDl.SENTENCE_START} 2024
          </Col>
          <Col md={5} className="px-5 mob-mt-4">
            <div className="text-uppercase mb-2">Platform</div>
            <div className="">
              <a href={publicLinkConstants.FIND_EXPERTS} className="">
                Find an {EngineName.SENTENCE_MID}
              </a>
              <br />
              <a href={publicLinkConstants.BECOME_EXPERT} className="">
                Become an {EngineName.SENTENCE_MID}
              </a>
              <br />
              <a href={publicLinkConstants.CHARCHAA} className="">
                Charchaa
              </a>
              <br />
              <a href={publicLinkConstants.QUESTIONS} className="">
                Problems
              </a>
              <br />
              <a href={publicLinkConstants.INTERVIEW_EXPERIENCES} className="">
                Interview experiences
              </a>
            </div>
          </Col>
          <Col md={5} className="px-5 mob-mt-4">
            <div className="text-uppercase mb-2">Company</div>
            <div className="">
              <a href={publicLinkConstants.PRIVACY_POLICY_LINK}>
                Privacy policy
              </a>
              <br />
              <a href={publicLinkConstants.TERMS_CONDITIONS_LINK}>
                Terms &amp; conditions
              </a>
              <br />
              <a href={publicLinkConstants.CANCELLATION_REFUNDS_LINK}>
                Cancellation &amp; refunds
              </a>
              <br />
              <a href={publicLinkConstants.CREDITS}>Credits</a>
            </div>
          </Col>
          <Col md={4} className="px-5 mob-mt-4">
            <div className="text-uppercase mb-2">Support</div>
            <div className="">
              <a href={publicLinkConstants.HELP_LINK}>Contact</a>
            </div>
          </Col>
        </Row>
      </div>

      <div
        className={`text-muted ${
          WindowHelpers.isMobile() ? "fs-20" : "fs-30"
        } mt-4 mb-4 text-center text-break`}
      >
        #LearnTogetherGrowTogether{" "}
        <div className="fs-100">
          <i className="fa-solid fa-people-pulling"></i>
        </div>
      </div>

      {/* Modals start */}
      <div
        className="modal fade text-dark"
        id="logoutModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Are you sure?
              </h5>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-justify">
              Select "Logout" below if you are ready to end your current
              session.
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                type="button"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <a
                className="btn btn-secondary"
                href={publicLinkConstants.LOGOUT}
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Modals end */}
    </footer>
  );
}
