import React from "react";
import {
  EBSelectOption,
  EBSelectOptionDataType,
  EBSelectV2,
} from "../../components/common/Form/EBSelectV2";
import { SelectorProps } from "../../components/common/Selectors";
import { FormTemplateLineItemType } from "../model/formTemplate.model";

interface FormSectionLineItemTypeSelectorProps extends SelectorProps {
  types: FormTemplateLineItemType[];
  isMulti?: boolean;
}

export interface FormTemplateLineItemTypeSelectorOption
  extends EBSelectOptionDataType,
    FormTemplateLineItemType {}

export function FormSectionLineItemTypeSelector(
  props: FormSectionLineItemTypeSelectorProps
) {
  let options: EBSelectOption<FormTemplateLineItemTypeSelectorOption>[] = [];

  props.types.forEach((type: FormTemplateLineItemType) => {
    options.push({
      label: type.dl,
      value: `${type.id}`,
      data: {
        ...type,
        selectOptionValue: `${type.id}`,
      },
    });
  });

  return (
    <div className="row">
      <div className="col">
        {options && options.length > 0 && (
          <EBSelectV2
            label={props.label}
            name={props.name}
            required={props.required}
            options={options}
            disabled={props.disabled}
            isMulti={props.isMulti}
            isDataEqual={(
              d1: FormTemplateLineItemTypeSelectorOption,
              d2: FormTemplateLineItemTypeSelectorOption
            ) => {
              return d1.id === d2.id;
            }}
          />
        )}
      </div>
    </div>
  );
}
