import { Col, Row } from "antd";
import React from "react";
import { EngineName } from "../Global.Names";
import { ImageComponent } from "../components/common/Image.component";
import { AssetLocation, LocationType } from "../model/Picture";
import { HomePageSection, HomePageSectionProps } from "./HomePage.Section";

export function HomePageExpertCompaniesSection(props: HomePageSectionProps) {
  let images: AssetLocation[] = [
    {
      locationType: LocationType.URL,
      data: "/img/logos/amazon-thumbnail.jpg",
    },
    {
      locationType: LocationType.URL,
      data: "/img/logos/microsoft-thumbnail.jpg",
    },
    {
      locationType: LocationType.URL,
      data: "/img/logos/adobe-thumbnail.jpg",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-meta.png",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-hotstar.png",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-google.png",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-flipkart.png",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-coinbase.png",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-linkedin.webp",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-mmt.svg",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-myntra.png",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-oracle.png",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-paypal.webp",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-salesforce.png",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-twilio.png",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-uber.png",
    },
    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-walmart.png",
    },

    {
      locationType: LocationType.URL,
      data: "https://eb-pb.s3.us-east-2.amazonaws.com/logo-atlassian.png",
    },
  ];
  return (
    <HomePageSection
      id="expert-companies"
      heading={
        <>
          Our {EngineName.SENTENCE_MID_PLURAL} are Industry Leaders from Top
          Companies
        </>
      }
      subheading={
        <>
          Learn from the Best: Our {EngineName.SENTENCE_START_PLURAL} are from
          World-Renowned Companies
        </>
      }
    >
      <div className="px-150 mob-px-2">
        <Row justify={"center"} gutter={[30, 30]}>
          {images.map((image, index) => (
            <Col className="p-4" key={index}>
              <ImageComponent
                image={image}
                className="img-fluid d-block mx-auto hp-img-comp"
              />
            </Col>
          ))}
        </Row>
        <div className="text-center text-muted mt-5 pt-5">
          & 200+ other companies{" "}
        </div>
      </div>
    </HomePageSection>
  );
}
