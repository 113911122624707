import React, { useState } from "react";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { TextInputV3 } from "../components/common/input/TextV3.Input";
import { SearchTokenHelper } from "../helpers/Search.helper";
import { SearchToken } from "../model/search/SearchToken";
import { SearchProps } from "./Search.Model";

export function QuerySearch(props: SearchProps) {
  let [searchQuery, setSearchQuery] = useState<string>();

  function onSearch() {
    let searchTokens: SearchToken[] = [];
    if (searchQuery) {
      searchTokens.push(SearchTokenHelper.getSearchStringToken(searchQuery));
    }
    props.onSearch(searchTokens);
  }

  return (
    <>
      <TextInputV3
        placeholder="Enter search query here"
        onChange={(str: string) => {
          setSearchQuery(str);
        }}
        onPressEnter={() => {
          onSearch();
        }}
      />
      <EBButtonV2
        className="mt-2"
        content={{ normal: "Search" }}
        onClick={() => {
          onSearch();
        }}
      />
    </>
  );
}
