import React, { useEffect, useState } from "react";
import { PageProps } from "../../model/views/PageProps";
import { Question } from "../../question/Question.Model";
import { QuestionsAPI } from "../../question/QuestionsAPI";
import { AdminQuestionsListWrapper } from "./AdminQuestionsListWrapper";

interface AdminQuestionsContentProps extends PageProps {}

export function AdminQuestionsContent(props: AdminQuestionsContentProps) {
  let [questions, setQuestions] = useState<Question[]>([]);
  useEffect(() => {
    QuestionsAPI.getAllQuestions().then((questionSearchResponse) => {
      setQuestions(questionSearchResponse.questions);
    });
  }, []);

  return (
    <div className="main-container">
      <div>
        <AdminQuestionsListWrapper questions={questions} />
      </div>
    </div>
  );
}
