export interface QueryParam {
  key: string;
  value: any;
}

export class LinkHelpers {
  public static Q_PARAM_LIST_DELIMETER = ",";

  public static addQueryParams(url: string, qps: QueryParam[]): string {
    if (qps.length === 0) {
      return url;
    }
    for (let qp of qps) {
      url = this.addQueryParam(url, qp);
    }

    return url;
  }

  public static addQueryParam(url: string, qp?: QueryParam): string {
    if (!qp) {
      return url;
    }
    if (url.indexOf("?") == -1) {
      return url + "?" + qp.key + "=" + qp.value;
    }
    return url + "&" + qp.key + "=" + qp.value;
  }
}
