import React from "react";
import { Price } from "../../model/Price";
import {
  OfferingOptionSlaData,
  OfferingOptionType,
  OfferingTextQuery,
} from "../Offering";
import { OfferingOptionsSlaComponent } from "../OfferingOptionsSla.component";

interface OfferingTextQueryPreBookingFormElementsProps {
  offering: OfferingTextQuery;
  onPriceFinalization: (price: Price) => void;
  onMandatoryOptionsSelection: (selected: boolean) => void;
}

export function OfferingTextQueryPreBookingFormElements(
  props: OfferingTextQueryPreBookingFormElementsProps
) {
  function onOptionSelect(optionData: OfferingOptionSlaData) {
    props.onPriceFinalization(optionData.price);
    props.onMandatoryOptionsSelection(true);
  }

  return (
    <div className="OfferingTextQueryPreBookingFormElements">
      {props.offering.commonDetails.optionsType === OfferingOptionType.SLA && (
        <OfferingOptionsSlaComponent
          options={props.offering.commonDetails.options}
          offering={props.offering}
          name={"optionsData"}
          onOptionSelect={onOptionSelect}
        />
      )}
    </div>
  );
}
