import React from "react";
import { publicLinkConstants } from "../constants";
import { EventDashboardContent } from "../events/EventDashboardContent";
import { EventLandingPageContent } from "../events/EventLandingPageContent";
import { PublicPageRoute } from "./PublicPage.route";

export function EventRoutes() {
  return (
    <>
      <PublicPageRoute
        exact
        path={publicLinkConstants.EVENT_LANDING_PAGE_LINK}
        contentComponent={EventLandingPageContent}
      />
      <PublicPageRoute
        exact
        path={publicLinkConstants.EVENT_DASHBOARD_LINK}
        contentComponent={EventDashboardContent}
      />
    </>
  );
}
