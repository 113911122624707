import { Field, Formik } from "formik";
import moment from "moment";
import React from "react";
import { useAlert } from "react-alert";
import Moment from "react-moment";
import { Booking } from "../../../booking/Booking.model";
import { SchedulingPreference } from "../../../calendar/Calendar.model";
import { apiLinkConstants } from "../../../constants";
import VideoSession from "../../../model/ExpertSession";
import { UserBasicDetails } from "../../../user/User";
import { Api, ApiMethod } from "../ApiLinkedButton";
import { EBDateInput, EBSelect, EBTextInput } from "../eb-ui-components";
import { VideoSessionRescheduleComponent } from "./VideoSessionReschedule.component";
import { CopyClipboardButton } from "../CopyCliboard.button";
import { EBTextInputV2 } from "../input/TextLine.input";
import { Label } from "../Form/elements/FormInputLabel";
import { Link } from "../../../links/Link";
import { ExportOutlined } from "@ant-design/icons";

interface ScheduleProps {
  session: VideoSession;
  booking: Booking;
  user: UserBasicDetails;
}

interface UpdateSessionScheduleRequest {
  sessionScheduledAtDate: string;
  sessionScheduledAtTime: string;
  scheduleDateTime?: string;
  scheduleMeetingLink?: string;
}

function EditSchedule(props: ScheduleProps) {
  const alert = useAlert();
  let scheduledAtDate = "";
  let scheduledAtTime = "";
  if (props.session.scheduleDateTime) {
    scheduledAtDate = moment(props.session.scheduleDateTime).format(
      "YYYY-MM-DD"
    );
    scheduledAtTime = moment(props.session.scheduleDateTime).format("HH:mm");
  }

  let initialValues: UpdateSessionScheduleRequest = {
    sessionScheduledAtDate: scheduledAtDate,
    sessionScheduledAtTime: scheduledAtTime,
    scheduleMeetingLink: props.session.scheduleMeetingLink,
    scheduleDateTime: props.session.scheduleDateTime,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        var utcStart = moment(
          values.sessionScheduledAtDate + "T" + values.sessionScheduledAtTime,
          "YYYY-MM-DDTHH:mm"
        )
          .utc()
          .format();

        values.scheduleDateTime = utcStart;

        let updateScheduleApiLink =
          apiLinkConstants.API_VIDEO_SESSIONS_SINGLE_SCHEDULE_UPDATE.replace(
            "{sessionId}",
            props.session.id
          );
        new Api<UpdateSessionScheduleRequest, void>(
          updateScheduleApiLink,
          ApiMethod.PUT
        )
          .execute(values)
          .then(function () {
            alert.success("Details saved successfully!");
            setSubmitting(false);
          })
          .catch(function (error) {
            alert.error(
              "Error occurred! Make sure all required fields are filled."
            );
            setSubmitting(false);
          });
      }}
    >
      {({ values, isSubmitting, handleSubmit, handleChange }) => (
        <form onSubmit={handleSubmit}>
          <div className="card mt-3">
            <div className="card-body">
              <div className="row">
                <div className="col-md-10">
                  <h6 className="card-title font-weight-bold">
                    Update Schedule Details (Once you have decided the final
                    time and date for the session, please update it here.)
                  </h6>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Saving. Please Wait..." : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-3 col-md-4 col-xs-12">
                  <EBDateInput
                    helpText=""
                    label="Session Date:"
                    name="sessionScheduledAtDate"
                    placeholder=""
                    required
                  />
                </div>
                <div className="col-xl-2 col-md-3 col-xs-12">
                  <Field
                    label="Time:"
                    name="sessionScheduledAtTime"
                    component={EBSelect}
                    options={getTimeList(15)}
                    mappingRequired={true}
                    selectToApiMapper={mapTimeSelectToAPI}
                    apiToSelectMapper={mapTimeAPIToSelect}
                    required
                  />
                </div>
                <div className="col-xl-7 col-md-5 col-xs-12">
                  <Label label="Meeting Link:" required />
                  <EBTextInputV2
                    name="scheduleMeetingLink"
                    placeholder=""
                    required
                  />
                  {values.scheduleMeetingLink && (
                    <div className="mt-1">
                      <Link
                        className="mr-3"
                        href={values.scheduleMeetingLink}
                        tooltip="Click to open offering as a user"
                        target="_blank"
                      >
                        <ExportOutlined />
                      </Link>

                      <CopyClipboardButton
                        textToCopy={values.scheduleMeetingLink}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12 col-md-12 col-xs-12 custom-control custom-switch">
                  <Field
                    type="checkbox"
                    id="isSlotConfirmedFromMentee"
                    className="custom-control-input"
                    name="isSlotConfirmedFromMentee"
                    required
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="isSlotConfirmedFromMentee"
                  >
                    Have you confirmed slot with mentee? (Please finalize the
                    slot with your mentee on the email thread and then only
                    schedule it.)
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

function getTimeList(stepInMins: number): SelectFormat[] {
  let timeList: SelectFormat[] = [];
  for (var mins = 0; mins < 24 * 60; mins += stepInMins) {
    let curHour = Math.floor(mins / 60);
    let curMins = mins % 60;

    var strHour = "";
    if (curHour < 10) {
      strHour = "0" + curHour;
    } else {
      strHour = "" + curHour;
    }

    var strMins = "";
    if (curMins < 10) {
      strMins = "0" + curMins;
    } else {
      strMins = "" + curMins;
    }

    let time = strHour + ":" + strMins;
    timeList.push(mapTimeAPIToSelect(time));
  }
  return timeList;
}

interface SelectFormat {
  value: string;
  label: string;
}

function mapTimeSelectToAPI(time: SelectFormat): string {
  return time.value;
}

function mapTimeAPIToSelect(time?: string): SelectFormat {
  if (!time) {
    return { value: "", label: "" };
  }

  return { value: time, label: time };
}

function ViewSchedule(props: ScheduleProps) {
  return (
    <div className="row">
      <div className="col-xl-6 col-md-6">
        <div className="card border-left-warning shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  {props.booking.bcd.engineCompletedAt
                    ? "Happened at"
                    : "Scheduled at"}
                </div>
                <div className="h5 mb-0 font-weight-bold text-gray-800">
                  {props.session.scheduleDateTime ? (
                    <Moment format="DD-MM-YYYY HH:mm">
                      {props.session.scheduleDateTime}
                    </Moment>
                  ) : (
                    "Not scheduled."
                  )}
                </div>
              </div>
              <div className="col-auto">
                <i className="fas fa-clock fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-6 col-md-6">
        <div className="card border-left-warning shadow h-100 py-2">
          <div className="card-body">
            <div className="row no-gutters align-items-center">
              <div className="col mr-2">
                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  Meeting Link
                </div>
                <div className="h5 mb-0 font-weight-bold text-gray-800">
                  {props.session.scheduleMeetingLink ? (
                    <a href={props.session.scheduleMeetingLink} target="_blank">
                      Join Here
                    </a>
                  ) : (
                    "Not available yet."
                  )}
                </div>
              </div>
              <div className="col-auto">
                <i className="fas fa-video fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function SessionScheduleDetailsComponent(props: ScheduleProps) {
  let vs = props.session;
  let bkng = props.booking;
  return (
    <>
      {vs.schedulingPreference === SchedulingPreference.CALENDAR && (
        <>
          <VideoSessionRescheduleComponent session={vs} bcd={bkng.bcd} />
        </>
      )}

      {bkng.canUpdate ? (
        <EditSchedule {...props} />
      ) : (
        <ViewSchedule {...props} />
      )}
    </>
  );
}
