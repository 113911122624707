import React from "react";
import { EBButtonV2, EBButtonV2Props } from "./EBButtonV2";

interface EBModalV2Props {
  body: React.ReactNode;
  header?: React.ReactNode;
  id: string;
  modalSize?: "sm" | "md" | "lg" | "xl";
  primaryButtonProps?: EBButtonV2Props;
  closeButtonClass?: string;
  closeButtonId?: string;
  closeButtonTitle?: string;
}

export function EBModalV2(modalProps: EBModalV2Props) {
  let headerId = modalProps.id + "-header";
  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      id={modalProps.id}
      tabIndex={-1}
      aria-labelledby={headerId}
      aria-hidden="true"
    >
      <div
        className={
          "modal-dialog " +
          (modalProps.modalSize ? "modal-" + modalProps.modalSize : "modal-xl")
        }
      >
        <div className="modal-content">
          {modalProps && modalProps.header && (
            <div className="modal-header">
              <h5 className="modal-title" id={headerId}>
                {modalProps.header}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          )}

          <div className="modal-body">{modalProps.body}</div>
          <div className="modal-footer">
            <EBButtonV2
              className={`btn btn-secondary ${modalProps.closeButtonClass}`}
              onClick={(event) => {
                event.stopPropagation();
              }}
              otherProps={{ "data-bs-dismiss": "modal" }}
              id={modalProps.closeButtonId ?? modalProps.id + "-close-button"}
              content={{ normal: modalProps.closeButtonTitle ?? "Close" }}
            />

            {modalProps.primaryButtonProps && (
              // This modal calls callback
              <EBButtonV2
                {...modalProps.primaryButtonProps}
                onClick={(event) => {
                  if (modalProps.primaryButtonProps?.onClick) {
                    modalProps.primaryButtonProps.onClick(event);
                  }
                  event.stopPropagation();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
