import {
  InterviewExperience,
  InterviewExperienceResponse,
} from "../model/InterviewExperience";

export class InterviewExperienceMapper {
  public static mapResponseToModel(
    response: InterviewExperienceResponse
  ): InterviewExperience {
    return {
      ...response,
    };
  }
}
