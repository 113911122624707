import React from "react";
import { HTMLProps } from "../model/HTMLProps";

export interface ListItemLayoutProps extends HTMLProps {
  index: number;
  listLength: number;
  children: React.ReactNode;

  excludePadding?: boolean;
  selectedIndex?: number;
}

export function ListItemLayoutDefault(props: ListItemLayoutProps) {
  return (
    <div
      className={`ListItemLayoutDefault ${
        props.index != props.listLength - 1 && "border-bottom "
      } ${!props.excludePadding && " py-3 px-3 "}  list-group-item-action ${
        props.index == props.selectedIndex && " h6 mb-0 bg-gray-light"
      } ${props.className}`}
    >
      {props.children}
    </div>
  );
}

export function ListItemPlainLayout(props: ListItemLayoutProps) {
  return (
    <div className={`ListItemPlainLayout ${props.className}`}>
      {props.children}
    </div>
  );
}

export function ListItemLayoutCard(props: ListItemLayoutProps) {
  return (
    <div
      className={`ListItemLayoutCard card card-body rounded-5 list-group-item-action ${props.className}`}
    >
      {props.children}
    </div>
  );
}
