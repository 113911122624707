import { Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { AddTitle } from "../components/common/Add.title";
import { CopyClipboardButton } from "../components/common/CopyCliboard.button";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { Mode } from "../components/common/Form/model/mode.model";
import { Loader } from "../components/common/Loader.component";
import { Center } from "../components/common/layout/Center.component";
import { EmptyOfferingsMessage } from "../expert-studio/EmptyOfferings.Message";
import { EditIcon } from "../icon/Edit.Icon";
import { LinkIcon } from "../icon/Link.Icon";
import { Link } from "../links/Link";
import { LinkClickHandler } from "../links/Link.ClickHandler";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { FetchContext } from "../model/enums/FetchContext.enum";
import { CancelTitle } from "../title/Cancel.Title";
import { User, UserBasicDetails } from "../user/User";
import { IOfferingV2, OfferingTypeName } from "./Offering";
import { OfferingAddOrEditForm } from "./Offering.AddOrEdit.Form";
import { OfferingHelper } from "./Offering.Helper";
import { OfferingPublishStatusBadge } from "./Offering.PublishStatus.Badge";
import { OfferingPublishStatusToggleButton } from "./Offering.PublishStatus.ToggleButton";
import { OfferingAPI } from "./Offering.api";
import { ExportOutlined } from "@ant-design/icons";

interface OfferingsEditComponentProps {
  user: User;
  offeringType: OfferingTypeName;
  offeringTargetType: AttachmentTargetType;
  getOfferingSpecificFormikInputs?: (offering: IOfferingV2) => React.ReactNode;
  getAdditionalOfferingActions?: (offering: IOfferingV2) => React.ReactNode;

  titleSideNode?: React.ReactNode;
  postTitleNode?: React.ReactNode;
}

export function OfferingsEditComponent(props: OfferingsEditComponentProps) {
  let [loading, setLoading] = useState<boolean>();
  let [offerings, setOfferings] = useState<IOfferingV2[]>();

  function fetchOfferings() {
    setLoading(true);
    OfferingAPI.list(
      props.user.basicDetails.id,
      FetchContext.EDIT,
      props.offeringTargetType
    )
      .executeV2()
      .then((offerings) => {
        setOfferings(offerings);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchOfferings();
  }, []);

  return (
    <div className="OfferingsPathsEditExpertStudioContent">
      {loading && (
        <>
          <Center>
            <Loader />
          </Center>
        </>
      )}
      {!loading && offerings && (
        <div>
          {offerings.length == 0 && (
            <div className="mb-2">
              <EmptyOfferingsMessage />
            </div>
          )}
          <div className="mb-3">
            <AddOfferingComponent
              user={props.user}
              onAdd={(offering) => {
                return OfferingAPI.add(props.offeringTargetType)
                  .executeV2(offering)
                  .then((addedOffering) => {
                    message.success(
                      `Offering ${addedOffering.commonDetails.dl} added!`
                    );
                    fetchOfferings();
                    return addedOffering;
                  });
              }}
              offeringType={props.offeringType}
              getOfferingSpecificFormikInputs={
                props.getOfferingSpecificFormikInputs
              }
              titleSideNode={props.titleSideNode}
              postTitleNode={props.postTitleNode}
            />
          </div>
          {offerings.map((offering, index) => (
            <div className="mb-2">
              <ExistingOfferingItem
                offering={offering}
                user={props.user}
                key={index}
                refreshOfferings={fetchOfferings}
                onUpdate={(offering) => {
                  return OfferingAPI.update(
                    props.offeringTargetType,
                    offering.id
                  )
                    .executeV2(offering)
                    .then((response) => {
                      message.success(
                        `Offering ${response.commonDetails.dl} updated!`
                      );
                      fetchOfferings();
                      return response;
                    });
                }}
                offeringType={props.offeringType}
                getOfferingSpecificFormikInputs={
                  props.getOfferingSpecificFormikInputs
                }
                getAdditionalOfferingActions={
                  props.getAdditionalOfferingActions
                }
                titleSideNode={props.titleSideNode}
                postTitleNode={props.postTitleNode}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

interface ExistingOfferingItemProps {
  offering: IOfferingV2;
  user: User;
  refreshOfferings: () => void;
  offeringType: OfferingTypeName;
  offeringSpecificFormikInputs?: React.ReactNode;

  onUpdate: (values: IOfferingV2) => Promise<IOfferingV2>;

  getOfferingSpecificFormikInputs?: (offering: IOfferingV2) => React.ReactNode;
  getAdditionalOfferingActions?: (offering: IOfferingV2) => React.ReactNode;

  titleSideNode?: React.ReactNode;
  postTitleNode?: React.ReactNode;
}

export function ExistingOfferingItem(props: ExistingOfferingItemProps) {
  let [mode, setMode] = useState<Mode>(Mode.VIEW);
  let offering = props.offering;

  function toggleMode() {
    if (mode == Mode.EDIT) {
      setMode(Mode.VIEW);
    } else {
      setMode(Mode.EDIT);
    }
  }
  return (
    <div className="card shadow ExistingOfferingItem">
      <div
        className={`card-header cursor-pointer ${
          mode == Mode.VIEW && "bg-white"
        }`}
        onClick={() => {
          toggleMode();
        }}
      >
        <div className="row">
          <div className="col-sm-12 col-lg-9">
            <div>
              {offering.commonDetails.url ? (
                <>
                  <div>
                    <span className="h6">{offering.commonDetails.dl}</span>
                    <Link
                      className="ml-3"
                      href={offering.commonDetails.url}
                      tooltip="Click to open offering as a user"
                      target="_blank"
                    >
                      <ExportOutlined />
                    </Link>
                    <CopyClipboardButton
                      className="ml-2"
                      tooltip="Click to copy offering url"
                      textToCopy={offering.commonDetails.url}
                    />
                  </div>
                </>
              ) : (
                <span className="h6">{offering.commonDetails.dl}</span>
              )}
            </div>
            <div className="mt-1">
              <OfferingPublishStatusBadge
                className="mt-2"
                offering={offering.commonDetails}
              />
            </div>
          </div>
          <div className="col-sm-12 col-lg-3">
            <div className="row">
              <div className="col"></div>
              <div className="col-auto hstack gap-3">
                <LinkClickHandler
                  className="text-secondary"
                  tooltip="Edit Information"
                  onClick={() => {
                    toggleMode();
                  }}
                >
                  <EditIcon />
                </LinkClickHandler>

                {props.getAdditionalOfferingActions && (
                  <>{props.getAdditionalOfferingActions(offering)}</>
                )}

                <OfferingPublishStatusToggleButton
                  offering={offering}
                  onOfferingUpdate={(updatedOffering) => {
                    props.refreshOfferings();
                    return Promise.resolve();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {mode == Mode.EDIT && (
        <div className="pb-3 px-3">
          <OfferingAddOrEditForm
            user={props.user}
            existingOffering={props.offering}
            onSave={props.onUpdate}
            getOfferingSpecificFormikInputs={
              props.getOfferingSpecificFormikInputs
            }
            titleSideNode={props.titleSideNode}
            postTitleNode={props.postTitleNode}
          />
        </div>
      )}
    </div>
  );
}

interface AddOfferingComponentProps {
  user: User;
  onAdd: (values: IOfferingV2) => Promise<IOfferingV2>;
  offeringType: OfferingTypeName;
  getOfferingSpecificFormikInputs?: (offering: IOfferingV2) => React.ReactNode;
  titleSideNode?: React.ReactNode;
  postTitleNode?: React.ReactNode;
}

export function AddOfferingComponent(props: AddOfferingComponentProps) {
  const [showAddForm, setShowAddForm] = useState<boolean>();

  return (
    <div className="AddOfferingComponent">
      <Row gutter={[7, 8]}>
        <Col>
          <EBButtonV2
            className="btn-sm"
            content={{
              normal: <AddTitle text="Add Offering" />,
            }}
            onClick={() => {
              setShowAddForm(true);
            }}
          />
        </Col>
        {showAddForm && (
          <Col>
            <EBButtonV2
              className="btn-sm btn-secondary"
              content={{
                normal: <CancelTitle />,
              }}
              onClick={() => {
                setShowAddForm(false);
              }}
            />
          </Col>
        )}
      </Row>

      {showAddForm && (
        <>
          <div className="card card-body mt-2 pb-3 px-3 pt-0">
            <OfferingAddOrEditForm
              user={props.user}
              onSave={props.onAdd}
              existingOffering={OfferingHelper.getInitialOffering(
                props.offeringType,
                props.user
              )}
              getOfferingSpecificFormikInputs={
                props.getOfferingSpecificFormikInputs
              }
              titleSideNode={props.titleSideNode}
              postTitleNode={props.postTitleNode}
            />
          </div>
        </>
      )}
    </div>
  );
}
