import React from "react";
import { ListItemLayoutDefault } from "../../list/ListItem.Layout";
import { ListItemProps, ListV2 } from "../../list/ListV2";
import { SearchRequest } from "../../model/search/UserSearchRequestV1";
import { PageResponse } from "../../paging/Paging.Model";
import { QuerySearch } from "../../search/Query.Search";
import { UserAPI } from "../../user/UserAPI";
import { UserListItemComponent } from "../../users/UserList.component";

export function AdminUsersPageContent() {
  return (
    <div className="main-container">
      <ListV2
        onFetch={function (
          searchRequest?: SearchRequest | undefined
        ): Promise<PageResponse<any>> {
          return UserAPI.searchUsers().executeV2(searchRequest ?? {});
        }}
        ItemLayoutComponent={ListItemLayoutDefault}
        itemNode={(itemProps: ListItemProps) => {
          let user = itemProps.item;
          return <UserListItemComponent user={user} showAdminActions={true} />;
        }}
        SearchComponent={QuerySearch}
      />
    </div>
  );
}
