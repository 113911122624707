import React, { useState } from "react";
import { WindowHelpers } from "../../helpers/Window.helper";

enum EBAlertType {
  SUCCESS,
  ERROR,
}

interface EBAlertProps {
  type: EBAlertType;
  message: string;
}

function EBAlert(props: EBAlertProps) {
  const [open, setOpen] = useState(true);

  return (
    <>
      {open && (
        <div
          className={`alert ${
            props.type == EBAlertType.SUCCESS ? "alert-success" : "alert-danger"
          } alert-dismissible`}
        >
          <a
            href="javascript:;"
            className="close"
            data-dismiss="alert"
            aria-label="close"
            onClick={() => {
              setOpen(false);
            }}
          >
            &times;
          </a>
          {props.type == EBAlertType.SUCCESS && (
            <i className="fa fa-check" aria-hidden="true"></i>
          )}
          {props.type == EBAlertType.ERROR && (
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          )}
          &nbsp;{props.message}
        </div>
      )}
    </>
  );
}

interface PublicPageAlertMessageProps {}
interface PublicPageAlertMessageState {
  message: string;
  type: EBAlertType;
}
export class PublicPageAlertMessage extends React.Component<
  PublicPageAlertMessageProps,
  PublicPageAlertMessageState
> {
  constructor(props: PublicPageAlertMessageProps) {
    super(props);
    let successMessage = WindowHelpers.qParam("successMessage");
    let errorMessage = WindowHelpers.qParam("errorMessage");
    if (successMessage) {
      this.state = { message: successMessage, type: EBAlertType.SUCCESS };
    } else {
      this.state = { message: errorMessage, type: EBAlertType.ERROR };
    }
  }

  render() {
    return (
      <>
        {this.state.message && (
          <div className="row">
            <div className="d-inline-flex p-2 justify-content-center">
              <EBAlert message={this.state.message} type={this.state.type} />
            </div>
          </div>
        )}
      </>
    );
  }
}
