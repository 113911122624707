import React from "react";
import {
  Mode,
  ModeBasedString,
} from "../../components/common/Form/model/mode.model";
import { ReviewView } from "../../components/common/review/Review.view";
import { ReviewEdit } from "../../components/common/review/ReviewForm.view";
import { ModeHelpers } from "../../helpers/Mode.helper";
import { ReviewV1 } from "../../model/Reviews";
import { FormLineItemComponentProps } from "./FormLineItemComponent";

interface FormLineItemReviewTemplateData {
  displayTitle: ModeBasedString;
}

interface FormLineItemReviewInputData {
  value: ReviewV1;
}

interface FormLineItemReviewCustomValidationsData {
  askForImprovements?: boolean;
  minImprovementsLength?: number;
}

export function FormLineItemReviewComponent(props: FormLineItemComponentProps) {
  let templateData = props.templateData as FormLineItemReviewTemplateData;
  let inputData = props.inputData as FormLineItemReviewInputData;
  let customValidations =
    props.customValidationsData as FormLineItemReviewCustomValidationsData;

  return (
    <>
      <div>
        {props.mode == Mode.EDIT && (
          <div>
            {ModeHelpers.getString(props.mode, templateData.displayTitle)}:
            <ReviewEdit
              askForImprovements={
                customValidations && customValidations.askForImprovements
                  ? true
                  : false
              }
              namePrefix={props.name + ".value"}
              currentValues={inputData ? inputData.value : undefined}
            />
          </div>
        )}
        {props.mode == Mode.VIEW && inputData && inputData.value && (
          <div>
            {ModeHelpers.getString(props.mode, templateData.displayTitle)}:
            <ReviewView
              review={inputData.value}
              hideCreatedAt={true}
              hideReviewer={true}
              hideContextDl={true}
            />
          </div>
        )}
      </div>
    </>
  );
}
