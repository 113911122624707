import React, { useState } from "react";
import { User } from "../user/User";
import { ExpertStudioMenuOption } from "./ExpertStudio.model";
import { UrlShareView } from "./UrlShare.view";

interface ExpertStudioMobileBottomTrayComponentProps {
  activeNavId?: string;
  user?: User;
  opts: ExpertStudioMenuOption[];
}

export function ExpertStudioMobileBottomTrayComponent(
  props: ExpertStudioMobileBottomTrayComponentProps
) {
  let [showMore, setShowMore] = useState<boolean>(false);
  function moreCallback() {
    setShowMore(!showMore);
  }
  return (
    <>
      <div className="navbar-dark">
        <div className="nav nav-pills row mb-auto bg-dark">
          {[
            ...props.opts.slice(0, 2),
            {
              id: "more",
              title: "More Options",
              faIconClass: "fa fa-bars",
              onClick: function () {
                moreCallback();
              },
            },
          ].map((navItem) => {
            return (
              <div className="nav-item col-4 text-center" key={navItem.id}>
                <a
                  href={navItem.href}
                  className={`nav-link ${
                    props.activeNavId === navItem.id ? "active" : "text-white"
                  }`}
                  aria-current="page"
                  onClick={navItem.onClick}
                >
                  <i className={`${navItem.faIconClass}`}></i>
                  <br />
                  {navItem.title}
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <ExpertStudioPageMobileBottomDrawer
        open={showMore}
        user={props.user}
        activeNavId={props.activeNavId}
        moreCallback={moreCallback}
        opts={props.opts}
      />
    </>
  );
}

interface ExpertStudioPageMobileBottomDrawerProps {
  open?: boolean;
  activeNavId?: string;
  moreCallback: () => void;
  user?: User;
  opts: ExpertStudioMenuOption[];
}

function ExpertStudioPageMobileBottomDrawer(
  props: ExpertStudioPageMobileBottomDrawerProps
) {
  return (
    <>
      {props.open && (
        <div
          className="ExpertStudioPageMobileBottomDrawer bg-dark text-white row p-3 mx-1"
          style={{
            position: "fixed",
            bottom: "0",
            left: "0",
            zIndex: 2,
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
          }}
        >
          <div className="align-items-center mt-1 mb-md-0 me-md-auto text-white">
            <span className="fs-4 ml-3">Expert Studio</span>
            <a
              className="text-white mr-3"
              aria-current="page"
              style={{ float: "right" }}
              onClick={props.moreCallback}
            >
              <i className="mr-1 pl-2 fa fa-close"></i>
            </a>
          </div>
          <div className="text-white m-3 text-wrap text-break">
            <div className="nav-list">
              {props.user?.basicDetails.profileUrl && (
                <UrlShareView url={props.user?.basicDetails.profileUrl} />
              )}
            </div>
          </div>
          <hr className="bg-white" />
          {props.opts.slice(2, props.opts.length).map((navItem) => {
            return (
              <div className="mb-1 text-center" key={navItem.id}>
                <a
                  href={navItem.href}
                  className={`nav-link  ${
                    props.activeNavId === navItem.id ? "active" : "text-white"
                  }`}
                  aria-current="page"
                >
                  <i className={`mr-2 ${navItem.faIconClass}`}></i>

                  {navItem.title}
                </a>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
