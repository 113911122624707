import React from "react";
import { CharchaaAddPostPageContent } from "../components/pages/charchaa/CharchaaAddPostPage";
import { CharchaaPageContent } from "../components/pages/charchaa/CharchaaPageContent";
import { CharchaaPostPageContent } from "../components/pages/charchaa/CharchaaPost.page-content";
import { CharchaaUpdatePostPageContent } from "../components/pages/charchaa/CharchaaUpdatePostPageContent";
import { publicLinkConstants } from "../constants";
import { PublicPageRoute } from "./PublicPage.route";

export function CharchaaRoutes() {
  return (
    <>
      <PublicPageRoute
        path={publicLinkConstants.CHARCHAA_POST_EDIT}
        contentComponent={CharchaaUpdatePostPageContent}
      />

      <PublicPageRoute
        exact
        path={publicLinkConstants.CHARCHAA}
        contentComponent={CharchaaPageContent}
      />
      <PublicPageRoute
        exact
        path={publicLinkConstants.CHARCHAA_POST}
        contentComponent={CharchaaPostPageContent}
      />
      <PublicPageRoute
        exact
        path={publicLinkConstants.CHARCHAA_POST_ADD}
        contentComponent={CharchaaAddPostPageContent}
      />
    </>
  );
}
