import React from "react";

interface AddTitleProps {
  text?: string;
}

export function AddTitle(props: AddTitleProps) {
  return (
    <>
      <i className="fas fa-plus mr-1"></i> {props.text ?? "Add"}
    </>
  );
}
