import React from "react";
import {
  EBSelectOption,
  EBSelectOptionDataType,
  EBSelectV2,
} from "../components/common/Form/EBSelectV2";
import { SelectorProps } from "../components/common/Selectors";
import { Country } from "../model/Country.model";

interface CountrySelectorProps extends SelectorProps {
  countries: Country[];
  isMulti?: boolean;
}

export interface CountrySelectorOption
  extends EBSelectOptionDataType,
    Country {}

export function CountrySelector(props: CountrySelectorProps) {
  let options: EBSelectOption<CountrySelectorOption>[] = [];

  props.countries.forEach((country: Country) => {
    options.push({
      label: country.dl,
      value: `${country.code}`,
      data: {
        ...country,
        selectOptionValue: `${country.code}`,
      },
    });
  });

  return (
    <div className="row">
      <div className="col">
        {options && options.length > 0 && (
          <EBSelectV2
            label={props.label}
            name={props.name}
            required={props.required}
            options={options}
            disabled={props.disabled}
            isMulti={props.isMulti}
            isDataEqual={(
              d1: CountrySelectorOption,
              d2: CountrySelectorOption
            ) => {
              return d1.code === d2.code;
            }}
          />
        )}
      </div>
    </div>
  );
}
