import { useField } from "formik";
import React from "react";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";

export enum TimeDurationOptionGranularity {
  MINS,
  HOURS,
  DAYS,
}
interface TimeDurationFormikInputProps extends FormikInputParams {
  customInputGranularity: TimeDurationOptionGranularity;
}
export function TimeDurationFormikInput(props: TimeDurationFormikInputProps) {
  const [field, meta, helpers] = useField({
    ...props,
    validate: (value: any) => {
      if (props.required && value === undefined) {
        return "Required";
      }
    },
  });

  function setFieldValue(valueInSecs: number) {
    field.onChange({
      target: {
        name: props.name,
        value: valueInSecs,
      },
    });
  }

  function onCustomDurationChange(value: number) {
    if (isNaN(value)) {
      setFieldValue(0);
      return false;
    }
    if (props.customInputGranularity == TimeDurationOptionGranularity.MINS) {
      setFieldValue(value * 60);
    } else if (
      props.customInputGranularity == TimeDurationOptionGranularity.HOURS
    ) {
      setFieldValue(value * 60 * 60);
    } else if (
      props.customInputGranularity == TimeDurationOptionGranularity.DAYS
    ) {
      setFieldValue(value * 24 * 60 * 60);
    }
  }

  function getCustomFieldValue() {
    if (!field.value) {
      return field.value;
    }
    if (props.customInputGranularity == TimeDurationOptionGranularity.MINS) {
      return field.value / 60;
    } else if (
      props.customInputGranularity == TimeDurationOptionGranularity.HOURS
    ) {
      return field.value / (60 * 60);
    } else if (
      props.customInputGranularity == TimeDurationOptionGranularity.DAYS
    ) {
      return field.value / (24 * 60 * 60);
    }
    return field.value;
  }
  return (
    <div className="mt-2">
      <div className="input-group">
        <input
          className="form-control"
          required={props.required}
          disabled={props.disabled}
          onChange={(event: any) => {
            onCustomDurationChange(event.target.value);
          }}
          value={getCustomFieldValue()}
        />

        <div className="input-group-append">
          <span className="input-group-text" id="inputGroupPrepend2">
            {props.customInputGranularity ==
              TimeDurationOptionGranularity.MINS && "Minutes"}
            {props.customInputGranularity ==
              TimeDurationOptionGranularity.HOURS && "Hours"}
            {props.customInputGranularity ==
              TimeDurationOptionGranularity.DAYS && "Days"}
          </span>
        </div>
      </div>
    </div>
  );
}
