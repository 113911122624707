import { Company } from "../company/Company";
import { ReactionCounts } from "../model/ReactionCounts.mode";
import { Topic } from "../topic/Topic.model";
import { AnswerSubmissionStatus } from "./QuestionAnswerSubmission.model";

export enum QuestionDifficulty {
  EASY = "EASY",
  MEDIUM = "MEDIUM",
  HARD = "HARD",
  UNKNOWN = "UNKNOWN",
}

export interface Question {
  id: number;
  title: string;
  statement: string;
  difficulty: QuestionDifficulty;
  createdAt?: string;
  isAnon: boolean;
  approvedAt?: string;
  archivedAt?: string;
  canUserEdit?: boolean;
  slug?: string;
  reactionCounts?: ReactionCounts;
  topics?: Topic[];
  companies?: Company[];
  url?: string;
  typeInfo?: QuestionTypeInfo;
  answerStatus?: AnswerSubmissionStatus;
}

export interface QuestionSearchResponse {
  questions: Question[];
}

export interface QuestionSummary {
  count: number;
}

export interface QuestionCreateUpdateRequest {
  id?: number;
  title?: string;
  statement?: string;
  difficulty?: string;
  isAnon?: boolean;
}

export interface IQuestionTypeData {}

export interface QuestionTypeDataMcq extends IQuestionTypeData {
  options: QuestionTypeDataMcqOption[];
  type: QuestionTypeDataMcqType;
}

export interface QuestionTypeDataMcqOption {
  id: string;
  text: string;
  order: number;
  isCorrect: boolean;
}

export interface QuestionTypeInfo {
  type: QuestionType;
  data: IQuestionTypeData;
}

export interface QuestionType {
  typeEnum: QuestionTypeEnum;
  dl: string;
}

export enum QuestionTypeEnum {
  CODING = "CODING",
  MCQ = "MCQ",
  THEORETICAL = "THEORETICAL",
  OTHER = "OTHER",
}

export enum QuestionTypeDataMcqType {
  SINGLE = "SINGLE",
  MULTI = "MULTI",
}
