let API_BASE = "/api";
export class AchievementConstants {
  public static API_USER_ACHIEVEMENTS_LIST =
    API_BASE + "/user/{userId}/user-achievements";

  public static API_USER_ACHIEVEMENT_SHAREABLE_IMAGE =
    API_BASE + "/user-achievement/{userAchievementId}/images/shareable";
  public static API_USER_ACHIEVEMENT_SHAREABLE_CONTENT =
    API_BASE + "/user-achievement/{userAchievementId}/content/shareable";
}
