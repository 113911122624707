import React from "react";
import { Label } from "./Form/elements/FormInputLabel";
import { EBTextInputV2 } from "./input/TextLine.input";

export function ExpertPaymentsSettingsForm() {
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <Label
            label={
              <>
                <i className="fa-solid fa-indian-rupee-sign"></i> UPI Id for INR
                earnings:
              </>
            }
          />

          <EBTextInputV2
            name="paymentSettings.upi"
            helpText="Needed for transferring your Indian Rupee earnings every month."
          />
        </div>
        <div className="col-md-6">
          <Label
            label={
              <>
                <i className="fa-brands fa-paypal"></i> Paypal Email for USD
                earnings:
              </>
            }
          />
          <EBTextInputV2
            name="paymentSettings.paypalEmail"
            helpText="Needed for transferring your USD earnings every month."
          />
        </div>
      </div>
    </div>
  );
}
