import React, { useEffect, useState } from "react";
import { CompanyEditComponent } from "./Company.Edit.Component";
import { PageProps } from "../model/views/PageProps";
import { PropertySafetyFilled } from "@ant-design/icons";
import { Company } from "./Company";
import { CompanyAPI } from "./CompanyAPI";
import { ReactMatch } from "../model/ReactMatchObj";
import { Loader } from "../components/common/Loader.component";

interface CompanyEditPagePathParams {
  companyId: string;
}

interface CompanyEditPageProps extends PageProps {
  match: ReactMatch<CompanyEditPagePathParams>;
}

export function CompanyEditPage(props: CompanyEditPageProps) {
  let [company, setCompany] = useState<Company>();
  let [loading, setLoading] = useState<boolean>();

  useEffect(function () {
    setLoading(true);
    CompanyAPI.getCompany(props.match.params.companyId)
      .executeV2()
      .then((company: Company) => {
        setCompany(company);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <div className="CompanyEditPage main-container">
      {loading && <Loader />}
      {props.user && company && (
        <CompanyEditComponent liu={props.user} company={company} />
      )}
    </div>
  );
}
