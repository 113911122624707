import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { ToggleLikeResponse } from "./Likes.model";

export class LikesAPI {
  public static toggleLike(
    targetType: AttachmentTargetType,
    targetId: string
  ): Promise<ToggleLikeResponse> {
    let api = apiLinkConstants.API_LIKE_TOGGLE.replaceAll(
      "{targetType}",
      targetType
    ).replaceAll("{targetId}", targetId);

    return new Api<void, ToggleLikeResponse>(api, ApiMethod.POST).execute();
  }
}
