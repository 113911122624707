import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { FormikInputParams } from "./FormikInputParams.model";

interface FormikHiddenInputProps extends FormikInputParams {
  value: any;
}
export function FormikHiddenInput(props: FormikHiddenInputProps) {
  let formikContext = useFormikContext();

  useEffect(() => {
    formikContext.setFieldValue(props.name, props.value);
  }, [props.value]);

  return <></>;
}
