import { Col, Row } from "antd";
import { Gutter } from "antd/es/grid/row";
import React from "react";

interface LeftAndMainLayoutProps {
  left: React.ReactNode;
  main: React.ReactNode;

  gutter?: Gutter | [Gutter, Gutter];
}

export function LeftAndMainLayout(props: LeftAndMainLayoutProps) {
  return (
    <div className="LeftAndMainLayout">
      <Row gutter={props.gutter}>
        <Col sm={12} md={10} lg={8} xxl={6}>
          {props.left}
        </Col>
        <Col sm={12} md={14} lg={16} xxl={18}>
          {props.main}
        </Col>
      </Row>
    </div>
  );
}
