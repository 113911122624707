import React from "react";
import {
  PaymentReleaseRequest,
  PaymentReleaseRequestsResponse,
} from "./PaymentReleaseRequest.model";
import { PaymentReleaseRequestsListComponent } from "./PaymentReleaseRequests.list.component";
import { PaymentReleaseRequestsSumaryListComponent } from "./PaymentReleaseRequestsSumary.component";

interface PaymentReleaseComponentProps {
  paymentReleaseRequests: PaymentReleaseRequestsResponse;
  showReviewOptions?: boolean;

  onPrrUpdate?: (index: number, prr: PaymentReleaseRequest) => Promise<void>;
}

export function PaymentReleaseComponent(props: PaymentReleaseComponentProps) {
  return (
    <div className="PaymentReleaseComponent">
      <PaymentReleaseRequestsSumaryListComponent
        summaries={props.paymentReleaseRequests.summaries}
      />

      {props.paymentReleaseRequests.paymentReleaseRequests.length > 0 && (
        <div className="mt-4">
          <PaymentReleaseRequestsListComponent
            prrs={props.paymentReleaseRequests.paymentReleaseRequests}
            showReviewOptions={props.showReviewOptions}
            onPrrUpdate={props.onPrrUpdate}
          />
        </div>
      )}

      {props.paymentReleaseRequests.paymentReleaseRequests.length == 0 && (
        <>No payment release requests found.</>
      )}
    </div>
  );
}
