import { Col, Row } from "antd";
import { FieldArray } from "formik";
import React from "react";
import { LinkClickHandler } from "../../links/Link.ClickHandler";
import { InterviewRoundFeedbackQuestion } from "../../model/requests/InterviewRoundFeedback";
import { QuestionCategoryResponse } from "../../model/response/QuestionCategory.response";
import { DifficultyBadge } from "../../question/Difficulty.badge";
import { QuestionView } from "../../question/Question.view";
import { User } from "../../user/User";
import { EBModalV2 } from "./EBModalV2";
import { FormSingleQuestionFeedback } from "./Form/FormSingleQuestionFeedback";
import { Mode } from "./Form/model/mode.model";
import { FormikHiddenInput } from "./input/FormikHiddentInput";
import { FormikInputParams } from "./input/FormikInputParams.model";

export interface InterviewFeedbackQuestionsProps extends FormikInputParams {
  questions?: InterviewRoundFeedbackQuestion[];
  allQuestionCategories: QuestionCategoryResponse[];
  user: User;
}
export function InterviewFeedbackQuestions(
  props: InterviewFeedbackQuestionsProps
) {
  const name = props.name ? props.name + ".questions" : "questions";
  let uniqueKey = name.replaceAll(".", "-");
  return (
    <div>
      <FieldArray name={name}>
        {({ insert, remove, push }: any) => (
          <div>
            {props.questions &&
              props.questions.length > 0 &&
              props.questions.map((feedbackQuestion, index) => (
                <div className="card card-body mb-3" key={index}>
                  <div className="d-flex flex-row mb-2">
                    <div className="flex-fill">
                      {feedbackQuestion.question ? (
                        <Row gutter={[8, 8]}>
                          <Col flex={"auto"}>
                            <EBModalV2
                              modalSize="lg"
                              body={
                                <QuestionView
                                  className="border-0"
                                  question={feedbackQuestion.question}
                                />
                              }
                              id={`${uniqueKey}-${index}-question-detail-modal`}
                            />
                            <LinkClickHandler
                              onClick={function (): void {}}
                              className="text-dark "
                              otherProps={{
                                "data-bs-toggle": "modal",
                                "data-bs-target": `#${uniqueKey}-${index}-question-detail-modal`,
                              }}
                            >
                              <span className="h6">
                                {index + 1}. {feedbackQuestion.question.title}
                                {feedbackQuestion.category?.displayName &&
                                  ` - (${feedbackQuestion.category?.displayName})`}
                              </span>
                            </LinkClickHandler>
                          </Col>
                          <Col>
                            <DifficultyBadge
                              difficulty={feedbackQuestion.difficulty}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <span className="text-danger">
                          Question not picked (Please pick it from below)
                        </span>
                      )}
                    </div>

                    {props.mode === Mode.EDIT && (
                      <div className="">
                        <button
                          className="btn text-danger"
                          type="button"
                          onClick={() => remove(index)}
                        >
                          <i className="far fa-trash-alt"></i>
                        </button>
                      </div>
                    )}
                  </div>
                  <FormikHiddenInput
                    value={index + 1}
                    name={`${name}.${index}.order`}
                  />
                  <FormSingleQuestionFeedback
                    name={`${name}.${index}`}
                    feedbackQuestion={feedbackQuestion}
                    allQuestionCategories={props.allQuestionCategories}
                    mode={props.mode}
                    user={props.user}
                  />
                </div>
              ))}

            {props.mode === Mode.EDIT && (
              <div className="row mt-3">
                <div className="col">
                  <button
                    className="btn btn-warning btn-sm"
                    type="button"
                    onClick={() => push({})}
                  >
                    Add Question
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </FieldArray>
    </div>
  );
}
