import React from "react";
import "./BreadcrumbComponent.css";

interface BreadcrumbItem {
  href?: string;
  children: React.ReactNode;
}
interface BreadcrumbComponentProps {
  items: BreadcrumbItem[];
}

export function BreadcrumbComponent(props: BreadcrumbComponentProps) {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb mb-0 pb-0">
        <li className="breadcrumb-item">
          <a href={"/"}>
            <i className="fas fa-house"></i>
          </a>
        </li>
        {props.items.map((item, index) => (
          <>
            <li className="breadcrumb-item" key={index}>
              {item.href ? (
                <>
                  <a href={item.href}>{item.children}</a>
                </>
              ) : (
                <>{item.children}</>
              )}
            </li>
          </>
        ))}
      </ol>
    </nav>
  );
}
