import { Status } from "../model/Status.model";

export interface VideoCreateRequest {
  title: string;
  collectionId: string;
}

export interface VideoCreateResponse {
  videoId: string;
}

export interface VideoCollectionCreateResponse {
  collectionId: string;
}

export interface VideoBunnyCdn {
  id?: string;
  bunnyId: string;
  collectionId?: string;
  dl?: string;
  libraryId: string;
  createdAt?: string;
  createdBy?: string;
  uploadCompletedAt?: string;
  uploadProgressUpdatedAt?: string;
  uploadProgress?: number;
  status?: Status<VideoStatus>;
  uploadProps: VideoBunnyCdnUploadProps;
}

export interface VideoBunnyCdnUploadProps {
  signature: string;
  expirationTimeInSecs: number;
  videoId: string;
  libraryId: string;
}

export enum VideoStatus {
  UPLOAD_IN_PROGRESS = "UPLOAD_IN_PROGRESS",
  PROCESSING = "PROCESSING",
  UPLOAD_COMPLETED = "UPLOAD_COMPLETED",
  UPLOAD_FAILED = "UPLOAD_FAILED",
}
