import React from "react";
import { EBButton } from "../components/common/EBButton";
import { User, UserBasicDetails } from "../user/User";
import { IContentItemHandler } from "./ContentItemType.Handlers";
import { EBModalV2 } from "../components/common/EBModalV2";
import { ModalLinkedButton } from "../button/ModalLinked.Button";
import { Center } from "../components/common/layout/Center.component";
import { AddTitle } from "../components/common/Add.title";

interface ContentInputSidebartProps {
  user: User;
  contentItemTypeHandlers: IContentItemHandler[];
  onSelect: (itemTypeHandler: IContentItemHandler) => void;
  addBtnLabel?: React.ReactNode;
}

export function ContentWidgetPicker(props: ContentInputSidebartProps) {
  function onSelect(itemTypeHandler: IContentItemHandler) {
    document.getElementById("content-widget-picker-btn")?.click();
    props.onSelect(itemTypeHandler);
  }
  return (
    <div className="ContentInputSidebar">
      <Center>
        <ModalLinkedButton
          modalBody={
            <>
              {props.contentItemTypeHandlers.map(
                (itemTypeHandler: IContentItemHandler, index: number) => (
                  <div key={index} className="">
                    <EBButton
                      classes={[
                        "btn btn-light  w-100 border border-2 rounded-0 fs-5 text-start pl-3 py-3",
                      ]}
                      onClick={() => {
                        onSelect(itemTypeHandler);
                      }}
                      text={{ normal: itemTypeHandler.sidebarListView }}
                    />
                  </div>
                )
              )}
            </>
          }
          uniqueKey={"content-widget-picker"}
          btnProps={{
            content: {
              normal: (
                <>
                  <div className="w-100 p-2 px-5">
                    {props.addBtnLabel ?? <AddTitle text="Add Item" />}
                  </div>
                </>
              ),
            },
            className: "btn-light w-100",
            id: "content-widget-picker-btn",
          }}
        />
      </Center>
    </div>
  );
}
