import React from "react";
import { publicLinkConstants } from "../constants";
import { UserOrdersPageContent } from "../orders/UserOrders.PageContent";
import { UserEditProfileContent } from "../user/UserEditProfileContent";
import { UserProfilePageContent } from "../user/UserProfile.PageContent";
import { PublicPageRoute } from "./PublicPage.route";

export function UserRoutes() {
  return (
    <>
      <PublicPageRoute
        exact
        path={publicLinkConstants.USER_PROFILE}
        contentComponent={UserProfilePageContent}
      />
      <PublicPageRoute
        exact
        path={publicLinkConstants.USER_PROFILE_OLD}
        contentComponent={UserProfilePageContent}
      />
      <PublicPageRoute
        exact
        path={publicLinkConstants.USER_ORDERS_LIST}
        contentComponent={UserOrdersPageContent}
      />
      <PublicPageRoute
        exact
        path={publicLinkConstants.USER_PROFILE_EDIT}
        contentComponent={UserEditProfileContent}
      />
    </>
  );
}
