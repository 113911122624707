import { ConfigProvider, Steps, message } from "antd";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ApiErrorHelper } from "../../helpers/ApiError.helper";
import { EBLogger } from "../../helpers/EBLogger";
import { EBButton } from "./EBButton";
import { Center } from "./layout/Center.component";

export enum EBStepType {
  FORM = "FORM",
  CONTENT = "CONTENT",
}

export interface EBStep {
  title: string;
  content: React.ReactNode;

  initialFormValues?: any;
  onFormSubmit?: (values: any) => Promise<void>;
}

export interface EBStepperProps {
  steps: EBStep[];
  navigationDisabled?: boolean;
  onDone?: () => Promise<void>;
  doneButtonText?: string;
  saveAndNext?: boolean;
  onSaveCallback?: () => Promise<void>;
}
export function EBStepper(props: EBStepperProps) {
  let [current, setCurrent] = useState<number>(0);
  let [stepWrappers, setStepWrappers] = useState<React.ReactNode[]>();

  useEffect(() => {
    let stepWrappers: React.ReactNode[] = [];
    for (let i = 0; i < props.steps.length; i++) {
      let step = props.steps[i];
      stepWrappers.push(
        <EBStepWrapperComponent
          step={step}
          showPrev={i > 0}
          showNext={i < props.steps.length - 1}
          showDone={i === props.steps.length - 1}
          onPrev={function (): void {
            setCurrent(i - 1);
          }}
          onNext={function (): void {
            setCurrent(i + 1);
          }}
          onDone={function (): void {
            if (props.onDone) {
              props.onDone();
            }
          }}
          doneButtonText={props.doneButtonText}
        />
      );
    }
    setStepWrappers(stepWrappers);
  }, [props.steps]);

  let currentWrapper = stepWrappers ? stepWrappers[current] : undefined;
  return (
    <div className="CreateEventComponent card">
      {currentWrapper && (
        <>
          <div className="card-header event-data-desktop">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#fed136",
                },
              }}
            >
              <Steps current={current} items={props.steps} />
            </ConfigProvider>
          </div>
          <div className="card-header event-data-mobile">
            <div className=" text-center pt-20 pe-0 pb-10 ps-0 fw-bold">
              Step {current + 1}/{props.steps.length} ({" "}
              {props.steps[current].title})
            </div>
          </div>
          <div className="card-body">{currentWrapper}</div>
        </>
      )}
    </div>
  );
}

interface EBStepComponentProps {
  step: EBStep;
  showPrev: boolean;
  showNext: boolean;
  showDone: boolean;
  onPrev: () => void;
  onNext: () => void;
  onDone: () => void;

  doneButtonText?: string;
  navigationDisabled?: boolean;
}

function EBStepWrapperComponent(props: EBStepComponentProps) {
  EBLogger.log("EBStepWrapperComponent: props.step", props.step);
  return (
    <Formik
      initialValues={props.step.initialFormValues ?? {}}
      enableReinitialize={true}
      onSubmit={function (values, actions): void | Promise<any> {
        props.step.initialFormValues = values;
        actions.setSubmitting(true);
        let step = props.step;

        if (step.onFormSubmit) {
          step
            .onFormSubmit(values)
            .then((response) => {
              props.onNext();
            })
            .catch((error) => {
              let displayError = ApiErrorHelper.getDisplayErrorMessage(error);
              message.error(displayError);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        } else {
          props.onNext();
        }
      }}
    >
      {(formProps) => {
        return (
          <Form>
            {props.step.content}
            <div>
              <Center>
                <EBStepperNavigation
                  showPrev={props.showPrev}
                  showNext={false}
                  showSaveNext={props.showNext}
                  showDone={props.showDone}
                  onPrev={props.onPrev}
                  onNext={props.onNext}
                  onSaveAndNext={() => {
                    formProps.handleSubmit();
                  }}
                  onDone={props.onDone}
                  doneButtonText={props.doneButtonText}
                />
              </Center>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

interface EBStepperNavigationProps {
  showPrev: boolean;
  showNext: boolean;
  showSaveNext: boolean;
  showDone: boolean;
  onPrev: () => void;
  onNext: () => void;
  onSaveAndNext: () => void;
  onDone: () => void;
  doneButtonText?: string;
  navigationDisabled?: boolean;
}

function EBStepperNavigation(props: EBStepperNavigationProps) {
  return (
    <>
      {props.showPrev && (
        <EBButton
          classes={["btn btn-secondary m-1"]}
          disabled={props.navigationDisabled}
          onClick={props.onPrev}
          text={{ normal: "Previous" }}
        />
      )}
      {props.showNext && (
        <EBButton
          classes={["btn btn-secondary m-1"]}
          disabled={props.navigationDisabled}
          onClick={props.onNext}
          text={{ normal: "Next" }}
        />
      )}

      {props.showDone && (
        <EBButton
          classes={["btn btn-primary m-1"]}
          disabled={props.navigationDisabled}
          onClick={props.onDone}
          text={{
            normal: props.doneButtonText ? props.doneButtonText : "Done",
          }}
        />
      )}

      {props.showSaveNext && (
        <>
          {" "}
          <EBButton
            classes={["btn btn-primary m-1"]}
            disabled={props.navigationDisabled}
            onClick={(event) => {
              props.onSaveAndNext();
              // event.preventDefault();
            }}
            text={{ normal: "Save & Next", submitting: "Saving..." }}
          ></EBButton>
        </>
      )}
    </>
  );
}
