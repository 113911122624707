import React from "react";

interface StarRatingViewProps {
  starRating: number;
  maxRating: number;
  hideNumbers?: boolean;
}

class StarRatingView extends React.Component<StarRatingViewProps, {}> {
  render() {
    const stars = [];
    for (var i = 0; i < this.props.starRating; i++) {
      stars.push(
        <i className="fas fa-star text-warning" key={stars.length}></i>
      );
    }

    for (var i = 0; i < this.props.maxRating - this.props.starRating; i++) {
      stars.push(
        <i className="far fa-star text-warning" key={stars.length}></i>
      );
    }

    return (
      <div>
        {!this.props.hideNumbers
          ? this.props.starRating + "/" + this.props.maxRating
          : null}{" "}
        {stars}
      </div>
    );
  }
}

export default StarRatingView;
