import React from "react";
import { PriceView } from "../../components/common/Price.view";
import { PaymentReleaseRequestSummary } from "./PaymentReleaseRequest.model";
import { StatCard } from "../../stat/Stat.Card";
import { StatCardList } from "../../stat/Stat.Card.List";
import { Stat } from "../../stat/Stat.Model";
import { HTMLProps } from "../../model/HTMLProps";

interface PaymentReleaseRequestsSumaryListComponentProps {
  summaries: PaymentReleaseRequestSummary[];
}

export function PaymentReleaseRequestsSumaryListComponent(
  props: PaymentReleaseRequestsSumaryListComponentProps
) {
  return (
    <div className="PaymentReleaseRequestsSumaryListComponent">
      {props.summaries.map((summary, index) => (
        <PaymentReleaseRequestSummaryComponent
          key={index}
          summary={summary}
          className="mb-2"
        />
      ))}
    </div>
  );
}

interface PaymentReleaseRequestSummaryComponentProps extends HTMLProps {
  summary: PaymentReleaseRequestSummary;
}

export function PaymentReleaseRequestSummaryComponent(
  props: PaymentReleaseRequestSummaryComponentProps
) {
  let stats: Stat[] = [
    {
      title: "Total",
      value: <PriceView price={props.summary.total} />,
    },
  ];
  if (props.summary.pending.amount > 0) {
    stats.push({
      title: "Pending Work Completion",
      value: (
        <PriceView className="text-warning" price={props.summary.pending} />
      ),
    });
  }

  if (props.summary.workCompleted.amount > 0) {
    stats.push({
      title: "Pending Review",
      value: (
        <PriceView
          className="text-warning"
          price={props.summary.workCompleted}
        />
      ),
    });
  }

  if (props.summary.verified.amount > 0) {
    stats.push({
      title: "Approved",
      value: (
        <PriceView className="text-success" price={props.summary.verified} />
      ),
    });
  }

  if (props.summary.rejected.amount > 0) {
    stats.push({
      title: "Rejected",
      value: (
        <PriceView price={props.summary.rejected} className="text-danger" />
      ),
    });
  }

  return (
    <div className="LedgerSummaryComponent">
      <StatCardList stats={stats} {...(props as HTMLProps)} />
    </div>
  );
}
