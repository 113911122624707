let API_BASE = "/api";

export class OfferingPathConstants {
  public static API_USER_OFFERING_PATH =
    API_BASE +
    "/user/{userId}/offerings/paths/{offeringId}?context={fetchContext}";

  // Steps

  public static API_USER_OFFERINGS_PATH_STEPS =
    API_BASE + "/{userId}/offerings/paths/{offeringId}/steps";
  public static API_USER_OFFERINGS_PATH_STEPS_EDIT_LIST =
    API_BASE + "/{userId}/offerings/paths/{offeringId}/steps:editList";
  public static API_USER_OFFERINGS_PATH_STEP_ADD =
    API_BASE + "/{userId}/offerings/paths/{offeringId}/steps";
  public static API_USER_OFFERINGS_PATH_STEP_UPDATE =
    API_BASE + "/{userId}/offerings/paths/{offeringId}/steps/{stepId}";
  public static API_USER_OFFERINGS_PATH_STEP =
    API_BASE + "/{userId}/offerings/paths/{offeringId}/steps/{stepId}";
  public static API_USER_OFFERINGS_PATH_STEP_SUB_STEP_ADD =
    API_BASE + "/{userId}/offerings/paths/{offeringId}/steps/{stepId}/steps";
  public static API_USER_OFFERINGS_PATH_STEP_MOVE =
    API_BASE +
    "/{userId}/offerings/paths/{offeringId}/steps/{stepId}/move?moveDirection={moveDirection}";
  public static API_USER_OFFERINGS_PATH_STEP_SUB_STEPS_EDIT_LIST =
    API_BASE +
    "/{userId}/offerings/paths/{offeringId}/steps/{stepId}/steps:editList";

  public static PAGE_USER_OFFERING_PATH =
    "/u/:userId/offering/paths/:offeringId";

  public static PAGE_USER_OFFERING_PATH_CONTENT_EDIT =
    "/u/:userId/offering/paths/:offeringId/content/edit";
  public static PAGE_USER_OFFERING_PATH_CONTENT_VIEW =
    "/u/:postedById/offering/paths/:offeringId/content/view";

  public static API_USER_OFFERINGS_PATH_STEP_MARK_COMPLETE =
    API_BASE +
    "/{postedById}/offerings/paths/{offeringId}/steps/{stepId}/complete";
  public static API_USER_OFFERINGS_PATH_STEP_MARK_INCOMPLETE =
    API_BASE +
    "/{postedById}/offerings/paths/{offeringId}/steps/{stepId}/incomplete";
}
