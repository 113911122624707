import React from "react";
import { ApiLinkedButtonV3 } from "../components/common/Form/ApiLinked.button.V3";
import { reloadWindowPromise } from "../components/common/reloadCallback";
import { BookingApi } from "./Booking.Api";
import { Booking } from "./Booking.model";

interface BookingEngineMarkCompleteButtonProps {
  className?: string;
  booking: Booking;
}

export function BookingEngineMarkCompleteButton(
  props: BookingEngineMarkCompleteButtonProps
) {
  return (
    <span className={`BookingEngineMarkCompleteButton`}>
      {props.booking.bcd.shouldShowEngineCompletionButton && (
        <ApiLinkedButtonV3
          className={props.className}
          uniqueKey={`booking-${props.booking.id}-engine-mark-complete`}
          buttonProps={{
            content: { normal: "Mark Completed" },
          }}
          api={BookingApi.markComplete(
            props.booking.bookingTarget,
            props.booking.bcd.engineId
          )}
          successCallback={reloadWindowPromise}
          apiFormBody={
            <div>
              Are you sure that you want to mark this booking as completed?
            </div>
          }
          formModalSize="sm"
        />
      )}
    </span>
  );
}
