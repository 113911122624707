import React, { useEffect, useState } from "react";
import { ReactMatch } from "../../model/ReactMatchObj";
import { PageProps } from "../../model/views/PageProps";
import {
  OfferingFetchContext,
  OfferingPath,
  OfferingTypeName,
} from "../Offering";
import { OfferingAPI } from "../Offering.api";
import { OfferingsPathStepsViewPageContent } from "./Offerings.Path.Steps.View.PageContent";

interface OfferingsPathContentViewPageContentProps extends PageProps {
  match: ReactMatch<OfferingsPathContentViewPageContentPathParams>;
}

interface OfferingsPathContentViewPageContentPathParams {
  offeringId: string;
  postedById: string;
}

export function OfferingsPathContentViewPageContent(
  props: OfferingsPathContentViewPageContentProps
) {
  const [offering, setOffering] = useState<OfferingPath>();
  useEffect(() => {
    OfferingAPI.get(
      OfferingTypeName.PATH,
      props.match.params.offeringId,
      OfferingFetchContext.PUBLIC
    )
      .executeV2()
      .then((offering) => {
        setOffering(offering);
      });
  }, []);
  return (
    <span className="OfferingsPathContentViewPageContent">
      {offering && props.user && (
        <div className="d-flex flex-column h-100">
          <div className="border-bottom w-100 py-3 px-4">
            <div className="col-auto m-0 p-0">
              <h1 className="h4 m-0 p-0">Path: {offering.commonDetails.dl}</h1>
            </div>
          </div>
          <div className="flex-grow-1">
            <OfferingsPathStepsViewPageContent
              user={props.user}
              offeringId={props.match.params.offeringId}
              postedById={props.match.params.postedById}
              offering={offering}
            />
          </div>
        </div>
      )}
    </span>
  );
}
