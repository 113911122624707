import React from "react";
import { Api } from "./ApiLinkedButton";
import { EBButtonV2Props } from "./EBButtonV2";
import { ApiLinkedButtonV3 } from "./Form/ApiLinked.button.V3";
import { EBTextAreaV2 } from "./Form/FormElements";

export interface ReviewData {
  comments?: string;
}

interface ReviewButtonProps {
  buttonProps: EBButtonV2Props;
  api: Api<ReviewData, any>;
  uniqueKey: string;

  successCallback?: (response: any) => Promise<any>;
}

export function ReviewButton(mainProps: ReviewButtonProps) {
  return (
    <>
      <ApiLinkedButtonV3
        apiFormBody={<EBTextAreaV2 label={"Comments?"} name={"comments"} />}
        formModalSize="md"
        buttonProps={mainProps.buttonProps}
        api={mainProps.api}
        uniqueKey={mainProps.uniqueKey}
        successCallback={mainProps.successCallback}
      />
    </>
  );
}
