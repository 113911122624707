import { Field } from "formik";
import React, { useEffect, useState } from "react";
import { EBTextAreaV2 } from "../components/common/Form/FormElements";
import { Topic } from "../topic/Topic.model";
import { TopicAPI } from "../topic/TopicAPI";
import { TopicSelector } from "../topic/TopicSelector.component";

export function AskQuestionForm() {
  let [allTopics, setAllTopics] = useState<Topic[]>();
  useEffect(() => {
    TopicAPI.getListingTopics().then((allTopics) => {
      setAllTopics(allTopics);
    });
  }, []);
  return (
    <div className="AskQuestionForm">
      <input type="hidden" name="id" />
      <div className="row">
        <div className="col">
          <EBTextAreaV2
            label="Title"
            name="title"
            required
            placeholder="Ask your question starting with 'What', 'How', 'Why', etc."
            helpText=""
            maxLength={200}
            showCount={true}
            enablePerformance={true}
          />
        </div>
      </div>
      <div className="row pt-2">
        <div className="col">
          {allTopics && (
            <>
              <TopicSelector
                label="Topics:"
                topics={allTopics}
                name={"topics"}
                isMulti={true}
                required={true}
              />
            </>
          )}
        </div>
      </div>
      <div className="row pt-4">
        <div className="col text-end">
          <Field
            type="checkbox"
            id="isAnon"
            className="custom-checkbox custom-control-input"
            name="isAnon"
          />
          <label className="custom-control-label" htmlFor="isAnon">
            Anonymous?
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col pt-4">
          <p className="card-header border-bottom-0 font-weight-bold">
            Use clear language, proper grammar and provide specific detail and
            context of the question.
          </p>
        </div>
      </div>
    </div>
  );
}
