import { Row } from "antd";
import React, { useEffect, useState } from "react";
import { CompaniesBadgeList } from "../badge/Companies.BadgeList";
import { CompanyImage } from "../company/Company.Image";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { ImageComponent } from "../components/common/Image.component";
import { PriceView } from "../components/common/Price.view";
import { RatingsSummaryView } from "../components/common/RatingsSummaryView";
import userHelpers from "../helpers/userHelpers";
import { HTMLProps } from "../model/HTMLProps";
import { UserBasicDetails, UserSummary } from "./User";
import { UserAPI } from "./UserAPI";
import { UserCardSkillsList } from "./card/UserCard.Skills.List";
import { UserCardTopRightBadge } from "./card/UserCard.TopRight.Badge";

interface UserProfileSidebarProps {
  userId: string;
  user?: UserBasicDetails;
  title?: string;
}

export function UserProfileSidebar(props: UserProfileSidebarProps) {
  let [user, setUser] = useState<UserBasicDetails>();
  useEffect(() => {
    if (props.user) {
      setUser(props.user);
    } else {
      UserAPI.fetchBasicUserDetails(props.userId, true).then((user) => {
        setUser(user);
      });
    }
  }, []);

  return (
    <div className="UserSidebar">
      {user && (
        <>
          {props.title}
          <UserSidebarProfile user={user} />
        </>
      )}
      {/* <ExpertCardMedium /> */}
    </div>
  );
}

interface UserSidebarProfileProps extends HTMLProps {
  user: UserBasicDetails;
}

export function UserSidebarProfile(props: UserSidebarProfileProps) {
  var user = props.user;
  const latestExp = user.latestExperience;

  return (
    <div className={`UserSidebarProfile ${props.className} `}>
      <a
        href={user.profileUrl}
        className="text-dark "
        style={{ textDecoration: "none" }}
      >
        <div
          className={`card card-body text-center grid-card ${
            user.expertDetails?.isTopExpert &&
            "border-primary border grid-card-primary"
          }`}
        >
          <UserCardTopRightBadge user={user} />

          <div className="media text-start">
            <img
              className="mr-3 rounded border border-warning overflow-hidden"
              src={userHelpers.getProfilePicUrl(user)}
              alt="Generic placeholder image"
              width={65}
            />
            <div className="media-body">
              <h5 className="mt-0 pb-0 mb-0">
                {user.dl}{" "}
                {user.experienceDurationInDays !== undefined &&
                  user.experienceDurationInDays > 0 && (
                    <span className=" small">
                      (
                      {Math.floor(user.experienceDurationInDays / 365).toFixed(
                        0
                      )}
                      + years)
                    </span>
                  )}{" "}
                {user.country?.dp && <ImageComponent image={user.country.dp} />}
              </h5>
              {user.username && `@${user.username} `}
              <div className="mt-1">
                {latestExp && (
                  <span className="mr-1">
                    {`${latestExp.designation.dl} `}-{" "}
                    <CompanyImage
                      company={latestExp.company}
                      width={14}
                      className="mr-1 rounded-circle mb-1"
                    />
                    {`${latestExp.company.dl}`}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="">
            {user.engineSummary && (
              <div className="text-center mt-3 card-text">
                <UserSummaryComponent
                  engineSummary={user.engineSummary}
                  center={true}
                />
              </div>
            )}
            {((user.skills && user.skills.length > 0) ||
              (user.pastCompanies && user.pastCompanies.length > 0)) && (
              <p className="mt-2">
                <UserCardSkillsList user={user} />
                <br></br>
                {user.pastCompanies && user.pastCompanies.length > 0 && (
                  <CompaniesBadgeList
                    companies={user.pastCompanies}
                    viewLimit={3}
                  />
                )}
              </p>
            )}
            {user.tagline ? (
              <p className="text-secondary mb-1 mt-2">{user.tagline}</p>
            ) : null}
          </div>
          {user.expertDetails?.minPrice && (
            <Row justify="center">
              <EBButtonV2
                content={{
                  normal: (
                    <>
                      Book @{" "}
                      <PriceView
                        price={user.expertDetails.minPrice}
                        showFree={true}
                      />
                    </>
                  ),
                }}
              />
            </Row>
          )}
        </div>
      </a>
    </div>
  );
}

interface UserSummaryComponentProps {
  engineSummary: UserSummary;
  className?: string;
  center?: boolean;
}

export function UserSummaryComponent(props: UserSummaryComponentProps) {
  return (
    <div className="hstack gap-3">
      {props.engineSummary.ratingsSummary?.expertAvgRating ? (
        <div className={`${props.className} ${props.center && "ms-auto"}`}>
          <RatingsSummaryView
            ratingsSummary={props.engineSummary.ratingsSummary}
          />
        </div>
      ) : null}
      {props.engineSummary.numBookings !== undefined &&
        props.engineSummary.numBookings > 0 && (
          <div className={`${props.className} ${props.center && "me-auto"}`}>
            <span className="text-warning">
              <i className="fas fa-rocket"></i>
            </span>{" "}
            {props.engineSummary.numBookings}
            {props.engineSummary.numBookings === 1 ? " Booking" : " Bookings"}
          </div>
        )}
    </div>
  );
}
