import { Api, ApiMethod } from "../../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../../constants";
import { SessionType } from "../Offering";

export class OfferingVideoSessionAPI {
  public static getVideoSessionTypes(): Promise<SessionType[]> {
    let api = apiLinkConstants.API_VIDEO_SESSION_TYPE_LIST;
    return new Api<void, SessionType[]>(api, ApiMethod.GET).execute();
  }
}
