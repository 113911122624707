import React from "react";
import { MarkdownViewV1 } from "../../../components/common/MarkdownView";
import { ContentItemDataMarkdown } from "../../Content.model";
import { ContentItemViewProps } from "../ContentItem.model";

export function ContentItemViewMarkdown(props: ContentItemViewProps) {
  let mdData = props.data as ContentItemDataMarkdown;

  return (
    <div className="ContentItemViewMarkdown">
      {mdData.md && <MarkdownViewV1>{mdData.md}</MarkdownViewV1>}
    </div>
  );
}
