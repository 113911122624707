import React, { useEffect, useState } from "react";
import { UserContributionsAPI } from "../api/UserContributionsAPI";

import { Col, Row } from "antd";
import { ImageComponent } from "../components/common/Image.component";
import { MarkdownView } from "../components/common/MarkdownView";
import { Center } from "../components/common/layout/Center.component";
import { EventApi } from "../events/Event.Api";
import { EventV2 } from "../events/Event.Model";
import { LeftAndMainLayout } from "../layout/LeftAndMain.Layout";
import { Link } from "../links/Link";
import { List } from "../list/List";
import { ListItemLayoutDefault } from "../list/ListItem.Layout";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { HTMLProps } from "../model/HTMLProps";
import { FetchContext } from "../model/enums/FetchContext.enum";
import { Offerings } from "../offerings/Offering";
import { OfferingAPI } from "../offerings/Offering.api";
import { OfferingCardV2Grid } from "../offerings/OfferingCardV2.Grid";
import { RelatedExpertsComponent } from "../promotion/RelatedExperts.Component";
import {
  TargetInputOption,
  TargetOptionsSelect,
} from "../target/Target.OptionsSelect";
import { UserExperienceListItem } from "../user-experience/UserExperience.List";
import { UserAdminActionsComponent } from "../users/UserAdminActions.Component";
import { ReviewsComponent } from "./Reviews.component";
import { User, UserBasicDetails } from "./User";
import { UserContributions } from "./UserContributions";
import { UserInterviewExperienceListItem } from "./UserInterviewExperience.List";
import { UserSummaryComponent } from "./UserProfile.Sidebar";
import { EngineSummaryCard } from "./card/EngineSummary.Card";
import { UserCardTopRightBadge } from "./card/UserCard.TopRight.Badge";

interface UserFullProfileContentProps {
  userToShow: UserBasicDetails;
  loggedInUser?: User;
  // SectionComponents: React.ComponentType<UserFullProfileSectionProps>[];
}

export function UserFullProfileContent(props: UserFullProfileContentProps) {
  let [offerings, setOfferings] = useState<Offerings>({});
  let [contributions, setContributions] = useState<UserContributions>();
  let [offeringEvents, setOfferingEvents] = useState<EventV2[]>();

  let userToShow = props.userToShow;

  useEffect(() => {
    UserContributionsAPI.getUserContributions(userToShow.id).then(
      (contributions) => {
        setContributions(contributions);
      }
    );

    OfferingAPI.getOfferings(userToShow.id, { reviews: true }).then(
      (offerings) => {
        setOfferings(offerings);
      }
    );

    EventApi.getEvents(userToShow.id, FetchContext.PUBLIC).then((events) => {
      setOfferingEvents(events);
    });
  }, []);

  return (
    <div className="UserFullProfileContent">
      {props.loggedInUser?.basicDetails.isAdmin && (
        <UserAdminActionsComponent user={{ basicDetails: props.userToShow }} />
      )}
      <LeftAndMainLayout
        left={
          <div className="pl-3 mob-px-3">
            <UserFullProfileLeftContent
              {...props}
              offerings={offerings}
              offeringEvents={offeringEvents}
              contributions={contributions}
            />
          </div>
        }
        main={
          <div className="vstack gap-4 mob-pt-3">
            {(offerings.videoSessions ? offerings.videoSessions.length : 0) +
              (offerings.offlineQueries ? offerings.offlineQueries.length : 0) +
              (offerings.products ? offerings.products.length : 0) +
              (offerings.paths ? offerings.paths.length : 0) +
              (offeringEvents ? offeringEvents.length : 0) >
              0 && (
              <UserFullProfileSectionOfferings
                {...props}
                offerings={offerings}
                offeringEvents={offeringEvents}
              />
            )}
            {(userToShow.aboutMe ||
              (userToShow.socialLinks &&
                userToShow.socialLinks.length > 0)) && (
              <UserFullProfileSectionAbout
                {...props}
                offerings={offerings}
                offeringEvents={offeringEvents}
                className="px-3"
              />
            )}

            {userToShow.engineSummary?.ratingsSummary &&
              userToShow.engineSummary?.ratingsSummary.ratingsCount > 0 && (
                <UserFullProfileSectionReviews
                  {...props}
                  offerings={offerings}
                  offeringEvents={offeringEvents}
                  className="px-3"
                />
              )}

            <div className="px-3">
              <RelatedExpertsComponent
                // className=" pl-3 mob-pl-0"
                context={{
                  topics: userToShow.skills,
                  excludedItemIds: [userToShow.id],
                }}
                horizontalLayout={true}
                horizontalSpan={{ xs: 24, xl: 12 }}
                className="mb-4 shadow-sm card card-body border border-1 rounded-4 bg-transparent bg-highlight"
                header={<div className="text-muted mb-2">More experts:</div>}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

function UserFullProfileLeftContent(props: UserFullProfileSectionProps) {
  let userToShow = props.userToShow;

  return (
    <div className="vstack gap-3">
      <div className="card ">
        <div className="card-body">
          <UserCardTopRightBadge user={userToShow} />

          <div className="d-flex flex-column align-items-center text-center">
            {userToShow.dp && (
              <ImageComponent
                image={userToShow.dp}
                className={`rounded-circle ${
                  userToShow.expertDetails?.isTopExpert &&
                  "border-primary border shadow-primary"
                }`}
                width={150}
                height={150}
              />
            )}

            <div className="mt-3">
              <div className="mb-3">
                <h1 className="h3 mb-0">
                  {userToShow.dl}{" "}
                  {userToShow.country?.dp && (
                    <ImageComponent image={userToShow.country.dp} />
                  )}
                </h1>
                {userToShow.username && `@${userToShow.username}`}
              </div>
              {userToShow.engineSummary && (
                <Center>
                  <UserSummaryComponent
                    engineSummary={userToShow.engineSummary}
                    className="mt-2"
                  />
                </Center>
              )}

              <p>
                <p className="mt-2">
                  {userToShow.skills &&
                    userToShow.skills.map((skill, index) => (
                      <span
                        className="badge bg-light-yellow mr-1 px-1"
                        key={skill.id}
                      >
                        {skill.dl}
                      </span>
                    ))}
                </p>
              </p>
              {userToShow.tagline ? (
                <p className="text-secondary mb-0 mt-2">{userToShow.tagline}</p>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {userToShow.engineSummary && (
        <EngineSummaryCard es={userToShow.engineSummary} />
      )}

      {userToShow.experienceDurationInDays != undefined &&
        userToShow.experienceDurationInDays > 0 && (
          <div className="card ">
            <h5 className="px-3 pt-3">
              Experience:{" "}
              {userToShow.experienceDurationInDays && (
                <span className=" small">
                  (
                  {Math.floor(
                    userToShow.experienceDurationInDays / 365
                  ).toFixed(0)}
                  + years)
                </span>
              )}
            </h5>

            {userToShow.experiences && userToShow.experiences.length > 0 && (
              <List
                onFetch={() => {
                  return new Promise((resolve, reject) => {
                    resolve(userToShow.experiences!);
                  });
                }}
                ItemComponent={UserExperienceListItem}
                emptyListContent="Not found"
                ItemLayoutComponent={ListItemLayoutDefault}
              />
            )}
          </div>
        )}

      {props.contributions?.userInterviewExperiences &&
        props.contributions.userInterviewExperiences.length > 0 && (
          <div className="card">
            <h5 className="px-3 pt-3">Interview Experiences Shared:</h5>
            <List
              onFetch={() => {
                return new Promise((resolve, reject) => {
                  resolve(props.contributions!.userInterviewExperiences!);
                });
              }}
              ItemComponent={UserInterviewExperienceListItem}
              emptyListContent="Not found"
              ItemLayoutComponent={ListItemLayoutDefault}
            />
          </div>
        )}
    </div>
  );
}

export interface UserFullProfileSectionProps extends HTMLProps {
  userToShow: UserBasicDetails;
  offerings: Offerings;
  offeringEvents?: EventV2[];
  contributions?: UserContributions;
  loggedInUser?: User;
}

export function UserFullProfileSectionOfferings(
  props: UserFullProfileSectionProps
) {
  let [selectedOptions, setSelectedOptions] =
    useState<AttachmentTargetType[]>();

  let offerings = props.offerings;

  let filterOptions: TargetInputOption[] = [];
  if (offerings.videoSessions && offerings.videoSessions.length > 0) {
    filterOptions.push({
      type: AttachmentTargetType.VIDEO_SESSION,
      suffix: "(" + offerings.videoSessions.length + ")",
    });
  }
  if (offerings.offlineQueries && offerings.offlineQueries.length > 0) {
    filterOptions.push({
      type: AttachmentTargetType.TEXT_QUERY,
      suffix: "(" + offerings.offlineQueries.length + ")",
    });
  }
  if (offerings.products && offerings.products.length > 0) {
    filterOptions.push({
      type: AttachmentTargetType.DIGITAL_PRODUCT,
      suffix: "(" + offerings.products.length + ")",
    });
  }
  if (offerings.paths && offerings.paths.length > 0) {
    filterOptions.push({
      type: AttachmentTargetType.BOOKING_PATH,
      suffix: "(" + offerings.paths.length + ")",
    });
  }

  let offeringSections = [];
  if (
    !selectedOptions ||
    selectedOptions.length == 0 ||
    selectedOptions.includes(AttachmentTargetType.VIDEO_SESSION)
  ) {
    offeringSections.push({ offerings: offerings.videoSessions });
  }
  if (
    !selectedOptions ||
    selectedOptions.length == 0 ||
    selectedOptions.includes(AttachmentTargetType.TEXT_QUERY)
  ) {
    offeringSections.push({ offerings: offerings.offlineQueries });
  }
  if (
    !selectedOptions ||
    selectedOptions.length == 0 ||
    selectedOptions.includes(AttachmentTargetType.DIGITAL_PRODUCT)
  ) {
    offeringSections.push({ offerings: offerings.products });
  }
  if (
    !selectedOptions ||
    selectedOptions.length == 0 ||
    selectedOptions.includes(AttachmentTargetType.BOOKING_PATH)
  ) {
    offeringSections.push({ offerings: offerings.paths });
  }

  return (
    <div className={props.className}>
      {filterOptions && filterOptions.length > 1 && (
        <div className="bg-main-body py-3 px-3 border-bottom mb-3 position-sticky top--1px z-100 mt--16px">
          <TargetOptionsSelect
            targetOptions={filterOptions}
            onSelectionUpdate={function (
              selectedOptions: AttachmentTargetType[]
            ): Promise<void> {
              setSelectedOptions(selectedOptions);
              return Promise.resolve();
            }}
            className="no-scroll-bar "
          />
        </div>
      )}

      <Row gutter={[20, 20]} className="px-3">
        {[
          ...offeringSections,
          {
            offerings: props.offeringEvents,
          },
        ].map((item, index) => (
          <>
            {item.offerings && item.offerings?.length > 0 && (
              <Col md={24} xl={item.offerings.length > 1 ? 24 : 12} key={index}>
                <OfferingCardV2Grid
                  offeringsV2={item.offerings}
                  loggedInUser={props.loggedInUser}
                  dl={item.offerings[0].commonDetails.type.dl}
                  itemColXl={item.offerings.length > 1 ? 12 : 24}
                />
              </Col>
            )}
          </>
        ))}
      </Row>
    </div>
  );
}

export function UserFullProfileSectionAbout(
  props: UserFullProfileSectionProps
) {
  return (
    <div className={props.className}>
      <div className="card">
        <div className="card-body">
          <div className="h5">About</div>
          {props.userToShow.aboutMe && (
            <MarkdownView text={props.userToShow.aboutMe} />
          )}

          <div className="hstack gap-2 overflow-auto mt-5">
            {props.userToShow.socialLinks &&
              props.userToShow.socialLinks.map((socialLink, index) => (
                <Link
                  target="_blank"
                  href={socialLink.url}
                  className="text-dark fs-4 mr-1"
                  key={index}
                >
                  <div className="border px-3 py-2 border-warning rounded">
                    <ImageComponent image={socialLink.type.dp} />
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function UserFullProfileSectionReviews(
  props: UserFullProfileSectionProps
) {
  return (
    <div className={props.className}>
      <ReviewsComponent userId={props.userToShow.id} />
    </div>
  );
}
