import { Col, Row } from "antd";
import React from "react";
import { DigitalProductDl, TextQueryDl, VideoSessionDl } from "../Global.Names";
import { ImageComponent } from "../components/common/Image.component";
import { WindowHelpers } from "../helpers/Window.helper";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { AssetLocation, LocationType } from "../model/Picture";
import { HomePageSection, HomePageSectionProps } from "./HomePage.Section";

interface OfferingType {
  id: string;
  type: AttachmentTargetType;
  dl: React.ReactNode;
  dd: React.ReactNode;
  dp: AssetLocation;
}

export function HomePageOfferingTypesSection(props: HomePageSectionProps) {
  let types: OfferingType[] = [
    {
      id: "1",
      type: AttachmentTargetType.OFFERING_VIDEO_SESSION,
      dl: <>{VideoSessionDl.SENTENCE_START_PLURAL}</>,
      dd: (
        <>
          Engage in personalized learning experiences with live 1:1 video calls.
          Whether you're sharing expertise or soaking up knowledge, these
          sessions offer a direct line to personal growth and tailored advice,
          fostering meaningful connections between experts and learners.
        </>
      ),
      dp: {
        locationType: LocationType.URL,
        data: "https://eb-pb.s3.us-east-2.amazonaws.com/static/ot-video.jpg",
      },
    },
    {
      id: "2",
      type: AttachmentTargetType.OFFERING_TEXT_QUERY,
      dl: <>{TextQueryDl.SENTENCE_START_PLURAL}</>,
      dd: (
        <>
          Keep the conversation going with our offline text query feature.
          Whether you're seeking further clarification or offering extended
          support, this service ensures that learning never stops, bridging the
          gap between scheduled sessions.
        </>
      ),
      dp: {
        locationType: LocationType.URL,
        data: "https://eb-pb.s3.us-east-2.amazonaws.com/static/ot-tq.jpg",
      },
    },
    {
      id: "3",
      type: AttachmentTargetType.OFFERING_DIGITAL_PRODUCT,
      dl: <>{DigitalProductDl.SENTENCE_START_PLURAL}</>,
      dd: (
        <>
          Enhance your learning or teaching toolkit with our range of digital
          products. From in-depth guides to comprehensive e-books, these
          resources are crafted to support ongoing education and offer valuable
          insights, accessible anytime and anywhere.
        </>
      ),
      dp: {
        locationType: LocationType.URL,
        data: "https://eb-pb.s3.us-east-2.amazonaws.com/static/ot-dp.jpg",
      },
    },
    {
      id: "4",
      type: AttachmentTargetType.OFFERING_PATH,
      dl: <>Webinars</>,
      dd: (
        <>
          Join the community in interactive webinars led by industry experts.
          These sessions are a hotspot for live learning, discussion, and
          discovery, offering a dynamic platform for experts to share insights
          and learners to deepen their understanding of key topics.
        </>
      ),
      dp: {
        locationType: LocationType.URL,
        data: "https://eb-pb.s3.us-east-2.amazonaws.com/static/ot-webinar.jpg",
      },
    },
    {
      id: "5",
      type: AttachmentTargetType.OFFERING_PATH,
      dl: <>Live & Recorded Courses</>,
      dd: (
        <>
          Dive into a world of knowledge with our diverse range of live and
          recorded courses. Catering to a variety of learning styles and
          schedules, these courses offer both experts and learners the
          flexibility to teach, learn, and explore at their own pace.
        </>
      ),
      dp: {
        locationType: LocationType.URL,
        data: "https://eb-pb.s3.us-east-2.amazonaws.com/static/ot-course.jpg",
      },
    },
  ];

  return (
    <HomePageSection
      className="HomePageTopExpertsSection bg-white"
      id="home-offering-types"
      headingStartIcon={<i className="fa-solid fa-arrow-up-right-dots"></i>}
      heading={<>Our Offerings Tailored for Your Success</>}
      subheading={
        <>Take your first step towards a great career, with proven results.</>
      }
    >
      <div className="container">
        {types.map((type, index) => (
          <Row align={"middle"} className="mt-5" key={index}>
            {(index % 2 == 0 || WindowHelpers.isMobile()) && (
              <>
                <Col
                  span={WindowHelpers.isMobile() ? 24 : 12}
                  className={`${WindowHelpers.isMobile() && "text-center"} ${
                    !WindowHelpers.isMobile() && "pr-4"
                  }`}
                >
                  <div className={`h1`}>{type.dl}</div>

                  <div className="text-muted fs-14 fw-normal mb-3">
                    {type.dd}
                  </div>
                </Col>
                <Col
                  span={WindowHelpers.isMobile() ? 24 : 12}
                  className={`${WindowHelpers.isMobile() && "text-center"} ${
                    !WindowHelpers.isMobile() && "pl-4"
                  }`}
                >
                  <ImageComponent image={type.dp} maxWidth={"100%"} />
                </Col>
              </>
            )}
            {index % 2 == 1 && !WindowHelpers.isMobile() && (
              <>
                <Col span={12} className="pr-4">
                  <ImageComponent image={type.dp} maxWidth={"100%"} />
                </Col>
                <Col span={12} className="pl-4">
                  <div className="h1">{type.dl}</div>

                  <div className="text-muted fs-14 fw-normal mb-3">
                    {type.dd}
                  </div>
                </Col>
              </>
            )}
          </Row>
        ))}
      </div>
    </HomePageSection>
  );
}
