import { ArrayHelper } from "../../helpers/Array.Helper";
import { MoveDirectionEnum, PathStep } from "./Offering.Path.Model";

export class PathStepHelper {
  public static searchStepById(
    steps: PathStep[],
    id: number
  ): PathStep | undefined {
    if (!steps) {
      return undefined;
    }
    for (let step of steps) {
      if (step.id === id) {
        return step;
      }
      if (step.steps) {
        let deepSearchItem = PathStepHelper.searchStepById(step.steps, id);
        if (deepSearchItem) {
          return deepSearchItem;
        }
      }
    }
    return undefined;
  }

  public static updateStep(steps: PathStep[], updatedStep: PathStep): void {
    if (!steps) {
      return undefined;
    }
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].id === updatedStep.id) {
        updatedStep.steps = steps[i].steps;
        steps[i] = updatedStep;
      }
      let subSteps = steps[i].steps;
      if (subSteps) {
        PathStepHelper.updateStep(subSteps, updatedStep);
      }
    }
  }

  public static deleteStep(
    steps: PathStep[],
    deletedStep: PathStep
  ): PathStep[] {
    let response: PathStep[] = [];
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].id !== deletedStep.id) {
        response.push(steps[i]);
      }
      let subSteps = steps[i].steps;
      if (subSteps) {
        steps[i].steps = PathStepHelper.deleteStep(subSteps, deletedStep);
      }
    }
    return response;
  }

  public static moveStep(
    steps: PathStep[],
    targetStep: PathStep,
    direction: MoveDirectionEnum
  ): void {
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].id === targetStep.id) {
        let targetIndex = direction == MoveDirectionEnum.UP ? i - 1 : i + 1;
        targetIndex = Math.max(0, targetIndex);
        targetIndex = Math.min(steps.length - 1, targetIndex);
        ArrayHelper.move(steps, i, targetIndex);
        return;
      }
      let subSteps = steps[i].steps;
      if (subSteps) {
        PathStepHelper.moveStep(subSteps, targetStep, direction);
      }
    }
  }

  public static nextStep(
    steps: PathStep[],
    fromStep: PathStep
  ): PathStep | undefined {
    let state = { found: false, nextStep: undefined };
    this._nextStep(steps, fromStep, state);
    return state.nextStep;
  }

  private static _nextStep(
    steps: PathStep[],
    fromStep: PathStep,
    state: { found: boolean; nextStep: PathStep | undefined }
  ) {
    if (state.found && state.nextStep) {
      return;
    }
    for (let i = 0; i < steps.length; i++) {
      const curStep = steps[i];

      if (state.found && !state.nextStep) {
        state.nextStep = curStep;
      }
      if (curStep.id === fromStep.id) {
        state.found = true;
      }
      let subSteps = curStep.steps;
      if (subSteps) {
        PathStepHelper._nextStep(subSteps, fromStep, state);
      }
    }
  }

  public static prevStep(
    steps: PathStep[],
    targetStep: PathStep
  ): PathStep | undefined {
    let state = {
      targetFound: false,
      prev: undefined,
      lastVisited: undefined,
    };
    this._prevStep(steps, targetStep, state);
    return state.prev;
  }

  private static _prevStep(
    steps: PathStep[],
    target: PathStep,
    state: {
      targetFound: boolean;
      prev: PathStep | undefined;
      lastVisited: PathStep | undefined;
    }
  ): PathStep | undefined {
    if (state.targetFound) {
      return;
    }
    for (let i = 0; i < steps.length; i++) {
      const curStep = steps[i];
      if (curStep.id === target.id) {
        state.targetFound = true;
        return;
      }
      if (!state.targetFound) {
        state.prev = curStep;
      }
      let subSteps = curStep.steps;
      if (subSteps) {
        PathStepHelper._prevStep(subSteps, target, state);
      }
    }
    return undefined;
  }
}
