import React from "react";
import Moment from "react-moment";
import { apiLinkConstants } from "../../../constants";
import { AttachmentHelper } from "../../../helpers/Attachments.helper";
import { UserHelpers } from "../../../helpers/Helpers";
import { InterviewRound } from "../../../model/InterviewRound";
import { User } from "../../../user/User";
import { reloadWindowPromise } from "../reloadCallback";
import { AttachmentView } from "./AttachmentView";

export interface InterviewRoundViewProps {
  interview: InterviewRound;
  user: User;
}
export function InterviewRoundView(props: InterviewRoundViewProps) {
  let interviewRoundAttachmentsUpdateLink =
    apiLinkConstants.INTERVIEW_ROUND_UPDATE_ATTACHMENT.replace(
      "{interviewRoundId}",
      props.interview.id
    );
  return (
    <div>
      {!AttachmentHelper.isAttachmentPresent(props.interview.location) && (
        <div className="alert alert-danger" role="alert">
          <i className="fas fa-exclamation-triangle"></i> Please add meeting
          link for this interview. It is missing right now.
        </div>
      )}
      {props.interview.scheduledAt &&
        !AttachmentHelper.isAttachmentPresent(props.interview.recording) && (
          <div className="alert alert-danger" role="alert">
            <i className="fas fa-exclamation-triangle"></i> Please add recording
            for this interview. It is missing right now.
          </div>
        )}
      <div className="card">
        <div className="card-header">
          Please find the details of this interview below
        </div>
        <div className="card-body">
          <div>
            Interviewer:{" "}
            {props.interview.interviewer &&
              UserHelpers.getUserFullName(props.interview.interviewer)}
          </div>
          <div>
            Interview Date and Time:{" "}
            {props.interview.startTimestamp ? (
              <Moment format="DD MMM YYYY - hh:mm a">
                {props.interview.startTimestamp}
              </Moment>
            ) : (
              "Not found"
            )}
          </div>
          <div>
            Interview Duration: {props.interview.durationInMinutes} Minutes
          </div>

          <div className="hstack gap-2">
            Resume:
            <AttachmentView
              attachment={props.interview.resume}
              attachmentDisplayName="Resume"
              uniqueKey={"ir-" + props.interview.id + "-candidate-resume"}
            />
          </div>

          <div className="hstack gap-2">
            Feedback:
            <AttachmentView
              attachment={props.interview.interviewerFeedback}
              attachmentDisplayName="Feedback"
              uniqueKey={"ir-" + props.interview.id + "-interviewerFeedback"}
            />
          </div>

          <div className="hstack gap-2">
            Interview Guidelines:
            <AttachmentView
              attachment={props.interview.guideline}
              attachmentDisplayName="Guidelines"
              uniqueKey={"ir-" + props.interview.id + "-guideline"}
            />
          </div>

          <div className="hstack gap-2">
            Meeting Link:
            <AttachmentView
              attachment={props.interview.location}
              attachmentDisplayName="Meeting Link"
              attachmentsUpdateEndpoint={
                props.interview.scheduledAt
                  ? undefined
                  : interviewRoundAttachmentsUpdateLink
              }
              updateCallback={reloadWindowPromise}
              uniqueKey={"ir-" + props.interview.id + "-location"}
            />
          </div>

          {props.interview.scheduledAt && (
            <div className="hstack gap-2">
              Recording:
              <AttachmentView
                attachment={props.interview.recording}
                attachmentDisplayName="Recording"
                attachmentsUpdateEndpoint={
                  props.interview.scheduledAt && !props.interview.completedAt
                    ? interviewRoundAttachmentsUpdateLink
                    : undefined
                }
                updateCallback={reloadWindowPromise}
                uniqueKey={"ir-" + props.interview.id + "-recording"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
