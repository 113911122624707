import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import Moment from "react-moment";
import { TimestampView } from "../components/common/TimestampView";
import { HTMLProps } from "../model/HTMLProps";
import { StatusDl } from "./Status.model";

interface StatusComponentProps extends HTMLProps {
  status: StatusDl;
}
export function StatusComponent(props: StatusComponentProps) {
  return (
    <span
      className={`StatusComponent ${props.className}`}
      data-tag="allowRowEvents"
    >
      {props.status.dl}
      {` `}
      {props.status.bracketDate && (
        <span className="small text-muted">
          (<TimestampView timestamp={props.status.bracketDate} tzInToolTip />)
        </span>
      )}
      {props.status.tooltipDate && (
        <Tooltip
          title={
            <>
              <Moment format="DD-MM-YYYY HH:mm">
                {props.status.tooltipDate}
              </Moment>
            </>
          }
        >
          <InfoCircleOutlined />
          {/* <>
            <Moment format="DD-MM-YYYY HH:mm">
              {props.status.tooltipDate}
            </Moment>
          </> */}
        </Tooltip>
      )}
    </span>
  );
}
