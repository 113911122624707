import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import {
  Api,
  ApiLinkedButton,
  ApiMethod,
} from "../components/common/ApiLinkedButton";
import { EBButton } from "../components/common/EBButton";
import { EditTitle } from "../components/common/Edit.title";
import { Mode } from "../components/common/Form/model/mode.model";
import { Loader } from "../components/common/Loader.component";
import { Center } from "../components/common/layout/Center.component";
import { reloadWindowPromise } from "../components/common/reloadCallback";
import { apiLinkConstants, publicLinkConstants } from "../constants";
import { WindowHelpers } from "../helpers/Window.helper";
import { Offerings } from "../offerings/Offering";
import { OfferingAPI } from "../offerings/Offering.api";
import { UserBasicDetails } from "../user/User";
import { AddOrEditCouponForm } from "./AddOrEditCoupon.component";
import { CouponApi } from "./Coupon.api";
import { UserCoupon } from "./Coupon.model";

interface UserCouponsEditComponentProps {
  user: UserBasicDetails;
}

export function UserCouponsEditComponent(props: UserCouponsEditComponentProps) {
  let [offerings, setOfferings] = useState<Offerings>();
  let [coupons, setCoupons] = useState<UserCoupon[]>();
  let [loading, setLoading] = useState<boolean>();

  function fetchUserCoupons() {
    setLoading(true);
    CouponApi.getUserCoupons(props.user.id)
      .then((userCoupons) => {
        setCoupons(userCoupons);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchUserCoupons();

    OfferingAPI.getOfferings(props.user.id, { hidden: true }).then(
      (offerings) => {
        setOfferings(offerings);
      }
    );
  }, []);

  return (
    <div className="UserCouponsEditComponent">
      {loading && (
        <>
          <Center>
            <Loader />
          </Center>
        </>
      )}

      {!loading && (
        <>
          {!loading && offerings && (
            <>
              <div>
                <AddCouponComponent user={props.user} offerings={offerings} />
              </div>

              {coupons && (
                <div className="mt-4">
                  <h5>Existing coupons</h5>
                  {coupons.length == 0 && (
                    <>
                      You have not created any coupons. Please create some from
                      above to attract more bookings.
                    </>
                  )}
                  {coupons.length > 0 && (
                    <ListCouponComponent
                      user={props.user}
                      offerings={offerings}
                      coupons={coupons}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

interface ListCouponComponentProps {
  user: UserBasicDetails;
  offerings: Offerings;
  coupons: UserCoupon[];
}

function ListCouponComponent(props: ListCouponComponentProps) {
  return (
    <div className="ListCouponComponent">
      {props.coupons.map((coupon, index) => (
        <div key={coupon.couponCode}>
          <ListItemCouponComponent
            user={props.user}
            offerings={props.offerings}
            coupon={coupon}
          />
        </div>
      ))}
    </div>
  );
}

interface ListItemCouponComponentProps {
  user: UserBasicDetails;
  offerings: Offerings;
  coupon: UserCoupon;
}

function ListItemCouponComponent(props: ListItemCouponComponentProps) {
  const [mode, setMode] = useState<Mode>(Mode.VIEW);
  const [deleting, setDeleting] = useState<boolean>(false);
  function toggleMode() {
    if (mode === Mode.VIEW) {
      setMode(Mode.EDIT);
    } else {
      setMode(Mode.VIEW);
    }
  }
  let alert = useAlert();
  return (
    <div className="ListItemCouponComponent card card-body mt-2">
      <div className="row">
        <div className="col">{props.coupon.couponCode}</div>
        <div className="col float-right text-right">
          <div className="">
            <a
              className="btn btn-light"
              target="_blank"
              href={publicLinkConstants.EXPERT_STUDIO_COUPON_USAGES.replace(
                ":couponCode",
                props.coupon.couponCode
              )}
            >
              View Usages
            </a>
            &nbsp;&nbsp;
            <EBButton
              text={{ normal: <EditTitle /> }}
              onClick={() => toggleMode()}
            />
            &nbsp;&nbsp;
            <ApiLinkedButton
              showConfirmation={true}
              api={
                new Api<void, void>(
                  apiLinkConstants.API_USER_COUPON_DELETE.replaceAll(
                    "{couponCode}",
                    props.coupon.couponCode
                  ).replaceAll("{userId}", props.user.id),
                  ApiMethod.DELETE
                )
              }
              uniqueKey={"coupon" + props.coupon.couponCode + "-delete"}
              callback={reloadWindowPromise}
              buttonProps={{
                text: {
                  normal: "X",
                },
                type: "danger",
              }}
            />
          </div>
        </div>
      </div>
      {mode === Mode.EDIT && (
        <div>
          <hr />
          <AddOrEditCouponForm
            onSubmit={function (values: UserCoupon): Promise<UserCoupon> {
              return CouponApi.updateCoupon(props.user.id, values).then(
                (response) => {
                  alert.success("Updated!");
                  WindowHelpers.reload();
                  return response;
                }
              );
            }}
            existingCoupon={props.coupon}
            user={props.user}
            offerings={props.offerings}
          />
        </div>
      )}
    </div>
  );
}

interface AddCouponComponentProps {
  user: UserBasicDetails;
  offerings: Offerings;
}

function AddCouponComponent(props: AddCouponComponentProps) {
  const [showAddForm, setShowAddForm] = useState<boolean>();
  let alert = useAlert();
  return (
    <div className="AddCouponComponent">
      <EBButton
        text={{ normal: "Create Coupon" }}
        onClick={() => {
          setShowAddForm(true);
        }}
      />
      {showAddForm && (
        <div className="card card-body mt-2">
          <AddOrEditCouponForm
            offerings={props.offerings}
            user={props.user}
            onSubmit={function (values: UserCoupon): Promise<UserCoupon> {
              return CouponApi.addCoupon(props.user.id, values).then(
                (response) => {
                  message.success(`Coupon '${values.couponCode}'Added!`);
                  WindowHelpers.reload();
                  return response;
                }
              );
            }}
          />
        </div>
      )}
    </div>
  );
}
