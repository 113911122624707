import { Col, Row } from "antd";
import { Gutter } from "antd/es/grid/row";
import React from "react";
import { HTMLProps } from "../model/HTMLProps";

interface MainAndRightLayoutProps extends HTMLProps {
  right: React.ReactNode;
  main: React.ReactNode;

  gutter?: Gutter | [Gutter, Gutter];
}

export function MainAndRightLayout(props: MainAndRightLayoutProps) {
  return (
    <div className={`MainAndRightLayout ${props.className}`}>
      <Row gutter={props.gutter}>
        <Col sm={12} md={14} lg={16} xxl={18}>
          {props.main}
        </Col>
        <Col sm={12} md={10} lg={8} xxl={6}>
          {props.right}
        </Col>
      </Row>
    </div>
  );
}
