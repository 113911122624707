import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { LinkHelpers } from "../helpers/Link.Helpers";
import { Skill } from "../model/Skill";
import { SkillConstants } from "./Skill.Constants";

export class SkillAPI {
  public static fetchAll(forceRefresh?: boolean): Promise<Skill[]> {
    let link = SkillConstants.SKILLS_FETCH_ALL_API;
    if (forceRefresh) {
      link = LinkHelpers.addQueryParam(link, {
        key: "forceRefresh",
        value: forceRefresh,
      });
    }
    return new Api<void, Skill[]>(link, ApiMethod.GET).execute();
  }

  public static add(dl: string): Promise<Skill> {
    return new Api<Skill, Skill>(
      SkillConstants.API_SKILLS_ADD,
      ApiMethod.POST
    ).execute({ dl: dl } as Skill);
  }

  public static update(skillId: number): Api<Skill, Skill> {
    let link = SkillConstants.API_SKILLS_UPDATE.replace(
      "{skillId}",
      skillId + ""
    );
    return new Api<Skill, Skill>(link, ApiMethod.PUT);
  }
}
