import { AuthzAction } from "../../model/enums/AuthzActions.enum";
import { Target } from "../../model/target.model";
import { UserBasicDetails } from "../../user/User";
import { FormTemplateResponse } from "./formTemplate.model";

export interface FormInputData {
  sectionsData: any;
}

export interface FormResponse {
  id: string;
  createdAt: Date;
  template: FormTemplateResponse;
  formData: FormInputData;
  formMetadata: FormMetadata;
  submittedBy?: UserBasicDetails;
  submittedAt?: string;
  target: Target;
}

export interface FormMetadata {
  allowedActions: AuthzAction[];
  canEdit: boolean;
}

export enum FormStatus {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
}
