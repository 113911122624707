import axios from "axios";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { ApiUrlHelpers } from "../helpers/Helpers";
import { OrderResponseV1 } from "../model/OrderDetails";
import { EventOrderRequest } from "../model/requests/EventOrder.request";
import { OrderResponse } from "../model/response/Order.response";

export class OrderAPI {
  public static fetchOrders(userId?: string): Promise<OrderResponseV1[]> {
    let api = apiLinkConstants.API_ORDERS_FETCH_ALL;
    if (userId) {
      api += "&userId=" + userId;
    }
    return new Api<void, OrderResponseV1[]>(api, ApiMethod.GET).execute();
  }

  public static createEventOrder(
    eventOrder: EventOrderRequest
  ): Promise<OrderResponse> {
    return new Promise<OrderResponse>((resolve, reject) => {
      axios
        .post(apiLinkConstants.EVENT_ORDER_CREATE, eventOrder)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static getOrderByorderId(orderId: string): Promise<OrderResponseV1> {
    return new Promise<OrderResponseV1>((resolve, reject) => {
      axios
        .get(ApiUrlHelpers.getOrderForOrderId(orderId))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
