import { Form, Formik } from "formik";
import React from "react";
import { useAlert } from "react-alert";
import { ButtonText } from "../../../model/views/Button.props";
import { EBTextInput } from "../eb-ui-components";

interface URLInputFormProps {
  submitCallback: (url: string) => Promise<void>;
  submitButtonText?: ButtonText;
  initialValue: string;
}

export function URLInputForm(mainProps: URLInputFormProps) {
  let alertManager = useAlert();
  return (
    <Formik
      initialValues={{ url: mainProps.initialValue }}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        mainProps
          .submitCallback(values.url)
          .then(() => {
            actions.setSubmitting(false);
          })
          .catch((err) => {
            alertManager.error(
              "Error occurred! Make sure all required fields are filled."
            );
            actions.setSubmitting(false);
          });
      }}
    >
      {(formProps) => (
        <Form>
          <EBTextInput label="" name="url" helpText="" required />
          <button
            className="btn btn-sm btn-primary mr-2"
            type="submit"
            disabled={formProps.isSubmitting}
          >
            {mainProps.submitButtonText ? (
              <>
                {formProps.isSubmitting
                  ? mainProps.submitButtonText.submitting
                  : mainProps.submitButtonText.normal}
              </>
            ) : (
              <>{formProps.isSubmitting ? "Saving... Please wait." : "Save"}</>
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
}
