import { FieldArray, useField } from "formik";
import React from "react";
import { DeleteButton } from "../button/Delete.Button";
import { AddTitle } from "../components/common/Add.title";
import { EBButton } from "../components/common/EBButton";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { SocialTypeSelector } from "../components/common/input/SocialType.selector";
import { EBTextInputV2 } from "../components/common/input/TextLine.input";
import { SocialLink, SocialType } from "./Social.model";

interface SocialLinksInputProps extends FormikInputParams {
  allSocialTypes: SocialType[];
}

export function SocialLinksInput(props: SocialLinksInputProps) {
  let [field] = useField(props);
  let currentLinks: SocialLink[] = field.value ?? [];
  return (
    <div className="SocialLinksInput">
      <FieldArray name={`${props.name}`}>
        {({ insert, remove, push }: any) => (
          <>
            {currentLinks.map((socialLink, index) => (
              <div className="row mb-2">
                <div className="col">
                  <SocialTypeSelector
                    required={true}
                    socialTypes={props.allSocialTypes}
                    name={`${props.name}.${index}.type`}
                  />
                </div>
                <div className="col">
                  <EBTextInputV2
                    required={true}
                    name={`${props.name}.${index}.url`}
                    placeholder="A valid URL. Example: https://enginebogie.com/u/username"
                  />
                </div>
                <div className="col-auto px-0">
                  <DeleteButton onClick={() => remove(index)} />
                </div>
              </div>
            ))}

            <EBButton
              classes={["btn btn-secondary btn-sm"]}
              type="button"
              text={{ normal: <AddTitle text="Add" /> }}
              onClick={() => {
                push({});
              }}
            />
          </>
        )}
      </FieldArray>
    </div>
  );
}
