import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { FormResponse } from "../form/model/form.model";
import { CancelRequest } from "../model/ExpertSessionCancelRequest";
import { AuthzAction } from "../model/enums/AuthzActions.enum";
import { Target } from "../model/target.model";
import { PageResponse } from "../paging/Paging.Model";
import { UserBasicDetails } from "../user/User";
import { BookingConstants } from "./Booking.Constants";
import { Booking, BookingListRequest } from "./Booking.model";

export class BookingApi {
  static getForm(booking: Target, userId: string): Api<void, FormResponse> {
    let link = BookingConstants.API_BOOKING_FEEDBACK_FORM_USER.replaceAll(
      "{bookingType}",
      booking.targetType
    )
      .replaceAll("{bookingId}", booking.targetId)
      .replaceAll("{userId}", userId);
    return new Api<void, FormResponse>(link, ApiMethod.PUT);
  }

  static getFeedbackFormList(
    booking: Target
  ): Api<{ excludeIfHasThisPermission?: AuthzAction[] }, FormResponse[]> {
    let link = BookingConstants.API_BOOKING_FEEDBACK_FORM_LIST.replaceAll(
      "{bookingType}",
      booking.targetType
    ).replaceAll("{bookingId}", booking.targetId);

    return new Api<
      { excludeIfHasThisPermission?: AuthzAction[] },
      FormResponse[]
    >(link, ApiMethod.POST);
  }

  static markComplete(booking: Target, userId: string): Api<void, void> {
    let link = BookingConstants.API_BOOKING_SINGLE_MARK_COMPLETE.replaceAll(
      "{bookingType}",
      booking.targetType
    )
      .replaceAll("{bookingId}", booking.targetId)
      .replaceAll("{userId}", userId);

    return new Api<void, void>(link, ApiMethod.POST);
  }

  public static notifExpertSuppress(booking: Target): Api<void, void> {
    let apiLink =
      BookingConstants.API_BOOKING_SINGLE_NOTIF_ENGINE_SUPPRESS.replaceAll(
        "{bookingType}",
        booking.targetType
      ).replaceAll("{bookingId}", booking.targetId);
    return new Api<void, void>(apiLink, ApiMethod.PUT);
  }

  public static notifExpertUnSuppress(booking: Target): Api<void, void> {
    let apiLink =
      BookingConstants.API_BOOKING_SINGLE_NOTIF_ENGINE_UNSUPPRESS.replaceAll(
        "{bookingType}",
        booking.targetType
      ).replaceAll("{bookingId}", booking.targetId);
    return new Api<void, void>(apiLink, ApiMethod.PUT);
  }

  public static getBogies(booking: Target): Api<void, UserBasicDetails[]> {
    let apiLink = BookingConstants.API_BOOKING_BOGIES.replaceAll(
      "{bookingType}",
      booking.targetType
    ).replaceAll("{bookingId}", booking.targetId);

    return new Api<void, UserBasicDetails[]>(apiLink, ApiMethod.GET);
  }

  public static list(): Api<BookingListRequest, PageResponse<Booking>> {
    let apiLink = BookingConstants.API_BOOKING_LIST;
    return new Api<BookingListRequest, PageResponse<Booking>>(
      apiLink,
      ApiMethod.POST
    );
  }

  public static get(bookingId: string): Api<void, Booking> {
    let apiLink = BookingConstants.API_BOOKING_SINGLE.replaceAll(
      "{bookingId}",
      bookingId
    );
    return new Api<void, Booking>(apiLink, ApiMethod.GET);
  }

  public static cancel(bookingId: string): Api<CancelRequest, void> {
    return new Api<CancelRequest, void>(
      BookingConstants.API_BOOKING_SINGLE.replace("{bookingId}", bookingId),
      ApiMethod.DELETE
    );
  }

  public static add(): Api<Booking, Booking> {
    return new Api<Booking, Booking>(
      BookingConstants.API_BOOKING_ADD,
      ApiMethod.POST
    );
  }

  public static update(bookingId: string): Api<Booking, Booking> {
    let apiLink = BookingConstants.API_BOOKING_SINGLE.replace(
      "{bookingId}",
      bookingId
    );
    return new Api<Booking, Booking>(apiLink, ApiMethod.PUT);
  }

  public static notifSendSessionCreated(bookingId: string): Api<void, void> {
    let apiLink =
      BookingConstants.API_BOOKING_NOTIF_SEND_SESSION_CREATED.replace(
        "{bookingId}",
        bookingId
      );
    return new Api<void, void>(apiLink, ApiMethod.POST);
  }
}
