import React from "react";

interface DeleteTitleProps {
  text?: string;
}

export function DeleteTitle(props: DeleteTitleProps) {
  return (
    <>
      <i className="far fa-trash-alt mr-1"></i> {props.text}
    </>
  );
}
