import React, { useEffect, useState } from "react";
import { PageProps } from "../model/views/PageProps";
import { Company } from "./Company";
import { CompanyAPI } from "./CompanyAPI";
import { ReactMatch } from "../model/ReactMatchObj";
import { Loader } from "../components/common/Loader.component";
import { CompanyImage } from "./Company.Image";
import { Link } from "../links/Link";
import { CompanyHelper } from "./Company.Helper";
import { EditTitle } from "../components/common/Edit.title";

interface CompanyViewPagePathParams {
  companyId: string;
  slug: string;
}

interface CompanyViewPageProps extends PageProps {
  match: ReactMatch<CompanyViewPagePathParams>;
}

export function CompanyViewPage(props: CompanyViewPageProps) {
  console.log("CompanyViewPage");
  let [company, setCompany] = useState<Company>();
  let [loading, setLoading] = useState<boolean>();

  useEffect(function () {
    setLoading(true);
    CompanyAPI.getCompany(props.match.params.companyId)
      .executeV2()
      .then((company: Company) => {
        setCompany(company);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <div className="CompanyViewPage main-container">
      {loading && <Loader />}
      {!loading && company && (
        <>
          <div className="py-2 pb-3">
            <div className="media">
              <CompanyImage company={company} />

              <div className={`media-body pl-4 mob-pl-3`}>
                <div className="d-flex w-100 justify-content-between">
                  <h1 className="mb-1 fw-normal h3">
                    {company.desDl ?? company.dl}
                  </h1>
                </div>

                <div className="d-flex w-100 justify-content-between">
                  {props.user?.basicDetails.isAdmin && (
                    <Link
                      href={CompanyHelper.getEditUrl(company)}
                      className="link-secondary float-right"
                    >
                      <EditTitle text="Edit metadata" />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
