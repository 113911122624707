import { useFormikContext } from "formik";
import React from "react";
import { ButtonText } from "../../../model/views/Button.props";
import { Question } from "../../../question/Question.Model";
import { QuestionPickerView } from "../../../question/QuestionPickerView";
import { User, UserBasicDetails } from "../../../user/User";
import { CallerContext } from "../CallerContext";
import { FormikInputParams } from "./FormikInputParams.model";

interface FormikQuestionPickerProps extends FormikInputParams {
  uniqueKey: string;
  pickerButtonContent: ButtonText;
  user: User;
}
export function FormikQuestionPicker(props: FormikQuestionPickerProps) {
  let formikContext = useFormikContext();

  function callback(question: Question, callerContext: CallerContext) {
    formikContext.setFieldValue(props.name, question);
  }

  return (
    <QuestionPickerView
      callback={callback}
      pickerButtonContent={props.pickerButtonContent}
      callerContext={{}}
      uniqueKey={props.uniqueKey}
      user={props.user}
    />
  );
}
