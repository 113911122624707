import { Field } from "formik";
import React from "react";
import { SelectItem } from "../../../model/views/Select.props";
import { QuestionDifficulty } from "../../../question/Question.Model";
import { EBSelect } from "../eb-ui-components";
import { FormikInputParams } from "./FormikInputParams.model";

interface FormikQuestionDifficultySelectProps extends FormikInputParams {
  label?: string;
  isMulti?: boolean;
}

const allDifficulties: QuestionDifficulty[] = [
  QuestionDifficulty.EASY,
  QuestionDifficulty.MEDIUM,
  QuestionDifficulty.HARD,
];

export function FormikQuestionDifficultySelect(
  props: FormikQuestionDifficultySelectProps
) {
  function mapToInput(selectItem: SelectItem): QuestionDifficulty {
    return selectItem.value as QuestionDifficulty;
  }

  function mapToSelectItem(
    category: QuestionDifficulty
  ): SelectItem | undefined {
    if (!category) {
      return undefined;
    }
    return { value: category.toString(), label: category.toString() };
  }

  let difficultyOptions: (SelectItem | undefined)[] = [];
  for (var difficulty of allDifficulties) {
    difficultyOptions.push({
      label: difficulty.toString(),
      value: difficulty.toString(),
    });
  }

  return (
    <div>
      <Field
        label={props.label}
        name={props.name}
        component={EBSelect}
        options={difficultyOptions}
        isMulti={props.isMulti}
        mappingRequired={true}
        selectToApiMapper={mapToInput}
        apiToSelectMapper={mapToSelectItem}
        required={props.required}
        placeholder={props.placeholder}
      />
    </div>
  );
}
