import React from "react";
import { Ledger, LedgerSummary } from "./Ledger.model";
import { LedgerEntryListComponent } from "./LedgerEntry.list.component";
import { PriceHelpers } from "../../helpers/Helpers";
import { PriceView } from "../../components/common/Price.view";
import { Stat } from "../../stat/Stat.Model";
import { StatCardList } from "../../stat/Stat.Card.List";
import { HTMLProps } from "../../model/HTMLProps";

interface LedgerSummaryListComponentProps {
  summaries: LedgerSummary[];
}

export function LedgerSummaryListComponent(
  props: LedgerSummaryListComponentProps
) {
  return (
    <div className="LedgerSummaryListComponent">
      {props.summaries.map((summary, index) => (
        <LedgerSummaryComponent
          key={index}
          summary={summary}
          className="mb-2"
        />
      ))}
    </div>
  );
}

interface LedgerSummaryComponentProps extends HTMLProps {
  summary: LedgerSummary;
}

export function LedgerSummaryComponent(props: LedgerSummaryComponentProps) {
  let stats: Stat[] = [
    {
      title: "Credits",
      value: (
        <PriceView
          price={props.summary.totalCredits}
          className="text-success"
        />
      ),
    },

    {
      title: "Debits",
      value: (
        <PriceView price={props.summary.totalDebits} className="text-danger" />
      ),
    },
    {
      title: "Balance",
      value: <PriceView price={props.summary.balance} />,
    },
  ];

  return (
    <div className="LedgerSummaryComponent">
      <StatCardList stats={stats} {...(props as HTMLProps)} />
    </div>
  );
}
