import { useField } from "formik";
import React from "react";
import { HTMLProps } from "../../../model/HTMLProps";
import { FormikInputParams } from "./FormikInputParams.model";

interface FormikSwitchInputProps extends FormikInputParams, HTMLProps {
  onChange?: (value: any) => void;
  label?: React.ReactNode;
}

export function FormikSwitchInput(props: FormikSwitchInputProps) {
  const [field] = useField(props);

  function onChange(evt: any) {
    field.onChange({
      target: {
        name: props.name,
        value: evt.target.checked || false,
      },
    });
    if (props.onChange) {
      props.onChange(evt.target.checked || false);
    }
  }

  return (
    <div className={`custom-control custom-switch ${props.className}`}>
      <input
        type="checkbox"
        id={props.name}
        className="custom-control-input"
        disabled={props.disabled}
        onChange={onChange}
        checked={field.value}
        required={props.required}
      />

      <label className="custom-control-label" htmlFor={props.name}>
        {props.label}
      </label>
    </div>
  );
}
