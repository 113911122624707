import React, { useEffect, useState } from "react";
import { Loader } from "../../components/common/Loader.component";
import { Center } from "../../components/common/layout/Center.component";
import { ContentView } from "../../content/Content.View";
import { CheckIcon } from "../../icon/Check.Icon";
import { LinkClickHandler } from "../../links/Link.ClickHandler";
import { AttachmentTargetType } from "../../model/AttachmentTargetType";
import { User } from "../../user/User";
import { PathStep } from "./Offering.Path.Model";
import { OfferingPathStepAPI } from "./Offering.PathStep.api";

interface OfferingsPathStepViewProps {
  user: User;
  offeringId: string;
  inputStep: PathStep;
  onStepSelect: (step: PathStep) => void;
}

export function OfferingsPathStepView(props: OfferingsPathStepViewProps) {
  let [loading, setLoading] = useState<boolean>();
  let [step, setStep] = useState<PathStep>();

  useEffect(() => {
    setLoading(true);
    OfferingPathStepAPI.get(
      props.user.basicDetails.id,
      props.offeringId,
      props.inputStep.id + ""
    )
      .then((step) => {
        setStep(step);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.inputStep.id]);

  return (
    <div className="OfferingsPathStepView p-4">
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!loading && step && step.content && (
        <>
          <ContentView
            content={step.content}
            user={props.user}
            target={{
              targetType: AttachmentTargetType.OFFERING_PATH,
              targetId: props.offeringId,
            }}
            topNode={
              <h2>
                {step.dl ?? "Untitled Step"}
                {step.completedAt && (
                  <span className="float-right text-success ml-1 fs-5">
                    <CheckIcon /> Completed
                  </span>
                )}
              </h2>
            }
          />
          {props.inputStep.steps && (
            <div className="mt-4">
              {props.inputStep.steps &&
                props.inputStep.steps.map((subStep, index) => (
                  <div className="">
                    <LinkClickHandler
                      className="text-dark bold"
                      onClick={() => {
                        props.onStepSelect(subStep);
                      }}
                    >
                      - {subStep.dl}
                    </LinkClickHandler>
                  </div>
                ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}
