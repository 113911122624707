import { UserBasicDetails } from "../user/User";

export class TestUserHelper {
  private static testUserIds: string[] = [
    "30b45b26-5bb8-4ccb-803d-77b5db8b2f34",
    "d2a4b17f-b3ae-42d2-9533-1ad717bdfb8e",
  ];

  public static isTestUserId(userId?: string): boolean {
    return userId != undefined && this.testUserIds.includes(userId);
  }

  public static isTestUser(user?: UserBasicDetails): boolean {
    if (!user) {
      return false;
    }
    return this.isTestUserId(user.id);
  }
}
