import React, { useEffect, useState } from "react";
import { AchievementApi } from "../achievements/Achievement.Api";
import { UserAchievement } from "../achievements/Achievement.Model";
import { Loader } from "../components/common/Loader.component";
import { RadioButtonGroupComponent } from "../components/common/input/RadioButtonGroup.component";
import { Center } from "../components/common/layout/Center.component";
import { ButtonDisplayProps } from "../model/views/Button.props";
import { UserBasicDetails } from "../user/User";
import { ShareAchievementComponent } from "./ShareAchievement.component";

interface ShareAchievementsComponentProps {
  user: UserBasicDetails;
  initialSelectedAchievementId?: string;
}

export function ShareAchievementsComponent(
  props: ShareAchievementsComponentProps
) {
  let [userAchievements, setUserAchievements] = useState<
    UserAchievement[] | undefined
  >();
  let [selectedAchievement, setSelectedAchievement] = useState<
    UserAchievement | undefined
  >();
  let [loading, setLoading] = useState<boolean | undefined>();
  useEffect(() => {
    setLoading(true);
    AchievementApi.getUserAchievementsApi(props.user.id)
      .execute()
      .then((userAchievements) => {
        setUserAchievements(userAchievements);

        if (props.initialSelectedAchievementId) {
          for (let userAchievement of userAchievements) {
            if (userAchievement.id == props.initialSelectedAchievementId) {
              setSelectedAchievement(userAchievement);
            }
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function getButtons(
    userAchievementsList?: UserAchievement[]
  ): ButtonDisplayProps[] {
    if (!userAchievementsList || userAchievementsList.length === 0) {
      return [];
    }

    return userAchievementsList.map((userAchievement) => {
      return {
        text: {
          normal: <>{userAchievement.achievement.dl}</>,
          submitting: "",
        },
        type: "secondary",
      };
    });
  }

  let buttons = getButtons(userAchievements);

  return (
    <div className="ExpertStudioShareTestimonialComponent">
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!loading && userAchievements && userAchievements.length > 0 && (
        <>
          <h5>
            Select an achievement from below for which you want to create the
            content:
          </h5>
          <div className="flex-nowrap overflow-auto bg-light">
            <RadioButtonGroupComponent
              buttonProps={buttons}
              onButtonClick={function (index: number): void {
                if (userAchievements) {
                  setSelectedAchievement(userAchievements[index]);
                }
              }}
            />
          </div>
        </>
      )}
      {!loading && (!userAchievements || userAchievements.length == 0) && (
        <>
          You do not have achievements right now. Please get more bookings to
          achieve more success! .
        </>
      )}
      {selectedAchievement && (
        <div className="mt-2">
          <ShareAchievementComponent userAchievement={selectedAchievement} />
        </div>
      )}
    </div>
  );
}
