import { Field } from "formik";
import React from "react";
import { Currency } from "../../../model/Price";
import { SelectorOption, SelectorProps } from "../Selectors";
import { EBSelect } from "../eb-ui-components";

interface CurrencySelectorProps extends SelectorProps {
  currencies: Currency[];
}

export function CurrencySelector(props: CurrencySelectorProps) {
  let options: SelectorOption[] = [];

  props.currencies.forEach((currency) => {
    options.push({ value: currency.id + "", label: currency.title });
  });

  return (
    <div className="row">
      <div className="col">
        {options && (
          <Field
            label={props.fieldTitle ?? "Currency"}
            name={`${props.name}.id`}
            component={EBSelect}
            required={props.required}
            options={options}
            isDisabled={props.disabled}
          />
        )}
      </div>
    </div>
  );
}
