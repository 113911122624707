import { Card, Col, Row } from "antd";
import React from "react";
import { MarkdownView } from "../../components/common/MarkdownView";
import { UserBasicDetails } from "../../user/User";
import { AgendaComponent } from "../Agenda.Component";
import { GenericMetadataInfo, IOfferingV2 } from "../Offering";

export interface OfferingDetailPageMainContentComponentProps {
  offering: IOfferingV2;
  engine?: UserBasicDetails;
  className?: string;
}

export function OfferingDetailPageMainContentComponent(
  props: OfferingDetailPageMainContentComponentProps
) {
  const offeringCommonDetails = props.offering.commonDetails;
  return (
    <div
      className={`OfferingDetailPageMainContentComponent ${props.className}`}
    >
      <div>
        <div className="vstack gap-3">
          {props.offering.commonDetails.genericMetadata?.learningObjectives && (
            <DetailPageMainContentSectionList
              title={"What you will learn"}
              list={
                props.offering.commonDetails.genericMetadata?.learningObjectives
              }
              content={
                <Row gutter={[16, 16]} className="">
                  {props.offering.commonDetails.genericMetadata.learningObjectives.map(
                    (learningObjective, index) => (
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} className="">
                        <Card className="border">
                          <i className="fa-solid fa-book-open-reader pr-1 text-secondary mr-1 fs-24"></i>
                          {learningObjective.dl}
                        </Card>
                      </Col>
                    )
                  )}
                </Row>
              }
            />
          )}

          {props.offering.commonDetails.detailShort &&
            props.offering.commonDetails.detailShort.length > 0 && (
              <DetailPageMainContentSection
                title={"Overview"}
                content={
                  <MarkdownView
                    text={props.offering.commonDetails.detailShort}
                  />
                }
              />
            )}

          {props.offering.commonDetails.agenda?.items &&
            props.offering.commonDetails.agenda?.items.length > 0 && (
              <DetailPageMainContentSection
                title={"Agenda"}
                content={
                  <AgendaComponent
                    agenda={props.offering.commonDetails.agenda}
                  />
                }
              />
            )}
          {props.offering.commonDetails.detailLong &&
            props.offering.commonDetails.detailLong.length > 0 && (
              <DetailPageMainContentSection
                title={"Description"}
                content={
                  <MarkdownView
                    text={props.offering.commonDetails.detailLong}
                  />
                }
              />
            )}

          {props.offering.commonDetails.genericMetadata?.targetAudiences && (
            <DetailPageMainContentSectionList
              title={"Who this is for"}
              list={
                props.offering.commonDetails.genericMetadata.targetAudiences
              }
              content={
                <ul>
                  {props.offering.commonDetails.genericMetadata.targetAudiences.map(
                    (targetAudience, index) => (
                      <li>{targetAudience.dl}</li>
                    )
                  )}
                </ul>
              }
            />
          )}

          {props.offering.commonDetails.genericMetadata?.prerequisites && (
            <DetailPageMainContentSectionList
              title={"Prerequisites"}
              list={props.offering.commonDetails.genericMetadata.prerequisites}
              content={
                <ul>
                  {props.offering.commonDetails.genericMetadata.prerequisites.map(
                    (targetAudience, index) => (
                      <li>{targetAudience.dl}</li>
                    )
                  )}
                </ul>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

interface DetailPageMainContentSectionProps {
  title: React.ReactNode;
  content: React.ReactNode;
}

function DetailPageMainContentSection(
  props: DetailPageMainContentSectionProps
) {
  return (
    <div className="bg-white card card-body rounded-3">
      <h5 className="mb-3">{props.title}</h5>
      <div>{props.content}</div>
    </div>
  );
}

interface DetailPageMainContentSectionListProps {
  title: React.ReactNode;
  list?: GenericMetadataInfo[];
  content: React.ReactNode;
}

function DetailPageMainContentSectionList(
  props: DetailPageMainContentSectionListProps
) {
  let listToShow: GenericMetadataInfo[] = [];

  if (props.list) {
    listToShow = props.list.filter((item) => item.dl && item.dl.length > 0);
  }
  return (
    <>
      {listToShow && listToShow.length > 0 && (
        <DetailPageMainContentSection
          title={props.title}
          content={props.content}
        />
      )}
    </>
  );
}
