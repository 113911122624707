import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export interface LoaderProps {
  width?: string | number;
  height?: string | number;
}

export const Loader = styled.div<LoaderProps>`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid #fed136;
  border-right: 2px solid #fed136;
  border-bottom: 2px solid #fed136;
  border-left: 4px solid black;
  background: transparent;
  width: ${(props) => props.width ?? "80px"};
  height: ${(props) => props.height ?? "80px"};
  border-radius: 50%;
`;
