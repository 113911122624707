import React from "react";
import { Withdrawal, WithdrawalResponse } from "./Withdrawal.model";
import { WithdrawalSummaryListComponent } from "./WithdrawalSumary.component";
import { WithdrawalListComponent } from "./Withdrawal.list.component";

interface WithdrawalsComponentProps {
  withdrawalResponse: WithdrawalResponse;
}

export function WithdrawalsComponent(props: WithdrawalsComponentProps) {
  return (
    <div className="WithdrawalsComponent">
      <WithdrawalSummaryListComponent
        summaries={props.withdrawalResponse.summaries}
      />
      <div className="mt-4">
        {props.withdrawalResponse.withdrawals.length > 0 ? (
          <WithdrawalListComponent
            withdrawals={props.withdrawalResponse.withdrawals}
          />
        ) : (
          <>No withdrawals found</>
        )}
      </div>
    </div>
  );
}
