export interface CalendarSettings {
  schedule?: CalendarSchedule;
  unavailabilitySettings?: UnavailabilitySettings;
  meetingLink?: string;
  schedulingPreference?: SchedulingPreference;
  reschedulingSettings?: CalendarReschedulingSettings;
  otherSettings?: CalendarOtherSettings;
}

export enum SchedulingPreference {
  MANUAL = "MANUAL",
  CALENDAR = "CALENDAR",
}

export interface CalendarSchedule {
  monday?: DayAvailability;
  tuesday?: DayAvailability;
  wednesday?: DayAvailability;
  thursday?: DayAvailability;
  friday?: DayAvailability;
  saturday?: DayAvailability;
  sunday?: DayAvailability;
}

export interface DayAvailability {
  timeSlots: TimeSlot[];
}

export interface TimeSlot {
  start: Time;
  end: Time;
}

export interface Time {
  h: number;
  m: number;
  displayString?: string;
}

export interface DurationTimestamp {
  fromMillis: number;
  toMillis: number;
}

export interface UnavailabilitySettings {
  unavailableDurations?: DurationTimestamp[];
}

export interface TimestampSlot {
  start: number;
  end: number;
}

export interface EBTimezone {
  id: string;
  label: string;
}

export interface AvailableDateTimeSlotsResponse {
  slots: TimestampSlot[];
  schedulingPreference: SchedulingPreference;
}

export interface CalendarReschedulingSettings {
  maxReschedulesAllowedByMentees: number;
  rescheduleAllowedUntilSecs: number;
}

export interface CalendarOtherSettings {
  startTimeIncrementSecs: number;
  minimumHeadsUpSecs: number;
  maxAllowedBookingFromCurrentSecs: number;
  minimumGapBetweenConsecutiveMeetingsMinutes: number;
  maximumVideoSessionBookingsCountInADay: number;
}
