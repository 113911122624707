import React from "react";
import { ExpertStudioContentProps } from "./ExpertStudio.page";
import { EditPriceDifferentialSettings } from "../price/price-differential-settings/EditPriceDifferentialSettings";

export function ExpertStudioPriceDifferentialSettingsComponent(
  props: ExpertStudioContentProps
) {
  return (
    <div className="ExpertStudioGeneralSettingsComponent">
      <h5>Price Differential Settings:</h5>

      <EditPriceDifferentialSettings />
    </div>
  );
}
