import React, { useEffect, useState } from "react";
import { BunnyVideoPlayer } from "../../../components/common/BunnyVideo.Component";
import { VideoApi } from "../../../video/Video.Api";
import { VideoBunnyCdn } from "../../../video/Video.Model";
import { ContentItemDataVideoUpload } from "../../Content.model";
import { ContentItemViewProps } from "../ContentItem.model";

export function ContentItemViewVideoUpload(props: ContentItemViewProps) {
  let data: ContentItemDataVideoUpload = props.data;

  let [video, setVideo] = useState<VideoBunnyCdn>();
  let [loading, setLoading] = useState<boolean>();

  function fetchVideo() {
    if (data.videoId) {
      setLoading(true);
      VideoApi.getVideo(data.videoId)
        .execute()
        .then((video) => {
          setVideo(video);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
  useEffect(fetchVideo, [data.videoId]);

  return (
    <div className="ContentItemInputVideoUpload">
      <h6 className="">{video?.dl}</h6>
      {video && (
        <>
          <BunnyVideoPlayer
            maxWidth="100%"
            libraryId={video.libraryId}
            videoId={video.bunnyId}
          />
        </>
      )}
    </div>
  );
}
