import { useField } from "formik";
import React, { useState } from "react";
import { Label } from "../components/common/Form/elements/FormInputLabel";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { RadioButtonGroupComponent } from "../components/common/input/RadioButtonGroup.component";
import { ButtonDisplayProps } from "../model/views/Button.props";
import { IOfferingV2, OfferingOption, OfferingOptionSlaData } from "./Offering";

export interface OfferingOptionsSlaComponentProps extends FormikInputParams {
  options: OfferingOption[];
  offering: IOfferingV2;
  onOptionSelect: (option: OfferingOptionSlaData) => void;
}

export function OfferingOptionsSlaComponent(
  props: OfferingOptionsSlaComponentProps
) {
  const [field, meta, helpers] = useField({
    ...props,
    validate: (value: any) => {
      if (props.required && !value) {
        return "Required";
      }
    },
  });
  let [selectedSlaIndex, setSelectedSlaIndex] = useState<number>();

  let optionDatas: OfferingOptionSlaData[] = props.options
    .map((option) => option.data)
    .filter(
      (optionData) => optionData.isEnabled && !optionData.deletedAt
    ) as OfferingOptionSlaData[];
  optionDatas.sort((op1, op2) => {
    return op1.price.amount - op2.price.amount;
  });
  let slas: number[] = [];
  let slaButtons: ButtonDisplayProps[] = [];
  for (let optionData of optionDatas) {
    if (!slas.includes(optionData.responseSlaInSecs)) {
      slas.push(optionData.responseSlaInSecs);
      slaButtons.push({
        text: {
          normal: optionData.displayResponseSla,
          submitting: "",
        },
        type: "secondary",
      });
    }
  }

  function onSlaOptionChange(index: number) {
    setSelectedSlaIndex(index);
    field.onChange({
      target: {
        name: props.name,
        value: optionDatas[index],
      },
    });
    props.onOptionSelect(optionDatas[index]);
  }

  return (
    <div className="OfferingOptionsSlaComponent">
      <Label label="How fast do you want the response?" required={true} />

      <RadioButtonGroupComponent
        buttonProps={slaButtons}
        onButtonClick={onSlaOptionChange}
        selectedIndex={selectedSlaIndex}
      />
    </div>
  );
}
