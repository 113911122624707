import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { ExpertDetails } from "../model/ExpertDetails";
import { User } from "./User";
import { UserConstants } from "./User.Constants";

export class ExpertAPI {
  public static updateExpertSettingsGeneral(
    userId: string,
    updatedExpertDetails: ExpertDetails
  ) {
    let apiLink = apiLinkConstants.UPDATE_EXPERT_SETTINGS_GENERAL.replace(
      "{userId}",
      userId
    );

    return new Api<ExpertDetails, ExpertDetails>(
      apiLink,
      ApiMethod.PUT
    ).execute(updatedExpertDetails);
  }

  public static updateEngineSettings(
    userId: string
  ): Api<ExpertDetails, ExpertDetails> {
    let apiLink = UserConstants.API_USER_ENGINE_SETTINGS.replace(
      "{userId}",
      userId
    );

    return new Api<ExpertDetails, ExpertDetails>(apiLink, ApiMethod.PUT);
  }
  public static listExperts(): Api<void, User[]> {
    return new Api<void, User[]>("/api/admin/experts", ApiMethod.GET);
  }
}
