import React from "react";
import { EBSelectOptionDataType } from "../components/common/Form/EBSelectV2";
import { SelectorProps } from "../components/common/Selectors";
import { RadioButtonGroupComponent } from "../components/common/input/RadioButtonGroup.component";
import { ButtonDisplayProps } from "../model/views/Button.props";
import { Topic, TopicType } from "./Topic.model";

interface TopicTypeButtonSelectorProps extends SelectorProps {
  onSelect: (type: TopicType) => void;
}

export interface TopicSelectorOption extends EBSelectOptionDataType, Topic {}

export function TopicTypeButtonSelector(props: TopicTypeButtonSelectorProps) {
  let buttons: ButtonDisplayProps[] = [
    {
      text: {
        normal: "Skill",
      },
      type: "secondary",
    },
    {
      text: {
        normal: "Company",
      },
      type: "secondary",
    },
    {
      text: {
        normal: "Designation",
      },
      type: "secondary",
    },
    {
      text: {
        normal: "Other",
      },
      type: "secondary",
    },
  ];

  return (
    <>
      <div className="">
        <RadioButtonGroupComponent
          buttonProps={buttons}
          onButtonClick={function (index: number): void {
            switch (index) {
              case 0:
                props.onSelect(TopicType.SKILL);
                break;
              case 1:
                props.onSelect(TopicType.COMPANY);
                break;
              case 2:
                props.onSelect(TopicType.DESIGNATION);
                break;
              case 3:
                props.onSelect(TopicType.CHANNEL);
                break;
            }
          }}
        />
      </div>
    </>
  );
}
