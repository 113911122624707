import React from "react";
import { TargetLink } from "../components/common/Target.link";
import { TimestampView } from "../components/common/TimestampView";
import { UserLink } from "../user/User.link";
import { CouponInstance, CouponInstanceStatus } from "./Coupon.model";

interface CouponInstancesListProps {
  couponInstances: CouponInstance[];
}

export function CouponInstancesList(props: CouponInstancesListProps) {
  let blockedCount = 0;
  for (let ci of props.couponInstances) {
    if (ci.status === CouponInstanceStatus.BLOCKED) {
      blockedCount++;
    }
  }

  return (
    <div className="CouponInstancesList">
      {blockedCount > 0 && (
        <>
          {blockedCount} {blockedCount == 1 && "Person is"}{" "}
          {blockedCount > 1 && "People are"} using it right now. We will show
          those details below once they complete their order.
        </>
      )}
      {props.couponInstances.map((instance, index) => (
        <>
          {instance.status === CouponInstanceStatus.REDEEMED && (
            <>
              <div className="card card-body mt-2">
                <div className="row">
                  <div className="col-md-4">
                    Used at: <TimestampView timestamp={instance.redeemedAt} />
                  </div>
                  <div className="col-md-2">
                    By:{" "}
                    {instance.redeemedBy && (
                      <>
                        <UserLink user={instance.redeemedBy} />
                      </>
                    )}
                  </div>
                  <div className="col-md-2">
                    For:{" "}
                    {instance.redeemTarget && (
                      <>
                        <TargetLink target={instance.redeemTarget} />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ))}
    </div>
  );
}
