import { Api, ApiMethod } from "../../components/common/ApiLinkedButton";
import { PriceDifferentialSettingsConstants } from "./PriceDifferentialSettings.Constants";
import { PriceDifferentialSettings } from "./PriceDifferentialSettings.Model";

export class PriceDifferentialSettingsApi {
  public static list(): Api<void, PriceDifferentialSettings[]> {
    return new Api<void, PriceDifferentialSettings[]>(
      PriceDifferentialSettingsConstants.PRICE_DIFFERENTIAL_LIST,
      ApiMethod.GET
    );
  }

  public static add(): Api<
    PriceDifferentialSettings,
    PriceDifferentialSettings
  > {
    return new Api<PriceDifferentialSettings, PriceDifferentialSettings>(
      PriceDifferentialSettingsConstants.PRICE_DIFFERENTIAL_ADD,
      ApiMethod.POST
    );
  }

  public static update(
    pdsId: string
  ): Api<PriceDifferentialSettings, PriceDifferentialSettings> {
    let apiLink =
      PriceDifferentialSettingsConstants.PRICE_DIFFERENTIAL_SINGLE.replaceAll(
        "{pdsId}",
        pdsId
      );
    return new Api<PriceDifferentialSettings, PriceDifferentialSettings>(
      apiLink,
      ApiMethod.PUT
    );
  }

  public static delete(
    pdsId: string
  ): Api<PriceDifferentialSettings, PriceDifferentialSettings> {
    let apiLink =
      PriceDifferentialSettingsConstants.PRICE_DIFFERENTIAL_SINGLE.replaceAll(
        "{pdsId}",
        pdsId
      );
    return new Api<PriceDifferentialSettings, PriceDifferentialSettings>(
      apiLink,
      ApiMethod.DELETE
    );
  }
}
