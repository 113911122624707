import React from "react";

interface OfferingInputSectionProps {
  title: React.ReactNode;
  content: React.ReactNode;
}

export function OfferingInputSection(props: OfferingInputSectionProps) {
  return (
    <div className="pt-4">
      <b>{props.title}</b>
      <div>{props.content}</div>
    </div>
  );
}
