import React from "react";
import { Booking } from "../booking/Booking.model";
import { ApiLinkedButtonV3 } from "../components/common/Form/ApiLinked.button.V3";
import { FormikSwitchInput } from "../components/common/input/FormikSwitch.input";
import { reloadWindowPromise } from "../components/common/reloadCallback";
import { VideoSessionApi } from "./VideoSessions.api";

interface VideoSessionMarkNoShowComponentProps {
  className?: string;
  booking: Booking;
}

export function VideoSessionMarkNoShowComponent(
  props: VideoSessionMarkNoShowComponentProps
) {
  return (
    <span className={`VideoSessionMarkNoShowComponent`}>
      <ApiLinkedButtonV3
        className={`btn-danger ${props.className}`}
        uniqueKey={`vs-${props.booking.id}-mark-no-show`}
        buttonProps={{
          content: { normal: "Mentee No-Show" },
        }}
        api={VideoSessionApi.markSessionMenteeNoShow(props.booking.id!)}
        successCallback={reloadWindowPromise}
        apiFormBody={
          <div>
            Are you sure that you want to mark this session as a No-Show (Did
            not join the session and you waited for 10 mins!) from the mentee
            side?
            <FormikSwitchInput
              name={"waited10Mins"}
              label="Have you waited 10 mins for the mentee to join?"
              required={true}
            />
          </div>
        }
        formModalSize="md"
      />
    </span>
  );
}
