import { useField } from "formik";
import React, { useState } from "react";
import { Label } from "../components/common/Form/elements/FormInputLabel";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { RadioButtonGroupComponent } from "../components/common/input/RadioButtonGroup.component";
import { ExpertSessionHelpers } from "../helpers/Helpers";
import { ButtonDisplayProps } from "../model/views/Button.props";
import {
  IOfferingV2,
  OfferingOption,
  OfferingOptionDurationData,
} from "./Offering";

export interface OfferingOptionsDurationComponentProps
  extends FormikInputParams {
  options: OfferingOption[];
  offering: IOfferingV2;
  onOptionSelect: (option: OfferingOptionDurationData) => void;
}

export function OfferingOptionsDurationComponent(
  props: OfferingOptionsDurationComponentProps
) {
  const [field, meta, helpers] = useField({
    ...props,
    validate: (value: any) => {
      if (props.required && !value) {
        return "Required";
      }
    },
  });

  let [selectedOption, setSelectedOption] =
    useState<OfferingOptionDurationData>();
  let [selectedDurationIndex, setSelectedDurationIndex] = useState<number>();

  let optionDatas: OfferingOptionDurationData[] = props.options
    .map((option) => option.data)
    .filter(
      (optionData) => optionData.isEnabled && !optionData.deletedAt
    ) as OfferingOptionDurationData[];

  optionDatas.sort((op1, op2) => {
    return op1.price.amount - op2.price.amount;
  });

  let durations: number[] = [];
  let durationButtons: ButtonDisplayProps[] = [];
  for (let optionData of optionDatas) {
    if (!durations.includes(optionData.durationInSeconds)) {
      durations.push(optionData.durationInSeconds);
      durationButtons.push({
        text: {
          normal: ExpertSessionHelpers.getDisplayDuration(
            optionData.durationInSeconds
          ),
          submitting: "",
        },
        type: "secondary",
      });
    }
  }

  function onDurationOptionChange(index: number) {
    setSelectedDurationIndex(index);
    field.onChange({
      target: {
        name: props.name,
        value: optionDatas[index],
      },
    });
    props.onOptionSelect(optionDatas[index]);
    // setSelectedOption(optionDatas[index]);
  }

  return (
    <div className="OfferingOptionsDurationComponent">
      <div>
        <Label
          label="Please select the duration for which you want to book:"
          required={true}
        />{" "}
        <RadioButtonGroupComponent
          buttonProps={durationButtons}
          onButtonClick={onDurationOptionChange}
          selectedIndex={selectedDurationIndex}
        />
      </div>
      {/* {selectedOption && (
        <div className="mt-4">
          <hr />
          <div className="card-body">
            <Center>
              <VideoSessionOfferingBookNowButton
                offeringId={props.offering.id}
                optionId={selectedOption.id}
                text={
                  "Register for " +
                  PriceHelpers.getDisplayPriceV2(selectedOption.price)
                }
              />
            </Center>
          </div>
        </div>
      )} */}
    </div>
  );
}
