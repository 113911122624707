import React from "react";
import { HTMLProps } from "../model/HTMLProps";

export function NewBadge(props: HTMLProps) {
  return (
    <span
      className={`badge badge-light border p-2 position-absolute right-10 top--10 ${props.className}`}
    >
      <i className="fa-solid fa-wand-sparkles mr-1"></i> New
    </span>
  );
}
