import React from "react";

interface CenterProps {
  children: React.ReactNode;
  className?: string;
}

export function Center(props: CenterProps) {
  return (
    <div className="row">
      <div className={`d-flex justify-content-center ${props.className}`}>
        {props.children}
      </div>
    </div>
  );
}
