import { useField } from "formik";
import React from "react";
import { FormikInputParams } from "../../components/common/input/FormikInputParams.model";
import { ListFormikInput } from "../../components/common/input/List.FormikInput";
import { FormTemplateSectionLineItemBuilder } from "./Form.Section.LineItem.TemplateBuilder";
import { FormTemplateBuilderProps } from "./Form.Template.Builder";
import { FormTemplateHelper } from "./Form.Template.Helper";

export function FormTemplateSectionBuilder(props: FormTemplateBuilderProps) {
  const [field] = useField(props);
  return (
    <span className={`FormTemplateSectionBuilder ${props.className}`}>
      <ListFormikInput
        emptyNode="No items"
        name={`${props.name}.lineItems`}
        // itemComponent={FormTemplateSectionLineItemBuilder}
        hideSeparateDeleteButton={true}
        itemNode={(
          itemProps: FormikInputParams,
          onDelete?: () => Promise<void>
        ) => {
          return (
            <>
              <FormTemplateSectionLineItemBuilder
                {...itemProps}
                onDelete={onDelete}
              />
            </>
          );
        }}
        getNewItem={(size: number) => {
          return FormTemplateHelper.getTextAreaInputLi(
            "li" + size,
            { displayTitle: { view: "" } },
            false
          );
        }}
      />
    </span>
  );
}
