import React from "react";
import { TimestampView } from "../components/common/TimestampView";
import { PriceHelpers } from "../helpers/Helpers";
import {
  OrderItem,
  OrderItemType,
  OrderResponseV1,
  OrderStatus,
} from "../model/OrderDetails";
import { OrderItemListComponent } from "./OrderItem.Component";

interface OrderListComponentProps {
  orders: OrderResponseV1[];
}

export function OrderListComponent(props: OrderListComponentProps) {
  return (
    <div className="OrderListComponent">
      {props.orders.map((order, index) => (
        <OrderListItemComponent key={index} order={order} />
      ))}
    </div>
  );
}

interface OrderListItemComponentProps {
  order: OrderResponseV1;
}

export function OrderListItemComponent(props: OrderListItemComponentProps) {
  return (
    <div
      className={`OrderListItemComponent card card-body mb-2 border-left-${
        props.order.status == OrderStatus.PAID && "success"
      } shadow`}
    >
      <div className={`row gy-2`}>
        <div className="col-md-2">
          <TimestampView timestamp={props.order.createdAt} />
        </div>
        <div className="col-md-5">
          {props.order.orderItems && props.order.orderItems.length > 0 && (
            <>
              {props.order.orderItems.map((orderItem, index) => (
                <div key={orderItem.id}>
                  {orderItem.itemType == OrderItemType.GENERAL_ITEM && (
                    <>
                      <OrderItemListComponent
                        orderItem={orderItem as OrderItem}
                        index={index}
                      />
                    </>
                  )}
                </div>
              ))}
            </>
          )}
          {(!props.order.orderItems || props.order.orderItems.length == 0) &&
            `Id: ${props.order.id}`}
        </div>

        <div className="col-md-1">
          {PriceHelpers.getDisplayPriceV2(props.order.price)}
        </div>

        <div className="col-md-2">Status: {props.order.status}</div>

        <div className="col-md-2">
          <a target="_blank" href={props.order.detailUrl}>
            Order details
          </a>
        </div>
      </div>
    </div>
  );
}
