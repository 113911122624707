import React from "react";

interface LoaderProps {
  size?: "normal" | "sm";
}

export function Loader(props: LoaderProps) {
  return (
    <div
      className={`spinner-grow ${props.size === "sm" && "spinner-grow-sm"}`}
      role="status"
      aria-hidden="true"
    ></div>
  );
}
