import React from "react";
import { FaIcon } from "./Fa.Icon";

interface ArrowIconProps {
  dir: "left" | "right" | "up" | "down";
}

export function ArrowIcon(props: ArrowIconProps) {
  return <FaIcon name={`arrow-${props.dir}`} type={"s"} />;
}
