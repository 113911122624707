import { ConfigProvider } from "antd";
import React from "react";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { SpoofHelper } from "./helpers/Spoof.Helper";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

// optional cofiguration
const options = {
  position: "bottom center",
  timeout: 5000,
  offset: "30px",
  transition: "scale",
  containerStyle: {
    zIndex: 2000,
  },
};

SpoofHelper.addCurrentSpoofingToAxiosHeaders();

ReactDOM.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#fed136",
        fontSize: "1rem",
      },
    }}
  >
    <AlertProvider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AlertProvider>
  </ConfigProvider>,
  document.getElementById("react-root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
