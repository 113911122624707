import { FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { AlertManager, useAlert } from "react-alert";
import { CompanyAPI } from "../company/CompanyAPI";
import { DesignationAPI } from "../api/DesignationAPI";
import { CallerContext } from "../components/common/CallerContext";
import { EBTextAreaV2 } from "../components/common/Form/FormElements";
import {
  EBDateInput,
  EBTextInput,
  FormInputLabel,
} from "../components/common/eb-ui-components";
import { CompanySelector } from "../company/CompanySelector.component";
import { FormikSwitchInput } from "../components/common/input/FormikSwitch.input";
import { EBTextInputV2 } from "../components/common/input/TextLine.input";
import { DesignationSelector } from "../designation/Designation.selector";
import { ApiErrorHelper } from "../helpers/ApiError.helper";
import { Company } from "../company/Company";
import { Designation } from "../model/Designation";
import { InterviewExperience } from "../model/InterviewExperience";
import { InterviewExperienceRound } from "../model/InterviewExperienceRound";
import { Question } from "../question/Question.Model";
import { QuestionPickerView } from "../question/QuestionPickerView";
import { User, UserBasicDetails } from "../user/User";
import { QuestionDl } from "../question/Question.Dl";

interface RoundsQuestionsProps {
  round: InterviewExperienceRound;
  roundIndex: number;
  alert: AlertManager;
  user: User;
}

interface QuestionPickerInterviewExperienceContext extends CallerContext {
  round: InterviewExperienceRound;
  alert: AlertManager;
  questionPush: (question: Question) => void;
}

function roundQuestionPickerCallback(
  question: Question,
  callerContext: CallerContext
) {
  let context = callerContext as QuestionPickerInterviewExperienceContext;
  let round = context.round;
  var isQuestionAlreadyPushed = false;
  round &&
    round.questions &&
    round.questions.forEach((roundQuestion) => {
      if (question.id === roundQuestion.id) {
        isQuestionAlreadyPushed = true;
      }
    });
  if (!isQuestionAlreadyPushed) {
    context.questionPush(question);
  } else {
    context.alert.error("Question already added in this round");
  }
}

function AddRoundQuestionsView(props: RoundsQuestionsProps) {
  return (
    <>
      <FieldArray name={`rounds.${props.roundIndex}.questions`}>
        {(arrayHelpers: any) => {
          let callerContext = {
            round: props.round,
            alert: props.alert,
            questionPush: arrayHelpers.push,
          };

          return (
            <>
              {props.round.questions && props.round.questions.length > 0 ? (
                <div>
                  <div className="row mt-2">
                    <div className="col">Problems Asked:</div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <ul className="list-group">
                        {props.round.questions.map((question, index) => {
                          return (
                            <li
                              className="list-group-item d-flex justify-content-between align-items-center"
                              key={question.id}
                            >
                              <QuestionDl ques={question} />
                              <div className="col d-flex justify-content-end">
                                <button
                                  className="btn btn-sm text-danger"
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <i className="far fa-trash-alt"></i>
                                </button>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row pt-2">
                <div className="col">
                  <QuestionPickerView
                    callback={roundQuestionPickerCallback}
                    callerContext={callerContext}
                    uniqueKey={"round" + props.roundIndex}
                    user={props.user}
                  />
                </div>
              </div>
            </>
          );
        }}
      </FieldArray>
    </>
  );
}

interface InterviewRoundsProps {
  rounds?: InterviewExperienceRound[];
  alert: AlertManager;
  user: User;
}

function InterviewRounds(props: InterviewRoundsProps) {
  return (
    <FieldArray name="rounds">
      {(arrayHelpers: any) => (
        <div>
          {props.rounds &&
            props.rounds.length > 0 &&
            props.rounds.map((round, index) => (
              <div key={index} className="row">
                <div className="col">
                  <div className="card mt-3">
                    <div className="card-header py-2 d-flex flex-row bd-highlight">
                      <div className="flex-fill bd-highlight">
                        <div className="pt-2 h6">
                          Please add round {index + 1} details below:
                        </div>
                      </div>

                      <div className="bd-highlight">
                        <button
                          className="btn text-danger"
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <i className="far fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <input type="hidden" name={`rounds.${index}.id`} />
                      <input
                        type="hidden"
                        name={`rounds.${index}.number`}
                        value={index + 1}
                      />
                      <div className="row">
                        <div className="col">
                          <FormInputLabel
                            label="What type of round it was?"
                            required
                          />
                          <EBTextInputV2
                            name={`rounds.${index}.name`}
                            placeholder="Coding and Problem Solving"
                            helpText=""
                            required
                          />
                        </div>

                        <div className="col">
                          <EBTextInput
                            label="How long was the round? (in minutes)"
                            name={`rounds.${index}.durationInMinutes`}
                            placeholder="60"
                            helpText=""
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <EBTextAreaV2
                            label="Round Description"
                            name={`rounds.${index}.roundDescription`}
                            maxLength={1000}
                            rows={2}
                            helpText=""
                            placeholder="General decription you might want to add about this round."
                            enablePerformance={true}
                          />
                        </div>
                      </div>
                      <input
                        type="hidden"
                        className="form-control"
                        value={index + 1}
                      />
                      <AddRoundQuestionsView
                        round={round}
                        roundIndex={index}
                        alert={props.alert}
                        user={props.user}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div className="row mt-3">
            <div className="col">
              <button
                className="btn btn-warning btn-sm"
                type="button"
                onClick={() => arrayHelpers.push({})}
              >
                Add Round
              </button>
            </div>
          </div>
        </div>
      )}
    </FieldArray>
  );
}

interface InterviewExperienceFormProps {
  onSubmit: (ie: InterviewExperience) => Promise<void>;
  initialIE?: InterviewExperience;
  user: User;
}

export function InterviewExperienceForm(props: InterviewExperienceFormProps) {
  let [allCompanies, setAllCompanies] = useState<Company[]>();
  let [allDesignations, setAllDesignations] = useState<Designation[]>();

  useEffect(() => {
    CompanyAPI.fetchAllCompanies().then((companies) => {
      setAllCompanies(companies);
    });
    DesignationAPI.fetchAllDesignations().then((designations) => {
      setAllDesignations(designations);
    });
  }, []);

  const alert = useAlert();
  return (
    <div>
      <Formik
        initialValues={props.initialIE ?? {}}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);

          var roundNumber = 1;
          values.rounds &&
            values.rounds.forEach((round) => {
              round.number = roundNumber++;
            });

          props
            .onSubmit(values)
            .catch(function (error) {
              let displayError = ApiErrorHelper.getDisplayErrorMessage(error);
              alert.error(displayError);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <EBDateInput
                  label="Interview Date:"
                  name="interviewDate"
                  required
                  placeholder="Interview date"
                  helpText=""
                />
              </div>

              <div className="col-md-4">
                {allCompanies && (
                  <>
                    <FormInputLabel label="Company:" required={true} />
                    <CompanySelector
                      name="company"
                      companies={allCompanies}
                      allowCreate={true}
                      required={true}
                    />
                  </>
                )}
              </div>
              <div className="col-md-4">
                {allDesignations && (
                  <>
                    <FormInputLabel label="Designation:" required={true} />
                    <DesignationSelector
                      name="designation"
                      designations={allDesignations}
                      required={true}
                      allowCreate={true}
                    />
                  </>
                )}
              </div>
            </div>

            <InterviewRounds
              rounds={formProps.values.rounds}
              alert={alert}
              user={props.user}
            />
            <div className="mt-3 ml-2">
              <div className="">
                <FormikSwitchInput
                  name={"receivedOffer"}
                  label="Received offer?"
                />
              </div>

              {formProps.values.receivedOffer ? (
                <div className="">
                  {allDesignations && (
                    <>
                      <FormInputLabel label="Select designation if offer was received for different designation" />
                      <DesignationSelector
                        name="offerDesignation"
                        designations={allDesignations}
                        required={false}
                        allowCreate={true}
                      />
                    </>
                  )}
                </div>
              ) : null}
            </div>
            <div className="hstack gap-5 mt-4">
              <div>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={formProps.isSubmitting}
                >
                  {formProps.isSubmitting ? "Saving. Please Wait..." : "Save"}
                </button>
              </div>

              <div>
                <FormikSwitchInput label="Add as Anonymous?" name="isAnon" />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
