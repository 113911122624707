export enum AttachmentTargetType {
  MOCK_INTERVIEW = "MOCK_INTERVIEW",
  EXPERT_SESSION = "EXPERT_SESSION",
  INTERVIEW_EXPERIENCE = "INTERVIEW_EXPERIENCE",
  QUESTION = "QUESTION",
  USER_PROFILE = "USER_PROFILE",
  POST = "POST",
  OFFERING = "OFFERING",
  OFFERING_VIDEO_SESSION = "OFFERING_VIDEO_SESSION",
  OFFERING_TEXT_QUERY = "OFFERING_TEXT_QUERY",
  OFFERING_DIGITAL_PRODUCT = "OFFERING_DIGITAL_PRODUCT",
  OFFERING_PATH = "OFFERING_PATH",
  OFFERING_PATH_STEP = "OFFERING_PATH_STEP",
  OFFERING_COMMUNITY = "OFFERING_COMMUNITY",
  COMMUNITY_GROUP = "COMMUNITY_GROUP",

  VIDEO_SESSION = "VIDEO_SESSION",
  TEXT_QUERY = "TEXT_QUERY",
  DIGITAL_PRODUCT = "DIGITAL_PRODUCT",
  BOOKING_PATH = "BOOKING_PATH",
  COUNTRY = "COUNTRY",
  USER = "USER",
}
