import React, { useEffect, useState } from "react";
import { CallbackButton } from "../components/common/ApiLinkedButton";
import CopyClipboardTextArea from "../components/common/CopyCliboard.textarea";
import { Loader } from "../components/common/Loader.component";
import { IOfferingV2 } from "../offerings/Offering";
import { OfferingAPI } from "../offerings/Offering.api";
import { ShareableContent } from "./Share.model";

interface ShareOfferingComponentProps {
  offering: IOfferingV2;
}

export function ShareOfferingComponent(props: ShareOfferingComponentProps) {
  let [image, setImage] = useState<string | undefined>();
  let [shareContentResponse, setShareContentResponse] = useState<
    ShareableContent | undefined
  >();
  let [loading, setLoading] = useState<boolean | undefined>();

  useEffect(() => {
    setLoading(true);
    setShareContentResponse(undefined);

    OfferingAPI.getOfferingShareableImage(props.offering)
      .then((response) => {
        setImage(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.offering]);

  function createSharePostContent(): Promise<void> {
    return OfferingAPI.getOfferingShareableContent(props.offering).then(
      (shareResponse) => {
        setShareContentResponse(shareResponse);
        return Promise.resolve();
      }
    );
  }

  return (
    <div className="ShareOfferingComponent">
      {loading && <Loader />}
      {!loading && image && (
        <div className="row">
          <div className="col-md-6 mb-2">
            <img src={image} width={"100%"} style={{ maxWidth: 800 }} />
          </div>
          <div className="col-md-6">
            <CallbackButton
              buttonProps={{
                text: {
                  normal: "Create Content to Share For This Offering",
                  submitting: "Creating!!",
                },
              }}
              callback={createSharePostContent}
              uniqueKey={"offering-" + props.offering.id + "-share"}
            />
            {shareContentResponse && (
              <div className="mt-2 fs-3">
                <CopyClipboardTextArea
                  initialValue={shareContentResponse.textContent}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
