import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { ImageGeneratorResponse } from "../image/Image.model";
import { ShareableContent } from "../share/Share.model";
import { AchievementConstants } from "./Achievement.Constants";
import { UserAchievement } from "./Achievement.Model";

export class AchievementApi {
  public static getUserAchievementsApi(
    userId: string
  ): Api<void, UserAchievement[]> {
    let apiLink = AchievementConstants.API_USER_ACHIEVEMENTS_LIST.replace(
      "{userId}",
      userId
    );
    return new Api<void, UserAchievement[]>(apiLink, ApiMethod.GET);
  }

  public static getUserAchievementImage(
    userAchievementId: string
  ): Api<void, ImageGeneratorResponse> {
    let apiLink =
      AchievementConstants.API_USER_ACHIEVEMENT_SHAREABLE_IMAGE.replace(
        "{userAchievementId}",
        userAchievementId
      );
    return new Api<void, ImageGeneratorResponse>(apiLink, ApiMethod.GET);
  }

  public static getUserAchievementContent(
    userAchievementId: string
  ): Api<void, ShareableContent> {
    let apiLink =
      AchievementConstants.API_USER_ACHIEVEMENT_SHAREABLE_CONTENT.replace(
        "{userAchievementId}",
        userAchievementId
      );
    return new Api<void, ShareableContent>(apiLink, ApiMethod.GET);
  }
}
