import { Col, Row } from "antd";
import React, { useState } from "react";
import { BogieName, EngineName, PlatformDl } from "../Global.Names";
import { Center } from "../components/common/layout/Center.component";
import { publicLinkConstants } from "../constants";
import { FaqList } from "../faq/Faq.List";
import { WindowHelpers } from "../helpers/Window.helper";
import { OptionsSelect } from "../user/listing/OptionsSelect";
import { HomePageSection, HomePageSectionProps } from "./HomePage.Section";

enum EngineBogieOption {
  ENGINE = "ENGINE",
  BOGIE = "BOGIE",
}

export function HomePageFaqsSection(props: HomePageSectionProps) {
  let [selOp, setSelOp] = useState<EngineBogieOption>(EngineBogieOption.ENGINE);
  let commonFaqs = [
    {
      dl: <>What is {PlatformDl.SENTENCE_MID}?</>,
      dd: (
        <>
          enginEBogie is the <b>world's most premium platform</b> that enables
          peer-to-peer connections. It is a platform connecting learners with
          {EngineName.SENTENCE_MID_PLURAL} across various fields for 1:1
          mentorship sessions, mock interviews, courses, and much more.
          <br />
          Our goal is to facilitate personal and professional growth by
          providing access to a wealth of knowledge and experience.
        </>
      ),
    },

    {
      dl: "How do the payments work?",
      dd: (
        <>
          Payments from {BogieName.SENTENCE_MID_PLURAL} are processed through
          our secure platform. We support taking payments from all over the
          world without you worrying about currency conversion and their
          charges.
        </>
      ),
    },
  ];

  let engineFaqs = [
    {
      dl: "Do I get paid for my services?",
      dd: (
        <>
          Yes, we allow you to offer both free and paid services. You can
          configure as per your needs. After a booking is completed, the funds
          will be transferred to your account.
        </>
      ),
    },

    {
      dl: "How much can I charge for my offerings?",
      dd: (
        <>
          We do not enforce our {EngineName.SENTENCE_MID_PLURAL} to put a
          certain value. You can decide the charge by yourself depending on how
          much value you think you're providing. For an idea, our
          {EngineName.SENTENCE_MID}
          charge anywhere from $50 to $500 for a session.
        </>
      ),
    },
    {
      dl: `Is ${PlatformDl.SENTENCE_MID} free?`,
      dd: (
        <>
          Yes absolutely! We're 100% free to use and setup. To keep ourselves
          sustainable, we earn only when you earn. We add a small commission of
          5% on top of the price you put. <br />
          {PlatformDl.SENTENCE_START} exists only because{" "}
          {EngineName.SENTENCE_MID_PLURAL} like you exist.
        </>
      ),
    },
    {
      dl: "What are transaction processing charges?",
      dd: `To process payments from ${BogieName.SENTENCE_MID_PLURAL}, we have to use third party payment gateways like PayPal etc who charge us around 3% of the transaction amount. This is something beyond our control. But hold on, we dont deduct them from you price, ${BogieName.SENTENCE_MID_PLURAL} pay this separately.`,
    },
  ];

  let bogieFaqs = [
    {
      dl: <>How do I find a {EngineName.SENTENCE_MID} on the platform</>,
      dd: (
        <>
          You can browse our extensive list of {EngineName.SENTENCE_MID_PLURAL}{" "}
          by expertise, industry, or specific skills. Use our{" "}
          <a href={publicLinkConstants.FIND_EXPERTS}>search and filter tools</a>{" "}
          to find {EngineName.SENTENCE_MID_PLURAL} who match your learning
          objectives, and view their profiles for more information about their
          background, expertise, and reviews from other{" "}
          {BogieName.SENTENCE_MID_PLURAL}.
        </>
      ),
    },
    {
      dl: (
        <>
          What types of services can I expect from{" "}
          {EngineName.SENTENCE_MID_PLURAL}?
        </>
      ),
      dd: (
        <>
          Our {EngineName.SENTENCE_MID_PLURAL} offer a variety of services,
          including 1:1 mentorship sessions, answering offline text queries,
          conducting mock interviews, creating personalized learning roadmaps,
          and offering digital products like PDF guides and courses.
        </>
      ),
    },
    {
      dl: <>How do I book a session with a? {EngineName.SENTENCE_MID}?</>,
      dd: (
        <>
          Once you've found a {EngineName.SENTENCE_MID}, you can book a session
          through their profile by selecting a date and time that works for both
          of you. Our integrated calendar and payment system make booking
          sessions seamless and straightforward.
        </>
      ),
    },
    {
      dl: <>How does {PlatformDl.SENTENCE_MID} protect my privacy and data?</>,
      dd: (
        <>
          Unlike other platforms, We take privacy and data protection seriously.
          Our platform uses advanced security measures to protect your personal
          and payment information. We comply with all relevant privacy laws and
          regulations to ensure your data is safe and secure.
        </>
      ),
    },
    {
      dl: <>Is my money safe with {PlatformDl.SENTENCE_MID}</>,
      dd: (
        <>
          Our dedicated support team is here to assist with any payment or
          booking issues. If you encounter any problems,{" "}
          <a href={publicLinkConstants.HELP_LINK}>
            please reach out to us immediately
          </a>
          , and we'll work diligently to resolve the issue to your satisfaction.{" "}
          <br />
          <br /> Your trust and safety are our top priorities, and we're
          committed to ensuring a secure and positive experience on{" "}
          {PlatformDl.SENTENCE_MID}.
        </>
      ),
    },
  ];

  return (
    <HomePageSection
      className="bg-main-body"
      id="faqs"
      headingStartIcon={<i className="fa-solid fa-question"></i>}
      heading={<>Frequently asked questions</>}
    >
      <div className="container">
        <Row className="" gutter={[30, 30]}>
          <Col span={WindowHelpers.isMobile() ? "24" : "12"}>
            <FaqList faqs={commonFaqs} />
          </Col>
          <Col span={WindowHelpers.isMobile() ? "24" : "12"}>
            <div className="w-100">
              <div className="mb-3">
                <Center>
                  <OptionsSelect<EngineBogieOption>
                    gap={"3"}
                    isSingle={true}
                    options={[
                      EngineBogieOption.ENGINE,
                      EngineBogieOption.BOGIE,
                    ]}
                    initialSelectedOptions={[selOp]}
                    onSelectionUpdate={(
                      so: EngineBogieOption[]
                    ): Promise<void> => {
                      if (so && so.length > 0) {
                        setSelOp(so[0]);
                      }
                      return Promise.resolve();
                    }}
                    isEqual={(
                      a: EngineBogieOption,
                      b: EngineBogieOption
                    ): boolean => {
                      return a == b;
                    }}
                    render={(option: EngineBogieOption): React.ReactNode => {
                      if (option == EngineBogieOption.ENGINE) {
                        return <>{EngineName.SENTENCE_START_PLURAL}</>;
                      }
                      return BogieName.SENTENCE_START_PLURAL;
                    }}
                  />
                </Center>
              </div>

              <FaqList
                faqs={
                  selOp == EngineBogieOption.ENGINE ? engineFaqs : bogieFaqs
                }
              />
            </div>
          </Col>
        </Row>
        <div className="text-center">
          <div className="fs-20 mt-4">Stil have more questions?</div>
          <div className="fs-16 mt-2">
            <a href={publicLinkConstants.HELP_LINK} className="bold">
              Reach out to us
            </a>
          </div>
        </div>
      </div>
    </HomePageSection>
  );
}
