import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { ImageGeneratorResponse } from "./Image.model";

export class ImageApi {
  public static generateReviewShareableImage(
    reviewId: string
  ): Promise<ImageGeneratorResponse> {
    let api = apiLinkConstants.API_REVIEW_GENERATE_SHAREABLE_IMAGE.replace(
      "{reviewId}",
      reviewId
    );

    return new Api<void, ImageGeneratorResponse>(api, ApiMethod.GET).execute();
  }
}
