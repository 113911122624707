import React from "react";
import { publicLinkConstants } from "../constants";
import { EBLoginPageContent } from "./EBLogin.PageContent";
import { PublicPageRoute } from "../routes/PublicPage.route";

export function EBLoginRoutes() {
  return (
    <>
      <PublicPageRoute
        exact
        hideNavBar={true}
        hideFooter={true}
        path={publicLinkConstants.LOGIN}
        contentComponent={EBLoginPageContent}
      />
    </>
  );
}
