import React, { useState } from "react";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { ArrowIcon } from "../icon/Arrow.Icon";
import "./Sidebar.Layout.css";

interface SidebarLayoutProps {
  sidebar: React.ReactNode;
  mainContent: React.ReactNode;
}

export function SidebarLayoutSliding(props: SidebarLayoutProps) {
  let [sidebarVisible, setSidebarVisible] = useState(true);
  function toggleSidebarVisibility() {
    setSidebarVisible(!sidebarVisible);
  }

  return (
    <div className="row h-100 m-0">
      <div
        className={`col-auto border-right px-0 ${!sidebarVisible} ${
          sidebarVisible ? "eb-sidebar-expanded" : "eb-sidebar-collapsed"
        }`}
      >
        <div className="eb-sidebar-toggle-button">
          {sidebarVisible && (
            <EBButtonV2
              className="btn-sm btn-light rounded-circle border  border-1"
              content={{ normal: <ArrowIcon dir={"left"} /> }}
              onClick={toggleSidebarVisibility}
            />
          )}

          {!sidebarVisible && (
            <EBButtonV2
              className="btn-sm btn-light rounded-circle border border-1"
              content={{ normal: <ArrowIcon dir={"right"} /> }}
              onClick={toggleSidebarVisibility}
            />
          )}
        </div>
        {sidebarVisible && (
          <div className="eb-sidebar h-100">{props.sidebar}</div>
        )}
      </div>
      <div className="col p-0">{props.mainContent}</div>
    </div>
  );
}
