import React from "react";
import { Link } from "../links/Link";
import { User } from "../user/User";
import { IOfferingV2 } from "./Offering";
import { OfferingCardV2 } from "./Offering.Card";
import "./OfferingCard.css";

export interface OfferingV2RedirectCardProps {
  offering: IOfferingV2;
  liu?: User;
  className?: string;
}

export function OfferingV2RedirectCard(props: OfferingV2RedirectCardProps) {
  let offUrl = props.offering.url;

  return (
    <Link
      href={offUrl}
      className="text-decoration-none text-dark"
      target="_blank"
    >
      <OfferingCardV2 offering={props.offering} className={props.className} />
    </Link>
  );
}
