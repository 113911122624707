import React from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage, FieldArray, useField, useFormikContext } from 'formik';
import { useAlert } from 'react-alert'

class TestEmail extends React.Component {
    render() {
        return (
        <div className="App">
            <h1>Test Mail</h1>
            <Formik
            initialValues={{ email: "" }}
            onSubmit={(values, { setSubmitting }) => {
                axios.post('/api/admin/email/test', values)
                    .then(function (response) {
                    alert("Request processed successfully!");
                    setSubmitting(false);
                }).catch(function (error) {
                    alert("Error occurred! Make sure all required fields are filled.");
                    setSubmitting(false);
                });
            }}
            >
                {( {values,
                    isSubmitting,
                    handleSubmit,
                    handleChange }) => (
                    <Form>
                        {/* <Field name="name" type="text" /> */}
                        <label>Destination Test Email: </label>
                        <Field name="email" type="email" />
                        <button className="btn btn-primary" type="submit" disabled={isSubmitting}>{isSubmitting ? "Submitting..." : "Submit"}</button>
                    </Form>
                )}

            </Formik>
        </div>
        )
    }
}

class MyComponent extends React.Component {

    render() {
        return (
            <div>
            <div>Tests Area</div>
            <TestEmail />
        </div>
        );
    }
}

export default MyComponent;
