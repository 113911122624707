import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { ExpertSessionRole } from "../model/ExpertSession";
import { Price } from "../model/Price";
import { PageParams } from "../model/search/UserSearchRequestV1";
import { Target } from "../model/target.model";
import { StatusDl } from "../status/Status.model";
import { UserBasicDetails } from "../user/User";
import { AttendeeFormat } from "../video-sessions/Attendee.model";

export enum BookingStatusEnumId {
  IN_PROGRESS = "IN_PROGRESS",
  PENDING_SCHEDULE = "PENDING_SCHEDULE",
  PENDING_COMPLETE = "PENDING_COMPLETE",
  SCHEDULED = "SCHEDULED",
  PENDING_COMPLETE_BOGIE = "PENDING_COMPLETE_BOGIE",
  PENDING_COMPLETE_ENGINE = "PENDING_COMPLETE_ENGINE",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export interface BookingCommonDetails {
  id?: string;
  dl?: string;

  role?: ExpertSessionRole;
  bogieCompletedAt?: string;
  engineCompletedAt?: string;
  engineFeedbackFormTemplateId?: string;
  bogieFeedbackFormTemplateId?: string;
  engineId: string;
  bogieId?: string;
  offeringId?: string;
  source: Target;

  engine?: UserBasicDetails;
  bogie?: UserBasicDetails;
  // bogies: UserBasicDetails[];
  attendeeFormat?: AttendeeFormat;

  preBookingFormId?: string;

  isSkipBogieCompletion?: boolean;
  isSkipEngineCompletion?: boolean;

  shouldShowEngineCompletionButton?: boolean;

  createdAt?: string;
  enginePrice?: Price;

  cancelledAt?: string;
  engineNotificationsSuppressedAt?: string;
}

export interface Booking {
  id?: string;
  bcd: BookingCommonDetails;
  bookingTarget: Target;
  specificBookingTypeModel?: ISpecificBookingType;
  url?: string;

  statusDl?: StatusDl;
  status?: BookingStatusEnumId;

  canUpdate?: boolean;
  canCancel?: boolean;

  bookingsCnt?: number;
  latestBookingCreatedAt?: string;
}

export interface BookingListRequest {
  bookingTypes?: AttachmentTargetType[];
  bogieIds?: string[];
  engineIds?: string[];
  offeringIds?: string[];
  statuses?: BookingStatusEnumId[];

  pageParams?: PageParams;

  groupByBogie?: boolean;
  groupByEngine?: boolean;
}

export interface ISpecificBookingType {
  url?: string;
}
