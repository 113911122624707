import React from "react";
import { ListItemLayoutDefault } from "../../list/ListItem.Layout";
import { ListItemProps, ListV2 } from "../../list/ListV2";
import { PageResponse } from "../../paging/Paging.Model";
import { SkillList } from "../../skills/Skill.List";
import { SkillAPI } from "../../skills/SkillAPI";

export function AdminSkillsPageContent() {
  return (
    <div className="main-container">
      <ListV2
        onFetch={function (): Promise<PageResponse<any>> {
          return SkillAPI.fetchAll(true).then(function (result) {
            return { content: result, totalSize: result.length };
          });
        }}
        ItemLayoutComponent={ListItemLayoutDefault}
        itemNode={(itemProps: ListItemProps) => {
          let skill = itemProps.item;
          return <SkillList skill={skill} showAdminActions={true} />;
        }}
      />
    </div>
  );
}
