import axios from "axios";
import { apiLinkConstants } from "../constants";
import { ReceivedReviewResponse, ReviewV1 } from "../model/Reviews";

export class ReviewsAPI {
  public static async getReceivedReviews(
    userId: string,
    page?: number,
    size?: number
  ): Promise<ReceivedReviewResponse> {
    let api = apiLinkConstants.USER_REVIEWS_RECEIVED.replace("{userId}", userId)
      .replaceAll("{page}", (page ?? 0) + "")
      .replaceAll("{size}", (size ?? 10000) + "");
    return new Promise<ReceivedReviewResponse>((resolve, reject) => {
      axios
        .get(api)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async complete(
    apiLink: string,
    reviewRequest: ReviewV1
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(apiLink)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
