import { useField } from "formik";
import React, { useState } from "react";
import { ButtonDisplayProps } from "../../../model/views/Button.props";
import { FormikInputParams } from "./FormikInputParams.model";
import { RadioButtonGroupComponent } from "./RadioButtonGroup.component";

export interface NumberOption {
  value: number;
  dl: string;
}

interface FormikNumberOptionsInputProps extends FormikInputParams {
  options: NumberOption[];
  supportCustom?: boolean;
}

export function FormikNumberOptionsInput(props: FormikNumberOptionsInputProps) {
  const [field, meta, helpers] = useField({
    ...props,
    validate: (value: any) => {
      if (props.required && !value) {
        return "Required";
      }
    },
  });

  function setFieldValue(valueInSecs: number) {
    field.onChange({
      target: {
        name: props.name,
        value: valueInSecs,
      },
    });
  }

  let valueIndex: number | undefined = undefined;
  let index = 0;
  let optionButtons: ButtonDisplayProps[] = [];
  for (let option of props.options) {
    if (field.value == option.value) {
      valueIndex = index;
    }
    index++;
    optionButtons.push({
      text: {
        normal: option.dl,
        submitting: "",
      },
      extraClassNames: "btn-sm",
      type: "secondary",
    });
  }

  let customIndex = index;
  if (props.supportCustom) {
    optionButtons.push({
      text: {
        normal: "Custom",
        submitting: "",
      },
      extraClassNames: "btn-sm",
      type: "secondary",
    });
  }

  let [selectedOptionIndex, setSelectedOptionIndex] = useState<
    number | undefined
  >(valueIndex ?? customIndex);

  function onDurationOptionChange(index: number) {
    if (index == customIndex) {
      setSelectedOptionIndex(index);
    } else {
      setSelectedOptionIndex(index);
      setFieldValue(props.options[index].value);
    }
  }

  function onValueChange(value: number) {
    if (isNaN(value)) {
      setFieldValue(0);
      return false;
    }
    setFieldValue(value);
  }

  function getCustomFieldValue() {
    return field.value;
  }

  return (
    <span className="FormikNumberOptionsInput">
      <div>
        <RadioButtonGroupComponent
          buttonProps={optionButtons}
          onButtonClick={onDurationOptionChange}
          selectedIndex={selectedOptionIndex}
          disabled={props.disabled}
        />
      </div>
      {props.supportCustom == true && selectedOptionIndex == customIndex && (
        <div className="mt-2">
          <div className="input-group">
            <input
              className="form-control"
              required={props.required}
              disabled={props.disabled}
              onChange={(event: any) => {
                onValueChange(event.target.value);
              }}
              value={getCustomFieldValue()}
            />
          </div>
        </div>
      )}
      {meta.error ? <div className="text-danger">{meta.error}</div> : null}
    </span>
  );
}
