import React from "react";
import TimeAgo from "timeago-react";
import { PendingApprovalBadge } from "../badge/PendingApproval.Badge";
import { CompanyImage } from "../company/Company.Image";
import { ReactionCountsViewComponent } from "../components/common/ReactionCountsViewComponent";
import { InterviewExperienceHelpers, UrlHelpers } from "../helpers/Helpers";
import { Link } from "../links/Link";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { HTMLProps } from "../model/HTMLProps";
import { InterviewExperienceResponse } from "../model/InterviewExperience";

interface InterviewExperiencesListItemProps extends HTMLProps {
  interviewExperience: InterviewExperienceResponse;
  dl: React.ReactNode;

  hideEdit?: boolean;
  hideReactionText?: boolean;
  hidePostedBy?: boolean;
}
export function InterviewExperiencesListItem(
  props: InterviewExperiencesListItemProps
) {
  let interviewExperience = props.interviewExperience;
  return (
    <div className={`QuestionsListItem ${props.className}`}>
      <Link
        href={interviewExperience.url}
        className="text-decoration-none text-dark"
      >
        <div className="row py-2" key={interviewExperience.id}>
          <div className="media">
            {interviewExperience.company && (
              <CompanyImage company={interviewExperience.company} />
            )}

            <div className="media-body pl-2">
              <div className="d-flex w-100 justify-content-between">
                <span className="mb-1">{props.dl}</span>
                <div>
                  {interviewExperience.canUserEdit && (
                    <a
                      href={UrlHelpers.getEditInterviewExperienceUrl(
                        interviewExperience.slug,
                        interviewExperience.id
                      )}
                      className="link-secondary"
                      role="button"
                    >
                      <i className="fas fa-edit"></i>
                    </a>
                  )}
                </div>
              </div>

              {!props.hidePostedBy && (
                <div className="d-flex w-100 justify-content-between">
                  <div>
                    <small className="text-muted">
                      Posted by:{" "}
                      {InterviewExperienceHelpers.postedByDisplayTitle(
                        interviewExperience
                      )}
                    </small>
                    {!interviewExperience.approvedAt && (
                      <PendingApprovalBadge />
                    )}
                  </div>
                  <div>
                    <small className="float-right">
                      {interviewExperience.createdAt ? (
                        <TimeAgo datetime={interviewExperience.createdAt} />
                      ) : null}
                    </small>
                  </div>
                </div>
              )}
              <div className="d-flex w-100 justify-content-between">
                {interviewExperience.reactionCounts && (
                  <div className="mt-2 float-right">
                    <ReactionCountsViewComponent
                      targetType={AttachmentTargetType.INTERVIEW_EXPERIENCE}
                      targetId={"" + interviewExperience.id}
                      reactionCounts={interviewExperience.reactionCounts}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
