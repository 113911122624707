import React from "react";

export class CancellationRefundsView extends React.Component {
  render() {
    return (
      <div className="main-container">
        <h1>Cancellation &amp; Refunds</h1>

        <h5>Video calls - Bookings without calendar based scheduling</h5>
        <ul>
          <li>
            If you book the session and ask to schedule it within a day or 2 and
            cancel it if you get later slot, then 4% deduction for INR payments
            (and 8% for USD payments) will be there as we incur charges for any
            refund from payment processor, etc. If expert says he/she is not
            available for next 7 days, then full refund will be initiated.
          </li>
          <li>
            Before 24 hrs from session start time: No cancellation charges will
            be applied
          </li>
          <li>
            From 24 hrs before to 1 hr from session start time: 10% cancellation
            charges will be applied
          </li>
          <li>
            From 1 hr before to 15 mins from session start time: 25%
            cancellation charges will be applied
          </li>
          <li>
            15 mins before session start time to 10 mins post session start
            time: 50% cancellation charges will be applied. Expert will wait
            only for max 10 mins from session start time post which session will
            be considered as cancelled.{" "}
          </li>

          <li>
            Every month, you will have only one free cancellation overall. If
            your cancellations increase more than one in a month, then you will
            incur minimum 4% deduction for INR payments (and 8% for USD
            payments). This is done to avoid misuse of the platform since even
            after refund we incur processing charges.
          </li>
        </ul>

        <h5>Video calls - Bookings using expert's calendar</h5>
        <ul>
          <li>
            Full refund if the expert declines the booked slot or if they do not
            join the call.
          </li>
          <li>
            If you ask to cancel the session before 24 hours before start of the
            session, you will get refund with 4% deduction for INR payments (and
            8% for USD payments)
          </li>
          <li>
            From 24 hrs before to 1 hr from session start time: 10% cancellation
            charges will be applied
          </li>
          <li>
            From 1 hr before to 15 mins from session start time: 25%
            cancellation charges will be applied
          </li>
          <li>
            15 mins before session start time to 10 mins post session start
            time: 50% cancellation charges will be applied. Expert will wait
            only for max 10 mins from session start time post which session will
            be considered as cancelled.{" "}
          </li>

          <li>
            Every month, you will have only one free cancellation overall. If
            your cancellations increase more than one in a month, then you will
            incur minimum 4% deduction for INR payments (and 8% for USD
            payments).
          </li>
        </ul>

        <h5>Offine Queries</h5>
        <ul>
          <li>
            Full refund if the expert does not repond within the given SLA. You
            can raise a request and we will initiate the refund. Applicable only
            if you raise the request before the response from the expert. Once
            they have responded, no refund will be applicable.
          </li>
          <li>
            Every month, you will have only one free cancellation overall. If
            your cancellations increase more than one in a month, then you will
            incur minimum 4% deduction for INR payments (and 8% for USD
            payments).
          </li>
        </ul>

        <h5>Digital Products</h5>
        <ul>
          <li>
            Full refund if the product is not provided post booking. You can
            reach out to us and we will look further into your issue.
          </li>
        </ul>

        <h1 className="mt-5">Contact Us</h1>
        <p className="p10">
          <span className="s1">
            Don't hesitate to contact us if you have any questions.
          </span>
        </p>
        <ul className="ul1">
          <li className="li16">
            <span className="s3">
              Via Email: <a>hello@enginebogie.com</a>
            </span>
          </li>
          <li className="li16">
            <span className="s3">
              Via this Link: <a>https://enginebogie.com/help</a>
            </span>
          </li>
        </ul>
      </div>
    );
  }
}
