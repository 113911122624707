import { Drawer } from "antd";
import React from "react";

interface EBDrawerProps {
  title: React.ReactNode;
  children: React.ReactNode;
  onClose?: () => void;
  open?: boolean;
}

export function EBDrawer(props: EBDrawerProps) {
  return (
    <Drawer
      title={props.title}
      placement="right"
      onClose={props.onClose}
      open={props.open}
      classNames={{ body: "p-0" }}
    >
      {props.children}
    </Drawer>
  );
}
