import React from "react";
import { BogieName, EngineName } from "../Global.Names";
import { BookingAttendeeLink } from "../booking/Booking.Attendee.Link";
import { BookingCommonComponent } from "../booking/Booking.Common.Component";
import { BookingComponentWrapper } from "../booking/Booking.ComponentWrapper";
import { Booking } from "../booking/Booking.model";
import { ReactMatch } from "../model/ReactMatchObj";
import { PageProps } from "../model/views/PageProps";
import { User } from "../user/User";
import { UserLink } from "../user/User.link";
import { DigitalProduct } from "./DigitalProduct.model";

interface DigitalProductDetailsProps {
  booking: Booking;
  user: User;
}

export function DigitalProductDetails(props: DigitalProductDetailsProps) {
  let booking = props.booking;
  let dp = booking.specificBookingTypeModel as DigitalProduct;
  return (
    <>
      <div className="text-gray-800">
        <div className="row">
          <div className="col-xl-6 col-md-6">
            <div className="card border-left-primary shadow">
              <div className="card-body p-1 p-3">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      {EngineName.SENTENCE_START}
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <UserLink user={booking.bcd.engine} />
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-user fa-2x text-secondary"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-6">
            <div className="card border-left-primary shadow">
              <div className="card-body p-3">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      {BogieName.SENTENCE_START}
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <BookingAttendeeLink booking={booking} />
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-user fa-2x text-secondary"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col">
            <div className="card border-left-primary shadow">
              <div className="card-body p-1 p-3">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Content
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {dp.attachments && dp.attachments.length > 0 && (
                        <>
                          {dp.attachments.map((attachment, index) => (
                            <div>
                              <a
                                target="_blank"
                                className="text-secondary"
                                href={attachment.location.data}
                              >
                                {attachment.title}
                              </a>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BookingCommonComponent booking={booking} user={props.user} />
      </div>
    </>
  );
}

interface DigitalProductDetailsPageContentProps extends PageProps {
  match: ReactMatch<DigitalProductDetailsPageContentPathParams>;
}
interface DigitalProductDetailsPageContentPathParams {
  dpId: string;
}

export function DigitalProductDetailsPageContent(
  props: DigitalProductDetailsPageContentProps
) {
  return (
    <div className="main-container DigitalProductDetailsPageContent">
      <BookingComponentWrapper
        bookingId={props.match.params.dpId}
        user={props.user}
      />
    </div>
  );
}
