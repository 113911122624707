import React from "react";
import { ReviewView } from "../components/common/review/Review.view";
import { ReviewV1 } from "../model/Reviews";
import "./UserFullProfileReviews.css";

export interface UserFullProfileReviewProps {
  reviews: ReviewV1[];
}

export interface UserFullProfileReviewState {}

class UserFullProfileReview extends React.Component<
  UserFullProfileReviewProps,
  UserFullProfileReviewState
> {
  render() {
    return (
      <div>
        {this.props.reviews && this.props.reviews.length > 0 ? (
          <div className="review-block">
            {this.props.reviews.map((review, index) => (
              <>
                <ReviewView review={review} />
                <hr />
              </>
            ))}
          </div>
        ) : (
          <div>No Reviews Yet.</div>
        )}
      </div>
    );
  }
}

export default UserFullProfileReview;
