import React, { useState } from "react";
import { BogieName } from "../../Global.Names";
import { EBButtonV2 } from "../../components/common/EBButtonV2";
import { ImageComponent } from "../../components/common/Image.component";
import { UserDrawer } from "../../components/drawer/User.Drawer";
import { AttachmentTargetType } from "../../model/AttachmentTargetType";
import { LocationType } from "../../model/Picture";
import { OfferingAPI } from "../Offering.api";

export function OfferingPathAttendeeDrawerButton(props: {
  offeringId: string;
  className?: string;
}) {
  let [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <EBButtonV2
        className={props.className}
        content={{
          normal: (
            <ImageComponent
              image={{
                locationType: LocationType.FA_CLASS,
                data: "fa-solid fa-users-line",
              }}
              width={20}
              height={20}
            />
          ),
        }}
        onClick={() => {
          setOpenDrawer(true);
        }}
        style={{ width: "54px" }}
      />
      {openDrawer && (
        <UserDrawer
          title={`${BogieName.SENTENCE_START_PLURAL}`}
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
          onFetch={() => {
            return OfferingAPI.getOfferingBogies(
              AttachmentTargetType.OFFERING_PATH,
              props.offeringId
            ).executeV2();
          }}
        />
      )}
    </>
  );
}
