import React from "react";
import { Step, StepperComponent } from "../components/common/Stepper.Component";
import { CalendarExpertStudioContent } from "../expert-studio/Calendar.ExpertStudio.Content";
import { ExpertStudioConstants } from "../expert-studio/ExpertStudio.constants";
import { ExpertStudioShareComponent } from "../expert-studio/ExpertStudioShare.component";
import { OfferingsDigitalProductEditExpertStudioContent } from "../expert-studio/Offerings.DigitalProduct.Edit.ExpertStudio.Content";
import { OfferingsTextQueryEditExpertStudioContent } from "../expert-studio/Offerings.TextQuery.Edit.ExpertStudio.Content";
import { OfferingsVideoSessionEditExpertStudioContent } from "../expert-studio/Offerings.VideoSession.Edit.ExpertStudio.Content";
import { WindowHelpers } from "../helpers/Window.helper";
import { PageProps } from "../model/views/PageProps";
import { UserProfileEdit } from "../user/UserProfile.Edit";

interface BecomeExpertPageContentProps extends PageProps {}

export function BecomeExpertPageContent(props: BecomeExpertPageContentProps) {
  let steps: Step[] = [];

  function save() {}

  if (props.user) {
    steps.push(
      {
        title: "Profile Information",
        content: <UserProfileEdit {...props} />,
      },
      {
        title: "Edit Video Offerings",
        content: (
          <OfferingsVideoSessionEditExpertStudioContent user={props.user} />
        ),
      },
      {
        title: "Edit Offline Query Offerings",
        content: (
          <OfferingsTextQueryEditExpertStudioContent user={props.user} />
        ),
      },
      {
        title: "Edit Digital Product Offerings",
        content: (
          <OfferingsDigitalProductEditExpertStudioContent user={props.user} />
        ),
      },
      {
        title: "Calendar",
        content: <CalendarExpertStudioContent user={props.user} />,
      },
      {
        title: "Publish",
        content: <ExpertStudioShareComponent user={props.user} />,
      }
    );
  }
  return (
    <div className="main-container">
      <h1 className="h4 ">Become an Expert on enginEBogie:</h1>
      {steps.length > 0 && (
        <StepperComponent
          steps={steps}
          onDone={() => {
            let redirectUrl = ExpertStudioConstants.PAGE_EXPERT_STUDIO_BOOKINGS;
            if (props.user?.basicDetails.profileUrl) {
              redirectUrl =
                props.user?.basicDetails.profileUrl +
                "?successMessage=🎉 Congratulations on completing your profile. Please share it with your network to get bookings.";
            }
            WindowHelpers.redirect(redirectUrl);
            return Promise.resolve();
          }}
        ></StepperComponent>
      )}
      <div className="text-center">
        {" "}
        Please make sure you Save before going "Next"
      </div>
    </div>
  );
}
