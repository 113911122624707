import React, { useEffect, useState } from "react";
import { publicLinkConstants } from "../../../constants";
import { AttachmentTargetType } from "../../../model/AttachmentTargetType";
import { ReactMatch } from "../../../model/ReactMatchObj";
import { PageProps } from "../../../model/views/PageProps";
import { RelatedContentComponent } from "../../../promotion/RelatedContent";
import { ViewsManager } from "../../../reactions/Views.Manager";
import { UserProfileSidebar } from "../../../user/UserProfile.Sidebar";
import { BreadcrumbComponent } from "../../common/BreadcrumbComponent";
import { CommentsComponent } from "../../common/comments/Comments.component";
import { CharchaaAPI } from "./Charchaa.api";
import { Post } from "./Charchaa.model";
import { OfferingsUserSidebar } from "./OfferingsUser.Sidebar";
import { PostDetailComponent } from "./PostDetail.component";

interface CharchaaPostPageContentPathParams {
  postId: number;
}

interface CharchaaPostPageContentProps extends PageProps {
  match: ReactMatch<CharchaaPostPageContentPathParams>;
}
export function CharchaaPostPageContent(props: CharchaaPostPageContentProps) {
  let [post, setPosts] = useState<Post | undefined>(undefined);

  function fetchPost(): Promise<any> {
    return CharchaaAPI.fetchPostById(props.match.params.postId).then(
      (postResponse) => {
        setPosts(postResponse);
      }
    );
  }

  useEffect(() => {
    fetchPost();
    ViewsManager.log({
      targetType: AttachmentTargetType.POST,
      targetId: props.match.params.postId + "",
    });
  }, []);
  return (
    <div className="PostDetailPageContent main-container">
      <div className="row">
        <div className="col pt-4">
          <BreadcrumbComponent
            items={[
              {
                href: publicLinkConstants.CHARCHAA,
                children: (
                  <>
                    <i className="fas fa-users"></i> Charchaa
                  </>
                ),
              },
              {
                children: <>Post</>,
              },
            ]}
          />
        </div>
        <div className="pb-2 text-end hstack gap-4 col-auto">
          <div className="ms-auto">
            <a
              href={publicLinkConstants.CHARCHAA_POST_ADD}
              className="btn btn-primary"
            >
              <span className="event-data-desktop">
                <i className="fas fa-plus"></i> Add Charchaa
              </span>
              <span className="event-data-mobile">
                <i className="fas fa-plus"></i> Add
              </span>
            </a>
          </div>
        </div>
      </div>
      {post && (
        <div className="row">
          <div className="col-md-8 col-lg-9">
            <PostDetailComponent post={post}></PostDetailComponent>
            <div className="mt-3">
              <CommentsComponent
                dl="Discussion"
                targetType={AttachmentTargetType.POST}
                targetId={props.match.params.postId + ""}
                loggedInuser={props.user}
              />
            </div>
          </div>
          <div className="col-md-4 col-lg-3 vstack gap-3">
            {!post.isAnon && post.actor && (
              <>
                <UserProfileSidebar userId={post.actor.id} />
                <OfferingsUserSidebar userId={post.actor.id} />
              </>
            )}
            <RelatedContentComponent context={{ topics: post.topics }} />
          </div>
        </div>
      )}
    </div>
  );
}
