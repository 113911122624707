import React from "react";
import { NewBadge } from "../../badge/New.Badge";
import { TopExpertBadge } from "../../badge/TopExpert.Badge";
import { UserBasicDetails } from "../User";

export function UserCardTopRightBadge(props: { user: UserBasicDetails }) {
  let engineDetails = props.user.expertDetails;
  return (
    <>
      {engineDetails?.isNew && (
        <h6>
          <NewBadge />
        </h6>
      )}

      {engineDetails?.isTopExpert && (
        <h6>
          <TopExpertBadge />
        </h6>
      )}
    </>
  );
}
