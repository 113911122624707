import React, { useState } from "react";
import { CopyClipboardButton } from "./CopyCliboard.button";

interface CopyClipboardTextAreaProps {
  initialValue: string;
  rows?: number;
}

export default function CopyClipboardTextArea(
  props: CopyClipboardTextAreaProps
) {
  const [textAreaContent, setTextAreaContent] = useState(props.initialValue);

  const onChange = (evt: any) => {
    setTextAreaContent(evt.target.value);
  };

  return (
    <div>
      <form>
        <textarea
          className="form-control"
          onChange={onChange}
          value={textAreaContent}
          rows={props.rows ?? 10}
        />
        <CopyClipboardButton textToCopy={textAreaContent} />
      </form>
    </div>
  );
}
