import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { PaymentSuccessResponse } from "../model/response/PaymentSuccess.response";
import { RazorpaymentProcessorPaymentResponse } from "../model/response/Razorpay.response";
import { Payment } from "./Payment.Model";

export class PaymentApi {
  static list(): Api<void, Payment[]> {
    return new Api<void, Payment[]>("/api/payment", ApiMethod.GET);
  }

  static updatePaymentStatus(): Api<Payment, PaymentSuccessResponse> {
    return new Api<Payment, PaymentSuccessResponse>(
      "/api/payment/update_status",
      ApiMethod.POST
    );
  }

  public static markRazorpayPaymentSuccess(): Api<
    RazorpaymentProcessorPaymentResponse,
    PaymentSuccessResponse
  > {
    return new Api<
      RazorpaymentProcessorPaymentResponse,
      PaymentSuccessResponse
    >(apiLinkConstants.RAZORPAY_PAYMENT_SUCESS, ApiMethod.POST);
  }
}
