import { Col, Row } from "antd";
import { useField } from "formik";
import React from "react";
import { FormikInputParams } from "./FormikInputParams.model";

export interface EBTextInputV2Props extends FormikInputParams {
  prependNode?: React.ReactNode;
  appendNode?: React.ReactNode;
  className?: string;

  maxLength?: number;
  minLength?: number;
  showCount?: boolean;
}

export function EBTextInputV2(props: EBTextInputV2Props) {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  let fieldValue = field.value;

  return (
    <>
      {props.prependNode || props.appendNode ? (
        <div className="input-group">
          {props.prependNode}
          <input className="form-control" type="text" {...field} {...props} />
          {props.appendNode}
        </div>
      ) : (
        <input
          className="form-control"
          type="text"
          {...field}
          {...props}
          maxLength={props.maxLength}
          minLength={props.minLength}
        />
      )}

      <Row>
        <Col flex={"auto"}>
          {props.helpText ? (
            <small className="form-text text-muted">{props.helpText}</small>
          ) : null}
        </Col>
        <Col>
          {props.showCount && (
            <>
              <small className="form-text text-muted">
                {fieldValue ? fieldValue.toLocaleString().length : 0}
                {props.maxLength != undefined && `/${props.maxLength}`}
              </small>
            </>
          )}
        </Col>
      </Row>

      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
}
