import React from "react";
import { Question } from "./Question.Model";

interface QuestionDlProps {
  ques: Question;
}
export function QuestionDl(props: QuestionDlProps) {
  let question = props.ques;
  return (
    <>
      {question.id}. {question.title}
    </>
  );
}
