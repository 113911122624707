import React from "react";
import { HTMLProps } from "../../model/HTMLProps";
import { Target } from "../../model/target.model";
import { TargetDl } from "../../target/Target.Dl";

interface TargetLinkProps extends HTMLProps {
  target: Target;
}

export function TargetLink(props: TargetLinkProps) {
  return (
    <a
      href={props.target.url}
      target="_blank"
      className={`text-dark ${props.className}`}
    >
      <TargetDl {...props} />
    </a>
  );
}
