import { FormLineItemInputTextAreaTemplateData } from "../line-items/FormLineItemInputTextAreaComponent";
import {
  FormTemplateLineItem,
  FormTemplateLineItemType,
  FormTemplateLineItemTypeId,
  FormTemplateResponse,
} from "../model/formTemplate.model";

export class FormTemplateHelper {
  public static getTextAreaInputLi(
    name: string,
    data: FormLineItemInputTextAreaTemplateData,
    required: boolean
  ): FormTemplateLineItem {
    return {
      name: name,
      type: {
        id: FormTemplateLineItemTypeId.INPUT_TEXT_AREA,
      } as FormTemplateLineItemType,
      data: data,
      validations: {
        required: required,
        customValidations: {},
      },
    };
  }

  public static allLiTypes(): FormTemplateLineItemType[] {
    return [
      {
        id: FormTemplateLineItemTypeId.INPUT_LINE,
        dl: "Short answer",
      },
      {
        id: FormTemplateLineItemTypeId.INPUT_TEXT_AREA,
        dl: "Paragraph",
      },
      {
        id: FormTemplateLineItemTypeId.BOOL,
        dl: "Yes/No",
      },
      {
        id: FormTemplateLineItemTypeId.FILE_UPLOAD,
        dl: "File upload",
      },
    ];
  }

  public static hasItems(template?: FormTemplateResponse): boolean {
    return (
      template?.templateData?.sections != undefined &&
      template.templateData.sections.some((section) => {
        return section.lineItems && section.lineItems.length > 0;
      })
    );
  }
}
