import React from "react";
import axios from "axios";
import { publicLinkConstants } from "../../constants";

const DEFAULT_USER_NAME = "Guest";

class Comp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoggedIn: false };
  }

  componentDidMount() {
    axios.get("/api/user").then((res) => {
      const user = res.data;
      this.setState({ user });
      this.setState({ isLoggedIn: true });
    });
  }

  render() {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        id="mainNav"
      >
        <div className="container">
          <a className="navbar-brand js-scroll-trigger" href="#page-top">
            {/* <div className="home-page-img"></div> */}
            {process.env.REACT_APP_WEBSITE_NAME}
          </a>
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
            <i className="fas fa-bars ml-1"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav text-uppercase ml-auto">
              {this.props.headerLinks &&
                this.props.headerLinks.map((headerLink, index) => (
                  <li className="nav-item" key={headerLink.id}>
                    <a
                      className="nav-link js-scroll-trigger"
                      href={headerLink.href}
                    >
                      {headerLink.text}
                    </a>
                  </li>
                ))}
              <li className="nav-item" key="find-experts">
                <a
                  className="btn btn-primary text-dark text-strong nav-link js-scroll-trigger"
                  href={publicLinkConstants.FIND_EXPERTS}
                >
                  <i class="fas fa-search"></i> Find Experts
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Comp;
