import React from "react";
import { MarkdownView } from "../components/common/MarkdownView";
import StarRatingView from "../components/common/StarRatingView";
import { HomePageSection, HomePageSectionProps } from "./HomePage.Section";

export function HomePageTestimonialsSection(props: HomePageSectionProps) {
  return (
    <HomePageSection
      className="HomePageTestimonialsSection bg-main-body"
      id="hp-testimonials"
      headingStartIcon={<i className="fa-solid fa-heart"></i>}
      heading={<> Our Users Love Us!</>}
    >
      <TestimonialSectionContent />
    </HomePageSection>
  );
}

function TestimonialSectionContent() {
  let ratings = [
    {
      text: "The session was really great. Udit answered all my questions and gave me right direction to think. I really got to know what are the essential s required in an interview for a product base firms.",
      user: "Paritosh Sudame",
      starsCount: 5,
    },
    {
      text: "Ankit patiently listens to all my queries, answer them to the point with honesty, After talking to him I have got a better perspective on approaching system design interview. Ankit speaks with experience and his thought are well articulated and action-oriented. I am grateful to have a discussion with him.",
      user: "Niraj",
      starsCount: 5,
    },
    {
      text: "Had a great session on LLD . Got to know strengths and weaknesses and had a great conversation over best possible approach to keep in mind while designing and what is the expectation an interviewer would have from you in these rounds.",
      user: "Aashirwad",
      starsCount: 5,
    },
    {
      text: "I had so many question, Priyanka was very calm, listened all my questions and confusions on how should I prepare for my next interview, she answered all my questions, helped me remove the confusion, gave me direction and path I should take to plan internal switch, provided a focused path that will help me accomplish the goals. Thank you for such great platform.",
      user: "Ash",
      starsCount: 5,
    },
    {
      text: "One of the best mentors out there! Absolutely loved the session, he gave me a solid advice on how to prepare and guided me in the best way he can. Would definitely reconnect with him for another session. Thanks Shivam & engineBogie.",
      user: "Rishab",
      starsCount: 5,
    },
    {
      text:
        "The session was very inspiring and uplifting. I am expressing my deepest gratitude for accepting to be my mentor today. I believe that getting the opportunity to learn from you will make a major impact on my professional short, medium, and long term career." +
        "\n You have been an exemplary and visionary mentor, a great leader who has dedicated her life to the service of humanity. I appreciate and treasure everything you have taught me." +
        "\n Thanks for being a good mentor and for guiding me on the right path. I will always be thankful to you.",
      user: "Staynand",
      starsCount: 5,
    },
    {
      text:
        "I had scheduled mock interview session for SDE 1 Position at FAANG/MAANG. Interview couldn't have been more real. I could feel the pressure along with time slipping by while coding, I had opted for medium and Hard Level but I took More time than expected. We started by having a Normal Discussion (for 15 minutes), 2 coding Questions(each were given 20 mins but I took 15mnis extra) ,In between the Question Discussion we jumped onto OOPS and OS concepts (where I went just blank), We had a discussion on System Design and different cases one could get in an interview. We ended the session with my entire interview review checking all the dos and don'ts. The entire session was for 1hr but it went to 1hr 20 mins." +
        "\nI am really feel fortunate that I took this session, I really know my true standing and what i need to work upon before entering the actual Interview. Its better to fail in a mock than in an actual interview.",
      user: "Manthan",
      starsCount: 5,
    },
    {
      text: "It was a great session indeed. Great set of questions (to the point), also feedback about my performance during interview. I really appreciate the details Vaibhav shared with me about areas I can improve upon. I would say that it was very well worth the time and effort!",
      user: "Amisha",
      starsCount: 5,
    },
  ];
  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide carousel-dark"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        {ratings.map((rating, index) => {
          return (
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={index}
              className={index == 0 ? "active" : ""}
              aria-label="Slide 1"
            ></button>
          );
        })}
      </div>
      <div className="carousel-inner">
        {ratings.map((rating, index) => {
          return (
            <div className={"carousel-item " + (index == 0 ? "active" : "")}>
              <div className="testimonial px-5 pb-5">
                <p className="fs-5">
                  <i className="fas fa-quote-left pr-1"></i>{" "}
                  <MarkdownView text={rating.text} />
                </p>
                <h4 className="font-weight-bold">{rating.user}</h4>
                <StarRatingView
                  starRating={rating.starsCount}
                  maxRating={5}
                  hideNumbers={true}
                />
              </div>
            </div>
          );
        })}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}
