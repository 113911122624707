let API_BASE = "/api";
export class QuestionConstants {
  public static QUESTION_ANSWER_SUBMISSION_ADD =
    API_BASE + "/question/{questionId}/answer/user/{userId}";
  public static QUESTION_ANSWER_SUBMISSION_FETCH_USER_SPECIFIC =
    API_BASE + "/question/{questionId}/answer/user/{userId}";
  public static QUESTION_ANSWER_SUBMISSION_FETCH =
    API_BASE + "/question/{questionId}/answer";

  public static API_QUESTIONS_MERGE =
    API_BASE + "/question/{questionIdToMergeInto}/merge";

  public static API_QUESTION_APPROVE =
    API_BASE + "/admin/question/:questionId/approve";
  public static API_QUESTION_DISAPPROVE =
    API_BASE + "/admin/question/:questionId/disapprove";

  public static API_QUESTION_ARCHIVE =
    API_BASE + "/admin/question/:questionId/archive";
  public static API_QUESTION_UNARCHIVE =
    API_BASE + "/admin/question/:questionId/unarchive";
}
