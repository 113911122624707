import React from "react";
import { CompaniesBadgeList } from "../../badge/Companies.BadgeList";
import { PriceHelpers, UserHelpers } from "../../helpers/Helpers";
import userHelpers from "../../helpers/userHelpers";
import { Link } from "../../links/Link";
import { LocationType } from "../../model/Picture";
import { UserBasicDetails } from "../../user/User";
import { UserSummaryComponent } from "../../user/UserProfile.Sidebar";
import { UserCardSkillsList } from "../../user/card/UserCard.Skills.List";
import { UserCardTopRightBadge } from "../../user/card/UserCard.TopRight.Badge";
import "./ExpertCardMedium.css";
import { ImageComponent } from "./Image.component";

interface ExpertCardMediumProps {
  expert: UserBasicDetails;
}

class ExpertCardMedium extends React.Component<ExpertCardMediumProps, {}> {
  render() {
    var expert = this.props.expert;
    const latestExp = expert.latestExperience;

    return (
      <>
        <Link
          href={expert.profileUrl}
          className="text-dark  text-decoration-none "
        >
          <div
            className={`card rounded-5 profile-card-5 grid-card ${
              expert.expertDetails?.isTopExpert &&
              "border-primary border grid-card-primary"
            }`}
          >
            <UserCardTopRightBadge user={expert} />
            <div className="text-center top--20 position-relative">
              <Link href={expert.profileUrl}>
                <ImageComponent
                  image={{
                    locationType: LocationType.URL,
                    data: userHelpers.getProfilePicUrl(expert),
                  }}
                  className={`rounded-circle ${
                    expert.expertDetails?.isTopExpert &&
                    "border-primary border shadow-primary"
                  }`}
                  width={100}
                  height={100}
                />
              </Link>
            </div>
            <div className="card-body pt-0">
              <h2 className="text-center card-title h5">
                {expert.dl}{" "}
                {expert.country?.dp && (
                  <ImageComponent image={expert.country.dp} />
                )}
                <br />
                {expert.username && (
                  <span className="fs-6">
                    <small>@{expert.username}</small>
                  </span>
                )}
              </h2>

              {latestExp ? (
                <>{`${latestExp.designation.dl} - ${latestExp.company.dl}`}</>
              ) : null}
              {expert.experienceDurationInDays != undefined &&
                expert.experienceDurationInDays > 0 && (
                  <span className="ml-1 small">
                    (
                    {Math.floor(expert.experienceDurationInDays / 365).toFixed(
                      0
                    )}
                    + years)
                  </span>
                )}
              {expert.engineSummary && (
                <UserSummaryComponent
                  engineSummary={expert.engineSummary}
                  className="mt-2"
                />
              )}
              <p className="mt-2">
                <UserCardSkillsList user={expert} />
                <br></br>
                {expert.pastCompanies && expert.pastCompanies.length > 0 && (
                  <CompaniesBadgeList
                    companies={expert.pastCompanies}
                    viewLimit={3}
                  />
                )}
              </p>
              {expert.tagline && (
                <p className="text-center">{expert.tagline}</p>
              )}
              <div className="text-center">
                <a
                  href={UserHelpers.getUserProfileUrl(expert)}
                  className={`btn  ${
                    expert.expertDetails?.isTopExpert
                      ? "btn-primary"
                      : "btn-light"
                  }`}
                >
                  Book @{" "}
                  {PriceHelpers.getDisplayPriceV2(
                    expert.expertDetails?.minPrice
                  )}{" "}
                </a>
                {/* <div className="text-primary mt-1">{lowestPrice ? "Starts at Rs. " + lowestPrice: null}</div> */}
              </div>
            </div>
          </div>
        </Link>
      </>
    );
  }
}

export default ExpertCardMedium;
