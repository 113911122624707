import React from "react";
import { OrderItem } from "../model/OrderDetails";

interface OrderItemComponentProps {
  orderItem: OrderItem;
  index: number;
}

export function OrderItemComponent(props: OrderItemComponentProps) {
  return (
    <>
      <div className="card border-left-primary shadow h-100 ">
        <div className="card-body">
          <h5 className="font-weight-bold">
            Item {props.index + 1}: {props.orderItem.dl}
          </h5>

          <div className="row">
            <div className="col no-gutters align-items-center">
              <span className="font-weight-bold">Details : </span>
              {!props.orderItem.itemBookingLink && (
                <>Booking details shall be shown post payment!</>
              )}
              {props.orderItem.itemBookingLink && (
                <>
                  <span className="col-6 text-xs mb-1">
                    <a href={props.orderItem.itemBookingLink} target="_blank">
                      Click here to go to booking.
                    </a>
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function OrderItemListComponent(props: OrderItemComponentProps) {
  return (
    <>
      <div className=" ">
        <div className="font-weight-bold">
          Item {props.index + 1}: {props.orderItem.dl}
        </div>

        <div className="row">
          <div className="col no-gutters align-items-center">
            {props.orderItem.itemBookingLink && (
              <>
                <span className="col-6 text-xs mb-1">
                  <a href={props.orderItem.itemBookingLink} target="_blank">
                    Click here to go to booking.
                  </a>
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
