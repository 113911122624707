import React from "react";
import { TimeHelper } from "../../../calendar/Time.helper";
import { DeleteTitle } from "../Delete.title";
import { FormikInputParams } from "./FormikInputParams.model";
import { TimeSelector } from "./Time.selector";

interface TimeSlotInputProps extends FormikInputParams {
  onDelete: () => void;
}

export function TimeSlotInput(props: TimeSlotInputProps) {
  return (
    <div className="TimeSlotEditComponent row">
      <div className="col">
        <TimeSelector
          name={`${props.name}.start`}
          times={TimeHelper.getTimeList(15)}
          fieldTitle=""
        />
      </div>
      <div className="col-auto">to</div>
      <div className="col">
        <TimeSelector
          name={`${props.name}.end`}
          times={TimeHelper.getTimeList(15)}
          fieldTitle=""
        />
      </div>
      <div className="col-auto">
        <button
          className="btn text-danger form-group"
          type="button"
          onClick={() => props.onDelete()}
          disabled={props.disabled}
        >
          <DeleteTitle />
        </button>
      </div>
    </div>
  );
}
