import React from "react";
import { ImageComponent } from "../components/common/Image.component";
import { HTMLProps } from "../model/HTMLProps";
import { Target } from "../model/target.model";

interface TargetDlProps extends HTMLProps {
  target: Target;
}

export function TargetDl(props: TargetDlProps) {
  return (
    <span className={`${props.className}`}>
      {props.target.dp && (
        <ImageComponent
          image={props.target.dp}
          className="mr-2 text-secondary"
        />
      )}
      {props.target.label}
    </span>
  );
}
