import React from "react";
import Moment from "react-moment";
import { PaymentDetailsPaypal } from "../model/OrderDetails";
import { PriceView } from "../components/common/Price.view";

interface PaymentPaypalComponentProps {
  paymentDetails: PaymentDetailsPaypal;
}

export class PaymentPaypalComponent extends React.Component<PaymentPaypalComponentProps> {
  render() {
    return (
      <>
        <div className="PaymentPaypalComponent">
          <div className="card-body p-0">
            {this.props.paymentDetails.paypalOrderId && (
              <div className="col no-gutters align-items-center">
                <span className="font-weight-bold">Paypal Order ID : </span>
                <span className="text-xs mb-1">
                  {this.props.paymentDetails.paypalOrderId}
                </span>
              </div>
            )}
            {this.props.paymentDetails.paymentDate && (
              <div className="col no-gutters align-items-center">
                <span className="font-weight-bold">Payment Date: </span>
                <span className=" text-xs mb-1">
                  <Moment format="DD-MM-YYYY">
                    {this.props.paymentDetails.paymentDate}
                  </Moment>
                </span>
              </div>
            )}
            <div className="col no-gutters align-items-center">
              <span className="font-weight-bold">Payment Status: </span>
              {this.props.paymentDetails.paymentStatus}
            </div>
            <div className="col no-gutters align-items-center">
              <span className="font-weight-bold">Payment Method: </span>

              <span className="text-xs mb-1">
                {this.props.paymentDetails.paymentMethod}
              </span>
            </div>

            {this.props.paymentDetails.paymentPrice && (
              <div className="col no-gutters align-items-center">
                <span className="font-weight-bold">Payment Amount: </span>

                <span className="text-xs mb-1">
                  <PriceView
                    price={this.props.paymentDetails.paymentPrice}
                    showFree={true}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
