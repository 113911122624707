import React from "react";
import { List, ListItemProps } from "../../list/List";
import { ListItemLayoutDefault } from "../../list/ListItem.Layout";
import { UserBasicDetails } from "../../user/User";
import { UserLink } from "../../user/User.link";
import { EBDrawer } from "../common/Drawer";

interface UserDrawerProps {
  onFetch: () => Promise<UserBasicDetails[]>;
  title: string;
  onClose?: () => void;
  open?: boolean;
}

export function UserDrawer(props: UserDrawerProps) {
  return (
    <EBDrawer title={props.title} onClose={props.onClose} open={props.open}>
      <List
        onFetch={props.onFetch}
        ItemComponent={UserListItem}
        emptyListContent="Not found"
        ItemLayoutComponent={ListItemLayoutDefault}
      />
    </EBDrawer>
  );
}

function UserListItem(props: ListItemProps) {
  let user = props.item as UserBasicDetails;
  return (
    <div className="UserListItem">
      <UserLink user={user}></UserLink>
    </div>
  );
}
