import axios from "axios";
import { CreateOrUpdateUpdateAttachmentRequest } from "../model/requests/CreateOrUpdateUpdateAttachmentRequest";

export class AttachmentsAPI {
  public static createOrUpdateAttachment(
    apiEndpoint: string,
    attachmentRequest: CreateOrUpdateUpdateAttachmentRequest
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(apiEndpoint, attachmentRequest)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
