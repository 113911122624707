import React, { useEffect, useState } from "react";
import { CalendarApi } from "../calendar/Calendar.api";
import { CalendarSettings } from "../calendar/Calendar.model";
import { CalendarSettingsEditComponent } from "../calendar/CalendarAvailability.component";
import { Loader } from "../components/common/Loader.component";
import { Tab } from "../components/common/Tab.view";
import { Center } from "../components/common/layout/Center.component";
import { ExpertStudioContentProps } from "./ExpertStudio.page";

interface CalendarExpertStudioContentProps extends ExpertStudioContentProps {}

export function CalendarExpertStudioContent(
  props: CalendarExpertStudioContentProps
) {
  let tabs: Tab[] = [];

  tabs.push({
    title: "Main Settings",
    content: <div className="card card-body"></div>,
    uniqueKey: "main",
    isActive: true,
  });

  let [calendarSettings, setCalendarSettings] = useState<CalendarSettings>();
  let [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    setLoading(true);
    CalendarApi.getUserCalendarSettings(props.user.basicDetails.id).then(
      (calendarSettings) => {
        setCalendarSettings(calendarSettings);
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className="CalendarExpertStudioContent">
      <h3>
        <i className="fas fa-calendar"></i> Calendar
      </h3>

      {/* <TabView tabs={tabs} selectedTab={tabs[0]} /> */}
      <div className="card card-body">
        {loading && (
          <>
            <Center>
              <Loader />
            </Center>
          </>
        )}
        {!loading && calendarSettings && (
          <>
            <CalendarSettingsEditComponent
              user={props.user.basicDetails}
              calendarSettings={calendarSettings}
            />
          </>
        )}
      </div>
    </div>
  );
}
